import { useEffect, useRef, useState } from "react";

import { loadPaddleSubscription } from "@/App";
import { Badge, Button } from "flowbite-react";

import {
  toggleManageSubscriptionModal,
  toggleUpgradeToProModal,
} from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { getUserVidyoJourney, updateSubscription } from "@/api/requests";

import {
  convertToCamelCase,
  planIdInterval,
  getPaddlePriceByPlanId,
} from "@/helpers";
import { getPlanName } from "@/helpers/amplitudeHelper";

import {
  ANALYTICS_CONSTANTS,
  eventsDataToRedux,
} from "@/utils/amplitudeAnalytcs";
import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

import { ScreenName } from "@/enums";

import BaseModal from "@/components/BaseModal/BaseModal";

import { CancelSubscription } from "@/views/home/components/ManageSubscriptionModal";
import { UpgradeSubscription } from "@/views/home/components/ManageSubscriptionModal/UpgradeSubscription";
import VidyoUserJourney from "@/views/home/components/ManageSubscriptionModal/VidyoUserJourney";

const UPGRADE = "UPGRADE";
const CANCEL = "CANCEL";
const MAIN = "MAIN";
const PAUSE = "PAUSE";

const Paddle = (window as any).Paddle;

const SUB_STATE = {
  ACTIVE: "active",
  DELETED: "deleted",
  PASTDUE: "past_due",
  PAUSED: "paused",
};

export const ManageSubscriptionModal = () => {
  const dispatch = useAppDispatch();

  const loadingUserJourneyRef = useRef(false);
  const [currentComponent, setCurrentComponent] = useState(MAIN);
  const [isPausingSub, setIsPausingSub] = useState(false);

  const manageSubscriptionModalVisible = useAppSelector(
    (state) => state.homeState.manageSubscriptionModalVisible
  );
  const paddleSubscription = useAppSelector(
    (state) => state.authState.paddleSubscription
  );
  const { planType } = useAppSelector(
    (state) => state.authState.userSubscription
  );
  const {
    state,
    signup_date,
    payment_information,
    next_payment,
    last_payment,
    update_url,
    plan_id,
    subscription_id,
    paused_at,
  } = paddleSubscription;
  const currentUserSubscriptionDetails = useAppSelector(
    (state) => state.authState.userSubscription
  );
  const homeAnalytics = useAppSelector(
    (state) => state.amplitudeState.homeScreen
  );
  const [userJourney, setUserJourney] = useState<any>({
    renderedCount: 0,
  });

  const loadUserJourneyData = async () => {
    if (loadingUserJourneyRef.current) {
      return;
    }
    loadingUserJourneyRef.current = true;
    try {
      const data: any = await getUserVidyoJourney();
      setUserJourney({
        renderedCount: data.rendered_count,
      });
    } catch (error: any) {
      console.log("could not load user analytics", error);
    }
    loadingUserJourneyRef.current = false;
  };

  useEffect(() => {
    setCurrentComponent(MAIN);
    if (
      manageSubscriptionModalVisible &&
      currentUserSubscriptionDetails.subscriptionId
    ) {
      loadUserJourneyData();
    }
  }, [paddleSubscription]);

  useEffect(() => {
    // close for inital render
    dispatch(toggleManageSubscriptionModal(false));
  }, []);

  const last_payment_date = last_payment
    ? new Date(last_payment?.date)
    : new Date();
  const isAnnual = planIdInterval[plan_id] === "yearly";

  const updatePaymentInformation = () => {
    Paddle.Checkout.open({
      override: update_url,
      passthrough: JSON.stringify({
        screenName: homeAnalytics?.paymentScreenName
          ? convertToCamelCase(homeAnalytics.paymentScreenName)
          : ScreenName.HOME,
      }),
    });
  };

  const isSubPaused = () => {
    return (
      (state === SUB_STATE.ACTIVE || state === SUB_STATE.PAUSED) && paused_at
    );
  };

  const planStateString = () => {
    switch (state) {
      case SUB_STATE.ACTIVE:
        if (isSubPaused()) {
          return (
            <div className="text-lg font-normal">
              Your plan paused on {new Date(paused_at).toLocaleDateString()}
            </div>
          );
        }
        return (
          <div className="text-lg font-normal">
            Your plan renews on{" "}
            {new Date(next_payment.date).toLocaleDateString()}
          </div>
        );
      case SUB_STATE.DELETED:
        return (
          <div className="text-lg font-normal text-red-500">
            Your plan cancels on{" "}
            {isAnnual
              ? new Date(
                  new Date(last_payment.date).setFullYear(
                    last_payment_date.getFullYear() + 1
                  )
                ).toLocaleDateString()
              : new Date(
                  new Date(last_payment.date).setMonth(
                    last_payment_date.getMonth() + 1
                  )
                ).toLocaleDateString()}
          </div>
        );
      case SUB_STATE.PASTDUE:
        return (
          <div className="text-lg font-normal text-red-500">
            Your payment is past due
          </div>
        );
      default:
        break;
    }
  };

  const updateSubscriptionPause = async (pause: boolean) => {
    setIsPausingSub(true);
    try {
      const response: any = await updateSubscription({
        subscription_id,
        pause,
      });
      if (response.success) {
        loadPaddleSubscription(currentUserSubscriptionDetails);
        setIsPausingSub(false);
        setCurrentComponent(MAIN);
      } else {
        throw new Error();
      }
    } catch (error) {
      showNotification(
        "Failed to pause subscription, please try again later or contact support.",
        notificationType.FAIL
      );
    }
    setIsPausingSub(false);
  };

  const renderRightSideButtons = () => {
    if (state === SUB_STATE.ACTIVE || state === SUB_STATE.PASTDUE) {
      if (currentComponent === PAUSE) {
        return (
          <div className="flex-col space-y-5">
            <>
              <Button
                className="w-full"
                outline={true}
                onClick={() => updateSubscriptionPause(true)}
                isProcessing={isPausingSub}
              >
                Yes, pause
              </Button>
              <Button
                outline={true}
                color="success"
                onClick={() => setCurrentComponent(MAIN)}
                disabled={isPausingSub}
              >
                No, don't pause
              </Button>
            </>
          </div>
        );
      }

      if (isSubPaused()) {
        return (
          <div className="flex-col space-y-5 w-56">
            <Button
              onClick={() => updateSubscriptionPause(false)}
              isProcessing={isPausingSub}
              className="w-full animate-bounce"
            >
              Resume Subscription
            </Button>
            <Button
              outline={true}
              color="dark"
              onClick={() => setCurrentComponent(CANCEL)}
              disabled={isPausingSub}
              className="w-full"
            >
              Cancel Subscription
            </Button>
          </div>
        );
      }

      return (
        <div className="flex-col space-y-5">
          <>
            <Button
              className="w-full bg-[#374151] hover:bg-[#000]"
              onClick={() => setCurrentComponent(UPGRADE)}
            >
              Change Plan
            </Button>
            <Button
              className="w-full bg-green-600 hover:bg-green-800"
              onClick={() => setCurrentComponent(PAUSE)}
            >
              Pause Subscription
            </Button>
            <Button
              className="w-full"
              outline={true}
              color="dark"
              onClick={() => setCurrentComponent(CANCEL)}
            >
              Cancel Subscription
            </Button>
          </>
        </div>
      );
    }

    if (state === SUB_STATE.DELETED) {
      return (
        <div className="flex-col space-y-5">
          <>
            <Button
              className="w-full bg-[#374151] hover:bg-[#000]"
              onClick={() => {
                dispatch(toggleUpgradeToProModal(true));
                dispatch(toggleManageSubscriptionModal(false));
                eventsDataToRedux(ANALYTICS_CONSTANTS.PAYMENT_SCREEN_NAME);
              }}
            >
              Resubscribe
            </Button>
          </>
        </div>
      );
    }
  };

  return (
    <BaseModal
      isModalOpen={manageSubscriptionModalVisible}
      handelModalOpen={(value: boolean) =>
        dispatch(toggleManageSubscriptionModal(value))
      }
      notClosable={isSubPaused()}
    >
      {manageSubscriptionModalVisible && (
        <div className="relative w-[1170px] h-[620px] bg-white px-7 py-8">
          {isSubPaused() ? null : (
            <button
              type="button"
              className="absolute z-10 right-6 ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-800 dark:hover:text-white"
              onClick={() => dispatch(toggleManageSubscriptionModal(false))}
            >
              <svg
                aria-hidden="true"
                className="h-5 w-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          )}

          {currentComponent === UPGRADE && (
            <UpgradeSubscription setCurrentComponent={setCurrentComponent} />
          )}
          {currentComponent === CANCEL && (
            <CancelSubscription
              setCurrentComponent={setCurrentComponent}
              userJourney={userJourney}
            />
          )}
          {(currentComponent === MAIN || currentComponent === PAUSE) && (
            <div className="flex-col space-y-9">
              <div className="text-3xl font-semibold flex justify-center items-center">
                {isSubPaused()
                  ? "Your Subscription is Paused"
                  : "Manage Your Subscription"}
              </div>

              <div className="flex flex-col gap-5">
                <VidyoUserJourney userJourney={userJourney} />

                {/* green */}
                <div className="h-[224px] bg-[#DEF7EC] flex items-center justify-between px-8 rounded-lg">
                  {/* left */}
                  <div className="flex-col space-y-3 pl-8 py-9 w-1/2">
                    <p className="text-lg font-normal">
                      {`You are a ${getPlanName(
                        planType
                      )} subscriber since ${new Date(
                        signup_date
                      ).toLocaleDateString()}`}
                    </p>
                    <div className="flex items-baseline text-lg">
                      <span className="text-3xl font-semibold">
                        {getPaddlePriceByPlanId(plan_id)}
                      </span>
                      {" / " + planIdInterval[plan_id]}
                      <Badge color="indigo">
                        {payment_information?.payment_method === "card"
                          ? `${payment_information.card_type.toUpperCase()} ${
                              payment_information.last_four_digits
                            }`
                          : "PayPal"}
                      </Badge>
                    </div>
                    {planStateString()}
                    {((state === SUB_STATE.ACTIVE && !isSubPaused()) ||
                      state === SUB_STATE.PASTDUE) && (
                      <div className="">
                        <Button
                          outline={true}
                          onClick={updatePaymentInformation}
                        >
                          Update Payment Method
                        </Button>
                      </div>
                    )}
                  </div>

                  {/* right */}
                  {renderRightSideButtons()}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </BaseModal>
  );
};
