import { useQuery } from "@tanstack/react-query";

import { getScheduledPostsList } from "@/api/requests";

import { auth } from "@/utils/firebase";

export default function useGetScheduledPostsHistory({
  successCallback,
  errorCallback,
}: {
  successCallback: (a: any) => any;
  errorCallback: (a: any) => any;
}) {
  const user = auth.currentUser;

  return useQuery({
    queryKey: ["posts-history", user],
    queryFn: () =>
      getScheduledPostsList({
        user_id: user!.uid,
        status: "pending",
        recent_posts: 500,
      }),
    enabled: !!user,
    onSuccess: (res) => successCallback(res),
    onError: (error) => errorCallback(error),
    refetchOnWindowFocus: true,
  });
}
