import { TrashIcon } from "@heroicons/react/24/solid";

import { AppliedCardVariant } from "@/enums";

import AppliedAssetCardContainer from "@/views/editor/components/AppliedAssetCard/AppliedAssetCardContainer";

type AppliedAssetCardProps = {
  cardType: AppliedCardVariant;
  assetUrl: string | null;
  color?: string | null;
  onDelete?: () => void;
  isApplied?: boolean;
  primaryLabel?: string;
  children?: React.ReactNode;
  assetName?: string;
  assetType?: string;
  isSocialLogo?: boolean;
  volume?: number;
};

const PrimaryLabel = ({ label }: { label?: string }) => (
  <div className="flex items-center space-x-3">
    <p className="text-gray-700 font-semibold text-sm">{label}</p>
  </div>
);

const DeleteIcon = ({ onDelete }: { onDelete?: () => void }) => (
  <TrashIcon
    className="text-red-500 w-5 h-5 cursor-pointer hover:text-red-600 transition-colors "
    onClick={onDelete}
  />
);

const AppliedAssetCard = ({
  cardType,
  assetUrl,
  color,
  onDelete = () => {},
  isApplied = true,
  primaryLabel,
  children,
  assetType,
  isSocialLogo = false,
  volume,
}: AppliedAssetCardProps) => {
  let appliedColor: string | undefined | null;
  let isSocial: boolean | undefined;

  switch (cardType) {
    case AppliedCardVariant.BACKGROUND:
      appliedColor = "#FFFFFF";
      break;
    case AppliedCardVariant.SOCIAL:
      isSocial = isSocialLogo;
      break;
    case AppliedCardVariant.B_ROLL:
      appliedColor = color;
      break;
    default:
      break;
  }

  return (
    <AppliedAssetCardContainer
      assetUrl={assetUrl}
      appliedColor={appliedColor}
      assetType={assetType}
      isSocial={isSocial}
      showTransition={isApplied}
      volume={volume}
    >
      <>
        {primaryLabel && <PrimaryLabel label={primaryLabel} />}
        <div
          className={`flex items-center  ${
            children ? "justify-between" : "justify-end"
          }`}
        >
          {children}
          {cardType !== AppliedCardVariant.B_ROLL &&
            cardType !== AppliedCardVariant.AUDIO && (
              <DeleteIcon onDelete={onDelete} />
            )}
        </div>
      </>
    </AppliedAssetCardContainer>
  );
};

export default AppliedAssetCard;
