import { useEffect, useState } from "react";

import { BUTTON_IDS } from "@/constants/segment-analytics";

import { trackUserButtonEvent } from "@/utils/SegmentAnalytics";

import BaseModal from "@/components/BaseModal/BaseModal";

import UserOnboardingSuccessBgImg from "@/assets/images/on-boarding/saving-onboarding-form-bg.png";

const { GET_STARTED_BTN } = BUTTON_IDS.USER_ONBOARD_COMPLETE_MODAL;

const UserOnboardingCompletedModal = ({
  showUserOnBoardingCompletedModal,
  setShowOnboardingCompletedModal,
}: {
  showUserOnBoardingCompletedModal: boolean;
  setShowOnboardingCompletedModal: () => void;
}) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowSuccessMessage(true);
    }, 5000);
  }, []);

  return (
    <BaseModal
      isModalOpen={showUserOnBoardingCompletedModal}
      handelModalOpen={() => {}}
      notClosable
    >
      <div className="sm:w-[35rem]">
        <div className="bg-[#B7EAE6] pt-3">
          <img
            src={UserOnboardingSuccessBgImg}
            alt="User onboarding in progress"
            className="mx-auto w-3/4"
          />
        </div>
        <div className="mt-2 bg-white text-center">
          {showSuccessMessage ? (
            <>
              <p className="mt-3 text-center text-xl font-bold">
                Hurray! Your account is now ready! 🥳
              </p>
              <p className="mx-auto my-3 w-11/12 text-sm font-medium text-gray-400">
                You now have free access to the world's most loved audio
                repurposing tool. Start by uploading your first audio.
              </p>
            </>
          ) : (
            <p className="mt-3 mb-6 text-center text-xl font-bold">
              We are personalizing your account...
            </p>
          )}

          <button
            type="button"
            id={GET_STARTED_BTN.ID}
            className={`mx-auto flex w-40 items-center justify-center rounded-lg px-5 py-2.5 text-center text-sm font-medium text-white focus:outline-none focus:ring-4 focus:ring-blue-300  ${
              !showSuccessMessage
                ? "mt-4 mb-6 bg-[#D1D5DB] opacity-70"
                : "my-6 bg-blue-600 hover:bg-blue-700"
            }`}
            disabled={!showSuccessMessage}
            onClick={() => {
              trackUserButtonEvent(GET_STARTED_BTN.ID, GET_STARTED_BTN.ACTION);
              setShowOnboardingCompletedModal();
            }}
          >
            Get Started
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default UserOnboardingCompletedModal;
