import clsx from "clsx";

import { setElementsActiveTab } from "@/store/editorSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { IntroOutroEditor } from "@/views/editor/components/IntroOutroEditor";
import { LogoEditor } from "@/views/editor/components/LogoEditor";
import { ProgressBarEditor } from "@/views/editor/components/ProgressBarEditor";

const PROGRESS_BAR = "Progress Bar";
const SOCIALS = "Socials";
const INTRO_OUTRO = "intro-outro";

const menuList = [
  {
    name: PROGRESS_BAR,
    Component: ProgressBarEditor,
    id: PROGRESS_BAR,
  },
  {
    name: "Logos",
    Component: LogoEditor,
    id: SOCIALS,
  },
  {
    name: "Outro",
    Component: IntroOutroEditor,
    id: INTRO_OUTRO,
  },
];

const ElementsTab = () => {
  const dispatch = useAppDispatch();
  const currentTab = useAppSelector(
    (state) => state.editorState.elementsActiveTab
  );

  const handelChangeCurrentTab = (tabName: string) => {
    dispatch(setElementsActiveTab(tabName));
  };

  return (
    <div className="relative  w-full">
      <div className="w-full bg-white p-2 pt-6">
        <p className="text-lg text-gray-800">Video Elements</p>
      </div>
      <div
        className="sticky top-0 z-50 overflow-x-auto  overflow-y-hidden border-b border-gray-200 bg-white text-center text-sm font-medium text-gray-500 dark:border-gray-700 dark:text-gray-400 flex"
        aria-label="Tabs"
      >
        {menuList.map((menuItems) => (
          <p
            key={menuItems.id}
            className={clsx(
              currentTab === menuItems.id
                ? "text-blue-600 font-medium hover:text-blue-600"
                : "text-gray-400 hover:text-gray-500 font-medium",
              "group relative overflow-hidden bg-white py-4 whitespace-nowrap cursor-pointer transition-all w-full"
            )}
            onClick={() => handelChangeCurrentTab(menuItems.id)}
          >
            <span className="-ml-1">{menuItems.name}</span>
            <span
              aria-hidden="true"
              className={clsx(
                currentTab === menuItems.id
                  ? "bg-indigo-500"
                  : "bg-transparent",
                "absolute inset-x-0 bottom-0 h-0.5 z-10"
              )}
            />
          </p>
        ))}
        <span
          aria-hidden="true"
          className="absolute inset-x-0 bottom-0 h-px bg-gray-100"
        />
      </div>

      <div className="p-2">
        {menuList
          .filter((menuItems) => menuItems.id === currentTab)
          .map((item) => (
            <item.Component key={item.id} />
          ))}
      </div>
    </div>
  );
};

export default ElementsTab;
