import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "@/api";
import { ApiEndpoints } from "@/api/constants";

import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

type Clips = {
  start: number;
  end: number;
  type: string;
};

const deleteClips = async (project_id: string, clips: Clips[]) => {
  const response = await api.post(ApiEndpoints.BULK_DELETE, {
    project_id,
    clips,
  });
  return response;
};

export default function useDeleteBulkClips() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ projectId, clips }: { projectId: string; clips: Clips[] }) =>
      deleteClips(projectId, clips),
    {
      onSuccess: () => {
        showNotification(
          "Video Clips deleted successfully",
          notificationType.SUCCESS
        );

        queryClient.invalidateQueries({ queryKey: ["micro-content-data"] });
      },
      onError: () => {
        showNotification(
          "Something went wrong, please try again!",
          notificationType.FAIL
        );
      },
    }
  );
}
