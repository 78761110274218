import { SVGProps } from "react";

const Hand = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={45}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.13}
      d="m24.322 22.66-.828-9.385L12.86 1a1.953 1.953 0 0 0-.252 2.53l7.074 10.166.336 14.525-2.26-13.265-8.476-10.59a1.362 1.362 0 0 0-.572.812c-.08.332-.03.68.139.978l5.383 9.488.936 9.241-3.02-7.151-4.836-9.702a2.935 2.935 0 0 0-.958 1.622 2.893 2.893 0 0 0 .234 1.86l3.471 7.112 3.269 14.946-7.564-5.931 1.615-3.679c.22-.5.266-1.057.134-1.586a2.502 2.502 0 0 0-.868-1.346L1 25.88 6.017 44l19.432-.23-1.127-21.11Z"
    />
  </svg>
);
export default Hand;
