import { useEffect, useState } from "react";

import { updatePassword, updateProfile } from "firebase/auth";
import { Button, Label, TextInput } from "flowbite-react";

import { store } from "@/store";
import { setCurrentUser } from "@/store/authSlice";
import { useAppSelector } from "@/store/hooks";

import { notificationType } from "@/utils/constants";
import { auth } from "@/utils/firebase";
import { showNotification } from "@/utils/showNotification";

import BaseModal from "@/components/BaseModal/BaseModal";
import Spinner from "@/components/Loader/Spinner";

import { validatePassword } from "@/views/auth/validator";

const PasswordChangeModal = ({
  displayPasswordChangeModal,
  setDisplayPasswordChangeModal,
}: {
  displayPasswordChangeModal: boolean;
  setDisplayPasswordChangeModal: (value: boolean) => void;
}) => {
  const user = auth.currentUser;
  const currentUser = useAppSelector((state) => state.authState.currentUser);

  const [isLoading, setIsLoading] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [repeatPasswordError, setRepeatPasswordError] = useState("");

  const handlePasswordChange = (e: any) => {
    const password = e.target.value;

    if (password.length < 8) {
      setPasswordError("Password must be 8 characters long");
    } else {
      setPasswordError("");
    }
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    if (e.target.password.value !== e.target.repeatPassword.value) {
      showNotification("Passwords doesn't match", notificationType.FAIL);
    } else {
      if (
        validatePassword(e.target.password.value, setPasswordError) &&
        validatePassword(e.target.repeatPassword.value, setPasswordError)
      ) {
        setIsLoading(true);
        user &&
          updatePassword(user, e.target.password.value.trim())
            .then(() => {
              setIsLoading(false);
              setDisplayPasswordChangeModal(false);
              showNotification(
                "Password changed successfully",
                notificationType.SUCCESS
              );
            })
            .catch((error) => {
              setIsLoading(false);
              switch (error.code) {
                case "auth/requires-recent-login":
                  showNotification(
                    "To change your pasword please log back in",
                    notificationType.FAIL
                  );
                  break;
                default:
                  showNotification(error.message, notificationType.FAIL);
                  break;
              }
            });
      } else {
        setPasswordError("Password must be 8 characters long");
      }
    }
  };
  return (
    <BaseModal
      isModalOpen={displayPasswordChangeModal}
      handelModalOpen={() => {}}
    >
      <form
        className="w-[25rem] bg-white p-6 pb-10 pt-8"
        onSubmit={handleFormSubmit}
      >
        <p className="mb-6 text-center text-2xl font-bold text-gray-900">
          Change your password
        </p>
        <div className="mt-6">
          <div>
            <Label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Password
            </Label>

            <div className="w-full relative">
              <TextInput
                type="password"
                name="password"
                id="password"
                placeholder="Enter new password"
                onChange={handlePasswordChange}
                className=" text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pt-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                color={`${passwordError && "failure"}`}
                helperText={
                  <span style={{ marginTop: "-10px" }}>{passwordError}</span>
                }
              />
            </div>
          </div>
          <div className=" mt-4">
            <Label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Repeat password
            </Label>

            <div className="w-full relative">
              <TextInput
                type="password"
                name="repeatPassword"
                id="repeatPassword"
                placeholder="Confirm password"
                className=" text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pt-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                color={`${repeatPasswordError && "failure"}`}
                helperText={
                  <span style={{ marginTop: "-10px" }}>
                    {repeatPasswordError}
                  </span>
                }
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row-reverse gap-10 mt-6">
          <Button
            disabled={passwordError ? true : false}
            type={isLoading ? "button" : "submit"}
            id="password-change-submit-btn"
            className={`w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 ${
              isLoading ? "cursor-wait opacity-70" : ""
            }`}
          >
            {isLoading ? (
              <Spinner className="h-4 w-4" />
            ) : (
              <span>Continue</span>
            )}
          </Button>
          <Button
            color="light"
            id="password-change-cancel-btn"
            className="w-full rounded-lg border border-blue-500 bg-white text-center text-sm font-medium text-blue-500 hover:bg-blue-50  focus:outline-none focus:ring-4 focus:ring-blue-300"
            onClick={() => {
              setDisplayPasswordChangeModal(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </form>
    </BaseModal>
  );
};
export default PasswordChangeModal;
