import clsx from "clsx";

import { IconPropType } from "@/utils/constants";

export const ForwardFrameIcon = ({
  fillColor,
  height,
  width,
  className,
  hoverFillColor,
}: IconPropType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={clsx(
        className,
        fillColor && `text-${fillColor}`,
        hoverFillColor && `hover:text-${hoverFillColor}`,
        "fill-current"
      )}
      color=""
    >
      <path
        fill="currentColor"
        d="M15.195 8.25c-6.61-4.483-6.729-4.506-6.92-4.543a.738.738 0 0 0-.88.724v3.036c-5.472-3.7-5.59-3.723-5.767-3.76a.738.738 0 0 0-.879.724v8.863c0 .273.148.524.391.65a.73.73 0 0 0 .753-.037l5.503-3.671v3.058c0 .273.148.524.391.65a.732.732 0 0 0 .753-.037l6.648-4.432a.737.737 0 0 0 .007-1.226Z"
      />
    </svg>
  );
};
