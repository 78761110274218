import { FC } from "react";

import clsx from "clsx";

import AppliedMediaCard from "./AppliedMediaCard";

import NoMediaImg from "@/assets/images/editor/no-media-selected.svg";

type PanelContentProps = {
  bRolls: any[];
  isDrawerEmpty: boolean;
};

const PanelContent: FC<PanelContentProps> = ({ bRolls, isDrawerEmpty }) => {
  if (isDrawerEmpty) {
    return (
      <div className=" flex flex-col items-center justify-center text-center mt-28">
        <img
          src={NoMediaImg}
          alt="No media Present"
        />
        <p className="text-gray-400 text-sm">
          You haven’t added any B rolls yet
        </p>
      </div>
    );
  }

  return (
    <div>
      {bRolls.length && (
        <p className="text-sm text-gray-700 px-2 mt-5 mb-3">
          Add the timing for when each B-roll should appear
        </p>
      )}
      <div className={clsx(bRolls.length > 3 ? "p-2" : "p-3")}>
        {bRolls.map((asset: any) => (
          <AppliedMediaCard
            key={asset.uid || asset.id}
            assetId={asset.uid || asset.id}
            assetType={asset.assetType}
            assetUrl={asset.url}
            startTime={asset.metaData?.start}
            endTime={asset.metaData?.end}
            isFullLength={asset.metaData?.isFullVideoLength}
            mediaVolume={asset.metaData?.volume}
            assetName={asset.metaData?.asset_name}
          />
        ))}
      </div>
    </div>
  );
};

export default PanelContent;
