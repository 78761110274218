import clsx from "clsx";

import { IconPropType } from "@/utils/constants";

export const InvertedTriangleIcon = ({
  fillColor,
  height = "16",
  width = "16",
  className,
}: IconPropType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={clsx(className)}
      color={fillColor}
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#a)">
        <path
          d="M7.022 14.434a1.13 1.13 0 0 0 1.96 0l6.857-11.667c.457-.778-.092-1.767-.98-1.767H1.144c-.889 0-1.437.99-.98 1.767l6.858 11.667Z"
          clipRule="evenodd"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="a">
          <rect
            width="100%"
            height="100%"
            fill="#fff"
            transform="matrix(1 0 0 -1 0 16)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
