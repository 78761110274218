export type IndexInfo = {
  arrayIndex: number;
  startIndex: number;
  endIndex: number;
};

export function getMultipleSubstringIndices(
  splitArray: string[],
  originalStartIndex: number,
  originalEndIndex: number
): IndexInfo[] {
  let startIndex = originalStartIndex;
  let end = originalEndIndex;
  return splitArray.map((subString, index): IndexInfo => {
    // when the substring is not part of the current line, but it exists in the next lines
    if (startIndex >= subString.length) {
      startIndex = startIndex - subString.length - 1;
      end = end - subString.length - 1;
      return { arrayIndex: index, startIndex: 0, endIndex: 0 };
    }
    // when the substring is partially contained in the current line, start exists in the current line but end is in the next line
    if (startIndex < subString.length && end > subString.length) {
      const itemToReturn = {
        arrayIndex: index,
        startIndex,
        endIndex: subString.length - 1,
      };
      end = end - subString.length - 1;
      startIndex = startIndex - subString.length - 1;
      return itemToReturn;
    }

    // when the substring is continuiting from the previous line and ends in the current line
    if (startIndex <= 0 && end <= subString.length) {
      const itemToReturn = { arrayIndex: index, startIndex: 0, endIndex: end };
      end = end - subString.length;
      return itemToReturn;
    }

    // when the substring is fully contained in the current line
    if (startIndex >= 0 && end <= subString.length) {
      const itemToReturn = { arrayIndex: index, startIndex, endIndex: end };
      startIndex = startIndex - subString.length;
      end = end - subString.length;
      return itemToReturn;
    }

    //  when the substring does not exist i.e both are below or = 0
    if (startIndex <= 0 && end <= 0) {
      return { arrayIndex: index, startIndex: 0, endIndex: 0 };
    }
    return { arrayIndex: index, startIndex: 0, endIndex: 0 };
  });
}
