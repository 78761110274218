import { useEffect, useState } from "react";
import { useBoolean } from "react-use";

import { Tooltip, Spinner } from "flowbite-react";

import useGetScheduledCompletedHistory from "@/api/useGetScheduledCompletedPosts";
import useGetScheduledFailedHistory from "@/api/useGetScheduledFailedPosts";

import { convertTimeStampToDateTime } from "@/helpers";

import { trackSchedulePostViewEvent } from "@/utils/amplitudeAnalytcs";
import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

import {
  FbIcon,
  InstagramColorIcon,
  LinkedinIcon,
  TiktokColorIcon,
  TwitterIcon,
  YoutubeIcon,
} from "@/views/home/components/DownloadVideoCard/SocialMediaSharing/icons";

export const CompletedPosts = () => {
  const [completedList, setCompletedList] = useState<any>([]);
  const [successList, setSuccessList] = useState<any>([]);
  const [failedList, setFailedList] = useState<any>([]);
  const [isSuccessEmpty, setIsSuccessEmpty] = useBoolean(false);
  const [isFailedEmpty, setIsFailedEmpty] = useBoolean(false);
  const [showNoPosts, setShowNoPosts] = useBoolean(false);
  const [isLoading, setIsLoading] = useBoolean(true);

  useGetScheduledCompletedHistory({
    successCallback: (res) => onSuccessData(res),
    errorCallback: (error) => onSuccessDataError(error),
  });

  useGetScheduledFailedHistory({
    successCallback: (res) => onErrorData(res),
    errorCallback: (error) => onErrorDataFailure(error),
  });

  const onSuccessData = (response: any) => {
    const { data, status } = response;
    if (status === 200) {
      if (!data.data.length) {
        setIsSuccessEmpty(true);
      } else {
        setSuccessList(data.data);
      }
    }
    setIsLoading(false);
  };

  const onSuccessDataError = (err: any) => {
    setIsLoading(false);
    if (err.response.status === 404) {
      setIsSuccessEmpty(true);
      return;
    }
    if (err.response.data.code !== 221) {
      showNotification(
        "Something went wrong. Please refresh the page to try again!",
        notificationType.FAIL
      );
    }
  };

  const onErrorData = (response: any) => {
    const { data, status } = response;
    if (status === 200) {
      if (!data.data.length) {
        setIsFailedEmpty(true);
      } else {
        setFailedList(data.data);
      }
    }
    setIsLoading(false);
  };

  const onErrorDataFailure = (err: any) => {
    setIsLoading(false);
    if (err.response.status === 404) {
      setIsFailedEmpty(true);
      return;
    }
    if (err.response.data.code !== 221) {
      showNotification(
        "Something went wrong. Please refresh the page to try again!",
        notificationType.FAIL
      );
    }
  };

  const getIconForSocialMedia = (platformName: string) => {
    switch (platformName) {
      case "tiktok":
        return <TiktokColorIcon />;
      case "youtube":
        return <YoutubeIcon fillColor="red" />;
      case "facebook":
        return <FbIcon fillColor="#3B5998" />;
      case "instagram":
        return (
          <InstagramColorIcon
            width="20"
            height="22"
          />
        );
      case "linkedin":
        return <LinkedinIcon fillColor="#0077B5" />;
      case "twitter":
        return <TwitterIcon fillColor="#1DA1F2" />;
    }
  };

  const getErrorMessage = (errMsg: string) => {
    if (errMsg.includes("Duplicate")) {
      return "Duplicate or similar content posted within the same three day period";
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (isSuccessEmpty && isFailedEmpty) {
      setShowNoPosts(true);
      setCompletedList([]);
    }
    setCompletedList([...successList, ...failedList]);
  }, [successList, failedList, isSuccessEmpty, isFailedEmpty]);

  if (isLoading) {
    return (
      <div className="h-[84%] flex items-center justify-center ">
        <Spinner className="w-[22px] h-[22px]" />
      </div>
    );
  }

  if (showNoPosts) {
    return (
      <p className="h-[84%] text-xl flex items-center justify-center font-medium text-gray-800">
        No posts yet! 🙁
      </p>
    );
  }

  return (
    <div className="flex justify-between flex-wrap pb-[20%]">
      {completedList.map((post: any) => {
        const {
          platforms = [],
          scheduleDate,
          title,
          mediaUrls,
          postIds = [],
          status,
          errors = [],
          id,
          clip_id,
        } = post;
        return (
          <div
            key={id}
            className="w-[49%] px-3 items-center border-gray-200 bg-transparent rounded-lg shadow sm:flex h-auto py-2 mb-5 relative border"
          >
            <video
              className="rounded-lg h-[130px] w-[150px] border border-gray-400"
              src={mediaUrls[0]}
            />
            <div className="pl-3">
              <h3 className="text-sm font-medium tracking-tight text-gray-700 dark:text-white w-[85%] mb-2">
                {title}
              </h3>
              {status === "error" ? (
                <div className="flex justify-between">
                  <div className="text-gray-500 dark:text-gray-400 text-sm">
                    Status:
                    <br />
                    <span className="font-bold text-md text-red-600">
                      Error{" "}
                    </span>
                  </div>
                  <p className="text-red-600 mt-6 text-sm">
                    {getErrorMessage(errors[0]?.message)}
                  </p>
                </div>
              ) : (
                <>
                  <div className="mt-3 flex justify-between gap-[100px]">
                    <div className="text-gray-500 dark:text-gray-400 text-sm ">
                      Posted at: <br />
                      <span className="font-bold text-md text-gray-900">
                        {convertTimeStampToDateTime(scheduleDate?.utc)}
                      </span>
                    </div>
                    <div className="text-gray-500 dark:text-gray-400 text-sm">
                      Status:
                      <br />
                      <span className="font-bold text-md text-green-600">
                        Success{" "}
                      </span>
                    </div>
                  </div>
                  <a
                    className="absolute right-[25px] top-[30px] cursor-pointer"
                    href={postIds[0]?.postUrl}
                    onClick={() => trackSchedulePostViewEvent(clip_id)}
                  >
                    <Tooltip
                      content={"Check Post"}
                      className="w-28 inline-block"
                    >
                      {getIconForSocialMedia(platforms[0])}
                    </Tooltip>
                  </a>
                </>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
