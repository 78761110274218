import { SVGProps, memo } from "react";

import clsx from "clsx";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    className={clsx(props.className, "fill-current")}
    {...props}
  >
    <path
      fill="currentColor"
      d="M20.571 5H3.43A1.429 1.429 0 0 0 2 6.429v11.428a1.429 1.429 0 0 0 1.429 1.429H20.57A1.429 1.429 0 0 0 22 17.857V6.43A1.429 1.429 0 0 0 20.571 5ZM10.93 15.236a3.571 3.571 0 1 1 0-6.186.714.714 0 1 1-.715 1.237 2.143 2.143 0 1 0 0 3.712.714.714 0 0 1 .715 1.237Zm7.142 0a3.571 3.571 0 1 1 0-6.186.713.713 0 0 1-.165 1.32.716.716 0 0 1-.549-.083 2.142 2.142 0 1 0 0 3.712.715.715 0 0 1 .714 1.237Z"
    />
  </svg>
);
const SubtitleIcon = memo(SvgComponent);
export default SubtitleIcon;
