import { useQuery } from "@tanstack/react-query";

import { getDefaultBaseTemplates } from "@/api/requests";

export default function useGetDefaultBaseTemplates() {
  return useQuery({
    queryKey: ["base-template"],
    queryFn: () => getDefaultBaseTemplates(),
    refetchOnWindowFocus: false,
    staleTime: 300000,
  });
}
