import { InformationCircleIcon } from "@heroicons/react/24/outline";

export const InfoToast = ({ info }: { info: string }) => {
  return (
    <div
      className={`h-9 w-full flex justify-between px-2 bg-[#DEF7EC] text-[#03543F] border border-[#03543F] rounded-md`}
    >
      <div className="font-light text-xs text-left flex items-center">
        <InformationCircleIcon
          className="mr-2"
          width={20}
          height={20}
        />
        {info}
      </div>
    </div>
  );
};
