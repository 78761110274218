import { FC } from "react";

import { ChevronUpIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

import { cn } from "@/helpers";

type PanelHeaderProps = {
  isOpen: boolean;
  hidden: boolean;
  toggleDrawer: () => void;
  mediaCountString: string;
  className?: string;
  buttonClassName?: string;
};

const PanelHeader: FC<PanelHeaderProps> = ({
  isOpen,
  hidden,
  toggleDrawer,
  mediaCountString,
  className = "",
  buttonClassName = "",
}) => {
  return (
    <>
      <div
        className={cn(
          "justify-between p-2 pl-3 flex items-center py-4 transition-all duration-400 ease-linear cursor-pointer rounded-t-lg",
          hidden ? "opacity-0 hidden" : "opacity-100",
          className
        )}
        style={{
          boxShadow: "0px -2px 11px 0px #0000001C",
        }}
        onClick={toggleDrawer}
      >
        <div>
          <p className="font-medium">
            B rolls{" "}
            <span className="font-thin text-sm">({mediaCountString})</span>
          </p>
        </div>
        <button
          className={cn(
            "w-6 h-6 rounded-full flex justify-center items-center  bg-gray-200",
            buttonClassName
          )}
          onClick={toggleDrawer}
          aria-label="Toggle Drawer"
        >
          <ChevronUpIcon
            className={clsx("w-4", isOpen ? "rotate-180 mt-0.5" : "-mt-0.5")}
            strokeWidth={2}
          />
        </button>
      </div>
    </>
  );
};

export default PanelHeader;
