import { useEffect, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import { TrashIcon } from "@heroicons/react/20/solid";

import {
  toggleSceneChange,
  updateAllSceneChanges,
  updateVideoFrameLengthInMillis,
} from "@/store/editorSlice";
import { updateShowEnableSceneChangeWarningModal } from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { getSceneChanges } from "@/api/useGetProjectScenes";

import { INTELLI_CLIP_TAG } from "@/constants";

import {
  changeStageUrlToProd,
  formatSeconds,
  getAllScenesBetweenMicroContent,
  getColonSeparatedTimeWithMillis,
  getSceneAtExactTime,
  getSecondsFromHMSM,
  sleep,
} from "@/helpers";

import {
  ANALYTICS_CONSTANTS,
  eventsDataToRedux,
} from "@/utils/amplitudeAnalytcs";
import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

import DropDownColorPicker from "@/components/DropDownColorPicker";
import Spinner from "@/components/Loader/Spinner";
import TimeField from "@/components/TimeField";
import Tooltip from "@/components/Tooltip";

import EnableCutMagicModal from "@/views/editor/components/EnableCutMagicModal";

import CutMagicOutputVideo from "@/assets/cutmagic.mp4";
import AddIcon from "@/assets/icons/add-dotted-black.svg";
import ExternalLinkIcon from "@/assets/icons/external-link.svg";
import InfoIcon from "@/assets/icons/info.svg";
import { ReactComponent as IconTriangleFilled } from "@/assets/icons/inverted-triangle-filled.svg";
import { ReactComponent as IconTriangleHalfFilled } from "@/assets/icons/inverted-triangle-half.svg";
import BlankCanvasIllustration from "@/assets/images/illustration-blank-canvas.svg";

const RenderCard = ({ scene, updateScene, deleteScene }: any) => {
  const currentSelectedMicroContent = useAppSelector(
    (state) => state.homeState.currentSelectedMicroContent
  );

  const handleStartTimeUpdate = (value: string) => {
    let newStartTimeInSec = getSecondsFromHMSM(value);

    if (typeof newStartTimeInSec === "undefined") {
      return;
    }

    let newStartTime =
      newStartTimeInSec * 1000 + currentSelectedMicroContent.start;

    // if same time is entered, do not update
    if (scene.start * 1000 === newStartTime) {
      return;
    }

    if (newStartTime === currentSelectedMicroContent.start) {
      newStartTime = newStartTime + 1000; // do not allow scene at start time since it will be always present
    }

    if (
      newStartTime > currentSelectedMicroContent.start &&
      newStartTime <= currentSelectedMicroContent.end
    ) {
      // check if a scene is already present at the time
      const existingScene = getSceneAtExactTime(newStartTime / 1000);
      if (existingScene) {
        // convert seconds to  hh:mm:ss.ms format
        const time = getColonSeparatedTimeWithMillis(
          newStartTime - currentSelectedMicroContent.start
        );
        showNotification(
          `Scene already present at ${time}. Please remove it first.`,
          notificationType.WARN
        );
      }
      updateScene({
        ...scene,
        start: newStartTime / 1000,
      });
      return newStartTimeInSec.toString();
    }
  };
  const allowEdit = scene.start * 1000 !== currentSelectedMicroContent.start;
  const timeAfterDiff = scene.start - currentSelectedMicroContent.start / 1000;
  return (
    <div className="flex flex-col gap-6 rounded-md bg-white p-3">
      <div className="flex items-center justify-between">
        <div className="flex items-center text-sm font-normal text-black">
          Start time
          <TimeField
            time={formatSeconds(timeAfterDiff >= 0 ? timeAfterDiff : 0)}
            handleTimeChange={handleStartTimeUpdate}
            disabled={!allowEdit}
            className="ml-2"
          />
        </div>

        <TrashIcon
          className={`h-4 w-4 fill-red-500 ${
            allowEdit ? "cursor-pointer" : "cursor-not-allowed"
          }`}
          onClick={() => allowEdit && deleteScene()}
        />
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <input
            checked={scene.isTwoFace}
            id={"split-screen-checkbox" + scene.id}
            type="checkbox"
            value=""
            onChange={(e) =>
              updateScene({
                ...scene,
                isTwoFace: !scene.isTwoFace,
              })
            }
            className="h-4 w-4 cursor-pointer rounded border-gray-300 bg-gray-100
            text-blue-600 focus:ring-2 focus:ring-blue-500 
            dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 
            dark:focus:ring-blue-600"
          />
          <label
            htmlFor={"split-screen-checkbox" + scene.id}
            className="ml-2 cursor-pointer select-none text-sm font-medium text-gray-400 dark:text-gray-300"
          >
            Split Screen
          </label>
          {scene.isTwoFace && (
            <IconTriangleHalfFilled className="ml-1 h-4 w-4 fill-gray-400" />
          )}
        </div>
        <div className="flex items-center gap-2">
          <div className="text-sm font-normal text-black">Scene Color</div>
          <DropDownColorPicker
            color={scene.color}
            onChange={(color: string) =>
              updateScene({
                ...scene,
                color,
              })
            }
            height="h-5"
            width="w-5"
          />
        </div>
      </div>
    </div>
  );
};

const ButtonAddSceneChange = ({ onSceneAdd }: any) => {
  return (
    <>
      <button
        onClick={onSceneAdd}
        type="button"
        className={`flex h-10 w-full items-center justify-center rounded-lg border border-gray-200 bg-white text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700`}
        id="add-new-scene"
        data-tip="Pro tip: to add manual scene cut, <br> start from the first frame of the scene you want to separate"
      >
        <img
          className="mr-2"
          src={AddIcon}
          alt="add"
        />
        Add scene cut at current time
      </button>
      <ReactTooltip
        effect="solid"
        padding="8px"
        className="rounded bg-gray-900 text-sm text-white"
        multiline
      />
    </>
  );
};

const CUTMAGIC_ALLOWED_DURATION_THRESHOLD = 180; // 3 minutes

const SceneChangeTab = () => {
  const dispatch = useAppDispatch();

  const { onSceneDelete, onSceneUpdate, onSceneAdd } = useOutletContext<any>();

  const [isEnableCutMagicModalOpen, setIsEnableCutMagicModalOpen] =
    useState(false);

  const enableSceneChange = useAppSelector(
    (state) => state.editorState.enableSceneChange
  );
  const currentSelectedProject = useAppSelector(
    (state) => state.homeState.currentSelectedProject
  );
  const showEnableSceneChangeWarningModal = useAppSelector(
    (state) => state.homeState.showEnableSceneChangeWarningModal
  );
  const currentSelectedMicroContent = useAppSelector(
    (state) => state.homeState.currentSelectedMicroContent
  );

  const [scenesData, setSceneData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const allowCutMagic = useRef(true);

  useEffect(() => {
    if (!enableSceneChange) {
      dispatch(updateAllSceneChanges([]));
    }
  }, [enableSceneChange]);

  useEffect(() => {
    if (!isLoading && scenesData) {
      dispatch(updateAllSceneChanges(scenesData.scenes));
      scenesData.fps &&
        dispatch(
          updateVideoFrameLengthInMillis(
            Number((1 / scenesData.fps).toFixed(3))
          )
        );
    }
  }, [scenesData]);

  const durationInSeconds =
    (currentSelectedMicroContent.end - currentSelectedMicroContent.start) /
    1000;
  let isAllowed = durationInSeconds < CUTMAGIC_ALLOWED_DURATION_THRESHOLD;
  isAllowed = enableSceneChange || isAllowed;
  allowCutMagic.current = isAllowed;

  const filteredSceneChanges = getAllScenesBetweenMicroContent();

  const fetchSceneChanges = async (reqBody: any) => {
    if (currentSelectedProject) {
      setIsLoading(true);
      try {
        const data = await getSceneChanges(reqBody);

        if (data.status === "submitted") {
          await sleep(5000);
          await fetchSceneChanges(reqBody);
        } else if (data.status === "completed") {
          setSceneData(data);
        } else {
          showNotification(
            "Could not fetch scene changes for this clip please try again",
            "error"
          );
        }
      } catch (e) {
        console.log(e);
      }
    }
    setIsLoading(false);
  };

  const onEnableSceneChange = (dontShowAgain: boolean) => {
    if (currentSelectedProject) {
      let videoUrl;
      if (
        currentSelectedMicroContent?.tag === INTELLI_CLIP_TAG &&
        currentSelectedMicroContent?.clip_src
      ) {
        videoUrl = changeStageUrlToProd(currentSelectedMicroContent.clip_src);
      } else {
        videoUrl = JSON.parse(currentSelectedProject.data).remote_url;
      }

      fetchSceneChanges({
        project_id: currentSelectedProject.id,
        remote_url: videoUrl,
        start: currentSelectedMicroContent.start,
        end: currentSelectedMicroContent.end,
      });
    }
    dispatch(toggleSceneChange(!enableSceneChange));
    dispatch(updateShowEnableSceneChangeWarningModal(!dontShowAgain));
    setIsEnableCutMagicModalOpen(false);
    eventsDataToRedux(ANALYTICS_CONSTANTS.ENABLE_CUTMAGIC);
  };

  const toggleEnableCutMagic = (enable: boolean) => {
    if (!enable) {
      dispatch(toggleSceneChange(enable));
      return;
    }

    if (!allowCutMagic.current) {
      return;
    }

    if (showEnableSceneChangeWarningModal) {
      setIsEnableCutMagicModalOpen(true);
    } else {
      currentSelectedProject &&
        fetchSceneChanges({
          project_id: currentSelectedProject.id,
          remote_url: JSON.parse(currentSelectedProject.data).remote_url,
          start: currentSelectedMicroContent.start,
          end: currentSelectedMicroContent.end,
        });
      dispatch(toggleSceneChange(enable));
    }
  };

  const renderSceneChangeContent = () => {
    if (enableSceneChange) {
      if (isLoading) {
        return (
          <div className="flex flex-1 flex-col items-center justify-center">
            <div>
              <Spinner className="h-12 w-12" />
            </div>
            <div className="mt-4 text-xs font-medium text-gray-500">
              Making magic happen, please hold on..
            </div>
          </div>
        );
      }

      if (!filteredSceneChanges.length) {
        return (
          <div className="flex flex-1 flex-col justify-center p-2.5">
            <img
              src={BlankCanvasIllustration}
              alt="enable cut magic illustration"
              className="self-center"
            />
            <div className="flex flex-col gap-5 px-5 pt-4 pb-6 text-center text-xs font-medium text-[#5E6473]">
              Our AI didn't detect any scene changes in your video. You can
              still add cuts manually using the button below.
            </div>
            <ButtonAddSceneChange onSceneAdd={onSceneAdd} />
          </div>
        );
      }
      return (
        <div className="flex flex-1 flex-col gap-9 px-2 py-4">
          <div className="flex flex-col gap-6">
            {filteredSceneChanges.map((scene) => (
              <RenderCard
                key={scene.id}
                scene={scene}
                updateScene={onSceneUpdate}
                deleteScene={() => onSceneDelete(scene.id)}
              />
            ))}
          </div>
          <ButtonAddSceneChange onSceneAdd={() => onSceneAdd()} />
        </div>
      );
    }
    return (
      <div>
        <div className="flex flex-1 flex-col justify-center">
          <div className="flex flex-col gap-[18px] px-5 text-center text-xs font-medium text-[#5E6473]">
            <video
              src={CutMagicOutputVideo}
              className="self-center rounded-md shadow-sm mt-4"
              loop
              autoPlay
            />

            <div className="p-2.5 w-full rounded-md font-medium bg-white shadow-sm text-[#5E6473]">
              🎬 CutMagic™ works best for clips under 3 mins! ✨
            </div>

            <button
              type="button"
              className={`
              items-center justify-center rounded-lg 
              border  bg-blue-600 px-4 py-2.5 text-center 
              text-xs font-medium focus:outline-none
              border-blue-500 text-white hover:bg-blue-700 focus:ring-4 focus:ring-blue-300
              ${!allowCutMagic.current ? "cursor-not-allowed" : ""}
              `}
              onClick={() => toggleEnableCutMagic(!enableSceneChange)}
              id="add-emojis-button"
            >
              {enableSceneChange ? "Disable CutMagic" : "Enable CutMagic"}
            </button>
            <a
              className="text-[#3263E9]"
              href="https://vidyo.ai/cutmagic-automatic-scene-change-detection"
              target="_blank"
            >
              Learn more
              <img
                src={ExternalLinkIcon}
                alt="external link"
                className="ml-1 inline-block h-3 w-3"
              />
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="relative flex flex-1 flex-col">
      <div className="sticky top-0 z-10 flex justify-between bg-white px-4 py-5 pt-6">
        <div className="flex flex-1 flex-col gap-4 text-black">
          <div className="flex justify-between items-center">
            <div className="flex">
              <div className="text-lg">CutMagic™</div>
              <Tooltip
                placement="bottom"
                enterDelay={0}
                leaveDelay={2000}
                label={
                  <div className="w-[268px] self-center rounded-lg bg-gray-900 p-2 text-center text-[10px] text-white">
                    CutMagic™ help you track cuts & scene changes in your video
                    with AI.
                    <div>
                      <a
                        className="text-[#3263E9]"
                        href="https://vidyo.ai/cutmagic-automatic-scene-change-detection"
                        target="_blank"
                      >
                        Learn more
                        <img
                          src={ExternalLinkIcon}
                          alt="external link"
                          className="ml-1 inline-block h-3 w-3"
                        />
                      </a>
                    </div>
                  </div>
                }
              >
                <img
                  src={InfoIcon}
                  alt="info"
                  className="ml-1.5 h-3.5 w-3.5 cursor-pointer self-center"
                />
              </Tooltip>
            </div>
            <button
              type="button"
              className={`
              items-center justify-center rounded-lg 
              border  bg-transparent px-4 py-2.5 text-center 
              text-xs font-medium focus:outline-none
              ${!allowCutMagic.current ? "cursor-not-allowed" : ""}
              ${
                enableSceneChange
                  ? "border-red-600 text-red-600 hover:bg-red-50"
                  : "border-blue-500 text-blue-500 hover:bg-blue-50"
              }`}
              onClick={() => toggleEnableCutMagic(!enableSceneChange)}
              id="add-emojis-button"
            >
              {enableSceneChange ? "Disable CutMagic" : "Enable CutMagic"}
            </button>
          </div>
          <div className="flex items-center justify-end">
            <IconTriangleFilled className="h-3 w-3 fill-gray-400" />
            <div className="ml-1 text-xs text-gray-400">Full Screen</div>
            <IconTriangleHalfFilled className="ml-2.5 h-3 w-3 fill-gray-400" />
            <div className="ml-1 text-xs text-gray-400">Split Screen</div>
          </div>
        </div>
      </div>
      {renderSceneChangeContent()}
      <EnableCutMagicModal
        isOpen={isEnableCutMagicModalOpen}
        onCancel={() => setIsEnableCutMagicModalOpen(false)}
        onSuccess={onEnableSceneChange}
      />
    </div>
  );
};

export default SceneChangeTab;
