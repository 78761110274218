import { Fragment } from "react";

import { Menu, Transition } from "@headlessui/react";
import { TrashIcon } from "@heroicons/react/24/outline";
import { Spinner } from "flowbite-react";

import useDeleteAsset from "@/api/useDeleteAsset";

import { generateCloudflareImageUrl } from "@/helpers";

import { AudioCardType, SimpleAssetType } from "@/enums";

import AudioCard from "@/components/AudioCard";

const AssetCard = ({
  src,
  assetType,
  fileName,
  assetId,
  handleDeleteColorFromPalette,
}: any) => {
  const { mutate: deleteAsset, isLoading: deleteAssetLoading } =
    useDeleteAsset();

  const renderAssetType = (assetType: string) => {
    if (assetType.includes(SimpleAssetType.VIDEO)) {
      return (
        <video
          className="w-full object-center object-scale-down h-[9rem]"
          src={src} //Safari hack
          preload="metadata"
          muted // Safari hack
          autoPlay={false} // Safari hack
          playsInline // Safari hack
        />
      );
    }
    if (assetType.includes("font")) {
      return (
        <div className="h-[9rem] w-full flex justify-center items-center">
          <p
            className="text-center px-2"
            style={{
              fontFamily: fileName,
            }}
          >
            The font display on the website is crucial for enhancing the user's
            reading experience.
          </p>
        </div>
      );
    }
    if (assetType.includes(SimpleAssetType.IMAGE)) {
      return (
        <img
          src={generateCloudflareImageUrl({
            originalUrl: src,
            imgOptions: {
              height: 200,
            },
          })}
          className="w-full object-center object-scale-down h-[9rem]"
          onError={(e: any) => {
            e.target.onerror = null;
            e.target.src = src;
          }}
        />
      );
    }
    if (assetType.includes("color")) {
      return (
        <div
          className="w-full h-[9rem]"
          style={{
            backgroundColor: fileName,
          }}
        ></div>
      );
    }
    if (assetType.includes("audio")) {
      return (
        <div className="w-full h-[9rem]">
          <AudioCard
            audioUrl={src}
            audioCardType={AudioCardType.BRAND_KIT}
          />
        </div>
      );
    }
  };

  return (
    <li className="h-[12rem]  min-w-[12rem] rounded-lg bg-gray-100 border shadow-sm border-opacity-50">
      <div className="group relative">
        <div className=" w-full overflow-hidden rounded-t-md bg-white border-b border-opacity-50 aspect-auto">
          {renderAssetType(assetType)}
        </div>
        <div className=" mt-2 flex justify-between px-2 items-center">
          <p className=" text-xs font-medium truncate mr-4">{fileName}</p>
          <Menu
            as="div"
            className="relative inline-block text-left"
          >
            <div>
              <Menu.Button
                className={`inline-flex w-full justify-center rounded-md bg-gray-50 ${
                  deleteAssetLoading
                    ? "px-1.5 py-1.5 cursor-default hover:bg-opacity-100"
                    : "px-2.5 py-1.5"
                } text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                disabled={deleteAssetLoading}
              >
                {deleteAssetLoading ? (
                  <Spinner className="w-4 h-4" />
                ) : (
                  <svg
                    id="Layer_1"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 29.96 122.88"
                    className="w-1"
                  >
                    <path
                      fillRule="evenodd"
                      d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Zm0,92.93a15,15,0,1,1-15,15,15,15,0,0,1,15-15Zm0-46.47a15,15,0,1,1-15,15,15,15,0,0,1,15-15Z"
                    />
                  </svg>
                )}
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-20 mt-1 w-36 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="px-1 py-2 ">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? "bg-blue-500 text-white" : "text-red-500"
                        } flex w-full cursor-pointer items-center rounded-md px-2 py-1.5 text-sm`}
                        onClick={() => {
                          if (handleDeleteColorFromPalette) {
                            handleDeleteColorFromPalette(fileName);
                          } else {
                            deleteAsset(assetId);
                          }
                        }}
                      >
                        <TrashIcon className="w-4 h-4 mr-1 -mt-px" />
                        <span>Delete {assetType}</span>
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </li>
  );
};

export default AssetCard;
