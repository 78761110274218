import * as React from "react";

import clsx from "clsx";
import { fabric as fabricJS } from "fabric";

import useElementSize from "@/hooks/useElementSize";

import { getNewFabricStaticCanvas } from "@/helpers";

import { Scene } from "@/interfaces";

import { STATUS } from "@/enums";

import { InvertedHalfTriangleIcon } from "@/components/Icons/InvertedHalfTriangleIcon";
import { InvertedTriangleIcon } from "@/components/Icons/InvertedTriangleIcon";

import "./Timeline.styles.scss";
import { sampleChannelData } from "./sampleChannelData";

const BAR_WIDTH = 2;
const GAP = BAR_WIDTH / 2;
const TIMELINE_HEIGHT = 50;
const WAVEFORM_HIGHLIGHT_COLOR = "#1e40af";
const WAVEFORM_COLOR = "#a1a1aa";
const PARTIALLY_GENERATED = "PARTIALLY_GENERATED";
// this is neccarry to keep in check because we are setting the left position of the marker
// that does not account the width of the marker, so inital px of the marker is representing the true time.
// we need to make necessary adjustments to keep the marker true to its end and start time.
const MARKER_WIDTH = 3;

const loadMap: Record<
  string,
  {
    channelData?: Float32Array;
    loadRequestedStatus: boolean;
    objectsData?: Record<number, fabric.Rect>;
  }
> = {};

type DrawWaveform = {
  channelData: Float32Array;
  canvas: fabric.Canvas;
  src: string;
  width: number;
  sample?: boolean;
};
const drawWaveform = ({
  channelData,
  canvas,
  src,
  width,
  sample = false,
}: DrawWaveform): void => {
  canvas.clear();
  const step =
    Math.ceil(channelData.length / (canvas.width || 1)) * (GAP + BAR_WIDTH);
  let maxAmplitude = 0;
  for (let i = 0; i < channelData.length; i++) {
    if (Math.abs(channelData[i]) > maxAmplitude) {
      maxAmplitude = Math.abs(channelData[i]);
    }
  }

  const amplitude = (sample ? 0.18 : 0.38) * (canvas.height || 1);
  const averageArray = [];
  for (let i = 0; i < (width || 0); i++) {
    let sum = 0;
    for (let j = 0; j < step; j++) {
      sum += Math.abs(channelData[i * step + j]);
    }
    const average = sum / step;
    averageArray.push(average);
  }

  const maxAverage = Math.max(...averageArray.filter((avg) => !isNaN(avg)));

  averageArray.forEach((avg, i) => {
    const height = (avg / maxAverage) * amplitude;
    const left = (BAR_WIDTH + GAP) * i;
    const rect = new fabricJS.Rect({
      left,
      top: (canvas.height || 1) / 2 - height,
      fill: WAVEFORM_COLOR,
      width: BAR_WIDTH,
      height: 2 * height,
      rx: 1,
      ry: 1,
    });
    // added null check because typescript was complaining about the null value even when if is added.
    if (loadMap[src].objectsData) {
      loadMap[src].objectsData![left] = rect;
    }
    canvas.add(rect);
  });
  canvas.renderAll();
};

/** Function to generate the wave form, takes in the mediaSourceURL, canvas and fabric Instance. */
async function generateWaveForm({
  src,
  canvas,
  cb,
  width,
  chapterPercentage,
}: {
  src: string;
  canvas: fabric.Canvas;
  cb?: () => void;
  width: number;
  chapterPercentage: {
    start: number;
    end: number;
  };
}) {
  loadMap[src] = loadMap[src] || {
    loadRequestedStatus: false,
    objectsData: [],
    channelData: [],
  };
  const audioContext = new AudioContext();
  if (canvas && canvas.width && canvas.height) {
    let channelData: Float32Array;
    // if the cached items are already available, use them.
    if (
      loadMap[src] &&
      loadMap[src].loadRequestedStatus &&
      loadMap[src].channelData !== undefined
    ) {
      channelData = loadMap[src].channelData!;
    } else {
      drawWaveform({
        // @ts-expect-error ignore for now
        channelData: sampleChannelData,
        canvas,
        src,
        width,
        sample: true,
      });
      cb?.();
      const response = await fetch(src);
      const arrayRes = await response.arrayBuffer();
      const audioBuffer = await audioContext.decodeAudioData(arrayRes);
      const channelDataRaw = audioBuffer.getChannelData(0);
      const startIndex = Math.floor(
        chapterPercentage.start * channelDataRaw.length
      );
      const endIndex = Math.floor(
        chapterPercentage.end * channelDataRaw.length
      );
      channelData = channelDataRaw.slice(startIndex, endIndex);
      loadMap[src].channelData = channelData;
      loadMap[src].loadRequestedStatus = true;
    }
    drawWaveform({ channelData, canvas, src, width });
    cb?.();
  }
}

// function to update the color of rectangles based on the left position which is store inside the cache.
function updateWaveFormColor(arg: {
  startPosition: number;
  endPosition: number;
  mediaURL: string;
  canvas: fabric.Canvas | null;
}) {
  const { startPosition, endPosition, mediaURL, canvas } = arg;
  const objectsData = loadMap[mediaURL]?.objectsData;
  if (objectsData && canvas) {
    Object.keys(objectsData).forEach((key) => {
      const left = parseInt(key);
      const rect = objectsData[left];
      rect.set(
        "fill",
        left < endPosition && left > startPosition
          ? WAVEFORM_HIGHLIGHT_COLOR
          : WAVEFORM_COLOR
      );
    });
    canvas.renderAll();
  }
}

export type SelectionHandle = "start" | "end";

interface TimelineProps {
  /** URL of the media of which the audio waveform will be shown in the timeline. Can be audio/video. */
  mediaURL: string;
  /** Current time at which the media is at in milliseconds.  */
  currentTime: number;
  /** Time in ms where the media starts the selection */
  startTime: number;
  /** Time in ms where the media ends the selection */
  endTime: number;
  /** Current time setter function. Input time is in milliseconds. */
  setCurrentTime: (time: number) => void;
  /** Function to update either start time or end time. */
  updateStartAndEndTime: (timeObject: {
    startTime: number;
    endTime: number;
    selectionHandle: SelectionHandle;
  }) => void;
  /** Duration param is ms. */
  duration: number;
  /** Cutmagic data object. */
  cutmagicData?: Scene[];
  /** Modify cutmagic scences. */
  updateCutmagicScene?: (newSceneInfo: { time: number; index: number }) => void;
  /** start and end percentage of the chapter w.r.t whole duration of video */
  chapterPercentage: {
    start: number;
    end: number;
  };
  isIntelligentCut?: boolean;
}

/** Timeline component for the editor, provides seek, in and out points for the media. */
export const Timeline: React.FC<TimelineProps> = ({
  mediaURL: mediaURLRaw,
  startTime,
  endTime,
  currentTime,
  setCurrentTime,
  updateStartAndEndTime: updateStartAndEndTimeRaw,
  duration,
  cutmagicData,
  updateCutmagicScene,
  chapterPercentage,
  isIntelligentCut,
}) => {
  const [left, setLeft] = React.useState(0);
  const waveformFabricRef = React.useRef<fabric.Canvas | null>(null);
  const waveformWrapperRef = React.useRef<HTMLDivElement | null>(null);
  const [timelineCanvasContainer, { width: timelineCanvasContainerWidth }] =
    useElementSize();

  const [mouseInsideSelection, setMouseInsideSelection] = React.useState(false);
  const [waveFormStatus, setWaveFormStatus] = React.useState<
    STATUS | typeof PARTIALLY_GENERATED
  >(STATUS.IDLE);

  const isMarkerDragging = React.useRef(false);
  const cutmagicMarkersDraggingMap = React.useRef<null | Record<
    string,
    boolean
  >>(null);
  const [cutmagicMarkersPositionsMap, setCutmagicMarkerPositionsMap] =
    React.useState<Record<string, number>>({});
  const [selectionPosition, setSelectionPosition] = React.useState<{
    start: number;
    end: number;
  }>({ start: 0, end: 0 });
  const isStartHandleDragging = React.useRef(false);
  const url = new URL(mediaURLRaw);
  const mediaURL = url.origin + url.pathname;
  const isEndHandleDragging = React.useRef(false);
  const oneSecondInPixels = React.useRef(0);
  const updateStartAndEndTime = React.useRef(updateStartAndEndTimeRaw);

  const waveformCanvasRef = React.useCallback((element: HTMLCanvasElement) => {
    if (!element) {
      if (waveformFabricRef && waveformFabricRef.current) {
        waveformFabricRef.current.dispose();
      }
      waveformFabricRef.current = null;
      return waveformFabricRef;
    }

    waveformFabricRef.current = getNewFabricStaticCanvas(element);
    // @ts-expect-error - typescript is not able to infer the type of the canvas.
    waveformFabricRef.current?.set("height", TIMELINE_HEIGHT);
    return waveformFabricRef;
  }, []);

  const calculatePosition = React.useCallback(
    (time: number) => {
      let position = 0;
      if (duration) {
        position = time / 1000 / duration;
        const wrapperWidth =
          waveformWrapperRef.current?.getBoundingClientRect().width || 0;
        position = position * wrapperWidth;
      }
      return position;
    },
    [duration]
  );

  // Generate waveform on mediaURL change. This will draw the wave form as soon as the mediaURL is available.
  React.useEffect(() => {
    if (waveformFabricRef.current && waveFormStatus === STATUS.IDLE) {
      setWaveFormStatus(STATUS.LOADING);
      generateWaveForm({
        src: mediaURL,
        canvas: waveformFabricRef.current,
        cb: () => {
          // @ts-expect-error igore null check for now.
          setWaveFormStatus((prev: typeof waveFormStatus) =>
            // @ts-expect-error igore null check for now.
            prev === PARTIALLY_GENERATED ? STATUS.SUCCESS : PARTIALLY_GENERATED
          );
        },
        width: timelineCanvasContainerWidth,
        chapterPercentage,
      });
    }
  }, [
    mediaURL,
    waveFormStatus,
    timelineCanvasContainerWidth,
    JSON.stringify(chapterPercentage),
  ]);

  React.useEffect(() => {
    if (
      waveFormStatus === STATUS.SUCCESS ||
      waveFormStatus === PARTIALLY_GENERATED
    ) {
      updateWaveFormColor({
        startPosition: selectionPosition.start,
        endPosition: selectionPosition.end,
        mediaURL,
        canvas: waveformFabricRef.current,
      });
    }
  }, [
    JSON.stringify(selectionPosition),
    mediaURL,
    currentTime,
    waveFormStatus,
  ]);

  React.useEffect(() => {
    if (cutmagicData?.length) {
      cutmagicMarkersDraggingMap.current = cutmagicData?.reduce(
        (acc, curr): Record<string, boolean> => {
          acc[curr.id] = false;
          return acc;
        },
        {} as Record<string, boolean>
      );
      const positionMap = cutmagicData?.reduce(
        (acc: Record<string, number>, curr: Scene): Record<string, number> => {
          acc[curr.id] = calculatePosition(curr.start * 1000);
          return acc;
        },
        {}
      );
      setCutmagicMarkerPositionsMap(positionMap);
    }
  }, [JSON.stringify(cutmagicData)]);

  React.useEffect(() => {
    setSelectionPosition({
      start: calculatePosition(startTime),
      end: calculatePosition(endTime),
    });
  }, [startTime, endTime]);

  // this is to ensure the global canvas always has proper dimension
  React.useLayoutEffect(() => {
    waveformFabricRef.current?.setWidth(timelineCanvasContainerWidth);
  }, [timelineCanvasContainerWidth]);

  const cutmagicDataRef = React.useRef(cutmagicData);
  const setCurrentTimeRef = React.useRef(setCurrentTime);
  const cutmagicMarkersPositionsMapRef = React.useRef(
    cutmagicMarkersPositionsMap
  );
  const startTimeRef = React.useRef(startTime);
  const endTimeRef = React.useRef(endTime);
  const currentTimeRef = React.useRef(currentTime);
  const timelineCanvasContainerWidthRef = React.useRef(
    timelineCanvasContainerWidth
  );
  const updateCutmagicSceneRef = React.useRef(updateCutmagicScene);

  const selectedPosRef = React.useRef(selectionPosition);

  const handleCutmagicMarkerDrag = (e: MouseEvent) => {
    if (cutmagicMarkersDraggingMap.current) {
      Object.keys(cutmagicMarkersDraggingMap.current).forEach((key) => {
        if (cutmagicMarkersDraggingMap.current) {
          const isDragging = cutmagicMarkersDraggingMap.current[key];
          if (isDragging) {
            const clientX = e.clientX;
            const { x } =
              waveformWrapperRef.current?.getBoundingClientRect() || {
                x: 0,
              };

            const left = clientX - x;
            const index = cutmagicDataRef.current?.findIndex(
              (item) => item.id === key
            );
            const lastIndex = (cutmagicDataRef.current?.length || 0) - 1;
            if (!index) {
              return;
            }

            const lowerBound = calculatePosition(
              (cutmagicDataRef.current?.[index - 1]?.start || 0) * 1000
            );
            const upperBound =
              index === lastIndex
                ? selectedPosRef.current.end
                : calculatePosition(
                    (cutmagicDataRef.current?.[index + 1]?.start || 0) * 1000
                  );

            // checking if the value we are going to set is not lower than the previous item.
            if (
              left < lowerBound ||
              left > upperBound
              // || left > selectionPosition.end ||
              // left < selectionPosition.start
            ) {
              return;
            }

            setCutmagicMarkerPositionsMap((prev) => {
              return {
                ...prev,
                [key]: left,
              };
            });
          }
        }
      });
    }
  };

  const makeAllMarkersFalse = () => {
    cutmagicMarkersDraggingMap.current = Object.keys(
      cutmagicMarkersDraggingMap?.current || {}
    ).reduce((acc, curr) => {
      acc[curr] = false;
      return acc;
    }, {} as Record<string, boolean>);
    isStartHandleDragging.current = false;
    isEndHandleDragging.current = false;
    isMarkerDragging.current = false;
  };

  const setCutmagicMarkerPosition = () => {
    if (cutmagicMarkersDraggingMap.current) {
      Object.keys(cutmagicMarkersDraggingMap.current).forEach((key) => {
        if (cutmagicMarkersDraggingMap.current) {
          const isDragging = cutmagicMarkersDraggingMap.current[key];
          if (isDragging) {
            const index = cutmagicDataRef.current?.findIndex(
              (item) => item.id === key
            );
            if (index) {
              const time = calculateTime(
                cutmagicMarkersPositionsMapRef.current[key] || 0,
                timelineCanvasContainerWidthRef.current
              );
              updateCutmagicSceneRef.current?.({ time, index });
            }
          }
        }
      });
    }
  };

  // manages the cursor, start and end trim handles
  const handleStartAndEndMarkerDrag = (e: MouseEvent) => {
    const { x } = waveformWrapperRef.current?.getBoundingClientRect() || {
      x: 0,
    };
    const leftPosition = e.clientX - x;

    if (
      leftPosition > 0 &&
      leftPosition < timelineCanvasContainerWidthRef.current
    ) {
      setLeft(leftPosition);
      if (
        isMarkerDragging.current &&
        duration &&
        leftPosition < selectedPosRef.current.end &&
        leftPosition > selectedPosRef.current.start
      ) {
        // Calculate the new current time based on the position of the mouse.
        const percentage =
          leftPosition / timelineCanvasContainerWidthRef.current;
        const newCurrentTime = percentage * duration * 1000;
        setCurrentTimeRef.current(newCurrentTime);
      }
      if (
        isStartHandleDragging.current &&
        selectedPosRef.current.end - leftPosition > oneSecondInPixels.current
      ) {
        if (!isIntelligentCut) {
          setSelectionPosition((prev) => ({ ...prev, start: leftPosition }));
        }
      }
      if (
        isEndHandleDragging.current &&
        leftPosition - selectedPosRef.current.start > oneSecondInPixels.current
      ) {
        if (!isIntelligentCut) {
          setSelectionPosition((prev) => ({ ...prev, end: leftPosition }));
        }
      }
    }
  };

  const setStartAndEndMarkerPosition = () => {
    if (isStartHandleDragging.current || isEndHandleDragging.current) {
      const startTime = calculateTime(
        selectedPosRef.current.start,
        timelineCanvasContainerWidthRef.current
      );

      const endTime = calculateTime(
        selectedPosRef.current.end,
        timelineCanvasContainerWidthRef.current
      );

      if (isStartHandleDragging.current && currentTimeRef.current < startTime) {
        // adding 1 ms because it shouldn't exactly overlap with starttime
        setCurrentTimeRef.current(startTime + 1);
      }
      if (isEndHandleDragging.current && currentTimeRef.current > endTime) {
        // removing 1 ms because it shouldn't exactly overlap with endTime
        setCurrentTimeRef.current(endTime - 1);
      }

      updateStartAndEndTime.current?.({
        endTime,
        startTime,
        selectionHandle: isStartHandleDragging.current ? "start" : "end",
      });
    }
  };

  React.useEffect(() => {
    cutmagicDataRef.current = cutmagicData;
    cutmagicMarkersPositionsMapRef.current = cutmagicMarkersPositionsMap;
    startTimeRef.current = startTime;
    endTimeRef.current = endTime;
    timelineCanvasContainerWidthRef.current = timelineCanvasContainerWidth;
    selectedPosRef.current = selectionPosition;
    currentTimeRef.current = currentTime;
    updateCutmagicSceneRef.current = updateCutmagicScene;
    oneSecondInPixels.current = timelineCanvasContainerWidth / duration;
    setCurrentTimeRef.current = setCurrentTime;
    updateStartAndEndTime.current = updateStartAndEndTimeRaw;
  }, [
    JSON.stringify(cutmagicData),
    JSON.stringify(cutmagicMarkersPositionsMap),
    JSON.stringify(selectionPosition),
    startTime,
    endTime,
    timelineCanvasContainerWidth,
    currentTime,
    updateCutmagicScene,
    duration,
    setCurrentTime,
    updateStartAndEndTimeRaw,
  ]);

  // Event listeners so that the dragging of markers can work properly.
  React.useEffect(() => {
    const handleMouseUp = () => {
      setCutmagicMarkerPosition();
      setStartAndEndMarkerPosition();
      makeAllMarkersFalse();
    };

    const handleMouseMove = (e: MouseEvent) => {
      handleStartAndEndMarkerDrag(e);
      handleCutmagicMarkerDrag(e);
    };

    window.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const booleanRefToggle =
    (ref: React.MutableRefObject<boolean>, value: boolean) => () =>
      (ref.current = value);

  const calculateTime = React.useCallback(
    (position: number, timelineWidth: number) => {
      let time = 0;
      if (duration) {
        time = (position / timelineWidth) * duration * 1000;
      }
      return time;
    },
    [duration]
  );

  function formatMilliseconds(milliseconds: number) {
    const hours = Math.floor(milliseconds / 3600000);
    const minutes = Math.floor((milliseconds % 3600000) / 60000);
    const seconds = Math.floor((milliseconds % 60000) / 1000);
    const millis = parseInt(`${milliseconds % 1000}`);

    let formattedTime = "";

    if (hours > 0) {
      formattedTime += (hours < 10 ? "0" : "") + hours + ":";
    }

    if (minutes > 0) {
      formattedTime += (minutes < 10 ? "0" : "") + minutes + ":";
    }
    formattedTime += (seconds < 10 ? "0" : "") + seconds + ".";
    formattedTime += (millis < 100 ? (millis < 10 ? "00" : "0") : "") + millis;
    return formattedTime;
  }

  const handleInAndOutClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const { x } = waveformWrapperRef.current?.getBoundingClientRect() || {
      x: 0,
    };
    const leftPosition = e.clientX - x;
    if (
      leftPosition > 0 &&
      leftPosition < timelineCanvasContainerWidth &&
      !isStartHandleDragging.current &&
      !isEndHandleDragging.current &&
      duration
    ) {
      const percentage = leftPosition / timelineCanvasContainerWidth;
      const newCurrentTime = percentage * duration * 1000;
      setCurrentTimeRef.current(newCurrentTime);
    }
  };

  const hoverTime =
    calculateTime(left, timelineCanvasContainerWidthRef.current) - startTime;

  const waveformRendered =
    waveFormStatus === STATUS.SUCCESS || waveFormStatus === PARTIALLY_GENERATED;

  const GhostLoader = (
    <div
      className={clsx(
        waveformRendered && "hidden",
        "skeleton",
        "waveform-loader",
        "rounded-sm"
      )}
    >
      {Array.from(
        { length: timelineCanvasContainerWidth / 4 },
        (_, k) => k
      ).map((k) => (
        <div
          key={k}
          className="bar"
        ></div>
      ))}
    </div>
  );

  const cutmagicSliders = cutmagicData
    ?.map((sceneData) => ({
      ...sceneData,
      start: sceneData.start * 1000,
    }))
    .map((sceneData, index) => {
      if (sceneData.start >= startTime && sceneData.start < endTime) {
        const cutmagicSliderLeft = cutmagicMarkersPositionsMap[sceneData.id];
        const TriangleIcon = sceneData.isTwoFace
          ? InvertedHalfTriangleIcon
          : InvertedTriangleIcon;

        return (
          <div
            style={
              {
                "--cutmagic-slider-left": `${cutmagicSliderLeft}px`,
                "--cutmagic-slider-color": `${sceneData.color}`,
                cursor: index === 0 ? "not-allowed" : "ew-resize",
              } as React.CSSProperties
            }
            key={sceneData.id}
            className="cutmagic-slider"
          >
            <button
              onMouseDown={() => {
                if (index !== 0 && cutmagicMarkersDraggingMap.current) {
                  cutmagicMarkersDraggingMap.current[sceneData.id] = true;
                }
              }}
            >
              <TriangleIcon
                height="10"
                width="10"
                fillColor={sceneData.color}
              />
            </button>
          </div>
        );
      }
    });

  return (
    <div
      style={
        {
          "--timeline-height": `${TIMELINE_HEIGHT}px`,
          background: "#F7F7F7",
        } as React.CSSProperties
      }
      className={`mx-auto rounded-md`}
    >
      <div
        ref={waveformWrapperRef}
        className={clsx(
          "relative",
          "wrapper",
          mouseInsideSelection && "mouseInsideSelection"
        )}
      >
        {hoverTime >= 0 && (
          <div
            id="time-tooltip"
            className="time-tooltip drop-shadow-sm"
            style={
              { "--time-tooltip-left": `${left}px` } as React.CSSProperties
            }
          >
            {formatMilliseconds(hoverTime)}
          </div>
        )}
        <div
          id="cursor"
          className="absolute z-50 c-pointer bg-slate-800"
          style={{
            height: "100%",
            width: "2px",
            top: 0,
            left,
          }}
        />
        <div
          id="marker"
          onMouseDown={booleanRefToggle(isMarkerDragging, true)}
          onMouseUp={booleanRefToggle(isMarkerDragging, false)}
          className="marker absolute cursor-ew-resize z-10 "
          style={
            {
              "--left-position": `${calculatePosition(
                currentTimeRef.current
              )}px`,
              "--marker-width": `${MARKER_WIDTH}px`,
            } as React.CSSProperties
          }
        />
        {cutmagicSliders}
        <div
          id="in-and-out"
          onClick={handleInAndOutClick}
          onMouseEnter={() => {
            setMouseInsideSelection(true);
          }}
          onMouseLeave={() => {
            setMouseInsideSelection(false);
          }}
          className={clsx(
            "absolute z-50 flex rounded-lg border-2 box-border in-and-out",
            isIntelligentCut && "remove-pointer-events"
          )}
          style={{
            height: "100%",
            width: `${
              selectionPosition.end - selectionPosition.start + MARKER_WIDTH
            }px`,
            top: 0,
            left: selectionPosition.start,
          }}
        >
          <div
            id="in"
            onMouseDown={booleanRefToggle(isStartHandleDragging, true)}
            className="start-handle"
          />
          <div
            id="body"
            className="flex-1"
            style={{ height: "100%", background: "transparent" }}
          />
          <div
            id="out"
            onMouseDown={booleanRefToggle(isEndHandleDragging, true)}
            className="end-handle"
          />
        </div>

        <div ref={timelineCanvasContainer}>
          <canvas
            className={clsx("z-40", !waveformRendered && "hidden")}
            height={TIMELINE_HEIGHT}
            id="waveform"
            ref={waveformCanvasRef}
          />
          {GhostLoader}
        </div>

        <div
          id="in-and-out-shadow"
          className="absolute bg-blue-300 opacity-50 rounded-md"
          style={{
            height: "100%",
            width: `${
              selectionPosition.end - selectionPosition.start + MARKER_WIDTH
            }px`,
            top: 0,
            left: selectionPosition.start,
          }}
        />
      </div>
    </div>
  );
};
