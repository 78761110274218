import { useState } from "react";
import "react-multi-carousel/lib/styles.css";
import { useLocation } from "react-use";

import {
  browserLocalPersistence,
  setPersistence,
  signInWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { Button, Label, Spinner, TextInput } from "flowbite-react";

import { login } from "@/store/authSlice";
import { useAppDispatch } from "@/store/hooks";

import api from "@/api/api";
import { ApiEndpoints } from "@/api/constants/ApiEndPoints";

import { LOGIN } from "@/constants";

import {
  getValueFromLocalStorage,
  removeValueFromLocalStorage,
} from "@/helpers";

import { trackSigninEvent, trackSignupEvent } from "@/utils/amplitudeAnalytcs";
import { notificationType } from "@/utils/constants";
import { auth } from "@/utils/firebase";
import { showNotification } from "@/utils/showNotification";

import HelperComponent from "@/views/auth/HelperComponent";
import CustomEmailModal from "@/views/auth/social/CustomEmailModal";
import FacebookAuth from "@/views/auth/social/FacebookAuth";
import GoogleAuth from "@/views/auth/social/GoogleAuth";
import MicrosoftAuth from "@/views/auth/social/MicrosoftAuth";
import { validateEmail, validatePassword } from "@/views/auth/validator";

const FORGOT_PASSWORD = "FORGOT_PASSWORD";
const HELPER_COMPONENT = "HELPER_COMPONENT";

const Login = ({
  updateCurrentTab,
  showHelperComponent,
  setShowHelperComponent,
  updateHelperComponentDetails,
}: {
  updateCurrentTab: (value: string) => void;
  showHelperComponent: boolean;
  setShowHelperComponent: (value: boolean) => void;
  updateHelperComponentDetails: (headline: string, message: string) => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentSingInMethod, setCurrentSingInMethod] = useState("");

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState({
    password: false,
    errorMessage: "",
  });

  const userEmailId = getValueFromLocalStorage("userEmail");
  const [email, setEmail] = useState(userEmailId || "");
  const [firebaseUserData, setFirebaseUserData] = useState<any>();
  const [displayModal, setDisplayModal] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const location = useLocation();
  const user = auth.currentUser;

  const actionCodeSettings = {
    url: `${window.location.origin}/auth/login?isNewUser=true`,
    handleCodeInApp: true,
  };

  const isNewUser = new URLSearchParams(location.search).get("isNewUser");

  const checkMainDBLogin = async (firestoreUserData: any) => {
    const userParam = { user_id: firestoreUserData?.uid };

    try {
      await api.get(ApiEndpoints.USERS, { params: userParam });
      dispatch(login(firestoreUserData));
      setIsLoading(false);
      trackSigninEvent(firestoreUserData);
    } catch (error: any) {
      if (error?.response?.data?.detail === "user not found in vidyo db") {
        createMainDBLogin(firestoreUserData);
      } else {
        setIsLoading(false);
        showNotification(
          "Error while logging in. Please try again.",
          notificationType.FAIL
        );
      }
    }
  };

  const createMainDBLogin = async (firestoreUserData: any) => {
    const userData = {
      id: firestoreUserData?.uid,
      email: firestoreUserData?.email,
      displayName: firestoreUserData?.displayName,
      phoneNumber: firestoreUserData?.phoneNumber,
    };

    try {
      await api.post(ApiEndpoints.USER_CREATE, userData);
      dispatch(login(firestoreUserData));
      setIsLoading(false);
      trackSignupEvent(firestoreUserData);
    } catch {
      showNotification("Error while creating User", notificationType.FAIL);
      setIsLoading(false);
    }
  };

  const handelSubmitForm = (e: any) => {
    e.preventDefault();
    setShowHelperComponent(false);

    if (
      validateEmail(e.target.email.value, setEmailError) &&
      validatePassword(e.target.password.value, setPasswordError)
    ) {
      setIsLoading(true);
      setPersistence(auth, browserLocalPersistence)
        .then(() => {
          signInWithEmailAndPassword(
            auth,
            e.target.email.value,
            e.target.password.value
          )
            .then((userCredential) => {
              setIsLoading(false);
              setFirebaseUserData(userCredential.user);
              if (!userCredential.user.emailVerified) {
                setShowHelperComponent(true);
              } else {
                removeValueFromLocalStorage("userEmail");
                dispatch(
                  login({
                    ...userCredential.user,
                    showOnBoarding: isNewUser === "true" ? true : false,
                  })
                );

                checkMainDBLogin({
                  ...userCredential.user,
                  showOnBoarding: isNewUser === "true" ? true : false,
                });
              }
            })
            .catch((error) => {
              setIsLoading(false);

              switch (error.code) {
                case "auth/user-not-found":
                  showNotification(
                    "Email not found, please signup first",
                    notificationType.FAIL
                  );
                  break;
                case "auth/wrong-password":
                  showNotification("Incorrect password", notificationType.FAIL);
                  break;

                default:
                  showNotification(
                    "Error while logging in. Please try again.",
                    notificationType.FAIL
                  );
              }
            });
        })
        .catch((error) => {
          setIsLoading(false);
          const errorCode = error.code;
          const errorMessage = error.message;
          showNotification(errorMessage, notificationType.FAIL);
        });
    } else {
    }
  };

  const handlePasswordChangeValidation = (e: any) => {
    const password = e.target.value;
    if (!password) {
      if (e.target.name === "password") {
        setPasswordError({ ...passwordError, password: true });
      }
    } else {
      setPasswordError({ ...passwordError, password: false });
    }
  };

  const handleEmailChangeValidation = (e: any) => {
    setEmail(e.target.value);
    validateEmail(e.target.value, setEmailError);
  };

  const getEmailVerificationLink = () => {
    setIsLoading(true);
    sendEmailVerification(firebaseUserData, actionCodeSettings)
      .then(() => {
        setIsLoading(false);
        updateHelperComponentDetails(
          "Please confirm your email! ✅",
          "Please click on the link of the email we have sent to you to confirm your account and start using audyo ✨"
        );
        updateCurrentTab(HELPER_COMPONENT);
      })
      .catch((error) => {
        setIsLoading(false);
        showNotification(
          "Please wait for sometime, and try again",
          notificationType.FAIL
        );
        updateCurrentTab(LOGIN);
      });
  };

  return (
    <>
      {displayModal && (
        <CustomEmailModal
          user={user}
          firebaseUserData={firebaseUserData}
          setDisplayModal={setDisplayModal}
          displayModal={displayModal}
        />
      )}

      <div>
        {showHelperComponent ? (
          <HelperComponent
            headline="Email not verified"
            message="Please verifiy your email by clicking the link provided in email"
            updateCurrentTab={updateCurrentTab}
            setShowHelperComponent={setShowHelperComponent}
            passwordReset={true}
            optionalButton={
              <Button
                color="light"
                className=" mt-4 w-full rounded-lg border border-blue-500 bg-white text-center text-sm font-medium text-blue-500 hover:bg-blue-50  focus:outline-none focus:ring-4 focus:ring-blue-300"
                onClick={getEmailVerificationLink}
                id="resend-email-verification-btn"
              >
                {isLoading ? (
                  <Spinner className="h-4 w-4" />
                ) : (
                  <span>Didn't get the link?</span>
                )}
              </Button>
            }
          />
        ) : (
          <div>
            <h1 className="text-lg xl:text-2xl font-semibold text-gray-700 dark:text-white pt-7 py-4">
              Sign in to your account
            </h1>

            <div className="">
              <div className="items-center space-x-0 space-y-3 md:flex md:space-x-2 md:space-y-0">
                <GoogleAuth
                  setDisplayModal={setDisplayModal}
                  setFirebaseUserData={setFirebaseUserData}
                />
                <FacebookAuth
                  setDisplayModal={setDisplayModal}
                  setFirebaseUserData={setFirebaseUserData}
                />
                <MicrosoftAuth
                  setDisplayModal={setDisplayModal}
                  setFirebaseUserData={setFirebaseUserData}
                />
              </div>
            </div>

            <div className="flex items-center xl:py-8 py-4">
              <div className="w-full h-0.5 bg-gray-200 dark:bg-gray-700"></div>
              <div className="px-5 text-center text-gray-500 dark:text-gray-400">
                or
              </div>
              <div className="w-full h-0.5 bg-gray-200 dark:bg-gray-700"></div>
            </div>

            <form onSubmit={handelSubmitForm}>
              <div className="flex flex-col xl:gap-5 xl:mb-7 gap-3 mb-5">
                <div className="">
                  <Label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Email
                  </Label>
                  <TextInput
                    type="email"
                    name="email"
                    id="email"
                    onChange={handleEmailChangeValidation}
                    className=" text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    placeholder="Enter your email"
                    value={email}
                    required={true}
                    color={`${emailError && "failure"}`}
                    helperText={
                      <span style={{ marginTop: "-10px" }}>
                        {emailError && <>{emailError}</>}
                      </span>
                    }
                  />
                </div>

                <div className="">
                  <Label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Password
                  </Label>

                  <div className="w-full relative">
                    <TextInput
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Enter your password"
                      onChange={handlePasswordChangeValidation}
                      className=" text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      required={true}
                      color={`${passwordError.password && "failure"}`}
                      helperText={
                        <span style={{ marginTop: "-10px" }}>
                          {passwordError.password && <>*Required</>}
                        </span>
                      }
                    />
                  </div>
                </div>
              </div>

              <Button
                type={isLoading ? "button" : "submit"}
                disabled={isLoading}
                id="sign-in-button"
                className={`w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 mt-5 ${
                  isLoading ? "cursor-wait opacity-70" : ""
                }`}
              >
                {isLoading ? (
                  <Spinner className="h-4 w-4" />
                ) : (
                  <span>Sign in</span>
                )}
              </Button>
            </form>

            <div className="flex xl:py-6 py-4">
              <p
                className="text-left font-lighttext-xs w-2/3 text-xs"
                style={{ color: "#1C64F2" }}
              >
                <span
                  onClick={() => updateCurrentTab(FORGOT_PASSWORD)}
                  className=" cursor-pointer hover:underline"
                >
                  Forgotten password?
                </span>
              </p>
              <p
                className="text-right text-xs w-1/3 underline cursor-pointer"
                style={{ color: "#1C64F2" }}
                onClick={() => updateCurrentTab("")}
              >
                Sign up instead
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Login;
