import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "@/api/api";
import { ApiEndpoints } from "@/api/constants/ApiEndPoints";

const retryVideo = async (body: any) => {
  const { data } = await api.post(ApiEndpoints.RETRY_PROJECT, body);
  return data;
};

export default function useRetryProject() {
  const queryClient = useQueryClient();

  return useMutation((body: any) => retryVideo(body), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["all-projects"] });
    },
  });
}
