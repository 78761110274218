import { useQuery } from "@tanstack/react-query";

import { useAppSelector } from "@/store/hooks";

export default function useGetAllProjects(
  queryFn: () => any,
  pageLimit: number,
  pageOffset: number,
  count: number,
  staleTime = 300000,
  isOnBoardingPage?: boolean
) {
  const currentUser = useAppSelector((state) => state.authState.currentUser);

  return useQuery({
    queryKey: ["all-projects", currentUser.uid, pageLimit, pageOffset, count],
    queryFn,
    refetchInterval: staleTime,
    staleTime: staleTime,
    enabled: !isOnBoardingPage,
  });
}
