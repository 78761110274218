import clsx from "clsx";

export const OnBoardingRadio = ({
  id,
  label,
  value,
  className,
  onChange,
  isSelected,
  isDisabled = false,
}: {
  id: string;
  label: string;
  value: string;
  className?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isSelected?: boolean;
  isDisabled?: boolean;
}) => {
  return (
    <div
      className={clsx(
        className,
        isDisabled && !isSelected && "cursor-not-allowed opacity-50",
        isSelected && "border-blue-500",
        "mb-4 flex items-center rounded-md border-2 border-transparent bg-white shadow-[0_0px_8px_0_rgba(0,0,0,0.1)] transition-colors hover:bg-gray-50"
      )}
    >
      <input
        id={id}
        type="radio"
        value={value}
        name="default-radio"
        className="ml-3 h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600 sm:h-6 sm:w-6"
        onChange={onChange}
        checked={isSelected}
        disabled={isDisabled}
      />
      <label
        htmlFor={id}
        className="w-full py-2.5  pl-2 text-left text-xs font-semibold text-[#1E1E1E] dark:text-gray-300 sm:pl-3 lg:py-3.5 lg:text-sm"
      >
        {label}
      </label>
    </div>
  );
};

export const OnBoardingCheckbox = ({
  id,
  label,
  value,
  className,
  onChange,
  isSelected,
}: {
  id: string;
  label: string;
  value: string;
  className?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isSelected?: boolean;
}) => {
  return (
    <div
      className={clsx(
        className,
        isSelected && "border-blue-500",
        "mb-4 flex items-center rounded-md  border-2 border-transparent bg-white shadow-[0_0px_8px_0_rgba(0,0,0,0.1)] transition-colors hover:bg-gray-50"
      )}
    >
      <input
        id={id}
        type="checkbox"
        value={value}
        name="default-checkbox"
        className="ml-3 h-4 w-4 rounded-md border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600 sm:h-6 sm:w-6"
        onChange={onChange}
        checked={isSelected}
      />
      <label
        htmlFor={id}
        className="w-full py-2.5 pl-2 text-left text-xs font-semibold text-[#1E1E1E] dark:text-gray-300 sm:pl-3 lg:py-3 lg:text-sm"
      >
        {label}
      </label>
    </div>
  );
};
