import { toggleUpgradeToProModal } from "@/store/homeSlice";
import { useAppDispatch } from "@/store/hooks";

import {
  ANALYTICS_CONSTANTS,
  eventsDataToRedux,
} from "@/utils/amplitudeAnalytcs";

import assetLimitReachedImg from "@/assets/images/asset-limit-reached.svg";

const AssetLimitReached = () => {
  const dispatch = useAppDispatch();

  return (
    <div className="col-span-2  px-6 text-center text-sm text-[#5E6473]">
      <img
        src={assetLimitReachedImg}
        className="mx-auto w-48"
      />

      <p className="mx-auto mt-4 mb-6 w-96">
        <span className="font-semibold">Free account</span> allows you to save
        up to 3 brand assets.
      </p>

      {/*
      <p className=" mt-4 mb-1">
        Click on the <span className="font-semibold">“Save Template”</span>{" "}
        button in the editor to save a template and then view it here.
      </p> */}

      <p className="mb-4">
        Please upgrade to <span className="font-semibold">PRO</span> to save
        more
      </p>

      <button
        type="button"
        id="upgrade-now-templates-tab"
        className={`my-6 mx-auto flex w-40 items-center justify-center rounded-lg bg-blue-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300`}
        onClick={() => {
          dispatch(toggleUpgradeToProModal(true));
          eventsDataToRedux(ANALYTICS_CONSTANTS.PAYMENT_SCREEN_NAME);
        }}
      >
        Upgrade now
      </button>
    </div>
  );
};

export default AssetLimitReached;
