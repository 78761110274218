import React, { Suspense } from "react";

import App from "@/App";
import MobileApp from "@/MobileApp";

import { isMobileDevice } from "@/helpers";

// import LogoSpinner from "@/components/Loader/LogoSpinner";

// const App = React.lazy(() => import("./App"));

const Root = () => {
  return (
    <>
      {isMobileDevice() ? (
        <MobileApp />
      ) : (
        // <Suspense fallback={<LogoSpinner />}>
        <App />
        // </Suspense>
      )}
    </>
  );
};

export default Root;
