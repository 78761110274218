import { useNavigate } from "react-router-dom";

import { updateCurrentSelectedMicroContent } from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import useDeleteAllDraftByUserId from "@/api/useDeleteAllDraftByUserId";
import useDeleteDraftById from "@/api/useDeleteDraftById";

import { applyCurrentDraft } from "@/helpers/draft";

import { trackEditClipEvent } from "@/utils/amplitudeAnalytcs";

import { EditorDraft } from "@/interfaces";

import { PlanType, ScreenName } from "@/enums";

import DraftCard from "@/views/home/components/DraftCard/DraftCard";
import NoDrafts from "@/views/home/components/NoDrafts";

type DraftsProps = {
  drafts: EditorDraft[];
};

const Drafts = ({ drafts }: DraftsProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const uid = useAppSelector((state) => state.authState.currentUser.uid);

  const planType = useAppSelector(
    (state) => state.authState.userSubscription.planType
  );

  const { mutate: deleteDraft, isLoading: isDraftDeleteMutationLoading } =
    useDeleteDraftById();
  const {
    mutate: deleteAllDrafts,
    isLoading: isAllDraftDeleteMutationLoading,
  } = useDeleteAllDraftByUserId();

  const handelOpenPreviewClip = (draftData: any) => {
    const templateData = {
      id: draftData.id,
      face_coord: draftData.face_coord || [],
      start: draftData.start,
      end: draftData.end,
      srt_string: draftData.srt_string,
      imageUrl: draftData.imageUrl,
      hasTwoFace: draftData.hasTwoFace,
      gist: draftData.gist || "",
      text: draftData.text,
      chapter_end: draftData.chapter_end,
      chapter_start: draftData.chapter_start,
      backgroundColor: draftData?.backgroundColor,
      clipId: draftData?.clipId,
      tag: draftData?.tag,
      clip_src: draftData?.clip_src,
    };

    dispatch(updateCurrentSelectedMicroContent(templateData));

    applyCurrentDraft({ ...draftData, fromDownloadPage: false });

    trackEditClipEvent(draftData, ScreenName.DRAFTS);

    navigate(`/editor/${draftData.project.id}/templates`);
  };

  const handleDeleteDraft = (draftData: any) => {
    // draft Id = user1/project1

    if (planType === PlanType.FREE) {
      deleteAllDrafts(uid);
    } else {
      deleteDraft({
        draftId: draftData.id,
        docId: `${uid}/${draftData.project.id}`,
      });
    }
  };

  return (
    <div className="relative w-full h-[calc(100vh_-_16.5rem)] overflow-y-auto">
      {drafts?.length > 0 ? (
        <ul
          role="list"
          className={`px-4 grid grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-8 min-[1200px]:grid-cols-4 sm:gap-x-6 xl:grid-cols-4 draft-page-large:grid-cols-5 xl:gap-x-8 my-4`}
        >
          {drafts.map((draft) => {
            return (
              <DraftCard
                key={draft.id}
                draft={draft}
                handelOpenPreviewClip={handelOpenPreviewClip}
                handleDeleteDraft={handleDeleteDraft}
                isDraftDeleteMutationLoading={
                  isDraftDeleteMutationLoading ||
                  isAllDraftDeleteMutationLoading
                }
              />
            );
          })}
        </ul>
      ) : (
        <NoDrafts />
      )}
    </div>
  );
};

export default Drafts;
