import { useEffect, useState } from "react";

import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Badge, Button, Card, Spinner } from "flowbite-react";

import { useAppSelector } from "@/store/hooks";

import { updateSubscription } from "@/api/requests";

import { USER_PAYMENT_CONSTANTS } from "@/constants/segment-analytics";

import {
  getPaddlePriceByPlanId,
  getPaddlePriceDetailsByPlanTypeAndInterval,
  getPlanTypeByPlanId,
  getProductIdByPlanTypeAndDuration,
  planIdInterval,
} from "@/helpers";

import { trackUserSubscription } from "@/utils/SegmentAnalytics";
import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

import { PlanType } from "@/enums";

const MAIN = "MAIN";
const MONTHLY = "monthly";
const ANNUAL = "yearly";

const UPGRADE_MODAL = "UPGRADE_MODAL";
const UPGRADE_CONFIRM = "UPGRADE_CONFIRM";

export const UpgradeSubscription = ({
  setCurrentComponent,
}: {
  setCurrentComponent: (value: string) => void;
}) => {
  const [planInterval, setPlanInterval] = useState<string>(MONTHLY);
  const [currentPlan, setCurrentPlan] = useState(PlanType.PRO_PLUS);
  const [currentModalVisible, setCurrentModalVisible] =
    useState(UPGRADE_CONFIRM);
  const [isLoading, setIsLoading] = useState(false);

  const { plan_id, subscription_id } = useAppSelector(
    (state) => state.authState.paddleSubscription
  );

  const currentPriceLabel = getPaddlePriceByPlanId(plan_id);

  useEffect(() => {
    setCurrentModalVisible(UPGRADE_MODAL);
    if (plan_id) {
      getPlanTypeByPlanId(plan_id) === PlanType.PRO_PLUS
        ? setCurrentPlan(PlanType.PRO)
        : setCurrentPlan(PlanType.PRO_PLUS);
    }
    setPlanInterval(planIdInterval[plan_id]);
  }, []);

  const monthlyPlan = () => {
    setPlanInterval(MONTHLY);
  };

  const annualPlan = () => {
    setPlanInterval(ANNUAL);
  };

  const confirmPayment = async () => {
    setIsLoading(true);
    try {
      const response: any = await updateSubscription({
        subscription_id,
        plan_id: getProductIdByPlanTypeAndDuration(currentPlan, isAnnual),
        bill_immediately: true,
      });
      if (response.success) {
        setIsLoading(false);
        setCurrentModalVisible(UPGRADE_CONFIRM);
        // plan upgrade/downgrade event
        trackUserSubscription(
          USER_PAYMENT_CONSTANTS.PLAN_MODIFIED,
          currentPlan,
          planIdInterval[plan_id]
        );
      } else {
        throw new Error();
      }
    } catch (error) {
      showNotification(
        "Failed to upgrade plan, please try again later or contact support.",
        notificationType.FAIL
      );
    }
    setIsLoading(false);
  };

  const isAnnual = planInterval === ANNUAL;

  const isCurrentPlan = (plan: PlanType) => {
    return getProductIdByPlanTypeAndDuration(plan, isAnnual) === plan_id;
  };

  const getPrice = (planType: PlanType) =>
    isCurrentPlan(planType)
      ? currentPriceLabel
      : getPaddlePriceDetailsByPlanTypeAndInterval(planType, isAnnual);

  return (
    <div className="flex flex-col space-y-9 h-full">
      {currentModalVisible === UPGRADE_CONFIRM && (
        <div className="flex justify-center items-center mt-12 bg-green-50 rounded-lg h-full">
          <div className="flex flex-col text-center gap-4 items-center">
            <CheckCircleIcon
              className="mx-auto text-green-500"
              width={100}
            />
            <div className="text-3xl font-semibold">
              Plan Change Successful!
            </div>
            <div className="text-xl">
              Enjoy your new plan with added benefits
            </div>
            <Button
              className="w-28"
              onClick={() => setCurrentComponent(MAIN)}
            >
              Okay 👌
            </Button>
          </div>
        </div>
      )}
      {currentModalVisible === UPGRADE_MODAL && (
        <>
          <div className="relative">
            <div
              className="absolute flex px-4 py-2.5 border rounded-lg cursor-pointer"
              onClick={() => setCurrentComponent(MAIN)}
            >
              <ChevronLeftIcon
                className="mt-[3px] mr-1"
                width={20}
                height={20}
              />
              Back
            </div>
            <div className="text-3xl font-semibold flex justify-center items-center">
              Change Your Plan
            </div>
          </div>

          <div className="flex-col space-y-6">
            <div className="flex justify-center items-center">
              <Button.Group>
                <Button
                  color={`${planInterval === MONTHLY ? "info" : "gray"}`}
                  onClick={monthlyPlan}
                >
                  Monthly
                </Button>
                <Button
                  color={`${planInterval === ANNUAL ? "info" : "gray"}`}
                  onClick={annualPlan}
                >
                  Annual
                </Button>
              </Button.Group>
            </div>
            <div className="bg-[#F3F4F6] h-[310px] rounded-lg flex justify-center items-center space-x-7">
              <Card
                className={`w-[258px] h-[258px] ${
                  currentPlan === PlanType.STARTER
                    ? "!border-blue-600"
                    : "!border-transparent"
                }`}
              >
                <div className="h-full w-full flex-col space-y-5">
                  <div className="flex space-x-4 items-center">
                    <p className="text-lg font-semibold">STARTER</p>
                    {isCurrentPlan(PlanType.STARTER) && (
                      <Badge>Current Plan</Badge>
                    )}
                  </div>
                  <div className="text-sm font-light text-[#6B7280]">
                    <p>
                      {isAnnual ? 1800 : 150} mins per{" "}
                      {isAnnual ? "year" : "month"}
                    </p>
                  </div>
                  <div>
                    <p className="text-2xl font-semibold">
                      {/* {getPaddlePriceDetailsByPlanTypeAndInterval(
                        PlanType.STARTER,
                        isAnnual
                      )} */}
                      {/* {currentPriceLabel} */}
                      {getPrice(PlanType.STARTER)}
                    </p>
                    <p>per {isAnnual ? "year" : "month"}</p>
                  </div>
                  {currentPlan !== PlanType.STARTER && (
                    <div>
                      <Button
                        outline={true}
                        color="dark"
                        onClick={() => setCurrentPlan(PlanType.STARTER)}
                      >
                        Select this plan
                      </Button>
                    </div>
                  )}
                </div>
              </Card>
              <Card
                className={`w-[258px] h-[258px] ${
                  currentPlan === PlanType.PRO
                    ? "!border-blue-600"
                    : "!border-transparent"
                }`}
              >
                <div className="h-full w-full flex-col space-y-5">
                  <div className="flex space-x-4 items-center">
                    <p className="text-lg font-semibold">PRO</p>
                    {isCurrentPlan(PlanType.PRO) && <Badge>Current Plan</Badge>}
                  </div>
                  <div className="text-sm font-light text-[#6B7280]">
                    <p>
                      {isAnnual ? 3600 : 300} mins per{" "}
                      {isAnnual ? "year" : "month"}
                    </p>
                  </div>
                  <div>
                    <p className="text-2xl font-semibold">
                      {/* {getPaddlePriceDetailsByPlanTypeAndInterval(
                        PlanType.PRO,
                        isAnnual
                      )} */}
                      {/* {currentPriceLabel} */}
                      {getPrice(PlanType.PRO)}
                    </p>
                    <p>per {isAnnual ? "year" : "month"}</p>
                  </div>
                  {currentPlan !== PlanType.PRO && (
                    <div>
                      <Button
                        outline={true}
                        color="dark"
                        onClick={() => setCurrentPlan(PlanType.PRO)}
                      >
                        Select this plan
                      </Button>
                    </div>
                  )}
                </div>
              </Card>
              <Card
                className={`w-[258px] h-[258px] ${
                  currentPlan === PlanType.PRO_PLUS
                    ? "!border-blue-600"
                    : "!border-transparent"
                }`}
              >
                <div className="h-full w-full flex-col space-y-4">
                  <div className="flex space-x-4 items-center">
                    <p className="text-lg font-semibold">PRO+</p>
                    {isCurrentPlan(PlanType.PRO_PLUS) && (
                      <Badge>Current Plan</Badge>
                    )}
                  </div>
                  <div className="text-sm font-light text-[#6B7280]">
                    <p>
                      {isAnnual ? 6000 : 500} mins per{" "}
                      {isAnnual ? "year" : "month"}
                    </p>
                    <p>Priority Support</p>
                  </div>
                  <div>
                    <p className="text-2xl font-semibold">
                      {/* {getPaddlePriceDetailsByPlanTypeAndInterval(
                        PlanType.PRO_PLUS,
                        isAnnual
                      )} */}
                      {/* {currentPriceLabel} */}
                      {getPrice(PlanType.PRO_PLUS)}
                    </p>
                    <p>per {isAnnual ? "year" : "month"}</p>
                  </div>
                  {currentPlan !== PlanType.PRO_PLUS && (
                    <div>
                      <Button
                        outline={true}
                        color="dark"
                        onClick={() => setCurrentPlan(PlanType.PRO_PLUS)}
                      >
                        Select this plan
                      </Button>
                    </div>
                  )}
                </div>
              </Card>
            </div>
            <div className="flex p-2 justify-between px-12">
              <div className="flex space-x-20 justify-start">
                <div className="flex-col space-y-3">
                  <div className="text-lg">
                    Amount charged at next billing cycle
                  </div>
                  {/* <div className="font-semibold">What you will pay today</div> */}
                  <div className="text-xs font-light">
                    Any difference in charges resulting from a plan change will
                    be prorated
                  </div>
                </div>
                <div className="flex-col space-y-3">
                  <div className="text-lg">
                    {getPaddlePriceDetailsByPlanTypeAndInterval(
                      currentPlan,
                      isAnnual
                    )}{" "}
                    per {isAnnual ? "year" : "month"}
                  </div>
                  {/* <div className="font-bold">$15.67</div> */}
                </div>
              </div>
              <div className="flex-col justify-end items-center space-y-2">
                <Button
                  className="w-60 select-none"
                  onClick={confirmPayment}
                  disabled={isCurrentPlan(currentPlan) || isLoading}
                >
                  {isLoading && <Spinner className="mr-2 h-4 w-4" />}
                  Confirm Plan Change
                </Button>
                {/* <div className="text-xs font-light text-[#6B7280]">
                  Your payment method will be charged $15.67
                </div> */}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
