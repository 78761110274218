import { UseQueryResult, useQuery } from "@tanstack/react-query";

import api from "@/api/api";
import { ApiEndpoints } from "@/api/constants/ApiEndPoints";

function parseClips(data: any): any[] {
  const clips: any = [];

  Object.entries(data).forEach(function ([key, value]) {
    clips.push({
      id: key,
      // @ts-ignore
      rendered_video_url: value?.rendered_video_url || "",
      // @ts-ignore
      filename: value.filename || "",
      // @ts-ignore
      status: value.status || "pending",
    });
  });

  return clips;
}

const parseData = (data: any) => {
  const bulkData = data.map((item: any) => {
    const { clips, project_id, zip_url, created_at, render_task_id } = item;
    const transformedItem = {
      project_id,
      clips: parseClips(clips),
      zip_url,
      created_at,
      render_task_id,
    };
    return transformedItem;
  });

  // before returning the data, sort it by created_at
  return bulkData.sort((a: any, b: any) => {
    return (
      new Date(b?.created_at).getTime() - new Date(a?.created_at).getTime()
    );
  });
};

const getBulkDownloads = async () => {
  const data = await api.get(ApiEndpoints.BULK_DOWNLOADS_LIST);
  return parseData(data.data);
};

export default function useGetBulkDownloads(): UseQueryResult<any> {
  return useQuery({
    queryKey: ["bulk-clip-downloads"],
    queryFn: () => getBulkDownloads(),
    refetchOnWindowFocus: true,
  });
}
