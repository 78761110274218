import { useMutation } from "@tanstack/react-query";

import { addUserOnBoardingData } from "@/api/requests";

import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

export default function useAddUserOnboardingData() {
  return useMutation({
    mutationFn: (data: any) => addUserOnBoardingData(data),
    onError: (error) => {
      console.log(error);
      showNotification(
        "Something went wrong, please try again!",
        notificationType.FAIL
      );
    },
  });
}
