import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { Spinner } from "flowbite-react";

import { EditorMediaStatus } from "@/enums";

import audioSelected from "@/assets/icons/audio-selected.svg";
import mediaSelected from "@/assets/icons/media-selected.svg";

type CardStatusProps = {
  status: EditorMediaStatus;
  isSelected: boolean | undefined;
  addMediaToEditor?: any;
  isAudio?: boolean;
  removeAsset?: any;
};
const CardStatus = ({
  status,
  isSelected,
  addMediaToEditor,
  isAudio,
  removeAsset,
}: CardStatusProps) => {
  if (isAudio) {
    if (status === EditorMediaStatus.LOADING) {
      return (
        <div className="absolute right-4 top-4 text-blue-500 ">
          <Spinner size="md" />
        </div>
      );
    }
    if (isSelected || status === EditorMediaStatus.READY) {
      return (
        <div
          className="absolute right-3 top-3 text-blue-500 cursor-pointer"
          onClick={removeAsset}
        >
          <img src={audioSelected} />
        </div>
      );
    }

    return (
      <button
        className=" hidden absolute right-3 top-3 text-blue-500 group-hover:block"
        onClick={addMediaToEditor}
      >
        <PlusCircleIcon className="text-blue-500 w-8" />
      </button>
    );
  }

  if (status === EditorMediaStatus.LOADING) {
    return (
      <div className="absolute inset-0 flex items-center justify-center opacity-100 bg-black/40 transition-opacity z-10 cursor-default">
        <Spinner className="text-white w-10" />
      </div>
    );
  }
  if (isSelected || status === EditorMediaStatus.READY) {
    return (
      <div
        className="absolute inset-0 flex items-center justify-center bg-black/40 z-10 cursor-pointer"
        onClick={removeAsset}
      >
        <img src={mediaSelected} />
      </div>
    );
  }
  return null;
};

export default CardStatus;
