import { useQuery } from "@tanstack/react-query";

import { getUserPreference } from "@/api/requests";

export default function useGetUserPreference(userId: string) {
  return useQuery({
    queryKey: ["user-preference", userId],
    queryFn: () => getUserPreference(userId),
    refetchOnWindowFocus: false,
    staleTime: 300000,
  });
}
