import { Outlet, useLocation } from "react-router";

import { RouterPath } from "@/enums";

// import Banner from "@/components/Banner/Banner";
import Header from "@/components/Header";

const pageTitles: Record<string, string> = {
  [RouterPath.BRAND_KIT]: "Brand Kit 💼",
  [RouterPath.MY_TEMPLATES]: "My Templates 🎨",
  [RouterPath.DOWNLOADS]: "My Downloads 📁",
};

const MediaLayout = () => {
  const location = useLocation();
  return (
    <main className="mx-auto h-[100vh] w-full max-w-screen-2xl bg-[#e8e8ee]">
      {/* <Banner bannerVariant="outrage" /> */}
      <Header />
      <section className="w-[95%] mx-auto mt-4">
        <p className="text-2xl font-bold text-gray-700">
          {pageTitles[location.pathname]}{" "}
        </p>
      </section>
      {/* NOTE: change the height from 13rem to 10rem bellow
          while Banner is not present
       */}
      <section className="w-[95%] mx-auto bg-white rounded-md overflow-y-auto relative h-[calc(100vh_-_10rem)] mt-5">
        <Outlet />
      </section>
    </main>
  );
};

export default MediaLayout;
