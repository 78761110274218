import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Project } from "@/interfaces";

import { VideoLayout } from "@/enums";

type clipTimeStamps = {
  start_time: number;
  end_time: number;
};

type onBoardingData = {
  selectedLayouts: string[];
  baseTemplates: any;
  processingVideoData: any | null;
  projectId?: any;
  key: string;
  progressPercentage: number;
  createdAt: string;
};

type onBoardingDataObject = {
  [key: string]: onBoardingData;
};

type MultipleClipsExportData = {
  layout: VideoLayout;
  templateData: any;
};

type HomeState = {
  topBannerVisible: boolean;
  upgradeToProModalVisible: boolean;
  manageSubscriptionModalVisible: boolean;
  uploadVideoModalVisible: boolean;
  currentVideoFilterTab: string;
  isUploadCompleted: boolean;
  showPreviewTemplatePlayer: boolean;
  clipTimeStamps: clipTimeStamps | null;
  currentSelectedProject: Project | null;
  currentSelectedMicroContent: any;
  showConfettiModal: boolean;
  baseTemplates: any;
  userSelectedLayouts: string[];
  userSocialMediaHandles: any[];
  onBoardingData: onBoardingDataObject;
  currentOnBoardingId: string;
  paymentStatus: string;
  showEnableSceneChangeWarningModal: boolean;
  isShortVideo: boolean;
  showAddMoreMinModal: boolean;
  showFullVideo: boolean;
  showPreviewClipsSideModal: boolean;
  currentSelectedClip: string;
  multipleClipsExportData: MultipleClipsExportData;
};

const initialState: HomeState = {
  topBannerVisible: false,
  upgradeToProModalVisible: false,
  manageSubscriptionModalVisible: false,
  uploadVideoModalVisible: false,
  currentVideoFilterTab: "all",
  isUploadCompleted: false,
  showPreviewTemplatePlayer: false,
  clipTimeStamps: null,
  currentSelectedProject: null,
  currentSelectedMicroContent: null,
  showConfettiModal: false,
  baseTemplates: {},
  userSelectedLayouts: [],
  userSocialMediaHandles: [],
  onBoardingData: {},
  currentOnBoardingId: "",
  paymentStatus: "",
  showEnableSceneChangeWarningModal: true,
  isShortVideo: false,
  showAddMoreMinModal: false,
  showFullVideo: false,
  showPreviewClipsSideModal: false,
  currentSelectedClip: "",
  multipleClipsExportData: {
    layout: VideoLayout.LAYOUT_9_16_1,
    templateData: {},
  },
};

const homePageSlice = createSlice({
  name: "home-page",
  initialState,
  reducers: {
    toggleTopBanner(state, action: PayloadAction<boolean>) {
      // it's okay to do this because immer makes it immutable
      // under the hood
      state.topBannerVisible = action.payload;
    },
    toggleUpgradeToProModal(state, action: PayloadAction<boolean>) {
      state.upgradeToProModalVisible = action.payload;
    },
    toggleManageSubscriptionModal(state, action: PayloadAction<boolean>) {
      state.manageSubscriptionModalVisible = action.payload;
    },
    toggleUploadVideoModal(state, action: PayloadAction<boolean>) {
      state.uploadVideoModalVisible = action.payload;
    },
    toggleAddMoreMinModal(state, action: PayloadAction<boolean>) {
      state.showAddMoreMinModal = action.payload;
    },

    updateUploadStatus(state, action: PayloadAction<boolean>) {
      state.isUploadCompleted = action.payload;
    },
    updateShowTemplatePreviewPlayerStatus(
      state,
      action: PayloadAction<boolean>
    ) {
      state.showPreviewTemplatePlayer = action.payload;
    },
    updateCurrentSelectedProject(state, action: PayloadAction<Project>) {
      state.currentSelectedProject = action.payload;
    },
    updateCurrentSelectedMicroContent(state, action: PayloadAction<any>) {
      state.currentSelectedMicroContent = action.payload && {
        ...state.currentSelectedMicroContent,
        ...action.payload,
      };
    },
    updateCurrentSelectedMicroContentGist(
      state,
      action: PayloadAction<string>
    ) {
      state.currentSelectedMicroContent.gist = action.payload;
    },
    updateShowConfettiModal(state, action: PayloadAction<boolean>) {
      state.showConfettiModal = action.payload;
    },
    updateBaseTemplates(state, action: PayloadAction<any>) {
      state.baseTemplates = action.payload;
    },
    updateUserSelectedLayouts(state, action: PayloadAction<string[]>) {
      state.userSelectedLayouts = action.payload;
    },
    updateUserSocialMediaHandles(state, action: PayloadAction<any>) {
      state.userSocialMediaHandles = action.payload;
    },
    updateOnBoardingData(state, action: PayloadAction<onBoardingDataObject>) {
      state.onBoardingData = action.payload;
    },
    updateOnBoardingDataById(state, action: PayloadAction<any>) {
      const { id, data } = action.payload;
      state.onBoardingData[id] = { ...state.onBoardingData[id], ...data };
    },
    updateCurrentOnBoardingId(state, action: PayloadAction<string>) {
      state.currentOnBoardingId = action.payload;
    },
    updatedPaymentStatus(state, action: PayloadAction<string>) {
      state.paymentStatus = action.payload;
    },
    updateShowEnableSceneChangeWarningModal(
      state,
      action: PayloadAction<boolean>
    ) {
      state.showEnableSceneChangeWarningModal = action.payload;
    },
    updateIsShortVideo(state, action: PayloadAction<boolean>) {
      state.isShortVideo = action.payload;
    },
    updateShowFullLengthVideo(state, action: PayloadAction<boolean>) {
      state.showFullVideo = action.payload;
    },
    updateShowPreviewClipsSideModal(state, action: PayloadAction<boolean>) {
      state.showPreviewClipsSideModal = action.payload;
    },
    updateCurrentMicroContentRating(state, action: PayloadAction<boolean>) {
      state.currentSelectedMicroContent.userClipRateExist = action.payload;
    },
    setCurrentSelectedClip(state, action: PayloadAction<string>) {
      state.currentSelectedClip = action.payload;
    },
    updateMultipleClipsExportLayout(state, action: PayloadAction<VideoLayout>) {
      state.multipleClipsExportData.layout = action.payload;
    },
    updateMultipleClipsExportTemplateData(state, action: PayloadAction<any>) {
      state.multipleClipsExportData.templateData = action.payload;
    },
    resetHomeState: () => initialState,
  },
});

export const {
  toggleTopBanner,
  toggleUpgradeToProModal,
  toggleManageSubscriptionModal,
  toggleUploadVideoModal,
  toggleAddMoreMinModal,
  updateUploadStatus,
  updateShowTemplatePreviewPlayerStatus,
  updateCurrentSelectedProject,
  updateCurrentSelectedMicroContent,
  updateCurrentSelectedMicroContentGist,
  updateShowConfettiModal,
  resetHomeState,
  updateBaseTemplates,
  updateUserSelectedLayouts,
  updateUserSocialMediaHandles,
  updateOnBoardingData,
  updateCurrentOnBoardingId,
  updateOnBoardingDataById,
  updatedPaymentStatus,
  updateShowEnableSceneChangeWarningModal,
  updateIsShortVideo,
  updateShowFullLengthVideo,
  updateShowPreviewClipsSideModal,
  updateCurrentMicroContentRating,
  setCurrentSelectedClip,
  updateMultipleClipsExportLayout,
  updateMultipleClipsExportTemplateData,
} = homePageSlice.actions;
export default homePageSlice;
