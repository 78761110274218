import noDownloadsImg from "@/assets/images/no-downloads.svg";

const NoDownloads = () => {
  return (
    <div className="flex h-full flex-col justify-center">
      <img
        src={noDownloadsImg}
        className="mx-auto w-80"
      />
      <p className="mx-auto mt-10 mb-6 w-80 text-center text-[#5E6473] text-sm">
        <span className="font-semibold">Videos</span> you download from the
        editor will show up here.
      </p>
    </div>
  );
};

export default NoDownloads;
