import { useEffect, useState } from "react";

import { Button, Spinner } from "flowbite-react";

import { toggleSaveDraft } from "@/store/editorSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import useAddDraft from "@/api/useAddDraft";
import useGetEditorDrafts from "@/api/useGetEditorDrafts";
import useUpdateDraft from "@/api/useUpdateDraft";

import { BUTTON_IDS } from "@/constants/segment-analytics";

import { generateDraftName, replaceUndefinedWithNull } from "@/helpers";
import { applyCurrentDraft } from "@/helpers/draft";

import { trackSaveDraftEvent } from "@/utils/amplitudeAnalytcs";
import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

import { PlanType } from "@/enums";

import BaseModal from "@/components/BaseModal";

const SAVE_AS_NEW_DRAFT = "new-draft";
const UPDATE_DRAFT = "update-draft";

const { SAVE_DRAFT_BTN } = BUTTON_IDS.SAVE_DRAFT_MODAL;

const SaveDraftModal = ({ getDraftsProperties }: any) => {
  const dispatch = useAppDispatch();

  const selectedEditorDraft = useAppSelector(
    (state) => state.draftState.selectedEditorDraft
  );

  const [draftName, setDraftName] = useState(
    generateDraftName(selectedEditorDraft?.name)
  );
  const [draftSaveOption, setDraftSaveOption] = useState(SAVE_AS_NEW_DRAFT);
  const [showDraftSaveSuccessModal, setShowDraftSaveSuccessModal] =
    useState(false);
  const [currentUpdatedDraft, setCurrentUpdatedDraft] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const showSaveDraftModal = useAppSelector(
    (state) => state.editorState.showSaveDraftModal
  );
  const planType = useAppSelector(
    (state) => state.authState.userSubscription.planType
  );

  const uid = useAppSelector((state) => state.authState.currentUser.uid);

  const currentSelectedMicroContent = useAppSelector(
    (state) => state.homeState.currentSelectedMicroContent
  );

  const { data: editorDraftsData, isLoading: editorDraftsLoading } =
    useGetEditorDrafts(uid);

  const handleCloseSaveDraftModal = () => {
    dispatch(toggleSaveDraft(false));
    setShowDraftSaveSuccessModal(false);
    setDraftSaveOption(SAVE_AS_NEW_DRAFT);
    setDraftName(generateDraftName(selectedEditorDraft?.name));
    if (currentUpdatedDraft) {
      applyCurrentDraft({ ...currentUpdatedDraft, fromDownloadPage: false });
    }
  };

  const handelUpdateSaveDraftOption = (e: any) => {
    setDraftSaveOption(e.target.value);
  };

  const saveDraftMutationSuccessCB = () => {
    setShowDraftSaveSuccessModal(true);
  };

  const { mutate: addDraft, isLoading: addDraftLoading } = useAddDraft(
    saveDraftMutationSuccessCB
  );

  const { mutate: updateDraft, isLoading: updateDraftLoading } = useUpdateDraft(
    saveDraftMutationSuccessCB
  );

  const showSpinner = () => {
    return (
      addDraftLoading || updateDraftLoading || isLoading || editorDraftsLoading
    );
  };

  const checkIfCurrentDraftIsEmpty = () => {
    if (selectedEditorDraft?.id) {
      return false;
    }
    return true;
  };

  const handelAddDraftData = async () => {
    try {
      setIsLoading(true);

      const generatedDraftObject = await getDraftsProperties();
      let draftData = replaceUndefinedWithNull(generatedDraftObject);
      setIsLoading(false);

      draftData = {
        ...draftData,
        id:
          draftSaveOption === UPDATE_DRAFT
            ? selectedEditorDraft?.id
            : draftData.id,
        name: draftName,
        updatedAt: new Date(),
      };

      let reqObj = {
        docId: draftData.id,
        projectId: draftData?.project?.id,
        userId: uid,
      };

      if (editorDraftsData?.length) {
        updateDraft({
          ...reqObj,
          draftData: {
            ...draftData,
            createdAt: selectedEditorDraft?.createdAt,
          },
        });
      } else {
        addDraft({
          ...reqObj,
          draftData: {
            ...draftData,
            createdAt: new Date(),
          },
        });
      }

      const updatedDraftData = {
        ...draftData,
        fromDownloadPage: false,
        stayOnEditor: true,
        clipId: currentSelectedMicroContent?.clipId,
      };

      setCurrentUpdatedDraft(updatedDraftData);
      trackSaveDraftEvent(updatedDraftData, draftSaveOption);
    } catch (error) {
      setIsLoading(false);
      console.log("Save Draft Error: ", error);
      showNotification(
        "Failed to save your draft. Please try again later.",
        notificationType.FAIL
      );
    }
  };

  useEffect(() => {
    if (addDraftLoading || updateDraftLoading) {
      return;
    }
    if (selectedEditorDraft?.name) {
      setDraftSaveOption(UPDATE_DRAFT);
    } else {
      setDraftSaveOption(SAVE_AS_NEW_DRAFT);
    }
    setDraftName(generateDraftName(selectedEditorDraft?.name));
  }, [selectedEditorDraft?.name, addDraftLoading, updateDraftLoading]);

  return (
    <BaseModal
      isModalOpen={showSaveDraftModal}
      handelModalOpen={() => {}}
      notClosable
    >
      {showSaveDraftModal && (
        <div className="w-[30rem] bg-white p-6 pb-10 pt-8">
          {showDraftSaveSuccessModal ? (
            <>
              <p className="mb-2 text-center text-3xl">🎉</p>
              {draftSaveOption === SAVE_AS_NEW_DRAFT ? (
                <p className=" mb-1 text-center text-2xl font-bold text-gray-900">
                  Awesome!
                </p>
              ) : (
                <p className=" mb-1 text-center text-2xl font-bold text-gray-900">
                  Woohoo!
                </p>
              )}

              <p className=" mb-8 text-center text-2xl font-bold text-gray-900">
                {draftSaveOption === SAVE_AS_NEW_DRAFT
                  ? "Your draft has been saved."
                  : "Your draft has been updated."}
              </p>

              <div className="mx-auto mb-10 w-fit">
                <p className="text-center font-light text-gray-500">
                  You can find your drafts in the{" "}
                  <span className="font-bold">Home / Drafts</span> section. You
                  can close this window now.
                </p>
              </div>

              <Button
                onClick={handleCloseSaveDraftModal}
                id="done-save-draft"
                className="w-[10rem] mx-auto"
              >
                Done
              </Button>
            </>
          ) : (
            <>
              <p className="mb-6 text-center text-2xl font-bold text-gray-900">
                Name your Draft
              </p>

              <div className="mx-auto mb-8 w-full">
                <input
                  type="text"
                  name="editor-draft-name"
                  id="user-editor-draft-name"
                  onChange={(e) => setDraftName(e.target.value)}
                  className="mx-auto block w-[80%] rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
                  placeholder="Enter your draft name"
                  value={draftName}
                  spellCheck="false"
                />

                <div className="mx-auto my-4 mt-6 w-[50%]">
                  <div className="mb-4 flex items-center">
                    <input
                      onChange={handelUpdateSaveDraftOption}
                      id="save-as-new-draft-radio"
                      type="radio"
                      value={SAVE_AS_NEW_DRAFT}
                      name="save-draft-preference-radio"
                      checked={draftSaveOption === SAVE_AS_NEW_DRAFT}
                      className="h-5 w-5 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                    />
                    <label
                      htmlFor="save-as-new-draft-radio"
                      className="ml-2 font-light text-gray-500 dark:text-gray-300"
                    >
                      Save as new draft
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      disabled={checkIfCurrentDraftIsEmpty()}
                      onChange={handelUpdateSaveDraftOption}
                      id="update-draft-radio"
                      type="radio"
                      value={UPDATE_DRAFT}
                      checked={draftSaveOption === UPDATE_DRAFT}
                      name="save-draft-preference-radio"
                      className={`h-5 w-5 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600 ${
                        checkIfCurrentDraftIsEmpty() &&
                        "cursor-not-allowed opacity-50"
                      }`}
                    />
                    <label
                      htmlFor="update-draft-radio"
                      className={`ml-2 font-light text-gray-500 dark:text-gray-300 ${
                        checkIfCurrentDraftIsEmpty() &&
                        "cursor-not-allowed opacity-50"
                      }`}
                    >
                      Update draft
                    </label>
                  </div>
                </div>
              </div>

              {planType === PlanType.FREE && (
                <div className="flex flex-col gap-2 p-2  border rounded-md t w-[80%] mx-auto my-4  mb-8 border-[#EDC200] bg-[#FEF6DF]  text-[#725C00]">
                  <div className="flex gap-5 items-center">
                    <div>
                      <div className="text-xs">
                        Free users can save upto 1 draft only. Saving this draft
                        will override any previously saved drafts
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="mx-auto flex w-[60%] justify-center space-x-6">
                <Button
                  color="gray"
                  onClick={handleCloseSaveDraftModal}
                  disabled={showSpinner()}
                  id="save-draft-modal-cancel-btn"
                  size="sm"
                  className="px-4"
                >
                  Cancel
                </Button>

                <Button
                  id={SAVE_DRAFT_BTN.ID}
                  disabled={showSpinner() || !draftName}
                  onClick={() => {
                    // trackUserButtonEvent(
                    //   SAVE_DRAFT_BTN.ID,
                    //   SAVE_DRAFT_BTN.ACTION
                    // );
                    handelAddDraftData();
                  }}
                  size="sm"
                  className="w-28"
                >
                  {showSpinner() && (
                    <Spinner className="h-4 w-4 mr-2 -mt-0.5" />
                  )}
                  {draftSaveOption === UPDATE_DRAFT ? "Update" : "Save"}
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </BaseModal>
  );
};

export default SaveDraftModal;
