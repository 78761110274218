import { SVGProps, memo } from "react";

import clsx from "clsx";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    className={clsx(props.className, "fill-current")}
    {...props}
  >
    <ellipse
      cx={7.122}
      cy={17.25}
      fill="currentColor"
      rx={4.122}
      ry={4.25}
    />
    <path
      fill="currentColor"
      d="m7.48 3 4.48 8H3l4.48-8ZM13.234 13h7.76v8h-7.76zM16.877 3l1.263 1.109 1.652.136.132 1.704L21 7.25l-1.076 1.301-.132 1.704-1.652.136-1.263 1.109-1.262-1.109-1.653-.136-.132-1.704-1.075-1.301 1.075-1.301.132-1.704 1.653-.136L16.877 3Z"
    />
  </svg>
);
const ElementsIcon = memo(SvgComponent);
export default ElementsIcon;
