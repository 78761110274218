import { useMutation, useQueryClient } from "@tanstack/react-query";

import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

import api from "./api";
import { ApiEndpoints } from "./constants/ApiEndPoints";

const deleteRenderedVideo = async (renderTaskId: string) => {
  const { data } = await api.delete(ApiEndpoints.DELETE_DOWNLOADS, {
    params: { task_id: renderTaskId },
  });
  return data;
};

export default function useDeleteRenderedVideo() {
  const queryClient = useQueryClient();

  return useMutation(
    (renderTaskId: string) => deleteRenderedVideo(renderTaskId),
    {
      onSuccess: () => {
        showNotification(
          "Video Deleted Successfully",
          notificationType.SUCCESS
        );
        queryClient.invalidateQueries({ queryKey: ["all-downloads"] });
      },
      onError: () => {
        showNotification(
          "Something went wrong, please try again!",
          notificationType.FAIL
        );
      },
    }
  );
}
