import { useMutation, useQueryClient } from "@tanstack/react-query";

import { updateUserTemplates } from "@/api/requests";

import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

export default function useUpdateUserTemplateData(
  saveTemplateMutationSuccessCB: any
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => updateUserTemplates(data),
    onSuccess: (data) => {
      saveTemplateMutationSuccessCB();
      queryClient.invalidateQueries({ queryKey: ["user-template"] });
    },
    onError: (error) => {
      console.log(error);
      showNotification(
        "Something went wrong, please try again!",
        notificationType.FAIL
      );
    },
  });
}
