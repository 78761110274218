import nlp from "compromise/three";
import memoize from "micro-memoize";

import { ID_TITLE_TEXT } from "@/constants";
import { emojiTags, exceptionEmojiTags } from "@/constants/emoji-tags";

const removeTrailingFullStop = (text: string) => {
  return text.replace(/\.$/, "");
};

const questionWords = [
  "what",
  "when",
  "where",
  "why",
  "how",
  "who",
  "which",
  "whom",
  "whose",
];

const exceptionWords = [
  "see",
  "can",
  "set",
  "bow",
  "bowing",
  "wave",
  "drop",
  "cross",
  "point",
  "pointing",
  "stick",
  "light",
  "roll",
  "shot",
  "present",
  "fire",
  "firing",
];

const exceptionWordsAdjec = ["present"];

const addQuestionMarkIfRequired = (text: string) => {
  // if question mark is present at the end of the text, return the text as it is
  if (text.slice(-1) === "?") {
    return text;
  }

  // check if the text begins with any of the question words from array
  const textWords = text.split(" ");
  const firstWord = textWords[0]?.toLowerCase();
  if (questionWords.includes(firstWord)) {
    return text + "?";
  }

  const doc = nlp(text).questions().json();
  if (doc.length > 0) {
    // add question mark at the end of the text
    return text + "?";
  }
  return text;
};

function lemmetization(myText: any, type: any) {
  let doc = nlp(myText);
  if (type == "noun") {
    // assumed to be a verb
    // tag it as a noun (neither plural or singular)
    doc.tag("Noun");
    // this method will do some plural/singular guesswork
    if (doc.nouns().isPlural()) {
      doc.tag("Plural");
    }
    //now this will work
    return doc.nouns().toSingular().text();
  } else if (type == "verb") {
    doc.tag("Verb");
    // @ts-ignore
    return doc.verbs().isSingular().toInfinitive().text();
  } else if (type == "adj") {
    doc.tag("Adjective");
    return doc.adjectives().toAdverb().text();
  }
}

export const nlpGist = (text: string) => {
  text = removeTrailingFullStop(text);
  text = addQuestionMarkIfRequired(text);
  return text;
};

let dicta_noun = {};
let dicta_verb = {};
let dicta_exception_verb = {};
let dicta_adjective = {};
let emoji_arr = [];

function emojiPicker(a: any, word1: string, word2?: string) {
  let search_x = {};
  let two = false;
  if (word2) {
    word1 = word1 + " " + word2;
    two = true;
  }
  if (a == "noun") {
    search_x = dicta_noun;
  } else if (a == "verb") {
    search_x = dicta_verb;
  } else if (a == "adj") {
    search_x = dicta_adjective;
  }
  let word_lemm = lemmetization(word1, a);

  if (two == true) {
    if (word_lemm.split(" ").length > 1) {
      if (word_lemm in search_x) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    if (word_lemm in search_x) {
      return true;
    } else {
      return false;
    }
  }
}

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

function exceptionEmojiPicker(word1: string, word2?: string) {
  let two = false;
  if (word2) {
    word1 = word1 + " " + word2;
    two = true;
  }
  let word_lemm = lemmetization(word1, "verb");
  if (two == true) {
    if (word_lemm.split(" ").length > 1) {
      if (word_lemm in dicta_exception_verb) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    if (word_lemm in dicta_exception_verb) {
      return true;
    } else {
      return false;
    }
  }
}

function index_modify(text: any, initial_index: number) {
  let arr = text.split(/[']/);
  let present_index = -1;
  let counter = 0;
  for (var i = 0; i < arr.length; i++) {
    let loop_sent = arr[i].split(" ");
    // console.log(loop_sent)
    present_index = present_index + loop_sent.length;
    // console.log(present_index)
    if (present_index >= initial_index) {
      counter = i;
      break;
    }
  }
  // console.log(counter)
  let final_index = initial_index - counter;
  // console.log(final_index)

  return final_index;
}

function checkSrtId(
  line_number: number,
  index_number: number,
  line_list: any,
  index_list: any
) {
  let init_id = 0;
  let final_id = 0;
  for (let i = line_list.length - 1; i >= 0; i--) {
    if (line_list[i] <= line_number) {
      init_id = i;
      break;
    }
  }
  for (let i = init_id; i >= 0; i--) {
    if (line_list[i] === line_number) {
      if (index_list[i] <= index_number) {
        final_id = i;
        break;
      }
    } else {
      final_id = i;
      break;
    }
  }
  return final_id;
}

const addEmojisToText = (text: any) => {
  const doc = nlp(text);
  const json = doc.json();
  const arr = json[0]?.terms || [];

  const emojiIndexArr = [],
    indexmonitorArr: any = [],
    nounCountArr: any = [],
    verbCountArr: any = [],
    proCountArr: any = [],
    adverbCountArr: any = [],
    adjectiveCountArr: any = [];

  let flag = 0,
    emoji_val,
    outputEmoji = "",
    counter = 0;

  arr.forEach((word: any) => {
    const tags = word.tags;
    if (tags.includes("Noun")) {
      if (tags.includes("Pronoun")) {
      } else {
        nounCountArr.push(counter);
      }
    }
    if (tags.includes("Verb")) {
      verbCountArr.push(counter);
    }
    if (tags.includes("Adjective")) {
      adjectiveCountArr.push(counter);
    }
    if (tags.includes("Pronoun")) {
      proCountArr.push(counter);
    }
    if (tags.includes("Adverb")) {
      adverbCountArr.push(counter);
    }
    counter = counter + 1;
  });

  if (nounCountArr.length > 0) {
    for (let i = nounCountArr.length - 1; i >= 0; i--) {
      flag = 0;
      const va = nounCountArr[i];
      if (indexmonitorArr.includes(va)) {
      } else {
        if (nounCountArr.includes(va - 1)) {
          if (
            emojiPicker(
              "noun",
              arr[va - 1].text.toLowerCase(),
              arr[va].text.toLowerCase()
            )
          ) {
            let temp_b = lemmetization(
              arr[va - 1].text.toLowerCase() + " " + arr[va].text.toLowerCase(),
              "noun"
            );
            if (temp_b in dicta_noun) {
              // @ts-ignore
              emoji_val = dicta_noun[temp_b];
            }
            if (emoji_val.length > 1) {
              const ex = getRandomInt(emoji_val.length);
              outputEmoji = emoji_val[ex];
            } else {
              outputEmoji = emoji_val[0];
            }
            flag = 1;
            const tempemojiIndexArr = [];
            tempemojiIndexArr.push(va - 1);
            tempemojiIndexArr.push(va);
            tempemojiIndexArr.push(outputEmoji);
            emojiIndexArr.push(tempemojiIndexArr);
            indexmonitorArr.push(va - 1);
            indexmonitorArr.push(va);
          }
        } else if (verbCountArr.includes(va - 1)) {
          if (
            emojiPicker(
              "noun",
              arr[va - 1].text.toLowerCase(),
              arr[va].text.toLowerCase()
            )
          ) {
            let temp_b = lemmetization(
              arr[va - 1].text.toLowerCase() + " " + arr[va].text.toLowerCase(),
              "noun"
            );
            if (temp_b in dicta_noun) {
              // @ts-ignore
              emoji_val = dicta_noun[temp_b];
            }
            if (emoji_val.length > 1) {
              const ex = getRandomInt(emoji_val.length);
              outputEmoji = emoji_val[ex];
            } else {
              outputEmoji = emoji_val[0];
            }
            flag = 1;
            const tempemojiIndexArr = [];
            tempemojiIndexArr.push(va - 1);
            tempemojiIndexArr.push(va);
            tempemojiIndexArr.push(outputEmoji);
            emojiIndexArr.push(tempemojiIndexArr);
            indexmonitorArr.push(va - 1);
            indexmonitorArr.push(va);
          }
        } else if (adjectiveCountArr.includes(va - 1)) {
          if (
            emojiPicker(
              "noun",
              arr[va - 1].text.toLowerCase(),
              arr[va].text.toLowerCase()
            )
          ) {
            let temp_b = lemmetization(
              arr[va - 1].text.toLowerCase() + " " + arr[va].text.toLowerCase(),
              "noun"
            );
            if (temp_b in dicta_noun) {
              // @ts-ignore
              emoji_val = dicta_noun[temp_b];
            }
            if (emoji_val.length > 1) {
              const ex = getRandomInt(emoji_val.length);
              outputEmoji = emoji_val[ex];
            } else {
              outputEmoji = emoji_val[0];
            }
            flag = 1;
            const tempemojiIndexArr = [];
            tempemojiIndexArr.push(va - 1);
            tempemojiIndexArr.push(va);
            tempemojiIndexArr.push(outputEmoji);
            emojiIndexArr.push(tempemojiIndexArr);
            indexmonitorArr.push(va - 1);
            indexmonitorArr.push(va);
          }
        }
        if (flag === 0) {
          if (emojiPicker("noun", arr[va].text.toLowerCase())) {
            let temp_b = lemmetization(arr[va].text.toLowerCase(), "noun");
            if (temp_b in dicta_noun) {
              // @ts-ignore
              emoji_val = dicta_noun[temp_b];
            }
            if (emoji_val.length > 1) {
              const ex = getRandomInt(emoji_val.length);
              outputEmoji = emoji_val[ex];
            } else {
              outputEmoji = emoji_val[0];
            }
            flag = 1;
            const tempemojiIndexArr = [];
            tempemojiIndexArr.push(va);
            tempemojiIndexArr.push(outputEmoji);
            emojiIndexArr.push(tempemojiIndexArr);
            indexmonitorArr.push(va);
          }
        }
      }
    }
  }
  flag = 0;
  if (flag === 0) {
    if (verbCountArr.length > 0) {
      for (let i = verbCountArr.length - 1; i >= 0; i--) {
        flag = 0;
        const va = verbCountArr[i];
        if (indexmonitorArr.includes(va)) {
        } else {
          if (verbCountArr.includes(va - 1)) {
            if (
              emojiPicker(
                "verb",
                arr[va - 1].text.toLowerCase(),
                arr[va].text.toLowerCase()
              )
            ) {
              let temp_b = lemmetization(
                arr[va - 1].text.toLowerCase() +
                  " " +
                  arr[va].text.toLowerCase(),
                "verb"
              );
              if (temp_b in dicta_verb) {
                // @ts-ignore
                emoji_val = dicta_verb[temp_b];
              }
              if (emoji_val.length > 1) {
                const ex = getRandomInt(emoji_val.length);
                outputEmoji = emoji_val[ex];
              } else {
                outputEmoji = emoji_val[0];
              }
              flag = 1;
              const tempemojiIndexArr = [];
              tempemojiIndexArr.push(va - 1);
              tempemojiIndexArr.push(va);
              tempemojiIndexArr.push(outputEmoji);
              emojiIndexArr.push(tempemojiIndexArr);
              indexmonitorArr.push(va - 1);
              indexmonitorArr.push(va);
            }
          } else if (adjectiveCountArr.includes(va - 1)) {
            if (
              emojiPicker(
                "verb",
                arr[va - 1].text.toLowerCase(),
                arr[va].text.toLowerCase()
              )
            ) {
              let temp_b = lemmetization(
                arr[va - 1].text.toLowerCase() +
                  " " +
                  arr[va].text.toLowerCase(),
                "verb"
              );
              if (temp_b in dicta_verb) {
                // @ts-ignore
                emoji_val = dicta_verb[temp_b];
              }
              if (emoji_val.length > 1) {
                const ex = getRandomInt(emoji_val.length);
                outputEmoji = emoji_val[ex];
              } else {
                outputEmoji = emoji_val[0];
              }
              flag = 1;
              const tempemojiIndexArr = [];
              tempemojiIndexArr.push(va - 1);
              tempemojiIndexArr.push(va);
              tempemojiIndexArr.push(outputEmoji);
              emojiIndexArr.push(tempemojiIndexArr);
              indexmonitorArr.push(va - 1);
              indexmonitorArr.push(va);
            }
          }
          if (flag === 0) {
            if (arr[va].text.toLowerCase().length > 1) {
              let temp_b = lemmetization(arr[va].text.toLowerCase(), "verb");
              if (exceptionWords.includes(temp_b)) {
                if (exceptionEmojiPicker(arr[va].text.toLowerCase())) {
                  if (temp_b in dicta_exception_verb) {
                    // @ts-ignore
                    emoji_val = dicta_exception_verb[temp_b];
                  }
                  //   emoji_val = dicta_exception_verb[inpa_dict];
                  if (emoji_val.length > 1) {
                    const ex = getRandomInt(emoji_val.length);
                    outputEmoji = emoji_val[ex];
                  } else {
                    outputEmoji = emoji_val[0];
                  }
                  flag = 1;
                  const tempemojiIndexArr = [];
                  tempemojiIndexArr.push(va);
                  tempemojiIndexArr.push(outputEmoji);
                  emojiIndexArr.push(tempemojiIndexArr);
                  indexmonitorArr.push(va);
                }
              } else {
                if (emojiPicker("verb", arr[va].text.toLowerCase())) {
                  let temp_b = lemmetization(
                    arr[va].text.toLowerCase(),
                    "verb"
                  );
                  if (temp_b in dicta_verb) {
                    // @ts-ignore
                    emoji_val = dicta_verb[temp_b];
                  }
                  if (emoji_val.length > 1) {
                    const ex = getRandomInt(emoji_val.length);
                    outputEmoji = emoji_val[ex];
                  } else {
                    outputEmoji = emoji_val[0];
                  }
                  flag = 1;
                  const tempemojiIndexArr = [];
                  tempemojiIndexArr.push(va);
                  tempemojiIndexArr.push(outputEmoji);
                  emojiIndexArr.push(tempemojiIndexArr);
                  indexmonitorArr.push(va);
                }
              }
            }
          }
        }
      }
    }
  }
  flag = 0;
  if (flag === 0) {
    if (proCountArr.length > 0) {
      for (let i = proCountArr.length - 1; i >= 0; i--) {
        flag = 0;
        const va = proCountArr[i];
        if (indexmonitorArr.includes(va)) {
        } else {
          if (emojiPicker("noun", arr[va].text.toLowerCase())) {
            let temp_b = lemmetization(arr[va].text.toLowerCase(), "noun");
            if (temp_b in dicta_noun) {
              // @ts-ignore
              emoji_val = dicta_noun[temp_b];
            }
            if (emoji_val.length > 1) {
              const ex = getRandomInt(emoji_val.length);
              outputEmoji = emoji_val[ex];
            } else {
              outputEmoji = emoji_val[0];
            }
            flag = 1;
            const tempemojiIndexArr = [];
            tempemojiIndexArr.push(va);
            tempemojiIndexArr.push(outputEmoji);
            emojiIndexArr.push(tempemojiIndexArr);
            indexmonitorArr.push(va);
          }
        }
      }
    }
  }
  flag = 0;
  if (flag === 0) {
    if (adjectiveCountArr.length > 0) {
      for (let i = adjectiveCountArr.length - 1; i >= 0; i--) {
        flag = 0;
        const va = adjectiveCountArr[i];
        if (indexmonitorArr.includes(va)) {
        } else {
          if (emojiPicker("adj", arr[va].text.toLowerCase())) {
            let temp_b = lemmetization(arr[va].text.toLowerCase(), "adj");
            let inpa_dict = arr[va].text.toLowerCase();
            if (temp_b in exceptionWordsAdjec) {
              inpa_dict = temp_b;
            }
            if (exceptionWordsAdjec.includes(inpa_dict)) {
              break;
            } else {
              if (temp_b in dicta_adjective) {
                // @ts-ignore
                emoji_val = dicta_adjective[temp_b];
              }
              if (emoji_val.length > 1) {
                const ex = getRandomInt(emoji_val.length);
                outputEmoji = emoji_val[ex];
              } else {
                outputEmoji = emoji_val[0];
              }
              flag = 1;
              const tempemojiIndexArr = [];
              tempemojiIndexArr.push(va);
              tempemojiIndexArr.push(outputEmoji);
              emojiIndexArr.push(tempemojiIndexArr);
              indexmonitorArr.push(va);
            }
          }
        }
      }
    }
  }
  flag = 0;
  if (adverbCountArr.length > 0) {
    for (let i = adverbCountArr.length - 1; i >= 0; i--) {
      flag = 0;
      const va = adverbCountArr[i];
      if (indexmonitorArr.includes(va)) {
      } else {
        if (emojiPicker("adj", arr[va].text.toLowerCase())) {
          let temp_b = arr[va].text.toLowerCase();
          let emoji_val = [];
          if (temp_b in dicta_adjective) {
            // @ts-ignore
            emoji_val = dicta_adjective[temp_b];
          }
          if (emoji_val.length > 1) {
            const ex = getRandomInt(emoji_val.length);
            outputEmoji = emoji_val[ex];
          } else if (emoji_val.length == 1) {
            outputEmoji = emoji_val[0];
          }
          if (emoji_val.length >= 0) {
            flag = 1;
            const tempemojiIndexArr = [];
            tempemojiIndexArr.push(va);
            tempemojiIndexArr.push(outputEmoji);
            emojiIndexArr.push(tempemojiIndexArr);
            indexmonitorArr.push(va);
          }
        }
      }
    }
  }
  return emojiIndexArr;
};

const updateEmojiTags = async () => {
  for (let k in emojiTags) {
    const promise = new Promise((resolve, _reject) => {
      setTimeout(() => {
        emoji_arr = emojiTags[k];
        let a = lemmetization(k, "noun");
        let b = lemmetization(k, "verb");
        let c = lemmetization(k, "adj");
        // @ts-ignore`
        dicta_noun[a] = emoji_arr;
        // @ts-ignore
        dicta_verb[b] = emoji_arr;
        // @ts-ignore
        dicta_adjective[c] = emoji_arr;
        resolve("done");
      }, 0);
    });
    await promise;
  }

  for (let k in exceptionEmojiTags) {
    const promise = new Promise((resolve, _reject) => {
      setTimeout(() => {
        emoji_arr = exceptionEmojiTags[k];
        let b = lemmetization(k, "verb");
        // @ts-ignore
        dicta_exception_verb[b] = emoji_arr;
        resolve("done");
      }, 0);
    });
    await promise;
  }
  for (var i = 0; i < exceptionWords.length; i++) {
    const promise = new Promise((resolve, _reject) => {
      setTimeout(() => {
        let b = lemmetization(exceptionWords[i], "verb");
        if (!exceptionWords.includes(b)) {
          exceptionWords.push(b);
        }
        resolve("done");
      }, 0);
    });
    await promise;
  }
};

export const updateEmojiTagsWithLemmetization = memoize(updateEmojiTags);

// write the above function to be memoized

export const addEmojisToSrtString = (subsArr: any[]) => {
  let result = [...subsArr];
  let sentArr: any = [];
  result.forEach((word) => {
    sentArr.push(word["text"]);
  });
  let line_counter = 1;
  let index_counter = 0;
  const line_list: any = [];
  const index_list: any = [];
  const modify_list: any = [];
  let start_flag = 0;
  let continue_text = "";

  // array of obects with subtitle id and emoji to be displayed
  let emojisArr: any = [];
  sentArr.forEach((word: any) => {
    const sent_text = word;
    if (start_flag == 0) {
      continue_text = continue_text + sent_text;
      start_flag = 1;
    } else {
      continue_text = continue_text + " " + sent_text;
    }
    let sentences = sent_text.split(/[!?.,]/);
    if (sentences.length > 1) {
      let x = sentences.length;
      line_list.push(line_counter);
      index_list.push(index_counter);
      line_counter = line_counter + x - 1;
      let text = sentences[x - 1];
      const wordsArr = text.split(" ");
      let li = wordsArr.length;
      index_counter = li - 1;
    } else {
      line_counter = line_counter;
      line_list.push(line_counter);
      index_list.push(index_counter);
      let text = sentences[0];
      const wordsArr = text.split(" ");
      let li = wordsArr.length;
      index_counter = index_counter + li;
    }
  });
  let line_found = continue_text.split(/[?!.,]/);
  line_found = line_found.slice(0, line_found.length - 1);
  line_found.forEach((word) => {
    let em = addEmojisToText(word);
    let le = em.length;
    if (le > 0) {
      modify_list.push(em);
    } else {
      modify_list.push([]);
    }
  });
  for (var i = 0; i < modify_list.length; i++) {
    var sortedArray = modify_list[i].sort(function (a: any, b: any) {
      return b[0] - a[0];
    });
    modify_list[i] = sortedArray;
  }

  for (var i = 0; i < modify_list.length; i++) {
    for (var m = 0; m < modify_list[i].length; m++) {
      if (modify_list[i][m].length > 1) {
        let initial_index_loop =
          modify_list[i][m][modify_list[i][m].length - 2];
        let index_loop = index_modify(line_found[i], initial_index_loop);
        let emoji = modify_list[i][m][modify_list[i][m].length - 1];
        let id = checkSrtId(i + 1, index_loop, line_list, index_list);
        let start_line = line_list[id];
        let start_index = index_list[id];
        let arr_this = sentArr[id].split(/[?!.,]/);
        let index_this = i + 1 - start_line;
        let selected_line = arr_this[index_this];
        let final_index = index_loop;
        if (index_this === 0) {
          final_index = final_index - start_index;
        } else {
          let extra_index = 0;
          for (var j = 0; j < index_this; j++) {
            if (arr_this[j].split(" ")[0] === "") {
              extra_index = extra_index + arr_this[j].split(" ").length - 1;
            } else {
              extra_index = extra_index + arr_this[j].split(" ").length;
            }
          }
          final_index = extra_index + index_loop;
        }
        let array_1 = sentArr[id].split(" ").slice(0, final_index + 1);
        // array_1.push(emoji);

        // for(let ind = 0; i<emojisArr.length; i++) {
        //   if(emojisArr[ind].id === id)
        // }

        let alreadyExistSubtitle = emojisArr.find(
          (emoji: any) => emoji.id === id
        );

        !alreadyExistSubtitle &&
          emojisArr.push({
            id,
            emoji,
          });

        let final_array = array_1.concat(
          sentArr[id].split(" ").slice(final_index + 1)
        );
        let final_output = final_array.join(" ");

        const updatedSrtObjWithEmoji = {
          ...result[id],
          text: final_output,
        };
        result[id] = updatedSrtObjWithEmoji;
        sentArr[id] = final_output;
      }
    }
  }

  let output_string = "";
  result.forEach((word) => {
    output_string =
      output_string +
      word["id"] +
      "\n" +
      word["startTime"] +
      " --> " +
      word["endTime"] +
      "\n" +
      word["text"] +
      "\n\n";
  });

  for (let i = 0; i < emojisArr.length; i++) {
    const { id, emoji } = emojisArr[i];
    const emojiObject = {
      text: emoji,
      width: result[id].emoji?.width || 0,
      maxHeight: result[id].emoji?.maxHeight || 0,
      top: result[id].emoji?.top || 0,
      left: result[id].emoji?.left || 0,
    };
    result[id].emoji = emojiObject;
  }

  return result;
};

export const removeEmojisFromText = (text: string) => {
  const nlpText = nlp(text);
  const emojis = nlpText.emoji().json();
  let modifiedText = text;
  emojis.forEach((emoji: any) => {
    modifiedText = modifiedText.replace(` ${emoji.text}`, "");
  });

  return modifiedText;
};

export const removeEmojisFromSrtString = (subsArr: any[]) => {
  let result = [...subsArr];
  let dicta: any = {};
  let tempy2arr: any = [];
  let tempyarr: any = [];
  let tempyindexarr: any = [];

  for (let k in emojiTags) {
    const emojiArray = emojiTags[k];
    for (let z = 0; z < emojiArray.length; z++) {
      dicta[emojiArray[z]] = k;
    }
  }
  for (let k in exceptionEmojiTags) {
    const emojiArray = exceptionEmojiTags[k];
    for (let z = 0; z < emojiArray.length; z++) {
      dicta[emojiArray[z]] = k;
    }
  }

  let counter_result = 0;

  result.forEach((word) => {
    tempyindexarr = [];
    tempyarr = [];
    tempy2arr = word["text"].split(" ");
    for (var z = 0; z < tempy2arr.length; z++) {
      if (tempy2arr[z] in dicta) {
      } else {
        tempyarr.push(tempy2arr[z]);
      }
    }
    let final_output2 = tempyarr.join(" ");

    const updatedSrtObjWithOutEmoji = {
      ...result[counter_result],
      text: final_output2,
    };

    result[counter_result] = updatedSrtObjWithOutEmoji;
    counter_result = counter_result + 1;
  });
  return result;
};

export const updateTemplateDefaultTexts = (
  textArr: any[],
  updateText: string
) => {
  return textArr?.map((text: any) => {
    return {
      ...text,
      content: text.id
        ? text.id === ID_TITLE_TEXT
          ? updateText
          : null
        : updateText,
    };
  });
};

export const smartTransformToLowerCase = (
  text: string,
  prevText: string | undefined
) => {
  // use compromise to smartly transform to lower case
  let doc = nlp(text);

  // Convert all to lowercase
  doc.toLowerCase();

  // Capitalize 'I'
  doc.match("(i)").toTitleCase();

  // Capitalize titles (Dr, Mr, Er, etc.)
  doc.match("(#Honorific)").toTitleCase();

  // Check if prevText is a string and ends with a full stop, then capitalize the first word of text
  if (
    typeof prevText === "string" &&
    (prevText.trim().endsWith(".") ||
      prevText.trim().endsWith("!") ||
      prevText.trim().endsWith("?"))
  ) {
    doc.sentences().first().terms().first().toTitleCase();
  }

  const words = doc.text().split(/\s+/);

  // Iterate through the words
  for (let i = 1; i < words.length; i++) {
    // Check if the word follows a full stop and is not empty
    if (
      (words[i - 1].endsWith(".") ||
        words[i - 1].endsWith("?") ||
        words[i - 1].endsWith("!")) &&
      words[i].length > 0
    ) {
      // Capitalize the first letter of the word
      words[i] = words[i][0].toUpperCase() + words[i].slice(1);
    }
  }

  return words.join(" ");
};
