import { PlusCircleIcon } from "@heroicons/react/24/outline";

import { useAppSelector } from "@/store/hooks";

import { PlanType } from "@/enums";

import Swatch from "./Swatch";

type BrandColorPickerProps = {
  onChange: any;
  colors?: string[];
  color: string;
  setShowColorUploadModal: (value: boolean) => void;
};

const BrandColorPicker = ({
  onChange,
  color,
  colors = [],
  setShowColorUploadModal,
}: BrandColorPickerProps) => {
  const planType = useAppSelector(
    (state) => state.authState.userSubscription.planType
  );

  return (
    <div>
      <div className="w-full flex flex-wrap items-center">
        {colors.map((c, i) => (
          <Swatch
            key={i}
            color={c}
            onClick={onChange}
            focus={c === color}
          />
        ))}
        {planType === PlanType.FREE ? (
          <p className="text-sm text-gray-500 my-4">
            Brand Color Palette is a PRO feature
          </p>
        ) : (
          <PlusCircleIcon
            className="w-[36px] h-[46px] cursor-pointer -mt-1"
            onClick={() => {
              console.log("hello world");
              setShowColorUploadModal(true);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default BrandColorPicker;
