import { LinkIcon } from "@heroicons/react/20/solid";

import { ApiEndpoints } from "@/api/constants/ApiEndPoints";

export const LinkTiktokComponent = ({
  isRetrivingData,
  isSocialMediaLinked,
}: {
  isRetrivingData: boolean;
  isSocialMediaLinked: boolean;
}) => {
  const handleLinkTikTok = () => {
    if (!isRetrivingData && !isSocialMediaLinked) {
      window.location.replace(ApiEndpoints.TIKTOK_AUTH_URL);
    }
  };

  return (
    <div className="mt-16 text-center">
      <h3 className="text-xl font-semibold">
        Post directly to Tiktok from audyo
      </h3>
      <p className="text-sm font-light mt-5">
        Link your account to post videos from here <br />
        Free accounts can post to Tiktok
        <br />
        PRO users can post everywhere
      </p>
      {isRetrivingData ? (
        <button className="cursor-not-allowed flex items-center justify-center m-auto text-sm font-medium mt-12 bg-green-500 rounded text-white h-[40px] w-[205px]">
          Retrieving your details
        </button>
      ) : (
        <button
          className="flex items-center justify-center m-auto text-sm font-medium mt-12 bg-primary-600 hover:bg-primary-700 rounded text-white h-[40px] w-[140px]"
          onClick={handleLinkTikTok}
        >
          <LinkIcon className="mr-2 w-5 h-5" />
          Link Tiktok
        </button>
      )}
    </div>
  );
};
