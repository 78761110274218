import { useState } from "react";

import { useSelectedClips } from "@/context/ReviewClips";
import clsx from "clsx";
import { Alert, Checkbox, Tooltip } from "flowbite-react";

import {
  setCurrentSelectedClip,
  updateShowFullLengthVideo,
} from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { INTELLI_CLIP } from "@/constants";

import { Project } from "@/interfaces";

import { VideoLayout, PreviewScreenClipTypes } from "@/enums";

import PreviewClipVideoCard from "@/views/home/components/PreviewClip/PreviewClipVideoCard";

// const keyframeStyles = `
// @keyframes spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }
// `;

type PreviewClipsListProps = {
  microContent: any;
  onVideoClipChange: (clip: any, layout: VideoLayout, key: string) => void;
  setIsClipDeleteModal: (value: boolean) => void;
  currentType: PreviewScreenClipTypes;
  projectData: Project;
  parsedProjectData: any;
  setCurrentType: (value: PreviewScreenClipTypes) => void;
  showIntelliClipModal: boolean;
};

export const PreviewClipsList = ({
  microContent,
  onVideoClipChange,
  setIsClipDeleteModal,
  currentType,
  projectData,
  parsedProjectData,
  setCurrentType,
  showIntelliClipModal,
}: PreviewClipsListProps) => {
  const dispatch = useAppDispatch();
  const {
    selectedClips,
    toggleClipSelection,
    selectModeOn,
    selectedClipsCount,
  } = useSelectedClips();

  const currentSelectedClip = useAppSelector(
    (state) => state.homeState.currentSelectedClip
  );

  const alertForNoClipsGenerated = () => {
    return (
      <Alert
        color="warning"
        className="m-2.5 font-light"
      >
        <div>
          We were unable to create any clips from your video. This may be
          because your video is not in English or has no spoken words.
        </div>
        <div>
          Use the <span className="font-semibold"> create custom clip </span>{" "}
          button below to clip out sections as per your requirements
        </div>
      </Alert>
    );
  };

  const renderFullVideoCard = () => {
    return (
      <PreviewClipVideoCard
        clipThumbnail={parsedProjectData.thumbnail_url}
        clipTitle={projectData?.title.trim()}
        currentType={currentType}
        endTime={parsedProjectData?.duration}
        startTime={0}
        layout={VideoLayout.LAYOUT_16_9}
        selectModeOn={selectModeOn}
        isCurrentClip={
          currentSelectedClip === PreviewScreenClipTypes.FULL_VIDEO
        }
      />
    );
  };

  const intelliClipText = () => {
    return (
      <div
        className={`flex py-4 bg-white rounded-lg shadow-sm outline-2 ${
          selectModeOn ? "" : "w-full"
        }`}
      >
        <div className="w-full text-md text-[#51555e] px-2 text-center">
          <p>
            While you dive into other clips, we're shaping your{" "}
            <span className="font-bold">{INTELLI_CLIP}</span> 🤩 behind the
            scenes. An email heads your way when it's ready! 📥
          </p>
        </div>
      </div>
    );
  };

  const renderClipContainers = () => {
    return microContent[currentType]?.map((clip: any, index: number) => {
      const clipKey = `${clip.start}-${clip.end}`;
      const isSelected = selectedClips.has(clipKey);
      const isDisabled = selectedClipsCount >= 5 && !isSelected;

      return (
        <div
          className="flex items-center space-x-3 transition-transform p-0.5"
          key={`${index}-${clipKey}-container`}
        >
          {selectModeOn && (
            <div key={`${index}-${clipKey}`}>
              <Tooltip
                content="Max 5 clips allowed"
                placement="top"
                className={isDisabled && !isSelected ? "" : "invisible"}
              >
                <Checkbox
                  id={`select-multiple-clips-${index}-${clipKey}`}
                  className={clsx(
                    "!h-5 !w-5 ml-1 cursor-pointer",
                    selectModeOn ? "block" : "hidden",
                    isDisabled && "opacity-50  !cursor-not-allowed"
                  )}
                  onChange={() => toggleClipSelection(clip)}
                  checked={isSelected}
                  disabled={isDisabled}
                />
              </Tooltip>
            </div>
          )}

          <PreviewClipVideoCard
            key={`${index}-${clip.id}`}
            clip={clip}
            onVideoClipChange={onVideoClipChange}
            setIsClipDeleteModal={setIsClipDeleteModal}
            clipTitle={clip.gist?.trim()}
            currentType={currentType}
            clipThumbnail={clip?.imageUrl}
            endTime={clip?.end}
            startTime={clip?.start}
            layout={
              currentType === PreviewScreenClipTypes.CHAPTERS
                ? VideoLayout.LAYOUT_16_9
                : clip.hasTwoFace
                ? VideoLayout.LAYOUT_9_16_2
                : VideoLayout.LAYOUT_9_16_1
            }
            selectModeOn={selectModeOn}
            isCurrentClip={currentSelectedClip === clip.clipId}
          />
        </div>
      );
    });
  };

  const renderClips = () => {
    if (!microContent) {
      setCurrentType(PreviewScreenClipTypes.FULL_VIDEO);
    }
    if (currentType === PreviewScreenClipTypes.FULL_VIDEO) {
      dispatch(setCurrentSelectedClip(PreviewScreenClipTypes.FULL_VIDEO));
      dispatch(updateShowFullLengthVideo(true));
      return (
        <>
          {microContent &&
          !microContent[PreviewScreenClipTypes.CHAPTERS].length &&
          !microContent[PreviewScreenClipTypes.CHAPTERS].length
            ? alertForNoClipsGenerated()
            : null}
          {renderFullVideoCard()}
        </>
      );
    } else {
      return (
        <div className="flex flex-col gap-y-3  pb-16 overflow-x-auto overflow-y-auto">
          {showIntelliClipModal &&
            currentType === PreviewScreenClipTypes.SHORTS &&
            intelliClipText()}
          {microContent && microContent[currentType]?.length
            ? renderClipContainers()
            : (() => {
                if (
                  microContent &&
                  microContent[PreviewScreenClipTypes.CHAPTERS]?.length
                ) {
                  setCurrentType(PreviewScreenClipTypes.CHAPTERS);
                } else if (
                  microContent &&
                  microContent[PreviewScreenClipTypes.SHORTS]?.length
                ) {
                  setCurrentType(PreviewScreenClipTypes.SHORTS);
                } else {
                  setCurrentType(PreviewScreenClipTypes.FULL_VIDEO);
                }
              })()}
        </div>
      );
    }
  };

  return <>{renderClips()}</>;
};
