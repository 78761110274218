import { useMutation, useQueryClient } from "@tanstack/react-query";

import { deleteAsset } from "@/api/requests";

import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

export default function useDeleteAsset(successCB?: () => void) {
  const queryClient = useQueryClient();

  return useMutation(
    (assetID: string | number | undefined) => deleteAsset(assetID),
    {
      onSuccess: () => {
        if (successCB) {
          successCB();
        } else {
          showNotification(
            "Asset deleted successfully",
            notificationType.SUCCESS
          );
        }
        queryClient.invalidateQueries({ queryKey: ["Assets"] });
        queryClient.invalidateQueries({ queryKey: ["Assets-count"] });
      },
      onError: () => {
        showNotification(
          "Something went wrong, please try again!",
          notificationType.FAIL
        );
      },
    }
  );
}
