import { SVGProps } from "react";

const Spider = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={63}
    height={29}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.189}
      d="M31.51 6.252 27.378 18.19l12.23.87-1.297 4.883-4.293-2.306-.443 1.279 6.048 5.154 5.088-9.05-2.921-4.572 9 5.112-.9 4.864-4.264 1.571.68 1.434L52.914 27l2.05-12.15-9.65-5.5 11.375 1.645 4.057 10.908 1.254-.796-1.594-11.236-6.004-3.585-11.58-.48 6.46-2.83L48.989 1l-10.34 3.856-7.139 1.396Z"
    />
    <path
      fill="#000"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.189}
      d="m31.491 6.252 4.13 11.937-12.229.87 1.299 4.883 4.29-2.306.444 1.279-6.048 5.154-5.088-9.05 2.921-4.572-9 5.112.9 4.864 4.264 1.571-.68 1.434L10.087 27l-2.05-12.15 9.65-5.5L6.31 10.995 2.254 21.903 1 21.107 2.594 9.871l6.004-3.585 11.579-.48-6.46-2.83L14.013 1l10.34 3.856 7.138 1.396Z"
    />
    <path
      fill="#FF7A15"
      d="M30.071 13.322c.321-.6-.554-1.668-1.955-2.387-1.4-.72-2.796-.816-3.117-.216-.32.6.554 1.668 1.955 2.388 1.4.719 2.796.815 3.117.216ZM35.148 13.128c1.4-.72 2.276-1.788 1.955-2.388-.321-.6-1.717-.503-3.118.216-1.4.719-2.276 1.788-1.955 2.388.321.6 1.717.503 3.118-.216Z"
    />
  </svg>
);

export default Spider;
