import { useEffect, useMemo, useRef, useState } from "react";

import { PauseIcon, PlayIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import { Spinner } from "flowbite-react";
import PubSub from "pubsub-js";
import WaveSurfer from "wavesurfer.js";

import { AUDIO_PLAYING_STATUS } from "@/constants";

import { secondsToMinSec } from "@/helpers";

import { AudioCardType } from "@/enums";

import WaveFormAnimation from "@/components/WaveFormAnimation/WaveFormAnimation";

type MediaCardProps = {
  audioUrl: string;
  playing: boolean;
  togglePlay: (e: any) => void;
  duration: number;
  waveformRef: React.MutableRefObject<HTMLDivElement | null>;
  assetName?: string;
  currentTime: number;
};

type BrandkitCardProps = {
  playing: boolean;
  togglePlay: (e: any) => void;
  waveformRef: React.MutableRefObject<HTMLDivElement | null>;
  duration: number;
  audioUrl: string;
};

type AudioCardProps = {
  audioUrl: string;
  audioCardType: AudioCardType;
  volume?: number;
  assetName?: string;
};

const BrandKitCard = ({
  playing,
  togglePlay,
  waveformRef,
  duration,
}: BrandkitCardProps) => {
  return (
    <div className="bg-white rounded-md py-2 px-1 relative flex flex-col justify-center items-center h-full">
      <div className="flex mt-6 mb-2">
        {" "}
        {duration ? (
          <button
            onClick={togglePlay}
            className="p-2 bg-blue-500 w-max rounded-full"
          >
            {playing ? (
              <PauseIcon className="w-6 text-white" />
            ) : (
              <PlayIcon className="w-6 text-white" />
            )}
          </button>
        ) : (
          <button className="bg-blue-500  rounded-full p-2">
            <Spinner
              size="md"
              className="-mt-0.5"
            />
          </button>
        )}
      </div>
      <div
        ref={waveformRef}
        style={{ position: "relative", width: "100%", height: "60px" }}
        className={clsx(" mt-2", !duration && "!absolute")}
      ></div>

      {!duration && (
        <div
          className="mb-1 mt-6"
          style={{ position: "relative", width: "100%", height: "20px" }}
        >
          <WaveFormAnimation bars={25} />
        </div>
      )}
    </div>
  );
};

const MediaSectionCard = ({
  audioUrl,
  playing,
  togglePlay,
  duration,
  waveformRef,
  assetName,
  currentTime,
}: MediaCardProps) => {
  const fileName = useMemo(
    () => assetName || audioUrl.substring(audioUrl.lastIndexOf("/") + 1),
    [audioUrl]
  );
  return (
    <div className="bg-white rounded-md py-2 px-1 relative flex flex-col justify-center items-center h-full">
      <div className="flex">
        {" "}
        {duration ? (
          <button
            onClick={togglePlay}
            className="p-2 bg-blue-500 w-max rounded-full"
          >
            {playing ? (
              <PauseIcon className="w-4 text-white" />
            ) : (
              <PlayIcon className="w-4 text-white" />
            )}
          </button>
        ) : (
          <button className="bg-blue-500 w-7 py-0.5  rounded-full cursor-default">
            <Spinner
              size="xs"
              className="-mt-0.5"
            />
          </button>
        )}
      </div>
      <div className="w-full mt-1 mb-2 text-center">
        <p className="text-[10px] truncate text-left w-[70px] mx-auto mt-1 text-gray-600 font-medium">
          {fileName}
        </p>
        {playing ? (
          <span className="text-[10px] text-gray-400">
            {secondsToMinSec(currentTime)} / {secondsToMinSec(duration)}
          </span>
        ) : (
          <span className="text-[10px] text-gray-400">
            {secondsToMinSec(duration)}
          </span>
        )}
      </div>

      <div
        ref={waveformRef}
        style={{ position: "relative", width: "100%", height: "10px" }}
        className={clsx("pb-4", !duration && "!absolute")}
      ></div>

      {!duration && (
        <div
          className="mt-4"
          style={{ position: "relative", width: "100%", height: "10px" }}
        >
          <WaveFormAnimation bars={15} />
        </div>
      )}
    </div>
  );
};

const FullWidthCard = ({
  audioUrl,
  playing,
  togglePlay,
  duration,
  waveformRef,
  assetName,
  currentTime,
}: MediaCardProps) => {
  const fileName = useMemo(
    () => assetName || audioUrl.substring(audioUrl.lastIndexOf("/") + 1),
    [audioUrl]
  );
  return (
    <div
      className="bg-white rounded-md relative flex flex-col h-full"
      id="full-width-card-audio"
    >
      <div
        className="flex items-center space-x-2  px-2 py-2 cursor-pointer"
        onClick={togglePlay}
      >
        <div className="flex">
          {" "}
          {duration ? (
            <button
              onClick={togglePlay}
              className="p-2 bg-blue-500 w-max rounded-full"
            >
              {playing ? (
                <PauseIcon className="w-4 text-white" />
              ) : (
                <PlayIcon className="w-4 text-white" />
              )}
            </button>
          ) : (
            <button className="bg-blue-500 w-7 py-0.5  rounded-full cursor-default">
              <Spinner
                size="xs"
                className="-mt-0.5"
              />
            </button>
          )}
        </div>
        <div className=" w-full mt-1 mb-2 text-left">
          <p className="text-sm truncate text-gray-600  mt-1 w-3/4 font-medium">
            {fileName}
          </p>
          {playing ? (
            <span className="text-xs text-gray-400">
              {secondsToMinSec(currentTime)} / {secondsToMinSec(duration)}
            </span>
          ) : (
            <span className="text-xs text-gray-400">
              {secondsToMinSec(duration)}
            </span>
          )}
        </div>
      </div>

      <div
        ref={waveformRef}
        style={{ position: "relative", width: "100%", height: "10px" }}
        className={clsx("pb-4 mb-2 px-2", !duration && "!absolute")}
        onClick={() => {
          if (!playing) {
            togglePlay(null);
          }
        }}
      ></div>

      {!duration && (
        <div
          className="pb-4 mb-2 px-2"
          style={{ position: "relative", width: "100%", height: "10px" }}
        >
          <WaveFormAnimation bars={55} />
        </div>
      )}
    </div>
  );
};

const AudioCard = ({
  audioUrl,
  audioCardType,
  volume = 30,
  assetName,
}: AudioCardProps) => {
  const waveformRef = useRef<HTMLDivElement | null>(null);

  const wavesurfer = useRef<WaveSurfer | null>(null);
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    if (waveformRef.current) {
      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: "#bfdbfe",
        progressColor: "#2563eb",
        cursorColor: "transparent",
        barWidth: 3,
        height: audioCardType === AudioCardType.BRAND_KIT ? 40 : 15,
        cursorWidth: 0,
        barRadius: 3,
        normalize: true,
        interact: true,
      });

      wavesurfer.current.load(audioUrl);

      wavesurfer.current.on("play", () => setPlaying(true));
      wavesurfer.current.on("pause", () => setPlaying(false));
      wavesurfer.current.on("finish", () => setPlaying(false));
      wavesurfer.current.on("ready", () => {
        setDuration(wavesurfer.current?.getDuration() || 0);
      });
      wavesurfer.current.on("timeupdate", (currentTime) =>
        setCurrentTime(currentTime)
      );
    }

    const handleAudioPlay = (msg: string, data: { audioUrl: string }) => {
      if (data.audioUrl !== audioUrl) {
        wavesurfer.current?.pause();
        wavesurfer.current?.seekTo(0);
      }
    };
    const token = PubSub.subscribe(AUDIO_PLAYING_STATUS, handleAudioPlay);

    return () => {
      wavesurfer.current?.destroy();
      PubSub.unsubscribe(token);
    };
  }, [audioUrl]);

  useEffect(() => {
    wavesurfer.current?.setVolume(volume / 100);
  }, [volume]);

  const togglePlay = (e: any) => {
    if (e) e.stopPropagation();

    if (!playing) {
      PubSub.publish(AUDIO_PLAYING_STATUS, { audioUrl });
    }

    wavesurfer.current?.playPause();
  };

  switch (audioCardType) {
    case AudioCardType.BRAND_KIT:
      return (
        <BrandKitCard
          playing={playing}
          togglePlay={togglePlay}
          waveformRef={waveformRef}
          duration={duration}
          audioUrl={audioUrl}
        />
      );
    case AudioCardType.MEDIA_SECTION_CARD:
      return (
        <MediaSectionCard
          audioUrl={audioUrl}
          playing={playing}
          togglePlay={togglePlay}
          duration={duration}
          waveformRef={waveformRef}
          assetName={assetName}
          currentTime={currentTime}
        />
      );
    case AudioCardType.FULL_WIDTH_CARD:
      return (
        <FullWidthCard
          audioUrl={audioUrl}
          playing={playing}
          togglePlay={togglePlay}
          duration={duration}
          waveformRef={waveformRef}
          assetName={assetName}
          currentTime={currentTime}
        />
      );

    default:
      return null;
  }
};

export default AudioCard;
