import { SVGProps, memo } from "react";

import clsx from "clsx";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    className={clsx(props.className, "fill-current")}
    {...props}
  >
    <path
      fill="currentColor"
      d="M21 4.286v3.428a1.286 1.286 0 0 1-2.571 0V5.571h-5.143V18.43h2.143a1.286 1.286 0 1 1 0 2.571H8.57a1.286 1.286 0 0 1 0-2.571h2.143V5.57H5.571v2.143a1.286 1.286 0 1 1-2.571 0V4.286A1.286 1.286 0 0 1 4.286 3h15.428A1.286 1.286 0 0 1 21 4.286Z"
    />
  </svg>
);
const TextsIcon = memo(SvgComponent);
export default TextsIcon;
