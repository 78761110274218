import { OnboardingReferer, OnboardingUserType } from "@/enums";

import Facebook from "@/assets/icons/onboarding/facebook.png";
import Instagram from "@/assets/icons/onboarding/instagram.png";
import Linkedin from "@/assets/icons/onboarding/linkedin.png";
import { ReactComponent as MarketingIcon } from "@/assets/icons/onboarding/marketing.svg";
import { ReactComponent as OtherIcon } from "@/assets/icons/onboarding/other-occ.svg";
import { ReactComponent as PodCasterIcon } from "@/assets/icons/onboarding/podcaster.svg";
import { ReactComponent as BusinessOwnerIcon } from "@/assets/icons/onboarding/stocks.svg";
import Tiktok from "@/assets/icons/onboarding/tiktok.png";
import Twitter from "@/assets/icons/onboarding/twitter.png";
import { ReactComponent as VideoCreatorIcon } from "@/assets/icons/onboarding/video-creator.svg";
import { ReactComponent as VideoEditorIcon } from "@/assets/icons/onboarding/video-editor.svg";
import Youtube from "@/assets/icons/onboarding/youtube.png";

export const OCCUPATION_LIST = [
  {
    id: "occupation-podcaster",
    value: "Podcaster",
    icon: PodCasterIcon,
  },
  {
    id: "occupation-video-creator",
    value: "Video creator",
    icon: VideoCreatorIcon,
  },
  {
    id: "occupation-video-editor",
    value: "Video editor",
    icon: VideoEditorIcon,
  },
  {
    id: "occupation-marketing-professional",
    value: "Marketing professional",
    icon: MarketingIcon,
  },
  {
    id: "occupation-business-owner",
    value: "Business owner",
    icon: BusinessOwnerIcon,
  },
  {
    id: "occupation-other",
    value: "Other",
    icon: OtherIcon,
  },
];

export const USER_WANTS_LIST = [
  {
    id: "make-short-clips",
    value: "Make short clips from long form videos",
  },
  {
    id: "edit-subtitles",
    value: "Edit subtitles",
  },
  {
    id: "cut-crop-resize-videos",
    value: "Cut, crop & resize videos",
  },
  {
    id: "noise-removal",
    value: "Noise removal",
  },
  {
    id: "filler-word-removal",
    value: "Filler word removal (like um, uh)",
  },
  {
    id: "publish-on-multiple-platforms",
    value: "Publish my clips to multiple platforms",
  },
  {
    id: "add-music-to-videos",
    value: "Add music to my videos",
  },
  {
    id: "add-animations-to-videos",
    value: "Add animations and effects",
  },
  {
    id: "join-combine-clips",
    value: "Join or combine multiple videos",
  },
  {
    id: "use-templates",
    value: "Use templates",
  },
];

export const USER_REFERRAL_LIST = [
  {
    id: "referral-friend",
    value: OnboardingReferer.FRIENDS,
  },
  {
    id: "referral-google",
    value: OnboardingReferer.GOOGLE,
  },
  {
    id: "referral-youtube",
    value: OnboardingReferer.YOUTUBE,
  },
  {
    id: "referral-twitter",
    value: OnboardingReferer.TWITTER,
  },
  {
    id: "referral-tiktok",
    value: OnboardingReferer.TIKTOK,
  },
  {
    id: "referral-other",
    value: OnboardingReferer.OTHERS,
  },
];

export const SOCIAL_MEDIA_LIST_WELCOME_PAGE = [
  {
    id: "social-media-facebook",
    value: "Facebook",
    icon: Facebook,
  },
  {
    id: "social-media-instagram",
    value: "Instagram",
    icon: Instagram,
  },
  {
    id: "social-media-youtube",
    value: "Youtube",
    icon: Youtube,
  },
  {
    id: "social-media-twitter",
    value: "Twitter",
    icon: Twitter,
  },
  {
    id: "social-media-tiktok",
    value: "Tiktok",
    icon: Tiktok,
  },
  {
    id: "social-media-linkedin",
    value: "Linkedin",
    icon: Linkedin,
  },
];
