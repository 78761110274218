import { useQuery } from "@tanstack/react-query";

import { getUserOnBoardingData } from "@/api/requests";

export default function useGetUserOnBoardingData(
  userId: string,
  isQueryEnabled = false
) {
  return useQuery({
    queryKey: ["user-onboarding-data", userId],
    queryFn: () => getUserOnBoardingData(userId),
    refetchOnWindowFocus: false,
    staleTime: 300000,
    enabled: isQueryEnabled,
  });
}
