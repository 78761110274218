import { IconPropType } from "@/utils/constants";

export const TiktokIcon = ({ fillColor }: IconPropType) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4997 5.98418C13.4811 5.8744 12.524 5.49295 11.7227 4.90663L10.9274 4.32469V5.31013V10.8985C10.9274 13.4341 8.88688 15.5 6.38024 15.5C3.87209 15.5 1.83301 13.4558 1.83301 10.8985C1.83301 8.53858 3.56178 6.62332 5.78806 6.33416V8.09351C4.50329 8.36788 3.52947 9.5232 3.52947 10.8985C3.52947 12.4639 4.78116 13.7638 6.35718 13.7638C7.93321 13.7638 9.18488 12.4639 9.18488 10.8985V0.5H10.9099C11.1297 2.41423 12.5966 3.95313 14.4997 4.23555V5.98418Z"
        fill={fillColor}
        stroke={fillColor}
      />
    </svg>
  );
};
