import { useState } from "react";
import { useNavigate } from "react-router";

import { Button, TextInput, Spinner } from "flowbite-react";

import { toggleManageSubscriptionModal } from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { deleteUserAccount } from "@/api/requests";

import { RouterPath } from "@/enums";

import BaseModal from "@/components/BaseModal/BaseModal";

const AccountDeleteModal = ({
  setAccountDeleteModal,
  displayProjectDeleteModal,
}: {
  setAccountDeleteModal: (value: boolean) => void;
  displayProjectDeleteModal: boolean;
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isPaddle = useAppSelector(
    (state) => state.authState.userSubscription?.isPaddle
  );
  const cancelOn = useAppSelector(
    (state) => state.authState.userSubscription?.cancelOn
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handelRedirectToSubscription = () => {
    navigate(RouterPath.MANAGE_SUBSCRIPTION);
  };

  const handleDelete = async () => {
    if (inputValue === "delete") {
      setIsDeleting(true);
      await deleteUserAccount();
      setIsDeleting(false);
    }
  };

  const cancelSubscriptionRedirect = () => {
    if (isPaddle) {
      dispatch(toggleManageSubscriptionModal(true));
    } else {
      handelRedirectToSubscription();
    }
  };

  const cancelSubRequired = isPaddle && !cancelOn;

  return (
    <BaseModal
      isModalOpen={displayProjectDeleteModal}
      handelModalOpen={() => {}}
    >
      <div className="max-w-screen-sm w-full bg-white p-6 pb-10 pt-8">
        <div className="w-full relative">
          <p className="mb-4 text-2xl text-center font-bold text-gray-900">
            Do you really want to delete your account? 😔
          </p>
          <p className="ml-2 mb-4 text-center text-gray-900">
            This action is <span className="text-red-600"> not reversible</span>{" "}
            and all your data{" "}
            <span className=" text-red-600">will be lost</span>.<br /> Type{" "}
            <span className="text-red-600">"delete"</span> to confirm.{" "}
          </p>
          <TextInput
            type="text"
            name="confirmation"
            id="edit-text"
            placeholder="Type here"
            className="w-60 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white block mx-auto text-center"
            value={inputValue}
            onChange={handleInputChange}
            disabled={cancelSubRequired}
          />
          {cancelSubRequired ? (
            <div className="mt-2 text-center">
              Please{" "}
              <span
                className="underline font-semibold text-blue-500 cursor-pointer"
                onClick={cancelSubscriptionRedirect}
              >
                cancel subscription
              </span>{" "}
              before deleting your account
            </div>
          ) : null}
        </div>
        <div className="mt-7 flex flex-row-reverse gap-10 md:gap-10 mt-3">
          <Button
            id="delete-account"
            color="failure"
            className="w-full rounded-lg text-center text-sm font-medium text-white"
            onClick={handleDelete}
            disabled={inputValue !== "delete"}
          >
            {isDeleting ? (
              <Spinner
                size="sm"
                light={true}
              />
            ) : (
              <span>Delete</span>
            )}
          </Button>
          <Button
            id="cancel-account-deletion"
            onClick={() => setAccountDeleteModal(false)}
            color="light"
            className="w-full rounded-lg border border-red-500 bg-white text-center text-sm font-medium text-blue-500 hover:bg-blue-50"
          >
            Cancel
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};

export default AccountDeleteModal;
