import { fabric as fabricjs } from "fabric";
import { nanoid } from "nanoid";

import { store } from "@/store";
import { updateAllSceneChanges } from "@/store/editorSlice";

import api from "@/api/api";
import { ApiEndpoints } from "@/api/constants/ApiEndPoints";
import { uploadFileToS3 } from "@/api/requests";
import { getPexelsAssets } from "@/api/usePexel";

import {
  EXPORT_RESOLUTIONS,
  SUBTITLE_ASSET_PREFIX,
  BUFFER_DIMENSIONS,
  SUBTITLE_EMOJI_PREFIX,
  SOCIAL_MEDIA_SELECTED_IMAGE,
  INTELLI_CLIP_TAG,
} from "@/constants";

import {
  alphaNumericNanoId,
  getRandomElement,
  getUniqueRandomBrightColor,
  hasMoreThanKeys,
  sleep,
} from "@/helpers/common";
import {
  GroupedTextProps,
  getFabricElementTextProperties,
  getFabricVideo,
  getFontSizeFromGroupText,
  getTextbox,
  getWidthFromGroupText,
  setFabricElementTextProperties,
  updateGroupedText,
} from "@/helpers/fabricjs";
import {
  smartTransformToLowerCase,
  updateTemplateDefaultTexts,
} from "@/helpers/text";

import { notificationType } from "@/utils/constants";
import { scaleMultiplier } from "@/utils/scaleMultiplier";
import { showNotification } from "@/utils/showNotification";

import {
  FaceCoordinates,
  Scene,
  TextboxStyles,
  VideoPosition,
  Template,
  EditorAppliedTemplate,
  Position,
  SelectionStyles,
  TextboxProps,
  OutroObject,
} from "@/interfaces";

import {
  AssetTags,
  ExportQuality,
  FontStyle,
  FontWeight,
  PlanType,
  SimpleAssetType,
  SubtitleStyle,
  TextElementType,
  VideoLayout,
  KeyCode,
} from "@/enums";

import {
  INIT_SUBS_CONFIG,
  INIT_SUB_EMOJI_CONFIG,
  INIT_TEXT_STYLES,
  MIN_LINE_HEIGHT,
  TEXT_TRANSFORM,
} from "@/views/editor/constant";

const EMOJI_REGEX = /\p{Extended_Pictographic}/u;
export const getCropArea = (fabricVideo: any, backGroundClipPath: any) => {
  const scaleFactor = getScaleFactor(backGroundClipPath);

  const clipPath = fabricVideo.clipPath;

  const crop_x = clipPath.left - fabricVideo.left;
  const crop_y = clipPath.top - fabricVideo.top;
  const position_x = clipPath.left - backGroundClipPath.left;
  const position_y = clipPath.top - backGroundClipPath.top;

  // calculate crop height in following cases
  let crop_height = 0;
  const videoHeight = fabricVideo.height * fabricVideo.scaleY;
  const videoWidth = fabricVideo.width * fabricVideo.scaleX;
  clipPath.bottom = clipPath.top + clipPath.height;
  fabricVideo.bottom = fabricVideo.top + videoHeight;
  if (
    clipPath.top >= fabricVideo.top &&
    clipPath.bottom <= fabricVideo.bottom
    // video is outside the clippath
  ) {
    crop_height = clipPath.height;
  } else if (
    clipPath.top <= fabricVideo.top &&
    clipPath.bottom >= fabricVideo.bottom
    // video is inside the clippath
  ) {
    crop_height = videoHeight;
  } else if (
    clipPath.top >= fabricVideo.top &&
    clipPath.bottom >= fabricVideo.bottom
    // video bottom is inside the clippath and top is outside the clippath
  ) {
    crop_height = fabricVideo.bottom - clipPath.top;
  } else if (
    clipPath.top <= fabricVideo.top &&
    clipPath.bottom <= fabricVideo.bottom
    // video top is inside the clippath and bottom is outside the clippath
  ) {
    crop_height = clipPath.bottom - fabricVideo.top;
  }

  // calculate crop width in following cases
  let crop_width = 0;
  clipPath.right = clipPath.left + clipPath.width;
  fabricVideo.right = fabricVideo.left + videoWidth;
  if (
    clipPath.left >= fabricVideo.left &&
    clipPath.right <= fabricVideo.right
    // video is outside the clippath
  ) {
    crop_width = clipPath.width;
  } else if (
    clipPath.left <= fabricVideo.left &&
    clipPath.right >= fabricVideo.right
    // video is inside the clippath
  ) {
    crop_width = videoWidth;
  } else if (
    clipPath.left >= fabricVideo.left &&
    clipPath.right >= fabricVideo.right
    // video right is inside the clippath and left is outside the clippath
  ) {
    crop_width = fabricVideo.right - clipPath.left;
  } else if (
    clipPath.left <= fabricVideo.left &&
    clipPath.right <= fabricVideo.right
    // video left is inside the clippath and right is outside the clippath
  ) {
    crop_width = clipPath.right - fabricVideo.left;
  }

  const cropArea = {
    left: (crop_x >= 0 ? position_x : position_x - crop_x) * scaleFactor,
    top: (crop_y >= 0 ? position_y : position_y - crop_y) * scaleFactor,
    crop_x: crop_x > 0 ? crop_x * scaleFactor : 0,
    crop_y: crop_y > 0 ? crop_y * scaleFactor : 0,
    crop_width: Math.round(crop_width * scaleFactor),
    crop_height: Math.round(crop_height * scaleFactor),
    // get width using bounding rectangle
    scaled_video_width: Math.round(videoWidth * scaleFactor),
    scaled_video_height: Math.round(videoHeight * scaleFactor),
  };
  return cropArea;
};

export const getVideoCoordsDetails = (
  canvasObjects: any,
  selectedLayout: any
) => {
  const faceCropDetails: any = {
    face_1_config: {},
    face_2_config: {},
  };

  faceCropDetails.face_1_config = getCropArea(
    canvasObjects.videoElUp,
    canvasObjects.backGroundClipPath
  );

  if (selectedLayout === VideoLayout.LAYOUT_9_16_2) {
    faceCropDetails.face_2_config = getCropArea(
      canvasObjects.videoElDown,
      canvasObjects.backGroundClipPath
    );
  }

  return faceCropDetails;
};

export const getScaleFactor = (backGroundClipPath: any) => {
  const selectedLayout = store.getState().editorState.selectedLayout;
  const { output_width, output_height } = getOutputDimensions(selectedLayout);
  let scaleFactor = output_height / backGroundClipPath.height;

  if (selectedLayout === VideoLayout.LAYOUT_16_9) {
    scaleFactor = output_width / backGroundClipPath.width;
  }
  return scaleFactor;
};

export const getDefaultExportQuality = ({ width, height }: any) => {
  const planType = store.getState().authState.userSubscription.planType;

  if (planType === PlanType.FREE) {
    return ExportQuality.HD;
  }

  if (width >= 1920 || height >= 1080) {
    return ExportQuality.FHD;
  }

  return ExportQuality.HD;
};

export const getOutputDimensions = (selectedLayout: VideoLayout) => {
  const { exportQuality } = store.getState().editorState;

  const exportQualityDimensions =
    EXPORT_RESOLUTIONS[exportQuality][selectedLayout];
  const dimensions: {
    output_width: number;
    output_height: number;
  } = {
    output_width: exportQualityDimensions.width,
    output_height: exportQualityDimensions.height,
  };

  return dimensions;
};

export const convertToEven = (number: number) => {
  return number % 2 === 0 ? number : number + 1;
};

export const getProgressBarDetails = (
  canvasObjects: any,
  progressBarObject: any
) => {
  // in this case we can consider left as 0,
  // because it progress bar always start from starting of video
  const coordinate_left = 0;
  let coordinate_top;
  let progressBarHeight;
  let progressBarColor;
  if (progressBarObject) {
    progressBarColor = progressBarObject.fill;
    const scaleFactor = getScaleFactor(canvasObjects.backGroundClipPath);
    coordinate_top =
      (progressBarObject.top - canvasObjects.backGroundClipPath.top) *
      scaleFactor;
    progressBarHeight = Math.round(progressBarObject.height * scaleFactor);
  }

  return {
    height: progressBarHeight,
    color: progressBarColor,
    top: coordinate_top,
    left: coordinate_left,
  };
};

export const getLogoDetails = async (
  canvasObjects: any,
  assetObjects: any,
  clipTimings: any
) => {
  const { start, end } = clipTimings;
  if ([...Object.values(assetObjects)].length) {
    const imgArray = [...Object.values(assetObjects)].map(
      async (refObj: any) => {
        const { asset, metaData, assetType } = refObj;
        const { backGroundClipPath } = canvasObjects;

        let scaleFactor = getScaleFactor(backGroundClipPath);
        let image_width = asset.width * asset.scaleX * scaleFactor;
        let image_height = asset.height * asset.scaleX * scaleFactor;

        let coordinate_left =
          (asset.left - backGroundClipPath.left) * scaleFactor;
        let coordinate_top = (asset.top - backGroundClipPath.top) * scaleFactor;

        const response = {
          left: coordinate_left,
          top: coordinate_top,
          height: image_height,
          width: image_width,
          url: refObj?.url,
          assetType: assetType || SimpleAssetType.IMAGE,
          start: metaData?.start ? (metaData?.start - start) / 1000 : 0,
          end: metaData?.end
            ? (end - metaData?.end) / 1000 < 0
              ? (end - start) / 1000
              : (metaData?.end - start) / 1000
            : (end - start) / 1000,
          metaData: metaData,
        };

        if (refObj.file) {
          const res = await uploadFileToS3(refObj.file);
          response.url = res?.s3Url;
        }

        return response;
      }
    );
    const resolvedImgArray = await Promise.all(imgArray);
    return resolvedImgArray;
  }
  return [];
};

export const getInitialPositionFromCanvas = (canvasObjects: any) => {
  return {
    left: canvasObjects.backGroundClipPath.left,
    top: canvasObjects.backGroundClipPath.top,
    progressBarWidth: canvasObjects.backGroundClipPath.width,
  };
};

export const getFabricObjectById = function (
  id: number | string,
  fabricCanvas: any
) {
  // get objectByID
  const object = fabricCanvas.getObjects().find((o: any) => o.id === id);
  return object;
};

export const updateSubsConfig = (
  currentTemplate: any,
  templateScaleUpRatio: number,
  setter: any,
  subsEmojiConfig: any
) => {
  const {
    font_size,
    font_color,
    effect_type,
    font_face,
    bold,
    italic,
    underline,
    alignment,
    style,
    ...rest
  } = INIT_SUBS_CONFIG;
  const subtitleStyle = currentTemplate.subtitle.style
    ? currentTemplate.subtitle.style
    : style;

  setter({
    ...rest,
    style: subtitleStyle,
    noEffect: currentTemplate.subtitle.noEffect,
    shadow: currentTemplate.subtitle.shadow,
    stroke: currentTemplate.subtitle.stroke,
    textBgColor: {
      ...rest.textBgColor,
      ...currentTemplate.subtitle.textBgColor,
    },
    blockBackground: {
      ...rest.blockBackground,
      ...currentTemplate.subtitle.blockBackground,
    },
    font_size: currentTemplate.subtitle.fontSize * templateScaleUpRatio,
    font_color: currentTemplate.subtitle.fontColor,
    font_face: currentTemplate.subtitle.fontFamily,
    effect_type: currentTemplate.subtitle.effect_type,
    effect_color: currentTemplate.subtitle.effect_color,
    bold: currentTemplate.subtitle.bold,
    italic: currentTemplate.subtitle.italic,
    underline: currentTemplate.subtitle.underline,
    alignment: currentTemplate.subtitle.textAlign,
    line_height: currentTemplate.subtitle.lineHeight || MIN_LINE_HEIGHT,
    textTransform: currentTemplate.subtitle.textTransform,
    padding: currentTemplate.subtitle.padding || rest.padding,
    margin: currentTemplate.subtitle.margin || rest.margin,
  });

  if (currentTemplate?.emojiStyles) {
    subsEmojiConfig.current = { ...currentTemplate.emojiStyles };
  }
};

const getBaseTemplates = ({
  layout,
  userSavedTemplates,
  defaultTemplates,
}: {
  layout?: VideoLayout;
  userSavedTemplates?: any;
  defaultTemplates: any;
}) => {
  let localBaseTemplates = hasMoreThanKeys(
    store.getState().homeState.baseTemplates,
    1
  )
    ? { ...store.getState().homeState.baseTemplates }
    : { ...defaultTemplates };

  // need to update the baseTemplate if user does not have any template
  // if admin update the template we have to update it redux

  if (defaultTemplates && Object.keys(defaultTemplates).length) {
    Object.keys(localBaseTemplates).forEach(function (key) {
      localBaseTemplates[key] =
        defaultTemplates[key]?.length >= 0
          ? defaultTemplates[key]
          : Object.values(defaultTemplates[key]);
    });
  }

  // if user has saved templates then we need to add it to base templates
  if (userSavedTemplates && userSavedTemplates.length > 0 && layout) {
    const tempLocalBaseTemplates =
      localBaseTemplates[layout].length >= 0
        ? localBaseTemplates[layout]
        : Object.values(localBaseTemplates[layout]);

    localBaseTemplates[layout] = [
      ...userSavedTemplates,
      ...tempLocalBaseTemplates,
    ];
  }

  return localBaseTemplates;
};

export const getCurrentSelectedTemplate = (
  layout: VideoLayout,
  currentSelectedMicroContent: any,
  userSavedTemplates?: any,
  defaultTemplates?: any
): EditorAppliedTemplate => {
  const baseTemplates = getBaseTemplates({
    layout,
    userSavedTemplates,
    defaultTemplates,
  });
  // @ts-ignore
  let currentTemplate: any = currentSelectedMicroContent?.id
    ? baseTemplates[layout]?.filter(
        (item: any) => item.id === currentSelectedMicroContent.id
      )[0]
    : null;

  if (!currentTemplate) {
    currentTemplate = getRandomElement(baseTemplates[layout]);
  }

  currentTemplate = {
    ...currentTemplate,
    imageUrl: currentSelectedMicroContent.imageUrl,
    srt_string: currentSelectedMicroContent.srt_string,
    hasTwoFace: false,
    texts:
      currentTemplate?.texts?.length > 0
        ? updateTemplateDefaultTexts(
            currentTemplate.texts,
            currentSelectedMicroContent.gist
          )
        : [],
    gist: currentSelectedMicroContent.gist,
    face_coord: currentSelectedMicroContent.face_coord,
    start: currentSelectedMicroContent.start,
    end: currentSelectedMicroContent.end,
    chapter_start: currentSelectedMicroContent.chapter_start,
    chapter_end: currentSelectedMicroContent.chapter_end,
  };
  if (layout === VideoLayout.LAYOUT_9_16_2) {
    currentTemplate = {
      ...currentTemplate,
      hasTwoFace: true,
    };
  }

  return currentTemplate;
};

export const pollForCutVideo = async (
  jobId: string,
  pollCompleteCB: any,
  projectID: string,
  pollVideoCatchCB: any
) => {
  try {
    const response: any = await api.get(ApiEndpoints.CUT_VIDEO, {
      params: {
        job_id: jobId,
        project_id: projectID,
      },
    });

    if (response.data.status === "IN-PROGRESS") {
      await sleep(3000);
      await pollForCutVideo(jobId, pollCompleteCB, projectID, pollVideoCatchCB);
    } else if (response.data.status === "COMPLETE") {
      pollCompleteCB(response.data.video_clip_uri);
    } else {
      pollVideoCatchCB();
    }
  } catch {
    pollVideoCatchCB();
  }
};

const adjustTextTopToCenter = (textObj: any) => {
  const { top, maxHeight, height } = getFabricElementTextProperties(textObj, [
    "top",
    "height",
    "maxHeight",
  ]);
  const textCenter = top + (maxHeight - height) / 2;
  setFabricElementTextProperties(textObj, { top: textCenter });
};

export const adjustTextBoxHeight = (textObj: any) => {
  let { maxHeight } = getFabricElementTextProperties(textObj, [
    "maxHeight",
    "height",
    "fontSize",
  ]);
  if (maxHeight && maxHeight > 0) {
    adjustTextTopToCenter(textObj);
  }
};

export const getRandomTemplateByLayout = (
  layout: VideoLayout,
  defaultTemplates: any
): Template => {
  const baseTemplates = getBaseTemplates({ defaultTemplates });

  const template = getRandomElement(baseTemplates[layout]);
  return template;
};

export const getRandomTemplateForSceneChange = (
  baseTemplates: any
): Template => {
  const templates: any = baseTemplates
    ? Object.values(baseTemplates[VideoLayout.LAYOUT_9_16_1])?.filter(
        (template: any) => template.isCutMagicEnabled
      )
    : [];
  const randomTemplate = getRandomElement(templates);
  return randomTemplate;
};

/**
 * This function sets the dimensions and width of the fabric text element
 * @param fabricEl
 * @param setTextsObj
 * @param textsObjs
 */

export const onTextModified = ({
  fabricEl,
  setTextsObj,
  textsObjs,
  transform,
  fabricRef,
}: {
  fabricEl: any;
  setTextsObj: any;
  textsObjs: any;
  transform?: any;
  fabricRef?: any;
}) => {
  const scaleX = fabricEl.scaleX;
  // deep copy
  const newTextsObj = JSON.parse(JSON.stringify(textsObjs));
  const targetObj = newTextsObj[fabricEl.id];
  const styles = getTransformedTextboxStyles(targetObj?.style);

  const groupNewWidth = getWidthFromGroupText(fabricEl) * scaleX;
  let updatedFontSize: number = getFontSizeFromGroupText(fabricEl);
  // if we are modifying using the middle control point, we need to adjust the height of element
  // no changing of fontsize is required
  if (!!transform?.corner?.match?.("m")) {
    updateGroupedText(
      fabricEl,
      { ...styles, width: groupNewWidth },
      () => {
        fabricRef?.current?.requestRenderAll();
      },
      fabricRef.current
    );
  } else if (scaleX !== 1) {
    updatedFontSize = parseFloat((updatedFontSize * scaleX).toFixed(2));
    updateGroupedText(
      fabricEl,
      {
        ...styles,
        width: groupNewWidth,
        fontSize: updatedFontSize,
        padding: {
          top: scaleMultiplier(styles.padding.top, scaleX),
          right: scaleMultiplier(styles.padding.right, scaleX),
          bottom: scaleMultiplier(styles.padding.bottom, scaleX),
          left: scaleMultiplier(styles.padding.left, scaleX),
        },
        margin: {
          top: scaleMultiplier(styles.margin.top, scaleX),
          right: scaleMultiplier(styles.margin.right, scaleX),
          bottom: scaleMultiplier(styles.margin.bottom, scaleX),
          left: scaleMultiplier(styles.margin.left, scaleX),
        },
        blockBackground: {
          ...styles.blockBackground,
          radius: scaleMultiplier(styles.blockBackground.radius, scaleX),
          padding: scaleMultiplier(styles.blockBackground.padding, scaleX),
        },
        textBgColor: {
          ...styles.textBgColor,
          radius: scaleMultiplier(styles.textBgColor.radius, scaleX),
        },
      },
      () => {
        fabricEl.set("scaleX", 1);
        fabricEl.set("scaleY", 1);
        fabricRef.current.requestRenderAll();
      },
      fabricRef.current
    );
  }

  targetObj.style = {
    ...targetObj?.style,
    width: groupNewWidth,
    font_size: updatedFontSize,
    coordinate_top: fabricEl.top,
    coordinate_left: fabricEl.left,
    maxHeight: fabricEl.height,
  };
  setTextsObj(newTextsObj);
};

export const onAssetModified = (
  fabricEl: any,
  setBRollsObj: any,
  bRolls: any,
  newUpdatedAsset: any
) => {
  const newBRolls = { ...bRolls };

  if (newBRolls[fabricEl.id]) {
    newUpdatedAsset.on("modified", () => {
      if (newUpdatedAsset?.assetTag?.includes(AssetTags.B_ROLL)) {
        onAssetModified(fabricEl, setBRollsObj, bRolls, newUpdatedAsset);
      }
    });
    newBRolls[fabricEl.id].asset = newUpdatedAsset;
    setBRollsObj(newBRolls);
  }
};

// function to either get the value or return 0, if there is value then convert it string from number;
const getNumberToStringValue = (value: undefined | number | string) => {
  if (value && typeof value === "number") {
    return value.toString();
  } else if (value && typeof value === "string") {
    return value;
  }
  return "0";
};

export const getTransformedTextboxStyles = (textStylesObject: any) => {
  const textStyles: TextboxStyles = {
    fontSize: textStylesObject.font_size,
    fill: textStylesObject.font_color,
    fontFamily: textStylesObject.font_face,
    textAlign: textStylesObject.alignment,
    effectType: textStylesObject.effect_type,
    effectColor: textStylesObject.effect_color,
    underline: textStylesObject.underline,
    fontStyle: textStylesObject.italic ? FontStyle.ITALIC : FontStyle.NORMAL,
    fontWeight: textStylesObject.bold ? FontWeight.BOLD : FontWeight.NORMAL,
    lineHeight: textStylesObject.line_height,
    noEffect: textStylesObject.noEffect,
    stroke: textStylesObject.stroke,
    shadow: {
      ...textStylesObject.shadow,
      config: {
        blur: getNumberToStringValue(textStylesObject.shadow?.config?.blur),
        offsetX: getNumberToStringValue(
          textStylesObject.shadow?.config?.offsetX
        ),
        offsetY: getNumberToStringValue(
          textStylesObject.shadow?.config?.offsetY
        ),
        nonScaling: true,
      },
    },
    textBgColor: textStylesObject.textBgColor,
    blockBackground: textStylesObject.blockBackground,
    padding: textStylesObject.padding,
    margin: textStylesObject.margin,
    width: textStylesObject.width,
    maxHeight: textStylesObject.maxHeight,
    textTransform: textStylesObject?.textTransform || TEXT_TRANSFORM.LOWERCASE,
  };
  return textStyles;
};

function getImageDimensions(dataURL: string) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = dataURL;
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = (error) => {
      reject(error);
    };
  });
}

const optimizeValuesForShadow = async (
  fabricObj: any,
  ratio: number,
  scaledProperties: {
    coordinate_left: number;
    coordinate_top: number;
    width: number;
  }
) => {
  const bounds = { ...scaledProperties };

  if (fabricObj.shadow) {
    // generate png image of the object and get the bounds of the image

    const image = fabricObj.toDataURL({
      format: "png",
      enableRetinaScaling: false,
    });

    const dimensions: any = await getImageDimensions(image);

    bounds.width = dimensions.width * ratio;
    bounds.coordinate_left -=
      ((dimensions.width - fabricObj.width) / 2) * ratio;
    bounds.coordinate_top -=
      ((dimensions.height - fabricObj.height) / 2) * ratio;
  }

  return bounds;
};

export const getScaledPropertiesForText = async (
  fabricObj: any,
  canvasObjects: any,
  offsetToAdd: number
) => {
  const scaleFactor = getScaleFactor(canvasObjects.backGroundClipPath);

  const coordinate_left =
    (fabricObj.left - canvasObjects.backGroundClipPath.left - 100) *
    scaleFactor;
  const coordinate_top =
    (fabricObj.top - canvasObjects.backGroundClipPath.top - 100) * scaleFactor;
  const scaledWidth = (fabricObj.width + offsetToAdd) * scaleFactor;

  const properties = await optimizeValuesForShadow(fabricObj, scaleFactor, {
    coordinate_left,
    coordinate_top,
    width: scaledWidth,
  });
  return {
    left: Math.round(properties.coordinate_left),
    top: Math.round(properties.coordinate_top),
    width: Math.round(properties.width),
  };
};

export const generateTextFabricObj = (
  props: any,
  fabricCanvas: fabric.Canvas,
  loadAndUseFont: (textbox: fabric.Group, styles: GroupedTextProps) => void
) => {
  const textStyles = props.style;
  const transformedStyleObj: TextboxStyles =
    getTransformedTextboxStyles(textStyles);

  const fabricText = getTextbox({
    text: props.content,
    left: textStyles.coordinate_left,
    top: textStyles.coordinate_top,
    width: textStyles.width,
    style: transformedStyleObj,
    type: props.isSocialText
      ? TextElementType.SOCIAL_HANDLE_TEXT
      : TextElementType.NORMAL_TEXT,
  });
  loadAndUseFont(fabricText, transformedStyleObj as GroupedTextProps);
  updateGroupedText(fabricText, {
    ...transformedStyleObj,
    top: textStyles.coordinate_top,
    left: textStyles.coordinate_left,
    inRender: true,
  } as TextboxProps);
  // re-adjust the height of the outer rect and group element;
  fabricCanvas?.renderAll();
  return fabricText;
};

export const setStyleForSelectedText = (
  startIndex: number,
  endIndex: number,
  fabricEl: any,
  activeStyle: any
): SelectionStyles | undefined => {
  const { type, color } = activeStyle;
  switch (type) {
    case SubtitleStyle.RANDOM_WORD_COLOR_CHANGE:
    case SubtitleStyle.WORD_COLOR_CHANGE:
    case SubtitleStyle.WORD_APPENDED:
      return {
        style: { fill: color },
        startIndex,
        endIndex,
      };
    case SubtitleStyle.WORD_BACKGROUND_CHANGE:
      return {
        style: { textBackgroundColor: color },
        startIndex,
        endIndex,
      };
    default:
      break;
  }
};

export const getSubsPngImages = async (
  zip: any,
  subtitleTextObj: fabric.Textbox,
  fabricCanvas: fabric.Canvas,
  subsArr: any,
  canvasObjects: any,
  subsStyles: any,
  subtitleEmojiConfig?: any
) => {
  if (subtitleTextObj) {
    const fabricEl: any = await new Promise((resolve) =>
      subtitleTextObj?.clone(resolve)
    );
    fabricEl.set({
      clipPath: null,
    });
    const ratio = 1 / canvasObjects.videoElUp.scaleX;
    fabricCanvas.renderAll();

    const width = fabricEl.get("width");
    const allItemsEmpty = subsArr.every((item: any) => !item.text);
    // if all the items are empty string then return nothing
    if (allItemsEmpty) {
      return;
    }
    subsArr.forEach((textObj: any, index: number) => {
      if (textObj.emoji) {
        const emojiObj = textObj.emoji;
        const fabricEmojiEl = getTextbox({
          text: emojiObj.text,
          left: subtitleEmojiConfig.left,
          top: subtitleEmojiConfig.top,
          width: subtitleEmojiConfig.fontSize,
          maxHeight: subtitleEmojiConfig.fontSize,
          type: TextElementType.SUBTITLE_EMOJI,
          style: {
            ...getTransformedTextboxStyles(INIT_TEXT_STYLES),
            fontSize: subtitleEmojiConfig.fontSize,
            width: subtitleEmojiConfig.fontSize,
          },
        });
        const dataUrl = fabricEmojiEl.toDataURL({
          enableRetinaScaling: false,
          multiplier: ratio + 1,
        });
        const idx = dataUrl.indexOf("base64,") + "base64,".length; // or = 28 if you're sure about the prefix
        const content = dataUrl.substring(idx);
        zip.file(`${SUBTITLE_EMOJI_PREFIX + textObj.id}.png`, content, {
          base64: true,
        });
      }
      const top = fabricEl.get("top");
      const left = fabricEl.get("left");
      const transformedStyleObj = getTransformedTextboxStyles(subsStyles);
      const selectionStyle = setStyleForSelectedText(
        textObj.wordStartIndex,
        textObj.wordEndIndex,
        fabricEl,
        subsStyles.style
      );

      const props = {
        ...transformedStyleObj,
        top,
        left,
        width,
        text: textObj.text,
        selectionStyle,
        inRender: true,
        multiplier: ratio + 1,
      };
      updateGroupedText(fabricEl, props);
      const dataUrl = fabricEl.toDataURL({
        enableRetinaScaling: false,
        multiplier: ratio + 1,
      });
      const idx = dataUrl.indexOf("base64,") + "base64,".length; // or = 28 if you're sure about the prefix
      const content = dataUrl.substring(idx);
      zip.file(`${SUBTITLE_ASSET_PREFIX + textObj.id}.png`, content, {
        base64: true,
      });
    });
  }
};

export const centerFaceInClipPath = (
  fabricVideoElement: any,
  clipPath: any,
  allFaceCoords: any,
  videoLayout: VideoLayout,
  isRightFace: boolean = false
) => {
  allFaceCoords = [...allFaceCoords];
  // if (allFaceCoords.length >= 2) {
  //   // rearrange faces based on x coordinate
  //   // keep the left most face at index 0 and right most face at index 1
  //   allFaceCoords.sort((a: any, b: any) => a.x - b.x);
  //   // if length greater then 2 swap last face with index 1
  //   if (allFaceCoords.length > 2) {
  //     const lastFace = allFaceCoords.pop();
  //     allFaceCoords.splice(1, 0, lastFace);
  //   }
  // }
  // scale video to clip path height set to clip path top and left
  fabricVideoElement.scaleToHeight(clipPath.height);
  fabricVideoElement.set({
    left: clipPath.left,
    top: clipPath.top,
  });
  if (videoLayout === VideoLayout.LAYOUT_9_16_2) {
    fabricVideoElement.scaleToWidth(clipPath.width * 2);
  }

  if (videoLayout === VideoLayout.LAYOUT_9_16_2 && allFaceCoords[1]) {
    const leftFaceCoords = allFaceCoords[0];
    const rightFaceCoords = allFaceCoords[1];
    if (leftFaceCoords.x1 && rightFaceCoords.x1) {
      // get ratio of left and right faces width
      const ratio =
        (leftFaceCoords.x2 - leftFaceCoords.x1) /
        (rightFaceCoords.x2 - rightFaceCoords.x1);
      // if ratio is greater than 1, then left face is wider than right face
      if (ratio > 1) {
        // scale right face to left face width
        isRightFace &&
          fabricVideoElement.set({
            scaleX: fabricVideoElement.scaleX * ratio,
            scaleY: fabricVideoElement.scaleY * ratio,
          });
      } else {
        // scale left face to right face width
        !isRightFace &&
          fabricVideoElement.set({
            scaleX: fabricVideoElement.scaleX / ratio,
            scaleY: fabricVideoElement.scaleY / ratio,
          });
      }
    }
  }

  let faceCoords = allFaceCoords[0];
  // check if two face template is selected and are trying to center right face
  if (videoLayout === VideoLayout.LAYOUT_9_16_2 && isRightFace) {
    // use first face coords if right face is not present
    faceCoords = allFaceCoords[1] || faceCoords;
  }

  const scaledVideoWidth = fabricVideoElement.width * fabricVideoElement.scaleX;
  const scaledVideoHeight =
    fabricVideoElement.height * fabricVideoElement.scaleY;
  const scaleFactor = fabricVideoElement.scaleX;

  // center videos if clip path is close to 16:9 ratio
  const clipPathAspectRatio = clipPath.width / clipPath.height;
  if (
    !faceCoords ||
    (clipPathAspectRatio > 1 && videoLayout !== VideoLayout.LAYOUT_9_16_2)
  ) {
    fabricVideoElement.set({
      left: clipPath.left - (scaledVideoWidth - clipPath.width) / 2,
    });
    return;
  }

  const faceLeftScaled = faceCoords.x * scaleFactor;
  const faceTopScaled = faceCoords.y * scaleFactor;

  // center face inside clippath
  let videoLeft = clipPath.left - faceLeftScaled + clipPath.width / 2;
  // if video left is greater than clippath left, then set video left to clippath left
  videoLeft = videoLeft > clipPath.left ? clipPath.left : videoLeft;
  // if video left + video width is less than clippath left + clippath width, then set video left to clippath left + clippath width - video width
  videoLeft =
    videoLeft + scaledVideoWidth < clipPath.left + clipPath.width
      ? clipPath.left + clipPath.width - scaledVideoWidth
      : videoLeft;

  let videoTop = clipPath.top - faceTopScaled + clipPath.height / 2;
  // if video top is greater than clippath top, then set video top to clippath top
  videoTop = videoTop > clipPath.top ? clipPath.top : videoTop;
  // if video top + video height is less than clippath top + clippath height, then set video top to clippath top + clippath height - video height
  const videoBottom = videoTop + scaledVideoHeight;
  const clipPathBottom = clipPath.top + clipPath.height;
  videoTop =
    videoBottom < clipPathBottom
      ? clipPath.top + clipPath.height - scaledVideoHeight
      : videoTop;

  fabricVideoElement.set({
    left: videoLeft,
    top: videoTop,
  });
};

export const getTemplatesForLayout = (
  layout: string,
  userTemplatesData: any
): Template[] => {
  if (userTemplatesData && Object.keys(userTemplatesData).includes(layout)) {
    return Object.values(userTemplatesData[layout]);
  }
  return [];
};

type BaseTemplates = {
  [VideoLayout.LAYOUT_16_9]: Template[];
  [VideoLayout.LAYOUT_9_16_1]: Template[];
  [VideoLayout.LAYOUT_9_16_2]: Template[];
  [VideoLayout.LAYOUT_1_1]: Template[];
};

export const getAllBaseTemplates = (data: any, userSavedTemplates?: any) => {
  let templates = {
    [VideoLayout.LAYOUT_16_9]: [],
    [VideoLayout.LAYOUT_9_16_1]: [],
    [VideoLayout.LAYOUT_9_16_2]: [],
    [VideoLayout.LAYOUT_1_1]: [],
  } as BaseTemplates;

  if (!data) return templates;

  if (data && !Object.keys(data).length) return templates;

  Object.keys(data).forEach((key) => {
    templates = {
      ...templates,
      [key]:
        userSavedTemplates && Object.keys(userSavedTemplates).length
          ? [
              ...Object.values(userSavedTemplates[key]),
              ...Object.values(data[key]),
            ]
          : Object.values(data[key]),
    };
  });

  return templates;
};

export const generateTemplateName = (
  existingTemplateName: string,
  isAdmin = false
) => {
  const randomString = alphaNumericNanoId(8);

  if (!isAdmin) {
    return existingTemplateName
      ? existingTemplateName
      : `my-template-${randomString}`;
  }
  return !existingTemplateName?.includes("template")
    ? `template-${randomString}`
    : existingTemplateName;
};

export const initVideoPosition = {
  videoElUp: null,
  videoElDown: null,
};

export const isTwoFace = (faceCoords: FaceCoordinates[]) => {
  return faceCoords.length >= 2;
};

export const getSceneAtGivenTime = (time: number) => {
  const allSceneChanges = store.getState().editorState.allSceneChanges;
  const reversedSceneChanges = [...allSceneChanges].reverse();
  const currentScene =
    reversedSceneChanges.find((scene: any) => time >= scene.start) ||
    reversedSceneChanges[0];
  return currentScene;
};

export const onSceneDelete = (id: string) => {
  const allSceneChanges = store.getState().editorState.allSceneChanges;
  const updatedSceneChanges = allSceneChanges.filter(
    (scene) => scene.id !== id
  );
  store.dispatch(updateAllSceneChanges(updatedSceneChanges));
};

export const onSceneUpdate = (updatedScene: Scene) => {
  const allSceneChanges = store.getState().editorState.allSceneChanges;
  const updatedSceneChanges = allSceneChanges.map((scene) => {
    if (scene.id === updatedScene.id) {
      return updatedScene;
    }
    return scene;
  });
  store.dispatch(updateAllSceneChanges(updatedSceneChanges));
};

export const getSceneAtExactTime = (time: number) => {
  const allSceneChanges = store.getState().editorState.allSceneChanges;
  const currentScene = allSceneChanges.find(
    (scene: any) => time === scene.start
  );
  return currentScene;
};

export const onSceneAdd = (time: number, face: FaceCoordinates[] = []) => {
  const sceneAtGivenTime = getSceneAtExactTime(time);
  if (sceneAtGivenTime) {
    showNotification(
      "A scene already exists at this time. Please select a different time.",
      notificationType.WARN
    );
    return;
  }

  const allSceneChanges = store.getState().editorState.allSceneChanges;
  // add scene based on current player time and insert it in the array

  // find index where this new scene should be inserted
  const index = allSceneChanges.findIndex((scene) => time < scene.start);
  const newSceneChanges = [...allSceneChanges];
  // @ts-expect-error because not properly typed or we don't need the end time while adding the scene
  const newScene: Scene = {
    start: time,
    id: nanoid(),
    color: getUniqueRandomBrightColor(),
    face: face,
    isTwoFace: isTwoFace(face),
    position: initVideoPosition,
    // end: time + 100,
  };
  index >= 0
    ? newSceneChanges.splice(index, 0, newScene)
    : newSceneChanges.push(newScene);
  store.dispatch(updateAllSceneChanges(newSceneChanges));
  return newScene;
};

export const onVideoMovedOnCanvas = (e: any, isDown = false) => {
  const enableSceneChange = store.getState().editorState.enableSceneChange;

  if (enableSceneChange) {
    const currentScene = getSceneAtGivenTime(e.target._element.currentTime);
    if (currentScene) {
      const videoPosition: VideoPosition = {
        top: e.target.top,
        left: e.target.left,
        scaleX: e.target.scaleX,
        scaleY: e.target.scaleY,
      };
      // update the scene with the new video position
      const updatedScene: Scene = {
        ...currentScene,
        position: {
          videoElUp: isDown ? currentScene.position.videoElUp : videoPosition,
          videoElDown: isDown
            ? videoPosition
            : currentScene.position.videoElDown,
        },
      };
      onSceneUpdate(updatedScene);
    }
  }
};

export const getAllScenesBetweenMicroContent = () => {
  const allSceneChanges = store.getState().editorState.allSceneChanges;
  const currentSelectedMicroContent =
    store.getState().homeState.currentSelectedMicroContent;
  // find all scenes between micro content start and end
  const scenes = allSceneChanges.filter(
    (scene) =>
      scene.start >= currentSelectedMicroContent.start / 1000 &&
      scene.start < currentSelectedMicroContent.end / 1000
  );

  return scenes;
};

export const sortScenesByStartTime = (scenes: Scene[]) => {
  return [...scenes].sort((a, b) => {
    if (a.start < b.start) return -1;
    if (a.start > b.start) return 1;
    return 0;
  });
};

export const sortAndUpdateScenes = () => {
  const allSceneChanges = store.getState().editorState.allSceneChanges;
  const sortedScenes = sortScenesByStartTime(allSceneChanges);
  store.dispatch(updateAllSceneChanges(sortedScenes));
};

const checkHSnap = ({
  x,
  y,
  snapZone,
  e,
  type,
  fabricRef,
  line_h,
  backgroundClipPathRef,
}: {
  x: number;
  y: number;
  snapZone: number;
  e: any;
  type: number;
  fabricRef: any;
  line_h: any;
  backgroundClipPathRef: any;
}) => {
  if (x > y - snapZone && x < y + snapZone) {
    line_h.opacity = 1;
    line_h.bringToFront();
    let value = y;
    if (type === 1) {
      value = y;
    } else if (type === 2) {
      value = y - (e.target.width * e.target.scaleX) / 2;
    } else if (type === 3) {
      value = y + (e.target.width * e.target.scaleX) / 2;
    }
    e.target
      .set({
        left: value,
      })
      .setCoords();
    line_h
      .set({
        x1: y,
        y1: backgroundClipPathRef.current.top,
        x2: y,
        y2:
          backgroundClipPathRef.current.height +
          backgroundClipPathRef.current.top,
      })
      .setCoords();
    fabricRef.current.renderAll();
  }
};

// Check for vertical snapping
const checkVSnap = ({
  x,
  y,
  snapZone,
  e,
  type,
  fabricRef,
  line_v,
  backgroundClipPathRef,
}: {
  x: number;
  y: number;
  snapZone: number;
  e: any;
  type: number;
  fabricRef: any;
  line_v: any;
  backgroundClipPathRef: any;
}) => {
  if (x > y - snapZone && x < y + snapZone) {
    line_v.opacity = 1;
    line_v.bringToFront();
    let value = y;
    if (type === 1) {
      value = y;
    } else if (type === 2) {
      value = y - (e.target.height * e.target.scaleY) / 2;
    } else if (type === 3) {
      value = y + (e.target.height * e.target.scaleY) / 2;
    }
    e.target
      .set({
        top: value,
      })
      .setCoords();
    line_v
      .set({
        y1: y,
        x1: backgroundClipPathRef.current.left,
        y2: y,
        x2:
          backgroundClipPathRef.current.width +
          backgroundClipPathRef.current.left,
      })
      .setCoords();
    fabricRef.current.renderAll();
  }
};

export const centerLines = (
  line_h: any,
  line_v: any,
  fabricRef: any,
  e: any,
  backgroundClipPathRef: any
) => {
  line_h.opacity = 0;
  line_v.opacity = 0;
  fabricRef.current.renderAll();
  const snapZone = 5;
  const objLeft = e.target.left;
  const objTop = e.target.top;
  const objWidth = e.target.width * e.target.scaleX;
  const objHeight = e.target.height * e.target.scaleY;
  fabricRef.current.forEachObject(function (obj: any, index: any, array: any) {
    if (obj !== e.target && obj !== line_h && obj !== line_v) {
      if (obj.id === line_v || obj.id === line_h) {
        const check1 = [[objLeft, obj.left, 1]];
        const check2 = [[objTop, obj.top, 1]];

        for (let i = 0; i < check1.length; i++) {
          checkHSnap({
            x: check1[i][0],
            y: check1[i][1],
            snapZone,
            e,
            type: check1[i][2],
            fabricRef,
            line_h,
            backgroundClipPathRef,
          });
          checkVSnap({
            x: check2[i][0],
            y: check2[i][1],
            snapZone,
            e,
            type: check2[i][2],
            fabricRef,
            line_v,
            backgroundClipPathRef,
          });
        }
      } else {
        const check1 = [
          [objLeft, obj.left, 1],
          [objLeft, obj.left + (obj.width * obj.scaleX) / 2, 1],
          [objLeft, obj.left - (obj.width * obj.scaleX) / 2, 1],
          [objLeft + objWidth / 2, obj.left, 2],
          [objLeft + objWidth / 2, obj.left + (obj.width * obj.scaleX) / 2, 2],
        ];
        const check2 = [
          [objTop, obj.top, 1],
          [objTop, obj.top + (obj.height * obj.scaleY) / 2, 1],
          [objTop, obj.top - (obj.height * obj.scaleY) / 2, 1],
          [objTop + objHeight / 2, obj.top, 2],
          [objTop + objHeight / 2, obj.top + (obj.height * obj.scaleY) / 2, 2],
        ];
        for (let i = 0; i < check1.length; i++) {
          checkHSnap({
            x: check1[i][0],
            y: check1[i][1],
            snapZone,
            e,
            type: check1[i][2],
            fabricRef,
            line_h,
            backgroundClipPathRef,
          });
          checkVSnap({
            x: check2[i][0],
            y: check2[i][1],
            snapZone,
            e,
            type: check2[i][2],
            fabricRef,
            line_v,
            backgroundClipPathRef,
          });
        }
      }
    }
  });
};

export const showBleedAreaOnBoundaryMove = (
  obj: any,
  canvas: any,
  bleedArea: any,
  showBleedAreaDistance: number,
  bleedStrokeWidth: number,
  progressbarRef: any
) => {
  const objRect = obj.getBoundingRect();
  const canvasRect = canvas.getBoundingRect();
  if (obj !== progressbarRef.current) {
    // Calculate the distance from the object to the canvas boundaries
    const distanceFromLeft = obj.left - canvasRect.left;
    const distanceFromTop = obj.top - canvasRect.top;
    const distanceFromRight =
      canvasRect.left + canvasRect.width - (obj.left + objRect.width);
    const distanceFromBottom =
      canvasRect.top + canvasRect.height - (obj.top + objRect.height);

    // Check if the object is within the specified distance from the canvas boundaries
    const shouldShowBleedArea =
      distanceFromLeft <= showBleedAreaDistance ||
      distanceFromTop <= showBleedAreaDistance ||
      distanceFromRight <= showBleedAreaDistance ||
      distanceFromBottom <= showBleedAreaDistance;

    bleedArea.visible = shouldShowBleedArea; // Show/hide the bleed area based on the distance

    // Adjust the bleed area position to match the canvas boundaries
    bleedArea.bringToFront();
    bleedArea.set({
      left: canvasRect.left,
      top: canvasRect.top,
      width: canvasRect.width - bleedStrokeWidth,
      height: canvasRect.height - bleedStrokeWidth,
    });
  }
};

export const getRandomStartAndEndIndexToColor = (text: string) => {
  let words = getWordsArrayWithEmojis(text);
  let textToHighlight = "";
  // check if there is word of length 7 or more and get index
  // set textToHighlight to that word
  // if not, check how many words are there and if there is more than 2 word
  // set textToHighlight to the 2nd and 3rd word
  // if not, don't highlight anything
  let wordIndex = words.findIndex((word) => word.split(" ")[0].length >= 7);
  if (wordIndex !== -1) {
    textToHighlight = words[wordIndex];
  } else if (words.length > 2) {
    textToHighlight = `${words[1]} ${words[2]}`;
    wordIndex = 1;
  } else {
    return { startIndex: 0, endIndex: 0 };
  }

  const previousText = words.slice(0, wordIndex).join(" ");
  const graphemeArray = fabricjs.util.string.graphemeSplit(previousText);
  const startIndex = graphemeArray.length + (previousText.length > 0 ? 1 : 0); // +1 for space
  const endIndex =
    startIndex + fabricjs.util.string.graphemeSplit(textToHighlight).length;
  return { startIndex, endIndex };
};

export const getWordLengthWithEmoji = (word: string) => {
  const wordArr = word.split(" ");
  const lastWord = wordArr[wordArr.length - 1];
  const isEmoji = EMOJI_REGEX.test(lastWord);
  if (isEmoji) {
    return word.length - lastWord.length;
  }
  return word.length;
};

export const getWordsArrayWithEmojis = (text: string) => {
  // divide the sub into words
  let words = text.trim().split(" ");

  // if emojis is enabled then get emoji next to the word and treat it as a word
  // modify the words array
  const modifiedWords = [];
  // loop through the words and check if the next word is an emoji
  // if yes then add the emoji to the current word
  // if no then add the word to the modified words array
  // if the word is an emoji then add it to the modified words array
  // if the next word is an emoji skip it in the loop
  let skipIndex = -1;
  for (let i = 0; i < words.length; i++) {
    if (i === skipIndex) {
      continue;
    }
    const word = words[i];
    const nextWord = words[i + 1];
    const isEmoji = EMOJI_REGEX.test(nextWord);
    if (nextWord && isEmoji) {
      modifiedWords.push(word + " " + nextWord);
      skipIndex = i + 1;
    } else {
      modifiedWords.push(word);
    }
  }
  words = modifiedWords;
  return words;
};

export const getWordAtCurrentTime = (
  currentTime: number,
  sub: any,
  offset: number
) => {
  const subStart = sub.start + offset;
  const subEnd = sub.end + offset;

  // divide the sub into words
  let words = getWordsArrayWithEmojis(sub.text);
  // divide sub text time into words equally
  const subTime = subEnd - subStart;
  const wordTime = subTime / words.length;

  // check which word is present at the current time
  let currentWord = { ...sub };
  let wordStartIndex = 0;
  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    const wordStart = subStart + i * wordTime;
    const wordEnd = wordStart + wordTime;
    const wordEndIndex = getWordLengthWithEmoji(word) + wordStartIndex;
    if (currentTime >= wordStart && currentTime <= wordEnd) {
      currentWord = {
        text: word,
        start: wordStart,
        end: wordEnd,
        textBeforeCurrentWord: words.slice(0, i).join(" "),
        wordStartIndex,
        wordEndIndex,
      };
      break;
    }
    const graphemeArray = fabricjs.util.string.graphemeSplit(word);
    wordStartIndex += graphemeArray.length + 1;
  }
  return currentWord;
};

const getTwoWordsAtCurrentTime = (
  currentTime: number,
  sub: any,
  offset: number
) => {
  const subStart = sub.start + offset;
  const subEnd = sub.end + offset;

  // divide the sub into words
  let words = getWordsArrayWithEmojis(sub.text);
  // divide sub text time into words equally
  const subTime = subEnd - subStart;
  const wordTime = subTime / words.length;

  // divide the sub into two words
  // assign time based on number of words
  let currentWord = { ...sub };
  // loop through two words
  for (let i = 0; i < words.length; i = i + 2) {
    const word1 = words[i];
    const word2 = words[i + 1] || "";
    // if there are two words new word time will be twice the word time
    const wordTime2 = word2 ? wordTime * 2 : wordTime;
    const wordStart = subStart + i * wordTime;
    const wordEnd = wordStart + wordTime2;
    if (currentTime >= wordStart && currentTime <= wordEnd) {
      currentWord = {
        text: word1 + (word2 ? " " + word2 : word2),
        start: wordStart,
        end: wordEnd,
      };
      break;
    }
  }
  return currentWord;
};

export const addSubtitleStyles = (
  sub: any,
  config: any,
  fabricEl: any,
  microContent: any,
  currentTime: any
) => {
  const styles = config.style;
  const styleObj = config.alreadyTransformed
    ? config
    : getTransformedTextboxStyles(config);
  const { type, color } = styles;
  // check for same text
  let textToAdd = sub.text;
  let selectionStyle;
  if (type === SubtitleStyle.ONE_WORD) {
    const wordObj = getWordAtCurrentTime(
      currentTime,
      sub,
      microContent.start / 1000
    );
    textToAdd = wordObj.text;
  }

  if (type === SubtitleStyle.TWO_WORD) {
    const wordObj = getTwoWordsAtCurrentTime(
      currentTime,
      sub,
      microContent.start / 1000
    );
    textToAdd = wordObj.text;
  }

  if (type === SubtitleStyle.WORD_APPENDED) {
    const word = getWordAtCurrentTime(
      currentTime,
      sub,
      microContent.start / 1000
    );
    const { wordStartIndex, wordEndIndex } = word;
    word.textBeforeCurrentWord += word.textBeforeCurrentWord ? " " : "";
    textToAdd = word.textBeforeCurrentWord + word.text;
    selectionStyle = {
      style: {
        fill: color,
      },
      startIndex: wordStartIndex,
      endIndex: wordEndIndex,
    };
  }

  if (type === SubtitleStyle.RANDOM_WORD_COLOR_CHANGE) {
    const { startIndex, endIndex } = getRandomStartAndEndIndexToColor(sub.text);
    selectionStyle = {
      style: {
        fill: color,
      },
      startIndex,
      endIndex,
    };
  }
  if (
    type === SubtitleStyle.WORD_COLOR_CHANGE ||
    type === SubtitleStyle.WORD_BACKGROUND_CHANGE
  ) {
    const style: Partial<TextboxStyles> =
      type === SubtitleStyle.WORD_COLOR_CHANGE
        ? { fill: color }
        : { textBackgroundColor: color };
    const word = getWordAtCurrentTime(
      currentTime,
      sub,
      microContent.start / 1000
    );
    const { wordStartIndex, wordEndIndex } = word;
    selectionStyle = {
      style,
      startIndex: wordStartIndex,
      endIndex: wordEndIndex,
    };
  }

  if (config.textTransform === TEXT_TRANSFORM.UPPERCASE) {
    textToAdd = textTransform({
      text: textToAdd,
      transformStyle: TEXT_TRANSFORM.UPPERCASE,
    });
  }

  updateGroupedText(fabricEl, {
    text: textToAdd,
    ...styleObj,
    selectionStyle,
  });
};

const formatTime = (seconds: any) => {
  const time = new Date(seconds * 1000).toISOString();
  return `${time.substring(11, 19)},${time.substring(20, 23)}`;
};

export const createSrtFromSubsArray = (subsArr: any) => {
  const srt = subsArr.map(
    (sub: any, index: number) =>
      `${index + 1}\n${formatTime(sub.start)} --> ${formatTime(sub.end)}\n${
        sub.text
      }\n\n`
  );
  return srt.join("");
};

export const generateDraftName = (existingDraftName: string) => {
  const randomString = alphaNumericNanoId(8);

  return existingDraftName ? existingDraftName : `draft-${randomString}`;
};

export const handleKeyDown = (
  e: any,
  fabricRef: any,
  progressBarRef: any,
  syncFabricTextsWithTextsObj: (
    coordinates: {
      top: number;
      left: number;
    },
    textId: string
  ) => void
) => {
  if (e.target.tagName === "INPUT" || e.target.tagName === "TEXTAREA") {
    return;
  }

  const obj = fabricRef.current?.getActiveObject();

  if (obj?.type === TextElementType.SUBTITLE_EMOJI) {
    return;
  }

  const handleUpdateSync = (obj: any) => {
    if (obj?.type === TextElementType.NORMAL_TEXT) {
      const { top, left } = obj;
      syncFabricTextsWithTextsObj({ top, left }, obj.id);
    }
  };

  if (obj) {
    const step = e.shiftKey ? 7 : 2;
    switch (e.keyCode) {
      case KeyCode.LEFT_ARROW:
        if (obj !== progressBarRef.current) {
          obj.set("left", obj.left - step);
          handleUpdateSync(obj);
        }
        break;

      case KeyCode.UP_ARROW:
        obj.set("top", obj.top - step);
        handleUpdateSync(obj);
        break;

      case KeyCode.RIGHT_ARROW:
        if (obj !== progressBarRef.current) {
          obj.set("left", obj.left + step);
          handleUpdateSync(obj);
        }
        break;

      case KeyCode.DOWN_ARROW:
        obj.set("top", obj.top + step);
        handleUpdateSync(obj);
        break;
    }
    obj.setCoords();
  }
};

export const handleSocialMediaOverlay = (
  fabricRef: any,
  backgroundClipPathRef: any,
  selectedLayout: string,
  overlayImage: string,
  socialMediaUsername: any,
  gist: any,
  profileImage: string,
  socialMedia: string
) => {
  if (
    selectedLayout === VideoLayout.LAYOUT_9_16_1 ||
    selectedLayout === VideoLayout.LAYOUT_9_16_2
  ) {
    if (fabricRef.current && backgroundClipPathRef.current) {
      const coordleft = backgroundClipPathRef.current.left;
      const coordtop = backgroundClipPathRef.current.top;

      fabricjs.Image.fromURL(overlayImage, function (img: any) {
        img.set({
          left: coordleft,
          top: coordtop,
        });

        // Setting the coordinates of username for all three social Media
        const usernameLeft =
          coordleft +
          (socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.TIKTOK
            ? BUFFER_DIMENSIONS.usernameLeft.tiktok
            : socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.INSTAGRAM
            ? BUFFER_DIMENSIONS.usernameLeft.instagram
            : BUFFER_DIMENSIONS.usernameLeft.youtube);

        const usernameTop =
          coordtop +
          (socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.TIKTOK
            ? BUFFER_DIMENSIONS.usernameTop.tiktok
            : socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.INSTAGRAM
            ? BUFFER_DIMENSIONS.usernameTop.instagram
            : BUFFER_DIMENSIONS.usernameTop.youtube);

        const username = new fabricjs.Text(
          socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.YOUTUBE
            ? `@${socialMediaUsername.toLowerCase().substring(0, 7)}..`
            : socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.INSTAGRAM
            ? `${socialMediaUsername.toLowerCase().substring(0, 13)}..`
            : socialMediaUsername,
          {
            fontFamily: "Arial",
            fontSize:
              socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.YOUTUBE ? 40 : 45,
            fontWeight: "bold",
            fill: "white",
            left: usernameLeft,
            top: usernameTop,
          }
        );

        // Setting the coordinates of title for all three social Media
        const titleLeft =
          coordleft +
          (socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.TIKTOK
            ? BUFFER_DIMENSIONS.titleLeft.tiktok
            : socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.INSTAGRAM
            ? BUFFER_DIMENSIONS.titleLeft.instagram
            : BUFFER_DIMENSIONS.titleLeft.youtube);

        const titleTop =
          coordtop +
          (socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.TIKTOK
            ? BUFFER_DIMENSIONS.titleTop.tiktok
            : socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.INSTAGRAM
            ? BUFFER_DIMENSIONS.titleTop.instagram
            : BUFFER_DIMENSIONS.titleTop.youtube);

        const title = new fabricjs.Text(
          socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.TIKTOK ||
          socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.INSTAGRAM
            ? `${gist.toLowerCase().substring(0, 40)}...`
            : `${gist.substring(0, 30)}\n${gist.substring(30, 60)}...`,
          {
            fontFamily: "Arial",
            fontSize:
              socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.INSTAGRAM ? 45 : 40,
            fill: "white",
            left: titleLeft,
            top: titleTop,
          }
        );

        // Setting the coordinates of music text for all three social Media
        const musicLeft =
          coordleft +
          (socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.TIKTOK
            ? BUFFER_DIMENSIONS.musicLeft.tiktok
            : BUFFER_DIMENSIONS.musicLeft.instagram);

        const musicTop =
          coordtop +
          (socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.TIKTOK
            ? BUFFER_DIMENSIONS.musicTop.tiktok
            : BUFFER_DIMENSIONS.musicTop.instagram);

        const music = new fabricjs.Text(
          socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.TIKTOK
            ? `original sound - ${socialMediaUsername.toLowerCase()}`
            : socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.INSTAGRAM
            ? `${socialMediaUsername.toLowerCase()} - Original audio`
            : "",
          {
            fontFamily: "Arial",
            fontSize: 35,
            fill: "white",
            left: musicLeft,
            top: musicTop,
          }
        );

        // Setting the coordinates of first profile picturefor all three social Media
        const profileImageLeft =
          coordleft +
          (socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.TIKTOK
            ? BUFFER_DIMENSIONS.profileImageLeft.tiktok
            : socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.INSTAGRAM
            ? BUFFER_DIMENSIONS.profileImageLeft.instagram
            : BUFFER_DIMENSIONS.profileImageLeft.youtube);

        const profileImageTop =
          coordtop +
          (socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.TIKTOK
            ? BUFFER_DIMENSIONS.profileImageTop.tiktok
            : socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.INSTAGRAM
            ? BUFFER_DIMENSIONS.profileImageTop.instagram
            : BUFFER_DIMENSIONS.profileImageTop.youtube);

        fabricjs.Image.fromURL(profileImage, function (profileImg: any) {
          // Create a rounded clip path for the profile image
          const clipPath = new fabricjs.Rect({
            originX: "center",
            originY: "center",
            width: profileImg.width,
            height: profileImg.height,
            rx: 50,
            ry: 50,
          });

          profileImg.set({
            clipPath: clipPath,
            left: profileImageLeft,
            top: profileImageTop,
            stroke:
              socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.INSTAGRAM
                ? "white"
                : "",
            strokeWidth:
              socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.INSTAGRAM ? 20 : 0,
            scaleX:
              socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.TIKTOK ? 1.5 : 1,
            scaleY:
              socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.TIKTOK ? 1.5 : 1,
          });

          // Setting the coordinates of second profile picturefor all three social Media
          const profileImage2Left =
            coordleft +
            (socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.TIKTOK
              ? BUFFER_DIMENSIONS.profileImage2Left.tiktok
              : socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.INSTAGRAM
              ? BUFFER_DIMENSIONS.profileImage2Left.instagram
              : BUFFER_DIMENSIONS.profileImage2Left.youtube);

          const profileImage2Top =
            coordtop +
            (socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.TIKTOK
              ? BUFFER_DIMENSIONS.profileImage2Top.tiktok
              : socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.INSTAGRAM
              ? BUFFER_DIMENSIONS.profileImage2Top.instagram
              : BUFFER_DIMENSIONS.profileImage2Top.youtube);

          fabricjs.Image.fromURL(profileImage, function (profileImg2: any) {
            // Create a rounded clip path for the profile image
            const clipPath = new fabricjs.Rect({
              originX: "center",
              originY: "center",
              width: profileImg2.width,
              height: profileImg2.height,
              rx: socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.TIKTOK ? 50 : 20,
              ry: socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.TIKTOK ? 50 : 20,
            });

            profileImg2.set({
              clipPath: clipPath,
              left: profileImage2Left,
              top: profileImage2Top,
              scaleX:
                socialMedia !== SOCIAL_MEDIA_SELECTED_IMAGE.INSTAGRAM ? 1.3 : 1,
              scaleY:
                socialMedia !== SOCIAL_MEDIA_SELECTED_IMAGE.INSTAGRAM ? 1.3 : 1,
              stroke:
                socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.TIKTOK
                  ? "#292929"
                  : socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.INSTAGRAM
                  ? "white"
                  : 0,
              strokeWidth:
                socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.TIKTOK
                  ? 50
                  : socialMedia === SOCIAL_MEDIA_SELECTED_IMAGE.INSTAGRAM
                  ? 20
                  : 0,
            });

            const group = new fabricjs.Group(
              [img, username, title, music, profileImg, profileImg2],
              {
                scaleX: backgroundClipPathRef.current.width / img.width,
                scaleY: backgroundClipPathRef.current.height / img.height,
                left: coordleft,
                top: coordtop,
              }
            );

            fabricRef.current.setOverlayImage(
              group,
              fabricRef.current.renderAll.bind(fabricRef.current)
            );
          });
        });
      });
    }
  }
};

/**
 * Gets the videoElUp and videoElDown's position values from the fabricObjects parameter.
 *
 * @export
 * @param {any} fabricObjects - The object containing videoElUp and videoElDown information.
 * @returns {Position} An object containing the position information for videoElUp and videoElDown.
 */

export const getVideoPositionForDraft = (fabricObjects: any): Position => {
  const videoElUp = fabricObjects.videoElUp;
  const videoElDown = fabricObjects.videoElDown;
  const backGroundClipPath = fabricObjects.backGroundClipPath;

  const position: Position = {
    videoElUp: null,
    videoElDown: null,
  };

  if (videoElUp) {
    position.videoElUp = {
      top: videoElUp.top,
      left: videoElUp.left,
      backGroundClipPathTop: backGroundClipPath.top,
      backGroundClipPathLeft: backGroundClipPath.left,
      scaleX: videoElUp.scaleX,
      scaleY: videoElUp.scaleY,
    };
  }
  if (videoElDown) {
    position.videoElDown = {
      top: videoElDown.top,
      left: videoElDown.left,
      backGroundClipPathTop: backGroundClipPath.top,
      backGroundClipPathLeft: backGroundClipPath.left,
      scaleX: videoElDown.scaleX,
      scaleY: videoElDown.scaleY,
    };
  }

  return position;
};

/**
 * Sets videoElUp and videoElDown's position values based on the video positioning of the selected draft.
 *
 * @param {any} selectedDraft - The draft containing the video positioning information.
 * @param {any} videoElUp - The video element whose position values need to be set based on the selected draft for up scaling.
 * @param {any} videoElDown - Only applicable for 9:16:2 templates. The video element whose position values need to be set based on the selected draft for down scaling.
 * @param {number} [templateScaleUpRatio=1] - The scaling ratio to apply to videoElUp's position values (optional, default is 1).
 * @param {any} [backGroundClipPath] - The background clip path object.
 */

export const setVideoPositionFromDraft = (
  selectedDraft: any,
  videoElUp: any,
  videoElDown: any,
  templateScaleUpRatio: number = 1,
  backGroundClipPath: any
) => {
  if (selectedDraft?.videoPosition?.videoElUp && videoElUp) {
    const {
      top,
      left,
      scaleX,
      scaleY,
      backGroundClipPathTop,
      backGroundClipPathLeft,
    } = selectedDraft.videoPosition.videoElUp;

    const dx =
      backGroundClipPath.left - backGroundClipPathLeft * templateScaleUpRatio;
    const dy =
      backGroundClipPath.top - backGroundClipPathTop * templateScaleUpRatio;
    videoElUp.set({
      scaleX: scaleX * templateScaleUpRatio,
      scaleY: scaleY * templateScaleUpRatio,
    });

    videoElUp.set({
      top: top * templateScaleUpRatio + dy,
      // top: top * templateScaleUpRatio,
      left: left * templateScaleUpRatio + dx,
    });
  }
  if (selectedDraft?.videoPosition?.videoElDown && videoElDown) {
    const {
      top,
      left,
      scaleX,
      scaleY,
      backGroundClipPathTop,
      backGroundClipPathLeft,
    } = selectedDraft.videoPosition.videoElDown;

    const dx =
      backGroundClipPath.left - backGroundClipPathLeft * templateScaleUpRatio;
    const dy =
      backGroundClipPath.top - backGroundClipPathTop * templateScaleUpRatio;

    videoElDown.set({
      scaleX: scaleX * templateScaleUpRatio,
      scaleY: scaleY * templateScaleUpRatio,
    });

    videoElDown.set({
      top: top * templateScaleUpRatio + dy,
      left: left * templateScaleUpRatio + dx,
    });
  }
};

export const textTransform = ({
  text,
  transformStyle,
  prevText,
  capitalizeFirstWord,
}: {
  text: any;
  transformStyle: any;
  prevText?: string;
  capitalizeFirstWord?: boolean;
}) => {
  switch (transformStyle) {
    case TEXT_TRANSFORM.CAPITALIZE:
      return text.replace(/\b\w/g, (match: any) => match.toUpperCase());

    case TEXT_TRANSFORM.DEFAULT:
      return text;

    case TEXT_TRANSFORM.LOWERCASE:
      if (capitalizeFirstWord) {
        let updatedText: any = smartTransformToLowerCase(text, prevText);
        const firstLetter = updatedText.charAt(0).toUpperCase();
        const restOfString = updatedText.slice(1);
        return firstLetter + restOfString;
      }
      return smartTransformToLowerCase(text, prevText);

    case TEXT_TRANSFORM.UPPERCASE:
      return text.toUpperCase();

    default:
      return text;
  }
};

export const getIsIntelliClip = (clip: any) => {
  return clip?.tag === INTELLI_CLIP_TAG && !!clip?.clip_src;
};
export const checkAndTransformText = () => {};
export const handleAddAudioAsset = (audioData: any, setState: any) => {
  const audio = new Audio(audioData.url);
  setState((prev: any) => {
    return {
      ...prev,
      [audioData.id]: {
        audioElement: audio,
        ...audioData,
      },
    };
  });
};

export const handleAddAudioClipsToCanvas = (
  currentTemplate: any,
  setState: any,
  currentSelectedMicroContent: any
) => {
  currentTemplate?.audioClips?.forEach((asset: any) => {
    handleAddAudioAsset(
      {
        ...asset,
        assetTag: AssetTags.BG_MUSIC,
        assetType: SimpleAssetType.AUDIO,
        id: asset.id,
        metaData: {
          start:
            currentSelectedMicroContent.start + asset.metaData.start * 1000,
          end: currentSelectedMicroContent.start + asset.metaData.end * 1000,
          duration: asset.metaData.duration,
          volume: asset.metaData.volume || 100,
          isFullVideoLength: asset.metaData?.isFullVideoLength || true,
        },
      },
      setState
    );
  });
};

const startAudioPlayback = (
  audioElement: HTMLAudioElement,
  time: number,
  volume: number
) => {
  audioElement.volume = volume;
  if (audioElement.paused) {
    audioElement.currentTime = time;
    audioElement.play();
  }
};

const stopAudioPlayback = (audioElement: HTMLAudioElement, volume: number) => {
  audioElement.volume = volume;
  if (!audioElement.paused) {
    audioElement.pause();
    audioElement.currentTime = 0;
  }
};

export const syncAudioAssetsWithMainVideo = (
  currentVideoElRef: any,
  audioElements: any
) => {
  const videoTime = currentVideoElRef.currentTime * 1000;

  Object.values(audioElements).forEach((audioAsset: any) => {
    const { start, end } = audioAsset.metaData;
    if (videoTime >= start && videoTime <= end && !currentVideoElRef.paused) {
      startAudioPlayback(
        audioAsset.audioElement,
        (videoTime - start) / 1000,
        audioAsset.metaData.volume / 100
      );
    } else {
      stopAudioPlayback(
        audioAsset.audioElement,
        audioAsset.metaData.volume / 100
      );
    }
  });
};

export const checkIfExternalAudioAssetPlaying = (
  currentVideoElRef: any,
  audioElements: any
) => {
  if (!currentVideoElRef) return false;
  const videoTime = currentVideoElRef.currentTime * 1000;

  const isExternalAudioPlaying = Object.values(audioElements).some(
    (audioAsset: any) => {
      const { start, end } = audioAsset.metaData;
      return (
        videoTime >= start && videoTime <= end && !currentVideoElRef.paused
      );
    }
  );

  return isExternalAudioPlaying;
};

export const getAudioClips = (
  audioElements: any,
  currentSelectedMicroContent: any
) => {
  const audioClips = Object.values(audioElements).map((item: any) => {
    return {
      id: item.id,
      url: item.url,
      assetType: SimpleAssetType.AUDIO,
      assetTag: AssetTags.BG_MUSIC,
      metaData: {
        ...item.metaData,
        start:
          Math.abs(currentSelectedMicroContent.start - item.metaData.start) /
          1000,
        end:
          Math.abs(currentSelectedMicroContent.start - item.metaData.end) /
          1000,
        volume: item.metaData.volume,
      },
    };
  });

  return audioClips;
};

export const getAudioDataForRender = (
  audioElements: any,
  currentSelectedMicroContent: any
) => {
  const audioClips = Object.values(audioElements).map((item: any) => {
    return {
      id: item.id,
      audio_url: item.url,
      start_time:
        Math.abs(currentSelectedMicroContent.start - item.metaData.start) /
        1000,
      end_time:
        Math.abs(currentSelectedMicroContent.start - item.metaData.end) / 1000,
      // change volume to 0-1
      volume: item.metaData.volume / 100,
    };
  });

  return audioClips;
};

export const getVideoElement = (url: string, cb: any, isOutro = false) => {
  var videoE = document.createElement(SimpleAssetType.VIDEO);
  videoE.onloadedmetadata = function () {
    videoE.width = videoE.videoWidth;
    videoE.height = videoE.videoHeight;
    cb && cb();
  };
  videoE.muted = isOutro ? false : true;
  // videoE.muted = true;
  var source = document.createElement("source");
  source.src = url;
  source.type = "video/mp4";
  videoE.appendChild(source);

  return videoE;
};

export const handleAddOutroAsset = ({
  outro,
  backGroundClipPath,
  commonProps,
  fabricObj,
  canvasObjects,
  outroVideoRef,
}: {
  outro: OutroObject;
  backGroundClipPath: any;
  commonProps: {
    left: any;
    top: any;
    clipPath: any;
    id: string;
    selectable: boolean;
    hasControls: boolean;
  };
  fabricObj: any;
  canvasObjects: React.MutableRefObject<any>;
  outroVideoRef: React.MutableRefObject<any>;
}) => {
  if (outro?.type.includes(SimpleAssetType.VIDEO)) {
    const videoElement = getVideoElement(
      outro.url,
      () => {
        const videoObj = getFabricVideo({
          ...commonProps,
          videoElement: videoElement,
        });
        videoObj.set({
          scaleY: backGroundClipPath?.height / videoObj.height,
          scaleX: backGroundClipPath?.width / videoObj.width,
        });
        outroVideoRef.current = videoElement;
        canvasObjects.current.outroImg = videoObj;
      },
      true
    );
  } else {
    fabricObj.Image.fromURL(outro.url, (img: any) => {
      img.set({
        ...commonProps,
        originX: "left",
        originY: "top",
        clipPath: backGroundClipPath,
      });

      img.set({
        scaleY: backGroundClipPath?.height / img.height,
        scaleX: backGroundClipPath?.width / img.width,
      });

      canvasObjects.current.outroImg = img;
    });
  }
};

export const getBRolls = async (
  inputTimeArr: { keyword: string; startTime: number; endTime: number }[]
) => {
  const bRolls = inputTimeArr.map(async (item) => {
    await sleep(1000);
    const data = await getPexelsAssets({
      searchQuery: item.keyword,
      page: 0,
      assetType: SimpleAssetType.IMAGE,
    });
    // call pexels api for each keyword
    return {
      data,
      startTime: item.startTime,
      endTime: item.endTime,
      keyword: item.keyword,
    };
  });

  const allItems = await Promise.all(bRolls);

  return allItems
    .filter((item) => item.data.total_results > 0)
    .map((item, index) => {
      // @ts-expect-error
      const image = getRandomElement(item.data.photos);
      return {
        url: image.src.medium,
        uid: nanoid(),
        assetType: SimpleAssetType.IMAGE,
        assetTag: AssetTags.B_ROLL,
        metaData: {
          start: item.startTime,
          end: item.endTime,
          isFullVideoLength: false,
        },
        top: 0,
        left: 0,
        file: null,
        keyword: item.keyword,
      };
    });
};
