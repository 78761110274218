import { SVGProps, memo } from "react";

import clsx from "clsx";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    className={clsx(props.className, "fill-current")}
    {...props}
  >
    <g
      fill="currentColor"
      clipPath="url(#a)"
    >
      <rect
        width={18}
        height={4.5}
        x={3}
        y={3}
        rx={1}
      />
      <rect
        width={10.8}
        height={10.8}
        x={3}
        y={10.2}
        rx={1}
      />
      <rect
        width={4.5}
        height={10.8}
        x={16.5}
        y={10.2}
        rx={1}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill="#fff"
          d="M0 0h24v24H0z"
        />
      </clipPath>
    </defs>
  </svg>
);
const TemplatesIcon = memo(SvgComponent);
export default TemplatesIcon;
