// @author: ChatGPT

export function getSubstringIndices(
  parts: string[],
  start: number,
  end: number
) {
  let currentLength = 0;
  let startIndex, endIndex, arrayIndex;
  let beforeSubstring = "";

  for (let i = 0; i < parts.length; i++) {
    if (start >= currentLength && start < currentLength + parts[i].length) {
      startIndex = start - currentLength;
      arrayIndex = i;
      beforeSubstring = parts[i].substring(0, startIndex);
    }

    if (end > currentLength && end <= currentLength + parts[i].length) {
      endIndex = end - currentLength;
      break; // We've found the start and end, so we can stop looping
    }

    currentLength += parts[i].length + 1; // +1 for the space between parts
  }

  return {
    arrayIndex: arrayIndex,
    start: startIndex,
    end: endIndex,
    beforeSubstring: beforeSubstring,
  };
}
