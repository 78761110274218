// function to scale a number by a multiplier, and round to the two digits
export const scaleMultiplier = (num: number, multiplier: number) => {
  return Math.round(num * multiplier * 100) / 100;
};

// scale each property of the object by the multiplier
export const scaleEachProperty = (
  obj: Record<string, number | undefined>,
  multiplier: number
) => {
  const newObj: any = {};
  for (const [key, value] of Object.entries(obj)) {
    if (value) {
      newObj[key] = scaleMultiplier(value, multiplier);
    } else {
      newObj[key] = value;
    }
  }
  return newObj;
};
