import { ampli } from "@/ampli";
import * as amplitude from "@amplitude/analytics-browser";
import { nanoid } from "nanoid";

import { store } from "@/store";
import {
  setDownloadAnalytics,
  setEditorAnalytics,
  setHomeAnalytics,
} from "@/store/amplitudeSlice";

import {
  convertToCamelCase,
  getCurrencyByCountryCode,
  isProd,
} from "@/helpers";
import {
  DATE_TIME,
  checkAndReturnStockBackground,
  checkAndReturnSubtitleStyleColor,
  convertCurrencyIntoUSD,
  convertToUSD,
  getAudioIds,
  getBRollAssetId,
  getClipDuration,
  getCurrentPlanEndTimeStamp,
  getDateTime,
  getEffects,
  getExportQualityResolution,
  getMonthAsString,
  getPlanName,
  getSocialLogos,
  textEffects,
} from "@/helpers/amplitudeHelper";

import { trackMoengageEvent } from "@/utils/moengage";

import { Project } from "@/interfaces";

import {
  AssetTags,
  ExportQuality,
  PlanType,
  ScreenName,
  SubtitleStyle,
  VideoLayout,
} from "@/enums";

export const MOENGAGE_EVENTS = {
  ADD_MORE_MINUTES_CLICK: "Add More Minutes Click",
  ADD_MORE_MINUTES: "addMoreMinutes",
  CANCEL_SUBSCRIPTION: "Cancel Subscription",
  CLICK_START_IMPORT: "Click Start Import",
  COUPON_APPLIED: "Coupon Applied",
  CREATE_CUSTOM_CLIP: "Create Custom Clip",
  DISCORD_ICON_CLICK: "Discord Icon Click",
  DOWNLOADS_CLICK: "Downloads Click",
  EDIT_AND_DOWNLOAD_VIDEO_CLICK: "Edit & Download Video clicked",
  ENABLE_CUTMAGIC: "Enable CutMagic",
  EXPORT_CLIP: "Export Clip",
  FINNISH_ON_BOARDING: "Finish On-boarding",
  IMPORT_VIDEO_CLICK: "import video click",
  MEDIA_CLICK: "Media Click",
  MINUTES_RENEWD: "Minutes Renewed",
  PLAN_CHANGED: "Plan Changed",
  REVIEW_CLIPS: "Review Clips",
  SIGNUP: "Sign up",
  REVIEW_SCREEN_INTERATION: "Review Screen Interations",
  SAVE_DRAFT: "Save Draft",
  SAVE_TEMPLATE: "Save Template",
  SIGN_IN: "Sign in",
  SUBSCRIPTION_PAYMENT_FAILED: "Subscription Payment Failed",
  SWITCH_CLIP_ASPECT_RATIO: "Switch Clip Aspect Ratio",
  UPGRADE_BUTTON_CLICK: "Upgrade Button Click",
  VIEW_ONBOARDING: "View OnBoarding",
  WHATS_NEW_ICON_CLICK: "Whats New Icon Click",
};

const SAVE_AS_NEW_DRAFT = "new-draft";
const UPDATE_DRAFT = "update-draft";

const B_ROLL_IMAGE = "image";
const B_ROLL_VIDEO = "video";

const STOCK_BACKGROUND = `Stock-${AssetTags.BACKGROUND}`;
const BRAND_KIT_BACKGROUND = `Brandkit-${AssetTags.BACKGROUND}`;
const BRAND_KIT_OUTRO = `Brandkit-${AssetTags.OUTRO}`;
const STOCK_OUTRO = `Stock-${AssetTags.OUTRO}`;

export const AUDIO_TYPE_USED = {
  STOCK_AUDIO: "StockAudio",
  BRAND_KIT_AUDIO: "BrandKitAudio",
};

const IMPORT_PREFERENCE = {
  LOCAL_DEVICE: "local device",
};

export const EXPORT_PREFERENCE = {
  DOWNLOAD: "download",
  TIKTOK: "tiktok",
  FACEBOOK: "facebook",
  INSTAGRAM: "instagram",
  LINKEDIN: "linkedin",
  TWITTER: "twitter",
  YOUTUBE: "youtube",
};

export const OVERVIEW_CONSTANT = {
  SHORTS: "shorts",
  CHAPTERS: "chapters",
  FULL_VIDEO: "full video",
};

export const ANALYTICS_CONSTANTS = {
  CLIP_COUNT: "CLIP_COUNT",
  OVERVIEW_TYPE: "OVERVIEW_TYPE",
  SUBTITLE_REMOVED: "SUBTITLE_REMOVED",
  DOWNLOAD_SRT: "DOWNLOAD_SRT",
  SUBTITLE_STYLE_CLICK: "SUBTITLE_STYLE_CLICK",
  SOCIAL_PREVIEW_ACCESSED: "SOCIAL_PREVIEW_ACCESSED",
  OUTRO_TYPE: "OUTRO_TYPE",
  LOGO_TYPE: "LOGO_TYPE",
  BROLL_TYPE: "BROLL_TYPE",
  BACKGROUND_TYPE: "BACKGROUND_TYPE",
  AUDIO_TYPE: "AUDIO_TYPE",
  AI_CAPTIONS: "AI_CAPTIONS",
  ENABLE_CUTMAGIC: "ENABLE_CUTMAGIC",
  FONT_SETTINGS_ACCESSED: "FONT_SETTINGS_ACCESSED",
  UPLOAD_CUSTOM_FONT: "UPLOAD_CUSTOM_FONT",
  TEXT_EFFECT: "TEXT_EFFECT",
  BLOCK_BACKGROUND: "Block Background",
  SHADOW: "Shadow",
  OUTLINE: "Outline",
  TEXT_BACKGROUND: "Text Background",
  NO_EFFECT: "No Effect",
  UPLOADS: {
    BACKGROUND_UPLOAD: "BACKGROUND_UPLOAD",
    FONT_UPLOAD: "FONT_UPLOAD",
    LOGO_UPLOAD: "LOGO_UPLOAD",
    OUTRO_IMAGE_UPLOAD: "OUTRO_IMAGE_UPLOAD",
    OUTRO_VIDEO_UPLOAD: "OUTRO_VIDEO_UPLOAD",
    AUDIO_UPLOAD: "AUDIO_UPLOAD",
  },
  EXPORT_PROJECT_ID: "EXPORT_PROJECT_ID",
  PAYMENT_SCREEN_NAME: "PAYMENT_SCREEN_NAME",
  EDITOR_MEDIA_PEXEL_SEARCH: "EDITOR_MEDIA_PEXEL_SEARCH",
};

export const REVIEW_CLIPS_INTERACTION = {
  TRANSCRIPT_CLICK: "TRANSCRIPT_CLICK",
  TRANSCRIPT_COPIED: "TRANSCRIPT_COPIED",
  AI_CAPTION_CLICK: "AI_CAPTION_CLICK",
  AI_CAPTIONS: "AI_CAPTIONS",
  VIDEO_TIMESTAMP_CLICKED: "VIDEO_TIMESTAMP_CLICKED",
  VIDEO_TIMESTAMP_COPIED: "VIDEO_TIMESTAMP_COPIED",
};

export const AMPLITUDE_CLICK_EVENTS = {
  UPLOAD_VIDEO_MODAL: "Upload Video Modal",
  UPDATE_TO_PRO_MODAL: "Update to Pro Modal",
  PROFILE_DROPDOWN_MENU: "Profile Dropdown Menu",
  HEADER: "Header",
};

const extractPaths = (pathname: string) => {
  // Use regular expression to extract the paths
  const regex = /\/([^/]+)/g;
  const matches = pathname.match(regex);

  // Extract the desired paths from the matches
  const paths = [];
  if (matches) {
    for (const match of matches) {
      // Remove the leading '/' and push the path to the array
      const path = match.substring(1);

      // Remove UUID-like segments and 'templates'
      if (
        !path.match(/[0-9a-f]{8}-(?:[0-9a-f]{4}-){3}[0-9a-f]{12}|templates/i)
      ) {
        paths.push(path);
      }
    }
  }

  if (paths.length) {
    return paths[0];
  }
  return null;
};

const getScreenNameFromHostName = () => {
  const pathname = extractPaths(window.location.pathname);

  switch (pathname) {
    case "home":
      return ScreenName.HOME;
    case "editor":
      return ScreenName.EDITOR;
    case "downloads":
      return ScreenName.DOWNLOADS;
    case "project":
      return ScreenName.REVIEW_CLIPS;
    case "my-templates":
    case null:
      return ScreenName.TEMPLATES;
    case "brand-kit":
      return ScreenName.BRAND_KIT;
    default:
      return ScreenName.HOME;
  }
};

export const eventsDataToRedux = (event: any, value?: any, id?: string) => {
  const editorAnalytics = store.getState().amplitudeState.editorScreen;
  const homeAnalytics = store.getState().amplitudeState.homeScreen;
  const downloadsAnalytics = store.getState().amplitudeState.downloadScreen;

  switch (event) {
    case ANALYTICS_CONSTANTS.CLIP_COUNT:
      store.dispatch(
        setHomeAnalytics({
          ...homeAnalytics,
          clipCount: value,
        })
      );
      break;
    case ANALYTICS_CONSTANTS.OVERVIEW_TYPE:
      store.dispatch(
        setHomeAnalytics({
          ...homeAnalytics,
          overviewType: value,
          overviewChanged: true,
        })
      );
      break;
    case ANALYTICS_CONSTANTS.DOWNLOAD_SRT:
      store.dispatch(
        setEditorAnalytics({ ...editorAnalytics, downloadSRT: true })
      );
      break;
    case ANALYTICS_CONSTANTS.SUBTITLE_REMOVED:
      store.dispatch(
        setEditorAnalytics({ ...editorAnalytics, subtitlesRemove: true })
      );
      break;
    case ANALYTICS_CONSTANTS.SUBTITLE_STYLE_CLICK:
      store.dispatch(
        setEditorAnalytics({ ...editorAnalytics, subtitleStyleClick: true })
      );
      break;
    case ANALYTICS_CONSTANTS.SOCIAL_PREVIEW_ACCESSED:
      !editorAnalytics?.socialPreviewAccessed &&
        store.dispatch(
          setEditorAnalytics({
            ...editorAnalytics,
            socialPreviewAccessed: true,
          })
        );
      break;
    case ANALYTICS_CONSTANTS.OUTRO_TYPE:
      store.dispatch(
        setEditorAnalytics({
          ...editorAnalytics,
          outro: {
            ...editorAnalytics?.outro,
            type: value,
            id,
          },
        })
      );
      break;
    case ANALYTICS_CONSTANTS.LOGO_TYPE:
      store.dispatch(
        setEditorAnalytics({
          ...editorAnalytics,
          logoType: [...(editorAnalytics?.logoType || []), value],
          socialLogoAdded: true,
        })
      );
      break;
    case ANALYTICS_CONSTANTS.BROLL_TYPE:
      store.dispatch(
        setEditorAnalytics({
          ...editorAnalytics,
          bRollType: [...(editorAnalytics?.bRollType || []), value],
        })
      );
      break;
    case ANALYTICS_CONSTANTS.BACKGROUND_TYPE:
      store.dispatch(
        setEditorAnalytics({
          ...editorAnalytics,
          backgroundType: value,
        })
      );
      break;
    case ANALYTICS_CONSTANTS.AUDIO_TYPE:
      let audioType = "";
      if (value.hasOwnProperty("ownerId"))
        audioType = AUDIO_TYPE_USED.BRAND_KIT_AUDIO;
      else audioType = AUDIO_TYPE_USED.STOCK_AUDIO;

      store.dispatch(
        setEditorAnalytics({
          ...editorAnalytics,
          audioType,
        })
      );
      break;
    case ANALYTICS_CONSTANTS.AI_CAPTIONS:
      trackReviewClipsInteractionEvents(
        REVIEW_CLIPS_INTERACTION.AI_CAPTIONS,
        value
      );
      store.dispatch(
        setEditorAnalytics({
          ...editorAnalytics,
          aiCaptions: [...(editorAnalytics?.aiCaptions || []), value],
          captionGenerated: true,
        })
      );
      break;
    case ANALYTICS_CONSTANTS.ENABLE_CUTMAGIC:
      store.dispatch(
        setEditorAnalytics({
          ...editorAnalytics,
          cutMagicEnabled: true,
        })
      );
      trackEnableCutMagicEvent();
      break;
    case ANALYTICS_CONSTANTS.FONT_SETTINGS_ACCESSED:
      store.dispatch(
        setEditorAnalytics({
          ...editorAnalytics,
          fontSettingsAccessed: true,
        })
      );
      break;
    case ANALYTICS_CONSTANTS.UPLOAD_CUSTOM_FONT:
      store.dispatch(
        setEditorAnalytics({
          ...editorAnalytics,
          uploadCustomFont: true,
        })
      );
      break;
    case ANALYTICS_CONSTANTS.TEXT_EFFECT:
      store.dispatch(
        setEditorAnalytics({
          ...editorAnalytics,
          textEffectChanged: true,
        })
      );
      break;
    case ANALYTICS_CONSTANTS.UPLOADS.FONT_UPLOAD:
      store.dispatch(
        setEditorAnalytics({
          ...editorAnalytics,
          fontUpload: true,
        })
      );
      break;
    case ANALYTICS_CONSTANTS.UPLOADS.BACKGROUND_UPLOAD:
      store.dispatch(
        setEditorAnalytics({
          ...editorAnalytics,
          backgroundUpload: true,
        })
      );
      break;
    case ANALYTICS_CONSTANTS.UPLOADS.LOGO_UPLOAD:
      store.dispatch(
        setEditorAnalytics({
          ...editorAnalytics,
          logoUpload: true,
        })
      );
      break;
    case ANALYTICS_CONSTANTS.UPLOADS.OUTRO_IMAGE_UPLOAD:
      store.dispatch(
        setEditorAnalytics({
          ...editorAnalytics,
          outroImageUpload: true,
        })
      );
      break;
    case ANALYTICS_CONSTANTS.UPLOADS.OUTRO_VIDEO_UPLOAD:
      store.dispatch(
        setEditorAnalytics({
          ...editorAnalytics,
          outroVideoUpload: true,
        })
      );
      break;
    case ANALYTICS_CONSTANTS.UPLOADS.AUDIO_UPLOAD:
      store.dispatch(
        setEditorAnalytics({
          ...editorAnalytics,
          audioUpload: true,
        })
      );
    case ANALYTICS_CONSTANTS.EXPORT_PROJECT_ID:
      store.dispatch(
        setDownloadAnalytics({
          ...downloadsAnalytics,
          projectId: value,
        })
      );
      break;
    case ANALYTICS_CONSTANTS.PAYMENT_SCREEN_NAME:
      const screenName = getScreenNameFromHostName();

      store.dispatch(
        setHomeAnalytics({
          ...homeAnalytics,
          paymentScreenName: convertToCamelCase(screenName),
        })
      );
      break;

    case ANALYTICS_CONSTANTS.EDITOR_MEDIA_PEXEL_SEARCH:
      store.dispatch;
      setEditorAnalytics({
        ...editorAnalytics,
        mediaSearched: true,
      });
  }
};

export const trackSaveDraftEvent = (
  draftData: any,
  draftSaveOption: string
) => {
  if (isProd()) {
    const homeAnalytics = store.getState().amplitudeState.homeScreen;
    const editorAnalytics = store.getState().amplitudeState.editorScreen;

    const currentSelectedMicroContent =
      store.getState().homeState.currentSelectedMicroContent;
    const duration =
      draftData.aspectRatio === VideoLayout.LAYOUT_16_9
        ? (draftData.chapter_end - draftData.chapter_start) / 1000
        : (draftData.end - draftData.start) / 1000;

    const eventData = {
      clipDuration: duration,
      clipId: draftData?.clipId || nanoid(),
      clipName: draftData.gist,
      isDiscontinuousClip: currentSelectedMicroContent?.tag === "004",
      autoAddEmoji: draftData.autoAddEmojisToSubtitles || false,
      downloadSRT: editorAnalytics?.downloadSRT || false,
      subtitleEffect: getEffects(draftData.subtitle),
      subtitleFontSize: parseInt(draftData.subtitle.fontSize),
      subtitlesRemove: editorAnalytics?.subtitlesRemove || false,
      subtitleStyle: editorAnalytics?.subtitlesRemove
        ? ""
        : convertToCamelCase(draftData.subtitle.style.type),
      subtitleStyleClick: editorAnalytics?.subtitleStyleClick || false,
      subtitleStyleColor: editorAnalytics?.subtitlesRemove
        ? ""
        : checkAndReturnSubtitleStyleColor(draftData.subtitle.style),
      aspectRatio: draftData.aspectRatio,
      newDraft: draftSaveOption === SAVE_AS_NEW_DRAFT,
      updateDraft: draftSaveOption === UPDATE_DRAFT,
      overviewChanged: homeAnalytics?.overviewChanged || false,
      overviewType: homeAnalytics?.overviewType || OVERVIEW_CONSTANT.SHORTS,
      templateId: currentSelectedMicroContent?.id,
      aiCaptions: editorAnalytics?.aiCaptions || [],
      brandkitBackground:
        editorAnalytics?.backgroundType === BRAND_KIT_BACKGROUND ? true : false,
      brandkitOutros:
        editorAnalytics?.outro?.type === BRAND_KIT_OUTRO ? true : false,
      bRollImageUsed: getBRollAssetId(draftData.bRolls, B_ROLL_IMAGE),
      bRollVideoUsed: getBRollAssetId(draftData.bRolls, B_ROLL_VIDEO),
      captionGenerated: editorAnalytics?.captionGenerated || false,
      cutmagicEnabled: editorAnalytics?.cutMagicEnabled || false,
      fontSettingAccessed: editorAnalytics?.fontSettingsAccessed || false,
      fontUploaded: editorAnalytics?.fontUpload || false,
      mediaSearched: editorAnalytics?.mediaSearched || false,
      socialLogos: getSocialLogos(draftData.socials),
      socialLogoAdded: editorAnalytics?.socialLogoAdded || false,
      socialPreviewAccessed: editorAnalytics?.socialPreviewAccessed || false,
      stockAudioIds: getAudioIds(
        draftData.audioClips,
        editorAnalytics?.audioType
      ),
      stockBackground: checkAndReturnStockBackground(
        editorAnalytics?.backgroundType,
        STOCK_BACKGROUND,
        draftData.backgroundImageUrl,
        draftData.backgroundColor
      ),
      stockOutroId:
        editorAnalytics?.outro?.type === STOCK_OUTRO
          ? editorAnalytics?.outro?.id
          : "",
      textEffects: textEffects(draftData.texts),
      textEffectsAccessed: editorAnalytics?.textEffectChanged || false,
      uploadedAudio: editorAnalytics?.audioUpload || false,
      uploadedBackground: editorAnalytics?.backgroundUpload || false,
      uploadedLogo: editorAnalytics?.logoUpload || false,
      uploadedOutroImage: editorAnalytics?.outroImageUpload || false,
      uploadedOutroVideo: editorAnalytics?.outroVideoUpload || false,
    };
    ampli.saveDraft(eventData);
    trackMoengageEvent(MOENGAGE_EVENTS.SAVE_DRAFT, eventData);
  }
};

export const trackRenderClipEvent = (exportPlatform: string, data: any) => {
  // downloading/exporting videos from editor screen

  if (isProd()) {
    const editorAnalytics = store.getState().amplitudeState.editorScreen;
    const homeAnalytics = store.getState().amplitudeState.homeScreen;
    const currentSelectedProject =
      store.getState().homeState.currentSelectedProject;
    const currentSelectedMicroContent =
      store.getState().homeState.currentSelectedMicroContent;

    const exportQuality = store.getState().editorState.exportQuality;

    const duration =
      data.aspectRatio === VideoLayout.LAYOUT_16_9
        ? (data.chapter_end - data.chapter_start) / 1000
        : (data.end - data.start) / 1000;

    const eventData = {
      exportPlatform,
      exportPreference: getExportQualityResolution(exportQuality),
      clipDuration: duration,
      clipId: data?.clipId || nanoid(),
      clipName: data.gist,
      isDiscontinuousClip: currentSelectedMicroContent?.tag === "004",
      autoAddEmoji: data.autoAddEmojisToSubtitles || false,
      downloadSRT: editorAnalytics?.downloadSRT || false,
      subtitleEffect: getEffects(data.subtitle),
      subtitleFontSize: parseInt(data.subtitle.fontSize),
      subtitlesRemove: editorAnalytics?.subtitlesRemove || false,
      subtitleStyle: editorAnalytics?.subtitlesRemove
        ? ""
        : convertToCamelCase(data.subtitle.style.type),
      subtitleStyleClick: editorAnalytics?.subtitleStyleClick || false,
      subtitleStyleColor: editorAnalytics?.subtitlesRemove
        ? ""
        : checkAndReturnSubtitleStyleColor(data.subtitle.style),
      overviewChanged: homeAnalytics?.overviewChanged || false,
      overviewType: homeAnalytics?.overviewType || OVERVIEW_CONSTANT.SHORTS,
      templateId: currentSelectedMicroContent?.id,
      aspectRatio: data.aspectRatio,
      aiCaptions: editorAnalytics?.aiCaptions || [],
      brandkitBackground:
        editorAnalytics?.backgroundType === BRAND_KIT_BACKGROUND ? true : false,
      brandkitOutros:
        editorAnalytics?.outro?.type === BRAND_KIT_OUTRO ? true : false,
      bRollImageUsed: getBRollAssetId(data.bRolls, B_ROLL_IMAGE),
      bRollVideoUsed: getBRollAssetId(data.bRolls, B_ROLL_VIDEO),
      captionGenerated: editorAnalytics?.captionGenerated || false,
      cutmagicEnabled: editorAnalytics?.cutMagicEnabled || false,
      fontSettingAccessed: editorAnalytics?.fontSettingsAccessed || false,
      fontUploaded: editorAnalytics?.fontUpload || false,
      mediaSearched: editorAnalytics?.mediaSearched || false,
      socialLogos: getSocialLogos(data.socials),
      socialLogoAdded: editorAnalytics?.socialLogoAdded || false,
      socialPreviewAccessed: editorAnalytics?.socialPreviewAccessed || false,
      stockAudioIds: getAudioIds(data.audioClips, editorAnalytics?.audioType),
      stockBackground: checkAndReturnStockBackground(
        editorAnalytics?.backgroundType,
        STOCK_BACKGROUND,
        data.backgroundImageUrl,
        data.backgroundColor
      ),
      stockOutroId:
        editorAnalytics?.outro?.type === STOCK_OUTRO
          ? editorAnalytics?.outro?.id
          : "",
      textEffects: textEffects(data.texts),
      textEffectsAccessed: editorAnalytics?.textEffectChanged || false,
      uploadedAudio: editorAnalytics?.audioUpload || false,
      uploadedBackground: editorAnalytics?.backgroundUpload || false,
      uploadedLogo: editorAnalytics?.logoUpload || false,
      uploadedOutroImage: editorAnalytics?.outroImageUpload || false,
      uploadedOutroVideo: editorAnalytics?.outroVideoUpload || false,
      videoDuration: JSON.parse(currentSelectedProject!.data).duration / 1000,
      videoId: currentSelectedProject?.id,
      videoName: currentSelectedProject?.title,
    };

    ampli.exportClip(eventData);
    trackMoengageEvent(MOENGAGE_EVENTS.EXPORT_CLIP, eventData);
  }
};

export const trackClipExportEvent = (
  projectId: string,
  clipId: string,
  exportPlatform: string
) => {
  // downloading/exporting videos from download screen, not from editor
  if (isProd()) {
    if (exportPlatform === EXPORT_PREFERENCE.DOWNLOAD) {
      ampli.exportClip({
        clipId,
        exportPlatform,
        videoId: projectId,
      });
    } else {
      const exportDescription = store.getState().editorState.caption.text;
      const eventData = {
        clipId,
        exportPlatform,
        exportDescription,
        videoId: projectId,
      };

      ampli.exportClip(eventData);
      trackMoengageEvent(MOENGAGE_EVENTS.EXPORT_CLIP, eventData);
    }
  }
};

export const trackEditClipEvent = (dataObj: any, screenName: ScreenName) => {
  const microcontent = {
    id: dataObj.templateId,
    start: dataObj.start,
    end: dataObj.end,
    gist: dataObj.gist || "",
    chapter_end: dataObj.chapter_end,
    chapter_start: dataObj.chapter_start,
    clipId: dataObj?.clipId,
    tag: dataObj?.tag,
  };
  trackEndAndDownloadEvent(
    screenName,
    dataObj.aspectRatio,
    dataObj.project,
    microcontent
  );
};

export const trackEndAndDownloadEvent = (
  screenName: ScreenName,
  aspectRatio: VideoLayout,
  currentSelectedProject: any,
  currentSelectedMicroContent: any
) => {
  if (isProd()) {
    const homeAnalytics = store.getState().amplitudeState.homeScreen;

    const eventData = {
      aspectRatio,
      clipDuration: getClipDuration(
        currentSelectedMicroContent,
        homeAnalytics?.overviewType === OVERVIEW_CONSTANT.CHAPTERS
      ),
      clipId: currentSelectedMicroContent?.clipId || nanoid(),
      clipName: currentSelectedMicroContent.gist,
      isDiscontinuousClip: currentSelectedMicroContent?.tag === "004",
      favouritesAdd: currentSelectedMicroContent?.bookmarked || false,
      overviewChanged: homeAnalytics?.overviewChanged || false,
      overviewType: homeAnalytics?.overviewType || OVERVIEW_CONSTANT.SHORTS,
      screenName: convertToCamelCase(screenName),
      templateId: currentSelectedMicroContent?.id || "",
      videoDuration:
        JSON.parse(currentSelectedProject!.data)?.duration / 1000 || 0,
      videoId: currentSelectedProject?.id,
      videoName: currentSelectedProject?.title,
    };

    ampli.editDownloadVideoClicked(eventData);
    trackMoengageEvent(
      MOENGAGE_EVENTS.EDIT_AND_DOWNLOAD_VIDEO_CLICK,
      eventData
    );
  }
};

export const trackLayoutChangeEvent = (
  layout: VideoLayout,
  screenName: ScreenName
) => {
  if (isProd()) {
    const currentSelectedProject =
      store.getState().homeState.currentSelectedProject;
    const currentSelectedMicroContent =
      store.getState().homeState.currentSelectedMicroContent;

    const homeAnalytics = store.getState().amplitudeState.homeScreen;

    const eventData = {
      aspectRatio: layout,
      clipDuration: getClipDuration(
        currentSelectedMicroContent,
        homeAnalytics?.overviewType === OVERVIEW_CONSTANT.CHAPTERS
      ),
      clipId: currentSelectedMicroContent?.clipId || nanoid(),
      clipName: currentSelectedMicroContent.gist,
      isDiscontinuousClip: currentSelectedMicroContent?.tag === "004",
      screenName: convertToCamelCase(screenName),
      videoDuration: JSON.parse(currentSelectedProject!.data).duration / 1000,
      videoId: currentSelectedProject?.id,
      videoName: currentSelectedProject?.title,
    };

    ampli.switchClipAspectRatio(eventData);
    trackMoengageEvent(MOENGAGE_EVENTS.SWITCH_CLIP_ASPECT_RATIO, eventData);
  }
};

export const identifyUser = (user: any, onBoardingData?: any) => {
  if (isProd() && user) {
    const userSubscription = store.getState().authState.userSubscription;
    const { nextBillDate, subscriptionStartedAt, intervalInMonths } =
      userSubscription;

    const { currentPlanEndDate, currentPlanEndMonth, currentPlanEndYear } =
      getCurrentPlanEndTimeStamp(subscriptionStartedAt, intervalInMonths);

    ampli.identify(user.uid, {
      userName: user.displayName,
      emailId: user.email,
      isPaidUser: userSubscription.planType !== PlanType.FREE,
      currentPlan: getPlanName(userSubscription.planType),
      currentPlanFrequency:
        userSubscription.intervalInMonths === 12 ? "Annual" : "Monthly",
      currentPlanEndDate,
      currentPlanEndMonth,
      currentPlanEndYear,
      personaOnboarding: onBoardingData?.occupation,
    });
  }
};

export const trackSignupEvent = (user: any) => {
  if (isProd()) {
    identifyUser(user);

    const eventData = {
      userId: user.uid,
      emailId: user.email,
      userName: user.displayName,
      signupSource: user.providerData[0].providerId,
      date: getDateTime(user.metadata.lastLoginAt, DATE_TIME.DATE),
      month: getMonthAsString(
        getDateTime(user.metadata.lastLoginAt, DATE_TIME.MONTH)
      ),
      year: getDateTime(user.metadata.lastLoginAt, DATE_TIME.YEAR),
      hours: getDateTime(user.metadata.lastLoginAt, DATE_TIME.HOUR),
      minutes: getDateTime(user.metadata.lastLoginAt, DATE_TIME.MINUTE),
      seconds: getDateTime(user.metadata.lastLoginAt, DATE_TIME.SECOND),
    };

    ampli.signUp(eventData);
    trackMoengageEvent(MOENGAGE_EVENTS.SIGNUP, eventData, true);
  }
};

export const trackSigninEvent = (user: any) => {
  if (isProd()) {
    const eventData = {
      userId: user.uid,
      emailId: user.email,
      userName: user.displayName,
      signinSource: user.providerData[0].providerId,
      date: getDateTime(user.metadata.lastLoginAt, DATE_TIME.DATE),
      month: getMonthAsString(
        getDateTime(user.metadata.lastLoginAt, DATE_TIME.MONTH)
      ),
      year: getDateTime(user.metadata.lastLoginAt, DATE_TIME.YEAR),
      hours: getDateTime(user.metadata.lastLoginAt, DATE_TIME.HOUR),
      minutes: getDateTime(user.metadata.lastLoginAt, DATE_TIME.MINUTE),
      seconds: getDateTime(user.metadata.lastLoginAt, DATE_TIME.SECOND),
    };
    ampli.signIn(eventData);
    trackMoengageEvent(MOENGAGE_EVENTS.SIGN_IN, eventData, true);
  }
};

export const trackUserOnboardingData = (updatedOnboardingData: any) => {
  if (isProd()) {
    const eventData = {
      userGoals: updatedOnboardingData.user_wants_to,
      socialAccounts: updatedOnboardingData.referer,
      personaOnboarding: updatedOnboardingData.occupation,
      teamPlayer: updatedOnboardingData.isIndividual,
    };

    ampli.finishOnBoarding(eventData);
    trackMoengageEvent(MOENGAGE_EVENTS.FINNISH_ON_BOARDING, eventData);
  }
};

const trackEnableCutMagicEvent = () => {
  if (isProd()) {
    const currentSelectedProject =
      store.getState().homeState.currentSelectedProject;
    const currentSelectedMicroContent =
      store.getState().homeState.currentSelectedMicroContent;

    const homeAnalytics = store.getState().amplitudeState.homeScreen;
    const selectedLayout = store.getState().editorState.selectedLayout;

    const eventData = {
      clipDuration: getClipDuration(
        currentSelectedMicroContent,
        homeAnalytics?.overviewType === OVERVIEW_CONSTANT.CHAPTERS
      ),
      clipId: currentSelectedMicroContent?.clipId || nanoid(),
      clipName: currentSelectedMicroContent.gist,
      isDiscontinuousClip: currentSelectedMicroContent?.tag === "004",
      overviewType: homeAnalytics?.overviewType || OVERVIEW_CONSTANT.SHORTS,
      videoDuration: JSON.parse(currentSelectedProject!.data).duration / 1000,
      videoId: currentSelectedProject?.id,
      videoName: currentSelectedProject?.title,
    };

    ampli.enableCutMagic(eventData);
    trackMoengageEvent(MOENGAGE_EVENTS.ENABLE_CUTMAGIC, eventData);
  }
};

export const trackViewOnboardingEvent = () => {
  if (isProd()) {
    ampli.viewOnBoarding();
    trackMoengageEvent(MOENGAGE_EVENTS.VIEW_ONBOARDING);
  }
};

export const trackReviewClipsEvent = () => {
  if (isProd()) {
    const currentSelectedProject =
      store.getState().homeState.currentSelectedProject;

    const homeAnalytics = store.getState().amplitudeState.homeScreen;

    const eventData = {
      clipCount: homeAnalytics?.clipCount || 0,
      videoDuration: JSON.parse(currentSelectedProject!.data).duration / 1000,
      videoId: currentSelectedProject?.id,
      videoName: currentSelectedProject?.title,
    };

    ampli.reviewClips(eventData);
    trackMoengageEvent(MOENGAGE_EVENTS.REVIEW_CLIPS, eventData);
  }
};

export const trackAddMoreMinutesEvent = async (
  quantity: number,
  totalCost: any
) => {
  if (isProd()) {
    amplitude.init("f4198062250430b00d2519f61f424e71");
    const { currentUser, userSubscription, country } =
      store.getState().authState;
    const currency = getCurrencyByCountryCode(country);

    let amountCharged = totalCost;
    if (currency !== "USD") {
      amountCharged = parseFloat(
        (await convertCurrencyIntoUSD(amountCharged, currency)).toFixed(2)
      );
    }
    if (amountCharged) {
      const eventData = {
        minutesAdded: quantity,
        amountCharged,
        currentPlan: getPlanName(userSubscription.planType),
      };
      ampli.addMoreMinutes(eventData);

      const identifyObj = new amplitude.Identify();
      identifyObj.add("totalSpend", amountCharged);
      identifyObj.add("minutesAdded", quantity);

      amplitude.identify(identifyObj, currentUser.uid);
      const event = new amplitude.Revenue()
        .setPrice(amountCharged)
        .setQuantity(1);

      amplitude.revenue(event, {
        user_id: currentUser.uid,
      });

      trackMoengageEvent(MOENGAGE_EVENTS.ADD_MORE_MINUTES, eventData);
    }
  }
};

// -------- second release events -------

export const trackDeleteClipEvent = (
  currentSelectedMicroContent: any,
  projectData: any,
  screenName = getScreenNameFromHostName()
) => {
  if (isProd()) {
    const homeAnalytics = store.getState().amplitudeState.homeScreen;

    ampli.deleteClip({
      clipDuration: getClipDuration(
        currentSelectedMicroContent,
        homeAnalytics?.overviewType === OVERVIEW_CONSTANT.CHAPTERS
      ),
      clipId: currentSelectedMicroContent?.clipId,
      clipName: currentSelectedMicroContent?.gist,
      isDiscontinuousClip: currentSelectedMicroContent?.tag === "004",
      overviewChanged: homeAnalytics?.overviewChanged || false,
      overviewType: homeAnalytics?.overviewType || OVERVIEW_CONSTANT.SHORTS,
      screenName: convertToCamelCase(screenName),
      templateId: currentSelectedMicroContent?.id,
      videoId: projectData.id,
      videoDuration: JSON.parse(projectData.data).duration / 1000,
      videoName: projectData.title,
    });
  }
};

export const trackRenameClipEvent = (
  currentSelectedMicroContent: any,
  updatedClipName: any
) => {
  if (isProd()) {
    const homeAnalytics = store.getState().amplitudeState.homeScreen;
    const currentSelectedProject =
      store.getState().homeState.currentSelectedProject;

    ampli.renameClip({
      clipDuration: getClipDuration(
        currentSelectedMicroContent,
        homeAnalytics?.overviewType === OVERVIEW_CONSTANT.CHAPTERS
      ),
      clipId: currentSelectedMicroContent?.clipId,
      clipName: currentSelectedMicroContent.gist,
      isDiscontinuousClip: currentSelectedMicroContent?.tag === "004",
      templateId: currentSelectedMicroContent.id,
      overviewChanged: homeAnalytics?.overviewChanged || false,
      overviewType: homeAnalytics?.overviewType || OVERVIEW_CONSTANT.SHORTS,
      updatedClipName,
      videoId: currentSelectedProject?.id,
      videoDuration: JSON.parse(currentSelectedProject!.data).duration / 1000,
      videoName: currentSelectedProject?.title,
    });
  }
};

export const trackCreateCustomClipEvent = (
  projectData: Project,
  newClipData: any
) => {
  try {
    if (isProd()) {
      const homeAnalytics = store.getState().amplitudeState.homeScreen;
      const videoDuration = JSON.parse(projectData.data).duration / 1000;

      const eventData = {
        customClipLength: (newClipData.end - newClipData.start) / 1000,
        customClipType: newClipData.type,
        customClipEnd: newClipData.end / 1000,
        customClipStart: newClipData.start / 1000,
        overviewChanged: homeAnalytics?.overviewChanged || false,
        overviewType:
          homeAnalytics?.overviewType || OVERVIEW_CONSTANT.FULL_VIDEO,
        videoDuration,
        videoId: projectData.id,
        videoName: projectData.title,
      };
      ampli.createCustomClip(eventData);
      trackMoengageEvent(MOENGAGE_EVENTS.CREATE_CUSTOM_CLIP, eventData);
    }
  } catch (err: any) {
    // do nothing
  }
};

export const trackReviewClipsInteractionEvents = (
  event: string,
  value?: string
) => {
  if (isProd()) {
    const currentSelectedMicroContent =
      store.getState().homeState.currentSelectedMicroContent;

    let eventData = null;
    switch (event) {
      case REVIEW_CLIPS_INTERACTION.TRANSCRIPT_CLICK:
        eventData = {
          transcriptClick: true,
          clipId: currentSelectedMicroContent?.clipId,
          isDiscontinuousClip: currentSelectedMicroContent?.tag === "004",
        };
        ampli.reviewScreenInteractions(eventData);
        trackMoengageEvent(MOENGAGE_EVENTS.REVIEW_SCREEN_INTERATION, eventData);
        break;

      case REVIEW_CLIPS_INTERACTION.TRANSCRIPT_COPIED:
        eventData = {
          transcriptCopied: true,
          clipId: currentSelectedMicroContent?.clipId,
          isDiscontinuousClip: currentSelectedMicroContent?.tag === "004",
        };
        ampli.reviewScreenInteractions(eventData);
        trackMoengageEvent(MOENGAGE_EVENTS.REVIEW_SCREEN_INTERATION, eventData);
        break;

      case REVIEW_CLIPS_INTERACTION.VIDEO_TIMESTAMP_CLICKED:
        eventData = {
          videoTimestampClick: true,
          clipId: currentSelectedMicroContent?.clipId,
          isDiscontinuousClip: currentSelectedMicroContent?.tag === "004",
        };
        ampli.reviewScreenInteractions(eventData);
        trackMoengageEvent(MOENGAGE_EVENTS.REVIEW_SCREEN_INTERATION, eventData);
        break;

      case REVIEW_CLIPS_INTERACTION.VIDEO_TIMESTAMP_COPIED:
        eventData = {
          videoTimestampCopied: true,
          clipId: currentSelectedMicroContent?.clipId,
          isDiscontinuousClip: currentSelectedMicroContent?.tag === "004",
        };
        ampli.reviewScreenInteractions(eventData);
        trackMoengageEvent(MOENGAGE_EVENTS.REVIEW_SCREEN_INTERATION, eventData);
        break;

      case REVIEW_CLIPS_INTERACTION.AI_CAPTION_CLICK:
        eventData = {
          aiCaptionClicked: true,
          clipId: currentSelectedMicroContent?.clipId,
        };
        ampli.reviewScreenInteractions(eventData);
        trackMoengageEvent(MOENGAGE_EVENTS.REVIEW_SCREEN_INTERATION, eventData);
        break;

      case REVIEW_CLIPS_INTERACTION.AI_CAPTIONS:
        const screenName = getScreenNameFromHostName();

        if (screenName === ScreenName.REVIEW_CLIPS) {
          eventData = {
            captionGenerated: true,
            aiCaptions: value,
            clipId: currentSelectedMicroContent?.clipId,
          };
          ampli.reviewScreenInteractions(eventData);
          trackMoengageEvent(
            MOENGAGE_EVENTS.REVIEW_SCREEN_INTERATION,
            eventData
          );
        }
        break;
    }
  }
};

export const trackTemplateSaveEvent = (currentSelectedMicroContent: any) => {
  return;
  // if (isProd()) {
  //   const homeAnalytics = store.getState().amplitudeState.homeScreen;
  //   const selectedLayout = store.getState().editorState.selectedLayout;
  //   ampli.saveTemplate({
  //     aspectRatio: selectedLayout,
  //     clipDuration: getClipDuration(
  //       currentSelectedMicroContent,
  //       homeAnalytics?.overviewType === OVERVIEW_CONSTANT.CHAPTERS
  //     ),
  //     clipId: currentSelectedMicroContent.clipId,
  //     clipName: currentSelectedMicroContent.gist,
  //     overviewChanged: homeAnalytics?.overviewChanged || false,
  //     overviewType: homeAnalytics?.overviewType || OVERVIEW_CONSTANT.SHORTS,
  //     templateId: currentSelectedMicroContent.id,
  //   });
  // }
};

export const trackDiscordIconClick = () => {
  if (isProd()) {
    ampli.discordIconClick();
    trackMoengageEvent(MOENGAGE_EVENTS.DISCORD_ICON_CLICK);
  }
};

export const trackWhatsNewIconClick = () => {
  if (isProd()) {
    ampli.whatsNewIconClick();
    trackMoengageEvent(MOENGAGE_EVENTS.WHATS_NEW_ICON_CLICK);
  }
};

export const trackImportVideoClick = () => {
  if (isProd()) {
    ampli.importVideoClick();
    trackMoengageEvent(MOENGAGE_EVENTS.IMPORT_VIDEO_CLICK);
  }
};

export const trackProjectImportEvent = (platform: string) => {
  if (isProd()) {
    let eventData = null;
    if (platform === "react:Dashboard") {
      eventData = {
        videoSourcePlatform: IMPORT_PREFERENCE.LOCAL_DEVICE,
      };
      ampli.clickStartImport(eventData);
      trackMoengageEvent(MOENGAGE_EVENTS.CLICK_START_IMPORT, eventData);
    } else {
      eventData = {
        videoSourcePlatform: platform,
      };
      ampli.clickStartImport(eventData);
      trackMoengageEvent(MOENGAGE_EVENTS.CLICK_START_IMPORT, eventData);
    }
  }
};

export const trackDownloadsTabClick = () => {
  if (isProd()) {
    getScreenNameFromHostName() !== ScreenName.DOWNLOADS &&
      ampli.downloadsClick();
    trackMoengageEvent(MOENGAGE_EVENTS.DOWNLOADS_CLICK);
  }
};

export const trackMediaTabClick = (tab: string) => {
  if (isProd()) {
    const eventData = { screenName: convertToCamelCase(tab) };
    ampli.mediaClick(eventData);
    trackMoengageEvent(MOENGAGE_EVENTS.MEDIA_CLICK, eventData);
  }
};

export const trackUpgradeButton = () => {
  // if (isProd()) {
  const eventData = {
    screenName: convertToCamelCase(getScreenNameFromHostName()),
  };
  isProd() && ampli.upgradeButtonClick(eventData);
  trackMoengageEvent(MOENGAGE_EVENTS.UPGRADE_BUTTON_CLICK, eventData);
  // }
};

export const addMoreMinutesButtonClick = (source: string) => {
  if (isProd()) {
    const eventData = {
      source,
      screenName: convertToCamelCase(getScreenNameFromHostName()),
    };
    ampli.addMoreMinutesClick(eventData);
    trackMoengageEvent(MOENGAGE_EVENTS.ADD_MORE_MINUTES_CLICK, eventData);
  }
};

export const trackPlatformLinkedProperty = (activePlanforms: any) => {
  if (isProd()) {
    const { currentUser } = store.getState().authState;
    ampli.identify(currentUser.uid, {
      exportSocialAccountsLinked:
        activePlanforms?.map((socialPlatform: any) =>
          convertToCamelCase(socialPlatform)
        ) || [],
    });
  }
};

export const trackUserUsage = (usage: number) => {
  if (isProd()) {
    const { currentUser, userSubscription } = store.getState().authState;
    ampli.identify(currentUser.uid, {
      minutesAvailable: userSubscription.allowedMinutes,
      minutesBalance: userSubscription.allowedMinutes - Math.floor(usage / 60),
    });
  }
};

export const schedulePostsEventTrack = (
  projectId: string,
  clipId: string,
  exportPlatform: string,
  scheduleDate: Date
) => {
  if (isProd()) {
    const exportDescription = store.getState().editorState.caption.text;
    const eventData = {
      clipId,
      exportPlatform,
      exportDescription: exportDescription ? exportDescription : "",
      videoId: projectId,
      postScheduleDate: scheduleDate.getDate(),
      postScheduleMonth: getMonthAsString(scheduleDate.getMonth()),
      postScheduleYear: scheduleDate.getFullYear(),
      postScheduleHour: scheduleDate.getHours(),
      postScheduleMinute: scheduleDate.getMinutes(),
      postScheduleSecond: scheduleDate.getSeconds(),
    };

    ampli.socialMediaPostSchedule(eventData);
  }
};

export const trackSchedulePostUpdateEvent = ({
  clipId,
  isDelete,
  scheduledDate,
}: {
  clipId: string;
  isDelete: boolean;
  scheduledDate?: Date;
}) => {
  // called when ever user modifies schedule data or delete the scheduled post
  if (isProd()) {
    let eventData: any = {};
    if (isDelete) {
      (eventData.postScheduleDeleted = true), (eventData.clipId = clipId);
    } else {
      (eventData.clipId = clipId),
        eventData.clipId,
        (eventData.postScheduleDate = scheduledDate?.getDate());
      eventData.postScheduleMonth = getMonthAsString(scheduledDate?.getMonth());
      eventData.postScheduleYear = scheduledDate?.getFullYear();
      eventData.postScheduleHour = scheduledDate?.getHours();
      eventData.postScheduleMinute = scheduledDate?.getMinutes();
      eventData.postScheduleSecond = scheduledDate?.getSeconds();
    }
    ampli.socialMediaPostSchedule(eventData);
  }
};

export const trackSchedulePostViewEvent = (clipId: string) => {
  if (isProd()) {
    ampli.socialMediaPostSchedule({
      clipId,
      postViewFromLink: true,
    });
  }
};
