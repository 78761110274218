import { FC } from "react";

import BaseModal from "../BaseModal";
import LogoSpinner from "./LogoSpinner";

// import Spinner from "./Spinner";

type FullScreenLoaderProps = {
  showLoader: boolean;
};

const FullPageLoader: FC<FullScreenLoaderProps> = ({ showLoader }) => {
  return (
    <BaseModal
      isModalOpen={showLoader}
      handelModalOpen={() => {}}
      isFullScreenLoader
    >
      {/* hack: adding a empty button to pass initial focus */}
      <button></button>
      {/* <Spinner className="w-12 h-12 -mt-4" /> */}
      <LogoSpinner />
    </BaseModal>
  );
};

export default FullPageLoader;
