import { FC, useState } from "react";
import { useToggle } from "react-use";

import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useQueryClient } from "@tanstack/react-query";
import AwsS3Multipart from "@uppy/aws-s3-multipart";
import Box from "@uppy/box";
import Uppy from "@uppy/core";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import Dropbox from "@uppy/dropbox";
import GoogleDrive from "@uppy/google-drive";
// import Instagram from "@uppy/instagram";
import { Dashboard as DashboardComponent } from "@uppy/react";
import Url from "@uppy/url";
import clsx from "clsx";
import { nanoid } from "nanoid";

import {
  toggleAddMoreMinModal,
  toggleUpgradeToProModal,
  updateCurrentOnBoardingId,
  updateOnBoardingDataById,
} from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import api from "@/api/api";
import { ApiEndpoints } from "@/api/constants/ApiEndPoints";
import { completeUserFileUpload } from "@/api/requests";
import useAddUserPreferenceData from "@/api/useAddUserPreferenceData";
import useGetUserPreference from "@/api/useGetUserPreference";
import usePostYTLink from "@/api/useUploadYoutubeVideo";
import useUserConsumedProcessingTime from "@/api/useUserConsumedProcessingTime";

import {
  BUFFER_TIME_FREE_USER_IN_MINS,
  BUFFER_TIME_PAID_USER_IN_MINS,
  FREE_USER_UPLOAD_FILE_SIZE_LIMIT,
  PRO_USER_UPLOAD_FILE_SIZE_LIMIT,
} from "@/constants";
import { BUTTON_IDS } from "@/constants/segment-analytics";

import {
  calculateRemainingProcessingTime,
  isValidYoutubeUrl,
  generateUploadedVideoUrl,
} from "@/helpers";

import {
  AMPLITUDE_CLICK_EVENTS,
  ANALYTICS_CONSTANTS,
  addMoreMinutesButtonClick,
  eventsDataToRedux,
  trackProjectImportEvent,
} from "@/utils/amplitudeAnalytcs";
import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

import { OnBoardingStep, PlanType, SimpleAssetType } from "@/enums";

import Spinner from "@/components/Loader/Spinner";

import YoutubeIcon from "@/assets/icons/social/youtube.svg";

const { GENERATE_CLIP_CONTINUE } = BUTTON_IDS.ON_BOARDING_UPLOAD_FILE;

type OnBoardingUploadFileProps = {
  handleChangeActiveStep: any;
  cancelProjectSuccessCB: (closeModal: boolean) => void;
  isVisible: boolean;
  userHasExistingProject?: boolean;
};

const OnBoardingUploadFile: FC<OnBoardingUploadFileProps> = ({
  handleChangeActiveStep,
  cancelProjectSuccessCB,
  isVisible,
  userHasExistingProject,
}) => {
  const onBoardingId = nanoid();

  const queryClient = useQueryClient();

  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [isVideoMetaDataLoading, toggleIsVideoMetaDataLoading] =
    useToggle(false);
  const [error, setError] = useState("");
  // const [showMovFileError, setShowMovFileError] = useState(false);
  const [showBuyMoreMinutes, setShowBuyMoreMinutes] = useState(false);
  const [isYoutubeUrlValid, setIsYoutubeUrlValid] = useState<boolean>(false);
  const [isInfoChecked, setIsInfoChecked] = useState<boolean>(true);

  const { data: usageData } = useUserConsumedProcessingTime();

  const dispatch = useAppDispatch();

  const { uid } = useAppSelector((state) => state.authState.currentUser);
  const { data: firestoreUserPreferenceData } = useGetUserPreference(uid);
  const { mutate: addUserPreferenceData } = useAddUserPreferenceData();

  const currentUserSubscriptionDetails = useAppSelector(
    (state) => state.authState.userSubscription
  );
  const planType = currentUserSubscriptionDetails.planType;

  const isPaidUser = planType !== PlanType.FREE;

  const refetchProjectCB = () => {
    queryClient.invalidateQueries({ queryKey: ["all-projects"] });
  };

  const uppy = new Uppy({
    id: onBoardingId,
    restrictions: {
      maxNumberOfFiles: 1,
      minNumberOfFiles: 1,
      maxFileSize:
        planType === PlanType.FREE
          ? FREE_USER_UPLOAD_FILE_SIZE_LIMIT
          : PRO_USER_UPLOAD_FILE_SIZE_LIMIT,
      allowedFileTypes: ["audio/mpeg"],
    },
    autoProceed: false,
    debug: false,
  });

  uppy.use(Box, {
    companionUrl: ApiEndpoints.UPPY_ENDPOINT,
  });
  uppy.use(Dropbox, {
    companionUrl: ApiEndpoints.UPPY_ENDPOINT,
  });
  uppy.use(GoogleDrive, {
    companionUrl: ApiEndpoints.UPPY_ENDPOINT,
  });
  // uppy.use(Instagram, {
  //   companionUrl: uppyCompanionUrl,
  // });
  uppy.use(Url, {
    companionUrl: ApiEndpoints.UPPY_ENDPOINT,
  });
  uppy.use(AwsS3Multipart, {
    limit: 5,
    companionUrl: ApiEndpoints.UPPY_ENDPOINT,
    allowedMetaFields: ["name", "type"],
  });

  const getFileSizeErrorMsg = (fileSize: number) => {
    const fileSizeInBytes =
      planType === PlanType.FREE
        ? FREE_USER_UPLOAD_FILE_SIZE_LIMIT
        : PRO_USER_UPLOAD_FILE_SIZE_LIMIT;
    if (fileSize && fileSize > fileSizeInBytes) {
      if (planType !== PlanType.FREE) {
        return "Uhh! This video is too large. Currently, we only support videos up to 15GB. Please try again with a smaller video.";
      } else {
        return "Uhh! This video is too large. Please upload a video less than 5 GB or update to Pro to upload larger videos.";
      }
    }
    return null;
  };

  const upgradeToProElement = () => {
    return (
      <>
        <span
          className="underline font-medium cursor-pointer mx-1"
          onClick={() => {
            if (isPaidUser) {
              dispatch(toggleAddMoreMinModal(true));
              addMoreMinutesButtonClick(
                AMPLITUDE_CLICK_EVENTS.UPLOAD_VIDEO_MODAL
              );
            } else {
              dispatch(toggleUpgradeToProModal(true));
              eventsDataToRedux(ANALYTICS_CONSTANTS.PAYMENT_SCREEN_NAME);
            }
          }}
          id="click-here-to-buy"
        >
          Click here
        </span>
        {isPaidUser ? "to buy more minutes" : "to upgrade"}
      </>
    );
  };

  const onSuccessfulUpload = async (result: any, onBoardingId: string) => {
    let reqData = result.successful[0];

    const data = await completeUserFileUpload({
      remote_url: generateUploadedVideoUrl({
        responseBody: reqData?.response?.body || reqData?.response?.uploadURL,
      }),
      asset_type: reqData.meta.type,
      asset_name: reqData.meta.originalFileName || reqData.meta?.name,
    }).catch((err) => {
      showNotification("Error while creating project", notificationType.FAIL);
    });

    const updatedOnBoardingDataById = {
      projectId: data?.project_id,
      progressPercentage: 100,
      // processingVideoData: null,
    };
    trackProjectImportEvent(reqData.source);

    dispatch(
      updateOnBoardingDataById({
        id: onBoardingId,
        data: updatedOnBoardingDataById,
      })
    );

    refetchProjectCB();
  };

  uppy.on("complete", async (result) =>
    onSuccessfulUpload(result, onBoardingId)
  );

  uppy.on("file-added", (file) => {
    const video = document.createElement(SimpleAssetType.VIDEO);

    if (file.source === "react:Dashboard") {
      const originalFileName = file.name;
      file.name = nanoid() + "." + file.extension;
      file.meta.name = file.name;
      file.meta.originalFileName = originalFileName;

      video.addEventListener("loadedmetadata", () => {
        URL.revokeObjectURL(video.src); // Clean up the object URL

        // if (!video.videoHeight && !video.videoWidth) {
        //   uppy.info(
        //     `The video you're trying to upload is not supported in this browser for playback. Please try uploading another video or consider using a different browser.`,
        //     "error",
        //     10000
        //   );
        //   const uppyButton = document.querySelector(
        //     "button.uppy-StatusBar-actionBtn--upload"
        //   );
        //   if (uppyButton) {
        //     uppyButton.setAttribute("disabled", "disabled");
        //   }
        // }

        const maxDurationInSeconds = calculateRemainingProcessingTime(
          usageData,
          planType === PlanType.FREE
            ? BUFFER_TIME_FREE_USER_IN_MINS
            : BUFFER_TIME_PAID_USER_IN_MINS
        );

        if (video.duration > maxDurationInSeconds) {
          uppy.removeFile(file.id);
          showNotification(
            `The video you're trying to upload exceeds the processing minutes remaining on your account.`,
            notificationType.FAIL,
            true,
            10000,
            true,
            upgradeToProElement()
          );
        }
      });
      video.src = URL.createObjectURL(file.data);
    }
  });

  uppy.on("file-removed", (file, reason) => {
    const uppyInfoElement = document.querySelector(
      "div.uppy-Informer-animated"
    );
    uppyInfoElement && uppyInfoElement.remove();
  });

  function onError(
    onBoardingId: string,
    isUserCancelled = false,
    uppyInstance: any
  ) {
    cancelProjectSuccessCB(false);

    if (isUserCancelled) {
      const updatedOnBoardingDataById = {
        processingVideoData: null,
      };
      dispatch(
        updateOnBoardingDataById({
          id: onBoardingId,
          data: updatedOnBoardingDataById,
        })
      );
    } else {
      // show error
      const updatedOnBoardingDataById = {
        processingVideoData: {
          isProcessing: false,
          isProcessingFailed: true,
          id: onBoardingId,
        },
      };
      dispatch(
        updateOnBoardingDataById({
          id: onBoardingId,
          data: updatedOnBoardingDataById,
        })
      );
    }

    !isUserCancelled &&
      showNotification(
        "Error while uploading file, Please try reuploading the file",
        notificationType.FAIL
      );
  }

  uppy.once("error", (err) => {
    onError(onBoardingId, false, uppy);
  });

  const handleStartUpload = (onBoardingId: string, uppy: any) => {
    addOnBoardingData({
      id: onBoardingId,
      uploader: uppy,
    });
    handleChangeActiveStep(
      userHasExistingProject
        ? OnBoardingStep.PERSONALIZED_VIDEO
        : OnBoardingStep.SELECT_LAYOUTS
    );
  };

  uppy.on("upload", () => handleStartUpload(onBoardingId, uppy));

  uppy.on("upload-error", (file, error, response) => {
    console.log("upload-error", error, response);
  });

  // uppy.on("upload-progress", (file, progress) => {
  //   handleUpdateOnBoardingData({
  //     progressPercentage: (progress.bytesUploaded / progress.bytesTotal) * 100,
  //   });
  // });

  uppy.once("cancel-all", () => onError(onBoardingId, true, uppy));

  uppy.on("restriction-failed", (file, error) => {
    const fileSizeError = getFileSizeErrorMsg(file?.size!);
    const isAllowedType = ["mp3"].includes(file?.extension!);
    if (fileSizeError) {
      uppy.info(fileSizeError, "error", 10000);
    } else if (!isAllowedType) {
      uppy.info("This is audyo.ai", "error", 10000);
    } else {
      uppy.info(error.message, "error", 10000);
    }
  });

  const addOnBoardingData = (processingVideoData: any) => {
    dispatch(
      updateOnBoardingDataById({
        id: onBoardingId,
        data: {
          key: onBoardingId,
          selectedLayouts: [],
          baseTemplates: {},
          progressPercentage: 0,
          processingVideoData,
          createdAt: new Date().toISOString(),
        },
      })
    );
    dispatch(updateCurrentOnBoardingId(onBoardingId));
  };

  const handleUpdateOnBoardingData = (updatedData: any, Id?: any) => {
    dispatch(
      updateOnBoardingDataById({
        id: Id || onBoardingId,
        data: { ...updatedData },
      })
    );
  };

  const youtubeMutateErrorCB = (error: any, Id?: any) => {
    cancelProjectSuccessCB(false);

    handleUpdateOnBoardingData(
      {
        processingVideoData: null,
      },
      Id
    );

    showNotification(
      error.response.data.detail === "Could not download youtube video"
        ? "Sorry, this YouTube video seems to have some problems. Please try and upload the video instead."
        : error.response.data.detail || "Something went wrong!",
      notificationType.FAIL
    );
  };

  const youtubeMutateSuccessCB = (data: any, Id?: any) => {
    handleUpdateOnBoardingData(
      {
        projectId: data.project_id,
      },
      Id
    );

    setYoutubeUrl("");
  };

  const { mutate } = usePostYTLink(
    youtubeMutateSuccessCB,
    youtubeMutateErrorCB
  );

  const checkIfUserAllowedToProceed = (
    currentVideoDuration: any,
    successCB: any,
    fileSize?: number
  ) => {
    const { planType } = currentUserSubscriptionDetails;
    const fileSizeError = getFileSizeErrorMsg(fileSize!);

    if (fileSizeError) {
      setError(fileSizeError);
    } else if (
      currentVideoDuration <=
      calculateRemainingProcessingTime(
        usageData,
        planType === PlanType.FREE
          ? BUFFER_TIME_FREE_USER_IN_MINS
          : BUFFER_TIME_PAID_USER_IN_MINS
      )
    ) {
      successCB && successCB();
    } else {
      setError(
        "The video you're trying to upload exceeds the processing minutes remaining on your account."
      );
      setShowBuyMoreMinutes(true);
    }
  };

  const getVideoDetailsFromYoutube = async (youtubeUrl: any) => {
    let requestBody;
    try {
      requestBody = {
        yt_url: youtubeUrl,
      };
      const resData = await api.post(
        ApiEndpoints.YOUTUBE_DATA_API,
        requestBody
      );
      toggleIsVideoMetaDataLoading(false);
      return resData.data;
    } catch (error) {
      console.error(
        "getVideoDetailsFromYoutube Failed to send data.",
        requestBody,
        error
      );
      // log as much info as possible of the error
      console.error(JSON.stringify(error));

      toggleIsVideoMetaDataLoading(false);
      throw error;
    }
  };

  const handleYoutubeInputChange = (youtubeUrl: string) => {
    const youtubeVideoId = isValidYoutubeUrl(youtubeUrl);
    setError("");
    setYoutubeUrl(youtubeUrl);
    setIsYoutubeUrlValid(youtubeVideoId ? true : false);
  };

  const getYoutubeErrorMsg = (errorCode: string) => {
    switch (errorCode) {
      case "livestream":
        return "Sorry, live Youtube videos are not supported. Please try another video instead.";

      case "private":
        return "Sorry, this is a private Youtube videos. Please try another video instead.";

      case "age_restricted":
        return "Sorry, this Youtube video is restricted for certain age groups. Please try another video instead.";

      case "members_only":
        return "Sorry, this Youtube video is restricted to members or subscribers of a particular channel. Please try another video instead.";

      case "recording_unavailable":
      case "unavailable":
        return "Sorry, this Youtube video is no longer available. Please try another video instead.";

      case "region_blocked":
        return "Sorry, this Youtube video is not available in your geographical location. Please try another video instead.";

      default:
        return "Sorry, this YouTube video seems to have some problems. Please try and upload the video instead.";
    }
  };

  const handleUploadYouTubeVideo = async () => {
    setError("");
    const youtubeVideoId = isValidYoutubeUrl(youtubeUrl);

    if (youtubeVideoId) {
      // addOnBoardingData(null);
      // handleChangeActiveStep(OnBoardingStep.SELECT_LAYOUTS);

      try {
        // added here to save the youtube import preference to firestore
        saveImportPreference(isInfoChecked);

        const ytVideoDetails: any = await getVideoDetailsFromYoutube(
          youtubeUrl
        );

        const { reason, title, duration, available } = ytVideoDetails || {};

        const ytOption = {
          id: onBoardingId,
          isYoutubeLocal: true,
          video_id: youtubeVideoId,
          title: title,
          duration: duration,
        };

        const videoDuration = duration / 1000 || 0;

        if (available) {
          checkIfUserAllowedToProceed(videoDuration, () => {
            addOnBoardingData(ytOption);
            handleChangeActiveStep(
              userHasExistingProject
                ? OnBoardingStep.PERSONALIZED_VIDEO
                : OnBoardingStep.SELECT_LAYOUTS
            );
            mutate({ ytLink: youtubeUrl, onBoardingId });

            return;
          });
        } else {
          showNotification(
            getYoutubeErrorMsg(reason),
            notificationType.WARN,
            false
          );
          handleUpdateOnBoardingData({
            processingVideoData: null,
          });
        }
      } catch {
        showNotification(
          "Please try again with another video!",
          notificationType.FAIL,
          false
        );
        handleUpdateOnBoardingData({
          processingVideoData: null,
        });
      }
    } else {
      //  write some code
      showNotification("Invalid youtube URL", notificationType.WARN, false);
      toggleIsVideoMetaDataLoading(false);
    }
  };

  const handleContinue = () => {
    toggleIsVideoMetaDataLoading(true);
    if (youtubeUrl) {
      handleUploadYouTubeVideo();
      trackProjectImportEvent("youtube");
    }
  };

  const saveImportPreference = (isInfoChecked: boolean) => {
    const requestData = {
      ...firestoreUserPreferenceData,
      allowLowResImport: isInfoChecked,
    };
    addUserPreferenceData({
      userId: uid,
      userPreferenceData: requestData,
    });
  };

  const handleCheckbox = () => {
    setIsInfoChecked((prevState) => !prevState);
  };

  return (
    <div
      className={`relative h-full rounded dark:bg-gray-700 ${
        isVisible ? "" : "hidden"
      }`}
      id="dashboardContainer"
    >
      <div
        className="mx-auto py-6 px-4 lg:px-6 bg-white flex flex-col items-center justify-center h-full"
        style={{ width: "780px", height: "620px", maxWidth: "780px" }}
      >
        <h2 className="mb-6 text-center text-2xl font-medium tracking-tight text-gray-600 dark:text-white sm:text-3xl">
          Import your video
        </h2>
        <div className="">
          {/* <p className="text-red-500 text-xs mt-2 text-center">
            We are currently experiencing difficulties with importing from
            YouTube. Our team is actively working on a solution and we apologize
            for any inconvenience caused. Please try again later.
          </p> */}

          <div className="pb-4 mx-auto text-center text-gray-500 text-lg">
            We support <span className="text-gray-700">ENGLISH</span> videos
            only. (More languages coming soon)
          </div>
          <div className="flex space-x-2 relative">
            <span className="w-full absolute z-10 top-8 flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                />
              </svg>
            </span>

            <DashboardComponent
              uppy={uppy}
              width={730}
              height={275}
              proudlyDisplayPoweredByUppy={false}
              showProgressDetails={true}
              plugins={["Box", "GoogleDrive", "Dropbox", "Url"]}
              note="Upload audio"
            />
            {/* <p className="absolute text-gray-500 text-xs w-full text-center bottom-5">
              Coming Soon: Dropbox and OneDrive 🚀
            </p> */}
          </div>

          {error && (
            <span className="text-xs text-red-500">
              {error}
              {showBuyMoreMinutes && upgradeToProElement()}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default OnBoardingUploadFile;
