import { memo } from "react";

import LogoSpinner from "@/components/Loader/LogoSpinner";

export const LoadingDisplay = memo(() => (
  <div className="flex h-full w-full flex-col justify-between">
    <div className="relative flex h-full w-full flex-col items-center justify-center">
      <LogoSpinner />
    </div>
  </div>
));
