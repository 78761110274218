import { SubArrItem } from "@/interfaces";

// @co-author: chatGPT.
export function findOverlappedSubtitles(
  subtitles: SubArrItem[]
): SubArrItem["id"][] {
  // Sort the subtitles by their start times
  const sortedSubtitles = [...subtitles].sort(
    (prevSub, nextSub) => prevSub.start - nextSub.start
  );

  let overlappedIDs = new Set<SubArrItem["id"]>();

  for (let i = 0; i < sortedSubtitles.length; i++) {
    for (
      let j = i + 1;
      j < sortedSubtitles.length &&
      sortedSubtitles[i].end > sortedSubtitles[j].start;
      j++
    ) {
      overlappedIDs.add(sortedSubtitles[i].id);
      overlappedIDs.add(sortedSubtitles[j].id);
    }
  }

  return [...overlappedIDs];
}
