import { useState } from "react";

import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { sendPasswordResetEmail } from "firebase/auth";
import { Button, Label, TextInput } from "flowbite-react";

import { LOGIN } from "@/constants";

import { notificationType } from "@/utils/constants";
import { auth } from "@/utils/firebase";
import { showNotification } from "@/utils/showNotification";

import HelperComponent from "./HelperComponent";
import { validateEmail } from "./validator";

const ForgotPassword = ({
  updateCurrentTab,
  showHelperComponent,
  setShowHelperComponent,
}: {
  updateCurrentTab: (value: string) => void;
  showHelperComponent: boolean;
  setShowHelperComponent: (value: boolean) => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState("");

  const handelSubmitForm = (e: any) => {
    e.preventDefault();
    if (e.target.email.value === "" || !e.target.email.value) {
      setEmailError("Please enter email");
    } else {
      setIsLoading(true);
      sendPasswordResetEmail(auth, e.target.email.value)
        .then(() => {
          setShowHelperComponent(true);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          switch (error.code) {
            case "auth/user-not-found":
              showNotification(
                "Email not found, please sign up first",
                notificationType.FAIL
              );
              break;
            default:
              showNotification(
                "Error while sending password reset link, please try again",
                notificationType.FAIL
              );
          }
        });
    }
  };

  const handleEmailChangeValidation = (e: any) => {
    validateEmail(e.target.value, setEmailError);
  };
  return (
    <form onSubmit={handelSubmitForm}>
      {showHelperComponent ? (
        <HelperComponent
          headline="We've sent you an email ✅"
          message="Please click the link in you email to reset your password"
          updateCurrentTab={updateCurrentTab}
          setShowHelperComponent={setShowHelperComponent}
          passwordReset={true}
        />
      ) : (
        <div className="xl:mt-36 lg:mt-24 my-10">
          <h1 className="text-2xl font-semibold text-gray-700 dark:text-white pb-6">
            Reset your password
          </h1>

          <div className="my-4">
            <Label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Email <span className="text-red-600">*</span>
            </Label>
            <TextInput
              type="email"
              name="email"
              id="email"
              onChange={handleEmailChangeValidation}
              className=" text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              placeholder="Enter your email"
              color={`${emailError && "failure"}`}
              helperText={
                <span style={{ marginTop: "-10px" }}>
                  {emailError && <>{emailError}</>}
                </span>
              }
            />
          </div>

          <div className="w-full flex pb-6">
            <div className="text-left my-auto">
              <InformationCircleIcon className="mr-1 h-6 w-6 text-gray-600" />
            </div>
            <div
              className="text-left ml-1.5 text-sm"
              style={{ color: "#6B7280" }}
            >
              <p>We'll send reset instructions to your email</p>
            </div>
          </div>
          <div>
            <Button
              type={isLoading ? "button" : "submit"}
              id="forgot-password-button"
              className={`mb-6 w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 ${
                isLoading ? "cursor-wait opacity-70" : ""
              }`}
            >
              {isLoading ? (
                <div
                  role="status"
                  className="flex mx-auto relative w-max"
                >
                  <svg
                    aria-hidden="true"
                    className="mr-2 w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Processing...</span>
                  <span>Continue</span>
                </div>
              ) : (
                <span>Continue</span>
              )}
            </Button>
            <Button
              color="light"
              className=" mt-4 w-full rounded-lg border border-blue-500 bg-white text-center text-sm font-medium text-blue-500 hover:bg-blue-50  focus:outline-none focus:ring-4 focus:ring-blue-300"
              onClick={() => updateCurrentTab(LOGIN)}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </form>
  );
};

export default ForgotPassword;
