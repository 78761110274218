import { useState } from "react";

import { toggleUpgradeToProModal } from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import useAddUserPreferenceData from "@/api/useAddUserPreferenceData";
import useGetAssets from "@/api/useGetAssets";
import useGetAssetsCount from "@/api/useGetAssetsCount";
import useGetUserPreference from "@/api/useGetUserPreference";

import {
  ANALYTICS_CONSTANTS,
  eventsDataToRedux,
} from "@/utils/amplitudeAnalytcs";

import { AssetTags, BrandKitMenu, PlanType, SimpleAssetType } from "@/enums";

import LogoSpinner from "@/components/Loader/LogoSpinner";

import ColorUploadModal from "../UploadMedia/ColorUploadModal";
import MediaUploader from "../UploadMedia/MediaUploader";
import MediaUploaderCard from "../UploadMedia/MediaUploaderCard";
import AssetCard from "./AssetCard";
import BrandKitSplash from "./BrandKitSplash";
import ProOnlyFeature from "./ProOnlyFeature";

const AssetsContainer = ({
  supportedFilesDisplayText,
  supportedMediaTypes,
  assetTypeText,
  assetTag,
}: {
  supportedFilesDisplayText: string;
  supportedMediaTypes: Record<string, string[]>;
  assetTypeText: string;
  assetTag: AssetTags;
}) => {
  const dispatch = useAppDispatch();

  const [showColorUploadModal, setShowColorUploadModal] = useState(false);

  const uid = useAppSelector((state) => state.authState.currentUser.uid);

  const planType = useAppSelector(
    (state) => state.authState.userSubscription.planType
  );

  const { data, isLoading } = useGetAssets({
    assetTag,
    disableQuery: assetTypeText === BrandKitMenu.COLOR_PALETTE,
  });

  const { data: assetCountData, isLoading: assetCountLoading } =
    useGetAssetsCount();

  const { data: userPreferenceData, isLoading: userPreferenceDataLoading } =
    useGetUserPreference(uid);

  const {
    mutate: addUserPreferenceData,
    isLoading: addUserPreferenceMutationLoading,
  } = useAddUserPreferenceData();

  const getColorPallets = () => {
    if (userPreferenceData?.colorPallets) {
      return userPreferenceData?.colorPallets;
    }
    return [];
  };

  const handelRenderAssets = () => {
    if (data?.length) {
      if (assetTypeText === BrandKitMenu.IMAGE) {
        return data?.filter((file) =>
          file.assetType.includes(SimpleAssetType.IMAGE)
        );
      } else if (assetTypeText === BrandKitMenu.VIDEO) {
        return data?.filter((file) =>
          file.assetType.includes(SimpleAssetType.VIDEO)
        );
      } else {
        return data;
      }
    } else {
      return [];
    }
  };

  const handleDeleteColorFromPalette = (colorInHex: string) => {
    const requestData = {
      ...userPreferenceData,
      colorPallets: userPreferenceData?.colorPallets.filter(
        (color) => color !== colorInHex
      ),
    };

    addUserPreferenceData(
      {
        userId: uid,
        userPreferenceData: requestData,
      },
      {
        onSuccess: () => {
          setShowColorUploadModal(false);
        },
      }
    );
  };

  const handleUpdateBrandKitVisitedState = () => {
    let requestData = {};
    if (userPreferenceData?.createdAt) {
      requestData = {
        ...userPreferenceData,
        isVisitedBrandKitPage: true,
      };
    } else {
      requestData = {
        userPreferenceData: {},
        selectedLayouts: [],
        socialMediaHandles: [],
        savePreference: false,
        createdAt: new Date(),
        colorPallets: [],
        isVisitedBrandKitPage: true,
      };
    }

    addUserPreferenceData({
      userId: uid,
      userPreferenceData: requestData,
    });
  };

  const handleOpenUpgradeToProModal = () => {
    dispatch(toggleUpgradeToProModal(true));
    eventsDataToRedux(ANALYTICS_CONSTANTS.PAYMENT_SCREEN_NAME);
  };

  if (isLoading && assetTypeText !== BrandKitMenu.COLOR_PALETTE) {
    return (
      <div className="w-full h-[calc(100%-_5rem)] flex justify-center flex-col items-center bg-white">
        <div>
          <LogoSpinner />
        </div>
      </div>
    );
  }

  if (
    userPreferenceDataLoading &&
    assetTypeText === BrandKitMenu.COLOR_PALETTE
  ) {
    return (
      <div className="w-full h-[calc(100%-_5rem)] flex justify-center flex-col items-center bg-white">
        <div>
          <LogoSpinner />
        </div>
      </div>
    );
  }

  if (
    !userPreferenceData?.isVisitedBrandKitPage &&
    !userPreferenceDataLoading &&
    !isLoading
  ) {
    return (
      <BrandKitSplash
        handleUpdateBrandKitVisitedState={handleUpdateBrandKitVisitedState}
        addUserPreferenceMutationLoading={addUserPreferenceMutationLoading}
      />
    );
  }

  if (
    planType === PlanType.FREE &&
    (assetTypeText === BrandKitMenu.COLOR_PALETTE ||
      assetTypeText === BrandKitMenu.FONT)
  ) {
    return <ProOnlyFeature assetTypeText={assetTypeText} />;
  }

  return (
    <>
      {data?.length === 0 ? (
        <div className="w-full h-[90%] flex justify-center flex-col items-center">
          <MediaUploader
            supportedMediaTypes={supportedMediaTypes}
            secondaryText="Can upload upto 3 Assets(Free), Unlimited(Pro)"
            supportedFilesDisplayText={supportedFilesDisplayText}
            assetTag={assetTag}
            isFreeUserLimitReached={
              !assetCountLoading && !assetCountData?.isAllowedToUpload
            }
            key={`media-uploader__${assetTypeText}`}
          />
        </div>
      ) : (
        <ul
          role="list"
          className={`px-4 grid grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-8 lg:grid-cols-4 sm:gap-x-6 xl:grid-cols-5 xl:gap-x-8 my-4`}
        >
          <MediaUploaderCard
            supportedMediaTypes={supportedMediaTypes}
            assetTypeText={assetTypeText}
            assetTag={assetTag}
            isColorUploader={assetTypeText === BrandKitMenu.COLOR_PALETTE}
            handelShowColorUploadModal={() => setShowColorUploadModal(true)}
            isFreeUserLimitReached={
              !assetCountLoading && !assetCountData?.isAllowedToUpload
            }
            handleOpenUpgradeToProModal={handleOpenUpgradeToProModal}
            key={`media-uploader-card__${assetTypeText}`}
          />
          {assetTypeText === BrandKitMenu.COLOR_PALETTE
            ? getColorPallets().map((file, index) => (
                <AssetCard
                  key={`${file}__${index}`}
                  fileName={file}
                  assetType="color"
                  handleDeleteColorFromPalette={handleDeleteColorFromPalette}
                />
              ))
            : handelRenderAssets().map((file) => (
                <AssetCard
                  key={file.id}
                  src={file.data && JSON.parse(file.data).remote_url}
                  fileName={file.data && JSON.parse(file.data).asset_name}
                  assetId={file.id}
                  assetType={file.assetType}
                />
              ))}
        </ul>
      )}
      <ColorUploadModal
        showColorUploadModal={showColorUploadModal}
        setShowColorUploadModal={setShowColorUploadModal}
      />
    </>
  );
};

export default AssetsContainer;
