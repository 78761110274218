import { useEffect, useRef } from "react";
import { useUpdateEffect } from "react-use";

import Hls from "hls.js";

function VideoJS({
  onTimeUpdate,
  initCanvas,
  syncBRollsVideoTimeWithMainVideo,
  videoElRef,
  url,
  onEnded,
  setIsVideoSeeked,
  poster,
  playbackRate = 1,
  ...props
}: {
  onTimeUpdate: (event: any) => void;
  initCanvas: (event: any) => void;
  syncBRollsVideoTimeWithMainVideo?: (time: number) => void;
  videoElRef: any;
  url: string;
  onEnded?: () => void;
  setIsVideoSeeked?: (isSeeked: boolean) => void;
  poster?: string;
  playbackRate?: number;
}) {
  const hlsRef = useRef<any>(null);

  useEffect(() => {
    const video = videoElRef.current;

    if (!video) return;

    if (Hls.isSupported() && /\.m3u8$/i.test(url)) {
      const hls = (hlsRef.current = new Hls({
        debug: false,
        lowLatencyMode: true,
        maxBufferLength: 5, // Maximum buffer length (in seconds)
        autoStartLoad: true,
        maxBufferSize: 0,
      }));

      hls.loadSource(url);
      hls.attachMedia(video);

      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        const availableQualities = hls.levels;
        const preferredQuality = availableQualities.find(
          (quality) => quality.height === 480 || quality.height === 720
        );
        const preferredQualityIndex =
          availableQualities.findIndex(
            (quality) => quality.height === 480 || quality.height === 720
          ) || 0;

        if (preferredQuality) {
          hls.currentLevel = preferredQualityIndex;
        }
      });

      hls.on(Hls.Events.ERROR, (event, data) => {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              hls.recoverMediaError();
              break;
            default:
              break;
          }
        }
      });
    } else if (
      Hls.isSupported() &&
      video.canPlayType("application/vnd.apple.mpegurl")
    ) {
      video.src = url; // If the browser supports HLS natively, use the native player
    } else {
      // if the url is not HLS, use a normal video
      video.src = url;
    }

    if (video) {
      video.playbackRate = playbackRate;
    }
    return () => {
      if (hlsRef.current) {
        hlsRef.current.destroy();
      }
      if (video) {
        video.removeAttribute("src"); // empty source
        video.load();
      }
    };
  }, [url]);

  useUpdateEffect(() => {
    if (videoElRef.current) {
      videoElRef.current.playbackRate = playbackRate;
    }
  }, [playbackRate]);

  return (
    <video
      {...props}
      poster={poster || ""}
      ref={videoElRef}
      className="hidden"
      muted
      autoPlay
      onLoadedMetadata={initCanvas}
      onTimeUpdate={onTimeUpdate}
      onSeeked={() => {
        syncBRollsVideoTimeWithMainVideo &&
          syncBRollsVideoTimeWithMainVideo(
            videoElRef.current.currentTime * 1000
          );
        setIsVideoSeeked && setIsVideoSeeked(true);
      }}
      onEnded={onEnded}
    />
  );
}

export default VideoJS;
