import { useEffect, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { Label, TextInput } from "flowbite-react";

import { changeSelectedLayout } from "@/store/editorSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { createMicroContent, fetchThumbnail } from "@/api/requests";
import { fetchSubtitles } from "@/api/useFetchSubtitles";

import { BUTTON_IDS } from "@/constants/segment-analytics";

import { formatSeconds, getSecondsFromHMSM } from "@/helpers";

import { trackUserButtonEvent } from "@/utils/SegmentAnalytics";
import { trackCreateCustomClipEvent } from "@/utils/amplitudeAnalytcs";
import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

import { VideoLayout } from "@/enums";

import BaseModal from "@/components/BaseModal/BaseModal";
import Spinner from "@/components/Loader/Spinner";
import TimeField from "@/components/TimeField";

const CHAPTER_START_END_OFFSET = 10000;
const MIN_REEL_DURATION = 180000; // 3 minutes

const { CREATE_CUSTOM_MODAL_CONTINUE_BTN } = BUTTON_IDS.CUSTOM_CLIP_MODAL;

type TimeFieldEntity = "start" | "end";

const CustomClipModal = ({
  isOpen,
  onCancel,
  getRandomTemplate,
  layout,
}: any) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const currentProject = useAppSelector(
    (state) => state.homeState.currentSelectedProject
  );

  const parsedProjectData =
    currentProject?.data && JSON.parse(currentProject?.data);
  const maxClipEnd =
    (parsedProjectData?.duration && Math.floor(parsedProjectData.duration)) ||
    0;

  const [loadingClip, setLoadingClip] = useState(false);
  const [inputTitle, setInputTitle] = useState<any>("Untitled clip");
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(maxClipEnd);

  useEffect(() => {
    setStartTime(0);
    setEndTime(maxClipEnd);
    setLoadingClip(false);
  }, [isOpen]);

  const handleTimeChange = (ent: TimeFieldEntity) => (value: string) => {
    const newTime = getSecondsFromHMSM(value);

    if (typeof newTime === "undefined") {
      return;
    }

    const newTimeInMS = newTime * 1000;

    const condition1 = {
      start: newTimeInMS <= maxClipEnd,
      end: newTimeInMS >= startTime && newTimeInMS <= maxClipEnd,
    };

    const setter = {
      start: setStartTime,
      end: setEndTime,
    };

    if (newTime >= 0 && condition1[ent]) {
      setter[ent](newTimeInMS);
      return newTime.toString();
    } else if (newTime > maxClipEnd) {
      showNotification(
        "Time cannot be greater than the duration of the video",
        notificationType.FAIL
      );
    }
  };

  const validateClip = () => {
    if (endTime - startTime < 0) {
      showNotification(
        "End time cannot be less than start time",
        notificationType.WARN
      );
      return false;
    }

    if (endTime - startTime < 1000) {
      showNotification(
        "Clip duration should be at least 1 second",
        notificationType.WARN
      );
      return false;
    }

    return true;
  };

  const onConfirm = async () => {
    if (!validateClip()) return;
    if (!currentProject) {
      // this should not happen
      showNotification(
        "Something went wrong while trying to create this clip please try again later",
        "error"
      );
      onCancel();
      return;
    }
    trackUserButtonEvent(
      CREATE_CUSTOM_MODAL_CONTINUE_BTN.ID,
      CREATE_CUSTOM_MODAL_CONTINUE_BTN.ACTION
    );
    setLoadingClip(true);

    const newCustomClipObject: any = {
      project_id: currentProject.id,
      type: "",
      start: 0,
      end: 0,
      rating: false,
      summary: "",
      text: "",
      gist: inputTitle,
      headline: "",
      thumbnail_src: "",
      thumbnail_ts: 0,
      face_coord: [],
      srt_string: "",
      chapter_start: 0,
      chapter_end: 0,
      captions: {},
    };

    if (layout === null) {
      // no clip case
      if (endTime - startTime > MIN_REEL_DURATION) {
        newCustomClipObject.type = "chapter";
        layout = VideoLayout.LAYOUT_16_9;
      } else {
        newCustomClipObject.type = "short";
        layout = VideoLayout.LAYOUT_9_16_1;
      }
    } else {
      if (layout === VideoLayout.LAYOUT_16_9)
        newCustomClipObject.type = "chapter";
      else newCustomClipObject.type = "short";
    }

    const template = getRandomTemplate(layout);
    newCustomClipObject.id = template.id;

    newCustomClipObject.start = startTime;
    newCustomClipObject.end = endTime;

    trackCreateCustomClipEvent(currentProject, newCustomClipObject);
    try {
      const { data }: any = await fetchSubtitles({
        chars_per_caption: 25,
        project_id: currentProject.id,
        start_ts: startTime,
        end_ts: endTime,
        clip: true,
      });
      newCustomClipObject.srt_string = data.srt_string || "";
    } catch {
      // could not fetch subtitles
      setLoadingClip(false);
    }

    try {
      const { data }: any = await fetchThumbnail(
        currentProject.id,
        parsedProjectData.remote_url,
        startTime
      );

      newCustomClipObject.thumbnail_src = data.url;
    } catch {
      // could not fetch thumbnail
      showNotification(
        "Error while generating thumbnail for your clip. Please try again",
        "error"
      );
      setLoadingClip(false);
      return;
    }

    // chapter start should be 10 sec before start time and chapter end should be 10 sec after end time
    // make sure chapter start and end are not less than 0 or greater than video duration
    newCustomClipObject.chapter_start =
      startTime - CHAPTER_START_END_OFFSET < 0
        ? 0
        : startTime - CHAPTER_START_END_OFFSET;
    newCustomClipObject.chapter_end =
      endTime + CHAPTER_START_END_OFFSET > maxClipEnd
        ? maxClipEnd
        : endTime + CHAPTER_START_END_OFFSET;

    try {
      await createMicroContent(newCustomClipObject);
      // dispatch(updateCurrentSelectedMicroContent(newCustomClipObject));
      dispatch(changeSelectedLayout(layout));
      showNotification("Clip Generated", notificationType.SUCCESS);
      queryClient.invalidateQueries({ queryKey: ["micro-content-data"] });
    } catch (error: any) {
      if (
        error.response.status === 400 &&
        error.response.data.detail === "Clip already exists"
      ) {
        showNotification("Clip already exists", notificationType.FAIL);
      } else {
        showNotification(
          "Something went wrong, please try again",
          notificationType.FAIL
        );
      }
    }

    setLoadingClip(false);
    onCancel();
    return;
  };

  const timeFieldClassName = "w-full py-2";

  return (
    <BaseModal
      isModalOpen={isOpen}
      handelModalOpen={() => {}}
      notClosable
    >
      <div className="sm:w-[30rem]">
        <div className="flex flex-col justify-center bg-white px-11 pt-7 pb-8 text-center">
          <div className="text-2xl font-bold text-gray-900">
            Create Custom Clip
          </div>

          <div className="flex flex-col gap-6 mt-4 mb-9 select-none items-center ">
            <div className="w-full">
              <div className="mb-2 block text-left">
                <Label
                  htmlFor="clipTitle"
                  value="Clip Title"
                />
              </div>
              <TextInput
                id="clipTitle"
                value={inputTitle}
                onChange={(e) => setInputTitle(e.target.value)}
              />
            </div>

            <div className="flex w-full gap-2">
              <div className="flex-1 font-normal text-black">
                <div className="mb-2 block text-left">
                  <Label
                    htmlFor="clipTitle"
                    value="Start Time"
                  />
                </div>
                <TimeField
                  time={formatSeconds(startTime / 1000)}
                  handleTimeChange={handleTimeChange("start")}
                  className={timeFieldClassName}
                />
              </div>
              <div className="flex-1 font-normal text-black">
                <div className="mb-2 block text-left">
                  <Label
                    htmlFor="clipTitle"
                    value="End Time"
                  />
                </div>
                <TimeField
                  time={formatSeconds(endTime / 1000)}
                  handleTimeChange={handleTimeChange("end")}
                  className={timeFieldClassName}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-center gap-10">
            <button
              onClick={onCancel}
              type="button"
              id="create-custom-cancel-btn"
              className="inline-flex items-center justify-center 
              rounded-lg border border-gray-500 
              bg-white px-5 py-2.5 text-center text-sm font-medium 
              text-gray-800 hover:bg-gray-50 focus:outline-none  focus:ring-4 focus:ring-gray-200 
              "
            >
              Cancel
            </button>
            <button
              type="button"
              id={CREATE_CUSTOM_MODAL_CONTINUE_BTN.ID}
              className={`flex items-center justify-center 
              rounded-lg bg-blue-600 px-5 py-2.5 
              text-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 
              focus:ring-blue-300`}
              onClick={onConfirm}
            >
              {loadingClip && <Spinner className="h-4 w-4" />}
              Confirm
            </button>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default CustomClipModal;
