export const notificationType = {
  SUCCESS: "success",
  FAIL: "ERROR",
  WARN: "warning",
  INFO: "information",
};
export interface IconPropType {
  fillColor?: string;
  width?: string;
  height?: string;
  className?: string;
  hoverFillColor?: string;
}

export const COUNTRY_CURRENCY_BY_LOCATION: { [key: string]: string } = {
  US: "USD",
  GB: "GBP",
  DE: "EUR",
  FR: "EUR",
  IT: "EUR",
  ES: "EUR",
  NL: "EUR",
  BE: "EUR",
  IE: "EUR",
  PT: "EUR",
  GR: "EUR",
  CY: "EUR",
  MT: "EUR",
  SK: "EUR",
  SI: "EUR",
  AT: "EUR",
  FI: "EUR",
  EE: "EUR",
  LV: "EUR",
  LT: "EUR",
  LU: "EUR",
  RU: "RUB",
  AU: "AUD",
  BR: "BRL",
  CA: "CAD",
  CH: "CHF",
  CN: "CNY",
  PL: "PLN",
  SE: "SEK",
  ZA: "ZAR",
  CZ: "CZK",
  HU: "HUF",
  DK: "DKK",
  NZ: "NZD",
  SG: "SGD",
  HK: "HKD",
  IN: "INR",
  JP: "JPY",
  KR: "KRW",
  MX: "MXN",
  AR: "ARS",
  TW: "TWD",
  NO: "NOK",
  TH: "THB",
  TR: "TRY",
  IL: "ILS",
  UA: "UAH",
};
