import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useBoolean, useUpdateEffect } from "react-use";

import {
  ArrowPathIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  PencilIcon,
} from "@heroicons/react/24/solid";
import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { fabric } from "fabric";
import { Spinner } from "flowbite-react";
import { nanoid } from "nanoid";
import parseSRT from "parse-srt";

import { setEditorAnalytics } from "@/store/amplitudeSlice";
import { updateSelectedDraft } from "@/store/draftSlice";
import {
  setSocialMediaHandels,
  setSubtitles,
  toggleSceneChange,
  updateAllSceneChanges,
  updateAutoAddEmojisToSubtitles,
} from "@/store/editorSlice";
import {
  setCurrentSelectedClip,
  updateCurrentMicroContentRating,
  updateCurrentSelectedMicroContent,
  updateShowFullLengthVideo,
  updateShowPreviewClipsSideModal,
} from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { updateMicroContentData } from "@/api/requests";
import useGetDefaultBaseTemplates from "@/api/useGetDefaultBaseTemplates";
import useGetUserTemplates from "@/api/useGetUserTemplates";

import {
  TIME_TO_START_POLLING_BEFORE_CLIP_END,
  INTELLI_CLIP_TAG,
} from "@/constants";
import { BUTTON_IDS } from "@/constants/segment-analytics";

import {
  calculateScaleUpSizeFromBaseTemplate,
  centerFaceInClipPath,
  getClipPath,
  getCurrentSelectedTemplate,
  getFabricVideo,
  getNewFabricStaticCanvas,
  getProgressBar,
  getTemplatesForLayout,
  getTextbox,
  getTransformedTextboxStyles,
  hasMoreThanKeys,
  loadFont,
  updateGroupedText,
  updateStylesWithNewProperties,
  GroupedTextProps,
  addSubtitleStyles,
  addStyleToTextObj,
  textTransform,
  syncAudioAssetsWithMainVideo,
  handleAddAudioClipsToCanvas,
  changeStageUrlToProd,
  handleAddOutroAsset,
} from "@/helpers";

import { trackEndAndDownloadEvent } from "@/utils/amplitudeAnalytcs";
import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

import { TextboxStyles } from "@/interfaces";

import {
  ImageType,
  PreviewScreenClipTypes,
  ScreenName,
  SimpleAssetType,
  TextAlignment,
  TextElementType,
  VideoLayout,
} from "@/enums";

import VideoJS from "@/components/VideoJs";

import {
  DEFAULT_OUTRO_LENGTH_IN_SEC,
  INIT_SUBS_CONFIG,
  INIT_TEXT_STYLES,
  MIN_LINE_HEIGHT,
  MIN_THRESHOLD_BETWEEN_SUBTITLES,
  TEXT_TRANSFORM,
} from "@/views/editor/constant";

import { FullVideo } from "../FullVideo";
import PlaybackRateController from "./PlaybackRateController";

import EditorPauseButton from "@/assets/icons/editor-pause-button.svg";
import EditorPlayButton from "@/assets/icons/editor-play-button.svg";

const RATE_UP = "Rate-up";
const RATE_DOWN = "Rate_down";
const BACKGROUND_CLIP_PATH = "backGroundClipPath";
const OUTRO_ASSET = "outro-asset";

const { MAKE_CHANGES_TO_CLIP_BTN_NEW_UI } = BUTTON_IDS.TEMPLATE_PREVIEW_NEW;

const loadingScreenTextArray = [
  "Finalizing pixel perfection",
  "Adding fancy captions",
  "Removing dead pixels",
  "Normalizing audio",
  "Adding nice templates",
  "Making short videos look good",
  "We're working on this, hold on",
  "Saving you hours of time",
  "Saving you $$$ in edits",
];

const Loader = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % loadingScreenTextArray.length);
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="flex items-center justify-center h-[102%] -mt-1 bg-gray-200 w-[102%] rounded-lg flex-col -ml-1">
      <p className="text-gray-700 font-bold text-[15px]">
        {loadingScreenTextArray[index]}
      </p>
      <Spinner className="mt-2" />
    </div>
  );
};

const TemplatesPreview = forwardRef(
  ({ clipDeleteLoading, setOutroTimeLeft, outroTimeLeft }: any, ref: any) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const [textsObj, setTextsObj] = useState<any>({});

    const currentSelectedMicroContent = useAppSelector(
      (state) => state.homeState.currentSelectedMicroContent
    );

    const currentSelectedProject = useAppSelector(
      (state) => state.homeState.currentSelectedProject
    );

    const selectedLayout = useAppSelector(
      (state) => state.editorState.selectedLayout
    );

    const userSocialMediaHandles = useAppSelector(
      (state) => state.homeState.userSocialMediaHandles
    );
    const showFullVideo = useAppSelector(
      (state) => state.homeState.showFullVideo
    );

    const showPreviewClipsSideModal = useAppSelector(
      (state) => state.homeState.showPreviewClipsSideModal
    );

    const { uid } = useAppSelector((state) => state.authState.currentUser);

    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [subsArr, setSubsArr] = useState<any>([]);
    const [currentOutro, setCurrentOutro] = useState<any>(null);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const [isVideoFinished, setIsVideoFinished] = useState<boolean>(false);
    const [playbackRate, setPlaybackRate] = useState(1);

    const [isVideoSeeked, setIsVideoSeeked] = useBoolean(false);
    const [audioElements, setAudioElements] = useState<any>({});

    const [subsConfig, setSubsConfig] = useState<any>(INIT_SUBS_CONFIG);
    const { fabricRef, canvasObjects, outroVideoRef } = ref.current;
    const progressBarRef = useRef<any>(null);
    const textFabricObj = useRef<any>({});
    const textsObjRef = useRef<any>(textsObj);
    const videoElRef = useRef<any>();
    const subsArrRef = useRef<any>();
    const subtitleTextRef = useRef<any>(null);
    const logoRef = useRef<any>({});
    const activeSubtitleIndexRef = useRef<number>(0);

    const [isPollingCurrentTime, toggleIsPollingCurrentTime] =
      useBoolean(false);

    const editorDimensions: any = {
      [VideoLayout.LAYOUT_16_9]: {
        width: 426.55,
        height: 240,
      },
      [VideoLayout.LAYOUT_1_1]: {
        width: 350,
        height: 350,
      },
      [VideoLayout.LAYOUT_9_16_1]: {
        width: 240,
        height: 426.55,
      },
      [VideoLayout.LAYOUT_9_16_2]: {
        width: 240,
        height: 426.55,
      },
    };

    const updateVideoAnimation = useRef<any>();

    const throttleUpdate = () => {
      if (updateVideoAnimation.current) {
        updateVideoAnimation.current = () => {
          if (videoElRef.current && videoElRef.current.currentTime) {
            videoElRef.current?.paused || onTimeUpdate();
          }
        };

        requestAnimationFrame(updateVideoAnimation.current);
      }
    };

    const updatePlaybackRate = useCallback((playbackRate: number) => {
      setPlaybackRate(playbackRate);
    }, []);

    useEffect(() => {
      throttleUpdate();
      return () => {
        updateVideoAnimation.current = null;

        // removing all the event listeners from animation frame
        cancelAnimationFrame(updateVideoAnimation.current);
      };
    }, [isPollingCurrentTime]);

    useEffect(() => {
      if (!outroTimeLeft) {
        fabricRef.current.remove(canvasObjects.current.outroImg);
        setIsVideoFinished(true);
        return;
      }

      const intervalId = setInterval(() => {
        setOutroTimeLeft(outroTimeLeft - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }, [outroTimeLeft]);

    const updateProgressBarAnimation = () => {
      if (
        videoElRef.current.currentTime * 1000 <=
        currentSelectedMicroContent?.end
      ) {
        handelProgressBarAnimation(videoElRef.current.currentTime ?? 0);
      }
    };

    const userTemplatesData = useGetUserTemplates(uid);
    const defaultBaseTemplates = useGetDefaultBaseTemplates();

    const EDITOR_WIDTH: number =
      selectedLayout && editorDimensions[selectedLayout].width;
    const EDITOR_HEIGHT: number =
      selectedLayout && editorDimensions[selectedLayout].height;

    const getVideoUrl = useMemo(() => {
      if (
        currentSelectedProject &&
        currentSelectedProject.data &&
        !userTemplatesData.isLoading &&
        !defaultBaseTemplates.isLoading
      ) {
        if (
          currentSelectedMicroContent?.tag === INTELLI_CLIP_TAG &&
          currentSelectedMicroContent?.clip_src
        ) {
          return changeStageUrlToProd(currentSelectedMicroContent?.clip_src);
        }
        return (
          JSON.parse(currentSelectedProject.data).remote_url +
          `#t=${currentSelectedMicroContent?.start / 1000}` +
          `?v=` +
          new Date().getTime()
        );
      }
      return "";
    }, [
      currentSelectedMicroContent?.start,
      currentSelectedMicroContent?.clip_src,
    ]);

    function handelProgressBarAnimation(
      timeStamp: number,
      isFullWidth?: boolean
    ) {
      const totalVideoLength =
        currentSelectedMicroContent?.end / 1000 -
          currentSelectedMicroContent?.start / 1000 ?? 0;
      const pxPerSec = progressBarRef?.current?.maxWidth / totalVideoLength;

      const val = progressBarRef?.current;
      if (val) {
        if (isFullWidth) {
          val.set("width", progressBarRef?.current?.maxWidth);
        } else {
          val.set(
            "width",
            pxPerSec * (timeStamp - currentSelectedMicroContent?.start / 1000)
          );
        }
        val.set("left", 0);
        val.set("dirty", true);
        progressBarRef.current = val;
        progressBarRef.current.setCoords();
      }
    }

    const handlePlayPause = () => {
      if (outroTimeLeft === 0) {
        if (videoElRef.current.paused && !isVideoPlaying) {
          videoElRef.current
            .play()
            .then(() => {
              setIsVideoPlaying(!isVideoPlaying);
            })
            .catch((error: any) => {
              console.log("handlePlayPause error", error);
            });
        }
        if (!videoElRef.current.paused && isVideoPlaying) {
          toggleIsPollingCurrentTime(false);
          setIsVideoPlaying(false);
          videoElRef.current.pause();
        }
      }
    };

    const handelAddSocialAssets = ({
      url,
      position,
      uid,
      logoWidth,
    }: {
      url: string;
      position: any;
      uid: string;
      logoWidth?: number;
    }) => {
      fabric.Image.fromURL(url, (img: any) => {
        if (fabricRef.current) {
          img.scaleToWidth(logoWidth ? logoWidth : 20).set({
            left: position.left,
            top: position.top,
            id: uid,
          });

          fabricRef.current.add(img);
          logoRef.current[uid] = {
            asset: img,
            url,
            uid,
          };
        }
      });
    };

    const addSocialTemplatesToCanvas = ({
      logoDetails,
      textDetails,
    }: {
      logoDetails: any;
      textDetails: any;
    }) => {
      handelAddSocialAssets({
        url: logoDetails.url,
        position: { top: logoDetails.top, left: logoDetails.left },
        uid: logoDetails.id,
        logoWidth: logoDetails.width,
      });
      if (textDetails) {
        addTextObject({
          initPosition: { top: textDetails.top, left: textDetails.left },
          ...textDetails,
          Id: `${logoDetails.id}_text`,
          isSocialText: true,
        });
      }
    };

    const setSubsInTemplates = (
      currentTemplate: any,
      templateScaleUpRatio: number
    ) => {
      const jsonSubs = parseSRT(currentSelectedMicroContent?.srt_string);
      let subs = jsonSubs.map((sub: any) => {
        return {
          ...sub,
          text: sub.text,
        };
      });
      setSubsArr(subs);
      const subtitleStyle = currentTemplate.subtitle.style
        ? currentTemplate.subtitle.style
        : INIT_SUBS_CONFIG.style;
      setSubsConfig({
        ...subsConfig,
        style: subtitleStyle,
        top: currentTemplate.subtitle.top * templateScaleUpRatio,
        left: currentTemplate.subtitle.left * templateScaleUpRatio,
        width: currentTemplate.subtitle.width * templateScaleUpRatio,
        font_size: currentTemplate.subtitle.fontSize * templateScaleUpRatio,
        font_color: currentTemplate.subtitle.fontColor,
        font_face: currentTemplate.subtitle.fontFamily,
        underline: currentTemplate.subtitle.underline,
        alignment: currentTemplate.subtitle.textAlign,
        effect_type: currentTemplate.subtitle.effect_type,
        effect_color: currentTemplate.subtitle.effect_color,
        bold: currentTemplate.subtitle.bold,
        italic: currentTemplate.subtitle.italic,
        maxHeight: currentTemplate.subtitle.maxHeight * templateScaleUpRatio,
        line_height: currentTemplate.subtitle.lineHeight || MIN_LINE_HEIGHT,
        noEffect: currentTemplate.subtitle.noEffect,
        shadow: currentTemplate.subtitle.shadow,
        stroke: currentTemplate.subtitle.stroke,
        textBgColor: currentTemplate.subtitle.textBgColor,
        blockBackground: currentTemplate.subtitle.blockBackground,
        textTransform: currentTemplate.subtitle?.textTransform,
        padding: currentTemplate.subtitle?.padding || INIT_SUBS_CONFIG.padding,
        margin: currentTemplate.subtitle?.margin || INIT_TEXT_STYLES.margin,
      });
    };

    const addProgressbarInLayout = ({
      currentTemplate,
      templateScaleUpRatio,
    }: any) => {
      if (currentTemplate.progressbar) {
        const progressBarRect = getProgressBar({
          height: currentTemplate.progressbar.height,
          top: currentTemplate.progressbar.top,
          left: currentTemplate.progressbar.left,
          maxWidth: currentTemplate.width,
          fill: currentTemplate.progressbar.fill,
          scaleRatio: templateScaleUpRatio,
        });
        progressBarRef.current = progressBarRect;
        fabricRef.current?.add(progressBarRect);
        fabricRef.current?.bringToFront(progressBarRect);
      }
    };

    const reorganizeCanvasObjects = () => {
      if (fabricRef.current) {
        if (subtitleTextRef.current) {
          fabricRef.current.bringToFront(subtitleTextRef.current);
        }
        Object.values(textFabricObj.current).forEach((obj) => {
          fabricRef.current.bringToFront(obj);
        });
        Object.values(logoRef.current).forEach((obj: any) => {
          fabricRef.current.bringToFront(obj.asset);
        });

        fabricRef.current?.requestRenderAll();
      }
    };

    const handelAddUserUploadedImages = ({
      url,
      position,
      uid,
      logoWidth,
      metaData,
    }: {
      url: string;
      position: any;
      uid: string;
      logoWidth?: number;
      metaData?: any;
    }) => {
      fabric.Image.fromURL(url, (img: any) => {
        img.scaleToWidth(logoWidth ? logoWidth : 100).set({
          left: position.left,
          top: position.top,
          id: uid,
          type: ImageType.USER_UPLOADED,
        });

        fabricRef.current?.add(img);

        if (metaData?.isTemplate) {
          reorganizeCanvasObjects();
        }

        logoRef.current[uid] = {
          asset: img,
          url,
          uid,
        };
      });
    };

    const addTemplateTextAndSocialsInLayout = ({
      currentTemplate,
      backGroundClipPath,
      templateScaleUpRatio,
    }: any) => {
      if (currentTemplate.socials.length) {
        currentTemplate.socials.forEach((social: any) => {
          // updating the social content with user preference
          userSocialMediaHandles.forEach((socialHandel: any) => {
            if (socialHandel.id === social.image.id) {
              let updatedSocial = { ...social };
              updatedSocial = {
                ...updatedSocial,
                text: {
                  ...updatedSocial.text,
                  content:
                    socialHandel.socialMediaHandle || social.text.content,
                },
              };
              social = updatedSocial;
            }
          });

          const socialLogoDetails = {
            top:
              social.image.top * templateScaleUpRatio + backGroundClipPath.top,
            left:
              social.image.left * templateScaleUpRatio +
              backGroundClipPath.left,
            id: social.image.id,
            url: social.image.url,
            width: social.image.width * templateScaleUpRatio,
          };
          let socialTextDetails: any = undefined;

          if (social.text) {
            socialTextDetails = {
              top:
                social.text.top * templateScaleUpRatio + backGroundClipPath.top,
              left:
                social.text.left * templateScaleUpRatio +
                backGroundClipPath.left,
              textContent: textTransform({
                text: social.text.content,
                transformStyle: social?.text?.textTransform,
                capitalizeFirstWord: true,
              }),
              textSize: social.text.fontSize * templateScaleUpRatio,
              fontFace: social.text.fontFamily,
              effectType: social.text.effect_type,
              Id: `${social.image.id}_text`,
              textAlignment: social.text.textAlign,
              textBold: social.text.bold,
              textBoxWidth: social.text.width
                ? social.text.width * templateScaleUpRatio
                : backGroundClipPath.width -
                  social.text.left * templateScaleUpRatio,
              lineHeight: social.text.lineHeight || MIN_LINE_HEIGHT,
              textTransform: social.text?.textTransform,
              margin: social.text?.margin || INIT_TEXT_STYLES.margin,
              padding: social.text?.padding || INIT_TEXT_STYLES.padding,
            };
          }

          addSocialTemplatesToCanvas({
            logoDetails: socialLogoDetails,
            textDetails: socialTextDetails,
          });
        });
      }

      currentTemplate.texts.forEach((text: any) => {
        addTextObject({
          initPosition: {
            left: text.left * templateScaleUpRatio,
            top: text.top * templateScaleUpRatio,
          },
          textSize: text.fontSize * templateScaleUpRatio,
          textAlign: text.textAlign,
          fontFace: text.fontFamily,
          textContent: textTransform({
            text: text.content || text.text,
            transformStyle: text?.textTransform,
            capitalizeFirstWord: true,
          }),
          textBoxWidth: text.width
            ? text.width * templateScaleUpRatio
            : backGroundClipPath.width,
          effectColor: text.effect_color,
          effectType: text.effect_type,
          textBold: text.bold,
          textItalic: text.italic,
          textUnderLine: text.underline,
          fontColor: text.fill,
          maxHeight: text.maxHeight * templateScaleUpRatio,
          lineHeight: text.lineHeight || MIN_LINE_HEIGHT,
          noEffect: text.noEffect,
          shadow: text.shadow,
          stroke: text.stroke,
          textBgColor: text.textBgColor,
          blockBackground: text.blockBackground,
          hasNoEffectKey: text.hasOwnProperty("noEffect"),
          textTransform: text?.textTransform,
          margin: text?.margin || INIT_TEXT_STYLES.margin,
          padding: text?.padding || INIT_TEXT_STYLES.padding,
        });
      });
    };

    const handelAddUserUploadedImagesToCanvas = ({
      currentTemplate,
      backGroundClipPath,
      templateScaleUpRatio,
    }: any) => {
      currentTemplate?.bRolls?.forEach((image: any) => {
        const userUploadedImages = {
          top: image.top * templateScaleUpRatio + backGroundClipPath.top,
          left: image.left * templateScaleUpRatio + backGroundClipPath.left,
          id: image.id,
          url: image.url,
          width: image.width * templateScaleUpRatio,
        };

        handelAddUserUploadedImages({
          url: userUploadedImages.url,
          position: {
            top: userUploadedImages.top,
            left: userUploadedImages.left,
          },
          uid: userUploadedImages.id,
          logoWidth: userUploadedImages.width,
          metaData: { isTemplate: true },
        });
      });

      currentTemplate?.logos?.forEach((image: any) => {
        const userUploadedImages = {
          top: image.top * templateScaleUpRatio + backGroundClipPath.top,
          left: image.left * templateScaleUpRatio + backGroundClipPath.left,
          id: image.id,
          url: image.url,
          width: image.width * templateScaleUpRatio,
        };

        handelAddUserUploadedImages({
          url: userUploadedImages.url,
          position: {
            top: userUploadedImages.top,
            left: userUploadedImages.left,
          },
          uid: userUploadedImages.id,
          logoWidth: userUploadedImages.width,
        });
      });

      // for older templates
      currentTemplate?.images?.forEach((image: any) => {
        const userUploadedImages = {
          top: image.top * templateScaleUpRatio + backGroundClipPath.top,
          left: image.left * templateScaleUpRatio + backGroundClipPath.left,
          id: image.id,
          url: image.url,
          width: image.width * templateScaleUpRatio,
        };

        handelAddUserUploadedImages({
          url: userUploadedImages.url,
          position: {
            top: userUploadedImages.top,
            left: userUploadedImages.left,
          },
          uid: userUploadedImages.id,
          logoWidth: userUploadedImages.width,
        });
      });
    };

    const initCanvas = () => {
      setIsVideoLoaded(false);
      setIsVideoFinished(false);
      setIsVideoSeeked(false);
      if (videoElRef.current) {
        if (!currentSelectedMicroContent) {
          // switch to full video mode
          dispatch(setCurrentSelectedClip(PreviewScreenClipTypes.FULL_VIDEO));
          showPreviewClipsSideModal &&
            dispatch(updateShowPreviewClipsSideModal(false));
          dispatch(updateShowFullLengthVideo(true));
          return;
        }

        const { videoWidth, videoHeight } = videoElRef.current;
        videoElRef.current.width = videoWidth;
        videoElRef.current.height = videoHeight;
        videoElRef.current.currentTime = currentSelectedMicroContent?.start
          ? currentSelectedMicroContent?.start / 1000
          : 0;
        videoElRef.current.muted = false;
        videoElRef.current.pause();

        initLayout(selectedLayout);

        fabric.util.requestAnimFrame(function render() {
          fabricRef.current?.requestRenderAll();
          fabric.util.requestAnimFrame(render);
        });
      }
    };

    const handelAddBackgroundImageToEditor = (
      currentTemplate: any,
      addVideoProps: any,
      backGroundClipPath?: any
    ) => {
      const img = document.createElement("img");
      img.src = currentTemplate.backgroundImageUrl;

      img.onload = function () {
        const bgImg = new fabric.Image(img, {
          left: 0,
          top: 0,
          clipPath: backGroundClipPath,
          selectable: false,
        });

        // bgImg.scaleToHeight(backGroundClipPath.height);
        bgImg.scaleY = EDITOR_HEIGHT / img.height;
        bgImg.scaleX = EDITOR_WIDTH / img.width;
        bgImg.dirty = true;

        if (fabricRef.current) {
          fabricRef.current.bringToFront(bgImg);
          fabricRef.current.renderAll();
        }

        if (addVideoProps) {
          addVideoProps();
        }
      };
    };

    const addTemplateElementToCanvas = ({
      currentTemplate,
      templateScaleUpRatio,
      backGroundClipPath,
    }: {
      currentTemplate: any;
      templateScaleUpRatio: number;
      backGroundClipPath: any;
    }) => {
      addProgressbarInLayout({
        currentTemplate,
        templateScaleUpRatio,
      });

      if (hasMoreThanKeys(currentTemplate.subtitle, 2)) {
        setSubsInTemplates(currentTemplate, templateScaleUpRatio);
      } else {
        setSubsArr([]);
      }
      addTemplateTextAndSocialsInLayout({
        currentTemplate,
        templateScaleUpRatio,
        backGroundClipPath,
      });

      handelAddUserUploadedImagesToCanvas({
        currentTemplate,
        templateScaleUpRatio,
        backGroundClipPath,
      });

      if (currentTemplate?.outro) {
        const commonOutroProps = {
          left: backGroundClipPath.left,
          top: backGroundClipPath.top,
          clipPath: backGroundClipPath,
          id: OUTRO_ASSET,
          selectable: false,
          hasControls: false,
        };

        handleAddOutroAsset({
          outro: currentTemplate?.outro,
          backGroundClipPath,
          commonProps: commonOutroProps,
          fabricObj: fabric,
          canvasObjects,
          outroVideoRef,
        });
      }

      if (currentTemplate?.audioClips?.length) {
        handleAddAudioClipsToCanvas(
          currentTemplate,
          setAudioElements,
          currentSelectedMicroContent
        );
      }

      setIsVideoLoaded(true);
    };

    const getTemplateDetails = (layout: VideoLayout) => {
      const userSavedTemplates = getTemplatesForLayout(
        layout,
        userTemplatesData?.data
      );

      const currentTemplate = getCurrentSelectedTemplate(
        layout,
        currentSelectedMicroContent,
        userSavedTemplates,
        defaultBaseTemplates?.data
      );
      setCurrentOutro(currentTemplate?.outro);

      const templateScaleUpRatio = calculateScaleUpSizeFromBaseTemplate(
        currentTemplate.height,
        EDITOR_HEIGHT
      );
      const backGroundClipPath = getClipPath({
        width: EDITOR_WIDTH,
        height: EDITOR_HEIGHT,
        backgroundColor: currentTemplate?.backgroundColor,
        id: BACKGROUND_CLIP_PATH,
      });

      return { currentTemplate, templateScaleUpRatio, backGroundClipPath };
    };

    const initLayout = (layout: VideoLayout) => {
      clearLayout();

      const { currentTemplate, templateScaleUpRatio, backGroundClipPath } =
        getTemplateDetails(layout);

      fabricRef.current.add(backGroundClipPath);

      const setVideoProps = () => {
        const videoProperties = currentTemplate.videoProperties;

        videoProperties.forEach((videoProperty, index) => {
          const clipPathProps = videoProperty.clipPath;
          const clipPath = getClipPath({
            width: clipPathProps.width,
            height: clipPathProps.height,
            top: clipPathProps.top,
            left: clipPathProps.left,
            scaleRatio: templateScaleUpRatio,
          });

          const videoEl = getFabricVideo({
            top: clipPath.top,
            left: clipPath.left,
            videoElement: videoElRef.current,
            clipPath,
            hasControls: false,
          });

          centerFaceInClipPath(
            videoEl,
            clipPath,
            currentSelectedMicroContent.face_coord,
            selectedLayout,
            index === 1
          );

          fabricRef.current?.bringToFront(videoEl);
        });

        addTemplateElementToCanvas({
          currentTemplate,
          templateScaleUpRatio,
          backGroundClipPath,
        });

        reorganizeCanvasObjects();
        handelProgressBarAnimation(currentSelectedMicroContent?.start / 1000);
      };

      if (currentTemplate.backgroundImageUrl) {
        handelAddBackgroundImageToEditor(
          currentTemplate,
          setVideoProps,
          backGroundClipPath
        );
      } else {
        setVideoProps();
      }
    };

    const canvasRef: any = useCallback((element: any) => {
      if (!element) {
        fabricRef.current?.dispose();
        fabricRef.current = null;
        return fabricRef;
      }

      fabricRef.current = getNewFabricStaticCanvas(element);
      fabricRef.current.renderOnAddRemove = true;
      return fabricRef;
    }, []);

    const clearLayout = () => {
      setIsVideoPlaying(false);
      //get all fabric objects and delete
      const objects = fabricRef.current?.getObjects();
      objects.forEach((object: any) => {
        fabricRef.current.remove(object);
      });

      canvasRef?.current?.clear();
      setTextsObj({});
      textFabricObj.current = {};
      setCurrentOutro(null);
      dispatch(setSocialMediaHandels([]));
      canvasObjects.current = {};
      subtitleTextRef.current = null;
      progressBarRef.current = null;
      textsObjRef.current = {};
      logoRef.current = {};
      setAudioElements({});

      dispatch(setSocialMediaHandels([]));
    };

    // start polling for current time for accuracy ending of end
    const startPollingForCurrentTime = () => {
      if (
        !clipDeleteLoading &&
        currentSelectedMicroContent.end -
          videoElRef.current.currentTime * 1000 <
          TIME_TO_START_POLLING_BEFORE_CLIP_END
      ) {
        videoElRef.current.paused || toggleIsPollingCurrentTime(true);
      } else {
        toggleIsPollingCurrentTime(false);
      }
    };

    const onTimeUpdate = () => {
      renderSubsOnCanvas();
      updateProgressBarAnimation();
      syncAudioAssetsWithMainVideo(videoElRef.current, audioElements);
      if (
        currentSelectedMicroContent &&
        !clipDeleteLoading &&
        videoElRef.current.currentTime >= currentSelectedMicroContent.end / 1000
      ) {
        if (!currentOutro) setIsVideoFinished(true);
        if (!isVideoFinished) markClipSeen();
        // videoElRef.current.paused || handlePlayPause();
        videoElRef.current.currentTime =
          currentSelectedMicroContent.start / 1000 || 0;

        toggleIsPollingCurrentTime(false);
        setIsVideoPlaying(false);
        videoElRef.current.pause();

        if (currentOutro) {
          setOutroTimeLeft(
            currentOutro.duration || DEFAULT_OUTRO_LENGTH_IN_SEC
          );
          fabricRef.current.bringToFront(canvasObjects.current.outroImg);
          fabricRef.current.requestRenderAll();

          if (currentOutro.type.includes(SimpleAssetType.VIDEO)) {
            canvasObjects.current.outroImg.getElement().play().then().catch();
          }
        }
      }
    };

    const userRating = async (value: string) => {
      let microContentUpdatedObject = {
        project_id: currentSelectedProject?.id,
        start: currentSelectedMicroContent.start,
        end: currentSelectedMicroContent.end,
        type: selectedLayout === VideoLayout.LAYOUT_16_9 ? "chapter" : "short",
        rating: false,
      };

      if (value === RATE_UP) {
        microContentUpdatedObject.rating = true;
      } else {
        microContentUpdatedObject.rating = false;
      }
      try {
        await updateMicroContentData(microContentUpdatedObject);
        dispatch(updateCurrentMicroContentRating(true));
        queryClient.invalidateQueries({ queryKey: ["micro-content-data"] });
        showNotification(
          "Thank you for your feedback ",
          notificationType.SUCCESS
        );
      } catch (error) {
        // display nothing to user if there is an error
        // user can still rate the video
      }
    };

    useEffect(() => {
      if (subtitleTextRef.current) {
        const subsStyles = getTransformedTextboxStyles(subsConfig);
        updateGroupedText(subtitleTextRef.current, subsStyles);
      }
    }, [subsConfig]);

    const setSubs = (textObj: any) => {
      if (fabricRef.current) {
        let fabricEl = subtitleTextRef.current;
        const textStyles = getTransformedTextboxStyles(subsConfig);
        const fabricTextProps = {
          left: subsConfig.left,
          top: subsConfig.top,
          width: subsConfig.width || EDITOR_WIDTH - subsConfig.left,
          maxHeight: subsConfig.maxHeight,
          type: TextElementType.SUBTITLE,
          style: textStyles,
          text: textObj.text,
        };

        if (textObj.text) {
          if (!fabricEl) {
            fabricEl = getTextbox(fabricTextProps);
            subtitleTextRef.current = fabricEl;

            loadFont(
              fabricEl,
              fabricTextProps.style as GroupedTextProps,
              () => {
                fabricRef.current?.add(fabricEl);
                fabricRef.current?.requestRenderAll();
                addSubtitleStyles(
                  textObj,
                  subsConfig,
                  fabricEl,
                  currentSelectedMicroContent,
                  videoElRef.current.currentTime
                );
              }
            );

            fabricRef.current.add(fabricEl);
          } else {
            updateGroupedText(fabricEl, {
              ...fabricTextProps.style,
              text: textObj.text,
            }).then(() => {
              fabricRef.current.requestRenderAll();
            });
          }
          addSubtitleStyles(
            textObj,
            subsConfig,
            fabricEl,
            currentSelectedMicroContent,
            videoElRef.current.currentTime
          );
        }
        if (fabricEl && !textObj.text) {
          updateGroupedText(fabricEl, {
            ...fabricTextProps.style,
            text: "",
          }).then(() => {
            fabricRef.current.requestRenderAll();
          });
        }
      }
    };

    const addTextToFabric = (text: any) => {
      const textStyles = text.style;
      const transformedStyleObj: TextboxStyles =
        getTransformedTextboxStyles(textStyles);
      const textboxProps = {
        id: text.id,
        text:
          typeof text.content === "string" ? text.content : text.content.text,
        left: textStyles.coordinate_left,
        top: textStyles.coordinate_top,
        width: textStyles.width,
        maxHeight: textStyles.maxHeight,
        style: transformedStyleObj,
        type: text.isSocialText
          ? TextElementType.SOCIAL_HANDLE_TEXT
          : TextElementType.NORMAL_TEXT,
      };
      const fabricText = getTextbox(textboxProps);

      loadFont(
        fabricText,
        textboxProps.style as GroupedTextProps,
        () => {
          fabricRef.current?.add(fabricText);
          fabricRef.current?.requestRenderAll();
        },
        true
      );

      textFabricObj.current[text.id] = fabricText;
    };

    const addTextObject = ({
      initPosition,
      textContent,
      textSize = 32,
      textBoxWidth = 0,
      fontColor = "#ffffff",
      fontFace = "Open Sans",
      effectType = "none",
      Id,
      textBold = false,
      isEmpty = false,
      textAlignment = TextAlignment.CENTER,
      effectColor = "#000000",
      textUnderLine = false,
      textItalic = false,
      maxHeight,
      isSocialText = false,
      lineHeight = MIN_LINE_HEIGHT,
      noEffect = true,
      stroke,
      shadow,
      textBgColor,
      blockBackground,
      hasNoEffectKey = false,
      margin,
      padding,
    }: any) => {
      const uid = Id || nanoid();

      const {
        font_size,
        font_color,
        font_face,
        effect_type,
        effect_color,
        bold,
        italic,
        alignment,
        underline,
        line_height,
        ...textStylesModified
      } = INIT_TEXT_STYLES;

      let updatedTextProperties;

      if (hasNoEffectKey) {
        updatedTextProperties = {
          noEffect: noEffect,
          shadow: shadow,
          stroke: stroke,
          textBgColor: textBgColor,
          blockBackground: blockBackground,
        };
      } else {
        updatedTextProperties = updateStylesWithNewProperties({
          effect_type: effectType,
          effect_color: effectColor,
        });
      }

      const newText = {
        start: 0,
        end:
          (currentSelectedMicroContent.end -
            currentSelectedMicroContent.start) /
          1000,
        content: isEmpty ? "" : textContent || "Edit Me",
        id: uid,
        style: {
          font_size: textSize,
          font_color: fontColor,
          font_face: fontFace,
          effect_type: effectType,
          effect_color: effectColor,
          bold: textBold,
          italic: textItalic,
          underline: textUnderLine,
          alignment: textAlignment,
          line_height: lineHeight,
          ...textStylesModified,
          margin,
          padding,
          coordinate_left: initPosition.left,
          coordinate_top: initPosition.top,
          width: textBoxWidth,
          maxHeight,
          ...updatedTextProperties,
        },
        isSocialText,
      };
      setTextsObj((prevState: []) => ({
        ...prevState,
        [newText.id]: newText,
      }));
      textsObjRef.current = { ...textsObjRef.current, [newText.id]: newText };
      addTextToFabric(newText);
    };

    const handelMakeChanges = () => {
      dispatch(updateSelectedDraft(null));
      dispatch(updateAutoAddEmojisToSubtitles(false));
      dispatch(toggleSceneChange(false));
      dispatch(updateAllSceneChanges([]));
      dispatch(setEditorAnalytics(null));
      dispatch(
        setSubtitles({
          subsArr: [],
          subStart: 0,
        })
      );

      trackEndAndDownloadEvent(
        ScreenName.REVIEW_CLIPS,
        selectedLayout,
        currentSelectedProject,
        currentSelectedMicroContent
      );

      if (currentSelectedProject) {
        navigate(`/editor/${currentSelectedProject.id}/templates`);
      }
    };

    const markClipSeen = async () => {
      let microContentUpdatedObject = {
        project_id: currentSelectedProject?.id,
        start: currentSelectedMicroContent.start,
        end: currentSelectedMicroContent.end,
        type: selectedLayout === VideoLayout.LAYOUT_16_9 ? "chapter" : "short",
        seen: true,
      };
      try {
        await updateMicroContentData(microContentUpdatedObject);
        dispatch(
          updateCurrentSelectedMicroContent({
            ...currentSelectedMicroContent,
            seen: true,
          })
        );
        queryClient.invalidateQueries({ queryKey: ["micro-content-data"] });
      } catch (error) {
        // display nothing to user if there is an error
      }
    };

    const isSubPresentOnTheCurrentPlayerTime = (
      textObj: any,
      currentTime: number
    ) => {
      return (
        currentTime >=
          textObj.start + currentSelectedMicroContent?.start / 1000 &&
        currentTime <= textObj.end + currentSelectedMicroContent?.start / 1000
      );
    };

    useEffect(() => {
      subsArrRef.current = subsArr;
    }, [subsArr, textsObj]);

    useUpdateEffect(() => {
      if (typeof currentSelectedMicroContent?.start === "number") {
        subsArrRef.current = [];
        setSubsArr([]);
        initCanvas();
      }
    }, [
      currentSelectedMicroContent?.start,
      currentSelectedMicroContent?.id,
      selectedLayout,
    ]);

    const renderSubsOnCanvas = () => {
      const isSubPresentOnCurrentVideoTime = subsArr.some((textObj: any) =>
        isSubPresentOnTheCurrentPlayerTime(
          textObj,
          videoElRef.current.currentTime
        )
      );

      if (isSubPresentOnCurrentVideoTime) {
        subsArr.forEach((textObj: any) => {
          if (
            isSubPresentOnTheCurrentPlayerTime(
              textObj,
              videoElRef.current.currentTime
            )
          ) {
            setSubs({
              ...textObj,
              text:
                subsConfig.textTransform === TEXT_TRANSFORM.UPPERCASE
                  ? textTransform({
                      text: textObj.text,
                      transformStyle: TEXT_TRANSFORM.UPPERCASE,
                    })
                  : textObj.text,
            });
          }
        });
      } else {
        // if no subtitle is present on the current video time and there is sub present on the canvas
        // we do not want to remove it if the next sub is going to start within 100ms
        // if the next sub is going to start after 100ms then we will remove the sub from the canvas
        // we can use the curren sub index to find the next sub
        if (
          activeSubtitleIndexRef.current !== null ||
          activeSubtitleIndexRef.current !== undefined
        ) {
          const nextSubIndex = activeSubtitleIndexRef.current + 1;
          const nextSub = subsArr[nextSubIndex];
          if (nextSub) {
            const diff = nextSub.start - videoElRef.current.currentTime; // in seconds
            if (diff < MIN_THRESHOLD_BETWEEN_SUBTITLES) {
              return;
            }
          }
        }
        setSubs({});
      }
    };

    useEffect(() => {
      textsObjRef.current = textsObj;
    }, [JSON.stringify(textsObj)]);

    useEffect(() => {
      if (subtitleTextRef.current) {
        const subsStyles = getTransformedTextboxStyles(subsConfig);
        addStyleToTextObj(subtitleTextRef.current, subsStyles);
      }
    }, [JSON.stringify(subsConfig)]);

    return (
      <>
        {showFullVideo ? (
          <FullVideo />
        ) : (
          <div
            className={`h-full bg-white text-center flex items-center justify-center -mt-24 select-none ${
              showPreviewClipsSideModal ? "w-[65%]" : "w-full"
            }`}
          >
            <div className="relative">
              <div
                style={{
                  width: EDITOR_WIDTH,
                  height: EDITOR_HEIGHT,
                }}
                className="bg-transparent group"
              >
                <div
                  style={{
                    width: EDITOR_WIDTH,
                    height: EDITOR_HEIGHT,
                  }}
                  className="bg-transparent"
                >
                  {getVideoUrl && (
                    <>
                      <VideoJS
                        url={getVideoUrl}
                        onTimeUpdate={onTimeUpdate}
                        initCanvas={initCanvas}
                        videoElRef={videoElRef}
                        onEnded={() => {
                          setIsVideoPlaying(false);
                        }}
                        setIsVideoSeeked={setIsVideoSeeked}
                        poster={currentSelectedMicroContent?.imageUrl || ""}
                        playbackRate={playbackRate}
                      />
                      <canvas
                        width={EDITOR_WIDTH}
                        height={EDITOR_HEIGHT}
                        style={{
                          borderRadius: "10px",
                          boxShadow:
                            isVideoLoaded && isVideoSeeked
                              ? "0px 0px 4px 1px rgba(0, 0, 0, 0.5)"
                              : "none",
                        }}
                        ref={canvasRef}
                        onClick={() => isVideoLoaded && handlePlayPause()}
                        className="cursor-pointer"
                        key={selectedLayout}
                      />
                    </>
                  )}

                  <div className="w-full absolute bottom-0 invisible group-hover:visible">
                    <PlaybackRateController
                      selectedRate={playbackRate}
                      setSelectedRate={updatePlaybackRate}
                    />
                  </div>
                  {!clipDeleteLoading && isVideoLoaded && isVideoSeeked ? (
                    !isVideoFinished && (
                      <img
                        className={clsx(
                          "top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform cursor-pointer absolute invisible group-hover:visible p-2",
                          outroTimeLeft != 0 ? "hidden" : ""
                        )}
                        onClick={handlePlayPause}
                        src={
                          isVideoPlaying ? EditorPauseButton : EditorPlayButton
                        }
                      />
                    )
                  ) : (
                    <div
                      className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform  h-full w-full cursor-auto`}
                    >
                      <Loader />
                    </div>
                  )}
                </div>
                <button
                  onClick={handelMakeChanges}
                  type="button"
                  id={MAKE_CHANGES_TO_CLIP_BTN_NEW_UI.ID}
                  className="w-full mt-4 items-center justify-center rounded-lg bg-blue-600 px-2 py-3 text-center text-white hover:bg-blue-700 focus:outline-none inline-flex text-sm font-medium"
                >
                  <PencilIcon
                    className="mr-2"
                    width={20}
                    height={20}
                  />
                  Edit & Download Video
                </button>

                {isVideoFinished && !isVideoPlaying && isVideoLoaded ? (
                  <div
                    style={{
                      width: EDITOR_WIDTH,
                      height: EDITOR_HEIGHT,
                    }}
                    className="bg-transparent"
                  >
                    <div className="absolute top-0 left-0 w-full h-full bg-[#838383] py-4 px-2 flex flex-col justify-center items-center space-y-2">
                      <div
                        className={`flex justify-center items-center w-full ${
                          !currentSelectedMicroContent?.userClipRateExist
                            ? ""
                            : "mt-[20%] ml-auto"
                        }`}
                      >
                        <button
                          onClick={() => {
                            handlePlayPause();
                            setIsVideoFinished(false);
                          }}
                          className="text-white w-3/5 flex justify-center items-center border border-white rounded-lg py-2 px-3"
                        >
                          <ArrowPathIcon
                            width={18}
                            height={18}
                            className="mr-2"
                          />
                          replay
                        </button>
                      </div>

                      {!currentSelectedMicroContent?.userClipRateExist ? (
                        <div className="mt-2 text-white flex flex-col space-y-9">
                          <div>
                            <div className="text-center text-lg font-medium mb-2">
                              Rate This Video
                            </div>
                            <div className="text-center text-xs font-light">
                              Your rating helps improve future AI suggestions
                            </div>
                          </div>
                          <div className="flex justify-center items-center space-x-12">
                            <div
                              className="p-1 bg-white rounded-full cursor-pointer"
                              onClick={() => userRating(RATE_UP)}
                            >
                              <HandThumbUpIcon
                                width={35}
                                height={35}
                                color="green"
                              />
                            </div>
                            <div
                              className="p-1 bg-white rounded-full cursor-pointer"
                              onClick={() => userRating(RATE_DOWN)}
                            >
                              <HandThumbDownIcon
                                width={35}
                                height={35}
                                color="red"
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
);

export default TemplatesPreview;
