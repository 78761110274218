import { Button } from "flowbite-react";

import useDeleteProject from "@/api/useDeleteProject";

import DeleteConfirmationModal from "@/components/DeleteConfirmationModal";
import Spinner from "@/components/Loader/Spinner";

const ProjectDeleteModal = ({
  setProjectDeleteModal,
  displayProjectDeleteModal,
  projectId,
}: {
  setProjectDeleteModal: (value: boolean) => void;
  displayProjectDeleteModal: boolean;
  projectId: string;
}) => {
  const { mutate, isLoading } = useDeleteProject();
  return (
    <DeleteConfirmationModal
      showDeleteConfirmationModal={displayProjectDeleteModal}
      heading="Do you really want to delete this video?"
    >
      <div className="mt-3 flex items-center">
        <div className="mb-6 text-gray-900">
          This action is <span className="underline"> not reversible</span> and
          used minutes will <span className="underline">not be refunded</span>{" "}
          for successfully processed videos.
        </div>
      </div>
      <div className="flex flex-row-reverse gap-10 md:gap-10 mt-3">
        <Button
          id="delete-project"
          color="failure"
          className="w-full rounded-lg text-center text-sm font-medium text-white"
          onClick={() => {
            mutate(projectId, {
              onSuccess: () => {
                setProjectDeleteModal(false);
              },
            });
          }}
        >
          {isLoading ? <Spinner className="h-4 w-4" /> : <span>Delete</span>}
        </Button>
        <Button
          id="cancel-project-deletion"
          onClick={() => setProjectDeleteModal(false)}
          color="light"
          className="w-full rounded-lg border border-red-500 bg-white text-center text-sm font-medium text-blue-500 hover:bg-blue-50"
        >
          Cancel
        </Button>
      </div>
    </DeleteConfirmationModal>
  );
};

export default ProjectDeleteModal;
