import { store } from "@/store";

import { getParsedJsonData } from "@/helpers/common";

import { UsageData } from "@/interfaces";

import { PlanType, ProjectStatus } from "@/enums";

const REMAINING_PROCESSING_ALLOWED_TIME_IN_SECONDS = 100000; // soft limit for unlimited users 27hrs
export const calculateRemainingProcessingTime = (
  usageDataInSeconds: UsageData | undefined,
  bufferTimeInMinutes = 0
) => {
  const { allowedMinutes, planType } =
    store.getState().authState.userSubscription;

  if (planType === PlanType.UNLIMITED) {
    return REMAINING_PROCESSING_ALLOWED_TIME_IN_SECONDS;
  }

  let remainingProcessingTimeInSecond = usageDataInSeconds
    ? (allowedMinutes + bufferTimeInMinutes) * 60 - usageDataInSeconds?.usage
    : allowedMinutes * 60;
  // if (remainingProcessingTimeInSecond < 0) {
  //   remainingProcessingTimeInSecond = 0;
  // }
  return remainingProcessingTimeInSecond;
};

export const calculateRemainingProcessingTimeInMinutes = (
  usageData: UsageData | undefined,
  bufferTimeForFreeUser = 0
) => {
  const remainingTimeInSecond = calculateRemainingProcessingTime(
    usageData,
    bufferTimeForFreeUser
  );
  return Math.floor(remainingTimeInSecond / 60);
};

export const checkIfProcessingHourisBellowThresholdLimit = (
  usageData: UsageData
) => {
  const { allowedMinutes } = store.getState().authState.userSubscription;

  const userUsage = usageData?.usage || 0;

  return userUsage < allowedMinutes * 60 * 0.8; // 80% of total free processing time
};

export const calculateTotalProcessingTime = (
  array = [],
  usageData: any
): UsageData => {
  let totalLocalProcessingTime = 0;
  array.forEach((project: any) => {
    if (project?.status === ProjectStatus.OPEN) {
      totalLocalProcessingTime += getParsedJsonData(project.data)?.duration;
    }
  });
  const updatedProcessingTime = {
    ...usageData,
    usage: usageData?.usage + totalLocalProcessingTime / 1000,
  };
  return updatedProcessingTime;
};
