import { Fragment } from "react";

import { Menu, Transition } from "@headlessui/react";
import { TrashIcon } from "@heroicons/react/24/outline";
import { Button, Card, Spinner } from "flowbite-react";

import { trackDeleteClipEvent } from "@/utils/amplitudeAnalytcs";

import { EditorDraft } from "@/interfaces";

import { ScreenName, TemplateVariant, VideoLayout } from "@/enums";

import TemplateStaticPreview from "../TemplateStaticPreview";

type DraftCardProps = {
  draft: EditorDraft;
  handelOpenPreviewClip: (draft: EditorDraft) => void;
  handleDeleteDraft: (draft: EditorDraft) => void;
  isDraftDeleteMutationLoading: boolean;
};

const DraftCard = ({
  draft,
  handelOpenPreviewClip,
  handleDeleteDraft,
  isDraftDeleteMutationLoading,
}: DraftCardProps) => {
  const getTemplateDimensions = () => {
    switch (draft.aspectRatio) {
      case VideoLayout.LAYOUT_9_16_1:
      case VideoLayout.LAYOUT_9_16_2:
        return {
          width: 85,
          height: 151,
        };
      case VideoLayout.LAYOUT_1_1:
        return {
          width: 151,
          height: 151,
        };
      case VideoLayout.LAYOUT_16_9:
        return {
          width: 258,
          height: 151,
        };
      default:
        return {
          width: 85,
          height: 151,
        };
    }
  };

  return (
    <Card className="draft-card rounded-md w-[258px]">
      <div className="relative group h-[151px]">
        <div className="absolute z-10 w-full h-full flex justify-center items-center  group-hover:bg-black group-hover:bg-opacity-40 transition-all rounded-t-md">
          <Button
            className="group-hover:block hidden mx-auto "
            size="sm"
            onClick={() => handelOpenPreviewClip(draft)}
          >
            Edit Clip
          </Button>
        </div>
        <TemplateStaticPreview
          template={draft}
          key={draft.id}
          variant={TemplateVariant.DRAFT}
          dimensions={getTemplateDimensions()}
          showDuration={false}
          layout={draft.aspectRatio}
        />
      </div>

      <div className="flex justify-between items-center bg-gray-100 p-2 rounded-b-md">
        <p className=" text-xs font-medium truncate mr-4">{draft.name}</p>
        <Menu
          as="div"
          className="relative inline-block text-left"
        >
          <div>
            <Menu.Button
              className={`inline-flex w-full justify-center rounded-md bg-gray-50 ${
                isDraftDeleteMutationLoading
                  ? "px-1.5 py-1 cursor-default hover:bg-opacity-100"
                  : "px-2.5 py-1.5"
              } text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
              disabled={isDraftDeleteMutationLoading}
            >
              {isDraftDeleteMutationLoading ? (
                <Spinner size="sm" />
              ) : (
                <svg
                  id="Layer_1"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 29.96 122.88"
                  className="w-1"
                >
                  <path
                    fillRule="evenodd"
                    d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Zm0,92.93a15,15,0,1,1-15,15,15,15,0,0,1,15-15Zm0-46.47a15,15,0,1,1-15,15,15,15,0,0,1,15-15Z"
                  />
                </svg>
              )}
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-20 mt-1 w-36 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="px-1 py-2 ">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? "bg-blue-500 text-white" : "text-red-500"
                      } flex w-full cursor-pointer items-center rounded-md px-2 py-1.5 text-sm`}
                      onClick={() => {
                        handleDeleteDraft(draft);
                        trackDeleteClipEvent(
                          draft,
                          draft.project,
                          ScreenName.DRAFTS
                        );
                      }}
                    >
                      <TrashIcon className="w-4 h-4 mr-1 -mt-px" />
                      <span>Delete Draft</span>
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </Card>
  );
};

export default DraftCard;
