import { Spinner } from "flowbite-react";

import AssetListItem from "./AssetListItem";
import EmptyAssetsState from "./EmptyAssetsState";

type AssetListProps = {
  items: any;
  onClick?: (item: any, assetType: any) => void;
  isLoading?: boolean;
  isSocialMediaIcon?: boolean;
  isStockAsset?: boolean;
  assetType?: string;
};

const AssetList = ({
  items,
  onClick = (item, assetType) => {},
  isLoading = false,
  isSocialMediaIcon = false,
  isStockAsset = false,
  assetType = "",
}: AssetListProps) => {
  return (
    <ul className="grid grid-cols-3 gap-4">
      {isLoading ? (
        <Spinner className="w-5 h-5" />
      ) : (
        <>
          {items?.length ? (
            <>
              {items.map((item: any) => (
                <AssetListItem
                  key={item.id || item.uid}
                  assetUrl={
                    item.data ? JSON.parse(item.data)?.remote_url : item.url
                  }
                  assetType={item.assetType || item.type}
                  onClick={() => onClick(item, assetType)}
                  isSocialMediaIcon={isSocialMediaIcon}
                  assetId={item.id}
                  isStockAsset={isStockAsset}
                  assetName={item.data ? JSON.parse(item.data)?.asset_name : ""}
                />
              ))}
            </>
          ) : (
            <EmptyAssetsState />
          )}
        </>
      )}
    </ul>
  );
};

export default AssetList;
