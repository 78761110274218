import { getCRUDBaseUrl, isProd } from "@/helpers";

export const ApiEndpoints = {
  GOOGLE_SHEET_PROXY:
    "https://us-central1-vidyo-ai-poc.cloudfunctions.net/google-sheet-proxy",
  YT_PROXY: "https://us-central1-vidyo-ai-poc.cloudfunctions.net/yt-proxy",
  MICRO_CONTENT_FEEDBACK_SHEET:
    "https://script.google.com/macros/s/AKfycbz958479K-yi9eNI2axPsck-Zc6ky19VM3Sb6stliMyU_r1lCfonPZEfsv5vxhSK5pa1w/exec",
  RENDERED_VIDEO_SHEET:
    "https://script.google.com/macros/s/AKfycbxpPJ66mIAQWLgHlbYEJ5Bm-2p9t1OZqHxKMqo1gpHURJpuuywIJRuVHTlOYDx4T-uQ5w/exec",
  ONBOARDING_DATA_SHEET:
    "https://script.google.com/macros/s/AKfycbzrHOoFN9jHwjdufnS8HW9g2mQxky3A-bswWey-lIUO1r6RETgApBb6ScVZU5rKu1Q0Gg/exec",
  TIKTOK_AUTH_URL: `https://www.tiktok.com/v2/auth/authorize/?client_key=awf259v4z6qxyrdr&scope=user.info.basic,video.upload,video.publish&response_type=code&redirect_uri=${
    isProd()
      ? `https://app.vidyo.ai/downloads`
      : `https://stage.vidyo-ui.pages.dev/downloads`
  }&state=${Math.random().toString(36).substring(2)}`,
  AYRSHARE_REDIRECT_URL: `${window.location.origin}/downloads`,
  IP_LOOKUP: "https://ip-lookup.vidyoai.workers.dev",
  ADD_MINUTES: "https://add-on-min-6djqmk5hza-uc.a.run.app",
  HASHTAGS: "https://function-hashtag-v2-lnjqr3l3hq-uc.a.run.app",
  POST_MICRO_CONTENT: "/create/micro_content",
  GET_MICRO_CONTENT: `${getCRUDBaseUrl()}/create/micro_content/`,
  MULTIPART_UPLOAD_POST: "/assets/upload/initializeMultipartUpload/",
  MULTIPART_UPLOAD_PRESIGNED_URL: "/assets/upload/getMultipartPreSignedUrls/",
  MULTIPART_UPLOAD_FINAL: "/assets/upload/finalizeMultipartUpload/",
  ALL_PROJECTS: "/project/",
  GET_PROJECT_BY_ID: `${getCRUDBaseUrl()}/project`,
  ALL_PROJECTS_LIST: `${getCRUDBaseUrl()}/project/list`,
  USERS: "/users/login/",
  USER_CREATE: "/users/create/",
  USER_UPDATE: "/users/update",
  USER_DELETE: "/users/delete/",
  CUT_VIDEO: "/create/video_clip/",
  PRESIGNED_URL_S3: "/assets/upload/getPreSignedUrl/",
  FINALIZE_ASSET_UPLOAD: "/assets/upload/finalizeUpload",
  ASSETS: "/assets",
  ASSETS_COUNT: "/assets/count",
  CROP_FACE_VIDEO: "/create/cropped_face_video/",
  OUTPUT_VIDEO: "/create/output_video/",
  SRT_FILE: "/create/srt_file",
  RETRY_PROJECT: "/create/micro_content/retry/",
  USAGE_TRACKING: `${getCRUDBaseUrl()}/usage/`,
  DOWNLOADS_LIST: "/tasks/rendered",
  POST_RENDER_VIDEO: "/create/render_video",
  GET_RENDER_VIDEO: `${getCRUDBaseUrl()}/create/render_video/`,
  RENDER_VIDEO_TEMP: "/create/render_video_temp",
  MANAGE_SUBSCRIPTION: "customer-portal",
  CREATE_CHECKOUT_SESSION: "/customer-portal/checkout",
  YOUTUBE_METADATA: "/create/youtube/data",
  YOUTUBE_DATA_API: isProd()
    ? "https://us-central1-vidyoai.cloudfunctions.net/data-api"
    : "https://us-central1-vidyo-ai-poc.cloudfunctions.net/data-api",
  CREATE_SCENES: "/create/scenes",
  CREATE_THUMBNAIL: "/create/thumbnail",
  DELETE_DOWNLOADS: "/tasks/",
  FIRESTORE_PROXY: `${getCRUDBaseUrl()}/proxy/firestore`,
  FIRESTORE_PROXY_BASE_TEMPLATES: `${getCRUDBaseUrl()}/proxy/firestore/base_templates`,
  MICROCONTENT: "/microcontent",
  PADDLE_SUBSCRIPTION: `${getCRUDBaseUrl()}/subscription/users`,
  PADDLE_CANCEL_SUBSCRIPTION: `${getCRUDBaseUrl()}/subscription/users/cancel`,
  PADDLE_UPDATE_SUBSCRIPTION: `${getCRUDBaseUrl()}/subscription/users/update`,
  PADDLE_PLANS: `${getCRUDBaseUrl()}/subscription/subscription/plans`,
  USER_JOURNEY: "/users/journey",
  MULTIPART_COMPLETE: "/multipart/complete",
  UPPY_ENDPOINT: isProd()
    ? "https://uploader.vidyo.ai"
    : "https://stage-uploader.vidyo.ai",
  EMAIL_CHECK: "https://email-check.vidyoai.workers.dev",
  SOCIAL_MEDIA: `${getCRUDBaseUrl()}/social-media`,
  QUERY_CREATOR_INFO: "/query-creator-info",
  CREATE_SOCIAL_MEDIA_ACCOUNT: "/create-user-account-data",
  UPDATE_SCHEDULED_POST: "/update-post",
  DELETE_SCHEDULED_POST: "/delete-post",
  SCHEDULED_POSTS_LIST: (uid: string) => `/${uid}/list-posts-history`,
  CREATE_PAID_USER_OAUTH: (uid: string) =>
    `/paid-version/${uid}/create-oauth-details`,
  SOCIAL_MEDIA_POST_VIDEOS: "/post-videos",
  REVOKE_SOCIAL_MEDIA_ACCESS: "/revoke-access",
  SOCIAL_MEDIA_EVENT_NOTIFICATIONS: "notification/socialMedia-events",
  SOCIAL_MEDIA_POST_UPDATES: (uid: string, videoId: string) =>
    `/social-media/${uid}/${videoId}/post-status`,
  BULK_RENDER_VIDEO: "/create/bulk_render_video",
  GET_BULK_RENDER_VIDEO: `${getCRUDBaseUrl()}/create/bulk_render_video/`,
  BULK_RENDER_GET_PRE_SIGNED_URL: "/assets/download/bulk/getPresignedUrl",
  BULK_DELETE: "/microcontent/delete/clips",
  BULK_DOWNLOADS_LIST: "/create/rendred/bulk",
  PEXELS_IMAGES: "https://pexels.vidyoai.workers.dev/v1/search",
  PEXELS_VIDEOS: "https://pexels.vidyoai.workers.dev/videos/search",
};
