import { useState } from "react";

import { PlayCircleIcon } from "@heroicons/react/24/outline";
import { TrashIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";

import useDeleteAsset from "@/api/useDeleteAsset";

import { generateCloudflareImageUrl } from "@/helpers";

import { SimpleAssetType } from "@/enums";

type AppliedAssetCardContainerProps = {
  assetUrl: string;
  assetType: string;
  onClick?: () => void;
  assetId: string;
  isSocialMediaIcon?: boolean;
  isStockAsset?: boolean;
  assetName?: string;
};

const AssetListItem = ({
  assetUrl,
  assetType,
  onClick = () => {},
  assetId,
  isSocialMediaIcon = false,
  isStockAsset = false,
  assetName = "",
}: AppliedAssetCardContainerProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const { mutate: deleteAsset, isLoading: deleteAssetLoading } =
    useDeleteAsset();

  const handleDelete = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    deleteAsset(assetId);
  };

  return (
    <li
      className="flex items-center justify-between bg-white border rounded-lg shadow-[0_0px_8px_0_rgba(0,0,0,0.1)] border-gray-100 h-24  cursor-pointer relative"
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {assetType.includes(SimpleAssetType.IMAGE) ? (
        <img
          src={generateCloudflareImageUrl({
            originalUrl: assetUrl,
            imgOptions: {
              height: 96,
            },
          })}
          className={clsx(
            isSocialMediaIcon ? "h-10 w-10 mx-auto" : "h-full w-full",
            "object-center object-scale-down"
          )}
          onError={(e: any) => {
            e.target.onerror = null;
            e.target.src = assetUrl;
          }}
        />
      ) : assetType.includes(SimpleAssetType.VIDEO) ? (
        <div className="h-full w-full">
          <video
            className="w-full object-center object-scale-down h-full"
            src={assetUrl}
            preload="metadata"
            muted // Safari hack
            autoPlay={false} // Safari hack
            playsInline
          />
          <PlayCircleIcon className="absolute h-5 w-5 text-gray-300 bottom-0 right-0" />
        </div>
      ) : assetType.includes(SimpleAssetType.AUDIO) ? (
        <div className="h-full w-full"></div>
      ) : (
        <></>
      )}
      {!isStockAsset && !isSocialMediaIcon && isHovered && (
        <div className="absolute top-0 right-0 m-1">
          <TrashIcon
            className="h-6 w-6 text-red-500 cursor-pointer bg-white rounded-full p-1 border-solid border-3 border-gray-200"
            onClick={handleDelete}
          />
        </div>
      )}
    </li>
  );
};

export default AssetListItem;
