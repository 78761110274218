import { useSelectedClips } from "@/context/ReviewClips";
import { Button } from "flowbite-react";

import { PreviewScreenClipTypes } from "@/enums";

import { InfoToast } from "../InfoToast";

type TopInfoProps = {
  currentType: PreviewScreenClipTypes;
};

const displayInfoToast = (currentType: PreviewScreenClipTypes) => {
  if (currentType === PreviewScreenClipTypes.CHAPTERS)
    return (
      <InfoToast info="Chapters are longer sections of the video divided by topics" />
    );
  else if (currentType === PreviewScreenClipTypes.SHORTS)
    return <InfoToast info="Shorts are small snippets upto 1m in length" />;
  else {
    return null;
  }
};

const TopInfo = ({ currentType }: TopInfoProps) => {
  const { selectModeOn, toggleMultiSelectMode } = useSelectedClips();
  return (
    <div className="flex justify-between">
      {displayInfoToast(currentType) && (
        <div className="w-2/3">
          <div className="h-9">{displayInfoToast(currentType)}</div>
        </div>
      )}
      {currentType !== PreviewScreenClipTypes.FULL_VIDEO && (
        <Button
          color="gray"
          size="sm"
          className="h-9 w-28"
          onClick={toggleMultiSelectMode}
        >
          {selectModeOn ? "Cancel" : "Select clips"}
        </Button>
      )}
    </div>
  );
};

export default TopInfo;
