import { useState } from "react";

import { OAuthProvider } from "firebase/auth";
import { Button, Spinner } from "flowbite-react";

import { signInWithFirebase } from "@/utils/FirebaseHelpers";

const MicrosoftAuth = ({
  setDisplayModal,
  setFirebaseUserData,
}: {
  setDisplayModal: (value: boolean) => void;
  setFirebaseUserData: (value: {}) => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const signInWithOutlook = (e: any) => {
    e.preventDefault();
    const provider = new OAuthProvider("microsoft.com");
    signInWithFirebase(
      provider,
      setIsLoading,
      setDisplayModal,
      setFirebaseUserData
    );
  };
  return (
    <Button
      color="light"
      onClick={signInWithOutlook}
      className={`inline-flex w-full  items-center justify-center rounded-md border border-black text-sm font-medium text-gray-900  focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400  dark:focus:ring-gray-700 bg-[fff] hover:bg-[#f2f2f2] transition-colors ${
        isLoading
          ? " border-black/60 opacity-60"
          : "hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-700 dark:hover:text-white"
      }`}
      id="sign-in-with-microsoft"
      disabled={isLoading}
    >
      {isLoading ? (
        <Spinner className="h-4 w-4" />
      ) : (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            width="20px"
            height="20px"
          >
            {" "}
            <path d="M 15 3 A 2 2 0 0 0 14.599609 3.0429688 L 14.597656 3.0410156 L 4.6289062 5.0351562 L 4.6269531 5.0371094 A 2 2 0 0 0 3 7 L 3 23 A 2 2 0 0 0 4.6289062 24.964844 L 14.597656 26.958984 A 2 2 0 0 0 15 27 A 2 2 0 0 0 17 25 L 17 5 A 2 2 0 0 0 15 3 z M 19 5 L 19 9.3417969 L 21 10.771484 L 26.9375 6.5292969 C 26.7255 5.6532969 25.942 5 25 5 L 19 5 z M 27 8.9433594 L 21.580078 12.814453 C 21.406078 12.938453 21.204 13 21 13 C 20.796 13 20.593922 12.938453 20.419922 12.814453 L 19 11.800781 L 19 18.34375 C 19.995 16.92875 21.638 16 23.5 16 C 24.83 16 26.049 16.472813 27 17.257812 L 27 8.9433594 z M 9.5820312 10 C 10.601031 10 11.427641 10.452469 12.056641 11.355469 C 12.684641 12.259469 13 13.450688 13 14.929688 C 13 16.449688 12.673484 17.672516 12.021484 18.603516 C 11.367484 19.533516 10.515797 20 9.4667969 20 C 8.4447969 20 7.6107969 19.550437 6.9667969 18.648438 C 6.3237969 17.747438 6 16.572 6 15.125 C 6 13.596 6.3274219 12.360016 6.9824219 11.416016 C 7.6374219 10.473016 8.5020312 10 9.5820312 10 z M 9.53125 11.996094 C 9.06225 11.996094 8.6900625 12.272266 8.4140625 12.822266 C 8.1380625 13.373266 8 14.102766 8 15.009766 C 8 15.929766 8.1380625 16.656359 8.4140625 17.193359 C 8.6900625 17.728359 9.0510469 17.996094 9.4980469 17.996094 C 9.9580469 17.996094 10.32375 17.737797 10.59375 17.216797 C 10.86475 16.696797 11 15.973828 11 15.048828 C 11 14.085828 10.868469 13.336781 10.605469 12.800781 C 10.344469 12.263781 9.98625 11.996094 9.53125 11.996094 z M 23.5 18 C 21.57 18 20 19.57 20 21.5 C 20 23.43 21.57 25 23.5 25 C 25.43 25 27 23.43 27 21.5 C 27 19.57 25.43 18 23.5 18 z M 23 19 L 24 19 L 24 21 L 26 21 L 26 22 L 24 22 L 23 22 L 23 21 L 23 19 z" />
          </svg>

          <span className="hidden xl:block text-dark text-md ml-3">
            Microsoft
          </span>
        </>
      )}
    </Button>
  );
};

export default MicrosoftAuth;
