import { memo } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import clsx from "clsx";
import { Tooltip } from "flowbite-react";

import { setCurrentSideMenu } from "@/store/editorSlice";

import { EditorMediaTab, EditorMediaTabMode } from "@/enums";

import BackgroundIcon from "@/components/Icons/Background";
import CaptionIcon from "@/components/Icons/Caption";
import CutMagicIcon from "@/components/Icons/CutMagic";
import ElementsIcon from "@/components/Icons/Elements";
import MediaIcon from "@/components/Icons/Media";
import SubtitleIcon from "@/components/Icons/Subtitle";
import TemplatesIcon from "@/components/Icons/Templates";
import TextsIcon from "@/components/Icons/Texts";

import { editorSubRoutes } from "@/views/editor/constant";

const menuItems = [
  {
    id: editorSubRoutes.TEMPLATES,
    alt: "Templates",
    Icon: TemplatesIcon,
  },
  {
    id: editorSubRoutes.SCENE_CHANGE,
    alt: "CutMagic",
    Icon: CutMagicIcon,
  },
  {
    id: editorSubRoutes.SUBTITLES,
    alt: "Subtitles",
    Icon: SubtitleIcon,
  },
  {
    id: editorSubRoutes.ELEMENTS,
    alt: "Elements",
    Icon: ElementsIcon,
  },
  {
    id: editorSubRoutes.MEDIA,
    alt: "Media",
    Icon: MediaIcon,
  },
  {
    id: editorSubRoutes.TEXTS,
    alt: "Texts",
    Icon: TextsIcon,
  },

  {
    id: editorSubRoutes.BACKGROUND,
    alt: "Background",
    Icon: BackgroundIcon,
  },
  {
    id: editorSubRoutes.HASHTAGS,
    alt: "AI Description",
    Icon: CaptionIcon,
  },
];

const SidebarItem = ({
  id,
  Icon,
  alt,
}: {
  id: string;
  Icon: any;
  alt: string;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = location.pathname.split("/")[3] === id;
  const currentProjectId = location.pathname.split("/")[2];

  const handelChangeSideBarMenu = (id: string) => {
    dispatch(setCurrentSideMenu(id));

    // If the id is media, then navigate to the media tab
    // with the image tab selected and browse mode
    if (id === editorSubRoutes.MEDIA) {
      navigate(
        `/editor/${currentProjectId}/${id}?type=${EditorMediaTab.IMAGE}&mode=${EditorMediaTabMode.BROWSE}`
      );
      return;
    }
    navigate(`/editor/${currentProjectId}/${id}`);
  };

  return (
    <li
      className="mb-4 w-20 cursor-pointer items-center text-gray-400 hover:text-gray-500"
      onClick={() => handelChangeSideBarMenu(id)}
    >
      <div
        className={clsx(
          isActive ? "bg-gray-100" : "bg-transparent",
          "flex items-center justify-center py-2.5"
        )}
      >
        <div
          className={clsx(
            isActive ? "bg-blue-500" : "bg-transparent",
            "h-8 w-1 rounded-tr-lg rounded-br-lg absolute left-0"
          )}
        ></div>

        <Tooltip
          content={alt}
          placement="left"
          className="whitespace-nowrap z-[999]"
        >
          <Icon className={isActive ? "text-blue-500" : "mx-auto"} />
        </Tooltip>
      </div>
    </li>
  );
};

const MemoizedSidebarItem = memo(SidebarItem);

const EditorLeftSidebar = () => {
  return (
    <div className="absolute flex h-full w-20 flex-col items-center justify-between  border-l bg-white sm:relative">
      <ul className="mt-4 flex w-full flex-col ">
        {menuItems.map((item) => (
          <MemoizedSidebarItem
            key={item.id}
            id={item.id}
            Icon={item.Icon}
            alt={item.alt}
          />
        ))}
      </ul>
    </div>
  );
};

export default EditorLeftSidebar;
