import { useBoolean } from "react-use";

import { LinkIcon, ShieldCheckIcon } from "@heroicons/react/20/solid";
import { Spinner } from "flowbite-react";

import { useAppSelector } from "@/store/hooks";

import { createSocialMediaAccount } from "@/api/requests";

import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

import { createPaidUserOauth } from "@/views/home/components/DownloadVideoCard/SocialMediaSharing/constants";

export const LinkSocialMedia = ({ activeTab }: { activeTab: string }) => {
  const { uid, displayName } = useAppSelector(
    (state) => state.authState.currentUser
  );
  const [isAccountLinking, setIsAccountLinking] = useBoolean(false);

  // creates social media account for paid user
  const handleLinkSocialMedia = async () => {
    setIsAccountLinking(true);
    try {
      const result = await createSocialMediaAccount({
        paid_version: {
          user_id: uid,
          profile_name: uid,
        },
      });
      if (result.status === 200) {
        await createPaidUserOauth(uid);
      }
      setIsAccountLinking(false);
    } catch (err: any) {
      const errResponse = err.response;
      const errorMsg = errResponse?.data?.message;
      const errorCode = errResponse?.data?.code;

      if (errorCode === 146) {
        showNotification(
          "Profile title already exists. Please choose a different title",
          notificationType.FAIL
        );
      } else if (
        errorMsg === "User is not a paid user" &&
        errResponse?.status === 401
      ) {
        showNotification(
          "Looks like your account is not paid. Please use Tiktok for posting.",
          notificationType.FAIL
        );
      } else {
        showNotification(
          "Something went wrong. Please try again!",
          notificationType.FAIL
        );
      }
      setIsAccountLinking(false);
    }
  };

  return (
    <div className="mt-16 text-center">
      <h3 className="text-xl font-semibold">Link your {activeTab} account</h3>
      <p className="text-sm font-light mt-5">
        As a PRO user, you can post to <br />
        any of the supported social networks 🎉
      </p>

      <button
        className="flex items-center justify-center m-auto text-sm font-medium mt-12 bg-primary-600 hover:bg-primary-700 rounded text-white h-[40px] w-[205px]"
        onClick={handleLinkSocialMedia}
      >
        {isAccountLinking ? (
          <Spinner className="w-[16px] h-[16px] mr-2" />
        ) : (
          <LinkIcon className="mr-2 w-5 h-5" />
        )}
        Link Social Platforms
      </button>
      <p className="text-xs font-light text-gray-600 mt-20 flex justify-center items-start leading-4">
        <ShieldCheckIcon className="w-5 h-5 text-gray-600 mr-2" />
        We use Ayrshare for seamless social posting. <br />
        Your data is absolutely secure at all times
      </p>
    </div>
  );
};
