import { SVGProps, memo } from "react";

import clsx from "clsx";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    className={clsx(props.className, "fill-current")}
    {...props}
  >
    <path
      fill="currentColor"
      d="M20.333 2H7a1.667 1.667 0 0 0-1.667 1.667v1.666H3.667A1.667 1.667 0 0 0 2 7v13.333A1.666 1.666 0 0 0 3.667 22H17a1.667 1.667 0 0 0 1.667-1.667v-1.666h1.666A1.666 1.666 0 0 0 22 17V3.667A1.667 1.667 0 0 0 20.333 2ZM7 3.667h13.333v7.227l-1.74-1.74a1.667 1.667 0 0 0-2.355 0L8.393 17H7V3.667Zm10 16.666H3.667V7h1.666v10A1.667 1.667 0 0 0 7 18.667h10v1.666Zm-7.5-12.5a1.667 1.667 0 1 1 3.333 0 1.667 1.667 0 0 1-3.333 0Z"
    />
  </svg>
);
const MediaIcon = memo(SvgComponent);
export default MediaIcon;
