export const emojiTags: any = {
  lover: ["😍"],
  "100": ["💯"],
  "search bar": ["🕵️"],
  "1234": ["🔢"],
  face: ["😀", "😃", "😄", "😁"],
  grin: ["😀", "😁", "😸"],
  smile: ["😃", "😄", "🙂", "😊", "☺️"],
  eye: ["👁️‍🗨️", "👀", "👓️", "🕶️"],
  laugh: ["😆", "🤣", "😂"],
  satisfied: ["😆"],
  cold: ["🥶", "🧊", "🏔️", "🌨️", "❄️", "☃️", "⛄️", "💧"],
  sweat: ["😰", "😓", "💦", "💧"],
  floor: ["🤣"],
  rofl: ["🤣"],
  rolling: ["🤣"],
  rotfl: ["🤣"],
  joy: ["😂", "😹"],
  tear: ["😂", "🥲", "😢", "😭", "😹", "😿"],
  "upside-down": ["🙃"],
  disappear: ["🫠", "🫥"],
  dissolve: ["🫠"],
  liquid: ["🫠"],
  melt: ["🫠"],
  wink: ["😉", "😜"],
  blush: ["😊"],
  angel: ["😇", "👼"],
  halo: ["😇"],
  innocent: ["😇"],
  adore: ["🥰"],
  crush: ["🥰"],
  hearts: ["🥰"],
  "in love": ["🥰"],
  love: ["😍", "😻", "💌", "💕", "❤️‍🔥", "🫶", "💑", "👩‍❤️‍👨", "👨‍❤️‍👨", "👩‍❤️‍👩"],
  eyes: ["🤩", "🙄"],
  grinning: ["🤩", "😺"],
  basketball: ["🏀"],
  star: ["🤩", "💫", "⭐️", "🌟", "🌠", "✨", "✡️", "🔯", "✴️"],
  kiss: ["😘", "😗", "😚", "😙", "😽", "💋", "👩‍❤️‍💋‍👨", "👨‍❤️‍💋‍👨", "👩‍❤️‍💋‍👩"],
  outlined: ["☺️", "❔", "❕", "🔳"],
  relaxed: ["☺️"],
  closed: ["😚", "📕", "📫️", "📪️", "🔒️", "🔐"],
  grateful: ["🥲"],
  proud: ["🥹", "🦚"],
  relieved: ["🥲", "😌", "😥"],
  smiling: ["🥲"],
  touched: ["🥲"],
  delicious: ["😋"],
  savouring: ["😋"],
  yum: ["😋"],
  tongue: ["😛"],
  joke: ["😜"],
  goofy: ["🤪"],
  large: ["🦣", "⭕️"],
  small: ["🦐"],
  horrible: ["😝"],
  taste: ["😝"],
  money: ["🤑", "💰️", "💴", "💵", "💶", "💷", "💸", "💳️"],
  hug: ["🤗", "🫂"],
  hugging: ["🤗"],
  "open hands": ["🤗"],
  "smiling face": ["🤗"],
  whoops: ["🤭"],
  amazement: ["🫢"],
  awe: ["🫢"],
  disbelief: ["🫢", "🤦", "🤦‍♂️", "🤦‍♀️"],
  embarrass: ["🫢"],
  scared: ["🫢", "😨", "😱"],
  surprise: ["🫢"],
  captivated: ["🫣"],
  peep: ["🫣"],
  stare: ["🫣"],
  quiet: ["🤫", "😶", "🔇", "🔕"],
  shush: ["🤫"],
  thinking: ["🤔"],
  ok: ["🫡", "👌", "🙆", "🙆‍♂️", "🙆‍♀️", "🆗"],
  salute: ["🫡"],
  sunny: ["🫡", "☀️"],
  troops: ["🫡"],
  yes: ["🫡"],
  zipper: ["🤐"],
  distrust: ["🤨"],
  skeptic: ["🤨"],
  deadpan: ["😐️"],
  meh: ["😐️", "😑", "😕", "🫤"],
  neutral: ["😐️"],
  expressionless: ["😑"],
  inexpressive: ["😑"],
  unexpressive: ["😑"],
  silent: ["😶", "🔇", "🔕"],
  depressed: ["🫥"],
  hide: ["🫥"],
  introvert: ["🫥"],
  invisible: ["🫥"],
  absentminded: ["😶‍🌫️"],
  "face in the fog": ["😶‍🌫️"],
  "head in clouds": ["😶‍🌫️"],
  smirk: ["😏"],
  unamused: ["😒"],
  unhappy: ["😒"],
  eyeroll: ["🙄"],
  grimace: ["😬"],
  exhale: ["😮‍💨"],
  gasp: ["😮‍💨"],
  groan: ["😮‍💨"],
  relief: ["😮‍💨"],
  whisper: ["😮‍💨"],
  whistle: ["😮‍💨"],
  lie: ["🤥"],
  pinocchio: ["🤥"],
  dejected: ["😔"],
  pensive: ["😔"],
  "good night": ["😪", "😴", "💤", "🛌"],
  sleep: ["😪", "😴", "💤", "🛌", "🛏️"],
  drool: ["🤤"],
  zzz: ["😴", "💤"],
  doctor: ["🧑‍⚕️", "👨‍⚕️", "👩‍⚕️", "🏥", "🥼", "💊", "🩺"],
  mask: ["😷", "🎭️"],
  sick: ["😷", "🤒", "🤮", "💉", "💊"],
  ill: ["🤒"],
  thermometer: ["🤒"],
  bandage: ["🤕", "🩹"],
  hurt: ["🤕", "🩼"],
  injury: ["🤕", "🩸"],
  nauseated: ["🤢"],
  vomit: ["🤢", "🤮"],
  puke: ["🤮"],
  gesundheit: ["🤧"],
  sneeze: ["🤧"],
  feverish: ["🥵"],
  "heat stroke": ["🥵"],
  hot: ["🥵", "🌶️"],
  "red-faced": ["🥵"],
  sweating: ["🥵"],
  "blue-faced": ["🥶"],
  freezing: ["🥶"],
  frostbite: ["🥶"],
  icicles: ["🥶"],
  dizzy: ["🥴", "😵‍💫", "🌀"],
  intoxicated: ["🥴"],
  tipsy: ["🥴"],
  "uneven eyes": ["🥴"],
  "wavy mouth": ["🥴"],
  "crossed-out eyes": ["😵"],
  dead: ["😵"],
  "knocked out": ["😵"],
  hypnotized: ["😵‍💫"],
  trouble: ["😵‍💫"],
  whoa: ["😵‍💫"],
  "mind blown": ["🤯"],
  shocked: ["🤯", "😲"],
  cowboy: ["🤠"],
  cowgirl: ["🤠"],
  hat: ["🤠", "🥳", "👷", "👲", "👒", "🎩", "🎓️", "⛑️"],
  celebration: [
    "🥳",
    "🙌",
    "🎂",
    "🎃",
    "🎄",
    "🎆",
    "🎇",
    "🎈",
    "🎉",
    "🎊",
    "🎋",
    "🎍",
    "🎎",
    "🎏",
    "🎐",
    "🎑",
    "🎀",
    "🎁",
    "🎗️",
    "🎖️",
  ],
  horn: ["🥳", "📯"],
  party: ["🥳", "🎉", "🪅"],
  disguise: ["🥸"],
  glasses: ["🥸", "🕶️"],
  incognito: ["🥸"],
  nose: ["🥸", "👃"],
  bright: ["😎", "🌞", "🔆"],
  cool: ["😎", "🆒"],
  sun: ["🌄", "🌅", "🌇", "🌞", "⛅️", "🌤️"],
  sunflower: ["🌻"],
  sunglasses: ["😎"],
  geek: ["🤓"],
  nerd: ["🤓"],
  monocle: ["🧐"],
  stuffy: ["🧐"],
  confused: ["😕"],
  disappointed: ["🫤", "😥", "😞"],
  skeptical: ["🫤"],
  unsure: ["🫤"],
  worried: ["😟", "🫦"],
  frown: ["🙁", "☹️", "😦", "🙍"],
  sympathy: ["😮"],
  hushed: ["😯"],
  stunned: ["😯"],
  surprised: ["😯", "🙀"],
  astonished: ["😲"],
  totally: ["😲"],
  dazed: ["😳"],
  flushed: ["😳"],
  begging: ["🥺"],
  mercy: ["🥺"],
  "puppy eyes": ["🥺"],
  angry: ["🥹", "😡", "😠"],
  cry: ["🥹", "😢", "😭", "😿"],
  resist: ["🥹"],
  sad: ["🥹", "😢", "😭", "😿"],
  anguished: ["😧"],
  fear: ["😨", "😱"],
  fearful: ["😨"],
  blue: ["💙", "🫐", "📘", "🔵", "🟦", "🔷", "🔹"],
  rushed: ["😰"],
  whew: ["😥"],
  sob: ["😭"],
  munch: ["😱"],
  scream: ["😱"],
  confounded: ["😖"],
  persevere: ["😣"],
  tired: ["😩", "😫", "🥱"],
  weary: ["😩", "🙀"],
  bored: ["🥱"],
  yawn: ["🥱"],
  triumph: ["😤"],
  won: ["😤"],
  enraged: ["😡"],
  mad: ["😡", "😠", "💢"],
  pouting: ["😡", "😾", "🙎", "🙎‍♂️", "🙎‍♀️"],
  rage: ["😡"],
  red: ["⭕️", "🔴", "🟥", "🔺", "🔻"],
  anger: ["😠"],
  swearing: ["🤬"],
  "fairy tale": [
    "😈",
    "💀",
    "👹",
    "👺",
    "👻",
    "👸",
    "👼",
    "🧌",
    "🐲",
    "🐉",
    "🔮",
  ],
  horns: ["😈", "🤘"],
  demon: ["👿"],
  devil: ["👿"],
  imp: ["👿"],
  death: ["⚰️", "⚱️"],
  monster: ["💀", "💩", "👹", "👺", "👻", "👾", "🤖"],
  crossbones: ["☠️"],
  skull: ["💀"],
  dung: ["💩"],
  poo: ["💩"],
  poop: ["💩"],
  clown: ["🤡"],
  creature: ["👹", "👺", "👻", "👽️", "👾"],
  extraterrestrial: ["👽️", "👾"],
  ufo: ["👽️", "👾", "🛸"],
  alien: ["👽️"],
  cat: ["😺", "😸", "😹", "😻", "😼", "😽"],
  heart: ["💟", "❤️‍🔥", "❤️", "🤎", "🤍", "🫀", "🩺"],
  ironic: ["😼"],
  wry: ["😼"],
  oh: ["🙀"],
  evil: ["🖤", "🦹", "🦹‍♂️", "🦹‍♀️"],
  forbidden: ["🙅", "🙅‍♂️", "🙅‍♀️", "🔕", "⛔️", "🚫", "📵"],
  monkey: ["🙈", "🙉", "🙊", "🐵", "🐒"],
  see: ["🙈", "👀"],
  hear: ["🙉", "🧏"],
  listen: ["🧏"],
  listener: ["🧏"],
  speak: ["🙊", "🗣️"],
  lips: ["💋", "👄"],
  letter: ["💌", "✉️", "📧", "📨", "📤️", "📥️"],
  mail: ["💌", "📧", "📤️", "📥️", "📫️", "📪️", "📬️", "📭️", "📮"],
  arrow: ["↗️", "➡️", "↘️"],
  cupid: ["💘"],
  ribbon: ["💝", "🎗️"],
  valentine: ["💝"],
  excited: ["🤩"],
  sparkle: ["🌟", "🎇", "✨"],
  nervous: ["🫦"],
  pulse: ["💗", "🫀"],
  beating: ["💓"],
  heartbeat: ["💓"],
  pulsating: ["💓"],
  exclamation: ["❣️", "‼️", "⁉️", "❕", "❗️"],
  mark: ["⁉️", "❕", "❗️", "✅", "✔️", "❌", "❎", "〽️", "™️", "🆙"],
  punctuation: ["❣️", "⁉️", "❓️", "❔", "❕", "❗️", "〰️"],
  heartbreak: ["💔"],
  break: ["⚒️"],
  heartbroken: ["💔"],
  heartburn: ["❤️‍🔥"],
  lust: ["❤️‍🔥"],
  "sacred heart": ["❤️‍🔥"],
  healthier: ["❤️‍🩹"],
  improving: ["❤️‍🩹"],
  mending: ["❤️‍🩹"],
  recovering: ["❤️‍🩹"],
  recuperating: ["❤️‍🩹"],
  well: ["❤️‍🩹"],
  orange: ["🍊", "🟠"],
  yellow: ["💛", "🟡", "🟨"],
  green: ["🥗", "📗", "🟢", "🟩"],
  purple: ["💜", "🟣", "🟪"],
  brown: ["🤎", "🟤", "🟫"],
  wicked: ["🖤"],
  full: ["💯"],
  hundred: ["💯"],
  score: ["💯"],
  comic: ["💢", "💥", "💫", "💦", "💨", "💣️", "💬", "💭", "💤"],
  boom: ["💥"],
  splashing: ["💦"],
  dash: ["💨", "〰️"],
  running: ["💨", "🏃", "🏃‍♂️", "🏃‍♀️", "🎽"],
  hole: ["🕳️", "⛳️"],
  balloon: ["🗨️", "🗯️", "💭"],
  bubble: ["💬", "👁️‍🗨️", "🗨️", "🗯️", "💭", "🧋"],
  dialog: ["💬", "🗨️"],
  speech: ["💬", "👁️‍🗨️", "🗨️"],
  witness: ["👁️‍🗨️"],
  thought: ["💭"],
  captions: ["💬"],
  hand: ["👋", "🖐️", "✋", "🖖"],
  wave: ["🌊"],
  waving: ["👋", "🏴", "🏳️"],
  backhand: ["🤚", "👈️", "👉️", "👆️", "👇️"],
  raised: ["🤚", "🙌", "🙋"],
  finger: ["🖖", "🤞", "🤘", "👈️", "👉️", "👆️", "🖕", "👇️"],
  splayed: ["🖐️"],
  "high 5": ["✋", "🙏"],
  "high five": ["✋", "🙏"],
  spock: ["🖖"],
  vulcan: ["🖖"],
  rightward: ["🫱"],
  leftward: ["🫲"],
  dismiss: ["🫳"],
  drop: ["💧"],
  shoo: ["🫳"],
  beckon: ["🫴"],
  catch: ["🫴", "🪝"],
  offer: ["🫴"],
  "hand gesture": ["🤌"],
  interrogation: ["🤌"],
  pinched: ["🤌"],
  sarcastic: ["🤌"],
  "small amount": ["🤏"],
  victory: ["✌️"],
  cross: ["⛪️", "⛑️", "✝️", "☦️", "❌", "🎌"],
  luck: ["🤞"],
  expensive: ["💵", "💰", "💸"],
  snap: ["🫰"],
  ily: ["🤟"],
  "rock-on": ["🤘"],
  call: ["🤙"],
  "hang loose": ["🤙"],
  shaka: ["🤙"],
  index: ["👈️", "👉️", "☝️", "🗂️", "📇"],
  up: ["👆️", "☝️", "👍️", "🔝", "🆙"],
  down: ["👇️", "👎️", "📉", "⬇️", "⤵️", "🔽", "⏬", "🔻"],
  you: ["🫵"],
  thumb: ["👍️", "👎️"],
  clenched: ["✊", "👊"],
  fist: ["✊", "👊", "🤛", "🤜"],
  punch: ["👊"],
  leftwards: ["🤛"],
  rightwards: ["🤜"],
  clap: ["👏"],
  hooray: ["🙌"],
  prayer: ["🤲", "📿"],
  agreement: ["🤝"],
  meeting: ["🤝"],
  shake: ["🤝"],
  please: ["🙏"],
  pray: ["🙏"],
  thanks: ["🙏", "🫂"],
  write: ["✍️"],
  cosmetics: ["💅", "💄"],
  manicure: ["💅"],
  nail: ["💅"],
  nailpolish: ["💅"],
  camera: ["🤳", "🎥", "📸", "📹️", "🎦"],
  phone: ["📱", "☎️", "📞"],
  biceps: ["💪"],
  muscle: ["💪"],
  accessibility: [
    "🦻",
    "🧏",
    "🧑‍🦯",
    "👨‍🦯",
    "👩‍🦯",
    "🧑‍🦼",
    "👨‍🦼",
    "👩‍🦼",
    "🧑‍🦽",
    "👨‍🦽",
    "👩‍🦽",
    "🦮",
    "🐕‍🦺",
    "🦽",
    "🦼",
    "🦯",
    "🛗",
  ],
  prosthetic: ["🦾", "🦿"],
  kick: ["🦵", "🦶", "🛴"],
  limb: ["🦵"],
  stomp: ["🦶"],
  body: ["👂️", "👃", "👁️", "👅"],
  "hard hearing": ["🦻"],
  intelligent: ["🧠"],
  anatomical: ["🫀"],
  cardiology: ["🫀"],
  organ: ["🫀", "🫁"],
  breath: ["🫁"],
  exhalation: ["🫁"],
  inhalation: ["🫁"],
  respiration: ["🫁"],
  dentist: ["🦷"],
  skeleton: ["🦴", "🩻"],
  anxious: ["🫦"],
  flirting: ["🫦"],
  uncomfortable: ["🫦"],
  young: ["👶", "🧒", "👦", "👧", "🌱"],
  "gender-neutral": ["🧒", "🧑", "🧑‍🦰", "🧑‍🦱", "🧑‍🦳", "🧑‍🦲", "🧓"],
  "unspecified gender": ["🧒", "🧑", "🧑‍🦰", "🧑‍🦱", "🧑‍🦳", "🧑‍🦲", "🧓"],
  virgo: ["👧", "♍️"],
  zodiac: [
    "⚖️",
    "♈️",
    "♉️",
    "♊️",
    "♋️",
    "♌️",
    "♍️",
    "♎️",
    "♏️",
    "♐️",
    "♑️",
    "♒️",
    "♓️",
    "⛎",
  ],
  adult: ["🧑", "👨", "👨‍🦰", "👩", "👩‍🦰"],
  blond: ["👱", "👱‍♂️"],
  "blond-haired person": ["👱"],
  hair: ["👱", "👱‍♀️", "👱‍♂️", "🦳"],
  beard: ["🧔", "🧔‍♂️", "🧔‍♀️"],
  person: ["🧔", "🧑‍🦰", "🧑‍🦱", "🧑‍🦳", "🧑‍🦲", "👲", "🤵", "👰", "🧑‍🍼", "🕴️", "🧑‍🤝‍🧑"],
  man: ["🧔‍♂️", "👨‍🦰", "👨‍🦱", "👨‍🦳", "👨‍🦲", "👱‍♂️", "👴", "🙍‍♂️", "🙎‍♂️"],
  woman: ["🧔‍♀️", "👩‍🦰", "👩‍🦱", "👩‍🦳", "👩‍🦲", "👱‍♀️", "👵", "🙍‍♀️", "🙎‍♀️"],
  "red hair": ["👨‍🦰", "👩‍🦰", "🧑‍🦰"],
  "curly hair": ["👨‍🦱", "👩‍🦱", "🧑‍🦱"],
  "white hair": ["👨‍🦳", "👩‍🦳", "🧑‍🦳"],
  bald: ["👨‍🦲", "👩‍🦲", "🧑‍🦲"],
  "blond-haired": ["👱‍♀️"],
  blonde: ["👱‍♀️"],
  "blond-haired man": ["👱‍♂️"],
  old: ["👴", "👵"],
  frowning: ["🙍‍♂️", "🙍‍♀️"],
  "gesturing no": ["🙅"],
  prohibited: ["🙅", "🙅‍♂️", "🙅‍♀️", "⛔️", "🚳", "🚭️", "🚯", "🚷", "🔞"],
  "man gesturing no": ["🙅‍♂️"],
  "woman gesturing no": ["🙅‍♀️"],
  "gesturing ok": ["🙆"],
  "man gesturing ok": ["🙆‍♂️"],
  "woman gesturing ok": ["🙆‍♀️"],
  help: ["🆘"],
  information: ["💁"],
  sassy: ["💁", "💁‍♂️", "💁‍♀️"],
  tipping: ["💁"],
  helicopter: ["🚁"],
  terrified: ["😱"],
  "tipping hand": ["💁‍♂️", "💁‍♀️"],
  happy: ["🙋"],
  "raising hand": ["🙋‍♂️", "🙋‍♀️"],
  deaf: ["🧏", "🧏‍♂️", "🧏‍♀️"],
  ear: ["🧏", "🌾", "🌽"],
  apology: ["🙇", "🙇‍♂️", "🙇‍♀️"],
  bow: ["🏹"],
  sorry: ["🙇", "🙇‍♂️", "🙇‍♀️"],
  favor: ["🙇‍♂️", "🙇‍♀️"],
  exasperation: ["🤦", "🤦‍♂️", "🤦‍♀️"],
  palm: ["🤦", "🌴", "🥥"],
  facepalm: ["🤦‍♂️", "🤦‍♀️"],
  doubt: ["🤷", "🤷‍♂️", "🤷‍♀️"],
  ignorance: ["🤷", "🤷‍♂️", "🤷‍♀️"],
  indifference: ["🤷", "🤷‍♂️", "🤷‍♀️"],
  shrug: ["🤷", "🤷‍♂️", "🤷‍♀️"],
  healthcare: ["🧑‍⚕️", "👨‍⚕️", "👩‍⚕️"],
  nurse: ["🧑‍⚕️", "👨‍⚕️", "👩‍⚕️"],
  therapist: ["🧑‍⚕️", "👨‍⚕️", "👩‍⚕️"],
  psychologist: ["🧑‍⚕️", "👨‍⚕️", "👩‍⚕️"],
  graduate: ["🧑‍🎓", "👨‍🎓", "👩‍🎓"],
  student: ["👨‍🎓", "👩‍🎓"],
  instructor: ["🧑‍🏫", "👨‍🏫", "👩‍🏫"],
  professor: ["🧑‍🏫", "👨‍🏫", "👩‍🏫"],
  teacher: ["👨‍🏫", "👩‍🏫"],
  justice: ["🧑‍⚖️", "👨‍⚖️", "👩‍⚖️", "⚖️", "♎️"],
  scales: ["🧑‍⚖️", "👨‍⚖️", "👩‍⚖️", "♎️"],
  judge: ["👨‍⚖️", "👩‍⚖️"],
  gardener: ["🧑‍🌾", "👨‍🌾", "👩‍🌾"],
  rancher: ["🧑‍🌾", "👨‍🌾", "👩‍🌾"],
  farmer: ["👨‍🌾", "👩‍🌾"],
  chef: ["🧑‍🍳", "👨‍🍳", "👩‍🍳"],
  cook: ["👨‍🍳", "👩‍🍳"],
  electrician: ["🧑‍🔧", "👨‍🔧", "👩‍🔧"],
  plumber: ["🧑‍🔧", "👨‍🔧", "👩‍🔧", "🪠"],
  tradesperson: ["🧑‍🔧", "👨‍🔧", "👩‍🔧"],
  mechanic: ["👨‍🔧", "👩‍🔧", "🧰"],
  assembly: ["🧑‍🏭", "👨‍🏭", "👩‍🏭"],
  factory: ["🧑‍🏭", "👨‍🏭", "👩‍🏭"],
  industrial: ["🧑‍🏭", "👨‍🏭", "👩‍🏭"],
  worker: ["🧑‍🏭", "👨‍🏭", "👩‍🏭", "👷", "👷‍♂️", "👷‍♀️"],
  architect: ["🧑‍💼", "👨‍💼", "👩‍💼"],
  business: ["🧑‍💼", "👨‍💼", "👩‍💼", "🕴️"],
  manager: ["🧑‍💼", "👨‍💼", "👩‍💼"],
  "white-collar": ["🧑‍💼", "👨‍💼", "👩‍💼"],
  biologist: ["🧑‍🔬", "👨‍🔬", "👩‍🔬", "🧫", "🧬"],
  chemist: ["🧑‍🔬", "👨‍🔬", "👩‍🔬", "🧪"],
  engineer: ["🧑‍🔬", "👨‍🔬", "👩‍🔬"],
  physicist: ["🧑‍🔬", "👨‍🔬", "👩‍🔬"],
  scientist: ["👨‍🔬", "👩‍🔬", "🥼"],
  coder: ["🧑‍💻", "👨‍💻", "👩‍💻"],
  developer: ["🧑‍💻", "👨‍💻", "👩‍💻"],
  inventor: ["🧑‍💻", "👨‍💻", "👩‍💻"],
  software: ["🧑‍💻", "👨‍💻", "👩‍💻"],
  technologist: ["👨‍💻", "👩‍💻"],
  actor: ["🧑‍🎤", "👨‍🎤", "👩‍🎤"],
  entertainer: ["🧑‍🎤", "👨‍🎤", "👩‍🎤"],
  rock: ["🧑‍🎤", "👨‍🎤", "👩‍🎤", "🥌"],
  singer: ["👨‍🎤", "👩‍🎤"],
  palette: ["🧑‍🎨", "👨‍🎨", "👩‍🎨", "🎨"],
  artist: ["👨‍🎨", "👩‍🎨"],
  plane: ["🧑‍✈️", "👨‍✈️", "👩‍✈️"],
  pilot: ["👨‍✈️", "👩‍✈️"],
  rocket: ["🧑‍🚀"],
  astronaut: ["👨‍🚀", "👩‍🚀"],
  firetruck: ["🧑‍🚒", "👨‍🚒", "👩‍🚒"],
  firefighter: ["👨‍🚒", "👩‍🚒"],
  cop: ["👮", "👮‍♂️", "👮‍♀️"],
  officer: ["👮", "👮‍♂️", "👮‍♀️"],
  police: ["👮", "👮‍♂️", "👮‍♀️", "🚓", "🚔️", "🚨"],
  sleuth: ["🕵️", "🕵️‍♂️", "🕵️‍♀️"],
  spy: ["🕵️", "🕵️‍♂️", "🕵️‍♀️"],
  detective: ["🕵️‍♂️", "🕵️‍♀️"],
  guard: ["💂", "💂‍♂️", "💂‍♀️"],
  fighter: ["🥷"],
  hidden: ["🥷"],
  stealth: ["🥷"],
  construction: ["👷", "👷‍♂️", "👷‍♀️", "🏗️"],
  monarch: ["🫅"],
  noble: ["🫅"],
  regal: ["🫅"],
  royalty: ["🫅"],
  prince: ["🤴"],
  turban: ["👳", "👳‍♂️", "👳‍♀️"],
  cap: ["👲", "🎓️"],
  "gua pi mao": ["👲"],
  skullcap: ["👲"],
  headscarf: ["🧕"],
  hijab: ["🧕"],
  mantilla: ["🧕"],
  tichel: ["🧕"],
  groom: ["🤵"],
  tuxedo: ["🤵", "🤵‍♂️", "🤵‍♀️"],
  bride: ["👰"],
  veil: ["👰", "👰‍♂️", "👰‍♀️"],
  wedding: ["👰"],
  pregnant: ["🤰", "🫃", "🫄"],
  belly: ["🫃", "🫄"],
  bloated: ["🫃", "🫄"],
  baby: ["🤱", "👩‍🍼", "👨‍🍼", "🧑‍🍼", "👼", "🐣", "🐤", "🐥", "🍼", "🚼️"],
  breast: ["🤱"],
  nursing: ["🤱", "👩‍🍼", "👨‍🍼", "🧑‍🍼"],
  feeding: ["👩‍🍼", "👨‍🍼", "🧑‍🍼"],
  christmas: ["🎅", "🤶", "🎄"],
  claus: ["🎅", "🤶"],
  father: ["🎅"],
  santa: ["🎅"],
  "santa claus": ["🎅"],
  mother: ["🤶"],
  "mrs.": ["🤶"],
  "mrs. claus": ["🤶"],
  "claus, christmas": ["🧑‍🎄"],
  hero: ["🦸", "🦸‍♂️"],
  heroine: ["🦸‍♀️"],
  superpower: ["🦸", "🦸‍♂️", "🦸‍♀️", "🦹", "🦹‍♂️", "🦹‍♀️"],
  criminal: ["🦹", "🦹‍♂️", "🦹‍♀️"],
  villain: ["🦹", "🦹‍♀️"],
  sorcerer: ["🧙", "🧙‍♂️"],
  sorceress: ["🧙", "🧙‍♀️"],
  witch: ["🧙", "🧙‍♀️", "🪄", "🧹"],
  wizard: ["🧙", "🧙‍♂️", "🪄"],
  oberon: ["🧚", "🧚‍♂️"],
  puck: ["🧚", "🧚‍♂️", "🏒"],
  titania: ["🧚", "🧚‍♀️"],
  dracula: ["🧛", "🧛‍♂️"],
  undead: ["🧛", "🧛‍♂️", "🧛‍♀️", "🧟", "🧟‍♂️", "🧟‍♀️"],
  mermaid: ["🧜"],
  merman: ["🧜"],
  merwoman: ["🧜", "🧜‍♀️"],
  triton: ["🧜‍♂️"],
  magical: ["🧝", "🧝‍♂️", "🧝‍♀️"],
  djinn: ["🧞", "🧞‍♂️", "🧞‍♀️"],
  "walking dead": ["🧟", "🧟‍♂️", "🧟‍♀️"],
  massage: ["💆", "💆‍♂️", "💆‍♀️"],
  salon: ["💆"],
  barber: ["💇", "💈"],
  beauty: ["💇"],
  haircut: ["💇", "💇‍♂️", "💇‍♀️", "💈"],
  parlor: ["💇"],
  hike: ["🚶", "🚶‍♂️", "🚶‍♀️"],
  walk: ["🚶", "🚶‍♂️", "🚶‍♀️"],
  walking: ["🚶"],
  stand: ["🧍"],
  standing: ["🧍", "🧍‍♂️", "🧍‍♀️"],
  kneel: ["🧎"],
  kneeling: ["🧎", "🧎‍♂️", "🧎‍♀️"],
  blind: ["🧑‍🦯", "👨‍🦯", "👩‍🦯", "🦮", "🦯"],
  wheelchair: ["🧑‍🦼", "👨‍🦼", "👩‍🦼", "🧑‍🦽", "👨‍🦽", "👩‍🦽"],
  marathon: ["🏃", "🏃‍♂️", "🏃‍♀️"],
  racing: ["🏃‍♂️", "🏃‍♀️", "🏇", "🐎", "🏎️", "🏍️", "🏁"],
  dance: ["💃", "🕺", "🩰"],
  dancing: ["💃", "🕺"],
  suit: ["🕴️"],
  "bunny ear": ["👯", "👯‍♂️", "👯‍♀️"],
  dancer: ["👯", "👯‍♂️", "👯‍♀️"],
  partying: ["👯", "👯‍♂️", "👯‍♀️"],
  men: ["🤼‍♂️", "👬"],
  women: ["🤼‍♀️", "👭"],
  sauna: ["🧖", "🧖‍♂️", "🧖‍♀️"],
  "steam room": ["🧖", "🧖‍♂️", "🧖‍♀️"],
  climber: ["🧗", "🧗‍♂️", "🧗‍♀️"],
  fencer: ["🤺"],
  fencing: ["🤺"],
  sword: ["🤺"],
  horse: ["🏇", "🐴", "🎠"],
  jockey: ["🏇"],
  racehorse: ["🏇", "🐎"],
  ski: ["⛷️", "🏂️", "🎿"],
  snow: [, "🏔️", "❄️", "☃️", "⛄️"],
  snowboard: ["🏂️"],
  ball: ["⚽️", "⚾️", "🥎", "🏀", "🏐", "🏈", "🏉", "🎾", "🏓", "🎱"],
  football: ["⚽️"],
  soccer: ["⚽️"],
  cricket: ["🏏"],
  "table tennis": ["🏓"],
  tennis: ["🎾"],
  bowling: ["🎳"],
  golf: ["🏌️", "🏌️‍♂️", "🏌️‍♀️", "⛳️"],
  surfing: ["🏄️", "🏄‍♂️", "🏄‍♀️"],
  boat: ["🚣", "🚣‍♂️", "🚣‍♀️", "⛵️", "🛶", "🚤", "⛴️", "🛥️", "🚢"],
  rowboat: ["🚣", "🚣‍♂️", "🚣‍♀️"],
  swim: ["🏊️", "🏊‍♂️", "🏊‍♀️", "👙"],
  lifter: ["🏋️"],
  weight: ["🏋️"],
  "weight lifter": ["🏋️‍♂️", "🏋️‍♀️"],
  bicycle: ["🚴", "🚴‍♂️", "🚴‍♀️", "🚵", "🚵‍♂️", "🚵‍♀️", "🚳"],
  biking: ["🚴", "🚴‍♂️", "🚴‍♀️", "🚵‍♀️"],
  cyclist: ["🚴", "🚴‍♂️", "🚴‍♀️", "🚵", "🚵‍♂️", "🚵‍♀️"],
  bicyclist: ["🚵"],
  bike: ["🚵", "🚵‍♂️", "🚵‍♀️", "🚲️", "🚳"],
  mountain: ["🚵", "🚵‍♂️", "🚵‍♀️", "🏔️", "⛰️", "🌋", "🗻", "🌄", "🚞", "🚠"],
  cartwheel: ["🤸", "🤸‍♂️", "🤸‍♀️"],
  gymnastics: ["🤸", "🤸‍♂️", "🤸‍♀️"],
  wrestle: ["🤼", "🤼‍♂️", "🤼‍♀️"],
  wrestler: ["🤼"],
  polo: ["🤽"],
  water: ["🌊", "🚰", "♒️"],
  "water polo": ["🤽‍♂️", "🤽‍♀️"],
  handball: ["🤾", "🤾‍♂️", "🤾‍♀️"],
  balance: ["⚖️"],
  juggle: ["🤹"],
  multitask: ["🤹"],
  skill: ["🤹"],
  juggling: ["🤹‍♂️"],
  meditation: ["🧘", "🧘‍♂️", "🧘‍♀️"],
  yoga: ["🧘", "🧘‍♂️", "🧘‍♀️"],
  bath: ["🛀", "🛁"],
  bathtub: ["🛀"],
  hotel: ["🛌", "🛏️", "🛋️"],
  couple: [
    "🧑‍🤝‍🧑",
    "👭",
    "👫",
    "👬",
    "💏",
    "👩‍❤️‍💋‍👨",
    "👨‍❤️‍💋‍👨",
    "👩‍❤️‍💋‍👩",
    "💑",
    "👩‍❤️‍👨",
    "👨‍❤️‍👨",
    "👩‍❤️‍👩",
  ],
  hold: ["🧑‍🤝‍🧑", "👫"],
  "holding hands": ["🧑‍🤝‍🧑", "👭", "👫", "👬"],
  gemini: ["👬", "♊️"],
  twins: ["👬", "♊️"],
  "couple with heart": ["👩‍❤️‍👨", "👨‍❤️‍👨", "👩‍❤️‍👩"],
  family: [
    "👪️",
    "👨‍👩‍👦",
    "👨‍👩‍👧",
    "👨‍👩‍👧‍👦",
    "👨‍👩‍👦‍👦",
    "👨‍👩‍👧‍👧",
    "👨‍👨‍👦",
    "👨‍👨‍👧",
    "👨‍👨‍👧‍👦",
    "👨‍👨‍👦‍👦",
    "👨‍👨‍👧‍👧",
    "👩‍👩‍👦",
    "👩‍👩‍👧",
    "👩‍👩‍👧‍👦",
    "👩‍👩‍👦‍👦",
  ],
  boy: ["👦"],
  girl: ["👧"],
  head: ["🗣️"],
  silhouette: ["🗣️", "👤", "👥"],
  speaking: ["🗣️"],
  talking: ["🗣️"],
  bust: ["👤", "👥"],
  goodbye: ["🫂"],
  hello: ["🫂"],
  clothing: [
    "👣",
    "🌂",
    "☂️",
    "☔️",
    "👔",
    "👕",
    "👖",
    "👗",
    "👘",
    "🥻",
    "👚",
    "👛",
    "👜",
  ],
  footprint: ["👣"],
  print: ["👣", "🐾"],
  ginger: ["🦰"],
  redhead: ["🦰"],
  afro: ["🦱"],
  curly: ["🦱"],
  ringlets: ["🦱"],
  gray: ["🦳"],
  chemotherapy: ["🦲"],
  hairless: ["🦲"],
  "no hair": ["🦲"],
  shaven: ["🦲"],
  gorilla: ["🦍"],
  ape: ["🦧"],
  dog: ["🐶", "🐕‍🦺", "🐩"],
  pet: ["🐶", "🐕️", "🐱", "🐈️", "🐹", "🐰", "🐇"],
  "guide dog": ["🦮"],
  "assistance dog": ["🐕‍🦺"],
  "service dog": ["🐕‍🦺"],
  curious: ["🦝"],
  sly: ["🦝"],
  unlucky: ["🐈‍⬛"],
  leo: ["🦁", "♌️"],
  tiger: ["🐯", "🐅"],
  leopard: ["🐆"],
  animal: ["🐆"],
  equestrian: ["🐎"],
  stripe: ["🦓"],
  deer: ["🦌"],
  buffalo: ["🦬", "🐃"],
  herd: ["🦬"],
  wisent: ["🦬"],
  cow: ["🐮", "🐄"],
  bull: ["🐂", "♉️"],
  taurus: ["🐂", "♉️"],
  pig: ["🐷", "🐗", "🐽"],
  sow: ["🐖"],
  aries: ["🐏", "♈️"],
  male: ["🐏"],
  sheep: ["🐏", "🐑"],
  female: ["🐑"],
  capricorn: ["🐐", "♑️"],
  dromedary: ["🐪"],
  hump: ["🐪", "🐫"],
  bactrian: ["🐫"],
  camel: ["🐫"],
  alpaca: ["🦙"],
  guanaco: ["🦙"],
  vicuña: ["🦙"],
  wool: ["🦙"],
  spots: ["🦒"],
  elephant: ["🐘"],
  extinction: ["🦣", "🦤"],
  tusk: ["🦣"],
  woolly: ["🦣"],
  rhinoceros: ["🦏"],
  hippo: ["🦛"],
  mouse: ["🐭", "🐁"],
  rat: ["🐀"],
  bunny: ["🐰", "🐇"],
  rabbit: ["🐰"],
  squirrel: ["🐿️"],
  dam: ["🦫"],
  spiny: ["🦔"],
  vampire: ["🦇"],
  arctic: ["🐻‍❄️"],
  bear: ["🐻‍❄️"],
  marsupial: ["🐨", "🦘"],
  lazy: ["🦥"],
  slow: ["🦥"],
  fishing: ["🦦"],
  playful: ["🦦"],
  stink: ["🦨"],
  australia: ["🦘", "🌏️"],
  joey: ["🦘"],
  jump: ["🦘"],
  "honey badger": ["🦡"],
  pester: ["🦡"],
  feet: ["🐾"],
  paw: ["🐾"],
  bird: ["🦃", "🐦️", "🐧", "🕊️", "🦅", "🦆", "🦢", "🦉", "🦚", "🦜"],
  hen: ["🐓"],
  penguin: ["🐧"],
  eagle: ["🦅"],
  duck: ["🦆"],
  swan: ["🦢"],
  owl: ["🦉"],
  parrot: ["🦜"],
  chick: ["🐣", "🐤", "🐥"],
  hatching: ["🐣"],
  fly: ["🕊️", "🪁", "💸"],
  peace: ["🕊️", "☮️"],
  cygnet: ["🦢"],
  "ugly duckling": ["🦆"],
  wise: ["🦉"],
  mauritius: ["🦤"],
  flight: ["✈️"],
  feather: ["🪶"],
  feathers: ["🪶"],
  light: ["🕯️", "💡"],
  lights: ["🕯️", "💡", "🔦"],
  "traffic light": ["🚥", "🚦"],
  plumage: ["🪶"],
  flamboyant: ["🦩"],
  tropical: ["🦩", "🍹"],
  ostentatious: ["🦚"],
  peahen: ["🦚"],
  pirate: ["🏴‍☠️"],
  talk: ["🦜"],
  crocodile: ["🐊"],
  terrapin: ["🐢"],
  tortoise: ["🐢"],
  reptile: ["🦎"],
  bearer: ["🐍", "♒️", "⛎"],
  ophiuchus: ["🐍", "⛎"],
  serpent: ["🐍", "⛎"],
  dragon: ["🐲"],
  brachiosaurus: ["🦕"],
  brontosaurus: ["🦕"],
  diplodocus: ["🦕"],
  "t-rex": ["🦖"],
  "tyrannosaurus rex": ["🦖"],
  spouting: ["🐳"],
  whale: ["🐳", "🐋"],
  flipper: ["🐬"],
  "sea lion": ["🦭"],
  pisces: ["🐟️"],
  fish: ["🐠", "🐡", "🦈"],
  octopus: ["🐙"],
  shell: ["🐚"],
  ocean: ["🌊"],
  reef: ["🪸"],
  snail: ["🐌"],
  insect: ["🐛", "🐜"],
  bee: ["🐝"],
  bug: ["🪲"],
  ladybug: ["🐞"],
  grasshopper: ["🦗"],
  pest: ["🪳", "🦟", "🪰"],
  roach: ["🪳"],
  spider: ["🕸️"],
  web: ["🕸️"],
  scorpio: ["🦂", "♏️"],
  disease: ["🦟", "🪰"],
  fever: ["🤒"],
  malaria: ["🦟"],
  virus: ["🦟", "🦠"],
  maggot: ["🪰"],
  rotting: ["🪰"],
  annelid: ["🪱"],
  earthworm: ["🪱"],
  parasite: ["🪱"],
  amoeba: ["🦠"],
  bacteria: ["🦠", "🧫"],
  flower: ["💐", "🌸", "💮", "🪷", "🌹", "🥀", "🌺", "🌻", "🌼", "🌷", "🎴"],
  blossom: ["🌸"],
  cherry: ["🌸", "🍒"],
  buddhism: ["🪷"],
  hinduism: ["🪷"],
  india: ["🪷"],
  purity: ["🪷"],
  vietnam: ["🪷"],
  plant: ["🏵️", "🪴", "🌵", "☘️", "🌰"],
  wilted: ["🥀"],
  house: ["🛖", "🏚️", "🏡"],
  nurturing: ["🪴"],
  tree: ["🌲", "🌳", "🌴", "🎄", "🎋"],
  deciduous: ["🌳"],
  shedding: ["🌳"],
  grain: ["🌾"],
  rice: ["🌾", "🍘", "🍙", "🍚", "🍛"],
  leaf: ["🌿", "🍀", "🍁", "🍂", "🍃", "🔰"],
  clover: ["🍀"],
  four: ["🍀"],
  "four-leaf clover": ["🍀"],
  falling: ["🍁", "🍂", "🌠"],
  maple: ["🍁"],
  blow: ["🌬️"],
  flutter: ["🍃"],
  wind: ["🍃", "🌬️", "🎐"],
  nesting: ["🪹", "🪺", "🪆"],
  fruit: [
    "🍇",
    "🍈",
    "🍉",
    "🍊",
    "🍋",
    "🍌",
    "🍍",
    "🥭",
    "🍎",
    "🍏",
    "🍐",
    "🍑",
    "🍒",
    "🍓",
    "🥝",
    "🍅",
    "🥑",
  ],
  grape: ["🍇"],
  watermelon: ["🍉"],
  mango: ["🍋"],
  banana: ["🍌"],
  pineapple: ["🍍"],
  guava: ["🍐"],
  tomato: ["🍅"],
  citrus: ["🍋"],
  apple: ["🍎", "🍏"],
  berries: ["🍒"],
  berry: ["🍓", "🫐"],
  strawberry: ["🍓"],
  bilberry: ["🫐"],
  blueberry: ["🫐"],
  food: [
    "🥝",
    "🫒",
    "🥑",
    "🥔",
    "🥕",
    "🥒",
    "🥜",
    "🫘",
    "🥐",
    "🥖",
    "🥞",
    "🥓",
    "🥙",
    "🥚",
    "🥘",
    "🥗",
    "🦐",
    "🦑",
  ],
  kiwi: ["🥝"],
  vegetable: ["🍅", "🍆", "🥔", "🥕", "🫑", "🥒", "🥜"],
  "piña colada": ["🥥"],
  aubergine: ["🍆"],
  carrot: ["🥕"],
  brinjal: ["🍆"],
  corn: ["🌽"],
  maize: ["🌽"],
  maze: ["🌽"],
  pepper: ["🌶️", "🫑"],
  capsicum: ["🫑"],
  pickle: ["🥒"],
  "bok choy": ["🥬"],
  cabbage: ["🥬"],
  kale: ["🥬"],
  lettuce: ["🥬"],
  "wild cabbage": ["🥦"],
  flavoring: ["🧄", "🧅"],
  toadstool: ["🍄"],
  nut: ["🥜", "🔩"],
  peanut: ["🥜"],
  kidney: ["🫘"],
  legume: ["🫘"],
  loaf: ["🍞"],
  bread: ["🥐", "🥖", "🥪"],
  breakfast: ["🥐", "🥯", "🥞", "🧇", "🥓", "🥚", "🍳", "🥣", "🍩"],
  french: ["🥐", "🥖", "🍟"],
  roll: ["🥐"],
  baguette: ["🥖"],
  arepa: ["🫓"],
  lavash: ["🫓"],
  naan: ["🫓"],
  pita: ["🫓"],
  twisted: ["🥨"],
  bakery: ["🥯", "🧁"],
  schmear: ["🥯"],
  crêpe: ["🥞"],
  hotcake: ["🥞"],
  pancake: ["🥞"],
  indecisive: ["🧇"],
  iron: ["🧇"],
  cheese: ["🧀", "🍕", "🫕"],
  bone: ["🦴"],
  meat: ["🍖", "🥓"],
  chicken: ["🍗"],
  drumstick: ["🍗"],
  leg: ["🦵"],
  poultry: ["🍗"],
  chop: ["🥩", "🪓"],
  lambchop: ["🥩"],
  porkchop: ["🥩"],
  steak: ["🥩"],
  burger: ["🍔"],
  fries: ["🍟"],
  slice: ["🍕", "🍰"],
  frankfurter: ["🌭"],
  hotdog: ["🌭"],
  sausage: ["🌭"],
  mexican: ["🌮", "🌯", "🫔"],
  wrap: ["🌯"],
  wrapped: ["🫔", "🎁"],
  falafel: ["🥙"],
  flatbread: ["🥙"],
  gyro: ["🥙"],
  kebab: ["🥙", "🍢"],
  stuffed: ["🥙", "🧸"],
  chickpea: ["🧆"],
  meatball: ["🧆"],
  egg: ["🍳"],
  frying: ["🍳"],
  pan: ["🍳", "🥘"],
  casserole: ["🥘"],
  paella: ["🥘"],
  shallow: ["🥘"],
  pot: ["🍲", "🫕", "🍯", "🫖"],
  stew: ["🍲"],
  chocolate: ["🫕", "🍫"],
  melted: ["🫕"],
  swiss: ["🫕"],
  cereal: ["🥣"],
  congee: ["🥣"],
  salad: ["🥗"],
  popcorn: ["🍿"],
  dairy: ["🧈"],
  condiment: ["🧂", "🫙"],
  shaker: ["🧂"],
  bento: ["🍱"],
  box: ["🍱", "🧃", "🎁", "📤️", "📥️", "📦️", "🗳️", "🗃️", "☑️"],
  cracker: ["🍘"],
  japanese: [
    "🍙",
    "🍡",
    "🏣",
    "🎋",
    "🎴",
    "🔰",
    "🈁",
    "🈂️",
    "🈷️",
    "🈶",
    "🈯️",
    "🉐",
  ],
  cooked: ["🍚"],
  curry: ["🍛"],
  bowl: ["🍜"],
  noodle: ["🍜"],
  ramen: ["🍜"],
  steaming: ["🍜", "☕️", "♨️"],
  pasta: ["🍝"],
  potato: ["🍠"],
  roasted: ["🍠"],
  sweet: ["🍨", "🍩", "🍪", "🎂", "🍰", "🧁", "🍫", "🍬", "🍭", "🍮", "🍯"],
  seafood: ["🍢", "🦞"],
  skewer: ["🍢", "🍡"],
  stick: ["🏑", "🏒", "🥍", "🩼"],
  sticky: ["🍡"],
  sushi: ["🍣"],
  fried: ["🍤"],
  prawn: ["🍤"],
  shrimp: ["🍤"],
  tempura: ["🍤"],
  cake: ["🍥", "🎂", "🍰"],
  pastry: ["🍥", "🎂", "🍰", "🥧"],
  swirl: ["🍥"],
  autumn: ["🥮"],
  festival: ["🥮", "🎎"],
  yuèbǐng: ["🥮"],
  dessert: ["🍦", "🍧", "🍨", "🍩", "🍪", "🎂", "🍰", "🍫", "🍬", "🍭", "🍮"],
  empanada: ["🥟"],
  gyōza: ["🥟"],
  jiaozi: ["🥟"],
  pierogi: ["🥟"],
  potsticker: ["🥟"],
  prophecy: ["🥠"],
  "oyster pail": ["🥡"],
  cancer: ["🦀", "♋️"],
  bisque: ["🦞"],
  claws: ["🦞"],
  shellfish: ["🦐"],
  molusc: ["🦑"],
  diving: ["🦪", "🤿"],
  pearl: ["🦪", "🧋"],
  cream: ["🍦", "🍨"],
  ice: ["🍦", "🍧", "🍨", "🏒", "⛸️"],
  icecream: ["🍦"],
  soft: ["🍦", "🔈️"],
  shaved: ["🍧"],
  donut: ["🍩"],
  birthday: ["🎂"],
  filling: ["🥧"],
  bar: [
    "🍫",
    "🍶",
    "🍾",
    "🍷",
    "🍸️",
    "🍹",
    "🍺",
    "🍻",
    "🏮",
    "📊",
    "🧼",
    "⏸️",
    "📶",
  ],
  candy: ["🍭"],
  pudding: ["🍮"],
  honey: ["🍯"],
  honeypot: ["🍯"],
  bottle: ["🍼", "🍶", "🍾"],
  drink: [
    "🍼",
    "🥛",
    "☕️",
    "🫖",
    "🍵",
    "🍶",
    "🍾",
    "🍷",
    "🍸️",
    "🍹",
    "🍺",
    "🍻",
    "🥂",
    "🫗",
    "🧉",
  ],
  milk: ["🍼", "🥛", "🧋"],
  glass: ["🥛", "🍷", "🍸️", "🔍️", "🔎"],
  beverage: ["☕️", "🍵", "🍶", "🍷", "🧃"],
  coffee: ["☕️"],
  tea: ["☕️", "🫖", "🍵", "🧋"],
  cup: ["🍵", "🍶"],
  teacup: ["🍵"],
  cork: ["🍾"],
  popping: ["🍾"],
  wine: ["🍷"],
  cocktail: ["🍸️"],
  beer: ["🍺", "🍻"],
  mug: ["🍺", "🍻"],
  clink: ["🍻", "🥂"],
  celebrate: ["🥂"],
  liquor: ["🥃"],
  shot: ["🥃", "💉"],
  tumbler: ["🥃"],
  whisky: ["🥃"],
  empty: ["🫗", "🫙"],
  spill: ["🫗"],
  juice: ["🥤", "🧃"],
  soda: ["🥤"],
  straw: ["🧃"],
  "ice cube": ["🧊"],
  iceberg: ["🧊"],
  hashi: ["🥢"],
  cooking: ["🍽️", "🍴", "🔪", "🏺"],
  fork: ["🍽️", "🍴"],
  knife: ["🍽️", "🍴", "🔪", "🗡️"],
  plate: ["🍽️"],
  cutlery: ["🍴"],
  tableware: ["🥄"],
  hocho: ["🔪"],
  tool: [
    "🔪",
    "⚓️",
    "🔥",
    "🔮",
    "🔍️",
    "🔎",
    "🔦",
    "✂️",
    "🔨",
    "⛏️",
    "⚒️",
    "🛠️",
    "🔫",
    "🪚",
    "🔧",
    "🪛",
    "🔩",
    "⚙️",
    "🗜️",
    "🧰",
    "⚗️",
    "🔬",
    "🔭",
    "🔱",
  ],
  weapon: ["🔪", "🗡️", "⚔️", "🔫", "🛡️"],
  container: ["🫙"],
  sauce: ["🫙"],
  store: ["🫙", "🏪", "🏬"],
  aquarius: ["🏺", "♒️"],
  jug: ["🏺"],
  africa: ["🌍️"],
  earth: ["🌍️", "🌎️", "🌏️", "🌐"],
  europe: ["🌍️"],
  globe: ["🌍️", "🌎️", "🌏️", "🌐"],
  "globe showing europe-africa": ["🌍️"],
  world: ["🌍️", "🌎️", "🌏️", "🌐", "🗺️"],
  americas: ["🌎️"],
  "globe showing americas": ["🌎️"],
  asia: ["🌏️"],
  "globe showing asia-australia": ["🌏️"],
  meridians: ["🌐"],
  map: ["🗺️", "🗾"],
  magnetic: ["🧭", "🧲"],
  navigation: ["🧭"],
  orienteering: ["🧭"],
  eruption: ["🌋"],
  fuji: ["🗻"],
  camping: ["🏕️", "⛺️", "🥾"],
  beach: ["🏖️"],
  umbrella: ["🏖️", "🌂", "☔️", "⛱️"],
  desert: ["🏜️", "🏝️"],
  island: ["🏝️"],
  park: ["🏞️"],
  stadium: ["🏟️"],
  classical: ["🏛️"],
  bricks: ["🧱"],
  clay: ["🧱"],
  mortar: ["🧱"],
  wall: ["🧱"],
  boulder: ["🪨"],
  heavy: ["🪨"],
  solid: ["🪨"],
  stone: ["🪨"],
  log: ["🪵"],
  lumber: ["🪵", "🪚"],
  timber: ["🪵"],
  roundhouse: ["🛖"],
  yurt: ["🛖"],
  houses: ["🏘️"],
  derelict: ["🏚️"],
  home: ["🏠️", "🏡"],
  garden: ["🏡"],
  building: ["🏢", "🏦", "🏨", "🏫", "🏭️"],
  "japanese post office": ["🏣"],
  post: ["🏣", "🏤", "📯", "🚩"],
  "post office": ["🏣", "🏤", "📯", "🚩"],
  european: ["🏤", "🏰"],
  medicine: ["🏥", "💉", "🩸", "💊", "🩺", "⚕️"],
  convenience: ["🏪"],
  department: ["🏬"],
  castle: ["🏯"],
  chapel: ["💒"],
  romance: ["💒"],
  tokyo: ["🗼"],
  tower: ["🗼"],
  liberty: ["🗽"],
  statue: ["🗽", "🗿"],
  "statue of liberty": ["🗽"],
  hindu: ["🛕", "🕉️"],
  temple: ["🛕", "🕍"],
  christian: ["⛪️", "✝️", "☦️"],
  religion: [
    "⛪️",
    "🕌",
    "🕍",
    "⛩️",
    "🕋",
    "📿",
    "🛐",
    "🕉️",
    "✡️",
    "☸️",
    "☯️",
    "✝️",
    "☦️",
    "☪️",
    "🕎",
  ],
  islam: ["🕌", "🕋", "☪️"],
  muslim: ["🕌", "🕋", "☪️"],
  jew: ["🕍", "✡️"],
  jewish: ["🕍", "✡️"],
  shinto: ["⛩️"],
  shrine: ["⛩️"],
  fountain: ["⛲️", "🖋️"],
  fog: ["🌁"],
  night: ["🌃", "🌉"],
  city: ["🏙️", "🌆"],
  morning: ["🌄", "🌅"],
  sunrise: ["🌄"],
  dusk: ["🌆", "🌇"],
  evening: ["🌆"],
  landscape: ["🌆"],
  sunset: ["🌆"],
  bridge: ["🌉"],
  hotsprings: ["♨️"],
  springs: ["♨️"],
  carousel: ["🎠"],
  "amusement park": ["🛝", "🎡", "🎢"],
  play: ["🛝", "▶️", "⏯️"],
  ferris: ["🎡"],
  "ferris wheel": ["🎡"],
  wheel: ["🎡", "☸️"],
  coaster: ["🎢"],
  roller: ["🎢", "🛼"],
  pole: ["💈", "🎣"],
  circus: ["🎪"],
  tent: ["🎪"],
  engine: ["🚂", "🚒"],
  railway: ["🚂", "🚃", "🚄", "🚅", "🚆", "🚈", "🚉", "🚞", "🛤️", "🚟"],
  steam: ["🚂"],
  train: ["🚂", "🚃", "🚄", "🚅", "🚉", "🛤️"],
  car: ["🚃", "🚞", "🚋", "🚓", "🚔️", "🚗", "🚘️", "🏎️", "🚨", "🚡"],
  electric: ["🚃", "⚡️", "🔌", "💡", "🔦"],
  tram: ["🚃", "🚋", "🚎"],
  trolleybus: ["🚃", "🚊", "🚋"],
  shinkansen: ["🚄", "🚅"],
  speed: ["🚄", "🚅"],
  bullet: ["🚅"],
  subway: ["🚇️"],
  vehicle: ["🚝", "🚌", "🚑️", "🚕", "🚜", "🚁"],
  bus: ["🚍️", "🚎", "🚐", "🚏"],
  oncoming: ["🚍️", "🚔️", "🚖", "🚘️"],
  trolley: ["🚎", "🛒"],
  fire: ["🔥"],
  truck: ["🚒", "🛻", "🚚", "🚛"],
  patrol: ["🚓"],
  taxi: ["🚖"],
  automobile: ["🚘️"],
  recreational: ["🚙"],
  "sport utility": ["🚙"],
  "pick-up": ["🛻"],
  pickup: ["🛻"],
  delivery: ["🚚"],
  lorry: ["🚛"],
  semi: ["🚛"],
  motor: ["🛵"],
  scooter: ["🛵", "🛴"],
  "tuk tuk": ["🛺"],
  board: ["🛹"],
  skate: ["🛼", "⛸️"],
  stop: ["🛑"],
  highway: ["🛣️"],
  road: ["🛣️"],
  drum: ["🛢️", "🪘"],
  oil: ["🛢️", "🪔"],
  diesel: ["⛽️"],
  fuel: ["⛽️"],
  fuelpump: ["⛽️"],
  gas: ["⛽️"],
  pump: ["⛽️"],
  station: ["⛽️"],
  circle: [
    "🛞",
    "⏺️",
    "⭕️",
    "Ⓜ️",
    "🔴",
    "🟠",
    "🟡",
    "🟢",
    "🔵",
    "🟣",
    "🟤",
    "⚫️",
    "⚪️",
  ],
  tire: ["🛞"],
  turn: ["🛞"],
  beacon: ["🚨"],
  signal: ["🚥", "🚦"],
  traffic: ["🚥", "🚦", "🚸", "⛔️"],
  octagonal: ["🛑"],
  sign: ["🛑", "🪧", "☢️", "☣️", "✖️", "➕", "➖", "➗"],
  barrier: ["🚧"],
  ship: ["🛳️"],
  float: ["🛟"],
  "life preserver": ["🛟"],
  "life saver": ["🛟"],
  rescue: ["🛟"],
  safety: ["🛟", "🦺"],
  resort: ["⛵️"],
  sea: ["⛵️"],
  yacht: ["⛵️"],
  passenger: ["🛳️", "⛴️", "🚢"],
  motorboat: ["🛥️"],
  aeroplane: ["✈️", "🛩️", "🛫", "🛬"],
  airplane: ["🛩️", "🛫", "🛬"],
  "check-in": ["🛫"],
  departure: ["🛫"],
  departures: ["🛫"],
  arrivals: ["🛬"],
  arriving: ["🛬"],
  landing: ["🛬"],
  "hang-glide": ["🪂"],
  parasail: ["🪂"],
  skydive: ["🪂"],
  chair: ["💺"],
  suspension: ["🚟"],
  cable: ["🚠", "🚡"],
  gondola: ["🚠", "🚡"],
  aerial: ["🚡"],
  tramway: ["🚡"],
  space: ["🛰️", "🚀", "🌌", "☄️"],
  bell: ["🛎️", "🎐", "🔔", "🔕"],
  bellhop: ["🛎️"],
  packing: ["🧳"],
  travel: ["🧳"],
  sand: ["⌛️", "⏳️"],
  timer: ["⌛️", "⏳️", "⏲️"],
  hourglass: ["⏳️"],
  clock: [
    "⌚️",
    "⏰",
    "⏱️",
    "⏲️",
    "🕰️",
    "🕛️",
    "🕧️",
    "🕐️",
    "🕜️",
    "🕑️",
    "🕝️",
    "🕒️",
    "🕞️",
    "🕓️",
    "🕟️",
    "🕔️",
    "🕠️",
    "🕕️",
    "🕡️",
    "🕖️",
    "🕢️",
    "🕗️",
    "🕣️",
    "🕘️",
    "🕤️",
    "🕙️",
    "🕥️",
    "🕚️",
    "🕦️",
  ],
  alarm: ["⏰"],
  "12:00": ["🕛️"],
  "o’clock": [
    "🕛️",
    "🕐️",
    "🕑️",
    "🕒️",
    "🕓️",
    "🕔️",
    "🕕️",
    "🕖️",
    "🕗️",
    "🕘️",
    "🕙️",
    "🕚️",
  ],
  twelve: ["🕛️", "🕧️"],
  "12:30": ["🕧️"],
  thirty: [
    "🕧️",
    "🕜️",
    "🕝️",
    "🕞️",
    "🕟️",
    "🕠️",
    "🕡️",
    "🕢️",
    "🕣️",
    "🕤️",
    "🕥️",
    "🕦️",
  ],
  "1:00": ["🕐️"],
  "1:30": ["🕜️"],
  "2:00": ["🕑️"],
  "2:30": ["🕝️"],
  "3:00": ["🕒️"],
  "3:30": ["🕞️"],
  "4:00": ["🕓️"],
  "4:30": ["🕟️"],
  "5:00": ["🕔️"],
  "5:30": ["🕠️"],
  "6:00": ["🕕️"],
  "6:30": ["🕡️"],
  "7:00": ["🕖️"],
  "7:30": ["🕢️"],
  "8:00": ["🕗️"],
  "8:30": ["🕣️"],
  "9:00": ["🕘️"],
  "9:30": ["🕤️"],
  "10:00": ["🕙️"],
  "10:30": ["🕥️"],
  "11:00": ["🕚️"],
  "11:30": ["🕦️"],
  dark: ["🌑", "🕶️"],
  moon: [
    "🌑",
    "🌒",
    "🌓",
    "🌔",
    "🌕️",
    "🌖",
    "🌗",
    "🌘",
    "🌙",
    "🌚",
    "🌛",
    "🌜️",
    "🌝",
  ],
  crescent: ["🌒", "🌘", "🌙"],
  waxing: ["🌒", "🌔"],
  "quarter moon": ["🌓", "🌗", "🌛", "🌜️"],
  gibbous: ["🌔", "🌖"],
  waning: ["🌖", "🌘"],
  weather: ["🌡️", "☁️"],
  rays: ["☀️"],
  saturn: ["🪐"],
  saturnine: ["🪐"],
  glittery: ["🌟"],
  glow: ["🌟"],
  shining: ["🌟"],
  "shooting star": ["🌠"],
  stars: ["🌠"],
  cloud: ["⛅️", "🌤️", "🌥️", "🌦️", "🌧️", "🌨️", "🌩️"],
  rain: ["⛈️", "🌧️", "🌈", "🌂", "☂️", "☔️", "⛱️"],
  rainy: ["⛈️", "🌦️", "🌧️", "🌈", "🌂", "☂️", "☔️", "⛱️"],
  thunder: ["⛈️"],
  lightning: ["🌩️", "⚡️"],
  whirlwind: ["🌪️"],
  hurricane: ["🌀"],
  twister: ["🌀"],
  typhoon: ["🌀"],
  danger: ["⚡️"],
  voltage: ["⚡️"],
  zap: ["⚡️"],
  snowman: ["⛄️"],
  flame: ["🔥"],
  halloween: ["🎃"],
  jack: ["🎃"],
  lantern: ["🎃", "🏮"],
  fireworks: ["🎇", "🧨"],
  dynamite: ["🧨"],
  explosive: ["🧨"],
  "*": ["✨", "✳️", "✴️", "❇️"],
  popper: ["🎉"],
  tada: ["🎉"],
  confetti: ["🎊"],
  banner: ["🎋"],
  bamboo: ["🎍"],
  pine: ["🎍"],
  doll: ["🎎", "🪆"],
  "japanese dolls": ["🎎"],
  carp: ["🎏"],
  streamer: ["🎏"],
  chime: ["🎐"],
  ceremony: ["🎑"],
  gift: ["🧧", "🎁"],
  "good luck": ["🧧"],
  hóngbāo: ["🧧"],
  "lai see": ["🧧"],
  present: ["🎁"],
  reminder: ["🎗️"],
  admission: ["🎟️", "🎫"],
  ticket: ["🎟️"],
  medal: ["🎖️", "🏅", "🥇", "🥈", "🥉"],
  military: ["🎖️", "🪖"],
  prize: ["🏆️"],
  first: ["🥇"],
  gold: ["🥇", "🪙"],
  second: ["🥈"],
  silver: ["🥈", "🪙"],
  bronze: ["🥉"],
  third: ["🥉"],
  glove: ["🥊"],
  underarm: ["🥎"],
  hoop: ["🏀"],
  game: [
    "🏐",
    "🎳",
    "🏏",
    "🏑",
    "🏒",
    "🏓",
    "🏸",
    "🥌",
    "🎯",
    "🎱",
    "🎮️",
    "🕹️",
    "🎰",
    "🎲",
    "♠️",
    "♥️",
    "♦️",
    "♣️",
    "🃏",
    "🀄️",
    "🎴",
  ],
  "american rugby": ["🏈"],
  rugby: ["🏉"],
  racquet: ["🏸"],
  ultimate: ["🥏"],
  "bat ball": ["🏏"],
  field: ["🏑"],
  hockey: ["🏑", "🏒"],
  goal: ["🥍", "🥅"],
  paddle: ["🏓"],
  birdie: ["🏸"],
  shuttlecock: ["🏸"],
  boxing: ["🥊"],
  judo: ["🥋"],
  karate: ["🥋"],
  "martial arts": ["🥋"],
  taekwondo: ["🥋"],
  uniform: ["🥋"],
  net: ["🥅"],
  scuba: ["🤿"],
  snorkeling: ["🤿"],
  athletics: ["🎽"],
  sash: ["🎽"],
  shirt: ["🎽", "👕"],
  sledge: ["🛷"],
  sleigh: ["🛷"],
  dart: ["🎯"],
  "direct hit": ["🎯"],
  target: ["🎯"],
  hit: ["🎯"],
  fluctuate: ["🪀"],
  toy: ["🪀", "🧸"],
  soar: ["🪁"],
  billiard: ["🎱"],
  crystal: ["🔮"],
  fortune: ["🔮", "🔯"],
  magic: ["🪄"],
  bead: ["🧿"],
  charm: ["🧿"],
  "evil-eye": ["🧿"],
  nazar: ["🧿"],
  talisman: ["🧿"],
  amulet: ["🪬"],
  fatima: ["🪬"],
  mary: ["🪬"],
  miriam: ["🪬"],
  protection: ["🪬"],
  controller: ["🎮️"],
  "video game": ["🕹️"],
  slot: ["🎰"],
  dice: ["🎲"],
  die: ["🎲"],
  clue: ["🧩", "🗝️"],
  interlocking: ["🧩"],
  jigsaw: ["🧩"],
  piece: ["🧩"],
  puzzle: ["🧩"],
  plaything: ["🧸"],
  plush: ["🧸"],
  disco: ["🪩"],
  glitter: ["🪩"],
  russia: ["🪆"],
  card: ["🃏", "💳️", "🗂️", "📇", "🗃️"],
  chess: ["♟️"],
  dupe: ["♟️"],
  expendable: ["♟️"],
  wildcard: ["🃏"],
  mahjong: ["🀄️"],
  playing: ["🎴"],
  art: ["🎭️", "🖼️", "🎨"],
  performing: ["🎭️"],
  theater: ["🎭️"],
  theatre: ["🎭️"],
  frame: ["🖼️", "🪟"],
  museum: ["🖼️", "🎨"],
  painting: ["🖼️", "🎨", "🖌️"],
  picture: ["🖼️"],
  needle: ["🧵", "🪡", "💉"],
  sewing: ["🧵", "🪡"],
  spool: ["🧵"],
  string: ["🧵"],
  embroidery: ["🪡"],
  stitches: ["🪡"],
  sutures: ["🪡"],
  tailoring: ["🪡"],
  crochet: ["🧶"],
  knit: ["🧶"],
  rope: ["🪢"],
  tangled: ["🪢"],
  tie: ["🪢", "👔"],
  twine: ["🪢"],
  twist: ["🪢"],
  eyeglasses: ["👓️"],
  eyewear: ["👓️", "🕶️"],
  "eye protection": ["🥽"],
  swimming: ["🥽"],
  welding: ["🥽"],
  experiment: ["🥼", "🧪"],
  emergency: ["🦺"],
  vest: ["🦺"],
  tshirt: ["👕"],
  pants: ["👖", "🩳"],
  trousers: ["👖"],
  neck: ["🧣"],
  jacket: ["🧥"],
  stocking: ["🧦"],
  dress: ["🥻"],
  "bathing suit": ["🩱", "🩲", "🩳"],
  "one-piece": ["🩲"],
  swimsuit: ["🩲"],
  underwear: ["🩲", "🩳"],
  coin: ["👛"],
  bag: ["👜", "👝", "🛍️", "🎒", "💰️"],
  purse: ["👜"],
  pouch: ["👝"],
  shopping: ["🛍️", "🛒"],
  rucksack: ["🎒"],
  satchel: ["🎒"],
  school: ["🎒"],
  "beach sandals": ["🩴"],
  sandals: ["🩴"],
  "thong sandals": ["🩴"],
  thongs: ["🩴"],
  zōri: ["🩴"],
  shoe: ["👞", "👟", "👠", "👡", "👢"],
  athletic: ["👟"],
  sneaker: ["👟"],
  backpacking: ["🥾"],
  boot: ["🥾", "👢"],
  hiking: ["🥾"],
  "ballet flat": ["🥿"],
  "slip-on": ["🥿"],
  slipper: ["🥿"],
  heel: ["👠"],
  sandal: ["👡"],
  ballet: ["🩰"],
  king: ["👑"],
  queen: ["👑"],
  top: ["🎩", "🔝"],
  tophat: ["🎩"],
  graduation: ["🎓️"],
  "baseball cap": ["🧢"],
  army: ["🪖"],
  helmet: ["🪖", "⛑️"],
  soldier: ["🪖"],
  warrior: ["🪖"],
  aid: ["⛑️"],
  beads: ["📿"],
  necklace: ["📿"],
  makeup: ["💄"],
  diamond: ["💍", "💎", "🔶", "🔷", "🔸", "🔹", "💠"],
  gem: ["💎"],
  jewel: ["💎"],
  mute: ["🔇", "🔕"],
  speaker: ["🎙️"],
  medium: ["🔉"],
  loud: ["🔊", "📢"],
  "public address": ["📢"],
  cheering: ["📣"],
  postal: ["📯"],
  music: [
    "🎼",
    "🎵",
    "🎶",
    "🎙️",
    "🎚️",
    "🎛️",
    "🎷",
    "🎸",
    "🎹",
    "🎺",
    "🎻",
    "🪕",
    "🥁",
  ],
  note: ["🎵", "🎶", "💴", "💵", "💶", "💷", "🗒️"],
  notes: ["🎶"],
  mic: ["🎙️", "🎤"],
  microphone: ["🎙️"],
  studio: ["🎙️"],
  level: ["🎚️"],
  slider: ["🎚️"],
  control: ["🎛️", "🛂"],
  knobs: ["🎛️"],
  karaoke: ["🎤"],
  earbud: ["🎧️"],
  video: ["📻️", "📽️", "📺️", "📷️", "📸", "📹️", "📼"],
  instrument: ["🎷", "🎸", "🎹", "🎺", "🎻"],
  sax: ["🎷"],
  concertina: ["🪗"],
  "squeeze box": ["🪗"],
  keyboard: ["🎹"],
  piano: ["🎹"],
  stringed: ["🪕"],
  drumsticks: ["🥁"],
  beat: ["🪘"],
  conga: ["🪘"],
  rhythm: ["🪘"],
  cell: ["📱", "📲", "📵", "📶", "📳", "📴"],
  mobile: ["📱", "📲", "📵", "📶", "📳", "📴"],
  telephone: ["📱", "📞", "📳", "📴"],
  receive: ["📲", "📨", "📥️"],
  receiver: ["📞"],
  pager: ["📟️"],
  fax: ["📠"],
  battery: ["🔋"],
  electronic: ["🪫"],
  "low energy": ["🪫"],
  electricity: ["🔌"],
  plug: ["🔌"],
  computer: ["💻️", "🖥️", "🖨️", "⌨️", "🖱️", "🖲️", "💽", "💾", "💿️", "📀"],
  pc: ["💻️"],
  desktop: ["🖥️"],
  disk: ["💽", "💾", "💿️", "📀"],
  minidisk: ["💽"],
  optical: ["💽", "💿️", "📀"],
  floppy: ["💾"],
  cd: ["💿️"],
  "blu-ray": ["📀"],
  calculation: ["🧮"],
  cinema: ["🎥", "🎞️", "📽️"],
  movie: ["🎥", "🎞️", "📽️", "🎬️", "🎦"],
  film: ["🎞️", "📽️", "🎦"],
  frames: ["🎞️"],
  projector: ["📽️"],
  clapper: ["🎬️"],
  tv: ["📺️"],
  flash: ["📸"],
  tape: ["📼"],
  vhs: ["📼"],
  magnifying: ["🔍️", "🔎"],
  search: ["🔍️", "🔎"],
  bulb: ["💡"],
  idea: ["💡"],
  torch: ["🔦"],
  diya: ["🪔"],
  lamp: ["🪔", "🛋️"],
  book: ["📔", "📕", "📖", "📗", "📘", "📙", "📚️"],
  cover: ["📔"],
  decorated: ["📔"],
  notebook: ["📔", "📓", "📒"],
  curl: ["📃", "➰", "➿"],
  document: ["📃", "📄"],
  page: ["📃", "📄"],
  paper: ["📜", "📰", "🗞️"],
  news: ["📰", "🗞️"],
  newspaper: ["🗞️"],
  rolled: ["🗞️"],
  bookmark: ["📑"],
  marker: ["📑"],
  tabs: ["📑"],
  label: ["🏷️"],
  dollar: ["💰️", "💵", "💲"],
  moneybag: ["💰️"],
  metal: ["🪙"],
  treasure: ["🪙", "🏴‍☠️"],
  banknote: ["💴", "💵", "💶", "💷", "💸"],
  bill: ["💴", "💵", "💶", "💷", "💸"],
  currency: ["💴", "💵", "💶", "💷", "💱", "💲"],
  yen: ["💴", "💹"],
  euro: ["💶"],
  pound: ["💷"],
  wings: ["💸"],
  credit: ["💳️"],
  "credit card": ["💳️"],
  accounting: ["🧾"],
  bookkeeping: ["🧾"],
  evidence: ["🧾"],
  proof: ["🧾"],
  chart: ["💹", "📈", "📉", "📊"],
  graph: ["💹", "📈", "📉", "📊"],
  growth: ["💹", "📈"],
  email: ["✉️", "📧", "📨", "📩"],
  "e-mail": ["📨", "📩"],
  envelope: ["📨", "📩"],
  "incoming messages": ["📨"],
  "outgoing messages": ["📩"],
  outbox: ["📤️"],
  "sent message": ["📤️"],
  tray: ["📤️", "📥️"],
  inbox: ["📥️"],
  parcel: ["📦️"],
  mailbox: ["📫️", "📪️", "📬️", "📭️", "📮"],
  postbox: ["📫️", "📪️", "📬️", "📭️"],
  lowered: ["📪️", "📭️"],
  ballot: ["🗳️"],
  pencil: ["✏️", "📝"],
  nib: ["✒️", "🔏"],
  pen: ["✒️", "🖋️", "🔏"],
  ballpoint: ["🖊️"],
  crayon: ["🖍️"],
  briefcase: ["💼"],
  file: ["📁", "📂", "🗃️", "🗄️"],
  folder: ["📁", "📂"],
  dividers: ["🗂️"],
  date: ["📅"],
  calendar: ["📆", "🗓️"],
  notepad: ["🗒️"],
  rolodex: ["📇"],
  trend: ["📈", "📉"],
  upward: ["📈"],
  clipboard: ["📋️"],
  pin: ["📌", "📍"],
  pushpin: ["📍"],
  paperclip: ["📎", "🖇️"],
  link: ["🖇️", "🔗"],
  ruler: ["📏", "📐"],
  "straight edge": ["📏"],
  set: ["📐"],
  triangle: ["📐", "▶️", "⏭️", "⏯️", "◀️", "⏮️"],
  cutting: ["✂️"],
  cabinet: ["🗄️"],
  filing: ["🗄️"],
  wastebasket: ["🗑️"],
  lock: ["🔓️", "🔏", "🔐", "🔑", "🗝️"],
  unlock: ["🔓️"],
  ink: ["🔏"],
  privacy: ["🔏"],
  key: ["🔐", "🗝️"],
  secure: ["🔐"],
  password: ["🔑"],
  hatchet: ["🪓"],
  split: ["🪓"],
  wood: ["🪓"],
  mining: ["⛏️"],
  hammer: ["⚒️", "🛠️"],
  pick: ["⚒️"],
  spanner: ["🛠️", "🔧"],
  wrench: ["🛠️"],
  crossed: ["⚔️", "🔀", "🎌"],
  swords: ["⚔️"],
  gun: ["🔫"],
  handgun: ["🔫"],
  pistol: ["🔫"],
  revolver: ["🔫"],
  rebound: ["🪃"],
  repercussion: ["🪃"],
  archer: ["🏹", "♐️"],
  sagittarius: ["🏹", "♐️"],
  carpenter: ["🪚"],
  saw: ["🪚"],
  screw: ["🪛"],
  bolt: ["🔩"],
  cog: ["⚙️"],
  cogwheel: ["⚙️"],
  compress: ["🗜️"],
  vice: ["🗜️"],
  libra: ["⚖️", "♎️"],
  scale: ["⚖️"],
  chain: ["⛓️"],
  curve: ["🪝"],
  ensnare: ["🪝"],
  "selling point": ["🪝"],
  chest: ["🧰"],
  attraction: ["🧲"],
  horseshoe: ["🧲"],
  butterfly: ["🦋"],
  ant: ["🐜"],
  climb: ["🪜"],
  rung: ["🪜"],
  step: ["🪜"],
  chemistry: ["⚗️", "🧪"],
  lab: ["🧪", "🧫"],
  science: ["🧪", "🔬", "🔭"],
  biology: ["🧫"],
  culture: ["🧫"],
  evolution: ["🧬"],
  gene: ["🧬"],
  genetics: ["🧬"],
  life: ["🧬"],
  antenna: ["📡", "📶"],
  dish: ["📡"],
  satellite: ["📡"],
  bleed: ["🩸"],
  "blood donation": ["🩸"],
  menstruation: ["🩸"],
  cane: ["🩼"],
  disability: ["🩼"],
  "mobility aid": ["🩼"],
  bones: ["🩻"],
  medical: ["🩻"],
  door: ["🚪"],
  doors: ["🚪"],
  hoist: ["🛗"],
  lift: ["🛗"],
  reflection: ["🪞"],
  reflector: ["🪞"],
  speculum: ["🪞"],
  "fresh air": ["🪟"],
  opening: ["🪟"],
  transparent: ["🪟"],
  view: ["🪟"],
  couch: ["🛋️"],
  seat: ["🪑"],
  sit: ["🪑"],
  toilet: ["🚽", "🪠", "🚹️", "🚺️", "🚻", "🚾"],
  "force cup": ["🪠"],
  suction: ["🪠"],
  bait: ["🪤"],
  mousetrap: ["🪤"],
  snare: ["🪤"],
  trap: ["🪤"],
  sharp: ["🪒"],
  shave: ["🪒"],
  lotion: ["🧴"],
  moisturizer: ["🧴"],
  shampoo: ["🧴"],
  sunscreen: ["🧴"],
  diaper: ["🧷"],
  "punk rock": ["🧷"],
  cleaning: ["🧹", "🧼", "🧽"],
  sweeping: ["🧹"],
  farming: ["🧺"],
  laundry: ["🧺"],
  picnic: ["🧺"],
  "paper towels": ["🧻"],
  "toilet paper": ["🧻"],
  cask: ["🪣"],
  pail: ["🪣"],
  vat: ["🪣"],
  bathing: ["🧼"],
  lather: ["🧼"],
  soapdish: ["🧼"],
  burp: ["🫧"],
  clean: ["🫧", "🪥"],
  soap: ["🫧"],
  underwater: ["🫧"],
  bathroom: ["🪥", "🚹️", "🚺️", "🚻", "🚾"],
  brush: ["🪥"],
  dental: ["🪥"],
  hygiene: ["🪥"],
  teeth: ["🪥"],
  absorbing: ["🧽"],
  porous: ["🧽"],
  extinguish: ["🧯"],
  quench: ["🧯"],
  cart: ["🛒"],
  smoking: ["🚬", "🚭️"],
  cemetery: ["🪦"],
  grave: ["🪦"],
  graveyard: ["🪦"],
  tombstone: ["🪦"],
  ashes: ["⚱️"],
  funeral: ["⚱️"],
  urn: ["⚱️"],
  moyai: ["🗿"],
  demonstration: ["🪧"],
  picket: ["🪧"],
  protest: ["🪧"],
  credentials: ["🪪"],
  id: ["🪪", "🆔"],
  license: ["🪪"],
  security: ["🪪"],
  atm: ["🏧"],
  "atm sign": ["🏧"],
  automated: ["🏧"],
  bank: ["🏧", "💱"],
  teller: ["🏧"],
  litter: ["🚮", "🚯"],
  "litter bin": ["🚮"],
  drinking: ["🚰"],
  potable: ["🚰"],
  access: ["♿️"],
  lavatory: ["🚹️", "🚺️", "🚻", "🚾"],
  restroom: ["🚹️", "🚺️", "🚾"],
  wc: ["🚹️", "🚺️", "🚻", "🚾"],
  changing: ["🚼️"],
  closet: ["🚾"],
  passport: ["🛂"],
  customs: ["🛃"],
  baggage: ["🛄", "🛅"],
  claim: ["🛄"],
  locker: ["🛅"],
  luggage: ["🛅"],
  warning: ["⚠️"],
  child: ["🧒"],
  "child crossing": ["🚸"],
  "road crossing": ["🚸"],
  pedestrian: ["🚸", "🚷"],
  entry: ["⛔️", "🚫"],
  "non-drinking": ["🚱"],
  "non-potable": ["🚱"],
  "age restriction": ["🔞"],
  eighteen: ["🔞"],
  underage: ["🔞"],
  cardinal: ["⬆️", "➡️", "⬇️", "⬅️"],
  direction: ["⬆️", "↗️", "➡️", "↘️", "⬇️", "↙️", "⬅️", "↖️"],
  north: ["⬆️"],
  intercardinal: ["↗️", "↘️", "↙️", "↖️"],
  northeast: ["↗️"],
  east: ["➡️"],
  southeast: ["↘️"],
  south: ["⬇️"],
  southwest: ["↙️"],
  west: ["⬅️"],
  northwest: ["↖️"],
  clockwise: ["🔃", "🔁", "🔂"],
  reload: ["🔃"],
  anticlockwise: ["🔄"],
  counterclockwise: ["🔄"],
  withershins: ["🔄"],
  back: ["🔙"],
  end: ["🔚"],
  "on!": ["🔛"],
  soon: ["🔜"],
  worship: ["🛐"],
  atheist: ["⚛️"],
  atom: ["⚛️"],
  dharma: ["☸️"],
  david: ["✡️"],
  "star of david": ["✡️"],
  buddhist: ["☸️"],
  tao: ["☯️"],
  taoist: ["☯️"],
  yang: ["☯️"],
  yin: ["☯️"],
  candelabrum: ["🕎"],
  candlestick: ["🕎"],
  ram: ["♈️"],
  ox: ["♉️"],
  crab: ["♋️"],
  lion: ["♌️"],
  scorpion: ["♏️"],
  scorpius: ["♏️"],
  goat: ["♑️"],
  snake: ["⛎"],
  repeat: ["🔁"],
  forward: ["⏩️"],
  "next scene": ["⏭️"],
  "next track": ["⏭️"],
  pause: ["⏯️", "⏸️"],
  reverse: ["◀️"],
  rewind: ["⏪️"],
  "previous scene": ["⏮️"],
  "previous track": ["⏮️"],
  button: ["🔼", "🔽", "✅", "🔘", "🔳", "🔲"],
  vertical: ["⏸️"],
  record: ["⏺️"],
  eject: ["⏏️"],
  brightness: ["🔆"],
  dim: ["🔅"],
  low: ["🔅"],
  mode: ["📳"],
  vibration: ["📳"],
  off: ["📴"],
  transgender: ["⚧️", "🏳️‍⚧️"],
  cancel: ["✖️", "❌"],
  multiplication: ["✖️", "❌"],
  x: ["✖️", "❌", "❎"],
  "×": ["✖️", "❌", "❎"],
  "+": ["➕"],
  math: ["➕", "➖", "➗", "🟰"],
  "-": ["➖"],
  "−": ["➖"],
  division: ["➗"],
  "÷": ["➗"],
  equality: ["🟰"],
  forever: ["♾️"],
  unbounded: ["♾️"],
  universal: ["♾️"],
  "!": ["‼️", "⁉️", "❕", "❗️"],
  "!!": ["‼️"],
  bangbang: ["‼️"],
  "!?": ["⁉️"],
  "?": ["⁉️", "❓️", "❔"],
  interrobang: ["⁉️"],
  question: ["⁉️", "❓️", "❔"],
  wavy: ["〰️"],
  exchange: ["💱"],
  aesculapius: ["⚕️"],
  staff: ["⚕️"],
  recycle: ["♻️"],
  "fleur-de-lis": ["⚜️"],
  anchor: ["🔱"],
  emblem: ["🔱"],
  trident: ["🔱"],
  badge: ["📛"],
  name: ["📛"],
  beginner: ["🔰"],
  chevron: ["🔰"],
  "japanese symbol for beginner": ["🔰"],
  o: ["⭕️", "🅾️"],
  check: ["✅", "☑️", "✔️"],
  "✓": ["✅", "☑️", "✔️"],
  multiply: ["❌"],
  loop: ["➰", "➿"],
  asterisk: ["✳️"],
  c: ["©️"],
  r: ["®️"],
  tm: ["™️"],
  trademark: ["™️"],
  keycap: [
    "#️⃣",
    "*️⃣",
    "0️⃣",
    "1️⃣",
    "2️⃣",
    "3️⃣",
    "4️⃣",
    "5️⃣",
    "6️⃣",
    "7️⃣",
    "8️⃣",
    "9️⃣",
    "🔟",
  ],
  abcd: ["🔠", "🔡"],
  input: ["🔠", "🔡", "🔢", "🔣", "🔤"],
  latin: ["🔠", "🔡", "🔤"],
  letters: ["🔠", "🔡", "🔤"],
  uppercase: ["🔠"],
  lowercase: ["🔡"],
  numbers: ["🔢"],
  "〒♪&%": ["🔣"],
  abc: ["🔤"],
  alphabet: ["🔤"],
  "a button (blood type)": ["🅰️"],
  "blood type": ["🅰️", "🆎", "🅱️", "🅾️"],
  "ab button (blood type)": ["🆎"],
  "b button (blood type)": ["🅱️"],
  "cl button": ["🆑"],
  "cool button": ["🆒"],
  "free button": ["🆓"],
  "id button": ["🆔"],
  identity: ["🆔"],
  "circled m": ["Ⓜ️"],
  "new button": ["🆕"],
  "ng button": ["🆖"],
  "o button (blood type)": ["🅾️"],
  "ok button": ["🆗"],
  "p button": ["🅿️"],
  parking: ["🅿️"],
  sos: ["🆘"],
  "sos button": ["🆘"],
  "up!": ["🆙"],
  "up! button": ["🆙"],
  versus: ["🆚"],
  able: ["💪"],
  geometric: [
    "🔴",
    "🔵",
    "⚫️",
    "⚪️",
    "⬛️",
    "⬜️",
    "◼️",
    "◻️",
    "◾️",
    "◽️",
    "▪️",
    "▫️",
    "🔶",
    "🔷",
    "🔸",
    "🔹",
  ],
  inside: ["💠"],
  radio: ["🔘"],
  checkered: ["🏁"],
  chequered: ["🏁"],
  pride: ["🏳️‍🌈"],
  rainbow: ["🏳️‍🌈"],
  flag: ["🇾🇹", "🇿🇦", "🇿🇲", "🇿🇼", "🏴󠁧󠁢󠁥󠁮󠁧󠁿", "🏴󠁧󠁢󠁳󠁣󠁴󠁿", "🏴󠁧󠁢󠁷󠁬󠁳󠁿"],
  "light blue": ["🏳️‍⚧️"],
  pink: ["🏳️‍⚧️"],
  "jolly roger": ["🏴‍☠️"],
  plunder: ["🏴‍☠️"],
  mom: ["👩‍👧‍👦"],
  power: ["💪"],
  weird: ["🥴"],
  never: ["🙅"],
  "no entry": [" 🚫 "],
  exactly: ["🎯"],
  promise: ["🙏"],
  powerful: ["💪"],
  decision: ["🤔"],
  "first thing": ["☝"],
  okay: ["👌"],
  mind: ["🧠"],
  brain: ["🧠"],
  success: ["🙌"],
  sleepy: ["😪"],
  accident: ["🤕"],
  ghost: ["👻"],
  goblin: ["👺"],
  ogre: ["👹"],
  robot: ["🤖"],
  "you viewer": ["🫵"],
  handshake: ["🤝"],
  "folded hands": ["🙏"],
  "nail polish": ["💅"],
  selfie: ["🤳"],
  foot: ["🦶"],
  lungs: ["🫁 "],
  tooth: ["🦷"],
  mouth: ["👄"],
  "old man": ["👴"],
  "old woman": ["👵"],
  flora: ["🌳"],
  fauna: ["🐅"],
  youngster: ["👦"],
  war: ["🪖"],
  "world war": ["🪖"],
  extinct: ["🦣", "🦤"],
  anxiety: ["😰"],
  disagree: ["🙅"],
  podcast: ["🎙️"],
  audience: ["👪"],
  society: ["👪"],
  time: ["🕛️"],
  marriage: ["👫"],
  health: ["💊 "],
  awareness: ["🧘‍♀️"],
  human: ["🧔‍♀️"],
  "human being": ["🧔‍♀️"],
  blood: ["🩸"],
  wound: ["🩸"],
  wounded: ["🩸"],
  scolding: ["🗣️"],
  scold: ["🗣️"],
  spaghetti: ["🍝"],
};

export const exceptionEmojiTags: any = {
  saw: ["👀"],
  bow: ["🙇"],
  bowing: ["🙇‍♂️", "🙇‍♀️"],
  wave: ["👋"],
  point: ["👆️"],
  pointing: ["👆️"],
  light: ["🕯️"],
};
