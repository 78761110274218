import { VideoLayout } from "@/enums";

import LandscapeLayoutImage from "@/assets/images/on-boarding/layouts/landscape-layout-min.jpeg";
import PortraitLayoutImage from "@/assets/images/on-boarding/layouts/portrait-layout-min.jpeg";
import SquareLayoutImage from "@/assets/images/on-boarding/layouts/square-layout-min.jpeg";

export const aspectRatioTitle: any = {
  [VideoLayout.LAYOUT_16_9]: "Landscape (16:9)",
  [VideoLayout.LAYOUT_1_1]: "Square (1:1)",
  [VideoLayout.LAYOUT_9_16_1]: "Portrait (9:16)",
  [VideoLayout.LAYOUT_9_16_2]: "Portrait Split (9:16)",
};

export const aspectRatioNames: any = {
  [VideoLayout.LAYOUT_16_9]: "Landscape (16:9)",
  [VideoLayout.LAYOUT_1_1]: "Square (1:1)",
  [VideoLayout.LAYOUT_9_16_1]: "Portrait (9:16:1)",
  [VideoLayout.LAYOUT_9_16_2]: "Portrait Split (9:16:2)",
};

export const layoutsArray = [
  VideoLayout.LAYOUT_16_9,
  VideoLayout.LAYOUT_1_1,
  VideoLayout.LAYOUT_9_16_1,
  VideoLayout.LAYOUT_9_16_2,
];

export const layoutDataArray = [
  {
    id: VideoLayout.LAYOUT_1_1,
    imgUrl: SquareLayoutImage,
    title: "Square (1:1)",
    recommendedPlatforms: [
      {
        id: "facebook",
        logoUrl:
          "https://storage.googleapis.com/vidyo-ai-static/preload-images/fb_logo.png",
      },
      {
        id: "linkedin",
        logoUrl:
          "https://storage.googleapis.com/vidyo-ai-static/preload-images/linkedin_logo.png",
      },
      {
        id: "twitter",
        logoUrl:
          "https://storage.googleapis.com/vidyo-ai-static/preload-images/twitter_logo.png",
      },
    ],
  },
  {
    id: VideoLayout.LAYOUT_9_16_1,
    imgUrl: PortraitLayoutImage,
    title: "Portrait (9:16)",
    recommendedPlatforms: [
      {
        id: "tiktok",
        logoUrl:
          "https://storage.googleapis.com/vidyo-ai-static/preload-images/4362958_tiktok_logo_social%20media_icon.png",
      },
      {
        id: "instagram",
        logoUrl:
          "https://storage.googleapis.com/vidyo-ai-static/preload-images/insta_logo.png",
      },
      {
        id: "youtube",
        logoUrl:
          "https://storage.googleapis.com/vidyo-ai-static/preload-images/youtube-shorts-icon.png",
      },
    ],
  },
  {
    id: VideoLayout.LAYOUT_16_9,
    imgUrl: LandscapeLayoutImage,
    title: "Landscape (16:9)",
    recommendedPlatforms: [
      {
        id: "youtube",
        logoUrl:
          "https://storage.googleapis.com/vidyo-ai-static/preload-images/youtube_logo.png",
      },
    ],
  },
];
