import { FC, useEffect } from "react";

import { useAppSelector } from "@/store/hooks";

import useManageSubscriptionWithStripe from "@/api/useManageSubscriptionWithStripe";

import Spinner from "@/components/Loader/Spinner";

const ManageSubscription: FC = () => {
  const currentUser = useAppSelector((state) => state.authState.currentUser);

  const { mutate, isLoading, isError, error } =
    useManageSubscriptionWithStripe();

  const handelRedirectToSubscription = () => {
    mutate({ email: currentUser?.email, return_url: window.location.origin });
  };

  useEffect(() => {
    if (currentUser) {
      handelRedirectToSubscription();
    }
  }, [currentUser]);

  useEffect(() => {
    // @ts-ignore
    if (error?.response?.data?.detail === "Customer not found") {
      window.location.replace("/");
    }
  }, [error]);

  return (
    <div className="flex h-[100vh] w-[100vw] flex-col items-center justify-center">
      {isLoading && (
        <>
          <div>
            <Spinner className="h-10 w-10" />
          </div>
          <p className="my-2 text-[17px] text-gray-500">
            Please wait while we are loading your subscription details... ⏳
          </p>
        </>
      )}

      {isError && (
        <>
          <p className="text-[17px] text-gray-500">
            {
              // @ts-ignore
              error?.response?.data?.detail ||
                "Something went wrong, please try again!"
            }
          </p>
          {
            // @ts-ignore
            error?.response?.data?.detail !== "Customer not found" && (
              <button
                className="my-4 inline-flex w-40 justify-center rounded-lg bg-primary-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
                onClick={handelRedirectToSubscription}
                id="retry-button-manage-subscription-page"
              >
                Retry
              </button>
            )
          }
        </>
      )}
    </div>
  );
};

export default ManageSubscription;
