import { configureStore } from "@reduxjs/toolkit";

import amplitudeReducer from "@/store/amplitudeSlice";
import authReducers from "@/store/authSlice";
import draftSlice from "@/store/draftSlice";
import editorReducers from "@/store/editorSlice";
import homePageReducer from "@/store/homeSlice";

import { loadStateForReduxFromLocalStorage } from "@/helpers";

const persistedState = loadStateForReduxFromLocalStorage() || {
  homeState: {},
  authState: {},
  editorState: {},
  draftState: {},
  amplitudeState: {},
};

export const store = configureStore({
  reducer: {
    homeState: homePageReducer.reducer,
    authState: authReducers.reducer,
    editorState: editorReducers.reducer,
    draftState: draftSlice.reducer,
    amplitudeState: amplitudeReducer.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
  preloadedState: {
    homeState: {
      ...homePageReducer.getInitialState(),
      ...persistedState.homeState,
    },
    authState: {
      ...authReducers.getInitialState(),
      ...persistedState.authState,
    },
    editorState: {
      ...editorReducers.getInitialState(),
      ...persistedState.editorState,
    },
    draftState: {
      ...draftSlice.getInitialState(),
      ...persistedState.draftState,
    },
    amplitudeState: {
      ...amplitudeReducer.getInitialState(),
      ...persistedState.amplitudeState,
    },
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
