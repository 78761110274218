import clsx from "clsx";

const HorizontalMenuTabItem = ({
  isActive = false,
  isDisabled = false,
  Icon,
  onClick,
  label,
}: {
  isActive?: boolean;
  isDisabled?: boolean;
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  onClick?: () => void;
  label: string;
}) => {
  return (
    <p
      className={clsx(
        isActive
          ? "text-blue-600 font-medium hover:text-blue-600"
          : "text-gray-400 hover:text-gray-500 font-medium",
        "group relative  overflow-hidden bg-gray-100 py-4 px-6 text-center  focus:z-10 whitespace-nowrap cursor-pointer transition-all w-min flex items-center"
      )}
      onClick={onClick}
    >
      {Icon && (
        <Icon
          width={14}
          className="mr-2"
        />
      )}
      <span>{label}</span>
      <span
        aria-hidden="true"
        className={clsx(
          isActive ? "bg-indigo-500" : "bg-transparent",
          "absolute inset-x-0 bottom-0 h-0.5 z-10"
        )}
      />
    </p>
  );
};

export default HorizontalMenuTabItem;
