import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import ReactTooltip from "react-tooltip";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { updateOnBoardingDataById } from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import useGetDefaultBaseTemplates from "@/api/useGetDefaultBaseTemplates";
import useGetUserPreference from "@/api/useGetUserPreference";
import useGetUserTemplates from "@/api/useGetUserTemplates";

import { layoutDataArray } from "@/constants/aspect-ratio";

import {
  areArraysEqual,
  getAllBaseTemplates,
  getTemplatesForLayout,
  removeDuplicateObjectsFromArray,
  sortByUpdatedDate,
  updatedUserSavedPreferencesData,
} from "@/helpers";

import { OnBoardingStep, TemplateVariant, VideoLayout } from "@/enums";

import { NextArrow, PrevArrow } from "@/components/SlickSliderArrow";

import { reactSlickSettings } from "@/views/home/components/TemplatePreference/carouselUtil";
import TemplateStaticPreview from "@/views/home/components/TemplateStaticPreview";

import TemplateCard from "./TemplateCard";

const TemplatePreference = ({
  getSelectedTemplatesCount,
  checkIfTemplateSelectionStateIsCompleted,
  handleChangeActiveStep,
}: any) => {
  const onBoardingId = useAppSelector(
    (state) => state.homeState.currentOnBoardingId
  );

  const baseTemplates = useAppSelector(
    (state) => state.homeState.baseTemplates
  );

  const uid = useAppSelector((state) => state.authState.currentUser.uid);

  const onBoardingData = useAppSelector(
    (state) => state.homeState.onBoardingData
  );

  const dispatch = useAppDispatch();

  const { data: userPreferenceData } = useGetUserPreference(uid);

  const defaultBaseTemplates = useGetDefaultBaseTemplates();
  const userTemplatesData = useGetUserTemplates(uid);

  const generateTemplatesData = (layoutId: string, isFilter = false) => {
    const userSavedTemplates = getTemplatesForLayout(
      layoutId,
      userTemplatesData?.data
    );
    if (layoutId === VideoLayout.LAYOUT_9_16_1 && !isFilter) {
      const userSavedTemplatesTwoFace = getTemplatesForLayout(
        layoutId,
        userTemplatesData?.data
      );

      const templatesArr = [
        ...userSavedTemplates,
        ...baseTemplates[VideoLayout.LAYOUT_9_16_1],
        ...userSavedTemplatesTwoFace,
        ...baseTemplates[VideoLayout.LAYOUT_9_16_2],
      ];

      const uniqueTemplates = removeDuplicateObjectsFromArray(templatesArr);

      return sortByUpdatedDate(uniqueTemplates);
    }
    const templatesArr = [...userSavedTemplates, ...baseTemplates[layoutId]];
    const uniqueTemplates = removeDuplicateObjectsFromArray(templatesArr);
    return sortByUpdatedDate(uniqueTemplates);
  };

  const [currentSelectedLayout, setCurrentSelectedLayout] = useState(
    onBoardingData[onBoardingId].selectedLayouts[0]
  );

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const getReactSlickSettings = () => {
    const newSettings = { ...settings };
    return {
      ...newSettings,
      ...reactSlickSettings(currentSelectedLayout),
    };
  };

  const checkIfNextButtonIsDisabled = (layout?: string) => {
    return (
      checkIfTemplateSelectionStateIsCompleted({
        layout: layout || currentSelectedLayout,
      }) < 3
    );
  };

  const continueButtonRef = useRef<any>(null);

  const showToolTip = () => {
    continueButtonRef.current && ReactTooltip.show(continueButtonRef.current);
  };

  const checkNextInvalidLayouts = (layoutsData: any) => {
    const nextLayout = layoutsData.find((el: string) =>
      checkIfNextButtonIsDisabled(el)
    );
    return nextLayout;
  };

  const handleLayoutMoveForward = () => {
    if (!checkIfNextButtonIsDisabled()) {
      const selectedLayouts = onBoardingData[
        onBoardingId
      ].selectedLayouts?.filter((item) => item !== "9:16:2");

      if (
        checkIfTemplateSelectionStateIsCompleted({
          checkIfReadyForNextStep: true,
        })
      ) {
        handleChangeActiveStep(OnBoardingStep.PERSONALIZED_VIDEO);
      }

      const nextLayout = checkNextInvalidLayouts(selectedLayouts);

      setCurrentSelectedLayout(nextLayout);
    } else {
      showToolTip();
    }
  };

  const handelLayoutMoveBackward = () => {
    const selectedLayouts = onBoardingData[
      onBoardingId
    ].selectedLayouts?.filter((item) => item !== "9:16:2");

    const currentIndex = selectedLayouts.findIndex(
      (item) => item === currentSelectedLayout
    );

    let prevIndex = currentIndex - 1;

    if (prevIndex < 0) {
      handleChangeActiveStep(OnBoardingStep.SELECT_LAYOUTS);
      setCurrentSelectedLayout(onBoardingData[onBoardingId].selectedLayouts[0]);
    } else {
      setCurrentSelectedLayout(selectedLayouts[prevIndex]);
    }
  };

  function createTemplatesArray(arr1: any, arr2: any) {
    const result = [];

    // Add common objects from arr1 and arr2 to result
    for (const obj of arr1) {
      for (const obj2 of arr2) {
        if (obj.id === obj2.id) {
          result.push(obj2);
          break;
        }
      }
    }

    // Add uncommon objects from arr1 to result
    for (const obj of arr1) {
      let found = false;
      for (const res of result) {
        if (res.id === obj.id) {
          found = true;
          break;
        }
      }
      if (!found) {
        result.push(obj);
      }
    }

    return removeDuplicateObjectsFromArray(result);
  }

  const filterCommonPreferenceBaseTemplates = (baseTemplates: any) => {
    let updatedBaseTemplates = {};

    for (let key in baseTemplates) {
      const baseTemplateByLayout = generateTemplatesData(key, true);

      const commonPrefBaseTemplate = baseTemplates[key];

      updatedBaseTemplates = {
        ...updatedBaseTemplates,
        [key]: createTemplatesArray(
          baseTemplateByLayout,
          commonPrefBaseTemplate
        ),
      };
    }

    return updatedBaseTemplates;
  };

  useEffect(() => {
    const baseTemplates = getAllBaseTemplates(
      defaultBaseTemplates?.data,
      userTemplatesData?.data
    );
    if (
      userPreferenceData?.savePreference &&
      areArraysEqual(
        onBoardingData[onBoardingId].selectedLayouts,

        userPreferenceData?.selectedLayouts
      )
    ) {
      dispatch(
        updateOnBoardingDataById({
          id: onBoardingId,
          data: {
            selectedLayouts: onBoardingData[onBoardingId].selectedLayouts,
            baseTemplates: filterCommonPreferenceBaseTemplates(
              updatedUserSavedPreferencesData(
                baseTemplates,
                userPreferenceData?.baseTemplates
              )
            ),
          },
        })
      );
    }
  }, [userPreferenceData]);

  return (
    <div className="relative rounded-lg  py-4 dark:bg-gray-700">
      <div className=" px-4 lg:px-6">
        <section className="bg-white ">
          <h2 className="mb-2 text-center  text-2xl font-medium tracking-tight text-gray-900 dark:text-white sm:text-4xl">
            Select templates
          </h2>

          <div className="mx-auto">
            <p className=" text-center text-sm font-light text-gray-400 sm:text-lg">
              We recommend selecting at least 3 of each type
            </p>

            <div className="my-4 flex items-center justify-center">
              <ul className="flex flex-wrap text-center text-sm font-medium text-gray-500 dark:text-gray-400">
                {onBoardingData[onBoardingId].selectedLayouts
                  ?.filter((item) => item !== "9:16:2")
                  .map((layoutId: string) => {
                    return (
                      <li
                        key={layoutId}
                        className={`mr-2 ${
                          currentSelectedLayout === layoutId
                            ? "active bg-blue-600 text-white"
                            : "hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-800 dark:hover:text-white"
                        } inline-block cursor-pointer rounded-lg py-3 px-4`}
                        onClick={() => setCurrentSelectedLayout(layoutId)}
                      >
                        {layoutDataArray
                          ?.find((layouts: any) => layouts.id === layoutId)
                          ?.title?.split("(")[0]
                          .trim()}{" "}
                        {`(${getSelectedTemplatesCount()[layoutId]})`}
                      </li>
                    );
                  })}
              </ul>
            </div>

            <div className=" relative mx-auto flex justify-center">
              {currentSelectedLayout === VideoLayout.LAYOUT_16_9 ? (
                <div className="relative mt-6 h-[200px] w-[720px]">
                  <Slider
                    key={currentSelectedLayout}
                    {...getReactSlickSettings()}
                  >
                    {generateTemplatesData(currentSelectedLayout).map(
                      (templateData: any) => (
                        <TemplateCard
                          key={templateData.id}
                          templateData={templateData}
                          layoutId={currentSelectedLayout}
                          dimensions={{ width: 304, height: 171 }}
                        >
                          <TemplateStaticPreview
                            template={templateData}
                            variant={TemplateVariant.SMALL}
                            key={templateData.id}
                            dimensions={{ width: 304, height: 171 }}
                            layout={VideoLayout.LAYOUT_16_9}
                            showDuration={false}
                          />
                        </TemplateCard>
                      )
                    )}
                  </Slider>
                </div>
              ) : (
                <div className="relative mt-6 h-[200px] w-[770px]">
                  <Slider {...getReactSlickSettings()}>
                    {generateTemplatesData(currentSelectedLayout).map(
                      (templateData: any) => (
                        <TemplateCard
                          key={templateData.id}
                          templateData={templateData}
                          layoutId={currentSelectedLayout}
                          dimensions={
                            currentSelectedLayout === VideoLayout.LAYOUT_1_1
                              ? { width: 200, height: 200 }
                              : { width: 153, height: 272 }
                          }
                        >
                          <TemplateStaticPreview
                            template={templateData}
                            variant={TemplateVariant.SMALL}
                            layout={
                              templateData.hasTwoFace
                                ? VideoLayout.LAYOUT_9_16_2
                                : (currentSelectedLayout as VideoLayout)
                            }
                            key={templateData.id}
                            dimensions={
                              currentSelectedLayout === VideoLayout.LAYOUT_1_1
                                ? { width: 200, height: 200 }
                                : { width: 153, height: 272 }
                            }
                            showDuration={false}
                          />
                        </TemplateCard>
                      )
                    )}
                  </Slider>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>

      <div className="relative mt-36">
        <div className="flex justify-center  pb-4">
          <button
            onClick={handelLayoutMoveBackward}
            id="back-button-template-selection"
            type="button"
            className={`mr-4 inline-flex w-44 items-center justify-center rounded-lg border border-blue-500 bg-white px-5 py-2.5 text-center text-sm font-medium text-blue-500 hover:bg-blue-50  focus:outline-none focus:ring-4 focus:ring-blue-300 sm:py-3.5`}
          >
            Back
          </button>

          <button
            ref={continueButtonRef}
            id="continue-button-template-selection"
            data-tip={
              checkIfTemplateSelectionStateIsCompleted({
                layout: currentSelectedLayout,
              }) >= 3
                ? ""
                : "Please select 3 or more templates to move ahead"
            }
            onClick={handleLayoutMoveForward}
            type="button"
            className={`inline-flex w-44 items-center justify-center rounded-lg bg-blue-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 sm:py-3.5  ${
              checkIfNextButtonIsDisabled()
                ? "cursor-not-allowed opacity-70"
                : ""
            }`}
          >
            Continue
          </button>
        </div>
      </div>
      <ReactTooltip
        effect="solid"
        arrowColor="transparent"
        backgroundColor="#EFF6FF"
        textColor="#71717A"
        padding="8px 10px"
      />
    </div>
  );
};

export default TemplatePreference;
