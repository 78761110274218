import { useState } from "react";
import { useDropzone } from "react-dropzone";

import clsx from "clsx";
import { Spinner } from "flowbite-react";

import { useAppSelector } from "@/store/hooks";

import { uploadFileToS3 } from "@/api/requests";
import useUploadAsset from "@/api/useUploadAsset";

import { AssetTags, SimpleAssetType } from "@/enums";

import AssetLimitReached from "../BrandAssets/AssetLimitReached";

type MediaUploaderProps = {
  supportedMediaTypes: Record<string, string[]>;
  secondaryText: string;
  supportedFilesDisplayText: string;
  maxFiles?: number;
  showButton?: boolean;
  assetTag: AssetTags;
  isFreeUserLimitReached: boolean;
};

const MediaUploader = ({
  supportedMediaTypes,
  secondaryText,
  supportedFilesDisplayText,
  maxFiles = 3,
  showButton = true,
  assetTag,
  isFreeUserLimitReached,
}: MediaUploaderProps) => {
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const uid = useAppSelector((state) => state.authState.currentUser.uid);

  const { mutate: finalizeUpload, isLoading: finalizeUploadLoading } =
    useUploadAsset(assetTag);

  const onDrop = async (acceptedFiles: any) => {
    const file = acceptedFiles?.[0];

    if (!file) {
      return;
    }

    try {
      setIsUploadLoading(true);

      if (file.type?.includes(SimpleAssetType.VIDEO)) {
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        });

        const video = document.createElement(SimpleAssetType.VIDEO);
        video.src = file.preview;
        video.preload = "metadata";
        video.muted = true;
        video.autoplay = false;
        video.playsInline = true;

        video.addEventListener("loadedmetadata", async () => {
          const res = await uploadFileToS3(file);

          res?.s3Url &&
            finalizeUpload({
              assetType: file.type,
              assetId: res?.assetId,
              ownerId: uid,
              parentId: null,
              assetTag: assetTag,
              data: {
                assetName: file.name.split(".")[0],
                remoteUrl: res?.s3Url,
                duration: Math.round(video.duration) || 0,
              },
            });
          removeAll();
          setIsUploadLoading(false);
        });
      } else {
        const res = await uploadFileToS3(file);

        res?.s3Url &&
          finalizeUpload({
            assetType: file.type,
            assetId: res?.assetId,
            ownerId: uid,
            parentId: null,
            assetTag: assetTag,
            data: {
              assetName: file.name.split(".")[0],
              remoteUrl: res?.s3Url,
            },
          });
        removeAll();
        setIsUploadLoading(false);
      }
    } catch (error) {
      console.log(error);
      removeAll();
      setIsUploadLoading(false);
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    inputRef,
    open,
  }: any = useDropzone({
    onDrop,
    accept: supportedMediaTypes,
    maxFiles: maxFiles,
  });

  const removeAll = () => {
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
    if (inputRef.current) inputRef.current.value = "";
  };

  if (isFreeUserLimitReached) {
    return <AssetLimitReached />;
  }

  return (
    <section className="flex flex-col justify-center w-full items-center">
      <div
        className={clsx(
          isUploadLoading && "opacity-60",
          "flex justify-center items-center w-max"
        )}
        {...getRootProps()}
        onClick={(e) => e.stopPropagation()}
      >
        <label
          htmlFor="dropzone-file-media-uploader"
          className={clsx(
            !isUploadLoading && "hover:bg-gray-100 dark:hover:bg-gray-600",
            "flex flex-col justify-center items-center w-max px-20 min-h-[15rem] bg-gray-50 rounded-lg border-2 border-gray-400 border-dashed cursor-pointer dark:bg-gray-700 dark:border-gray-600 dark:hover:border-gray-500 "
          )}
        >
          <div className="flex flex-col justify-center items-center pt-5 pb-6">
            <svg
              aria-hidden="true"
              className={clsx(
                "mb-3 w-10 h-10 text-gray-500",
                showButton && "mt-4"
              )}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
              ></path>
            </svg>
            {isUploadLoading ? (
              <div className="flex justify-center items-center">
                <Spinner className="w-4 h-4 mr-2 -mt-3" />
                <p className="mb-2 text-gray-500 dark:text-gray-400 font-medium">
                  Uploading your file... please wait
                </p>
              </div>
            ) : isDragActive ? (
              <p className="mb-2 text-gray-500 dark:text-gray-400 font-medium">
                Drop the files here...
              </p>
            ) : (
              <p className="mb-2 text-gray-500 dark:text-gray-400 font-medium">
                Click to upload or drag and drop
              </p>
            )}

            <p className="text-xs text-gray-400 dark:text-gray-400">
              {secondaryText}
            </p>

            {showButton && (
              <button
                type="button"
                className=" text-blue-600 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-8 py-2  my-6 focus:outline-none border border-blue-600"
                id="browse-file-media-uploader"
                onClick={open}
                disabled={isUploadLoading || finalizeUploadLoading}
              >
                Browse File
              </button>
            )}
          </div>
          <input
            {...getInputProps()}
            id="dropzone-file-media-uploader"
            disabled={isUploadLoading || finalizeUploadLoading}
          />
        </label>
      </div>
      <p className=" text-gray-900 text-xs font-medium mt-2">
        {supportedFilesDisplayText}
      </p>
    </section>
  );
};

export default MediaUploader;
