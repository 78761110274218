import { useEffect, useMemo, useState } from "react";
import { useBoolean } from "react-use";

import { useSelectedClips } from "@/context/ReviewClips";

import {
  setCurrentSelectedClip,
  updateCurrentSelectedMicroContent,
  updateShowFullLengthVideo,
  updateShowPreviewClipsSideModal,
} from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { PreviewScreenClipTypes, VideoLayout } from "@/enums";

import ClipDeleteModal from "@/views/home/components/ClipDeleteModal";
import CustomClipModal from "@/views/home/components/CustomClipModal";
import MultipleClipsDeleteConfirmationModal from "@/views/home/components/MultipleClipsDeleteConfirmationModal";

import { PreviewClipsList } from "../PreviewClipsList";
import BottomCTA from "./BottomCTA";
import TabList from "./ClipTypeTab";
import TopInfo from "./TopInfo";

type LeftSidebarProps = {
  microContent: any;
  onVideoClipChange: any;
  projectData: any;
  parsedProjectData: any;
  toggleShowDeleteMultipleClipsModal: () => void;
  toggleSelectExportMultipleClipsModal: () => void;
  setClipDeleteLoading: (value: boolean) => void;
  getRandomTemplateByLayout: (layout: VideoLayout) => any;
  showDeleteMultipleClipsModal: boolean;
  showIntelliClipModal: boolean;
};

const LeftSidebar = ({
  microContent,
  onVideoClipChange,
  projectData,
  parsedProjectData,
  toggleShowDeleteMultipleClipsModal,
  toggleSelectExportMultipleClipsModal,
  setClipDeleteLoading,
  getRandomTemplateByLayout,
  showDeleteMultipleClipsModal,
  showIntelliClipModal,
}: LeftSidebarProps) => {
  const dispatch = useAppDispatch();

  const { toggleMultiSelectMode, selectModeOn } = useSelectedClips();

  const showPreviewClipsSideModal = useAppSelector(
    (state) => state.homeState.showPreviewClipsSideModal
  );

  const selectedLayout = useAppSelector(
    (state) => state.editorState.selectedLayout
  );

  const currentSelectedMicroContent = useAppSelector(
    (state) => state.homeState.currentSelectedMicroContent
  );

  const [isCustomClipModalOpen, toggleCustomClipModal] = useBoolean(false);
  const [isClipDeleteModal, setIsClipDeleteModal] = useBoolean(false);
  const [currentType, setCurrentType] = useState(PreviewScreenClipTypes.SHORTS);
  const [customClipLayout, setCustomClipLayout] = useState<VideoLayout | null>(
    null
  );

  const getCustomClipLayout = useMemo(() => {
    switch (currentType) {
      case PreviewScreenClipTypes.CHAPTERS:
        return VideoLayout.LAYOUT_16_9;
      case PreviewScreenClipTypes.SHORTS:
        return VideoLayout.LAYOUT_9_16_1;
      case PreviewScreenClipTypes.FULL_VIDEO:
        return null;
      default:
        return null;
    }
  }, [currentType]);

  const handleOpenCustomClipModal = () => {
    setCustomClipLayout(getCustomClipLayout);
    toggleCustomClipModal();
  };

  useEffect(() => {
    if (
      microContent &&
      (microContent[PreviewScreenClipTypes.SHORTS].length ||
        microContent[PreviewScreenClipTypes.CHAPTERS].length)
    ) {
      // this is the loading displayed on the canvas when clip is deleted
      setClipDeleteLoading(false);

      if (
        currentSelectedMicroContent &&
        !currentSelectedMicroContent.isDeleted
      ) {
        if (
          currentSelectedMicroContent.clipOrigin ===
          PreviewScreenClipTypes.CHAPTERS
        ) {
          setCurrentType(PreviewScreenClipTypes.CHAPTERS);
        } else {
          setCurrentType(PreviewScreenClipTypes.SHORTS);
        }
        onVideoClipChange(
          currentSelectedMicroContent,
          selectedLayout,
          currentSelectedMicroContent.clipId
        );

        return;
      }

      // selecting fist clip in short
      let firstClip;

      if (microContent[PreviewScreenClipTypes.SHORTS].length) {
        // SHORT

        firstClip = microContent[PreviewScreenClipTypes.SHORTS][0];

        setCurrentType(PreviewScreenClipTypes.SHORTS);

        dispatch(setCurrentSelectedClip(firstClip.clipId));

        onVideoClipChange(
          firstClip,
          firstClip.hasTwoFace
            ? VideoLayout.LAYOUT_9_16_2
            : VideoLayout.LAYOUT_9_16_1,
          firstClip.clipId,
          false
        );
      } else {
        // CHAPTER
        firstClip = microContent[PreviewScreenClipTypes.CHAPTERS][0];

        setCurrentType(PreviewScreenClipTypes.CHAPTERS);

        dispatch(setCurrentSelectedClip(firstClip.clipId));

        onVideoClipChange(
          firstClip,
          VideoLayout.LAYOUT_16_9,
          firstClip.clipId,
          false
        );
      }
    } else {
      // display full video in case when there is no chapter or short available
      setCurrentType(PreviewScreenClipTypes.FULL_VIDEO); // full video tab
      selectModeOn && toggleMultiSelectMode();

      dispatch(setCurrentSelectedClip(PreviewScreenClipTypes.FULL_VIDEO));
      showPreviewClipsSideModal &&
        dispatch(updateShowPreviewClipsSideModal(false));
      dispatch(updateShowFullLengthVideo(true));
      dispatch(updateCurrentSelectedMicroContent(null));
    }
    // run this useeffect only if clip is deleted and on mount to set first clip by default
  }, [
    microContent[PreviewScreenClipTypes.SHORTS].length,
    microContent[PreviewScreenClipTypes.CHAPTERS].length,
  ]);

  return (
    <>
      <CustomClipModal
        isOpen={isCustomClipModalOpen}
        onCancel={toggleCustomClipModal}
        getRandomTemplate={getRandomTemplateByLayout}
        layout={customClipLayout}
        microContent={microContent}
      />
      <MultipleClipsDeleteConfirmationModal
        setIsClipDeleteModal={toggleShowDeleteMultipleClipsModal}
        isClipDeleteModal={showDeleteMultipleClipsModal}
        projectData={projectData}
        currentType={currentType}
        setClipDeleteLoading={setClipDeleteLoading}
      />
      <ClipDeleteModal
        setIsClipDeleteModal={setIsClipDeleteModal}
        isClipDeleteModal={isClipDeleteModal}
        projectData={projectData}
        microContent={microContent}
        currentType={currentType}
        setCurrentType={setCurrentType}
        setClipDeleteLoading={setClipDeleteLoading}
      />
      <div className="w-[32.5%] h-full bg-[#F3F4F6] relative pt-6">
        <div className="w-full h-full gap-y-5 flex flex-col px-6 pb-5">
          <TabList
            currentType={currentType}
            setCurrentType={setCurrentType}
            microContent={microContent}
          />
          <TopInfo currentType={currentType} />
          <PreviewClipsList
            microContent={microContent}
            onVideoClipChange={onVideoClipChange}
            setIsClipDeleteModal={setIsClipDeleteModal}
            currentType={currentType}
            projectData={projectData}
            parsedProjectData={parsedProjectData}
            setCurrentType={setCurrentType}
            showIntelliClipModal={showIntelliClipModal}
          />
        </div>
        <BottomCTA
          handleOpenCustomClipModal={handleOpenCustomClipModal}
          toggleShowDeleteMultipleClipsModal={
            toggleShowDeleteMultipleClipsModal
          }
          toggleSelectExportMultipleClipsModal={
            toggleSelectExportMultipleClipsModal
          }
        />
      </div>
    </>
  );
};

export default LeftSidebar;
