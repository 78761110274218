import { useState } from "react";
import { useUpdateEffect } from "react-use";

import { toggleShowSaveTemplateModal } from "@/store/editorSlice";
import { toggleUpgradeToProModal } from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import useAddUserTemplateData from "@/api/useAddUserTemplateData";
import useGetDefaultBaseTemplates from "@/api/useGetDefaultBaseTemplates";
import useGetUserTemplates from "@/api/useGetUserTemplates";
import useUpdateDefaultTemplateData from "@/api/useUpdateDefaultTemplateData";
import useUpdateUserTemplateData from "@/api/useUpdateUserTemplateData";

import { aspectRatioNames, layoutsArray } from "@/constants/aspect-ratio";
import { BUTTON_IDS } from "@/constants/segment-analytics";

import {
  generateTemplateName,
  isAdminTemplateEditor,
  replaceUndefinedWithNull,
} from "@/helpers";

import { trackUserButtonEvent } from "@/utils/SegmentAnalytics";
import {
  ANALYTICS_CONSTANTS,
  eventsDataToRedux,
  trackTemplateSaveEvent,
} from "@/utils/amplitudeAnalytcs";
import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

import { PlanType } from "@/enums";

import BaseModal from "@/components/BaseModal";
import Spinner from "@/components/Loader/Spinner";

const SAVE_AS_NEW_TEMPLATE = "new-template";
const UPDATE_TEMPLATE = "update-template";

const { UPGRADE_NOW_SAVE_TEMPLATE_MODAL_BTN, SAVE_TEMPLATE_BTN } =
  BUTTON_IDS.SAVE_TEMPLATES_MODAL;

const SaveTemplateModal = ({ generateTemplateJSON }: any) => {
  const currentSelectedMicroContent = useAppSelector(
    (state) => state.homeState.currentSelectedMicroContent
  );

  const [templateName, setTemplateName] = useState(
    generateTemplateName(
      currentSelectedMicroContent?.name,
      isAdminTemplateEditor()
    )
  );

  const [templateSaveOption, setTemplateSaveOption] =
    useState(SAVE_AS_NEW_TEMPLATE);
  const [showTemplateSaveSuccessModal, setShowTemplateSaveSuccessModal] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const showSaveTemplateModal = useAppSelector(
    (state) => state.editorState.showSaveTemplateModal
  );

  const planType = useAppSelector(
    (state) => state.authState.userSubscription.planType
  );
  const isPaidUser = planType !== PlanType.FREE;

  const uid = useAppSelector((state) => state.authState.currentUser.uid);

  const selectedLayout = useAppSelector(
    (state) => state.editorState.selectedLayout
  );

  const selectedAspectRatioTitle = aspectRatioNames[selectedLayout];

  const dispatch = useAppDispatch();

  const defaultBaseTemplates = useGetDefaultBaseTemplates();

  const handleCloseSaveTemplateModal = () => {
    dispatch(toggleShowSaveTemplateModal(false));
    setShowTemplateSaveSuccessModal(false);
    setTemplateSaveOption(SAVE_AS_NEW_TEMPLATE);
    setTemplateName(
      generateTemplateName(
        currentSelectedMicroContent?.name,
        isAdminTemplateEditor()
      )
    );
  };

  const handelUpdateSaveTemplateOption = (e: any) => {
    setTemplateSaveOption(e.target.value);
  };

  const saveTemplateMutationSuccessCB = () => {
    setShowTemplateSaveSuccessModal(true);
  };

  const { mutate: addUserTemplate, isLoading: isAddTemplateLoading } =
    useAddUserTemplateData(saveTemplateMutationSuccessCB);

  const { mutate: updateUserTemplate, isLoading: isUpdateTemplateLoading } =
    useUpdateUserTemplateData(saveTemplateMutationSuccessCB);

  const {
    mutate: updateDefaultTemplate,
    isLoading: isDefaultUpdateTemplateLoading,
  } = useUpdateDefaultTemplateData(saveTemplateMutationSuccessCB);

  const showSpinner = () => {
    return (
      isUpdateTemplateLoading ||
      isAddTemplateLoading ||
      isLoading ||
      isDefaultUpdateTemplateLoading
    );
  };

  const userSavedTemplatesData = useGetUserTemplates(uid);

  const checkIfCurrentTemplateIsDefault = () => {
    if (isAdminTemplateEditor()) {
      return false;
    }
    if (!isPaidUser) {
      return true;
    }
    if (defaultBaseTemplates?.data) {
      const currentAppliedTemplate: any = Object.values(
        // @ts-ignore
        defaultBaseTemplates?.data[selectedLayout]
      ).filter((item: any) => item.id === currentSelectedMicroContent.id)[0];
      return currentAppliedTemplate?.isDefault;
    }
    return true;
  };

  // write a function to replace undefined with null

  const handelAddTemplateData = async () => {
    try {
      setIsLoading(true);

      const generatedTemplateObject = await generateTemplateJSON(
        isAdminTemplateEditor()
      );
      let templateData = replaceUndefinedWithNull(generatedTemplateObject);
      setIsLoading(false);

      templateData = {
        ...templateData,
        id:
          templateSaveOption === UPDATE_TEMPLATE
            ? currentSelectedMicroContent?.id
            : templateData.id,
        name: templateName,
        isDefault: isAdminTemplateEditor(),
      };

      if (isAdminTemplateEditor()) {
        if (defaultBaseTemplates?.data) {
          const defaultTemplatesDataForSingleLayout = {
            ...defaultBaseTemplates.data[selectedLayout],
            [templateData.id]: {
              ...templateData,
              // @ts-ignore`
              createdAt: defaultBaseTemplates.data[selectedLayout][
                templateData.id
              ]?.createdAt
                ? // @ts-ignore
                  defaultBaseTemplates.data[selectedLayout][templateData.id]
                    .createdAt
                : new Date(),
              updatedAt: new Date(),
            },
          };
          updateDefaultTemplate({
            defaultTemplatesDataForSingleLayout,
            userId: uid,
            layout: selectedLayout,
          });
        }
      } else {
        if (
          userSavedTemplatesData.data &&
          Object.keys(userSavedTemplatesData.data).length > 0
        ) {
          const userTemplatesData = {
            ...userSavedTemplatesData.data,
            [selectedLayout]: {
              ...userSavedTemplatesData.data[selectedLayout],
              [templateData.id]: {
                ...templateData,
                // @ts-ignore
                createdAt: userSavedTemplatesData.data[selectedLayout][
                  templateData.id
                ]?.createdAt
                  ? // @ts-ignore
                    userSavedTemplatesData.data[selectedLayout][templateData.id]
                      .createdAt
                  : new Date(),
                updatedAt: new Date(),
              },
            },
          };
          updateUserTemplate({
            userTemplatesData,
            userId: uid,
            layout: selectedLayout,
          });
        } else {
          const emptyLayoutsObject = layoutsArray
            .filter((layout) => layout !== selectedLayout)
            .reduce((obj: any, item: any) => {
              obj[item] = {};
              return obj;
            }, {});

          const updatedUserTemplateJson = {
            ...emptyLayoutsObject,
            [selectedLayout]: {
              [templateData.id]: {
                ...templateData,
                createdAt: new Date(),
                updatedAt: new Date(),
              },
            },
          };
          addUserTemplate({
            userTemplatesData: updatedUserTemplateJson,
            userId: uid,
          });
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Save Template Error: ", error);
      showNotification(
        "Failed to save your template. Please try again later.",
        notificationType.FAIL
      );
    }
  };

  useUpdateEffect(() => {
    setTemplateName(
      generateTemplateName(
        currentSelectedMicroContent?.name,
        isAdminTemplateEditor()
      )
    );
  }, [currentSelectedMicroContent?.name]);

  return (
    <BaseModal
      isModalOpen={showSaveTemplateModal}
      handelModalOpen={() => {}}
      notClosable
    >
      {showSaveTemplateModal && (
        <div className="w-[31rem] bg-white p-6 pb-10 pt-8">
          {!isPaidUser && !isAdminTemplateEditor() ? (
            <>
              <p className="mb-2 text-center text-3xl">🤩</p>
              <p className=" mb-8 text-center text-2xl font-bold text-gray-900">
                You've discovered a PRO feature!
              </p>

              <div className="mx-auto mb-10 w-fit">
                <p className="mx-auto w-[80%] text-center font-light text-gray-500">
                  <span className="font-bold">PRO</span> users can save custom
                  templates with their own designs, fonts and more. To use this
                  feature, <span className="font-bold">upgrade to PRO</span>
                </p>
              </div>

              <div className="flex w-full justify-center">
                <button
                  onClick={() => {
                    handleCloseSaveTemplateModal();
                  }}
                  type="button"
                  id="maybe-later-upgrade"
                  className="mr-4 inline-flex w-40 items-center justify-center rounded-lg border border-gray-500 bg-white text-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-4 focus:ring-gray-300"
                  tabIndex={-1}
                >
                  Maybe later
                </button>

                <button
                  onClick={() => {
                    dispatch(toggleUpgradeToProModal(true));
                    handleCloseSaveTemplateModal();
                    eventsDataToRedux(ANALYTICS_CONSTANTS.PAYMENT_SCREEN_NAME);
                  }}
                  type="button"
                  className="inline-flex w-40 items-center justify-center rounded-lg bg-blue-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300"
                  id={UPGRADE_NOW_SAVE_TEMPLATE_MODAL_BTN.ID}
                >
                  Upgrade now
                </button>
              </div>
            </>
          ) : showTemplateSaveSuccessModal ? (
            <>
              <p className="mb-2 text-center text-3xl">🎉</p>
              {templateSaveOption === SAVE_AS_NEW_TEMPLATE ? (
                <p className=" mb-1 text-center text-2xl font-bold text-gray-900">
                  Awesome!
                </p>
              ) : (
                <p className=" mb-1 text-center text-2xl font-bold text-gray-900">
                  Woohoo!
                </p>
              )}

              <p className="mb-8 text-center text-2xl font-bold text-gray-900">
                {templateSaveOption === SAVE_AS_NEW_TEMPLATE ? (
                  <>
                    Your {selectedAspectRatioTitle} template
                    <br />
                    has been saved.
                  </>
                ) : (
                  <>
                    Your {selectedAspectRatioTitle} template
                    <br />
                    has been updated.
                  </>
                )}
              </p>

              <div className="mx-auto mb-8 w-fit">
                {isAdminTemplateEditor() ? (
                  <p className="text-center font-light text-gray-500">
                    Your new template is now published as global template. You
                    can close this window now.
                  </p>
                ) : (
                  <p className="text-center font-light text-gray-500">
                    You can find your new template in the{" "}
                    <span className="font-bold">My Templates</span> section. You
                    can close this window now.
                  </p>
                )}
              </div>

              <div className="mx-auto mb-8 p-2 w-fit border rounded-md border-[#EDC200] bg-[#FEF6DF]  text-black ">
                <p className="text-xs text-center font-medium">
                  This template will only be available for videos in{" "}
                  {selectedAspectRatioTitle}.
                </p>
              </div>

              <div className="flex w-full justify-center">
                <button
                  onClick={handleCloseSaveTemplateModal}
                  type="button"
                  className="inline-flex w-32 items-center justify-center rounded-lg bg-blue-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300"
                  id="done-save-template"
                >
                  Done
                </button>
              </div>
            </>
          ) : (
            <>
              <p className="mb-6 text-center text-2xl font-bold text-gray-900">
                Name your template
              </p>

              <div className="mx-auto mb-8 w-full">
                <input
                  type="text"
                  name="template-name"
                  id="user-template-name"
                  onChange={(e) => setTemplateName(e.target.value)}
                  className="mx-auto block w-[80%] rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
                  placeholder="Enter your template name"
                  value={templateName}
                  spellCheck="false"
                />

                <div className="mx-auto my-4 mt-6 w-[50%]">
                  <div className="mb-4 flex items-center">
                    <input
                      onChange={handelUpdateSaveTemplateOption}
                      id="save-as-new-template-radio"
                      type="radio"
                      value={SAVE_AS_NEW_TEMPLATE}
                      name="download-preference-radio"
                      checked={templateSaveOption === SAVE_AS_NEW_TEMPLATE}
                      className="h-5 w-5 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                    />
                    <label
                      htmlFor="save-as-new-template-radio"
                      className="ml-2 font-light text-gray-500 dark:text-gray-300"
                    >
                      Save as new template <br /> for {selectedAspectRatioTitle}
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      disabled={checkIfCurrentTemplateIsDefault()}
                      onChange={handelUpdateSaveTemplateOption}
                      id="update-template-radio"
                      type="radio"
                      value={UPDATE_TEMPLATE}
                      checked={templateSaveOption === UPDATE_TEMPLATE}
                      name="download-preference-radio"
                      className={`h-5 w-5 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600 ${
                        checkIfCurrentTemplateIsDefault() &&
                        "cursor-not-allowed opacity-50"
                      }`}
                    />
                    <label
                      htmlFor="update-template-radio"
                      className={`ml-2 font-light text-gray-500 dark:text-gray-300 ${
                        checkIfCurrentTemplateIsDefault() &&
                        "cursor-not-allowed opacity-50"
                      }`}
                    >
                      Update template
                    </label>
                  </div>
                </div>
              </div>

              <div className="mx-auto mb-8 p-2 w-fit border rounded-md border-[#EDC200] bg-[#FEF6DF]  text-black ">
                <p className="text-xs text-center font-medium">
                  Saving gallery videos is not supported by templates.
                </p>
              </div>

              <div className="mx-auto flex w-[60%] justify-center">
                <button
                  onClick={handleCloseSaveTemplateModal}
                  type="button"
                  disabled={showSpinner()}
                  id="save-template-modal-cancel-btn"
                  className="mr-4 inline-flex w-40 items-center justify-center rounded-lg border border-blue-500 bg-white px-5 py-2.5 text-center text-sm font-medium text-blue-500 hover:bg-blue-50  focus:outline-none focus:ring-4 focus:ring-blue-300"
                >
                  Cancel
                </button>

                <button
                  type="button"
                  id={SAVE_TEMPLATE_BTN.ID}
                  disabled={showSpinner() || !templateName}
                  className={`flex w-40 items-center justify-center rounded-lg bg-blue-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 ${
                    showSpinner() && "cursor-wait opacity-75"
                  } ${!templateName && "cursor-not-allowed opacity-75"}}`}
                  onClick={() => {
                    handelAddTemplateData();
                    trackTemplateSaveEvent(currentSelectedMicroContent);
                  }}
                >
                  {showSpinner() ? (
                    <div>
                      <Spinner className="h-4 w-4" />
                    </div>
                  ) : (
                    <></>
                  )}
                  Save
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </BaseModal>
  );
};

export default SaveTemplateModal;
