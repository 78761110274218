import { Transition } from "@headlessui/react";
import { PlayCircleIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";

import { generateCloudflareImageUrl } from "@/helpers";

import { SimpleAssetType } from "@/enums";

type AppliedAssetCardContainerProps = {
  showTransition?: boolean;
  children: React.ReactNode;
  assetUrl?: string | null;
  appliedColor?: string | null;
  isSocial?: boolean;
  assetType?: string;
  volume?: number;
};

const AppliedAssetCardContainer = ({
  showTransition = true,
  children,
  assetUrl,
  appliedColor = "",
  isSocial = false,
  assetType,
  volume,
}: AppliedAssetCardContainerProps) => {
  const renderAsset = () => {
    if (
      assetUrl &&
      (assetType?.includes(SimpleAssetType.IMAGE) ||
        assetType?.includes("Image"))
    )
      return (
        <img
          src={generateCloudflareImageUrl({
            originalUrl: assetUrl,
            imgOptions: {
              height: 80,
            },
          })}
          className={clsx(
            isSocial ? "h-10 w-10 mx-auto" : "h-full w-full",
            "object-center object-scale-down"
          )}
          onError={(e: any) => {
            e.target.onerror = null;
            e.target.src = assetUrl;
          }}
        />
      );

    if (
      assetUrl &&
      (assetType?.includes(SimpleAssetType.VIDEO) ||
        assetType?.includes("Video"))
    ) {
      return (
        <div className="w-full h-full relative">
          <video
            className="w-full object-center object-scale-down h-full"
            src={assetUrl}
            preload="metadata"
            muted // Safari hack
            autoPlay={false} // Safari hack
            playsInline
          />
          <PlayCircleIcon className="absolute h-5 w-5 text-gray-300 bottom-0 right-0" />
        </div>
      );
    }

    return (
      <div
        className="w-full h-full rounded-md"
        style={{
          backgroundColor: appliedColor || "",
        }}
      />
    );
  };
  return (
    <Transition
      show={showTransition}
      leave="transition-opacity duration-200 ease-out"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="bg-white rounded-md p-2 py-3 flex justify-between items-center shadow-[0_0px_4px_0_rgba(0,0,0,0.1)]">
        <div
          className={`flex items-center justify-between rounded-md  h-[75px] w-[75px] shadow-[0_0px_8px_0_rgba(0,0,0,0.1)] ${
            assetUrl && `bg-white border-gray-100 border`
          }`}
        >
          {renderAsset()}
        </div>
        <div className="w-[calc(100%_-_6rem)] flex flex-col justify-between space-y-5">
          {children}
        </div>
      </div>
    </Transition>
  );
};

export default AppliedAssetCardContainer;
