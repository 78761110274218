import { useState } from "react";
import ReactSlider from "react-slider";

import { Button } from "flowbite-react";

import { setProgressBarColor, setProgressBarHeight } from "@/store/editorSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { MediaIconColors } from "@/constants/brand-kit";

import AccordionLayout from "@/components/Accordion/AccordionLayout";
import ColorPicker from "@/components/ColorPicker";

import { DEFAULT_PROGRESS_BAR_HEIGHT } from "@/views/editor/constant";

const ProgressBarEditor = () => {
  const progressBarColorAsHex = useAppSelector(
    (state) => state.editorState.progressBarColorAsHex
  );

  const progressBarHeight = useAppSelector(
    (state) => state.editorState.progressBarHeight
  );

  const [isProgressBarActive, setIsProgressBarActive] = useState(
    progressBarHeight > 0
  );
  const dispatch = useAppDispatch();

  const handelSwitchProgressbarStyles = () => {
    if (!isProgressBarActive) {
      setIsProgressBarActive(true);
      dispatch(setProgressBarHeight(DEFAULT_PROGRESS_BAR_HEIGHT));
    } else {
      dispatch(setProgressBarHeight(0));
      setIsProgressBarActive(false);
    }
  };

  const handelChangeProgressBarColor = (e: any) => {
    dispatch(setProgressBarColor(e.hex));
  };

  return (
    <>
      {!isProgressBarActive && (
        <div className="flex items-center justify-end mt-4">
          <Button
            className="w-full py-[5px] bg-blue-600 rounded-md font-normal hover:!bg-blue-600"
            onClick={handelSwitchProgressbarStyles}
            id="add-progress-bar"
            size="md"
          >
            Add Progress Bar
          </Button>
        </div>
      )}

      {isProgressBarActive && (
        <>
          <div className="-mt-2">
            <AccordionLayout
              title="Progress Bar color"
              Icon={MediaIconColors}
              iconColor={progressBarColorAsHex}
            >
              <ColorPicker
                colorInHex={progressBarColorAsHex}
                handleChangeColor={handelChangeProgressBarColor}
              />
            </AccordionLayout>
          </div>
          <div className="my-8">
            <p className=" mb-4 text-gray-500">Thickness</p>

            <div className="px-2">
              <ReactSlider
                className="relative"
                min={0}
                max={30}
                value={progressBarHeight}
                onChange={(e) => {
                  dispatch(setProgressBarHeight(e));
                }}
                renderTrack={(props, state) => (
                  <div
                    {...props}
                    className="mt-1 h-3 rounded-xl bg-white"
                  ></div>
                )}
                renderThumb={(props, state) => (
                  <div
                    {...props}
                    className="h-5 w-5 rounded-full bg-blue-700"
                  ></div>
                )}
              />
            </div>
          </div>

          <div className="mt-8 flex items-center justify-end">
            <button
              type="button"
              className="mb-2 mt-8 rounded-lg border border-gray-200 bg-white py-2.5 px-5 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700 "
              onClick={handelSwitchProgressbarStyles}
              id="remove-progress-bar"
            >
              Remove Progress Bar
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default ProgressBarEditor;
