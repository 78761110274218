export const TiktokColorIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="25"
      width="25"
      viewBox="-4.15362 -7.827075 35.99804 46.96245"
    >
      <g fill="none">
        <path
          fill="#25F4EE"
          d="M10.7908 12.33v-1.22a9.003 9.003 0 00-1.28-.11C5.36 10.9912 1.685 13.681.4387 17.6403c-1.2465 3.9592.2248 8.269 3.632 10.6397-2.5522-2.7315-3.2609-6.708-1.8094-10.153 1.4514-3.445 4.792-5.7155 8.5295-5.797z"
        />
        <path
          fill="#25F4EE"
          d="M11.0208 26.15c2.3207-.0031 4.2284-1.8315 4.33-4.15V1.31h3.78a7.09 7.09 0 01-.11-1.31h-5.17v20.67c-.086 2.3303-1.9981 4.1762-4.33 4.18a4.3996 4.3996 0 01-2-.5 4.34 4.34 0 003.5 1.8zm15.17-17.82V7.18a7.06 7.06 0 01-3.91-1.18 7.17 7.17 0 003.91 2.33z"
        />
        <path
          fill="#FE2C55"
          d="M22.2808 6a7.12 7.12 0 01-1.77-4.7h-1.38a7.16 7.16 0 003.15 4.7zm-12.77 10.17c-1.9916.0102-3.7206 1.3746-4.1936 3.3092-.473 1.9346.4314 3.9428 2.1936 4.8708a4.33 4.33 0 013.51-6.87c.434.0054.865.0728 1.28.2v-5.26a9.0809 9.0809 0 00-1.28-.1h-.23v4a4.45 4.45 0 00-1.28-.15z"
        />
        <path
          fill="#FE2C55"
          d="M26.1908 8.33v4a12.2701 12.2701 0 01-7.17-2.33v10.51c-.011 5.2444-4.2656 9.49-9.51 9.49a9.4097 9.4097 0 01-5.44-1.72c2.6562 2.8568 6.79 3.7972 10.4207 2.3706 3.6306-1.4267 6.0184-4.9297 6.0193-8.8306V11.34a12.29 12.29 0 007.18 2.3V8.49a7.3297 7.3297 0 01-1.5-.16z"
        />
        <path
          fill="#000"
          d="M19.0208 20.51V10a12.27 12.27 0 007.18 2.3v-4a7.17 7.17 0 01-3.92-2.3 7.16 7.16 0 01-3.15-4.7h-3.78V22c-.0756 1.8468-1.3126 3.443-3.082 3.9772-1.7695.5342-3.683-.1108-4.768-1.6072-1.7622-.928-2.6666-2.9362-2.1936-4.8708.473-1.9346 2.202-3.299 4.1936-3.3092a4.451 4.451 0 011.28.2v-4c-3.7585.0637-7.1243 2.342-8.5799 5.8077-1.4555 3.4657-.726 7.464 1.8599 10.1923a9.41 9.41 0 005.45 1.61c5.2444 0 9.499-4.2456 9.51-9.49z"
        />
      </g>
    </svg>
  );
};
