import { useRef, useState } from "react";

import {
  PauseCircleIcon,
  PlayCircleIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";

import BaseModal from "@/components/BaseModal/BaseModal";

import { videoDimensions } from "@/views/home/components/DownloadVideoCard/SocialMediaSharing/constants";

export const VideoPreviewModal = ({
  videoData,
  showPreviewModal,
  setShowPreviewModal,
}: {
  videoData: any;
  showPreviewModal: any;
  setShowPreviewModal: (a: any) => any;
}) => {
  const { mediaUrls, title, id } = videoData;

  const videoElRef = useRef<any>();

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handlePlayPause = () => {
    if (videoElRef.current.paused && !isVideoPlaying) {
      videoElRef.current
        .play()
        .then(() => {
          setIsVideoPlaying(!isVideoPlaying);
        })
        .catch((error: any) => {
          console.log("handlePlayPause error", error);
        });
    }
    if (!videoElRef.current.paused && isVideoPlaying) {
      setIsVideoPlaying(false);
      videoElRef.current.pause();
    }
  };

  const onClose = () => {
    setShowPreviewModal((prevState: any) => ({
      ...prevState,
      [id]: false,
    }));
  };

  const clipType = "portrait";
  return (
    <BaseModal
      isModalOpen={showPreviewModal[id]}
      handelModalOpen={() => {}}
    >
      <div className="bg-white p-6 pb-10 pt-8 h-[500px]">
        <XMarkIcon
          className="h-6 w-6 text-gray-400 cursor-pointer absolute top-5 right-4"
          onClick={onClose}
        />
        <h5 className="text-center text-lg font-semibold text-gray-900 mt-5 px-3">
          {" "}
          {title}{" "}
        </h5>
        <video
          style={{
            width: videoDimensions[clipType].width,
            height: videoDimensions[clipType].height,
          }}
          className={`rounded bg-gray-100 m-auto mt-8`}
          src={`${mediaUrls[0]}${mediaUrls[0] ? "#t=0.1" : ""}`} //Safari hack
          preload="metadata"
          autoPlay={false} // Safari hack
          playsInline // Safari hack
          onEnded={() => {
            setIsVideoPlaying(false);
          }}
          ref={videoElRef}
        />
        <span
          className="left-[48%] transform cursor-pointer absolute top-[240px]"
          onClick={handlePlayPause}
        >
          {isVideoPlaying ? (
            <PauseCircleIcon
              className="w-[40px] h-[40px] text-white"
              style={{
                filter: "drop-shadow(0 0 2px rgba(0, 0, 0, .7))",
              }}
            />
          ) : (
            <PlayCircleIcon
              className="w-[40px] h-[40px] text-white"
              style={{
                filter: "drop-shadow(0 0 2px rgba(0, 0, 0, .7))",
              }}
            />
          )}
        </span>
      </div>
    </BaseModal>
  );
};
