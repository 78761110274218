import { memo } from "react";

import { PauseIcon, PlayIcon } from "@heroicons/react/24/solid";
import "@heroicons/react/24/solid";
import { Tooltip } from "flowbite-react";

import { useAppSelector } from "@/store/hooks";

import { ForwardFrameIcon } from "@/components/Icons/ForwardFrameIcon";
import { ForwardIcon } from "@/components/Icons/ForwardIcon";
import { RewindIcon } from "@/components/Icons/RewindIcon";

import "./EditorControls.styles.scss";

interface EditorControlsProps {
  backwardVideo: () => void;
  backwardVideoByOneFrame: () => void;
  handlePlayPause: () => void;
  outroTimeLeft: number;
  forwardVideoByOneFrame: () => void;
  forwardVideo: () => void;
  beforeElement?: React.ReactNode;
  afterElement?: React.ReactNode;
}

const EditorControls: React.FC<EditorControlsProps> = ({
  backwardVideo,
  backwardVideoByOneFrame,
  handlePlayPause,
  outroTimeLeft,
  forwardVideoByOneFrame,
  forwardVideo,
  beforeElement = null,
  afterElement = null,
}) => {
  const isEditorVideoPaused = useAppSelector(
    (state) => state.editorState.isEditorVideoPaused
  );

  return (
    <div className="relative mt-[10px] mx-2 flex gap-4 select-none items-center justify-center">
      {beforeElement}
      <Tooltip
        placement="top"
        content="Skip 5 sec back"
      >
        <div
          className="cursor-pointer secondary-controls p-1.5 rounded-full hover:bg-gray-100"
          onClick={backwardVideo}
        >
          <RewindIcon
            height="20"
            width="20"
            className="no-transform"
          />
        </div>
      </Tooltip>

      <Tooltip
        content="Skip 1 frame back"
        placeholder="top"
      >
        <div
          className="cursor-pointer secondary-controls p-2 rounded-full hover:bg-gray-100 place-content-center"
          onClick={backwardVideoByOneFrame}
        >
          <ForwardFrameIcon
            height="15"
            width="15"
            className="rev"
          />
        </div>
      </Tooltip>
      <div
        className="cursor-pointer place-content-center bg-slate-500 hover:bg-slate-600 p-2 rounded-full"
        onClick={() => {
          handlePlayPause();
        }}
      >
        {outroTimeLeft ? (
          <PauseIcon
            height={15}
            width={15}
            color="#fff"
          />
        ) : (
          <>
            {isEditorVideoPaused ? (
              <PauseIcon
                height={15}
                width={15}
                color="#fff"
              />
            ) : (
              <PlayIcon
                height={15}
                width={15}
                color="#fff"
                className="translate-x-px"
              />
            )}
          </>
        )}
      </div>

      <Tooltip
        content="Skip 1 frame forward"
        placement="top"
      >
        <div
          className="cursor-pointer secondary-controls p-2 rounded-full hover:bg-gray-100 place-content-center"
          onClick={forwardVideoByOneFrame}
        >
          <ForwardFrameIcon
            height="15"
            width="15"
          />
        </div>
      </Tooltip>

      <Tooltip
        placeholder="top"
        content="Skip 5 sec forward"
        className="flex place-content-center"
      >
        <div
          className="cursor-pointer secondary-controls rounded-full p-1.5 hover:bg-gray-100"
          onClick={forwardVideo}
        >
          <ForwardIcon
            height="20"
            width="20"
            className="no-transform"
          />
        </div>
      </Tooltip>
      {afterElement}
    </div>
  );
};

export default memo(EditorControls);
