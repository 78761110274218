import { toggleUpgradeToProModal } from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { BUTTON_IDS } from "@/constants/segment-analytics";

import { trackUserButtonEvent } from "@/utils/SegmentAnalytics";
import {
  ANALYTICS_CONSTANTS,
  eventsDataToRedux,
} from "@/utils/amplitudeAnalytcs";

import { PlanType } from "@/enums";

import NoTemplateImg from "@/assets/images/illustration-blank-canvas.svg";
import ProFeatureImg from "@/assets/images/pro-only-template.svg";

const { UPGRADE_NOW_TEMPLATES_TAB } = BUTTON_IDS.TEMPLATES_EMPTY;

const TemplatesEmpty = () => {
  const dispatch = useAppDispatch();

  const planType = useAppSelector(
    (state) => state.authState.userSubscription.planType
  );
  const isPaidUser = planType !== PlanType.FREE;

  return (
    <>
      {isPaidUser ? (
        <div className="col-span-2 mt-36 px-6 text-center text-sm text-[#5E6473]">
          <img
            src={NoTemplateImg}
            className="mx-auto"
          />
          <p className="mt-4 mb-6">
            You can now save templates with your custom design & logo to reuse
            for later.
          </p>
          <p>
            Click on the <span className="font-semibold">“Save Template”</span>{" "}
            button on the top menu to view your custom templates here.
          </p>
        </div>
      ) : (
        <div className="col-span-2 mt-36 px-6 text-center text-sm text-[#5E6473]">
          <img
            src={ProFeatureImg}
            className="mx-auto"
          />

          <p className=" my-4">
            <span className="font-semibold">PRO users</span> can save custom
            templates with their own designs, fonts and more. To use this
            feature, <span className="font-semibold">upgrade to PRO</span>{" "}
          </p>

          <button
            type="button"
            id={UPGRADE_NOW_TEMPLATES_TAB.ID}
            className={`my-6 mx-auto flex w-40 items-center justify-center rounded-lg bg-blue-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300`}
            onClick={() => {
              dispatch(toggleUpgradeToProModal(true));
              eventsDataToRedux(ANALYTICS_CONSTANTS.PAYMENT_SCREEN_NAME);
            }}
          >
            Upgrade now
          </button>
        </div>
      )}
    </>
  );
};

export default TemplatesEmpty;
