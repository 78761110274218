import { createContext, useEffect, useRef, useState } from "react";

import {
  PauseCircleIcon,
  PencilSquareIcon,
  PlayCircleIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import clsx from "clsx";
import { Tooltip } from "flowbite-react";

import { useAppSelector } from "@/store/hooks";

import {
  createSocialMediaAccount,
  getSocialMediaStatus,
  getQueryCreatorInfo,
  revokeSocialMediaAccess,
} from "@/api/requests";
import useDeleteRenderedVideo from "@/api/useDeleteRenderedVideo";

import {
  downloadS3Url,
  getValueFromLocalStorage,
  removeValueFromLocalStorage,
  saveValueToLocalStorage,
} from "@/helpers";

import {
  EXPORT_PREFERENCE,
  trackClipExportEvent,
  trackPlatformLinkedProperty,
} from "@/utils/amplitudeAnalytcs";
import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

import { DownloadedClip } from "@/interfaces";

import {
  DownloadedClipType,
  PlanType,
  PlanTypeLabels,
  SocialMediaTypes,
} from "@/enums";

import BaseModal from "@/components/BaseModal/BaseModal";

import { LinkSocialMedia } from "@/views/home/components/DownloadVideoCard/SocialMediaSharing/partials/linkSocialMedia";
import { LinkTiktokComponent } from "@/views/home/components/DownloadVideoCard/SocialMediaSharing/partials/linkTiktok";
import { UpgradeToPro } from "@/views/home/components/DownloadVideoCard/SocialMediaSharing/partials/upgradeToPro";
import { UploadPostsComponent } from "@/views/home/components/DownloadVideoCard/SocialMediaSharing/partials/uploadPostComponent";
import {
  UploadPostToSocialMediaType,
  UserInfoType,
} from "@/views/home/components/DownloadVideoCard/SocialMediaSharing/types";

import { socialMediaTabsList, videoDimensions } from "./constants";

interface ShareVideoModalProps {
  showShareModal: any;
  clipType: DownloadedClipType;
  downloadClip: DownloadedClip;
  setShowShareModal: (a: any) => void;
  toggleFetchDraft: () => void;
}

export const UploadPostToSocialMediaContext =
  createContext<UploadPostToSocialMediaType>({
    isFreeAccount: false,
    activeSocialData: {},
    downloadClip: {},
    userInfo: {},
    videoDuration: 0,
    handleUnlinkAccount: () => {},
    isRevokeAccessLoading: true,
    isPostUploading: false,
    isDraftUploading: false,
    setIsPostUploading: () => {},
    setIsDraftUploading: () => {},
    storePlatformOnLocalStorage: () => {},
    closeSuccessScreen: false,
    isUploadPostSuccess: false,
    isUploadDraftSuccess: false,
    setIsUploadDraftSuccess: () => {},
    setIsUploadPostSuccess: () => {},
    setScheduleDate: () => {},
    scheduleDate: null,
  });

export const ShareVideoModal = ({
  showShareModal,
  clipType,
  setShowShareModal,
  downloadClip,
  toggleFetchDraft,
}: ShareVideoModalProps): JSX.Element => {
  const {
    renderedVideoUrl,
    projectId,
    filename,
    render_task_id,
    clip_start,
    clip_end,
    clip_id,
  } = downloadClip;

  const videoElRef = useRef<any>();
  let timerRef = useRef<any>();

  const { mutate: deleteRenderedView } = useDeleteRenderedVideo();

  const { uid } = useAppSelector((state) => state.authState.currentUser);
  const planType = useAppSelector(
    (state) => state.authState.userSubscription.planType
  );

  const isFreeAccount =
    planType === PlanType.FREE || planType === PlanType.STARTER;
  const params = new URLSearchParams(
    decodeURIComponent(window.location.search)
  );
  const code = params.get("code");

  const [activeTab, setActiveTab] = useState<string>("tiktok");
  const [isVideoMetadataLoading, setIsVideoMetadataLoading] =
    useState<boolean>(true);
  const [isSocialMediaLinked, setIsSocialMediaLinked] = useState(false);
  const [isRetrivingData, setIsRetrivingData] = useState(true);
  const [userInfo, setUserInfo] = useState<UserInfoType | any>({});
  const [isRevokeAccessLoading, setIsRevokeAccessLoading] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [scheduleDate, setScheduleDate] = useState<Date | null>(null);
  const [isPostUploading, setIsPostUploading] = useState<boolean>(false);
  const [isDraftUploading, setIsDraftUploading] = useState<boolean>(false);
  const [isUploadPostSuccess, setIsUploadPostSuccess] = useState<any>({});
  const [isUploadDraftSuccess, setIsUploadDraftSuccess] = useState<any>({});

  const lastUploadedPlatform = getValueFromLocalStorage(
    "lastPublishedPlatform"
  );
  let isDataStored = false;

  const getUserInfo = async () => {
    try {
      const result: any = await getQueryCreatorInfo(uid);
      if (result.status === 200) {
        setIsRevokeAccessLoading(false);
        const {
          data: { social_media_type, data },
        } = result;
        if (social_media_type === "PAID") {
          const { activeSocialAccounts, displayNames } = data;
          let userInfoObj = {
            creator_username: null,
            // hard coding tiktok privacy level options since Ayrshare is not giving (for paid users)
            privacy_level_options: [
              "PUBLIC_TO_EVERYONE",
              "MUTUAL_FOLLOW_FRIENDS",
              "SELF_ONLY",
            ],
            comment_disabled: false,
            duet_disabled: false,
            stitch_disabled: false,
            linkedAccounts: activeSocialAccounts,
          };

          if (!activeSocialAccounts || !activeSocialAccounts?.length) {
            showNotification(
              `Sorry, we were not able to get your data. Please try linking your account again!`,
              notificationType.FAIL
            );
          } else {
            activeSocialAccounts?.forEach((account: string) => {
              const platFormData = displayNames.find(
                (obj: any) => obj.platform === account
              );
              userInfoObj = {
                ...userInfoObj,
                [`${account}_username`]: platFormData.displayName,
              };
            });
            trackPlatformLinkedProperty(activeSocialAccounts);
          }
          setUserInfo(userInfoObj);
        } else {
          setUserInfo(data.data);
        }
      } else {
        showNotification(
          "Something went wrong. Please refresh page or try unlinking the account and link again",
          notificationType.FAIL
        );
      }
    } catch (error: any) {
      showNotification(
        "Something went wrong. Please try again",
        notificationType.FAIL
      );
      console.log("creator user info api error: ", error);
    }
  };

  const getUserSocialMediaStatus = async () => {
    try {
      const result: any = await getSocialMediaStatus(uid);
      if (result.status === 200) {
        setIsRetrivingData(false);
        if (code) {
          window.history.replaceState(null, "", window.location.pathname);
        }
        const userSubscriptionType = isFreeAccount
          ? PlanTypeLabels.FREE
          : PlanTypeLabels.PAID;
        if (result.data.social_media_type === userSubscriptionType) {
          setIsSocialMediaLinked(true);
          await getUserInfo();
        } else {
          await handleUnlinkAccount(result.data.social_media_type);
        }
      } else if (result.status === 404) {
        storeAccountData();
        setIsSocialMediaLinked(false);
      }
    } catch (error: any) {
      storeAccountData();
      setIsRetrivingData(false);
    }
  };

  // creates social media account for free user
  const storeAccountData = async () => {
    if (code && !isDataStored && !isSocialMediaLinked && isFreeAccount) {
      try {
        const data = await createSocialMediaAccount({
          free_version: {
            user_id: uid,
            code,
          },
        });
        if (data.status === 200) {
          setIsSocialMediaLinked(true);
          showNotification(
            "Social Media account successfully linked",
            notificationType.SUCCESS
          );
          if (code) {
            params.delete("code");
          }
          await getUserInfo();
          isDataStored = true;
          return;
        } else {
          setIsSocialMediaLinked(false);
          return;
        }
      } catch (error: any) {
        console.log("store account data api error: ", error);
      }
    }
  };

  const handleUnlinkAccount = async (socialMediaType?: string) => {
    setIsRevokeAccessLoading(true);
    let payload: any = {
      user_id: uid,
    };
    if ((socialMediaType && socialMediaType === "FREE") || isFreeAccount) {
      payload = {
        ...payload,
        free_version: {
          platform: "tiktok",
        },
      };
      // revoke all platforms
    } else if (socialMediaType && socialMediaType === "PAID") {
      payload = {
        ...payload,
        paid_version: {
          platform: null,
          revoke_full_access: true,
        },
      };
      // revoke specific platform
    } else {
      payload = {
        ...payload,
        paid_version: {
          platform: activeTab,
          revoke_full_access: false,
        },
      };
    }
    try {
      const response = await revokeSocialMediaAccess(payload);

      if (response.status === 200) {
        // await getUserSocialMediaStatus();
        if (isFreeAccount) {
          setIsSocialMediaLinked(false);
          setIsRevokeAccessLoading(false);
        }
      } else {
        showNotification(
          "Something went wrong. Please try again",
          notificationType.FAIL
        );
        setIsRevokeAccessLoading(false);
      }
      timerRef.current = setTimeout(() => {
        getUserInfo();
      }, 5000);
    } catch (e) {
      setIsRevokeAccessLoading(false);
      showNotification(
        "Something went wrong. Please try again",
        notificationType.FAIL
      );
    }
  };

  const onClose = () => {
    setShowShareModal((prevState: any) => ({
      ...prevState,
      [render_task_id]: false,
    }));
  };

  const onTabClick = (tabId: string) => {
    setActiveTab(tabId);
  };

  const handleDownload = async () => {
    downloadS3Url(renderedVideoUrl, filename, projectId);
    trackClipExportEvent(projectId, clip_id, EXPORT_PREFERENCE.DOWNLOAD);
  };

  const deleteVideoHandler = () => {
    deleteRenderedView(render_task_id);
  };

  const handlePlayPause = () => {
    if (videoElRef.current.paused && !isVideoPlaying) {
      videoElRef.current
        .play()
        .then(() => {
          setIsVideoPlaying(!isVideoPlaying);
        })
        .catch((error: any) => {
          console.log("handlePlayPause error", error);
        });
    }
    if (!videoElRef.current.paused && isVideoPlaying) {
      setIsVideoPlaying(false);
      videoElRef.current.pause();
    }
  };

  const storePlatformOnLocalStorage = (platformName: string) => {
    saveValueToLocalStorage("lastPublishedPlatform", platformName);
  };

  const activeSocialData = socialMediaTabsList.find(
    (tab) => tab.id === activeTab
  );

  const videoDurationInSecs = (clip_end - clip_start) / 1000;

  const renderContent = () => {
    const isTiktokActive = activeTab === SocialMediaTypes.TIKTOK;

    if (isFreeAccount) {
      if (isTiktokActive) {
        if (isSocialMediaLinked) {
          return <UploadPostsComponent />;
        } else {
          return (
            <LinkTiktokComponent
              isRetrivingData={isRetrivingData}
              isSocialMediaLinked={isSocialMediaLinked}
            />
          );
        }
      } else {
        return (
          <UpgradeToPro
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            closeShareModal={onClose}
          />
        );
      }
    } else {
      if (isSocialMediaLinked && userInfo.linkedAccounts?.includes(activeTab)) {
        return <UploadPostsComponent />;
      } else {
        return <LinkSocialMedia activeTab={activeSocialData?.label!} />;
      }
    }
  };

  useEffect(() => {
    getUserSocialMediaStatus();
    removeValueFromLocalStorage("lastPublishedPlatform");
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const contextValue = {
    isFreeAccount,
    activeSocialData,
    downloadClip,
    userInfo,
    videoDuration: videoDurationInSecs,
    handleUnlinkAccount,
    isRevokeAccessLoading,
    isPostUploading,
    isDraftUploading,
    setIsPostUploading,
    setIsDraftUploading,
    storePlatformOnLocalStorage,
    closeSuccessScreen: activeTab === lastUploadedPlatform,
    isUploadPostSuccess,
    isUploadDraftSuccess,
    setIsUploadDraftSuccess,
    setIsUploadPostSuccess,
    setScheduleDate,
    scheduleDate,
  };

  if (showShareModal) {
    return (
      <UploadPostToSocialMediaContext.Provider value={contextValue}>
        <BaseModal
          isModalOpen={showShareModal}
          handelModalOpen={() => {}}
        >
          <div
            className={clsx(
              "bg-white p-6 pb-10 pt-8 h-[635px]",
              isFreeAccount ? "max-w-[1100px]" : "max-w-[1024px]"
            )}
          >
            <XMarkIcon
              className="h-6 w-6 text-gray-400 cursor-pointer absolute top-7 right-7"
              onClick={onClose}
            />
            <div className="mb-10 flex items-center justify-center">
              <p className="text-center text-2xl font-semibold text-gray-900">
                {filename?.replace(".mp4", "")}
              </p>
            </div>

            <div className="mt-4 w-[100%] flex items-start justify-start px-6">
              <div className="left-section w-[213px] relative">
                <video
                  style={{
                    width: videoDimensions[clipType].width,
                    height: videoDimensions[clipType].height,
                  }}
                  onLoadedMetadata={() => setIsVideoMetadataLoading(false)}
                  className={`rounded bg-gray-100`}
                  src={`${renderedVideoUrl}${renderedVideoUrl ? "#t=0.1" : ""}`} //Safari hack
                  preload="metadata"
                  autoPlay={false} // Safari hack
                  playsInline // Safari hack
                  onEnded={() => {
                    setIsVideoPlaying(false);
                  }}
                  ref={videoElRef}
                />
                <span
                  className="left-[88px] transform cursor-pointer absolute"
                  onClick={handlePlayPause}
                  style={{
                    top: videoDimensions[clipType].buttonPlacement,
                  }}
                >
                  {isVideoPlaying ? (
                    <PauseCircleIcon
                      className="w-[40px] h-[40px] text-white"
                      style={{
                        filter: "drop-shadow(0 0 2px rgba(0, 0, 0, .7))",
                      }}
                    />
                  ) : (
                    <PlayCircleIcon
                      className="w-[40px] h-[40px] text-white"
                      style={{
                        filter: "drop-shadow(0 0 2px rgba(0, 0, 0, .7))",
                      }}
                    />
                  )}
                </span>

                <div className="mt-4 flex items-center">
                  <button
                    type="button"
                    onClick={handleDownload}
                    disabled={isVideoMetadataLoading}
                    className={`inline-flex items-center justify-center  
                rounded bg-white border border-gray-900 h-9 w-30 px-5 py-2 text-center text-sm 
                font-medium text-gray-800 focus:outline-none mr-4 ${
                  isVideoMetadataLoading ? "cursor-not-allowed opacity-50" : ""
                }`}
                    id="download-clip-social-media-modal"
                  >
                    Download
                  </button>

                  <Tooltip
                    content={"Edit Video"}
                    className={""}
                  >
                    <button
                      type="button"
                      onClick={toggleFetchDraft}
                      disabled={isVideoMetadataLoading}
                      className={`inline-flex items-center justify-center  
                rounded bg-white border border-gray-900 h-9 w-9 focus:outline-none mr-4 ${
                  isVideoMetadataLoading ? "cursor-not-allowed opacity-50" : ""
                }`}
                      id="edit-clip-social-media-modal"
                    >
                      <PencilSquareIcon className="w-5 h-5 text-gray-900" />
                    </button>
                  </Tooltip>

                  <Tooltip
                    content={"Delete Video"}
                    className={""}
                  >
                    <button
                      type="button"
                      onClick={deleteVideoHandler}
                      disabled={isVideoMetadataLoading}
                      className={`inline-flex items-center justify-center  
                rounded bg-white border border-red-600 h-9 w-9 focus:outline-none ${
                  isVideoMetadataLoading ? "cursor-not-allowed opacity-50" : ""
                }`}
                      id="delete-clip-social-media-modal"
                    >
                      <TrashIcon className="h-5 w-5 cursor-pointer fill-red-500 " />
                    </button>
                  </Tooltip>
                </div>
              </div>
              <div className="ml-16 right-section">
                <div className="tabs-container flex justify-between w-8/12">
                  {socialMediaTabsList.map((tab) => {
                    const { id, label, icon } = tab;
                    const isActiveTab = activeTab === id;

                    return (
                      <div
                        key={id}
                        className={`tab cursor-pointer text-gray-500 text-sm h-[46px] px-4 rounded flex items-center justify-center ${
                          isActiveTab ? "bg-primary-600 text-white" : ""
                        }`}
                        onClick={() => onTabClick(id)}
                      >
                        <span className="mr-1.5">
                          {icon(isActiveTab ? "white" : "#6B7280")}
                        </span>
                        <span className="flex items-center">
                          {label}
                          {id === "tiktok" && isFreeAccount && (
                            <span
                              className={`ml-2 px-2 py-0.5 rounded ${
                                activeTab === SocialMediaTypes.TIKTOK
                                  ? "text-green-800  bg-green-100"
                                  : "text-indigo-800  bg-indigo-100"
                              }`}
                            >
                              {" "}
                              Free{" "}
                            </span>
                          )}
                        </span>
                      </div>
                    );
                  })}
                </div>
                {renderContent()}
              </div>
            </div>
          </div>
        </BaseModal>
      </UploadPostToSocialMediaContext.Provider>
    );
  }
  return <></>;
};
