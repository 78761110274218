import { SVGProps, memo } from "react";

import clsx from "clsx";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    className={clsx(props.className, "fill-current")}
    {...props}
  >
    <path
      fill="currentColor"
      d="M20 8.039h-3.65l.634-3.765a1.15 1.15 0 0 0-.161-.809.997.997 0 0 0-.644-.448.942.942 0 0 0-.748.175 1.091 1.091 0 0 0-.415.695l-.7 4.152h-3.3l.635-3.765a1.163 1.163 0 0 0-.162-.809 1.04 1.04 0 0 0-.28-.293.928.928 0 0 0-.753-.148.965.965 0 0 0-.359.168 1.091 1.091 0 0 0-.415.695l-.699 4.152h-3.65a.964.964 0 0 0-.707.316 1.125 1.125 0 0 0-.293.764c0 .286.106.56.293.763a.964.964 0 0 0 .707.316H8.62l-.606 3.6H4a.964.964 0 0 0-.707.316 1.125 1.125 0 0 0-.293.763c0 .287.105.561.293.764a.964.964 0 0 0 .707.316h3.65l-.634 3.765a1.164 1.164 0 0 0 .16.81c.075.118.17.218.28.295a.883.883 0 0 0 .543.169c.235 0 .462-.09.642-.252a1.1 1.1 0 0 0 .342-.636l.7-4.151h3.3l-.634 3.765a1.165 1.165 0 0 0 .16.81c.075.118.17.218.281.295a.958.958 0 0 0 .544.173c.234 0 .461-.09.64-.251.18-.162.302-.387.344-.636l.699-4.156h3.65c.265 0 .52-.114.707-.316.187-.203.293-.477.293-.764 0-.286-.106-.56-.293-.763a.964.964 0 0 0-.707-.317H15.38l.606-3.599H20c.265 0 .52-.113.707-.316.188-.202.293-.477.293-.763 0-.287-.105-.561-.293-.764A.964.964 0 0 0 20 8.04Zm-6.652 5.758h-3.301l.605-3.599h3.301l-.605 3.6Z"
    />
  </svg>
);
const CaptionIcon = memo(SvgComponent);
export default CaptionIcon;
