import * as amplitude from "@amplitude/analytics-browser";
import {
  browserLocalPersistence,
  getAdditionalUserInfo,
  getAuth,
  setPersistence,
  signInWithPopup,
  signOut,
} from "firebase/auth";

import { store } from "@/store";
import { login } from "@/store/authSlice";

import api from "@/api/api";
import { ApiEndpoints } from "@/api/constants/ApiEndPoints";
import { deleteUserAccount } from "@/api/requests";

import { deleteMoengageSession } from "@/utils/moengage";

import { trackSigninEvent, trackSignupEvent } from "./amplitudeAnalytcs";
import { notificationType } from "./constants";
import { auth } from "./firebase";
import { showNotification } from "./showNotification";

export const checkMainDBLogin = async (
  firestoreUserData: any,
  setIsLoading: (value: boolean) => void
) => {
  const userParam = { user_id: firestoreUserData?.uid };

  try {
    await api.get(ApiEndpoints.USERS, { params: userParam });
    store.dispatch(login(firestoreUserData));
    setIsLoading(false);
    trackSigninEvent(firestoreUserData);
  } catch (error: any) {
    if (error?.response?.data?.detail === "user not found in vidyo db") {
      createMainDBLogin(firestoreUserData, setIsLoading);
    } else {
      setIsLoading(false);
      showNotification(
        "Error while logging in. Please try again.",
        notificationType.FAIL
      );
    }
  }
};

const createMainDBLogin = async (
  firestoreUserData: any,
  setIsLoading: (value: boolean) => void
) => {
  const userData = {
    id: firestoreUserData?.uid,
    email: firestoreUserData?.email,
    displayName: firestoreUserData?.displayName,
    phoneNumber: firestoreUserData?.phoneNumber,
  };

  try {
    await api.post(ApiEndpoints.USER_CREATE, userData);
    store.dispatch(login(firestoreUserData));
    setIsLoading(false);
    trackSignupEvent(firestoreUserData);
  } catch {
    showNotification("Error while creating User", notificationType.FAIL);
    setIsLoading(false);
  }
};

const handleDelete = async (userInfo: any) => {
  const userCountry = store.getState().authState.country as any;
  if (userInfo?.isNewUser) {
    await deleteUserAccount(false);
    handelLogout();
    window.location.reload();
  }
};

export const signInWithFirebase = async (
  provider: any,
  setIsLoading: (value: boolean) => void,
  setDisplayModal: (value: boolean) => void,
  setFirebaseUserData: (value: {}) => void
) => {
  setPersistence(auth, browserLocalPersistence)
    .then(() => {
      signInWithPopup(auth, provider)
        .then(async (user) => {
          setIsLoading(true);
          const additionalInfo = getAdditionalUserInfo(user);
          let userEmail = user.user.email;

          if (!userEmail) {
            setDisplayModal(true);
            setIsLoading(false);
            setFirebaseUserData({
              ...user.user,
              showOnBoarding: additionalInfo?.isNewUser,
            });
          } else {
            checkMainDBLogin(
              {
                ...user.user,
                showOnBoarding: additionalInfo?.isNewUser,
              },
              setIsLoading
            );
          }
        })
        .catch((error) => {
          setIsLoading(false);
          switch (error.code) {
            case "auth/popup-blocked":
            case "auth/cancelled-popup-request":
              showNotification(
                "Pro tip:  Make sure your browser is not blocking pop-ups",
                notificationType.FAIL
              );
              break;
            case "auth/popup-closed-by-user":
              // Do nothing since closed by user
              break;
            case "auth/account-exists-with-different-credential":
              showNotification(
                "Looks like you are already registered with Gmail, please login with Gmail",
                notificationType.INFO
              );
              break;

            default:
              showNotification(
                "Error while logging in. Please try again.",
                notificationType.FAIL
              );
          }
        });
    })
    .catch((error) => {
      const errorMessage = error.message;
      showNotification(errorMessage, notificationType.FAIL);
    });
};

export const handelLogout = () => {
  const auth = getAuth();
  signOut(auth)
    .then(() => {
      amplitude.reset();
      deleteMoengageSession();
    })
    .catch((error) => {
      // An error happened.
      console.log("logout error", error);
    });
};
