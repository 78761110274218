import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  ArrowLongRightIcon,
  CheckIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { Player } from "@lottiefiles/react-lottie-player";
import clsx from "clsx";
import { Button, Rating, Spinner, Tooltip } from "flowbite-react";

import {
  toggleAddMoreMinModal,
  toggleManageSubscriptionModal,
  toggleUpgradeToProModal,
  updatedPaymentStatus,
} from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import {
  BUTTON_IDS,
  USER_PAYMENT_CONSTANTS,
} from "@/constants/segment-analytics";

import {
  forceUpgradeModalOnUser,
  getPaddlePriceDetailsByPlanTypeAndInterval,
  getProductIdByPlanTypeAndDuration,
  planIdInterval,
} from "@/helpers";

import { handelLogout } from "@/utils/FirebaseHelpers";
import {
  trackUserButtonEvent,
  trackUserSubscription,
} from "@/utils/SegmentAnalytics";
import {
  AMPLITUDE_CLICK_EVENTS,
  addMoreMinutesButtonClick,
} from "@/utils/amplitudeAnalytcs";
import { showNotification } from "@/utils/showNotification";

import { Subscription } from "@/interfaces";

import {
  PlanType,
  RouterPath,
  ScreenName,
  SubscriptionIntervalInMonths,
} from "@/enums";

import BaseModal from "@/components/BaseModal/BaseModal";

import BackgroundImage from "@/assets/icons/upgrade-modal-halloween-bg.svg";

const { SUBSCRIBE_NOW_BTN } = BUTTON_IDS.UPDATE_TO_PRO_MODAL;

const UPLOAD_FILES_UPTO_15GB = "Upload file up to 15 GB";
const UNLIMITED_1080P_RENDERS = "Unlimited 1080p renders";
const UNLIMITED_EDITOR_DRAFTS = "Unlimited editor drafts";
const BRANDED_TEMPLATES = "Branded templates";
const CUSTOM_FONTS_DESIGNS = "Custom fonts & designs";
const PRIORITY_SUPPORT = "Priority support";
const MINUTES_OF_UPLOAD = " minutes of upload";
const UPLOAD_TO_ALL_SOCIALS = "Publish clips to all platforms";
const BULK_DOWNLOAD = "Bulk download clips";
const FULLY_MANAGED_SERVICE_FOR_YOUR_VIDEOS = "Fully managed service";
const GET_MULTIPLE_CLIPS = "Get 5 videos every week";
const DEDICATED_MANAGER = "Dedicated account manager";
const AUTO_PUBLISHED_TO_SOCIAL = "Auto published to social media";
const MONEY_BACK_GUARANTEE = "14 day money back guarantee";
const ONE_REVISION_PER_VIDEO = "1 revision per video";
const IDEAL_FOR_BUSY_CREATORS = "Ideal for busy creators";
const TIKTOK_UPLOAD = "Direct TikTok publishing";
const FULL_HD_YOUTUBE_IMPORTS = "1080p YouTube imports";

const FREE_PLAN_FEATURES = [
  "75 minutes of upload",
  "Unlimited 720p Renders",
  "1 Upload at a time",
  "1 Editor draft",
  TIKTOK_UPLOAD,
];

const NOT_FREE_PLAN_FEATURES = [
  FULL_HD_YOUTUBE_IMPORTS,
  BULK_DOWNLOAD,
  BRANDED_TEMPLATES,
  CUSTOM_FONTS_DESIGNS,
  UPLOAD_TO_ALL_SOCIALS,
  PRIORITY_SUPPORT,
];

const STARTER_PLAN_FEATURES = [
  150 + MINUTES_OF_UPLOAD,
  UPLOAD_FILES_UPTO_15GB,
  FULL_HD_YOUTUBE_IMPORTS,
  UNLIMITED_1080P_RENDERS,
  BULK_DOWNLOAD,
  "1 Upload at a time",
  UNLIMITED_EDITOR_DRAFTS,
  BRANDED_TEMPLATES,
  CUSTOM_FONTS_DESIGNS,
  TIKTOK_UPLOAD,
];

const STARTER_ANNUAL_FEATURES = [
  1800 + MINUTES_OF_UPLOAD,
  UPLOAD_FILES_UPTO_15GB,
  FULL_HD_YOUTUBE_IMPORTS,
  UNLIMITED_1080P_RENDERS,
  BULK_DOWNLOAD,
  "1 Upload at a time",
  UNLIMITED_EDITOR_DRAFTS,
  BRANDED_TEMPLATES,
  CUSTOM_FONTS_DESIGNS,
  TIKTOK_UPLOAD,
];

const NOT_STARTER_FEATURES = [UPLOAD_TO_ALL_SOCIALS, PRIORITY_SUPPORT];

const PRO_PLAN_FEATURES = [
  "300 minutes of upload",
  UPLOAD_FILES_UPTO_15GB,
  FULL_HD_YOUTUBE_IMPORTS,
  UNLIMITED_1080P_RENDERS,
  BULK_DOWNLOAD,
  "3 Uploads at a time",
  UNLIMITED_EDITOR_DRAFTS,
  BRANDED_TEMPLATES,
  CUSTOM_FONTS_DESIGNS,
  TIKTOK_UPLOAD,
  UPLOAD_TO_ALL_SOCIALS,
];

const PRO_PLAN_ANNUAL_FEATURES = [
  "3600 minutes of upload",
  UPLOAD_FILES_UPTO_15GB,
  FULL_HD_YOUTUBE_IMPORTS,
  UNLIMITED_1080P_RENDERS,
  BULK_DOWNLOAD,
  "3 Uploads at a time",
  UNLIMITED_EDITOR_DRAFTS,
  BRANDED_TEMPLATES,
  CUSTOM_FONTS_DESIGNS,
  TIKTOK_UPLOAD,
  UPLOAD_TO_ALL_SOCIALS,
];

const NOT_PRO_PLAN_FEATURES = [PRIORITY_SUPPORT];

const PRO_PLUS_FEATURES = [
  "500 minutes of upload",
  UPLOAD_FILES_UPTO_15GB,
  FULL_HD_YOUTUBE_IMPORTS,
  UNLIMITED_1080P_RENDERS,
  BULK_DOWNLOAD,
  "5 Uploads at a time",
  UNLIMITED_EDITOR_DRAFTS,
  BRANDED_TEMPLATES,
  CUSTOM_FONTS_DESIGNS,
  TIKTOK_UPLOAD,
  UPLOAD_TO_ALL_SOCIALS,
  PRIORITY_SUPPORT,
];

const PRO_PLUS_ANNUAL_FEATURES = [
  "6000 minutes of upload",
  UPLOAD_FILES_UPTO_15GB,
  FULL_HD_YOUTUBE_IMPORTS,
  UNLIMITED_1080P_RENDERS,
  BULK_DOWNLOAD,
  "5 Uploads at a time",
  UNLIMITED_EDITOR_DRAFTS,
  BRANDED_TEMPLATES,
  CUSTOM_FONTS_DESIGNS,
  TIKTOK_UPLOAD,
  UPLOAD_TO_ALL_SOCIALS,
  PRIORITY_SUPPORT,
];

const FULL_SERVICE_FEATURES = [
  FULLY_MANAGED_SERVICE_FOR_YOUR_VIDEOS,
  GET_MULTIPLE_CLIPS,
  DEDICATED_MANAGER,
  AUTO_PUBLISHED_TO_SOCIAL,
  MONEY_BACK_GUARANTEE,
  ONE_REVISION_PER_VIDEO,
  IDEAL_FOR_BUSY_CREATORS,
];

const LEARN_MORE_NOTION =
  "https://vidyo-ai.notion.site/Full-Service-with-vidyo-ai-7bf0b673ab43450e865ef347c035fade";
const getSupportedFeatures = (planType: PlanType, isAnnual: boolean) => {
  if (planType === PlanType.STARTER) {
    return isAnnual ? STARTER_ANNUAL_FEATURES : STARTER_PLAN_FEATURES;
  } else if (planType === PlanType.PRO) {
    return isAnnual ? PRO_PLAN_ANNUAL_FEATURES : PRO_PLAN_FEATURES;
  } else if (planType === PlanType.PRO_PLUS) {
    return isAnnual ? PRO_PLUS_ANNUAL_FEATURES : PRO_PLUS_FEATURES;
  } else if (planType === PlanType.FULL_SERVICE) {
    return FULL_SERVICE_FEATURES;
  } else {
    return FREE_PLAN_FEATURES;
  }
};

const getNotSupportedFeatures = (planType: PlanType) => {
  if (planType === PlanType.STARTER) {
    return NOT_STARTER_FEATURES;
  } else if (planType === PlanType.PRO) {
    return NOT_PRO_PLAN_FEATURES;
  } else if (planType === PlanType.FREE) {
    return NOT_FREE_PLAN_FEATURES;
  } else {
    return [];
  }
};

const PRICING_MODAL = "PRICING_MODAL";
const IS_HALLOWEEN_THEME = true;

function ListItem({ text = "", isSupported = true }) {
  return (
    <li
      className={clsx(
        "font-medium flex items-center w-1/2 gap-2 py-1",
        isSupported
          ? `${IS_HALLOWEEN_THEME ? "text-white" : ""}`
          : "line-through text-gray-400"
      )}
    >
      <CheckIcon
        className={`h-[18px] w-[20px] ${
          isSupported ? "text-green-400" : "text-gray-400"
        }`}
      />
      <div className="text-wrap">{text}</div>
    </li>
  );
}

const getOffersByPlanType = (isAnnual: boolean, planType: number) => {
  if (isAnnual) {
    if (planType === PlanType.PRO_PLUS || planType === PlanType.PRO) {
      return 50;
    } else if (planType === PlanType.STARTER) {
      return 17;
    }
  }
  if (planType === PlanType.STARTER) {
    return 17;
  }
};

const OfferBadge = ({
  isAnnual,
  planType,
}: {
  isAnnual: boolean;
  planType: PlanType;
}) => {
  const offerPercent = getOffersByPlanType(isAnnual, planType);
  if (offerPercent) {
    return (
      <span className="bg-[#33463F] rounded-md text-green-300 px-8 py-1">
        Save {getOffersByPlanType(isAnnual, planType)}%
      </span>
    );
  }
  return null;
};

const PriceLabel = ({
  isAnnual,
  planType,
}: {
  isAnnual: boolean;
  planType: PlanType;
}) => {
  // adding following to invoke re render for price
  const country = useAppSelector((state) => state.authState.country);
  const allPaddlePlans = useAppSelector((state) => state.authState.paddlePlans);
  const paddleSubscription = useAppSelector(
    (state) => state.authState.paddleSubscription
  );

  let price =
    planType === PlanType.FREE
      ? 0
      : getPaddlePriceDetailsByPlanTypeAndInterval(planType, isAnnual);

  const getPreviousPrice = () => {
    const monthlyPrice = getPaddlePriceDetailsByPlanTypeAndInterval(
      planType,
      false
    );
    return `${monthlyPrice.split(" ")[0]} ${(
      monthlyPrice.split(" ")[1] * 12
    ).toFixed(2)}`;
  };

  const isOfferValid =
    isAnnual &&
    (planType === PlanType.PRO ||
      planType === PlanType.PRO_PLUS ||
      planType === PlanType.STARTER);

  const interval =
    planType === PlanType.FREE ? "forever" : isAnnual ? "year" : "month";

  return (
    <div
      className={clsx(
        IS_HALLOWEEN_THEME
          ? "text-white text-[26px]"
          : "text-gray-900 text-3xl ",
        "mt-6 font-extrabold dark:text-white"
      )}
    >
      {price}
      {isOfferValid && (
        <span className="ml-2 text-[18px] font-extrabold text-gray-500 text-light line-through">
          {getPreviousPrice()}
        </span>
      )}
      {planType !== PlanType.FREE ? (
        <div className="text-center font-light text-gray-400 !text-base mt-1">
          per {interval}
        </div>
      ) : (
        <span className="ml-2 text-xl font-light text-gray-500 text-light dark:text-gray-400">
          / {interval}
        </span>
      )}
    </div>
  );
};

const PlanActionButton = ({
  subscription,
  handleCheckout,
  btnType,
  isLoading,
  isAnnual,
  id,
}: {
  subscription: Subscription;
  handleCheckout: () => void;
  btnType: PlanType;
  isLoading: boolean;
  isAnnual: boolean;
  id: string;
}) => {
  let showCurrentPlanButton = subscription.planType === btnType;

  // if user is on annual plan than do not show on monthly plan
  if (
    !isAnnual &&
    subscription.intervalInMonths === SubscriptionIntervalInMonths.YEARLY
  ) {
    showCurrentPlanButton = false;
  }

  // if user is on monthly plan than do not show on annual plan
  if (
    isAnnual &&
    subscription.intervalInMonths === SubscriptionIntervalInMonths.MONTHLY
  ) {
    showCurrentPlanButton = false;
  }

  // if paddle subscription and cancelled than do not show current plan button
  if (subscription.isPaddle && subscription.cancelOn) {
    showCurrentPlanButton = false;
  }

  if (subscription.planType === PlanType.FREE && btnType === PlanType.FREE) {
    showCurrentPlanButton = true;
  }

  return showCurrentPlanButton ? (
    <div
      className={clsx(
        "text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-6 select-none cursor-default w-full",
        IS_HALLOWEEN_THEME ? "bg-fuchsia-600 h-[42px]" : "bg-gray-900 "
      )}
    >
      Your Current Plan
    </div>
  ) : (
    <Button
      id={id}
      className={clsx(
        IS_HALLOWEEN_THEME && "bg-fuchsia-700 hover:bg-hal",
        "mt-6 w-full"
      )}
      onClick={() => {
        trackUserButtonEvent(SUBSCRIBE_NOW_BTN.ID, SUBSCRIBE_NOW_BTN.ACTION);
        handleCheckout();
      }}
      // disabled={shouldDisableButton()}
    >
      {isLoading && (
        <div className="mr-3">
          <Spinner
            size="sm"
            light={true}
          />
        </div>
      )}
      Subscribe Now
    </Button>
  );
};

const Paddle = (window as any).Paddle;

const plans: Array<{
  name: string;
  type: PlanType;
  hide?: string;
}> = [
  {
    name: "AUDYO+",
    type: PlanType.PRO_PLUS,
  },
];

const UpdateToProModal = () => {
  const showProModalModal = useAppSelector(
    (state) => state.homeState.upgradeToProModalVisible
  );

  const currentUser = useAppSelector((state) => state.authState.currentUser);
  const currentUserSubscriptionDetails = useAppSelector(
    (state) => state.authState.userSubscription
  );
  const homeAnalytics = useAppSelector(
    (state) => state.amplitudeState.homeScreen
  );

  const [isAnnual, setIsAnnual] = useState(true);
  const [loadingPlanType, setLoadingPlanType] = useState<PlanType | null>(null);
  const [isLoadingCheckout, setIsLoadingCheckout] = useState(false);
  const [currentComponent, setCurrentComponent] = useState(PRICING_MODAL);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const activeSubscriptionType = isAnnual ? "annual" : "monthly";

  useEffect(() => {
    setIsAnnual(
      currentUserSubscriptionDetails.intervalInMonths ===
        SubscriptionIntervalInMonths.YEARLY || true
    );
    setCurrentComponent(PRICING_MODAL);
  }, [showProModalModal]);

  // select plan if it is available with current subscription
  // else select Pro+ by default
  useEffect(() => {
    const availablePlan = plans.find(
      (planType) =>
        planType.type === selectedPlan.type &&
        planType.hide !== activeSubscriptionType
    );
    setSelectedPlan(availablePlan || plans[0]);
  }, [isAnnual]);

  const handelRedirectToSubscription = () => {
    navigate(RouterPath.MANAGE_SUBSCRIPTION);
    handelToggleUpdateToProModal(false);
  };

  const handelToggleUpdateToProModal = (state: boolean) => {
    dispatch(toggleUpgradeToProModal(state));
  };

  const errorCB = () => {
    // show notification
    showNotification(
      "If you are already subscribed, please give a minute to update your subscription."
    );
    dispatch(toggleUpgradeToProModal(false));
  };

  const successCB = () => {
    dispatch(toggleUpgradeToProModal(false));
  };

  const paddleInlineCheckout = (productId: number) => {
    const referral =
      (window as any).Rewardful && (window as any).Rewardful.referral;

    Paddle?.Checkout.open({
      product: productId, // replace with a product ID or plan ID
      allowQuantity: false,
      disableLogout: true,
      passthrough: JSON.stringify({
        user_uid: currentUser.uid,
        email: currentUser.email,
        customer_name: currentUser.displayName,
        rewardful: { referral: referral },
        screenName: homeAnalytics?.paymentScreenName || ScreenName.HOME,
      }),
      email: currentUser.email,
      loadCallback: function (e: any) {
        setIsLoadingCheckout(false);
      },
      successCallback: function () {
        successCB();
        // plan purchase event
        dispatch(toggleUpgradeToProModal(false));
        dispatch(updatedPaymentStatus("Success"));
        trackUserSubscription(
          USER_PAYMENT_CONSTANTS.PLAN_PURCHASED,
          currentUserSubscriptionDetails.planType,
          planIdInterval[productId]
        );
      },
    });
  };

  const handleCheckout = async (planType: PlanType, isAnnual: boolean) => {
    if (currentUserSubscriptionDetails.planType !== PlanType.FREE) {
      if (currentUserSubscriptionDetails.isPaddle) {
        // if user has cancelled the subscription we will allow them to start new subscription
        if (!currentUserSubscriptionDetails.cancelOn) {
          dispatch(toggleManageSubscriptionModal(true));
          dispatch(toggleUpgradeToProModal(false));
          return;
        }
      } else {
        handelRedirectToSubscription();
        return;
      }
    }

    setIsLoadingCheckout(true);
    setLoadingPlanType(planType);
    paddleInlineCheckout(getProductIdByPlanTypeAndDuration(planType, isAnnual));
    setIsLoadingCheckout(false);
  };

  const [selectedPlan, setSelectedPlan] = useState<{
    name: string;
    type: PlanType;
  }>(plans[0]);

  const selectPlan = (plan: any) => {
    setSelectedPlan(plan);
  };

  const forceUpgradeModal = forceUpgradeModalOnUser();

  const allowClose = !forceUpgradeModal;

  if (showProModalModal) {
    return (
      <BaseModal
        isModalOpen={showProModalModal}
        handelModalOpen={handelToggleUpdateToProModal}
        notClosable={forceUpgradeModal}
        isDarkTheme={IS_HALLOWEEN_THEME}
      >
        <div
          className="flex max-w-7xl items-center justify-center py-8 px-10 relative"
          style={{
            backgroundImage: `url(${BackgroundImage})`,
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="w-[72rem] h-auto rounded-lg dark:bg-gray-700">
            {allowClose ? (
              <button
                type="button"
                className="absolute right-6 ml-auto inline-flex items-center rounded-lg bg-transparent p-1 text-sm text-gray-400 hover:bg-gray-700 hover:text-gray-900 dark:hover:bg-gray-800 dark:hover:text-white border-slate-600 border"
                onClick={() => {
                  handelToggleUpdateToProModal(false);
                }}
              >
                <XMarkIcon className="text-white h-6 w-6" />
                <span className="sr-only">Close modal</span>
              </button>
            ) : (
              <button
                type="button"
                className="absolute right-6 ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 px-3 text-sm hover:bg-gray-700 hover:text-gray-900 dark:hover:bg-gray-100 dark:hover:text-white border border-blue-600"
                onClick={() => {
                  handelLogout();
                  handelToggleUpdateToProModal(false);
                }}
              >
                <span className="text-blue-600 font-bold">Logout</span>
              </button>
            )}

            <section
              className={clsx(
                "dark:bg-gray-900",
                IS_HALLOWEEN_THEME ? "bg-none" : "bg-white px-4"
              )}
            >
              <div className="text-center">
                <h2
                  className={clsx(
                    "mb-8 text-4xl tracking-tight font-extrabold dark:text-white",
                    IS_HALLOWEEN_THEME ? "text-white" : "text-gray-900"
                  )}
                >
                  Upgrade to the only plan and enjoy spooky benefits! 👻
                </h2>
                {forceUpgradeModal && (
                  <div className="-mt-7 mb-7 text-base font-medium text-red-500">
                    Free plans are unavailable in your country. Choose a paid
                    plan to proceed
                  </div>
                )}
                <div
                  className={clsx(
                    "flex justify-center items-center",
                    IS_HALLOWEEN_THEME ? "" : "pl-36"
                  )}
                >
                  <span
                    className={clsx(
                      "text-xl font-medium dark:text-white",
                      IS_HALLOWEEN_THEME ? "text-white" : "text-gray-800"
                    )}
                  >
                    Yearly{" "}
                    {/* <span className="text-gray-500 font-light">
                      (Save 17% 🎉)
                    </span> */}
                  </span>
                </div>
              </div>
              <section
                className={clsx(
                  "dark:bg-gray-900 mt-5 h-[345px]",
                  IS_HALLOWEEN_THEME ? "bg-halloween-black" : "bg-white"
                )}
              >
                <div className="mx-auto max-w-screen-xl h-full">
                  <div
                    className={clsx(
                      "rounded-lg divide-y divide-halloween-slate h-full shadow dark:divide-gray-700 lg:divide-y-0 lg:divide-x lg:grid lg:grid-cols-3 dark:bg-gray-800",
                      IS_HALLOWEEN_THEME ? "bg-halloween-black" : "bg-white"
                    )}
                  >
                    <div className="col-span-2 p-6 lg:p-8 h-80">
                      <div
                        className={clsx(
                          "flex text-sm font-medium text-center text-gray-500 md:rounded-lg md:grid-cols-4 dark:divide-gray-600 dark:text-gray-400",
                          IS_HALLOWEEN_THEME ? "bg-halloween-gray" : ""
                        )}
                        role="tablist"
                      >
                        {plans.map((plan, index) => {
                          if (
                            plan.type === PlanType.FREE &&
                            forceUpgradeModal
                          ) {
                            return;
                          }

                          return (
                            <button
                              key={plan.name}
                              type="button"
                              className={`flex justify-center items-center font-normal w-full py-3 first:rounded-l-lg last:rounded-r-lg
                              ${
                                IS_HALLOWEEN_THEME
                                  ? `${
                                      isAnnual
                                        ? "[&:nth-of-type(2)]:rounded-l-lg"
                                        : "[&:nth-of-type(4)]:rounded-r-lg [&:nth-of-type(4)]:border-none"
                                    } [&:not(:last-of-type)]:border-r text-halloween-lightGray`
                                  : "border"
                              } 
                              ${
                                selectedPlan.type === plan.type
                                  ? IS_HALLOWEEN_THEME
                                    ? `bg-halloween-darkSlate border-none !text-white`
                                    : "border-blue-500 text-blue-700 bg-blue-50 font-semibold"
                                  : IS_HALLOWEEN_THEME
                                  ? "border-halloween-slate"
                                  : "border-gray-200"
                              } ${
                                plan.hide === activeSubscriptionType
                                  ? "hidden"
                                  : ""
                              }`}
                              onClick={() => selectPlan(plan)}
                            >
                              {(plan.type === PlanType.PRO_PLUS ||
                                plan.type === PlanType.PRO) && (
                                <span className="mr-2">
                                  <Player
                                    autoplay={true}
                                    loop={true}
                                    controls={true}
                                    src="https://lottie.host/68ea1900-eb16-425b-b415-cd1b2f9fcf18/8RUx8yNWjL.json"
                                    style={{
                                      height: "26px",
                                      width: "28px",
                                      margin: "0",
                                      display: "inline-block",
                                    }}
                                    id="pumpkin-animation"
                                  />
                                </span>
                              )}
                              {plan.name}
                            </button>
                          );
                        })}
                      </div>
                      <div className="flex flex-wrap text-fuchsia-700 text-5xl font-bold text-center justify-center items-center h-full">
                        You Reap What You Sow
                      </div>
                      {selectedPlan.type === PlanType.FULL_SERVICE && (
                        <div className="text-gray-500 mt-10">
                          Need more details?
                          <span
                            className="ml-1 text-blue-700 cursor-pointer underline font-medium"
                            onClick={() => {
                              window.open(LEARN_MORE_NOTION, "_blank");
                            }}
                            id="learn-more-full-service"
                          >
                            Read here
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col">
                      <div className="flex flex-col flex-1 items-center justify-center p-4 lg:p-8 relative">
                        {IS_HALLOWEEN_THEME && (
                          <OfferBadge
                            isAnnual={isAnnual}
                            planType={selectedPlan.type}
                          />
                        )}
                        <PriceLabel
                          isAnnual={isAnnual}
                          planType={selectedPlan.type}
                        />
                        <div className="w-72 flex flex-col">
                          {selectedPlan.type !== PlanType.FREE && (
                            <PlanActionButton
                              btnType={selectedPlan.type}
                              subscription={currentUserSubscriptionDetails}
                              handleCheckout={() =>
                                handleCheckout(selectedPlan.type, isAnnual)
                              }
                              isLoading={
                                isLoadingCheckout &&
                                loadingPlanType === selectedPlan.type
                              }
                              isAnnual={isAnnual}
                              id={`${selectedPlan.type}-${
                                isAnnual ? "yearly" : "monthly"
                              }`}
                            />
                          )}
                          {/* {selectedPlan.type === PlanType.FULL_SERVICE && (
                            <Button
                              color="light"
                              className={clsx(
                                "mt-6",
                                IS_HALLOWEEN_THEME
                                  ? "!bg-halloween-gray text-white border-none"
                                  : ""
                              )}
                              onClick={() => {
                                window.open(LEARN_MORE_NOTION, "_blank");
                              }}
                              id="learn-more-full-service"
                            >
                              Learn more
                            </Button>
                          )} */}
                          <div className="mt-5 text-gray-400 text-sm font-normal w-full flex justify-center items-center">
                            {(selectedPlan.type === PlanType.PRO ||
                              selectedPlan.type === PlanType.PRO_PLUS) &&
                            selectedPlan.type !==
                              currentUserSubscriptionDetails.planType
                              ? "Limited Halloween Offer "
                              : "Happy Halloween "}
                            <span className="group-hover:hidden">
                              {/* for icon animation */}
                              <Player
                                autoplay={true}
                                loop={true}
                                controls={true}
                                src="https://lottie.host/5e4acda8-ae43-4c93-ad8c-59daf70a321d/N5xqs2VvZv.json"
                                style={{
                                  height: "30px",
                                  width: "30px",
                                  margin: "0",
                                  display: "inline-block",
                                }}
                                id="pumpkin-with-wings-animation"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <Rating className="justify-center pb-4">
                        <Rating.Star />
                        <p
                          className={clsx(
                            "ml-1 text-xs dark:text-white",
                            IS_HALLOWEEN_THEME ? "text-white" : "text-gray-900"
                          )}
                        >
                          4.6
                        </p>
                        <span className="mx-1.5 h-1 w-1 rounded-full bg-gray-500 dark:bg-gray-400" />
                        <p className="text-xs font-medium text-gray-500 hover:no-underline dark:text-white">
                          Join 10000+ happy customers
                        </p>
                      </Rating>
                    </div>
                  </div>
                </div>
              </section>
              <div className="flex justify-center items-center mt-7">
                <span className={clsx(IS_HALLOWEEN_THEME ? "text-white" : "")}>
                  Need top-up minutes?
                </span>

                <Tooltip
                  content={
                    currentUserSubscriptionDetails.planType === PlanType.FREE
                      ? "Available on paid plans"
                      : ""
                  }
                  className={
                    currentUserSubscriptionDetails.planType !== PlanType.FREE
                      ? "hidden"
                      : ""
                  }
                >
                  <button
                    className={clsx(
                      "relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 mx-2",
                      IS_HALLOWEEN_THEME
                        ? "text-white bg-gradient-to-r from-halloween-red via-halloween-orange to-halloween-yellow"
                        : "bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500"
                    )}
                    onClick={() => {
                      handelToggleUpdateToProModal(false);
                      dispatch(toggleAddMoreMinModal(true));
                      addMoreMinutesButtonClick(
                        AMPLITUDE_CLICK_EVENTS.UPDATE_TO_PRO_MODAL
                      );
                    }}
                    disabled={
                      currentUserSubscriptionDetails.planType === PlanType.FREE
                    }
                  >
                    <span
                      className={clsx(
                        "relative px-3 py-1 transition-all ease-in duration-75 dark:bg-gray-900 rounded-md group-hover:bg-opacity-0",
                        IS_HALLOWEEN_THEME
                          ? "group-hover:bg-opacity-0 bg-halloween-gray"
                          : "bg-white"
                      )}
                    >
                      Click here
                    </span>
                  </button>
                </Tooltip>
              </div>
              <div className="text-center text-blue-700 mt-3">
                All our plans do not come with money back guarantee.{" "}
              </div>
            </section>
          </div>
        </div>
      </BaseModal>
    );
  }
  return null;
};

export default UpdateToProModal;
