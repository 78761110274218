import React from "react";

import { Transition } from "@headlessui/react";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

type AccordionLayoutProps = {
  title: string;
  children: React.ReactNode;
  iconColor?: string;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
};

const AccordionLayout = ({
  title,
  children,
  Icon,
  iconColor,
}: AccordionLayoutProps) => {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button
            className={`flex w-full justify-between p-2 bg-white py-3.5 hover:bg-gray-50 cursor-pointer select-none duration-200 ease-in-out hover:rounded-t-md transition-colors mt-6 rounded-lg ${
              open ? "rounded-b-none" : ""
            }`}
          >
            <div className="flex items-center space-x-2">
              <span
                className={clsx(iconColor && "border p-0.5 rounded")}
                style={{
                  backgroundColor: iconColor || "",
                }}
              >
                <Icon
                  className="w-3.5 h-3.5 text-gray-800"
                  style={{ color: iconColor || "" }}
                />
              </span>

              <div className="text-gray-800 font-semibold text-sm">{title}</div>
            </div>
            <div className="flex items-center justify-center">
              <ChevronUpIcon
                className={`w-4 transition-transform duration-300 h-4 ${
                  open ? "text-blue-600" : "rotate-180"
                }`}
                strokeWidth={2.5}
              />
            </div>
          </Disclosure.Button>
          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className="shadow-3xl shadow-cyan-500/50 p-2 mb-6 bg-white rounded-b-lg border-t transition-transform">
              {children}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};

export default AccordionLayout;
