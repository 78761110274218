import { Fragment, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useLocation } from "react-router";

import { Listbox, Transition } from "@headlessui/react";
import {
  AdjustmentsHorizontalIcon,
  CheckIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/solid";
import { Card, RangeSlider, TextInput } from "flowbite-react";

import { useAppSelector } from "@/store/hooks";

import { uploadFileToS3 } from "@/api/requests";
import useGetAssets from "@/api/useGetAssets";
import useUploadAsset from "@/api/useUploadAsset";

import {
  ANALYTICS_CONSTANTS,
  eventsDataToRedux,
} from "@/utils/amplitudeAnalytcs";

import { AssetTags } from "@/enums";
import { TextAlignment } from "@/enums";

import DropDownColorPicker from "@/components/DropDownColorPicker";
import IconPopover from "@/components/IconPopover/IconPopover";

import {
  fontFaces,
  MIN_LINE_HEIGHT,
  MAX_LINE_HEIGHT,
  MAX_STROKE_WIDTH,
  MIN_STROKE_WIDTH,
  MIN_SHADOW_OFFSET,
  MAX_SHADOW_OFFSET,
  MIN_SHADOW_BLUR,
  MAX_SHADOW_BLUR,
  DEFAULT_SHADOW_PROPERTIES,
  DEFAULT_STROKE_WIDTH,
  MIN_TEXTBOX_RADIUS,
  MAX_TEXTBOX_RADIUS,
  DEFAULT_BLOCK_BACKGROUND_RADIUS,
  TEXT_TRANSFORM,
  DEFAULT_TEXT_BACKGROUND_RADIUS,
  INIT_TEXT_STYLES,
} from "@/views/editor/constant";

import { UploadAssetButton } from "../UploadAssetButton";

import ActiveCenterAlignIcon from "@/assets/icons/active-center-align.svg";
import ActiveLeftAlignIcon from "@/assets/icons/active-left-align.svg";
import ActiveRightAlignIcon from "@/assets/icons/active-right-align.svg";
import CenterAlignIcon from "@/assets/icons/center-align.svg";
import LeftAlignIcon from "@/assets/icons/left-align.svg";
import LineSpacing from "@/assets/icons/line-spacing.svg";
import RightAlignIcon from "@/assets/icons/right-align.svg";
import BackgroundEffect from "@/assets/images/editor/background_effect.png";
import BlockEffect from "@/assets/images/editor/block_effect.png";
import OutlineEffect from "@/assets/images/editor/outline_effect.png";
import ShadowEffect from "@/assets/images/editor/shadow_effect.png";

const SUBTITLES_TAB = "subtitles";
const TEXTS_TAB = "texts";

const getValue = (value: any, options: any) => {
  const selected = options.find((item: any) => item.value === value);
  if (!selected) {
    return "";
  }

  return selected;
};

const TextStyleEditor = ({ styles, onChange, onFormatSubtitleText }: any) => {
  const fontProperties = getValue(styles?.font_face, fontFaces);
  const [updatedFontFaces, setUpdatedFontFaces] = useState<any>(fontFaces);

  const [currentFontFace, setCurrentFontFace] = useState(fontProperties);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [defaultTextType, setTextType] = useState(
    styles?.textTransform || TEXT_TRANSFORM.LOWERCASE
  );

  const { mutate: finalizeUpload, isLoading: finalizeUploadLoading } =
    useUploadAsset(AssetTags.FONT);
  const uid = useAppSelector((state) => state.authState.currentUser.uid);

  const currentSideMenu = useAppSelector(
    (state) => state.editorState.currentSideMenu
  );

  const { data: userFonts } = useGetAssets({
    assetTag: AssetTags.FONT,
  });

  useEffect(() => {
    if (userFonts?.length) {
      const updatedFonts = userFonts.map((item: any) => ({
        label: JSON.parse(item?.data)?.asset_name,
        value: JSON.parse(item?.data)?.asset_name,
        boldAllowed: false,
        italicAllowed: false,
        underlineAllowed: false,
        isCustom: true,
      }));
      setUpdatedFontFaces([...updatedFonts, ...fontFaces]);
    }
  }, [userFonts]);

  useEffect(() => {
    const fontProperties = getValue(styles?.font_face, updatedFontFaces);
    setCurrentFontFace(fontProperties);
  }, [styles?.font_face, updatedFontFaces]);

  const handelChangeFontColor = (hex: string) => {
    onChange({ ...styles, font_color: hex });
  };

  const handelChangeFontFace = (fontObj: any) => {
    setCurrentFontFace(fontObj);
    onChange({
      ...styles,
      font_face: fontObj.value,
      bold: fontObj.boldAllowed ? styles.bold : false,
      italic: fontObj.italicAllowed ? styles.italic : false,
      underline: fontObj.underlineAllowed ? styles.underline : false,
      textTransform: fontObj.uppercase ? TEXT_TRANSFORM.UPPERCASE : null,
    });
    fontObj.uppercase
      ? onFormatSubtitleText && onFormatSubtitleText(TEXT_TRANSFORM.UPPERCASE)
      : onFormatSubtitleText && onFormatSubtitleText(TEXT_TRANSFORM.LOWERCASE);

    fontObj.uppercase
      ? setTextType(TEXT_TRANSFORM.UPPERCASE)
      : setTextType(TEXT_TRANSFORM.LOWERCASE);
  };

  useEffect(() => {
    if (
      styles.textBgColor.enabled === false &&
      styles.stroke.enabled === false &&
      styles.shadow.enabled === false &&
      styles.blockBackground.enabled === false
    ) {
      onChange({ ...styles, noEffect: true });
    }
  }, [
    styles.textBgColor.enabled,
    styles.stroke.enabled,
    styles.shadow.enabled,
    styles.blockBackground.enabled,
  ]);

  const onDrop = async (acceptedFiles: any) => {
    const file = acceptedFiles?.[0];

    if (!file) {
      return;
    }

    try {
      setIsUploadLoading(true);

      const isFontFile = file.type === "font/ttf";

      if (isFontFile) {
        const res = await uploadFileToS3(file);

        res?.s3Url &&
          finalizeUpload({
            assetType: file.type,
            assetId: res?.assetId,
            ownerId: uid,
            parentId: null,
            assetTag: AssetTags.FONT,
            data: {
              assetName: file.name.split(".")[0],
              remoteUrl: res?.s3Url,
            },
          });
      }
      removeAll();
      setIsUploadLoading(false);
      eventsDataToRedux(ANALYTICS_CONSTANTS.UPLOADS.FONT_UPLOAD);
    } catch (error) {
      removeAll();
      console.log(error);
      setIsUploadLoading(false);
    }
  };

  const { getRootProps, getInputProps, acceptedFiles, inputRef }: any =
    useDropzone({
      onDrop,
      accept: {
        "font/ttf": [".ttf"],
      },
      maxFiles: 1,
    });

  const removeAll = () => {
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
    if (inputRef.current) inputRef.current.value = "";
  };

  const textTransform = () => {
    if (currentFontFace.uppercase) return;
    switch (currentSideMenu) {
      case SUBTITLES_TAB:
        if (
          defaultTextType === "" ||
          defaultTextType === TEXT_TRANSFORM.LOWERCASE
        ) {
          setTextType(TEXT_TRANSFORM.UPPERCASE);
          onFormatSubtitleText(TEXT_TRANSFORM.UPPERCASE);
          onChange({
            ...styles,
            textTransform: TEXT_TRANSFORM.UPPERCASE,
          });
          return;
        }
        if (defaultTextType === TEXT_TRANSFORM.UPPERCASE) {
          setTextType(TEXT_TRANSFORM.LOWERCASE);
          onFormatSubtitleText(TEXT_TRANSFORM.LOWERCASE);
          onChange({
            ...styles,
            textTransform: TEXT_TRANSFORM.LOWERCASE,
          });
          return;
        }
        break;

      case TEXTS_TAB:
        if (
          defaultTextType === "" ||
          defaultTextType === TEXT_TRANSFORM.LOWERCASE
        ) {
          setTextType(TEXT_TRANSFORM.UPPERCASE);
          onChange({
            ...styles,
            textTransform: TEXT_TRANSFORM.UPPERCASE,
          });
          return;
        }
        if (defaultTextType === TEXT_TRANSFORM.UPPERCASE) {
          setTextType(TEXT_TRANSFORM.LOWERCASE);
          onChange({
            ...styles,
            textTransform: TEXT_TRANSFORM.LOWERCASE,
          });
          return;
        }
        break;
    }
  };

  return (
    <div className="flex-1">
      <Card className="flowbite-custom-card">
        <div className="flex gap-2 items-center">
          <Listbox
            value={currentFontFace}
            onChange={handelChangeFontFace}
          >
            <div className="relative flex-1">
              <Listbox.Button className="relative w-full cursor-default rounded border bg-gray-50 py-2.5 pl-3 pr-10  text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                <span className="block truncate text-[14px] text-gray-700">
                  {currentFontFace.label}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDownIcon
                    className="h-6 w-6 text-gray-600"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-40 mt-1 max-h-96 w-full overflow-auto rounded-md bg-white pb-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  <div
                    className="bg-white flex sticky top-0 z-50 p-3"
                    {...getRootProps()}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <UploadAssetButton
                      label="Upload Custom Font"
                      id="dropzone-file-font-editor"
                      isLoading={isUploadLoading || finalizeUploadLoading}
                      getInputProps={getInputProps}
                      isDisabled={isUploadLoading || finalizeUploadLoading}
                      isUploadFont={true}
                    />
                  </div>
                  {updatedFontFaces.map((fonts: any) => (
                    <Listbox.Option
                      key={fonts.value}
                      className={({ active }) =>
                        `relative cursor-default select-none py-4 pl-10 pr-4 ${
                          active
                            ? "bg-blue-100 text-amber-900"
                            : "text-gray-900"
                        } ${fonts?.isCustom ? "bg-gray-100" : ""}`
                      }
                      value={fonts}
                    >
                      {({ selected }) => (
                        <>
                          <p
                            style={{
                              fontFamily: fonts.value,
                            }}
                          >
                            {fonts.label}
                          </p>

                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-2 text-blue-500">
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
          <TextInput
            className="w-16"
            type="number"
            value={styles?.font_size ? Math.floor(styles?.font_size) : 0}
            max={99}
            onChange={(e) => onChange({ ...styles, font_size: e.target.value })}
            placeholder="Size"
          />
          <DropDownColorPicker
            color={styles?.font_color}
            onChange={handelChangeFontColor}
          />
        </div>
        <div className="flex justify-between mt-3">
          <div className="flex items-center rounded py-2 bg-white border-gray-400 border 2xl:gap-1 gap-0.5 2xl:px-2 px-1">
            <div
              className={`
            select-none transition-all cursor-pointer text-lg font-light w-7 text-center
            ${styles?.bold ? "!font-extrabold text-gray-900" : ""}
            ${fontProperties.boldAllowed ? "" : "!cursor-not-allowed"}
            `}
              onClick={() =>
                fontProperties.boldAllowed &&
                onChange({ ...styles, bold: !styles.bold })
              }
            >
              B
            </div>
            <div
              className={`
            select-none transition-all cursor-pointer text-lg font-light  w-7 text-center
            ${styles?.italic ? "!font-extrabold text-gray-900" : ""}
            ${fontProperties.italicAllowed ? "" : "!cursor-not-allowed"}
            `}
              onClick={() =>
                fontProperties.italicAllowed &&
                onChange({ ...styles, italic: !styles.italic })
              }
            >
              I
            </div>
            <div
              className={`
            select-none cursor-pointer text-lg font-light w-7 transition-all text-center
            ${styles?.underline ? "!font-extrabold text-gray-900" : ""}
            ${fontProperties.underlineAllowed ? "" : "!cursor-not-allowed"}
            `}
              style={{ textDecoration: "underline" }}
              onClick={() =>
                fontProperties.underlineAllowed &&
                onChange({ ...styles, underline: !styles.underline })
              }
            >
              U
            </div>
          </div>

          {/* {location.pathname.split("/")[3] === SUBTITLES_TAB && ( */}
          <div className="flex items-center rounded py-2 px-2 bg-white border-gray-400 border">
            <div
              className={`select-none transition-all cursor-pointer text-lg font-light w-7 text-center
                  ${currentFontFace.uppercase ? "!cursor-not-allowed" : ""}
                `}
              onClick={textTransform}
            >
              <span
                className={`${
                  defaultTextType === "" ||
                  (defaultTextType === TEXT_TRANSFORM.LOWERCASE &&
                    "font-extrabold")
                }`}
              >
                a
              </span>
              <span
                className={`${
                  defaultTextType === TEXT_TRANSFORM.UPPERCASE &&
                  "font-extrabold"
                }`}
              >
                A
              </span>
            </div>
          </div>
          {/* // )} */}
          <div
            className={
              "flex items-center rounded py-2 bg-white border-gray-400 border 2xl:gap-1 gap-0.5 2xl:px-2 px-1 "
            }
          >
            <div
              className="w-7 flex justify-center select-none cursor-pointer"
              onClick={() => {
                onChange({
                  ...styles,
                  alignment: TextAlignment.LEFT,
                  text_align: TextAlignment.LEFT,
                });
              }}
            >
              <img
                src={
                  styles?.alignment === TextAlignment.LEFT
                    ? ActiveLeftAlignIcon
                    : LeftAlignIcon
                }
                alt="left align"
              />
            </div>
            <div
              className="w-7 flex justify-center select-none cursor-pointer"
              onClick={() =>
                onChange({
                  ...styles,
                  alignment: TextAlignment.CENTER,
                  text_align: TextAlignment.CENTER,
                })
              }
            >
              <img
                src={
                  styles?.alignment === TextAlignment.CENTER
                    ? ActiveCenterAlignIcon
                    : CenterAlignIcon
                }
                alt="center align"
              />
            </div>
            <div
              className="w-7 flex justify-center select-none cursor-pointer"
              onClick={() =>
                onChange({
                  ...styles,
                  alignment: TextAlignment.RIGHT,
                  text_align: TextAlignment.RIGHT,
                })
              }
            >
              <img
                src={
                  styles?.alignment === TextAlignment.RIGHT
                    ? ActiveRightAlignIcon
                    : RightAlignIcon
                }
                alt="right align"
              />
            </div>
          </div>
          <div className="flex items-center rounded pl-2 bg-white border-gray-400 border">
            <img
              src={LineSpacing}
              alt="line spacing"
              className="w-6 h-6 mr-2"
            />
            <input
              className="w-14 h-11 p-1 pl-2 bg-gray-50 border-gray-300 text-gray-900 rounded border-0 focus:border-0 focus:ring-0"
              type="number"
              min={MIN_LINE_HEIGHT}
              step={0.1}
              max={MAX_LINE_HEIGHT}
              value={styles?.line_height ? styles.line_height : MIN_LINE_HEIGHT}
              onChange={(e) => {
                const value = parseFloat(e.target.value);
                if (value >= MIN_LINE_HEIGHT && value <= MAX_LINE_HEIGHT) {
                  onChange({ ...styles, line_height: value });
                } else if (value < MIN_LINE_HEIGHT) {
                  onChange({ ...styles, line_height: MIN_LINE_HEIGHT });
                } else if (value > MAX_LINE_HEIGHT) {
                  onChange({ ...styles, line_height: MAX_LINE_HEIGHT });
                } else {
                  onChange({ ...styles, line_height: 1 });
                }
              }}
            />
          </div>
        </div>
      </Card>
      <Card className="mt-4 flowbite-custom-card">
        <div className="text-gray-800 text-sm font-semibold">
          Effect Customization
        </div>
        <div className="flex flex-col gap-3">
          <div
            id="no-effect"
            className={`flex-1 select-none rounded-lg text-center border-2 cursor-pointer ${
              styles.noEffect ? "border-blue-500" : "border-transparent"
            } `}
            onClick={() => {
              onChange({
                ...styles,
                noEffect: !styles.noEffect,
                stroke: {
                  ...styles.stroke,
                  enabled: false,
                },
                textBgColor: {
                  ...styles.textBgColor,
                  enabled: false,
                },
                shadow: {
                  ...styles.shadow,
                  enabled: false,
                },
                blockBackground: {
                  ...styles.blockBackground,
                  enabled: false,
                },
                padding: INIT_TEXT_STYLES.padding,
                margin: INIT_TEXT_STYLES.margin,
              });
              eventsDataToRedux(
                ANALYTICS_CONSTANTS.TEXT_EFFECT,
                ANALYTICS_CONSTANTS.NO_EFFECT
              );
            }}
          >
            <div className="h-20 font-bold flex flex-1 items-center justify-center rounded-lg border border-gray-200 bg-white shadow-md">
              No effect
            </div>
          </div>
          <div
            id="text-bg-effect"
            className={`flex-1 cursor-pointer select-none rounded-lg text-center border-2 ${
              styles.textBgColor.enabled
                ? "border-blue-500"
                : "border-transparent"
            } `}
            onClick={() => {
              onChange({
                ...styles,
                noEffect: false,
                textBgColor: {
                  ...styles.textBgColor,
                  enabled: !styles.textBgColor.enabled,
                },
                padding: INIT_TEXT_STYLES.padding,
                margin: INIT_TEXT_STYLES.margin,
              });
              eventsDataToRedux(
                ANALYTICS_CONSTANTS.TEXT_EFFECT,
                ANALYTICS_CONSTANTS.TEXT_BACKGROUND
              );
            }}
          >
            <div className="flex h-20 justify-between items-center rounded-lg border border-gray-200 bg-white shadow-md">
              <img
                className="h-8 ml-4 rounded-lg dark:border-gray-700 dark:bg-gray-800 "
                src={BackgroundEffect}
                alt="Text Background Effect"
              />
              <div className="flex gap-5 items-center mr-5">
                <IconPopover
                  triggerElement={
                    <AdjustmentsHorizontalIcon className="h-6 w-6 text-gray-600" />
                  }
                >
                  <div className="flex flex-col bg-white p-4 rounded shadow-md mr-16 mb-2 border">
                    <div className="flex justify-between items-center">
                      <div className="text-gray-800 text-sm font-semibold">
                        Margin
                      </div>
                      <div className="text-gray-800 text-sm font-medium">
                        {styles.margin.bottom}
                      </div>
                    </div>
                    <RangeSlider
                      value={styles.margin.bottom}
                      min={MIN_TEXTBOX_RADIUS}
                      max={MAX_TEXTBOX_RADIUS}
                      onChange={(e: any) => {
                        onChange({
                          ...styles,
                          margin: {
                            ...INIT_TEXT_STYLES.margin,
                            bottom: parseInt(e.target.value),
                          },
                        });
                      }}
                      className="w-60"
                    />
                    <div className="flex justify-between items-center">
                      <div className="text-gray-800 text-sm font-semibold">
                        Padding
                      </div>
                      <div className="text-gray-800 text-sm font-medium">
                        {styles.padding.top}
                      </div>
                    </div>
                    <RangeSlider
                      value={styles.padding.top}
                      min={MIN_TEXTBOX_RADIUS}
                      max={MAX_TEXTBOX_RADIUS}
                      onChange={(e: any) => {
                        onChange({
                          ...styles,
                          padding: {
                            top: parseInt(e.target.value),
                            left: parseInt(e.target.value),
                            right: parseInt(e.target.value),
                            bottom: parseInt(e.target.value),
                          },
                        });
                      }}
                      className="w-60"
                    />
                    <div className="flex justify-between items-center">
                      <div className="text-gray-800 text-sm font-semibold">
                        Radius
                      </div>
                      <div className="text-gray-800 text-sm font-medium">
                        {styles.textBgColor.radius}
                      </div>
                    </div>
                    <RangeSlider
                      value={styles.textBgColor.radius}
                      min={MIN_TEXTBOX_RADIUS}
                      max={MAX_TEXTBOX_RADIUS}
                      onChange={(e: any) => {
                        onChange({
                          ...styles,
                          textBgColor: {
                            ...styles.textBgColor,
                            radius: parseInt(e.target.value),
                          },
                        });
                      }}
                      className="w-60"
                    />
                    <div
                      className="text-sm font-semibold underline text-blue-500 mt-5 cursor-pointer"
                      onClick={() => {
                        onChange({
                          ...styles,
                          textBgColor: {
                            ...styles.textBgColor,
                            radius: DEFAULT_TEXT_BACKGROUND_RADIUS,
                          },
                          padding: INIT_TEXT_STYLES.padding,
                          margin: INIT_TEXT_STYLES.margin,
                        });
                      }}
                    >
                      Reset to default
                    </div>
                  </div>
                </IconPopover>
                <DropDownColorPicker
                  color={styles.textBgColor.color}
                  onChange={(color: string) =>
                    onChange({
                      ...styles,
                      textBgColor: {
                        ...styles.textBgColor,
                        color,
                      },
                    })
                  }
                  position="bottom"
                  width="w-5"
                  height="h-5"
                  right="-right-7"
                />
              </div>
            </div>
          </div>
          <div
            id="block-effect"
            className={`flex-1 select-none  cursor-pointer rounded-lg text-center border-2 ${
              styles.blockBackground.enabled
                ? "border-blue-500"
                : "border-transparent"
            } `}
            onClick={() => {
              onChange({
                ...styles,
                noEffect: false,
                blockBackground: {
                  ...styles.blockBackground,
                  enabled: !styles.blockBackground.enabled,
                },
              });
              eventsDataToRedux(
                ANALYTICS_CONSTANTS.TEXT_EFFECT,
                ANALYTICS_CONSTANTS.BLOCK_BACKGROUND
              );
            }}
          >
            <div className="flex h-20 justify-between items-center rounded-lg border border-gray-200 bg-white shadow-md">
              <img
                className="h-8 ml-4 rounded-lg dark:border-gray-700 dark:bg-gray-800"
                src={BlockEffect}
                alt="Box Effect"
              />
              <div className="flex gap-5 mr-5 items-center">
                <IconPopover
                  triggerElement={
                    <AdjustmentsHorizontalIcon className="h-6 w-6 text-gray-600" />
                  }
                >
                  <div className="flex flex-col bg-white p-4 rounded shadow-md mr-16 mb-2 border">
                    <div className="flex justify-between items-center">
                      <div className="text-gray-800 text-sm font-semibold">
                        Radius
                      </div>
                      <div className="text-gray-800 text-sm font-medium">
                        {styles.blockBackground.radius}
                      </div>
                    </div>
                    <RangeSlider
                      value={styles.blockBackground.radius}
                      min={MIN_TEXTBOX_RADIUS}
                      max={MAX_TEXTBOX_RADIUS}
                      onChange={(e: any) => {
                        onChange({
                          ...styles,
                          blockBackground: {
                            ...styles.blockBackground,
                            radius: parseInt(e.target.value),
                          },
                        });
                      }}
                      className="w-60"
                    />
                    {
                      // NOTE: Below snipped is commented because this has to be included in next release.
                      <>
                        <div className="flex justify-between items-center">
                          <div className="text-gray-800 text-sm font-semibold">
                            Padding
                          </div>
                          <div className="text-gray-800 text-sm font-medium">
                            {styles.blockBackground.padding}
                          </div>
                        </div>
                        <RangeSlider
                          value={styles.blockBackground.padding}
                          min={0}
                          max={20}
                          onChange={(e: any) => {
                            onChange({
                              ...styles,
                              blockBackground: {
                                ...styles.blockBackground,
                                padding: parseInt(e.target.value),
                              },
                            });
                          }}
                          className="w-60"
                        />
                      </>
                    }
                    <div
                      className="text-sm font-semibold underline text-blue-500 mt-5 cursor-pointer"
                      onClick={() => {
                        onChange({
                          ...styles,
                          blockBackground: {
                            ...styles.blockBackground,
                            radius: DEFAULT_BLOCK_BACKGROUND_RADIUS,
                            padding: INIT_TEXT_STYLES.blockBackground.padding,
                          },
                        });
                      }}
                    >
                      Reset to default
                    </div>
                  </div>
                </IconPopover>

                <DropDownColorPicker
                  color={styles.blockBackground.color}
                  onChange={(color: string) =>
                    onChange({
                      ...styles,
                      blockBackground: {
                        ...styles.blockBackground,
                        color,
                      },
                    })
                  }
                  width="w-5"
                  height="h-5"
                  right="-right-7"
                />
              </div>
            </div>
          </div>
          <div
            id="outline-effect"
            className={`flex-1 select-none rounded-lg text-center border-2 ${
              styles.stroke.enabled ? "border-blue-500" : "border-transparent"
            } `}
            onClick={() => {
              onChange({
                ...styles,
                noEffect: false,
                stroke: {
                  ...styles.stroke,
                  enabled: !styles.stroke.enabled,
                },
              });
              eventsDataToRedux(
                ANALYTICS_CONSTANTS.TEXT_EFFECT,
                ANALYTICS_CONSTANTS.OUTLINE
              );
            }}
          >
            <div className="flex cursor-pointer justify-between items-center rounded-lg border border-gray-200 bg-white shadow-md">
              <img
                className="h-20 rounded-lg dark:border-gray-700 dark:bg-gray-800"
                src={OutlineEffect}
                alt="Outline Effect"
              />
              <div className="flex gap-5 mr-5 items-center">
                <IconPopover
                  triggerElement={
                    <AdjustmentsHorizontalIcon className="h-6 w-6 text-gray-600" />
                  }
                >
                  <div className="flex flex-col bg-white p-4 rounded shadow-md mr-16 mb-2 border">
                    <div className="flex justify-between items-center">
                      <div className="text-gray-800 text-sm font-semibold">
                        Thickness
                      </div>
                      <div className="text-gray-800 text-sm font-medium">
                        {styles.stroke.config.width}
                      </div>
                    </div>
                    <RangeSlider
                      value={styles.stroke.config.width}
                      min={MIN_STROKE_WIDTH}
                      max={MAX_STROKE_WIDTH}
                      onChange={(e: any) =>
                        onChange({
                          ...styles,
                          stroke: {
                            ...styles.stroke,
                            config: {
                              ...styles.stroke.config,
                              width: e.target.value,
                            },
                          },
                        })
                      }
                      className="w-60"
                    />
                    <div
                      className="text-sm font-semibold underline text-blue-500 mt-5 cursor-pointer"
                      onClick={() => {
                        onChange({
                          ...styles,
                          stroke: {
                            ...styles.stroke,
                            config: {
                              ...styles.stroke.config,
                              width: DEFAULT_STROKE_WIDTH,
                            },
                          },
                        });
                      }}
                    >
                      Reset to default
                    </div>
                  </div>
                </IconPopover>
                <DropDownColorPicker
                  color={styles.stroke.color}
                  onChange={(color: string) =>
                    onChange({
                      ...styles,
                      stroke: {
                        ...styles.stroke,
                        color,
                      },
                    })
                  }
                  position="top"
                  width="w-5"
                  height="h-5"
                  right="-right-7"
                />
              </div>
            </div>
          </div>
          <div
            id="shadow-effect"
            className={`flex-1 cursor-pointer select-none rounded-lg text-center border-2 ${
              styles.shadow.enabled ? "border-blue-500" : "border-transparent"
            } `}
            onClick={() => {
              onChange({
                ...styles,
                noEffect: false,
                shadow: {
                  ...styles.shadow,
                  enabled: !styles.shadow.enabled,
                },
              });
              eventsDataToRedux(
                ANALYTICS_CONSTANTS.TEXT_EFFECT,
                ANALYTICS_CONSTANTS.SHADOW
              );
            }}
          >
            <div className="flex justify-between items-center rounded-lg border border-gray-200 bg-white shadow-md">
              <img
                className="h-20 rounded-lg dark:border-gray-700 dark:bg-gray-800"
                src={ShadowEffect}
                alt="Shadow Effect"
              />
              <div className="flex gap-5 mr-5 items-center">
                <IconPopover
                  triggerElement={
                    <AdjustmentsHorizontalIcon className="h-6 w-6 text-gray-600" />
                  }
                >
                  <div className="flex flex-col bg-white p-4 rounded shadow-md mr-16 mb-2 border">
                    <div className="flex justify-between items-center">
                      <div className="text-gray-800 text-sm font-semibold">
                        OffsetX
                      </div>
                      <div className="text-gray-800 text-sm font-medium">
                        {styles.shadow.config.offsetX}
                      </div>
                    </div>
                    <RangeSlider
                      value={styles.shadow.config.offsetX}
                      min={MIN_SHADOW_OFFSET}
                      max={MAX_SHADOW_OFFSET}
                      onChange={(e: any) =>
                        onChange({
                          ...styles,
                          shadow: {
                            ...styles.shadow,
                            config: {
                              ...styles.shadow.config,
                              offsetX: e.target.value,
                            },
                          },
                        })
                      }
                      className="w-60"
                    />
                    <div className="flex justify-between items-center mt-5">
                      <div className="text-gray-800 text-sm font-semibold">
                        OffsetY
                      </div>
                      <div className="text-gray-800 text-sm font-medium">
                        {styles.shadow.config.offsetY}
                      </div>
                    </div>
                    <RangeSlider
                      value={styles.shadow.config.offsetY}
                      min={MIN_SHADOW_OFFSET}
                      max={MAX_SHADOW_OFFSET}
                      onChange={(e: any) =>
                        onChange({
                          ...styles,
                          shadow: {
                            ...styles.shadow,
                            config: {
                              ...styles.shadow.config,
                              offsetY: e.target.value,
                            },
                          },
                        })
                      }
                      className="w-60"
                    />
                    <div className="flex justify-between items-center mt-5">
                      <div className="text-gray-800 text-sm font-semibold">
                        Blur
                      </div>
                      <div className="text-gray-800 text-sm font-medium">
                        {styles.shadow.config.blur}
                      </div>
                    </div>
                    <RangeSlider
                      value={styles.shadow.config.blur}
                      min={MIN_SHADOW_BLUR}
                      max={MAX_SHADOW_BLUR}
                      onChange={(e: any) =>
                        onChange({
                          ...styles,
                          shadow: {
                            ...styles.shadow,
                            config: {
                              ...styles.shadow.config,
                              blur: e.target.value,
                            },
                          },
                        })
                      }
                      className="w-60"
                    />
                    <div
                      className="text-sm font-semibold underline text-blue-500 mt-5 cursor-pointer"
                      onClick={() => {
                        onChange({
                          ...styles,
                          shadow: {
                            ...styles.shadow,
                            config: {
                              ...styles.shadow.config,
                              offsetX: DEFAULT_SHADOW_PROPERTIES.offsetX,
                              offsetY: DEFAULT_SHADOW_PROPERTIES.offsetY,
                              blur: DEFAULT_SHADOW_PROPERTIES.blur,
                            },
                          },
                        });
                      }}
                    >
                      Reset to default
                    </div>
                  </div>
                </IconPopover>
                <DropDownColorPicker
                  color={styles.shadow.color}
                  onChange={(color: string) =>
                    onChange({
                      ...styles,
                      shadow: {
                        ...styles.shadow,
                        color,
                      },
                    })
                  }
                  position="top"
                  width="w-5"
                  height="h-5"
                  right="-right-7"
                />
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default TextStyleEditor;
