import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "@/store/hooks";

import useAddUserOnboardingData from "@/api/useAddUserOnboardingData";

import { addOnboardingDataForTracking } from "@/helpers";

import {
  trackUserOnboardingData,
  trackViewOnboardingEvent,
} from "@/utils/amplitudeAnalytcs";

import { UserOnboarding as UserOnBoardingType } from "@/interfaces";

import { OnboardingReferer, PlanType, UserOnboardingSteps } from "@/enums";

import Header from "@/components/Header";
import Protick from "@/components/Protick";

import {
  HandelOnboardingDataType,
  Occupation,
  Referer,
  TeamQuestionnaires,
  UserMainFocus,
} from "./components/UserOnBoardingStages";

const UserOnboarding = ({ country }: { country: any }) => {
  const navigate = useNavigate();

  const { uid, email, displayName } = useAppSelector(
    (state) => state.authState.currentUser
  );

  const currentUserSubscriptionDetails = useAppSelector(
    (state) => state.authState.userSubscription
  );
  const { planType } = currentUserSubscriptionDetails;

  const [currentOnBoardingState, setCurrentOnBoardingState] = useState(
    UserOnboardingSteps.OCCUPATION
  );
  const [isDeleting, setIsDeleting] = useState(false);
  const [showNotSupportedMsg, setShowNotSupportedMsg] = useState("");

  const [onBoardingData, setOnBoardingData] = useState<UserOnBoardingType>({
    occupation: "",
    isIndividual: null,
    user_wants_to: [],
    referer: "",
  });

  const { mutate, isLoading } = useAddUserOnboardingData();

  const handleOnBoardingData = ({
    onBoardingData,
  }: HandelOnboardingDataType) => {
    setOnBoardingData((prev) => ({ ...prev, ...onBoardingData }));
  };

  const isAllowedToProceed = () => {
    if (currentOnBoardingState === UserOnboardingSteps.USER_WANTS) {
      return onBoardingData.user_wants_to.length > 0;
    }

    return true;
  };

  const handleNext = (nextStep?: number, value?: any) => {
    if (isAllowedToProceed()) {
      if (currentOnBoardingState === UserOnboardingSteps.REFERER) {
        const updatedOnboardingData = {
          ...onBoardingData,
          referer: value,
          email: email,
        };
        mutate(
          {
            onBoardingData: updatedOnboardingData,
            userId: uid,
          },
          {
            onSuccess: () => {
              addOnboardingDataForTracking(uid, updatedOnboardingData);
              trackUserOnboardingData(updatedOnboardingData);
              navigate("/home", { replace: true });
            },
            onError: () => {
              setOnBoardingData({
                occupation: "",
                isIndividual: true,
                user_wants_to: [],
                referer: OnboardingReferer.GOOGLE,
              });
              setCurrentOnBoardingState(UserOnboardingSteps.OCCUPATION);
            },
          }
        );
      } else {
        if (nextStep) {
          setCurrentOnBoardingState(nextStep);
        } else {
          setCurrentOnBoardingState((prev) => prev + 1);
        }
      }
    }
  };

  useEffect(() => {
    trackViewOnboardingEvent();
  }, []);

  const ONBOARDING_STAGES = {
    [UserOnboardingSteps.OCCUPATION]: (
      <Occupation
        handleOnBoardingData={handleOnBoardingData}
        occupation={onBoardingData.occupation}
        handleNext={handleNext}
      />
    ),
    [UserOnboardingSteps.USER_TYPE]: (
      <TeamQuestionnaires
        handleOnBoardingData={handleOnBoardingData}
        isIndividual={onBoardingData.isIndividual}
        handleNext={handleNext}
      />
    ),
    [UserOnboardingSteps.USER_WANTS]: (
      <UserMainFocus
        handleOnBoardingData={handleOnBoardingData}
        userWants={onBoardingData.user_wants_to}
      />
    ),
    [UserOnboardingSteps.REFERER]: (
      <Referer
        handleOnBoardingData={handleOnBoardingData}
        referer={onBoardingData.referer}
        handleNext={handleNext}
        isMutationLoading={isLoading}
      />
    ),
  };

  return (
    <main className="mx-auto  h-[100vh] w-full max-w-screen-2xl bg-[#e8e8ee]">
      <Header showHomeOnly={true} />

      <>
        <div className="relative px-4 lg:px-6">
          <div className="my-6 flex justify-between">
            <div className="flex items-center space-x-3">
              <p className="truncate sm:tracking-wider max-w-[300px] sm:max-w-none">
                <span className="text-xl sm:text-2xl">Welcome,</span>{" "}
                <span className="text-xl font-medium sm:text-2xl">
                  {displayName || email} 👋🏼
                </span>
              </p>
              {PlanType && <Protick planType={planType} />}
            </div>
          </div>
        </div>
        <div className="relative mx-auto flex h-[70%] rounded-lg bg-white py-6 sm:w-11/12 md:w-[70%] lg:w-[960px]">
          <button
            className="invisible mx-2 text-gray-500 sm:ml-6"
            tabIndex={-1}
            disabled={isLoading}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1}
              stroke="currentColor"
              className="h-8 w-8 sm:h-10 sm:w-10"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>

          <div className="relative mx-auto  flex w-full flex-col justify-between overflow-y-auto text-center">
            {ONBOARDING_STAGES[currentOnBoardingState]}
          </div>

          <button
            className={`mx-2 sm:mr-6 ${
              isAllowedToProceed() ? "text-white" : "text-gray-500"
            } ${
              currentOnBoardingState === UserOnboardingSteps.USER_WANTS &&
              isAllowedToProceed()
                ? "visible"
                : "invisible"
            } `}
            tabIndex={-1}
            onClick={() => handleNext()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#2563eb"
              viewBox="0 0 24 24"
              strokeWidth={1}
              stroke="currentColor"
              className="h-8 w-8 sm:h-10 sm:w-10"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>

          <ul className="absolute bottom-3 left-1/2 mx-auto mt-10 flex w-min -translate-x-1/2 space-x-1.5">
            <li
              className={`${
                currentOnBoardingState === UserOnboardingSteps.OCCUPATION
                  ? "w-10 bg-blue-600"
                  : "w-2 bg-gray-200"
              } h-2 rounded-lg`}
            ></li>
            <li
              className={`${
                currentOnBoardingState === UserOnboardingSteps.USER_TYPE
                  ? "w-10 bg-blue-600"
                  : "w-2 bg-gray-200"
              } h-2 rounded-lg`}
            ></li>
            <li
              className={`${
                currentOnBoardingState === UserOnboardingSteps.USER_WANTS
                  ? "w-10 bg-blue-600"
                  : "w-2 bg-gray-200"
              } h-2 rounded-lg`}
            ></li>
            <li
              className={`${
                currentOnBoardingState === UserOnboardingSteps.REFERER
                  ? "w-10 bg-blue-600"
                  : "w-2 bg-gray-200"
              } h-2 rounded-lg`}
            ></li>
          </ul>
        </div>
      </>
    </main>
  );
};

export default UserOnboarding;
