import {
  updateOnBoardingDataById,
  updateUserSelectedLayouts,
} from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import useGetDefaultBaseTemplates from "@/api/useGetDefaultBaseTemplates";
import useGetUserPreference from "@/api/useGetUserPreference";
import useGetUserTemplates from "@/api/useGetUserTemplates";

import { getAllBaseTemplates, getPreferredTemplate } from "@/helpers";

import { VideoLayout } from "@/enums";

const LayoutCard = ({ layoutData }: any) => {
  const onBoardingId = useAppSelector(
    (state) => state.homeState.currentOnBoardingId
  );

  const onBoardingData = useAppSelector(
    (state) => state.homeState.onBoardingData
  );

  const uid = useAppSelector((state) => state.authState.currentUser.uid);

  const data = useGetUserPreference(uid);

  const isLayoutPresentInSelectedLayouts = (layoutId: string) => {
    return onBoardingData[onBoardingId].selectedLayouts.includes(layoutId);
  };

  const dispatch = useAppDispatch();

  const defaultBaseTemplates = useGetDefaultBaseTemplates();
  const userTemplatesData = useGetUserTemplates(uid);

  const pickLayoutArray = (layoutId: VideoLayout | string) => {
    const baseTemplates = getAllBaseTemplates(
      defaultBaseTemplates?.data,
      userTemplatesData?.data
    );

    return data.data?.savePreference
      ? getPreferredTemplate({
          layout: layoutId as VideoLayout,
          baseTemplates,
          savedTemplates: data.data.baseTemplates,
        })
      : baseTemplates[layoutId as VideoLayout];
  };

  const updateLayoutSelection = () => {
    const baseTemplates = getAllBaseTemplates(
      defaultBaseTemplates?.data,
      userTemplatesData?.data
    );

    if (isLayoutPresentInSelectedLayouts(layoutData.id)) {
      let newBaseTemplates = {
        ...baseTemplates,
        [layoutData.id]: [],
      };
      if (layoutData.id === VideoLayout.LAYOUT_9_16_1) {
        newBaseTemplates = {
          ...newBaseTemplates,
          [VideoLayout.LAYOUT_9_16_2]: [],
        };
      }
      Object.keys(baseTemplates).forEach((key) => {
        if (!isLayoutPresentInSelectedLayouts(key)) {
          newBaseTemplates = {
            ...newBaseTemplates,
            [key]: [],
          };
        }
      });

      const newSelectedLayoutsArray = onBoardingData[
        onBoardingId
      ].selectedLayouts.filter((id) => {
        if (layoutData.id === VideoLayout.LAYOUT_9_16_1) {
          return id !== layoutData.id && id !== VideoLayout.LAYOUT_9_16_2;
        }
        return id !== layoutData.id;
      });
      dispatch(updateUserSelectedLayouts(newSelectedLayoutsArray));

      dispatch(
        updateOnBoardingDataById({
          id: onBoardingId,
          data: {
            selectedLayouts: newSelectedLayoutsArray,
            baseTemplates: newBaseTemplates,
          },
        })
      );
    } else {
      let newBaseTemplates: any = {
        [layoutData.id]: pickLayoutArray(layoutData.id),
      };

      Object.keys(baseTemplates).forEach((key) => {
        // check if key present in selectedLayoutsArray
        if (
          isLayoutPresentInSelectedLayouts(key) ||
          key === layoutData.id ||
          key === VideoLayout.LAYOUT_9_16_2
        ) {
          newBaseTemplates = {
            ...newBaseTemplates,
            [key]: pickLayoutArray(key),
          };
          if (key === VideoLayout.LAYOUT_9_16_1) {
            newBaseTemplates = {
              ...newBaseTemplates,
              [VideoLayout.LAYOUT_9_16_1]: pickLayoutArray(
                VideoLayout.LAYOUT_9_16_1
              ),
              [VideoLayout.LAYOUT_9_16_2]: pickLayoutArray(
                VideoLayout.LAYOUT_9_16_2
              ),
            };
          } else {
            newBaseTemplates = {
              ...newBaseTemplates,
              [key]: pickLayoutArray(key),
            };
          }
        } else {
          newBaseTemplates = {
            ...newBaseTemplates,
            [key]: [],
          };
        }
      });

      let newSelectedLayoutsArray = [
        ...onBoardingData[onBoardingId].selectedLayouts,
        layoutData.id,
      ];
      if (layoutData.id === VideoLayout.LAYOUT_9_16_1) {
        newSelectedLayoutsArray = [
          ...newSelectedLayoutsArray,
          VideoLayout.LAYOUT_9_16_2,
        ];
      }
      dispatch(updateUserSelectedLayouts(newSelectedLayoutsArray));

      dispatch(
        updateOnBoardingDataById({
          id: onBoardingId,
          data: {
            selectedLayouts: newSelectedLayoutsArray,
            baseTemplates: newBaseTemplates,
          },
        })
      );
    }
  };

  return (
    <div className="relative flex h-80 w-64 cursor-pointer select-none justify-center rounded-xl border bg-white shadow-md">
      <div className="absolute left-3 top-4 mb-4 flex items-center">
        <input
          id={layoutData.id}
          type="checkbox"
          value=""
          checked={isLayoutPresentInSelectedLayouts(layoutData.id)}
          onChange={updateLayoutSelection}
          className="h-6 w-6 rounded-full border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
        />
      </div>
      <label
        htmlFor={layoutData.id}
        className="h-full w-full cursor-pointer"
      >
        <div className="mt-10 flex flex-col items-center justify-center space-y-6">
          <img
            src={layoutData.imgUrl}
            className={`rounded-xl ${
              layoutData.title === "Landscape (16:9)" ? "mt-8" : ""
            }`}
          />

          <div>
            <p
              className={`font-semibold text-gray-900 ${
                layoutData.title === "Landscape (16:9)" ? "mt-7" : ""
              }`}
            >
              {layoutData.title}
            </p>
            <div className="mt-3 flex items-center justify-center space-x-2">
              {layoutData.recommendedPlatforms.map((platform: any) => {
                return (
                  <img
                    key={platform.id}
                    src={platform.logoUrl}
                    className="h-5 w-5"
                  />
                );
              })}
            </div>
          </div>
        </div>
      </label>
    </div>
  );
};

export default LayoutCard;
