import { useEffect } from "react";

import { updateUserSocialMediaHandles } from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import useGetUserPreference from "@/api/useGetUserPreference";

const SocialInput = ({ socialData }: any) => {
  const uid = useAppSelector((state) => state.authState.currentUser.uid);
  const userSocialMediaHandles = useAppSelector(
    (state) => state.homeState.userSocialMediaHandles
  );

  const dispatch = useAppDispatch();

  const { data: userPreferenceData } = useGetUserPreference(uid);

  const addOrReplaceObjectInSocialMediaHandlesArray = (
    arr: any,
    newObj: any
  ) => [...arr.filter((o: any) => o.id !== newObj.id), { ...newObj }];

  const updateSocialMediaHandle = (e: any) => {
    const socialMediaHandleValue = e.target.value;

    const newUserSocialMediaHandles =
      addOrReplaceObjectInSocialMediaHandlesArray(userSocialMediaHandles, {
        id: socialData.id,
        socialMediaHandle: socialMediaHandleValue,
        label: socialData.labelName,
      });

    dispatch(updateUserSocialMediaHandles(newUserSocialMediaHandles));
  };

  const getSocialInputValue = () => {
    const socialsArray = userSocialMediaHandles;
    const socialMediaHandle = socialsArray.find(
      (o: any) => o.id === socialData.id
    );
    return socialMediaHandle?.socialMediaHandle || "";
  };

  useEffect(() => {
    if (userPreferenceData?.socialMediaHandles?.length) {
      dispatch(
        updateUserSocialMediaHandles(userPreferenceData?.socialMediaHandles)
      );
    }
  }, [userPreferenceData]);

  return (
    <div>
      <label
        htmlFor={socialData.id}
        className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300"
      >
        {socialData.labelName}
      </label>
      <input
        type="text"
        name={socialData.id}
        id={socialData.id}
        value={getSocialInputValue()}
        onChange={updateSocialMediaHandle}
        className="block w-48 rounded-lg border border-gray-300 
        bg-gray-50 p-2 text-sm text-gray-900 focus:border-blue-500 
        focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 
        dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
        placeholder={socialData.placeHolder}
      />
    </div>
  );
};

export default SocialInput;
