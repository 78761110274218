import { useQuery } from "@tanstack/react-query";

import { getUserTemplates } from "@/api/requests";

export default function useGetUserTemplates(userId: string) {
  return useQuery({
    queryKey: ["user-template", userId],
    queryFn: () => getUserTemplates(userId),
    refetchOnWindowFocus: false,
    staleTime: 300000,
  });
}
