const Swatch = ({
  color,
  onClick,
  title = color,
  children,
  focus,
}: {
  color: string;
  onClick: (color: { hex: string }) => void;
  title?: string;
  children?: React.ReactNode;
  focus: boolean;
}) => {
  const handleClick = () => onClick({ hex: color });

  return (
    <div
      className="w-[50px] h-[40px] rounded-md flex items-center  mr-1.5 mb-1.5 cursor-pointer border"
      onClick={handleClick}
      title={title}
      tabIndex={0}
      style={{
        backgroundColor: color,
        boxShadow: focus ? `0 0 4px ${color}` : "none",
      }}
    >
      {children}
    </div>
  );
};

export default Swatch;
