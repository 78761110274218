import { useState } from "react";
import { ChromePicker } from "react-color";

import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { Button, Spinner } from "flowbite-react";

import { useAppSelector } from "@/store/hooks";

import useAddUserPreferenceData from "@/api/useAddUserPreferenceData";
import useGetUserPreference from "@/api/useGetUserPreference";

import BaseModal from "@/components/BaseModal";

type ColorUploadModalProps = {
  showColorUploadModal: boolean;
  setShowColorUploadModal: (value: boolean) => void;
};

const ColorUploadModal = ({
  showColorUploadModal,
  setShowColorUploadModal,
}: ColorUploadModalProps) => {
  const [currentColor, setCurrentColor] = useState("#22194D");

  const uid = useAppSelector((state) => state.authState.currentUser.uid);

  const { data: userPreferenceData } = useGetUserPreference(uid);

  const {
    mutate: addUserPreferenceData,
    isLoading: isAddColorMutationLoading,
  } = useAddUserPreferenceData();

  const handleAddColorToPalette = (colorInHex: string) => {
    let requestData = {};
    if (userPreferenceData?.createdAt) {
      requestData = {
        ...userPreferenceData,
        colorPallets: [...userPreferenceData?.colorPallets, colorInHex],
      };
    } else {
      requestData = {
        userPreferenceData: {},
        selectedLayouts: [],
        socialMediaHandles: [],
        savePreference: false,
        createdAt: new Date(),
        colorPallets: [colorInHex],
        isVisitedBrandKitPage: false,
      };
    }
    addUserPreferenceData(
      {
        userId: uid,
        userPreferenceData: requestData,
      },
      {
        onSuccess: () => {
          setShowColorUploadModal(false);
        },
      }
    );
  };

  return (
    <BaseModal
      isModalOpen={showColorUploadModal}
      handelModalOpen={setShowColorUploadModal}
      notClosable={isAddColorMutationLoading}
    >
      <button
        type="button"
        className="absolute top-4 right-2.5 z-10 ml-auto inline-flex items-center rounded-lg bg-blue-100 p-1.5 text-sm text-gray-400 hover:bg-blue-200 hover:text-gray-900 dark:hover:bg-gray-800 dark:hover:text-white"
        onClick={() => {
          setShowColorUploadModal(false);
        }}
      >
        <svg
          aria-hidden="true"
          className="h-5 w-5"
          fill="#1C64F2"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
        <span className="sr-only">Close modal</span>
      </button>
      <div
        className="bg-white w-96 py-8"
        id="closeModal"
      >
        <p className="text-center text-2xl text-gray-900 mb-6 font-bold">
          Add a color
        </p>
        <div className="flex justify-center items-center">
          <ChromePicker
            color={currentColor}
            onChange={(c: any) => setCurrentColor(c.hex)}
          />
        </div>
        <Button
          id="add-brand-kiy-color-btn"
          size="sm"
          className="mx-auto mt-6 w-40"
          onClick={() => handleAddColorToPalette(currentColor)}
          disabled={isAddColorMutationLoading}
        >
          {isAddColorMutationLoading && <Spinner className="h-5 w-5 mr-2" />}
          Add color
        </Button>
      </div>
    </BaseModal>
  );
};

export default ColorUploadModal;
