import { useState, useRef, useEffect, FC } from "react";

const MediaVideo: FC<{ src: string; handleUpdateDuration: any }> = ({
  src,
  handleUpdateDuration,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    videoRef.current!.volume = 0;

    const handleLoadedData = () => {
      handleUpdateDuration(Math.floor(videoElement!.duration));
      setIsLoading(false);
    };

    const handleMouseEnter = () => {
      videoElement?.play();
    };

    const handleMouseLeave = () => {
      videoElement?.pause();
    };

    if (videoElement) {
      videoElement.addEventListener("loadeddata", handleLoadedData);
      videoElement.addEventListener("mouseenter", handleMouseEnter);
      videoElement.addEventListener("mouseleave", handleMouseLeave);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("loadeddata", handleLoadedData);
        videoElement.removeEventListener("mouseenter", handleMouseEnter);
        videoElement.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, []);

  return (
    <>
      {isLoading && (
        <div className="w-full h-full bg-gradient-to-t from-gray-100 to-gray-200 animate-pulse absolute z-10"></div>
      )}
      <video
        className={`object-scale-down w-full h-full ${
          isLoading ? "hidden" : ""
        }`}
        controls={false}
        preload="metadata"
        ref={videoRef}
      >
        <source src={src} />
        Your browser does not support the video tag.
      </video>
    </>
  );
};

export default MediaVideo;
