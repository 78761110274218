import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "@/api/api";
import { ApiEndpoints } from "@/api/constants/ApiEndPoints";

const updateProject = async (reqData: any) => {
  const { data } = await api.patch(ApiEndpoints.ALL_PROJECTS, reqData);
  return data;
};

export default function useUpdateProject() {
  const queryClient = useQueryClient();

  return useMutation((data: any) => updateProject(data), {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries({ queryKey: ["all-projects"] });
      queryClient.invalidateQueries({ queryKey: ["project"] });
    },
  });
}
