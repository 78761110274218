import { SpeakerWaveIcon, SpeakerXMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { RangeSlider } from "flowbite-react";

const MIN_VOLUME = 0;
const MAX_VOLUME = 100;
const DEFAULT_VOLUME = 30;

const VolumeControl = ({
  volume = DEFAULT_VOLUME,
  setVolume,
}: {
  volume: number;
  setVolume: (value: number) => void;
}) => {
  const handleVolumeChange = (value: number) => {
    setVolume(Math.min(Math.max(value, MIN_VOLUME), MAX_VOLUME));
  };

  const handleMuteSound = () => {
    setVolume(volume === MIN_VOLUME ? DEFAULT_VOLUME : MIN_VOLUME);
  };

  const addValidation = (e: any) => {
    e.target.value = e.target.value.replace(/^0+/, "");
    const value = parseInt(e.target.value || MIN_VOLUME);
    handleVolumeChange(value);
  };

  return (
    <div className="h-6 flex justify-between items-center mt-2">
      <button
        onClick={handleMuteSound}
        className={clsx(
          "w-8 h-6 bg-white rounded-md border transition-colors",
          volume && "border-blue-500"
        )}
      >
        {volume === MIN_VOLUME ? (
          <SpeakerXMarkIcon className="w-3 h-3 mx-auto text-gray-500 " />
        ) : (
          <SpeakerWaveIcon className="w-3 h-3 mx-auto text-blue-500" />
        )}
      </button>
      <div className="w-full -mt-1.5 mx-2">
        <RangeSlider
          className="relative"
          min={MIN_VOLUME}
          max={MAX_VOLUME}
          value={volume}
          sizing="sm"
          onChange={(e) => handleVolumeChange(parseInt(e.target.value))}
        />
      </div>
      <input
        type="text"
        className="border w-9 h-6 rounded-md p-0.5 text-[11px] text-center border-gray-300 text-gray-600"
        value={`${volume}%`}
        pattern="\d*" // only allow numbers
        onChange={(e) =>
          handleVolumeChange(
            parseInt(e.target.value.replace(/\D/g, "")) || MIN_VOLUME
          )
        }
        onBlur={addValidation}
      />
    </div>
  );
};

export default VolumeControl;
