import { Button } from "flowbite-react";

import Spinner from "@/components/Loader/Spinner";

import BrandKitImg from "@/assets/images/brand-kit.svg";

type BrandKitSplashProps = {
  handleUpdateBrandKitVisitedState: () => void;
  addUserPreferenceMutationLoading: boolean;
};
const BrandKitSplash = ({
  handleUpdateBrandKitVisitedState,
  addUserPreferenceMutationLoading,
}: BrandKitSplashProps) => {
  return (
    <div className="col-span-2 mt-36 px-6 text-center text-sm text-[#5E6473]">
      <img
        src={BrandKitImg}
        className="mx-auto"
      />
      <p className="mx-auto mt-4 mb-6 w-96">
        <span className="font-semibold">Brand Kits</span> help you manage your
        custom assets like logos, fonts & colors - so your videos always looks
        good & on point.
      </p>

      <Button
        className="mx-auto w-60"
        onClick={handleUpdateBrandKitVisitedState}
        disabled={addUserPreferenceMutationLoading}
      >
        {addUserPreferenceMutationLoading && (
          <Spinner className="mr-2 h-5 w-5" />
        )}
        Create Your Brand Kit
      </Button>
    </div>
  );
};

export default BrandKitSplash;
