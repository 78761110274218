import { useState } from "react";
import { usePopper } from "react-popper";

import { Popover, Transition } from "@headlessui/react";

const IconPopover = ({ children, triggerElement }: any) => {
  let [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>();
  let [popperElement, setPopperElement] = useState<HTMLDivElement | null>();
  let { styles, attributes } = usePopper(referenceElement, popperElement);

  return (
    <Popover>
      <Popover.Button
        ref={setReferenceElement}
        className="cursor-pointer"
        as="div"
        onClick={(e: any) => e.stopPropagation()}
      >
        {triggerElement}
      </Popover.Button>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Popover.Panel
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          onClick={(e: any) => e.stopPropagation()}
        >
          {children}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default IconPopover;
