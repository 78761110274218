import { useState } from "react";
import { useEffectOnce } from "react-use";

import {
  MediaMenuList,
  MediaTypesUploadProperties,
} from "@/constants/brand-kit";

import { zE } from "@/helpers";

import HorizontalMenuTabContainer from "@/components/MenuTab/HorizontalMenuTabContainer";
import HorizontalMenuTabItem from "@/components/MenuTab/HorizontalMenuTabItem";

import AssetsContainer from "@/views/home/components/BrandAssets/AssetsContainer";

const BrandKit = () => {
  const [currentMenuTab, setCurrentMenuTab] = useState(MediaMenuList[0]);

  useEffectOnce(() => {
    zE("webWidget", "helpCenter:setSuggestions", {
      search: "brand kit",
    });
  });

  return (
    <>
      <HorizontalMenuTabContainer>
        {MediaMenuList.map((mediaTab) => (
          <HorizontalMenuTabItem
            key={mediaTab.id}
            label={mediaTab.label}
            Icon={mediaTab.Icon}
            isActive={currentMenuTab.id === mediaTab.id}
            onClick={() => setCurrentMenuTab(mediaTab)}
          />
        ))}
      </HorizontalMenuTabContainer>

      <AssetsContainer
        supportedFilesDisplayText={
          MediaTypesUploadProperties[currentMenuTab.type]
            .supportedFilesDisplayText
        }
        supportedMediaTypes={
          MediaTypesUploadProperties[currentMenuTab.type].supportedMediaTypes
        }
        assetTypeText={
          MediaTypesUploadProperties[currentMenuTab.type].assetTypeText
        }
        assetTag={MediaTypesUploadProperties[currentMenuTab.type].assetTag}
      />
    </>
  );
};

export default BrandKit;
