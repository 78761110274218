import { IconPropType } from "@/utils/constants";

export const YoutubeIcon = ({ fillColor }: IconPropType) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3382_21626)">
        <path
          d="M15.6811 4.12328C15.5906 3.78289 15.4123 3.47223 15.1641 3.22239C14.9159 2.97254 14.6063 2.79228 14.2665 2.69964C13.0156 2.36328 8.01562 2.36328 8.01562 2.36328C8.01562 2.36328 3.01563 2.36328 1.76472 2.69964C1.42491 2.79228 1.1154 2.97254 0.86715 3.22239C0.618902 3.47223 0.440629 3.78289 0.35017 4.12328C0.015625 5.37964 0.015625 7.99964 0.015625 7.99964C0.015625 7.99964 0.015625 10.6196 0.35017 11.876C0.440629 12.2164 0.618902 12.5271 0.86715 12.7769C1.1154 13.0267 1.42491 13.207 1.76472 13.2996C3.01563 13.636 8.01562 13.636 8.01562 13.636C8.01562 13.636 13.0156 13.636 14.2665 13.2996C14.6063 13.207 14.9159 13.0267 15.1641 12.7769C15.4123 12.5271 15.5906 12.2164 15.6811 11.876C16.0156 10.6196 16.0156 7.99964 16.0156 7.99964C16.0156 7.99964 16.0156 5.37964 15.6811 4.12328Z"
          fill={fillColor}
        />
        <path
          d="M6.37939 10.3793V5.62109L10.5612 8.00018L6.37939 10.3793Z"
          fill={fillColor === "white" ? "#6B7280" : "white"}
        />
      </g>
      <defs>
        <clipPath id="clip0_3382_21626">
          <rect
            width="16"
            height="16"
            fill={fillColor}
          />
        </clipPath>
      </defs>
    </svg>
  );
};
