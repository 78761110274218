import { useEffect, useRef } from "react";
import { useUnmount } from "react-use";

import {
  ArrowUpIcon,
  CheckCircleIcon,
  FolderArrowDownIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { useQueryClient } from "@tanstack/react-query";
import { Button } from "flowbite-react";

import api from "@/api/api";
import { ApiEndpoints } from "@/api/constants/ApiEndPoints";

import { downloadS3Url, sleep } from "@/helpers";

const clipsStatus: any = {
  complete: {
    Icon: () => <CheckCircleIcon className="w-5 h-5 text-green-500" />,
  },
  error: {
    Icon: () => <XCircleIcon className="w-5 h-5 text-red-500" />,
  },
  pending: {
    Icon: () => <ArrowPathIcon className="w-5 h-5 text-blue-500" />,
  },
  submitted: {
    Icon: () => <ArrowUpIcon className="w-5 h-5 text-blue-500" />,
  },
};

export const BulkExportClipsCard = ({ download }: { download: any }) => {
  const { zip_url, project_id, clips, render_task_id } = download;

  const queryClient = useQueryClient();

  const handleDownload = () => {
    downloadS3Url(zip_url, "", project_id, true);
  };

  const pollAbortController = useRef<any>(null);

  const pollForBulkVideo = async (renderTaskId: string) => {
    try {
      let controller;
      if (!pollAbortController.current) {
        controller = new AbortController();
        pollAbortController.current = controller;
      } else {
        controller = pollAbortController.current;
      }

      const response: any = await api.get(ApiEndpoints.GET_BULK_RENDER_VIDEO, {
        params: {
          render_task_id: renderTaskId,
          project_id: project_id,
        },
        signal: controller.signal,
      });

      console.log("running poll", response);

      if (!response.data.zip_url) {
        await sleep(10000);
        await pollForBulkVideo(renderTaskId);
      } else {
        queryClient.invalidateQueries({ queryKey: ["bulk-clip-downloads"] });
      }
    } catch (e: any) {
      if (e?.message === "canceled") {
        pollAbortController.current = null;
      }
    }
  };

  useEffect(() => {
    if (!zip_url) {
      pollForBulkVideo(render_task_id);
    }
  }, [zip_url]);

  useUnmount(() => {
    pollAbortController.current && pollAbortController.current.abort();
  });

  return (
    <div className=" bg-white h-80 w-96 rounded-md p-2 border shadow flex flex-col justify-between">
      <ul className="text-sm ">
        {clips?.map((clip: any) => {
          return (
            <li
              key={clip.id}
              className="mb-2 mt-2 w-full flex items-center justify-between px-2"
            >
              <div className="flex w-[90%] items-center">
                <span className="block w-[100%] truncate text-sm text-left border rounded-md p-2 text-gray-800">
                  {" "}
                  {clip.filename}
                </span>
              </div>
              {clipsStatus[clip.status].Icon()}
            </li>
          );
        })}
      </ul>
      <div className="flex justify-center my-2">
        <Button
          size="sm"
          onClick={handleDownload}
          disabled={!zip_url}
        >
          <FolderArrowDownIcon className="h-4 w-4 text-white mr-2" />
          Download Zip
        </Button>
      </div>
    </div>
  );
};
