import { Fragment, useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useEffectOnce } from "react-use";

import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { DocumentTextIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import { Badge, Button, Spinner } from "flowbite-react";

import { updateSelectedDraft } from "@/store/draftSlice";
import {
  changeSelectedLayout,
  toggleSaveDraft,
  toggleShowDownloadPreferenceModal,
  toggleShowSaveTemplateModal,
  updateAutoAddEmojisToSubtitles,
  updateIsUserChangeLayout,
} from "@/store/editorSlice";
import { updateCurrentSelectedMicroContent } from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import useGetDefaultBaseTemplates from "@/api/useGetDefaultBaseTemplates";

import { aspectRatioTitle } from "@/constants/aspect-ratio";
import { BUTTON_IDS } from "@/constants/segment-analytics";

import {
  getRandomTemplateByLayout,
  isAdminTemplateEditor,
  zE,
} from "@/helpers";

import { trackUserButtonEvent } from "@/utils/SegmentAnalytics";
import { trackLayoutChangeEvent } from "@/utils/amplitudeAnalytcs";

import { ScreenName, VideoLayout } from "@/enums";

// import Banner from "@/components/Banner";
import UploadVideoModal from "@/views/home/components/UploadVideoModal";

import AudyoAILogo from "@/assets/icons/audyo.png";
import SaveTemplateIcon from "@/assets/icons/save-template.svg";

const {
  SAVE_DRAFT_EDITOR_TOP_BTN,
  SAVE_TEMPLATE_EDITOR_TOP_BTN,
  EDITOR_RENDER_VIDEO_BTN,
} = BUTTON_IDS.EDITOR_LAYOUT;

const EditorLayout = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const [microContentGist, setMicroContentGist] = useState(null);

  const selectedLayout = useAppSelector(
    (state) => state.editorState.selectedLayout
  );
  const isRenderingVideo = useAppSelector(
    (state) => state.editorState.isRenderingVideo
  );

  const isUserChangedLayout = useAppSelector(
    (state) => state.editorState.isUserChangedLayout
  );
  const currentProject = useAppSelector(
    (state) => state.homeState.currentSelectedProject
  );

  const currentMicroContent = useAppSelector(
    (state) => state.homeState.currentSelectedMicroContent
  );

  const isShortVideo = useAppSelector((state) => state.homeState.isShortVideo);

  const enableSceneChange = useAppSelector(
    (state) => state.editorState.enableSceneChange
  );

  const { selectedEditorDraft } = useAppSelector((state) => state.draftState);

  const defaultBaseTemplates = useGetDefaultBaseTemplates();

  useEffectOnce(() => {
    zE("webWidget", "helpCenter:setSuggestions", {
      search: "editor",
    });
  });

  const handelChangeLayout = (layout: VideoLayout) => {
    dispatch(changeSelectedLayout(layout));

    if (!isUserChangedLayout) {
      dispatch(updateIsUserChangeLayout(true));
    }

    const randomTemplate = getRandomTemplateByLayout(
      layout,
      defaultBaseTemplates?.data
    );

    if (layout === VideoLayout.LAYOUT_16_9) {
      dispatch(
        updateCurrentSelectedMicroContent({
          ...currentMicroContent,
          id: randomTemplate.id,
        })
      );
    } else if (
      layout === VideoLayout.LAYOUT_1_1 ||
      layout === VideoLayout.LAYOUT_9_16_1
    ) {
      dispatch(
        updateCurrentSelectedMicroContent({
          ...currentMicroContent,
          hasTwoFace: false,
          id: randomTemplate.id,
        })
      );
    } else {
      dispatch(
        updateCurrentSelectedMicroContent({
          ...currentMicroContent,
          hasTwoFace: true,
          id: randomTemplate.id,
        })
      );
    }
    trackLayoutChangeEvent(layout, ScreenName.EDITOR);
  };

  useEffect(() => {
    // Load this only once when editor is loaded
    // Since, user can this and the navigation text will also change
    if (currentMicroContent?.gist) {
      setMicroContentGist(currentMicroContent.gist);
    }
  }, []);

  return (
    <main className="mx-auto h-[100vh] w-full max-w-screen-2xl bg-[#e8e8ee]">
      {/* <Banner bannerVariant="outrage" /> */}
      <div>
        <div className="relative flex h-[4.5rem] items-center justify-between lg:px-6">
          <nav
            className="flex py-4"
            aria-label="Breadcrumb"
          >
            <ol className="flex items-center space-x-1 md:space-x-3">
              <li>
                <p
                  onClick={() => {
                    dispatch(updateIsUserChangeLayout(false));
                    navigate("/");
                  }}
                  className="flex cursor-pointer items-center lg:order-2 lg:justify-center"
                >
                  <img
                    src={AudyoAILogo}
                    className="mr-1 h-6 sm:h-7"
                    alt="VidyoAI Logo"
                  />
                  <span className="self-center whitespace-nowrap text-lg font-semibold dark:text-white">
                    audyo
                  </span>
                </p>
              </li>

              {isShortVideo ? null : (
                <li>
                  <div className="flex items-center">
                    <svg
                      className="h-6 w-6 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <p
                      onClick={() => {
                        dispatch(updateIsUserChangeLayout(false));
                        navigate(`/home/project/${currentProject?.id}`);
                      }}
                      className="ml-1 max-w-[10rem] cursor-pointer truncate font-medium text-gray-500 hover:text-gray-600 md:ml-2"
                    >
                      {currentProject?.title}
                    </p>
                  </div>
                </li>
              )}

              <li>
                <div className="flex items-center">
                  <svg
                    className="h-6 w-6 text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <p className="ml-1  max-w-[12rem] cursor-default truncate font-semibold text-gray-700 hover:text-gray-800  md:ml-2 ">
                    {microContentGist}
                  </p>
                </div>
              </li>

              {selectedEditorDraft?.fromDownloadPage ? null : (
                <li className="flex relative">
                  <Button
                    disabled={isRenderingVideo}
                    size="sm"
                    color="gray"
                    className="bg-transparent text-primary-600 hover:bg-blue-100 w-48 border-gray-300"
                    onClick={() => {
                      dispatch(toggleSaveDraft(true));
                    }}
                    id={SAVE_DRAFT_EDITOR_TOP_BTN.ID}
                  >
                    <DocumentTextIcon className="w-4 h-4 mr-1" />
                    {selectedEditorDraft?.id ? "Update Draft" : "Save Draft"}

                    <Badge
                      className="left-0 ml-2"
                      color="success"
                    >
                      NEW
                    </Badge>
                  </Button>
                </li>
              )}
            </ol>
          </nav>
          <div className="flex items-center space-x-4">
            <div className=" relative z-50 text-right">
              <Menu
                as="div"
                className="relative inline-block text-left"
                data-tip={
                  enableSceneChange
                    ? "This feature is unavailable when using CutMagic"
                    : ""
                }
              >
                <div>
                  <Menu.Button
                    disabled={enableSceneChange}
                    className="inline-flex w-48 justify-between rounded-md bg-gray-500  px-4 py-2 text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    style={{
                      cursor: enableSceneChange ? "not-allowed" : "",
                    }}
                  >
                    {aspectRatioTitle[selectedLayout]}
                    <ChevronDownIcon
                      className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute z-[50] right-0 mt-2 w-48 origin-top-right divide-y divide-gray-100 rounded-md bg-white text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-1 py-1 ">
                      {Object.keys(aspectRatioTitle).map(function (key) {
                        return (
                          <Menu.Item
                            key={key}
                            disabled={
                              isRenderingVideo || selectedLayout === key
                            }
                          >
                            {({ active }) => (
                              <button
                                className={`${
                                  active
                                    ? "bg-blue-500 text-white"
                                    : "text-gray-900"
                                } group flex w-full items-center rounded-md px-2 py-2`}
                                onClick={() => {
                                  dispatch(updateSelectedDraft(null));
                                  dispatch(
                                    updateAutoAddEmojisToSubtitles(false)
                                  );
                                  handelChangeLayout(key as VideoLayout);
                                }}
                              >
                                {aspectRatioTitle[key]}
                              </button>
                            )}
                          </Menu.Item>
                        );
                      })}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            {isAdminTemplateEditor() ? null : (
              <Button
                disabled={isRenderingVideo || enableSceneChange}
                size="sm"
                color="gray"
                className={clsx(
                  isRenderingVideo || enableSceneChange
                    ? "hover:bg-transparent cursor-not-allowed opacity-70"
                    : "hover:bg-blue-100",
                  "bg-transparent border-primary-600 text-primary-600 flex items-center"
                )}
                onClick={() => {
                  dispatch(toggleShowSaveTemplateModal(true));
                }}
                id={SAVE_TEMPLATE_EDITOR_TOP_BTN.ID}
              >
                <img
                  src={SaveTemplateIcon}
                  alt="save"
                  className="mr-1.5"
                />
                Save Template
              </Button>
            )}

            <Button
              size="sm"
              disabled={isRenderingVideo}
              id={EDITOR_RENDER_VIDEO_BTN.ID}
              className={clsx(isRenderingVideo && "cursor-wait opacity-70")}
              onClick={() => {
                dispatch(toggleShowDownloadPreferenceModal(true));
              }}
            >
              {isRenderingVideo ? (
                <div
                  role="status"
                  className="relative mx-auto flex w-max"
                >
                  <Spinner className="mr-1 w-4 h-4 -mt-0.5" />
                  <span>Downloading Video</span>
                </div>
              ) : (
                <span>Download Video</span>
              )}
            </Button>
          </div>
        </div>

        <div
          className={`relative overflow-y-auto bg-white h-[calc(100vh_-_5rem)`}
        >
          <Outlet />
        </div>
      </div>
      <UploadVideoModal />
      <ReactTooltip
        effect="solid"
        padding="8px"
        arrowColor="transparent"
        className="bg-gray-900 text-sm text-white"
      />
    </main>
  );
};

export default EditorLayout;
