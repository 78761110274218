import { useState } from "react";

import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Widget } from "@typeform/embed-react";
import { Badge, Button, Spinner } from "flowbite-react";

import { toggleManageSubscriptionModal } from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { cancelSubscription } from "@/api/requests";
import useGetUserOnBoardingData from "@/api/useGetUserOnBoardingData";

import { USER_PAYMENT_CONSTANTS } from "@/constants/segment-analytics";

import {
  getDaysDifference,
  getPaddlePriceByPlanId,
  isProd,
  planIdInterval,
} from "@/helpers";

import { trackUserSubscription } from "@/utils/SegmentAnalytics";
import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

import { PlanType, getKeyByValue } from "@/enums";

import VidyoUserJourney from "@/views/home/components/ManageSubscriptionModal/VidyoUserJourney";

const MAIN = "MAIN";

const CANCEL_PROGRESS = "CANCEL_PROGRESS";
const CANCEL_CONFIRM = "CANCEL_CONFIRM";
const CANCEL_MODAL = "CANCEL_MODAL";

export const CancelSubscription = ({
  setCurrentComponent,
  userJourney,
}: {
  setCurrentComponent: (value: string) => void;
  userJourney: any;
}) => {
  const dispatch = useAppDispatch();

  const [currentModalVisible, setCurrentModalVisible] = useState(CANCEL_MODAL);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmbedLoading, setIsEmbedLoading] = useState(true);

  const uid = useAppSelector((state) => state.authState.currentUser.uid);
  const email = useAppSelector((state) => state.authState.currentUser.email);
  const planType = useAppSelector(
    (state) => state.authState.userSubscription.planType
  );
  const intervalInMonths = useAppSelector(
    (state) => state.authState.userSubscription.intervalInMonths
  );

  const userPlanDetails = `${getKeyByValue(PlanType, planType)} ${
    intervalInMonths === 1 ? "Monthly" : "Annual"
  }`;

  const { data }: any = useGetUserOnBoardingData(uid, true);

  const { payment_information, plan_id, subscription_id, signup_date } =
    useAppSelector((state) => state.authState.paddleSubscription);

  const onCancel = async () => {
    setCurrentModalVisible(CANCEL_CONFIRM);
    setIsLoading(true);
    try {
      const response: any = await cancelSubscription(subscription_id);
      if (!response.success) {
        showNotification(
          "Failed to cancel subscription, please try again later or contact support.",
          notificationType.FAIL
        );
        return;
      }
      trackUserSubscription(
        USER_PAYMENT_CONSTANTS.CANCELLATION,
        planType,
        planIdInterval[plan_id],
        getDaysDifference(new Date(signup_date).getTime(), new Date().getTime())
      );
      setCurrentComponent(MAIN);
    } catch (error) {
      showNotification(
        "Failed to cancel subscription, please try again later.",
        notificationType.FAIL
      );
    }
    setIsLoading(false);
  };

  return (
    <div className="relative w-full h-full">
      {currentModalVisible === CANCEL_PROGRESS && (
        <div className="w-full h-full flex flex-1 flex-col">
          <div
            className="absolute flex px-4 py-2.5 border rounded-lg cursor-pointer"
            onClick={() => setCurrentModalVisible(CANCEL_MODAL)}
          >
            <ChevronLeftIcon
              className="mt-[3px] mr-1"
              width={20}
              height={20}
            />
            Back
          </div>
          <div className="text-3xl font-semibold flex justify-center items-center">
            Cancellation in progress.. 🙁
          </div>
          {isEmbedLoading && (
            <div className="flex flex-1 mt-40 items-center justify-center">
              <Spinner className="w-16 h-16" />
            </div>
          )}
          <div className="flex flex-1 items-center justify-center mt-10 overflow-hidden">
            <Widget
              id="yt2pp1AZ"
              className="h-[550px] w-[800px] self-center justify-center text-lg overflow-hidden"
              hidden={{
                email: email,
                persona: data?.occupation,
                plan: userPlanDetails,
                subscribe_date: signup_date,
                jobs_to_be_done: data?.user_wants_to,
              }}
              hideFooter={true}
              hideHeaders={true}
              autoFocus={true}
              medium="snippet"
              transitiveSearchParams={true}
              opacity={100}
              onEndingButtonClick={() => {
                onCancel();
              }}
              onReady={() => {
                setIsEmbedLoading(false);
              }}
              enableSandbox={!isProd()}
            />
          </div>
        </div>
      )}
      {currentModalVisible === CANCEL_CONFIRM && (
        <div className="flex flex-col w-full h-full justify-center items-center gap-4">
          {isLoading ? (
            <Spinner className="w-16 h-16" />
          ) : (
            <div className="text-6xl">🙁</div>
          )}
          <div className="text-3xl font-semibold">
            {isLoading ? "Canceling Subscription" : "Subscription Cancelled"}
          </div>
          <div className="text-xl">
            Your plan will be active until the end of the billing period.
          </div>
          {!isLoading && (
            <Button
              onClick={() => dispatch(toggleManageSubscriptionModal(false))}
              className="w-48 mt-4"
            >
              See you soon 👋
            </Button>
          )}
        </div>
      )}
      {currentModalVisible === CANCEL_MODAL && (
        <>
          <div
            className="absolute flex px-4 py-2.5 border rounded-lg cursor-pointer"
            onClick={() => setCurrentComponent(MAIN)}
          >
            <ChevronLeftIcon
              className="mt-[3px] mr-1"
              width={20}
              height={20}
            />
            Back
          </div>
          <div className="text-3xl font-semibold flex justify-center items-center mr-16">
            Cancel Your Subscription 🙁
          </div>

          <div className="mt-9 flex flex-col gap-5">
            <VidyoUserJourney userJourney={userJourney} />

            {/* green */}
            <div className="h-[224px] bg-[#DEF7EC] flex items-center justify-between px-8 rounded-lg">
              {/* left */}
              <div className="flex-col space-y-3 pl-8 py-9 w-1/2">
                <p className="text-xl font-normal">
                  Are you sure you want to cancel your subscription?
                </p>

                <div className="flex items-baseline text-lg">
                  <span className="text-3xl font-semibold">
                    {getPaddlePriceByPlanId(plan_id)}
                  </span>
                  {" / " + planIdInterval[plan_id]}
                  <Badge color="indigo">
                    {payment_information.payment_method === "card"
                      ? `${payment_information.card_type.toUpperCase()} ${
                          payment_information.last_four_digits
                        }`
                      : "PayPal"}
                  </Badge>
                </div>
                <p>
                  Your subscription will be cancelled, but will remain active
                  until the end of your billing cycle.
                </p>
              </div>

              <div className="flex-col space-y-5">
                <>
                  <Button
                    className="w-full"
                    outline={true}
                    color="failure"
                    onClick={() => {
                      setIsEmbedLoading(true);
                      setCurrentModalVisible(CANCEL_PROGRESS);
                    }}
                  >
                    Yes, cancel
                  </Button>
                  <Button
                    outline={true}
                    color="success"
                    onClick={() => setCurrentComponent(MAIN)}
                    disabled={isLoading}
                  >
                    No, don't cancel
                  </Button>
                </>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
