import { useQuery } from "@tanstack/react-query";

import api from "@/api/api";

import { changeHttpToHttps } from "@/helpers";

import { Project } from "@/interfaces";

import { ApiEndpoints } from "./constants/ApiEndPoints";

const getProjectById = async (projectID: string | undefined) => {
  const {
    data,
  }: {
    data: Project;
  } = await api.get(
    `${ApiEndpoints.GET_PROJECT_BY_ID}?project_id=${projectID}`
  );

  // need to change remote_url to https
  const parsedData = JSON.parse(data.data);
  if (parsedData.remote_url) {
    parsedData.remote_url = changeHttpToHttps(parsedData.remote_url);
  }
  data.data = JSON.stringify(parsedData);

  return data;
};

export default function useGetSingleProject(projectID: string | undefined) {
  return useQuery(["project", projectID], () => getProjectById(projectID), {
    staleTime: 300000,
    enabled: !!projectID,
  });
}
