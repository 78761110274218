import {
  ReactElement,
  memo,
  useState,
  forwardRef,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { useOutletContext } from "react-router";

import { TrashIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import PubSub from "pubsub-js";

import useDeleteAsset from "@/api/useDeleteAsset";

import { generateCloudflareImageUrl, secondsToMinSec } from "@/helpers";
import { cn } from "@/helpers";

import { AudioCardType, EditorMediaStatus, SimpleAssetType } from "@/enums";

import AudioCard from "@/components/AudioCard/AudioCard";

import { EDITOR_MEDIA_LOAD_STATE } from "@/views/editor/constant";

import CardStatus from "./CardStatus";
import MediaImage from "./MediaImage";
import MediaVideo from "./MediaVideo";

import PexelStockImg from "@/assets/images/editor/pexel-stock.svg";

type MediaCardProps = {
  url: string;
  mediaType: SimpleAssetType;
  isStock?: boolean;
  mediaCardVariant?: MediaCardVariant;
  isSelected?: boolean;
  handleAddMedia?: any;
  id?: string | number;
  assetName?: string | undefined;
  volume?: number;
  isFullWidthCard?: boolean;
  isUserUploadedAsset?: boolean;
  removeAsset?: any;
};

type MediaCardVariant = "small" | "large";

type RenderShellProps = {
  children: ReactElement;
  mediaCardVariant: MediaCardVariant;
  isAudio: boolean;
  isFullWidthCard?: boolean;
};

const RenderShell = forwardRef<HTMLDivElement, RenderShellProps>(
  ({ children, mediaCardVariant, isAudio, isFullWidthCard }, ref) => {
    const commonClasses =
      "bg-white rounded-md overflow-hidden relative border mx-auto";

    const size =
      mediaCardVariant === "small"
        ? "w-20 h-20 2xl:w-24 2xl:h-24"
        : "w-28 h-28 2xl:w-[125px] 2xl:h-[120px] group";

    if (isFullWidthCard) {
      return (
        <div
          ref={ref}
          className={`${commonClasses} w-full`}
        >
          {children}
        </div>
      );
    }

    if (isAudio) {
      const audioSize =
        mediaCardVariant === "small"
          ? "w-20 h-32 2xl:w-24 2xl:h-32"
          : "w-28 h-36 2xl:w-[125px] 2xl:h-[150px] group";
      return (
        <div
          ref={ref}
          className={`${commonClasses} ${audioSize}`}
        >
          {children}
        </div>
      );
    }

    return (
      <div
        ref={ref}
        className={`${commonClasses} ${size}`}
      >
        {children}
      </div>
    );
  }
);

const MediaCard = forwardRef<HTMLDivElement, MediaCardProps>(
  (
    {
      url,
      mediaType,
      isStock,
      mediaCardVariant = "large",
      isSelected,
      handleAddMedia,
      id,
      assetName,
      volume = 30,
      isFullWidthCard,
      isUserUploadedAsset,
      removeAsset,
    },
    ref
  ) => {
    const { mutate: deleteAsset } = useDeleteAsset();

    const handleUserAssetDelete = (e: React.MouseEvent<SVGSVGElement>) => {
      e.stopPropagation();
      deleteAsset(id);
    };
    const cardRef = useRef<any>(null); // ref for the media card

    const [duration, setDuration] = useState<number | null>(null);
    const [mediaLoadingStatus, setMediaLoadingStatus] =
      useState<EditorMediaStatus>(EditorMediaStatus.NOT_ADDED);

    const isVideo = mediaType === SimpleAssetType.VIDEO;
    const isImage = mediaType === SimpleAssetType.IMAGE;
    const isAudio = mediaType === SimpleAssetType.AUDIO;

    const formattedDuration = duration ? secondsToMinSec(duration) : null;

    const addMediaToEditor = () => {
      if (
        handleAddMedia &&
        !isSelected &&
        mediaLoadingStatus === EditorMediaStatus.NOT_ADDED
      ) {
        handleAddMedia({ url, id, duration, assetName }, mediaType);
      }
    };

    const removeAssetFromEditor = () => {
      if (removeAsset) {
        removeAsset(id);
      }
    };

    const generatedCFImageUrl = useMemo(() => {
      if (isImage && url.includes("storage")) {
        return generateCloudflareImageUrl({
          originalUrl: url,
          imgOptions: {
            height: 112,
          },
        });
      }
      return url;
    }, [isImage, url]);

    const handleUpdateDuration = useCallback((duration: number) => {
      setDuration(duration);
    }, []);

    const addToCartAnimation = () => {
      /**
       * Owner of the bellow snippet: ChatGPT
       */
      if (cardRef.current) {
        const cardRect = cardRef.current?.getBoundingClientRect();
        const cartRect = document
          .getElementById("bottom-b-roll-panel")
          ?.getBoundingClientRect();

        if (cardRect && cartRect) {
          document.body.style.overflow = "hidden";
          const clonedCard = cardRef.current.cloneNode(true) as HTMLElement;
          clonedCard.style.position = "absolute";
          clonedCard.style.top = `${cardRect.top}px`;
          clonedCard.style.left = `${cardRect.left}px`;
          clonedCard.style.zIndex = "9999";
          clonedCard.style.width = `${cardRect.width}px`;
          clonedCard.style.height = `${cardRect.height}px`;
          clonedCard.style.transition =
            "all 0.8s cubic-bezier(.68,-.15,.67,1.55)";

          document.body.appendChild(clonedCard);

          void clonedCard.offsetWidth;

          clonedCard.style.transform = `translate3d(${
            cartRect.left - cardRect.left
          }px, ${cartRect.top - cardRect.top}px, 0) scale(0.25)`;

          clonedCard.style.opacity = "0";

          clonedCard.addEventListener("transitionend", function () {
            if (clonedCard.parentNode) {
              clonedCard.parentNode.removeChild(clonedCard);
            }
          });
        }
      }
    };

    useEffect(() => {
      const token = PubSub.subscribe(EDITOR_MEDIA_LOAD_STATE, (_, data) => {
        if (data.id === id) {
          setMediaLoadingStatus(data.status);
        }
      });
      return () => {
        PubSub.unsubscribe(token);
      };
    }, []);

    useEffect(() => {
      if (mediaLoadingStatus === EditorMediaStatus.READY) {
        addToCartAnimation();
      }
    }, [mediaLoadingStatus]);

    return (
      <RenderShell
        mediaCardVariant={mediaCardVariant}
        isAudio={isAudio}
        ref={cardRef}
        isFullWidthCard={isFullWidthCard}
      >
        <div
          className={clsx("w-full h-full group", !isAudio && "cursor-pointer")}
          ref={ref}
          onClick={() => {
            if (isAudio) return;
            addMediaToEditor();
          }}
        >
          {mediaCardVariant === "large" && !isAudio && (
            <CardStatus
              status={mediaLoadingStatus}
              isSelected={isSelected}
              removeAsset={removeAssetFromEditor}
            />
          )}

          {isUserUploadedAsset && (
            <div
              className={cn(
                "absolute hidden items-center justify-center cursor-default rounded-full shadow-xl border border-gray-300  z-10 ",
                mediaLoadingStatus === EditorMediaStatus.LOADING ||
                  mediaLoadingStatus === EditorMediaStatus.READY ||
                  isSelected
                  ? "group-hover:hidden"
                  : "group-hover:flex",
                isAudio ? "right-[50px] top-4" : "right-2 top-2"
              )}
            >
              <TrashIcon
                className="h-6 w-6 text-red-500 cursor-pointer bg-white rounded-full p-1"
                onClick={handleUserAssetDelete}
              />
            </div>
          )}

          {isStock && (
            <>
              {isAudio ? (
                <div className="absolute top-0 left-0  text-white text-[10px] px-2 py-0.5 rounded-br-md font-medium z-10 bg-black/50">
                  Stock
                </div>
              ) : (
                <div className="absolute top-2 left-0  text-white text-[10px] px-2 py-0.5 rounded-br-md font-medium z-10">
                  <img src={PexelStockImg} />
                </div>
              )}
            </>
          )}

          {isImage && <MediaImage src={generatedCFImageUrl} />}

          {isAudio && (
            <AudioCard
              audioUrl={url}
              audioCardType={
                isFullWidthCard
                  ? AudioCardType.FULL_WIDTH_CARD
                  : AudioCardType.MEDIA_SECTION_CARD
              }
              assetName={assetName}
              volume={volume}
            />
          )}

          {isAudio && isFullWidthCard && (
            <CardStatus
              status={mediaLoadingStatus}
              isSelected={isSelected}
              addMediaToEditor={addMediaToEditor}
              isAudio
              removeAsset={removeAssetFromEditor}
            />
          )}

          {isVideo && (
            <>
              <MediaVideo
                src={url}
                handleUpdateDuration={handleUpdateDuration}
              />
              {duration && (
                <div
                  className={cn(
                    "absolute bottom-1 right-2 bg-black/50 text-white text-[10px] rounded-md px-2 py-0.5 font-medium",
                    mediaCardVariant === "small" && "bottom-1 right-1"
                  )}
                >
                  {formattedDuration}
                </div>
              )}
            </>
          )}
        </div>
      </RenderShell>
    );
  }
);

MediaCard.displayName = "MediaCard";

export default memo(MediaCard);
