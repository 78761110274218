import { getAuth } from "firebase/auth";

import { store } from "@/store";

import { isProd } from "@/helpers";

import { PlanType } from "@/enums";

const segmentAnalytics = (window as any).analytics;

// tracks user's current page
export const trackUserPageActivity = () => {
  return;
  const user = getAuth().currentUser;
  if (isProd() && user) {
    segmentAnalytics.page({
      userId: user?.uid,
    });
  }
};

// identifies user
export const identifyLoggedInUser = (user: any) => {
  return;
  const planType = store.getState().authState.userSubscription.planType;
  if (isProd()) {
    segmentAnalytics.identify(user.uid, {
      email: user.email,
      name: user.displayName,
      planType,
    });
  }
};

// tracks user's events
export const trackUserButtonEvent = (buttonId: string, userAction?: string) => {
  return;
  const user = getAuth().currentUser;
  if (isProd()) {
    segmentAnalytics.track(buttonId, {
      userId: user?.uid,
      userAction,
    });
  }
};

export const trackUserSubscription = (
  event: string,
  planType: PlanType,
  planInterval: string,
  duration?: number | null
) => {
  return;
  const user = getAuth().currentUser;
  if (isProd()) {
    segmentAnalytics.track(event, {
      userId: user?.uid,
      planType,
      planInterval,
      duration, // in case of churn and cancellation, set duration
      actionDate: new Date().toDateString(),
    });
  }
};
