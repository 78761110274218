import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "@/api/api";
import { ApiEndpoints } from "@/api/constants/ApiEndPoints";

import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

const deleteProject = async (projectID: string) => {
  const { data } = await api.delete(ApiEndpoints.ALL_PROJECTS, {
    params: { project_id: projectID },
  });
  return data;
};

export default function useDeleteProject(successCB?: () => void) {
  const queryClient = useQueryClient();

  return useMutation((projectID: string) => deleteProject(projectID), {
    onSuccess: () => {
      if (successCB) {
        successCB();
      } else {
        showNotification(
          "Project deleted successfully",
          notificationType.SUCCESS
        );
      }
      queryClient.invalidateQueries({ queryKey: ["all-projects"] });
    },
    onError: () => {
      showNotification(
        "Something went wrong, please try again!",
        notificationType.FAIL
      );
    },
  });
}
