import { updateShowConfettiModal } from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { PlanType } from "@/enums";

import BaseModal from "@/components/BaseModal/BaseModal";

import GenerateClipIcon from "@/assets/icons/generateClipIcon.svg";

type FilesCountModalTypes = {
  counts: any;
};

const FilesCountModal = ({ counts }: FilesCountModalTypes) => {
  const dispatch = useAppDispatch();

  const { planType } = useAppSelector(
    (state) => state.authState.userSubscription
  );

  const showConfettiModal = useAppSelector(
    (state) => state.homeState.showConfettiModal
  );

  return (
    <BaseModal
      isModalOpen={showConfettiModal}
      handelModalOpen={() => dispatch(updateShowConfettiModal(false))}
    >
      <div className=" flex max-w-xl items-center justify-center">
        <div className="relative rounded-lg  dark:bg-gray-700">
          <div className="py-6 px-4 lg:px-6">
            <section className="w-96 bg-white">
              <p className="text-center text-6xl">🎊</p>

              <p className=" mt-6 text-center text-lg">
                We’ve generated{" "}
                <span className="font-bold">
                  {counts.chapter_count
                    ? `${counts.chapter_count} chapters`
                    : ``}
                </span>
                <span className="font-bold">
                  {" "}
                  {counts.chapter_count ? " , " : ""}
                </span>
                <span className="font-bold">
                  {counts.micro_content_count
                    ? `${counts.micro_content_count} short${
                        counts.micro_content_count > 1 ? "s" : ""
                      }`
                    : ``}
                </span>{" "}
                from your video!
              </p>
              {planType !== PlanType.FREE && (
                <>
                  <p className=" mt-6 text-center text-lg">
                    While you dive into these clips, we're crafting{" "}
                    <span className="font-bold">IntelliClips</span> 🎥🤩
                  </p>
                  <p className="text-center text-lg">
                    {" "}
                    Keep an eye on your email
                  </p>
                </>
              )}
              <button
                type="button"
                className="mt-8 inline-flex w-full items-center  justify-center rounded-lg bg-blue-600 px-5 py-3.5 text-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none"
                onClick={() => dispatch(updateShowConfettiModal(false))}
                id="lets-go"
              >
                <img
                  src={GenerateClipIcon}
                  className="mr-2"
                />
                Let’s go!
              </button>
            </section>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default FilesCountModal;
