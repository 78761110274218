import { AssetTags, BrandKitMenu } from "@/enums";

import { ReactComponent as AudioIcon } from "@/assets/icons/brand-kit/media-type-audio.svg";
import { ReactComponent as BackgroundIcon } from "@/assets/icons/brand-kit/media-type-background.svg";
import { ReactComponent as BrandKitIcon } from "@/assets/icons/brand-kit/media-type-brand-kit.svg";
import { ReactComponent as ColorPaletteIcon } from "@/assets/icons/brand-kit/media-type-color-palette.svg";
import { ReactComponent as ColorsIcon } from "@/assets/icons/brand-kit/media-type-colors.svg";
import { ReactComponent as FontIcon } from "@/assets/icons/brand-kit/media-type-font.svg";
import { ReactComponent as ImageIcon } from "@/assets/icons/brand-kit/media-type-image.svg";
import { ReactComponent as LogoIcon } from "@/assets/icons/brand-kit/media-type-logo.svg";
import { ReactComponent as OutroIcon } from "@/assets/icons/brand-kit/media-type-outro.svg";
import { ReactComponent as SocialIcon } from "@/assets/icons/brand-kit/media-type-social.svg";
import { ReactComponent as StockIcon } from "@/assets/icons/brand-kit/media-type-stock.svg";
import { ReactComponent as VideoIcon } from "@/assets/icons/brand-kit/media-type-video.svg";

export const MediaIconBrandKit = BrandKitIcon;
export const MediaIconColors = ColorsIcon;
export const MediaIconImage = ImageIcon;
export const MediaIconStock = StockIcon;
export const MediaIconVideo = VideoIcon;
export const MediaIconSocial = SocialIcon;
export const MediaIconAudio = AudioIcon;

export const MediaMenuList = [
  {
    id: "media-type-logo",
    label: BrandKitMenu.LOGO,
    Icon: LogoIcon,
    type: BrandKitMenu.LOGO,
  },
  {
    id: "media-type-outro",
    label: BrandKitMenu.OUTRO,
    Icon: OutroIcon,
    type: BrandKitMenu.OUTRO,
  },
  {
    id: "media-type-color-palette",
    label: BrandKitMenu.COLOR_PALETTE,
    Icon: ColorPaletteIcon,
    type: BrandKitMenu.COLOR_PALETTE,
  },
  {
    id: "media-type-font",
    label: BrandKitMenu.FONT,
    Icon: FontIcon,
    type: BrandKitMenu.FONT,
  },
  {
    id: "media-type-background",
    label: BrandKitMenu.BACKGROUND,
    Icon: BackgroundIcon,
    type: BrandKitMenu.BACKGROUND,
  },
  {
    id: "media-type-image",
    label: BrandKitMenu.IMAGE,
    Icon: ImageIcon,
    type: BrandKitMenu.IMAGE,
  },
  {
    id: "media-type-video",
    label: BrandKitMenu.VIDEO,
    Icon: VideoIcon,
    type: BrandKitMenu.VIDEO,
  },
  {
    id: "media-type-audio",
    label: BrandKitMenu.AUDIO,
    Icon: AudioIcon,
    type: BrandKitMenu.AUDIO,
  },
];

type MediaTypesUploadProperties = {
  supportedMediaTypes: Record<string, string[]>;
  supportedFilesDisplayText: string;
  assetTypeText: string;
  assetTag: AssetTags;
};

export const MediaTypesUploadProperties: Record<
  BrandKitMenu,
  MediaTypesUploadProperties
> = {
  [BrandKitMenu.LOGO]: {
    supportedMediaTypes: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
    },
    supportedFilesDisplayText: "(* Only JPG, PNG are supported for now)",
    assetTypeText: BrandKitMenu.LOGO,
    assetTag: AssetTags.LOGO,
  },
  [BrandKitMenu.OUTRO]: {
    supportedMediaTypes: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
      "video/mp4": [".mp4"],
    },
    supportedFilesDisplayText: "(* Only JPG, PNG, MP4 are supported for now)",
    assetTypeText: BrandKitMenu.OUTRO,
    assetTag: AssetTags.OUTRO,
  },
  [BrandKitMenu.AUDIO]: {
    supportedMediaTypes: {
      "audio/mp3": [".mp3"],
    },
    supportedFilesDisplayText: "(* Only MP3 are supported for now)",
    assetTypeText: BrandKitMenu.AUDIO,
    assetTag: AssetTags.BG_MUSIC,
  },
  [BrandKitMenu.BACKGROUND]: {
    supportedMediaTypes: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
    },
    supportedFilesDisplayText: "(* Only JPG, PNG are supported for now)",
    assetTypeText: BrandKitMenu.BACKGROUND,
    assetTag: AssetTags.BACKGROUND,
  },
  [BrandKitMenu.FONT]: {
    supportedMediaTypes: {
      "font/ttf": [".ttf"],
    },
    supportedFilesDisplayText: "(* Only TTF are supported for now)",
    assetTypeText: BrandKitMenu.FONT,
    assetTag: AssetTags.FONT,
  },
  [BrandKitMenu.COLOR_PALETTE]: {
    supportedMediaTypes: {
      "application/json": [".json"],
    },
    supportedFilesDisplayText: "(* Only JSON are supported for now)",
    assetTypeText: BrandKitMenu.COLOR_PALETTE,
    assetTag: AssetTags.UPLOAD,
  },
  [BrandKitMenu.IMAGE]: {
    supportedMediaTypes: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
    },
    supportedFilesDisplayText: "(* Only JPG, PNG are supported for now)",
    assetTypeText: BrandKitMenu.IMAGE,
    assetTag: AssetTags.B_ROLL,
  },
  [BrandKitMenu.VIDEO]: {
    supportedMediaTypes: {
      "video/mp4": [".mp4"],
    },
    supportedFilesDisplayText: "(* Only MP4 are supported for now)",
    assetTypeText: BrandKitMenu.VIDEO,
    assetTag: AssetTags.B_ROLL,
  },
};
