import { useMutation, useQueryClient } from "@tanstack/react-query";

import { updateDefaultTemplate } from "@/api/requests";

import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

export default function useUpdateDefaultTemplateData(
  saveTemplateMutationSuccessCB: () => void
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => updateDefaultTemplate(data),
    onSuccess: () => {
      saveTemplateMutationSuccessCB();
      queryClient.invalidateQueries({ queryKey: ["base-template"] });
      queryClient.invalidateQueries({ queryKey: ["user-template"] });
      queryClient.invalidateQueries({ queryKey: ["micro-content-data"] });
    },
    onError: (error) => {
      console.log(error);
      showNotification(
        "Something went wrong, please try again!",
        notificationType.FAIL
      );
    },
  });
}
