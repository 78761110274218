import { useState } from "react";

import { OCCUPATION_LIST } from "@/constants/on-boarding";

import { sleep } from "@/helpers";

import UserOccupationModal from "./UserOccupationModal";
import { HandelOnboardingDataType } from "./types";

const OccupationOptionCard = ({
  value,
  Icon,
  handelUpdateOccupation,
  selectedOccupation,
}: {
  value: string;
  Icon: any;
  handelUpdateOccupation: (occupation: string) => void;
  selectedOccupation: string;
}) => {
  return (
    <div
      className={`mx-auto flex h-24 w-24 cursor-pointer flex-col items-center justify-center space-y-2  rounded-lg bg-white shadow-[0_0px_8px_0_rgba(0,0,0,0.1)] transition-colors hover:border-2 hover:border-blue-600 hover:text-blue-600 sm:h-[100px] sm:w-[100px] lg:h-32 lg:w-32 ${
        selectedOccupation === value
          ? "border-2 border-blue-600 font-semibold text-blue-600 hover:text-blue-600"
          : "text-[#9ca3afe3]"
      }`}
      onClick={() => handelUpdateOccupation(value)}
    >
      <span
        className={`${value === "Marketing professional" ? "lg:mt-4" : ""}`}
      >
        <Icon />
      </span>
      <p className="text-xs sm:text-sm">{value}</p>
    </div>
  );
};

export const Occupation = ({
  handleOnBoardingData,
  occupation,
  handleNext,
}: {
  handleOnBoardingData: (data: HandelOnboardingDataType) => void;
  occupation: string;
  handleNext: (nextStep?: number, value?: any) => void;
}) => {
  const [selectedOccupation, setSelectedOccupation] = useState(occupation);
  const [showOtherOccupationModal, setShowOtherOccupationModal] =
    useState(false);

  const handelUpdateOccupation = async (occupation: string) => {
    setSelectedOccupation(occupation);
    await sleep(200);
    if (occupation === "Other") {
      setShowOtherOccupationModal(true);
    } else {
      handleOnBoardingData({
        onBoardingData: {
          occupation,
        },
      });
      handleNext(1);
    }
  };
  return (
    <>
      <div className="pb-4">
        <p className="mt-6 text-xs leading-6 text-gray-400 lg:text-base">
          Help us personalize your experience by answering a few questions
        </p>

        <p className="my-4 text-xl font-semibold leading-6 text-gray-900 sm:text-2xl">
          Which of these describes you best?
        </p>

        <div className=" mx-auto mt-8 grid grid-cols-2 gap-x-4 gap-y-4 sm:grid-cols-3 md:grid-cols-3 lg:w-[422px]">
          {OCCUPATION_LIST.map((occupation) => (
            <OccupationOptionCard
              key={occupation.id}
              value={occupation.value}
              Icon={occupation.icon}
              selectedOccupation={selectedOccupation}
              handelUpdateOccupation={handelUpdateOccupation}
            />
          ))}
        </div>
      </div>
      <UserOccupationModal
        showOtherOccupationModal={showOtherOccupationModal}
        handleOnBoardingData={handleOnBoardingData}
        setShowOtherOccupationModal={setShowOtherOccupationModal}
        setSelectedOccupation={setSelectedOccupation}
        handleNext={handleNext}
      />
    </>
  );
};
