import { memo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useGetSingleProject from "@/api/useGetSingleProject";

import { UserOptionDropdown } from "@/components/Header";

import AudyoAILogo from "@/assets/icons/audyo.png";

const ReviewClipsHeader = () => {
  const navigate = useNavigate();
  const { projectID } = useParams();
  const { data: projectData } = useGetSingleProject(projectID);

  return (
    <div className="max-w-screen-2xl mx-auto px-2 lg:px-6 h-[4.5rem] bg-white dark:bg-gray-700 border-b-4 border-[#EBEBEB]">
      <div className="flex">
        <nav
          // className="flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:py-6"
          className="mt-5"
          aria-label="Breadcrumb"
        >
          <ol className="flex items-center space-x-1 md:space-x-3">
            <li className="hidden sm:block">
              <p
                onClick={() => navigate("/")}
                className="flex cursor-pointer items-center md:order-2 md:justify-center"
              >
                <img
                  src={AudyoAILogo}
                  className="mr-1 h-6 sm:h-7"
                  alt="VidyoAI Logo"
                />
                <span className="self-center whitespace-nowrap text-lg font-semibold text-violet-700 dark:text-white">
                  audyo
                </span>
              </p>
            </li>
            <li>
              <div className="flex items-center">
                <svg
                  className="h-6 w-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <p className="ml-1 font-semibold text-gray-700 hover:text-gray-900  md:ml-2">
                  {projectData?.title?.split(".mp4")[0]}
                </p>
              </div>
            </li>
          </ol>
        </nav>
        <div className="ml-auto mt-5">
          <UserOptionDropdown />
        </div>
      </div>
    </div>
  );
};

export default memo(ReviewClipsHeader);
