import { useAppSelector } from "@/store/hooks";

import NoTemplateImg from "@/assets/images/illustration-blank-canvas.svg";

const TemplatesEmpty = () => {
  return (
    <div className="col-span-2 mt-16 px-6 text-center text-sm text-[#5E6473]">
      <img
        src={NoTemplateImg}
        className="mx-auto"
      />
      <p className="mt-4 mb-6">
        You can now save templates with your custom design & logo to reuse for
        later.
      </p>
      <p>
        Click on the <span className="font-semibold">“Save Template”</span>{" "}
        button on the video editor.
      </p>
      <p className="mt-6">
        This is a <span className="font-semibold">PRO feature</span>
      </p>
    </div>
  );
};

export default TemplatesEmpty;
