import { useState } from "react";

import { sleep } from "@/helpers";

import { OnboardingUserType } from "@/enums";

import { OnBoardingRadio } from "./OnBoardingInputs";
import { HandelOnboardingDataType } from "./types";

export const TeamQuestionnaires = ({
  handleOnBoardingData,
  isIndividual,
  handleNext,
}: {
  handleOnBoardingData: (data: HandelOnboardingDataType) => void;
  isIndividual: boolean | null;
  handleNext: (nextStep?: number, value?: any) => void;
}) => {
  const [selectedUserType, setSelectedUserType] = useState<string>(
    isIndividual !== null
      ? isIndividual
        ? OnboardingUserType.SOLO_CREATOR
        : OnboardingUserType.TEAM
      : ""
  );

  const handleTeamQuestionnaires = async (value: OnboardingUserType) => {
    setSelectedUserType(value);

    await sleep(200);
    handleOnBoardingData({
      onBoardingData: {
        isIndividual: value === OnboardingUserType.SOLO_CREATOR,
      },
    });
    handleNext(2);
  };

  return (
    <div>
      <p className="mt-6 text-xs leading-6 text-gray-400 lg:text-base">
        Tell us about your working style
      </p>

      <p
        className="my-4 text-xl font-semibold leading-10 text-gray-900 sm:text-2xl"
        style={{
          lineHeight: "36px",
        }}
      >
        How many people do you
        <br /> work with?
      </p>

      <div className="mx-auto mt-10 mb-4 flex w-11/12 flex-col space-y-2 sm:w-1/2">
        <OnBoardingRadio
          id="option-single"
          label="I work independently"
          value={OnboardingUserType.SOLO_CREATOR}
          onChange={(e) =>
            handleTeamQuestionnaires(
              e.currentTarget.value as OnboardingUserType
            )
          }
          isSelected={selectedUserType === OnboardingUserType.SOLO_CREATOR}
        />
        <OnBoardingRadio
          id="option-team"
          label="I work with a team"
          value={OnboardingUserType.TEAM}
          onChange={(e) =>
            handleTeamQuestionnaires(
              e.currentTarget.value as OnboardingUserType
            )
          }
          isSelected={selectedUserType === OnboardingUserType.TEAM}
        />
      </div>
    </div>
  );
};
