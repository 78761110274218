import { forwardRef, memo } from "react";

import { useSelectedClips } from "@/context/ReviewClips";
import clsx from "clsx";

import { useAppSelector } from "@/store/hooks";

import { CaptionTab } from "@/views/editor/components/CaptionTab";
import TemplatesPreview from "@/views/home/components/PreviewClip";
import {
  TemplatePreviewSideTabs,
  TemplatesTab,
  TranscriptsTab,
  VideoTimestamps,
} from "@/views/home/components/TemplatePreviewSideMenu";
import TemplatePreviewTitle from "@/views/home/components/TemplatePreviewTitle/TemplatePreviewTitle";

const TRANSCRIPT = "Transcript";
const TEMPLATES = "Templates";
const AI_CAPTIONS = "AI_Captions";
const INSTANT_CHAPTERS = "Instant_Chapters";

// Using React.memo to avoid unnecessary re-renders
const CenterEditor = memo(
  forwardRef(
    (
      {
        onVideoClipChange,
        clipDeleteLoading,
        projectData,
        updateActivePreviewTab,
        microContent,
        isSelectExportMultipleClipsModalOpen,
        showDeleteMultipleClipsModal,
        showFullVideo,
        showPreviewClipsSideModal,
        activePreviewTab,
        setOutroTimeLeft,
        outroTimeLeft,
      }: any,
      ref
    ) => {
      const { selectModeOn } = useSelectedClips();

      const upgradeToProModalVisible = useAppSelector(
        (state) => state.homeState.upgradeToProModalVisible
      );

      const rightSideTabComponents: any = {
        [TEMPLATES]: () => (
          <TemplatesTab setActivePreviewTab={updateActivePreviewTab} />
        ),
        [TRANSCRIPT]: () => (
          <TranscriptsTab setActivePreviewTab={updateActivePreviewTab} />
        ),
        [AI_CAPTIONS]: () => (
          <CaptionTab
            isPreviewScreenOpen={true}
            setActivePreviewTab={updateActivePreviewTab}
          />
        ),
        [INSTANT_CHAPTERS]: () => (
          <VideoTimestamps
            setActivePreviewTab={updateActivePreviewTab}
            microContentData={microContent}
          />
        ),
      };

      return (
        <div className="w-[67.5%] h-full bg-white">
          <div
            className={clsx(
              "bg-gray-700/30 w-[67.5%] h-full absolute  opacity-0 z-[-999] transition-opacity",
              selectModeOn &&
                !isSelectExportMultipleClipsModalOpen &&
                !showDeleteMultipleClipsModal &&
                !upgradeToProModalVisible &&
                "opacity-100 z-[80]"
            )}
          ></div>
          <TemplatePreviewTitle
            onVideoClipChange={onVideoClipChange}
            projectData={projectData}
            clipDeleteLoading={clipDeleteLoading}
          />

          {!showFullVideo &&
            showPreviewClipsSideModal &&
            rightSideTabComponents[activePreviewTab]?.()}

          {!showPreviewClipsSideModal && !showFullVideo && (
            <TemplatePreviewSideTabs
              setActivePreviewTab={updateActivePreviewTab}
            />
          )}

          <TemplatesPreview
            clipDeleteLoading={clipDeleteLoading}
            ref={ref}
            setOutroTimeLeft={setOutroTimeLeft}
            outroTimeLeft={outroTimeLeft}
          />
        </div>
      );
    }
  )
);

export default CenterEditor;
