import { useRef, useState } from "react";
import { useClickAway } from "react-use";

import EmojiPicker, {
  EmojiStyle,
  SkinTones,
  Theme,
  Categories,
  EmojiClickData,
  Emoji,
  SuggestionMode,
  SkinTonePickerLocation,
} from "emoji-picker-react";

const EmojiInput = ({
  id,
  presetEmoji,
  setterFunction,
}: {
  id: any;
  presetEmoji: any | null;
  setterFunction: any;
}) => {
  const [isVisible, setVisivle] = useState<boolean>(false);
  const clickOutsideRef = useRef(null);
  useClickAway(clickOutsideRef, () => {
    setVisivle(false);
  });
  const onAddEmoji = (emoji: any) => {
    setterFunction(emoji.emoji);
    setVisivle(false);
  };

  return (
    <div className="w-full h-full relative">
      <div className="flex items-center justify-center">
        {presetEmoji ? (
          <div
            className="text-2xl cursor-pointer"
            onClick={() => setVisivle(!isVisible)}
          >
            {presetEmoji}
          </div>
        ) : (
          <svg
            onClick={(e) => setVisivle(!isVisible)}
            className="cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            fill="#8B3DFF"
          >
            <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0m0 22C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10"></path>
            <path d="M8 7a2 2 0 1 0-.001 3.999A2 2 0 0 0 8 7M16 7a2 2 0 1 0-.001 3.999A2 2 0 0 0 16 7M15.232 15c-.693 1.195-1.87 2-3.349 2-1.477 0-2.655-.805-3.347-2H15m3-2H6a6 6 0 1 0 12 0"></path>
          </svg>
        )}
      </div>
      {isVisible && (
        <div
          className="absolute right-0 top-10 z-20 border-2 rounded-lg bg-gray-300"
          ref={clickOutsideRef}
        >
          <EmojiPicker
            key={id}
            onEmojiClick={onAddEmoji}
            emojiVersion="15.0"
            lazyLoadEmojis={true}
          />
        </div>
      )}
    </div>
  );
};

export default EmojiInput;
