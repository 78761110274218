import { useEffect } from "react";
import {
  Navigate,
  useLocation,
  useSearchParams,
  RouteProps,
} from "react-router-dom";

import { store } from "@/store";
import {
  toggleUpgradeToProModal,
  updatedPaymentStatus,
} from "@/store/homeSlice";
import { useAppSelector } from "@/store/hooks";

import { getLoginRedirectUrl } from "@/helpers";

type LayoutProps = {
  children: RouteProps["children"];
};
const PrivateRoute = ({ children }: LayoutProps) => {
  const isAuthenticated = useAppSelector(
    (state) => state.authState.isAuthenticated
  );

  const [searchParams] = useSearchParams();

  const location = useLocation();

  const closePaymentModal = searchParams.get("closePaymentModal");
  const paymentStatus = searchParams.get("modalStatus") || "";

  useEffect(() => {
    if (closePaymentModal === "True") {
      store.dispatch(toggleUpgradeToProModal(false));
      store.dispatch(updatedPaymentStatus(paymentStatus));
    }
  }, [closePaymentModal]);

  return isAuthenticated ? (
    <>{children}</>
  ) : (
    <Navigate
      replace
      to={getLoginRedirectUrl(location)}
    />
  );
};

export default PrivateRoute;
