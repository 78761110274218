import { memo } from "react";
import { useOutletContext } from "react-router";

import { InformationCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { Checkbox } from "flowbite-react";
import PubSub from "pubsub-js";

import { useAppSelector } from "@/store/hooks";

import { formatSeconds, getSecondsFromHMSM } from "@/helpers";

import { EditorMediaStatus, SimpleAssetType } from "@/enums";

import VolumeControl from "@/components/AudioCard/VolumeControl";
import TimeField from "@/components/TimeField";

import { EDITOR_MEDIA_LOAD_STATE } from "@/views/editor/constant";

import { MediaCard } from "../MediaCard";

type AppliedMediaCardProps = {
  assetId: string | number;
  assetUrl: string;
  assetType: SimpleAssetType;
  startTime: number;
  endTime: number;
  isFullLength?: boolean;
  mediaVolume?: number;
  assetName?: string;
};
type TimeEntity = "start" | "end";

type TimeFieldComponentProps = {
  value: number;
  isVideoCard?: boolean;
  isAudioCard?: boolean;
  assetId: string | number;
  inputType: TimeEntity;
  assetDuration?: number;
  isFullLength?: boolean;
};

type MediaCardQuickActionSectionProps = {
  isVideoCard: boolean;
  isAudioCard: boolean;
  isFullLength?: boolean;
  removeAsset: () => void;
  handleIsFullLengthUpdate: (value: boolean) => void;
  mediaVolume?: number;
  handleAudioVolumeUpdate?: (volume: number) => void;
};

const TimeFieldComponent: React.FC<TimeFieldComponentProps> = ({
  value,
  isVideoCard = false,
  assetId,
  inputType,
  isFullLength = false,
  isAudioCard = false,
}) => {
  const { onStartUpdate, onEndUpdate, audioAssetTimeUpdate } =
    useOutletContext<any>();

  const currentSelectedMicroContent = useAppSelector(
    (state) => state.homeState.currentSelectedMicroContent
  );

  const handleTimeChange = (ent: TimeEntity) => (value: string) => {
    const newTimeInSec = getSecondsFromHMSM(value);

    if (typeof newTimeInSec === "undefined") {
      return;
    }
    const newTime = newTimeInSec * 1000 + currentSelectedMicroContent.start;

    const setter = {
      start: onStartUpdate,
      end: onEndUpdate,
    };

    if (
      newTime >= currentSelectedMicroContent.start &&
      newTime <= currentSelectedMicroContent.end
    ) {
      if (isAudioCard) {
        audioAssetTimeUpdate(newTime, assetId, ent);
        return;
      }
      setter[ent](newTime, assetId, true);
      return newTimeInSec.toString();
    }
    return;
  };
  const timeDifference = (value - currentSelectedMicroContent.start) / 1000;

  return (
    <TimeField
      disabled={isFullLength || isVideoCard}
      time={formatSeconds(timeDifference > 0 ? timeDifference : 0)}
      handleTimeChange={handleTimeChange(inputType)}
      className={clsx(isVideoCard && "opacity-40 bg-gray-100 ", "w-28")}
    />
  );
};

const MediaCardQuickActionSection: React.FC<MediaCardQuickActionSectionProps> =
  memo(
    ({
      isVideoCard,
      isAudioCard,
      isFullLength = false,
      removeAsset,
      handleIsFullLengthUpdate,
      mediaVolume = 30,
      handleAudioVolumeUpdate,
    }) => {
      return (
        <>
          {isAudioCard && (
            <div className="ml-2.5 2xl:ml-5">
              <VolumeControl
                volume={mediaVolume}
                setVolume={(volume: number) => {
                  if (handleAudioVolumeUpdate) handleAudioVolumeUpdate(volume);
                }}
              />
            </div>
          )}
          <div className="flex justify-between items-center mt-4 ml-2.5 2xl:ml-5">
            {isVideoCard ? (
              <div className="flex items-center space-x-1">
                <InformationCircleIcon className="text-gray-500 w-4" />
                <p className="text-gray-500 text-xs">
                  Audio will be muted for this clip
                </p>
              </div>
            ) : (
              <div className="flex items-center space-x-1.5">
                <Checkbox
                  checked={isFullLength}
                  onChange={(e) => handleIsFullLengthUpdate(e.target.checked)}
                />
                <p className="text-gray-500 text-xs">
                  {isAudioCard
                    ? "Add for entire duration"
                    : "Show for entire duration"}
                </p>
              </div>
            )}

            <TrashIcon
              className="w-4 text-red-500 cursor-pointer"
              onClick={removeAsset}
            />
          </div>
        </>
      );
    }
  );

const AppliedMediaCard: React.FC<AppliedMediaCardProps> = ({
  assetId,
  assetUrl,
  assetType,
  startTime,
  endTime,
  isFullLength,
  mediaVolume,
  assetName,
}) => {
  const {
    handleRemoveAsset,
    removeAudioAsset,
    updateIsFullVideoLength,
    audioAssetVolumeUpdate,
    audioAssetTimeUpdate,
  } = useOutletContext<any>();

  const currentSelectedMicroContent = useAppSelector(
    (state) => state.homeState.currentSelectedMicroContent
  );

  const removeAsset = () => {
    PubSub.publish(EDITOR_MEDIA_LOAD_STATE, {
      id: assetId,
      status: EditorMediaStatus.NOT_ADDED,
    });
    if (assetType === SimpleAssetType.AUDIO) {
      removeAudioAsset(assetId);
      return;
    }
    handleRemoveAsset(assetId);
  };

  const handleIsFullLengthUpdate = (value: boolean) => {
    if (assetType === SimpleAssetType.AUDIO) {
      audioAssetTimeUpdate(
        currentSelectedMicroContent.start,
        assetId,
        "start",
        value
      );
      audioAssetTimeUpdate(
        currentSelectedMicroContent.end,
        assetId,
        "end",
        value
      );
    }
    updateIsFullVideoLength(value, assetId, true);
  };

  const handleAudioVolumeUpdate = (mediaVolume: number) => {
    audioAssetVolumeUpdate(mediaVolume, assetId);
  };

  const isVideoCard = assetType === SimpleAssetType.VIDEO;
  const isAudioCard = assetType === SimpleAssetType.AUDIO;

  return (
    <div className=" w-full border shadow-sm mb-6 rounded-md flex items-center p-3.5  2xl:p-4">
      <MediaCard
        url={assetUrl}
        mediaType={assetType}
        mediaCardVariant="small"
        volume={mediaVolume}
        assetName={assetName}
      />
      <div className="flex flex-col rounded-lg ">
        <div className="flex items-center mb-1 ml-2.5 2xl:ml-5">
          <TimeFieldComponent
            value={startTime}
            assetId={assetId}
            inputType="start"
            isFullLength={isFullLength}
            isAudioCard={isAudioCard}
          />

          <p className="text-gray-400 mx-1">-</p>
          <TimeFieldComponent
            value={endTime}
            isVideoCard={isVideoCard}
            assetId={assetId}
            inputType="end"
            isFullLength={isFullLength}
            isAudioCard={isAudioCard}
          />
        </div>
        <MediaCardQuickActionSection
          isVideoCard={isVideoCard}
          isAudioCard={isAudioCard}
          removeAsset={removeAsset}
          isFullLength={isFullLength}
          handleIsFullLengthUpdate={handleIsFullLengthUpdate}
          mediaVolume={mediaVolume}
          handleAudioVolumeUpdate={handleAudioVolumeUpdate}
        />
      </div>
    </div>
  );
};

export default memo(AppliedMediaCard);
