import { useQuery } from "@tanstack/react-query";

import { store } from "@/store";

import api from "@/api/api";
import { ApiEndpoints } from "@/api/constants/ApiEndPoints";
import useGetAllProjects from "@/api/useGetAllProjects";

import { convertFireBaseTimeToDate, currentTimeInISOString } from "@/helpers";

import { Project } from "@/interfaces";

import { getAllProjects } from "./requests";

const getUsageTimeForUser = async (
  userID: string,
  timeFrom: string | null | undefined,
  timeTo: string | null | undefined
) => {
  const { data }: any = await api.get(
    `${ApiEndpoints.USAGE_TRACKING}${userID}/processed?start=${timeFrom}&end=${timeTo}`
  );

  return {
    usage: data?.usage || 0,
  };
};

export default function useUserConsumedProcessingTime() {
  const userID = store.getState().authState.currentUser.uid;
  const userSubDate = convertFireBaseTimeToDate(
    store.getState().authState.userSubscription.subscriptionStartedAt
  );

  const timeFrom = userSubDate && userSubDate.toISOString();
  const timeTo = currentTimeInISOString();
  const isOnBoardingPage = window.location.pathname.includes("onboarding");

  // it will only call first 10 videos, and these will contain the current processing videos
  // so if any video completes processing,
  // it will again call the first 10 videos.
  const { data }: any = useGetAllProjects(
    () => getAllProjects(userID, 10, 0),
    10,
    0,
    0,
    300000,
    isOnBoardingPage
  );

  return useQuery({
    queryKey: [
      "usage",
      userID,
      timeFrom,
      data?.results?.map((p: Project) => `${p.id}-${p.status}`),
    ],
    queryFn: () => getUsageTimeForUser(userID, timeFrom, timeTo),
    enabled: !!data && !!timeFrom && !isOnBoardingPage,
    staleTime: 300000,
  });
}
