import api from "@/api/api";
import { ApiEndpoints } from "@/api/constants/ApiEndPoints";

const tester =
  /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
const validateEmailRegex = (email: string) => {
  if (!email) return false;

  var emailParts = email.split("@");

  if (emailParts.length !== 2) return false;

  var account = emailParts[0];
  var address = emailParts[1];

  if (account.length > 64) return false;
  else if (address.length > 255) return false;

  var domainParts = address.split(".");
  if (
    domainParts.some(function (part) {
      return part.length > 63;
    })
  )
    return false;

  if (!tester.test(email)) return false;

  return true;
};
export const validateEmail = (
  email: string,
  cb: (arg: string) => void,
  checkForTempMail = false
) => {
  email = email.trim();
  if (!email) {
    cb("*Required");
    return false;
  } else if (!validateEmailRegex(email)) {
    cb("Invalid email address");
    return false;
  }
  if (checkForTempMail) {
    return api
      .get(ApiEndpoints.EMAIL_CHECK, { params: { email } })
      .then((res) => {
        const { isValidEmail } = res.data as { isValidEmail: boolean };
        if (isValidEmail) {
          cb("");
          return true;
        }
        cb("Unsupported email type.");
        return false;
      })
      .catch(() => {
        cb("Something went wrong!");
        return false;
      });
  } else {
    cb("");
    return true;
  }
};

export const validatePassword = (password: string, cb: (arg: any) => void) => {
  password = password.trim();
  if (!password.length) {
    cb("*Required");
    return false;
  }
  if (password.length < 8) {
    cb("Password must be 8 characters long");
    return false;
  } else return true;
};

export const validateName = (name: string, cb: (arg: string) => void) => {
  name = name.trim();
  if (!name) {
    cb("*Required");
    return false;
  } else {
    cb("");
    return true;
  }
};
