import { useState } from "react";
import Slider from "react-slick";

import clsx from "clsx";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { TemplateTabVariant, VideoLayout } from "@/enums";

import { NextArrow, PrevArrow } from "@/components/SlickSliderArrow";

import TemplateContainer from "@/views/home/components/BulkExportClipsModal/TemplateContainer";
import { reactSlickSettings } from "@/views/home/components/TemplatePreference/carouselUtil";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  variableWidth: false,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

type TemplateSliderProps = {
  selectedLayout: VideoLayout;
  generateTemplatesData: (
    selectedLayout: VideoLayout,
    currentSelectedTab: TemplateTabVariant
  ) => any[];
  currentSelectedTab: TemplateTabVariant;
  setCurrentSelectedTab: (value: TemplateTabVariant) => void;
  isUserTemplateEmpty: (layoutId: VideoLayout) => boolean;
};

const TemplateSlider = ({
  selectedLayout,
  generateTemplatesData,
  currentSelectedTab,
  setCurrentSelectedTab,
  isUserTemplateEmpty,
}: TemplateSliderProps) => {
  const getReactSlickSettings = () => {
    const newSettings = { ...settings };
    return {
      ...newSettings,
      ...reactSlickSettings(selectedLayout),
    };
  };

  return (
    <div className="flex flex-col -mt-4">
      <ul className="flex w-80 transition-transform mx-auto mb-5">
        <li
          className={clsx(
            "w-[50%] cursor-pointer whitespace-nowrap text-center  rounded-l-md",
            currentSelectedTab === TemplateTabVariant.DEFAULT_TEMPLATE
              ? `bg-[#C3DDFD]`
              : `bg-gray-100`
          )}
          onClick={() => {
            setCurrentSelectedTab(TemplateTabVariant.DEFAULT_TEMPLATE);
          }}
        >
          <p className="inline-block p-3">Default Templates</p>
        </li>

        <li
          className={clsx(
            "w-[50%] cursor-pointer select-none whitespace-nowrap text-center rounded-r-md",
            currentSelectedTab === TemplateTabVariant.USER_TEMPLATE
              ? `bg-[#C3DDFD]`
              : `bg-gray-100`,
            isUserTemplateEmpty(selectedLayout) &&
              "opacity-50 !cursor-not-allowed"
          )}
          onClick={() => {
            if (isUserTemplateEmpty(selectedLayout)) {
              return;
            }
            setCurrentSelectedTab(TemplateTabVariant.USER_TEMPLATE);
          }}
        >
          <p className="inline-block p-3">My Templates</p>
        </li>
      </ul>
      <div
        className={clsx(
          "relative",
          selectedLayout === VideoLayout.LAYOUT_16_9 && "h-[160px] w-[720px]",
          selectedLayout === VideoLayout.LAYOUT_1_1 && "h-[220px] w-[770px]",
          selectedLayout === VideoLayout.LAYOUT_9_16_1 && "h-[260px] w-[720px]",
          selectedLayout === VideoLayout.LAYOUT_9_16_2 && "h-[260px] w-[720px]"
        )}
      >
        <Slider
          key={selectedLayout}
          {...getReactSlickSettings()}
        >
          {generateTemplatesData(selectedLayout, currentSelectedTab).map(
            (templateData: any) => (
              <TemplateContainer
                templateData={templateData}
                selectedLayout={selectedLayout}
                key={templateData.id}
              />
            )
          )}
        </Slider>
      </div>
    </div>
  );
};
export default TemplateSlider;
