import { useMutation, useQueryClient } from "@tanstack/react-query";

import { addDraft } from "@/api/requests";

import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

import { FirebaseCollection } from "@/enums";

export default function useAddDraft(
  cb?: () => void,
  draftType = FirebaseCollection.EDITOR_DRAFTS
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => addDraft(data, draftType),
    onSuccess: () => {
      if (cb) cb();
      queryClient.invalidateQueries({ queryKey: ["editor-drafts"] });
      queryClient.invalidateQueries({ queryKey: ["render-drafts"] });
    },
    onError: (error) => {
      console.log(error);
      showNotification(
        "Something went wrong, please try again!",
        notificationType.FAIL
      );
    },
  });
}
