const CompletedOnboardingSteps = ({
  syncUserCommonPreferencesWithCurrentProject,
  resetDefaultsOnBoardingFlow,
  handelToggleVideoUploadModal,
  savePreference,
  setSavePreference,
}: any) => {
  const handleSaveUserPreferences = (event: any) => {
    setSavePreference(event.target.checked);
  };

  return (
    <div className="relative flex  h-full flex-col justify-center rounded-lg dark:bg-gray-700">
      <div className="py-6 px-4 lg:px-6">
        <section className="bg-white ">
          <p className="text-center text-[60px]">🎊</p>
          <h2 className="mb-2 text-center  text-2xl font-medium tracking-tight text-gray-900 dark:text-white sm:text-2xl">
            ...and you are DONE!
          </h2>

          <div className="mx-auto w-[30rem]">
            <p className=" text-center text-gray-500">
              We are processing your video.
            </p>
            <p className=" text-center text-sm font-light text-gray-400 sm:text-lg">
              This can take upto 30% time of your videos length. We’ll send you
              an email when it’s done!
            </p>
          </div>
          <div className="mb-4 mt-20 flex items-center justify-center">
            <input
              id="saveUserPreferences-checkbox"
              type="checkbox"
              value=""
              checked={savePreference}
              onChange={handleSaveUserPreferences}
              className="h-5 w-5 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
            />
            <label
              htmlFor="saveUserPreferences-checkbox"
              className="ml-2 text-lg  font-light text-gray-500 dark:text-gray-300"
            >
              Save my preferences for all future videos
            </label>
          </div>
        </section>
        <div className="mt-10 flex justify-center">
          <button
            type="button"
            className={`inline-flex w-96 items-center justify-center rounded-lg bg-blue-600 px-5 py-2.5 text-center text-sm font-medium text-white  focus:outline-none focus:ring-4 focus:ring-blue-300  sm:py-3.5 `}
            onClick={() => {
              syncUserCommonPreferencesWithCurrentProject();
              resetDefaultsOnBoardingFlow();
              handelToggleVideoUploadModal(false);
            }}
            id="go-to-dashboard-onboarding-completed"
          >
            Go to dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompletedOnboardingSteps;
