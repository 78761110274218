// component -> button -> id/action

export const BUTTON_IDS = {
  ASSET_CARD: {
    REVIEW_CLIPS_PROJECT_CARD: {
      ID: "review-clips-project-card",
      ACTION: "review clips button clicked",
    },
    PROJECT_CARD: {
      ID: "projectCard",
      ACTION: "review clips card clicked",
    },
  },
  CUSTOM_CLIP_MODAL: {
    CREATE_CUSTOM_MODAL_CONTINUE_BTN: {
      ID: "create-custom-modal-continue-btn",
      ACTION: "Create custom clip confirm btn clicked",
    },
  },
  DOWNLOAD_PREFERENCE_MODAL: {
    DOWNLOAD_CONFIRM_BTN: {
      ID: "download-confirm-btn",
      ACTION: "Download Confirm btn clicked",
    },
  },
  EDITOR_LAYOUT: {
    SAVE_DRAFT_EDITOR_TOP_BTN: {
      ID: "save-draft-editor-top-btn",
      ACTION: "Save/Update Draft button inside editor clicked",
    },
    SAVE_TEMPLATE_EDITOR_TOP_BTN: {
      ID: "save-template-editor-top-btn",
      ACTION: "Save templates btn clicked",
    },
    EDITOR_RENDER_VIDEO_BTN: {
      ID: "editor-render-video-button",
      ACTION: "Download video button clicked",
    },
  },
  GENERATED_FILES: {
    CREATE_CUSTOM_CLIP_BTN: {
      ID: "create-custom-clip-btn",
      ACTION: "create custom clip old ui clicked",
    },
  },
  HEADER: {
    HEADER_OPEN_UPGRADE_MODAL: {
      ID: "header-open-upgrade-modal",
      ACTION: "upgrade button from header clicked",
    },
  },
  ON_BOARDING_UPLOAD_FILE: {
    GENERATE_CLIP_CONTINUE: {
      ID: "generate-clip-continue",
      ACTION: "Start import button clicked",
    },
  },
  PERSONALIZE_VIDEO_FORM: {
    DONE_BUTTON_PERSONALIZE_VIDEO_FORM: {
      ID: "done-button-personalize-video-form",
      ACTION: "video upload finish button clicked",
    },
  },
  PRO_FEATURE_DISCOVERY_MODAL: {
    UPGRADE_NOW_FEATURE_DISCOVERY_MODAL_BTN: {
      ID: "upgrade-now-feature-discovery-modal-button",
      ACTION: "open upgrade modal from pro feature discovery",
    },
  },
  REVIEW_CLIPS: {
    CREATE_CUSTOM_CLIP_BTN_NEW_UI: {
      ID: "create-custom-clip-btn-new-ui",
      ACTION: "custom clip button new preview screen",
    },
  },
  SAVE_DRAFT_MODAL: {
    SAVE_DRAFT_BTN: {
      ID: "save-draft-btn",
      ACTION: "Draft save/update confirmation button",
    },
  },
  SAVE_TEMPLATES_MODAL: {
    UPGRADE_NOW_SAVE_TEMPLATE_MODAL_BTN: {
      ID: "upgrade-now-save-template-modal-button",
      ACTION: "Upgrade now button clicked",
    },
    SAVE_TEMPLATE_BTN: {
      ID: "save-template-btn",
      ACTION: "Template save/update confirmation button",
    },
  },
  TEMPLATE_PREVIEW_NEW: {
    MAKE_CHANGES_TO_CLIP_BTN_NEW_UI: {
      ID: "make-changes-to-clip-button-new",
      ACTION: "Edit & download Button new ui Clicked",
    },
  },
  TEMPLATE_PREVIEW_OLD: {
    MAKE_CHANGES_TO_CLIP_BTN_OLD_UI: {
      ID: "make-changes-to-clip-button",
      ACTION: "Edit & download Button old ui Clicked",
    },
  },
  TEMPLATE_STATIC_PREVIEW: {
    PREVIEW_CLIP_TEMPLATE_INDIVIDUAL: {
      ID: "preview-clip-template-individual",
      ACTION: "preview clip button old ui clicked",
    },
  },
  TEMPLATES_EMPTY: {
    UPGRADE_NOW_TEMPLATES_TAB: {
      ID: "upgrade-now-templates-tab",
      ACTION: "Upgrade Now btn clicked",
    },
  },
  UPDATE_TO_PRO_MODAL: {
    SUBSCRIBE_NOW_BTN: {
      ID: "subscribe-now-btn",
      ACTION: "Subscribe Now clicked",
    },
  },
  USER_ONBOARD_COMPLETE_MODAL: {
    GET_STARTED_BTN: {
      ID: "user-onboarding-completed-btn",
      ACTION: "new user get started button clicked",
    },
  },
  WORKSPACE: {
    HOME_TOP_UPLOAD_FILE_MODAL_BTN: {
      ID: "home-top-upload-file-modal-button",
      ACTION: "upload file button clicked",
    },
    HOME_TOP_UPLOAD_FILE_MODAL_BTN_SOCIAL: {
      ID: "home-top-upload-file-modal-button-social",
      ACTION: "upload file from social icons",
    },
    UPLOAD_VIDEO_CARD: {
      ID: "upload-video-card",
      ACTION: "import video button clicked",
    },
  },
};

export const USER_PAYMENT_CONSTANTS = {
  PLAN_PURCHASED: "subscription-plan-purchased",
  PLAN_MODIFIED: "subscription-plan-changed",
  CANCELLATION: "subscription-cancelled",
  CHURN: "churn",
  REACTIVATION: "subscription-reactivated",
};
