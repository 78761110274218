import { useEffect, useState } from "react";

import { Button, Spinner, TextInput } from "flowbite-react";

import useUpdateProject from "@/api/useUpdateProject";

import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

import BaseModal from "@/components/BaseModal/BaseModal";

const TitleChangeModal = ({
  setTitleChangeModal,
  displayTitleChangeModal,
  projectTitle,
  project,
}: {
  setTitleChangeModal: (value: boolean) => void;
  displayTitleChangeModal: boolean;
  projectTitle: string;
  project: any;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState({
    title: projectTitle,
    error: false,
    errorMessage: "",
  });

  const { mutate, error } = useUpdateProject();

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    if (!title?.title.trim()) {
      setTitle({ ...title, error: true });
    } else {
      setIsLoading(true);
      const updatedProject = { ...project, title: title.title };

      mutate(updatedProject);

      if (error) {
        showNotification(
          "Failed to update title, please try again",
          notificationType.FAIL
        );
      }

      setTitleChangeModal(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setTitle({ title: projectTitle, error: false, errorMessage: "" });
  }, [displayTitleChangeModal]);

  const buttonDisable = () => {
    if (projectTitle === title.title) return true;
    if (!title?.title?.length) return true;
    return false;
  };

  const titleChangeValidate = (e: any) => {
    const title = e.target.value;
    if (title.trim()) {
      setTitle({ ...title, title, error: false });
    } else {
      setTitle({ ...title, error: true });
    }
  };

  return (
    <BaseModal
      isModalOpen={displayTitleChangeModal}
      handelModalOpen={() => {}}
    >
      <form
        className="w-[25rem] bg-white p-6 pb-10 pt-8"
        onSubmit={handleFormSubmit}
      >
        <p className="mb-6 text-center text-2xl font-bold text-gray-900">
          Update video title
        </p>
        <div className="mt-4">
          <div className="w-full relative">
            <TextInput
              type="text"
              name="titleChange"
              id="edit-title"
              placeholder="Enter title"
              value={title.title}
              onChange={titleChangeValidate}
              className=" text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pt-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              color={`${title.error && "failure"}`}
              helperText={
                <span style={{ marginTop: "-10px" }}>
                  {title.error && <>*Required</>}
                </span>
              }
            />
          </div>
        </div>
        <div className="flex flex-row-reverse gap-10 mt-6">
          <Button
            disabled={buttonDisable()}
            type={isLoading ? "button" : "submit"}
            id="title-change-submit-btn"
            className={`w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 ${
              isLoading ? "cursor-wait opacity-70" : ""
            }`}
          >
            {isLoading ? (
              <Spinner className="h-4 w-4" />
            ) : (
              <span>Continue</span>
            )}
          </Button>
          <Button
            color="light"
            id="title-change-cancel-btn"
            className="w-full rounded-lg border border-blue-500 bg-white text-center text-sm font-medium text-blue-500 hover:bg-blue-50  focus:outline-none focus:ring-4 focus:ring-blue-300"
            onClick={() => {
              setTitleChangeModal(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </form>
    </BaseModal>
  );
};
export default TitleChangeModal;
