import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useQueryClient } from "@tanstack/react-query";
import { Button, Spinner } from "flowbite-react";

import {
  toggleUpgradeToProModal,
  updatedPaymentStatus,
} from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import useGetUserOnBoardingData from "@/api/useGetUserOnBoardingData";

import { isProd } from "@/helpers";

import {
  ANALYTICS_CONSTANTS,
  eventsDataToRedux,
} from "@/utils/amplitudeAnalytcs";

import { PlanType, ScreenName } from "@/enums";

import BaseModal from "@/components/BaseModal/BaseModal";

import GenerateIcon from "@/assets/icons/generateClipIcon.svg";

const PaymentsStatusModal = () => {
  const queryClient = useQueryClient();

  const [isPaymentProcessingLoading, setIsPaymentProcessingLoading] =
    useState(true);

  const [searchParams, setSearchParams] = useSearchParams();

  const removeQueryParams = () => {
    const paramPaymentModal = searchParams.get("closePaymentModal");
    const paramModalStatus = searchParams.get("modalStatus");

    if (paramPaymentModal) searchParams.delete("closePaymentModal");
    if (paramModalStatus) searchParams.delete("modalStatus");
    setSearchParams(searchParams);
  };

  const paymentStatus = useAppSelector(
    (state) => state.homeState.paymentStatus
  );
  const uid = useAppSelector((state) => state.authState.currentUser.uid);
  const email = useAppSelector((state) => state.authState.currentUser.email);

  const planType = useAppSelector(
    (state) => state.authState.userSubscription.planType
  );

  const dispatch = useAppDispatch();

  const { data, isLoading }: any = useGetUserOnBoardingData(
    uid,
    !!paymentStatus
  );

  const closePaymentStatusModal = () => {
    dispatch(updatedPaymentStatus(""));
    removeQueryParams();
  };

  const handelRetryPayment = () => {
    closePaymentStatusModal();
    dispatch(toggleUpgradeToProModal(true));
    eventsDataToRedux(ANALYTICS_CONSTANTS.PAYMENT_SCREEN_NAME);
  };

  const updateUserOnBoardingData = () => {
    closePaymentStatusModal();
    // Invalidate all queries to refetch the data
    // This is to ensure that user will not face any free user limit if any
    queryClient.invalidateQueries();
  };

  useEffect(() => {
    if (paymentStatus !== "Success") return;
    // If payment status is success, then wait for 20 seconds to stripe webhook to update the user payment data
    const timeoutId = setTimeout(() => {
      setIsPaymentProcessingLoading(false);
    }, 20000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [paymentStatus]);

  useEffect(() => {
    if (planType !== PlanType.FREE) {
      setIsPaymentProcessingLoading(false);
    }
  }, [planType]);

  return (
    <BaseModal
      isModalOpen={!!paymentStatus}
      handelModalOpen={() => {}}
    >
      <div className=" flex w-full max-w-lg items-center justify-center py-4">
        <div className="relative rounded-lg  dark:bg-gray-700">
          {paymentStatus === "Canceled" && (
            <button
              type="button"
              className="absolute top-0 right-2.5 ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-800 dark:hover:text-white"
              onClick={closePaymentStatusModal}
              id="close-modal-payment-status-cancelled"
            >
              <svg
                aria-hidden="true"
                className="h-5 w-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          )}

          <div className="py-6 px-6 lg:px-8">
            {paymentStatus === "Success" ? (
              <div className="text-center">
                <p className="my-2 text-center text-4xl">✅</p>
                <p className="text-gray-700">
                  The World will never be the same.
                </p>
                <p className="text-black">You're officially upgraded.</p>
                <p className="mt-6 font-light text-gray-400">
                  Manage your membership from settings. You can pick up where
                  you left off now 😉
                </p>
                <p className="mt-8 text-center text-sm font-light text-gray-400">
                  Ps: If you don't see your membership status, please refresh
                  the page after a minute.
                </p>
                <Button
                  onClick={updateUserOnBoardingData}
                  id="close-modal-payment-status-success"
                  disabled={isLoading || isPaymentProcessingLoading}
                  className="mt-4 w-full"
                  size="md"
                >
                  {isLoading || isPaymentProcessingLoading ? (
                    <div className="flex items-center">
                      <Spinner
                        size="sm"
                        light={true}
                      />
                      <p className="ml-2">Updating your subscription...</p>
                    </div>
                  ) : (
                    <>
                      {" "}
                      <img
                        src={GenerateIcon}
                        className="mr-2"
                      />
                      Got it
                    </>
                  )}
                </Button>
              </div>
            ) : paymentStatus === "Canceled" ? (
              <div className="text-center">
                <span className="my-2 text-center text-4xl">&#128577;</span>
                <p className="font-bold text-black"> Oops!!</p>
                <p className="text-black">Your payment did not go through</p>
                <p className="mt-6 font-light text-gray-400">
                  Please make sure you are allowing <br />
                  popups on the checkout page. <br />
                  Want to try it again?
                </p>
                <Button
                  onClick={handelRetryPayment}
                  id="retry-payment-payment-status-cancelled"
                  className="mt-4 w-full"
                >
                  Retry Payment
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default PaymentsStatusModal;
