export const sampleChannelData = [
  -0.11809175461530685, -0.12059067189693451, -0.11389921605587006,
  -0.11366792768239975, -0.12193839251995087, -0.11962637305259705,
  -0.11669503152370453, -0.12227118015289307, -0.11971704661846161,
  -0.11663499474525452, -0.12175194174051285, -0.12015548348426819,
  -0.12061696499586105, -0.12325246632099152, -0.10994356870651245,
  -0.11108742654323578, -0.11976288259029388, -0.07163883745670319,
  -0.04204116016626358, -0.13667073845863342, -0.21707311272621155,
  -0.14172323048114777, -0.05145002529025078, -0.08897113054990768,
  -0.16500972211360931, -0.1646035611629486, -0.11400232464075089,
  -0.10092282295227051, -0.14388535916805267, -0.17029275000095367,
  -0.13948160409927368, -0.10382982343435287, -0.10526163876056671,
  -0.12776243686676025, -0.14792533218860626, -0.1505197286605835,
  -0.1373925656080246, -0.13264580070972443, -0.145758718252182,
  -0.15946504473686218, -0.15682759881019592, -0.14349399507045746,
  -0.14347626268863678, -0.15750278532505035, -0.15811960399150848,
  -0.14408040046691895, -0.13695362210273743, -0.1397424042224884,
  -0.14915630221366882, -0.16085755825042725, -0.16020718216896057,
  -0.1493271440267563, -0.1458520144224167, -0.1498800665140152,
  -0.1515025645494461, -0.14941944181919098, -0.14755617082118988,
  -0.1494932472705841, -0.15291747450828552, -0.15399639308452606,
  -0.1540445238351822, -0.1521546095609665, -0.14790017902851105,
  -0.14469584822654724, -0.14101852476596832, -0.13879652321338654,
  -0.14326635003089905, -0.14556050300598145, -0.14055487513542175,
  -0.13945679366588593, -0.14156275987625122, -0.13824264705181122,
  -0.13609439134597778, -0.13955296576023102, -0.14109498262405396,
  -0.1377899944782257, -0.1328800618648529, -0.13077351450920105,
  -0.13307467103004456, -0.13367868959903717, -0.12998519837856293,
  -0.12766951322555542, -0.12634043395519257, -0.12303262948989868,
  -0.12319532036781311, -0.1275404840707779, -0.12601353228092194,
  -0.11773382872343063, -0.11447880417108536, -0.11840279400348663,
  -0.12037178128957748, -0.11899377405643463, -0.11789819598197937,
  -0.11557193845510483, -0.1127987876534462, -0.11202061921358109,
  -0.11138146370649338, -0.1111520305275917, -0.11070004105567932,
  -0.10607689619064331, -0.10197822004556656, -0.10423740744590759,
  -0.10730676352977753, -0.10627784579992294, -0.10232075303792953,
  -0.09880238026380539, -0.10059192031621933, -0.10366589576005936,
  -0.100401870906353, -0.09540083259344101, -0.0923868715763092,
  -0.08973242342472076, -0.09188288450241089, -0.09451325237751007,
  -0.08803978562355042, -0.0811043232679367, -0.08082340657711029,
  -0.08109766244888306, -0.08298798650503159, -0.08444830030202866,
  -0.07880555838346481, -0.07499051094055176, -0.07613406330347061,
  -0.07317282259464264, -0.07194588333368301, -0.07441383600234985,
  -0.07065749168395996, -0.06951654702425003, -0.07537208497524261,
  -0.07071125507354736, -0.05985242500901222, -0.05931112542748451,
  -0.0603681318461895, -0.05830948054790497, -0.061167918145656586,
  -0.061028365045785904, -0.05425747483968735, -0.05270744115114212,
  -0.054431866854429245, -0.053042080253362656, -0.05422961711883545,
  -0.0563352033495903, -0.05367417633533478, -0.05139787867665291,
  -0.050329748541116714, -0.04693564027547836, -0.04797997325658798,
  -0.05340176820755005, -0.051055822521448135, -0.043326664716005325,
  -0.041469816118478775, -0.0436847098171711, -0.04220721498131752,
  -0.03284847363829613, -0.021806811913847923, -0.023084230720996857,
  -0.03141353651881218, -0.028736356645822525, -0.01991625316441059,
  -0.015516260638833046, -0.009722438640892506, -0.007658492308109999,
  -0.018390266224741936, -0.024517452344298363, -0.013933884911239147,
  -0.0035293404944241047, -0.0013368293875828385, 0.0027998872101306915,
  0.008745526894927025, 0.009900077246129513, 0.00943021010607481,
  0.012626942247152328, 0.019103704020380974, 0.0258784256875515,
  0.029720021411776543, 0.029386920854449272, 0.027965156361460686,
  0.029925549402832985, 0.03537779301404953, 0.041517458856105804,
  0.04865729808807373, 0.05669613555073738, 0.06121364235877991,
  0.06254956126213074, 0.06612007319927216, 0.0704411193728447,
  0.07317692786455154, 0.07968897372484207, 0.08771829307079315,
  0.08639198541641235, 0.07972928136587143, 0.08196014165878296,
  0.092433400452137, 0.10101457685232162, 0.10565756261348724,
  0.10894089937210083, 0.11166507005691528, 0.11509838700294495,
  0.1205771267414093, 0.12728683650493622, 0.13191449642181396,
  0.13247548043727875, 0.1329980492591858, 0.13575749099254608,
  0.1359378695487976, 0.13581377267837524, 0.14342544972896576,
  0.15199707448482513, 0.15132401883602142, 0.14972253143787384,
  0.15571598708629608, 0.16243724524974823, 0.16510261595249176,
  0.16664893925189972, 0.16860634088516235, 0.17146340012550354,
  0.17309358716011047, 0.1713985800743103, 0.17340104281902313,
  0.1832481473684311, 0.18980740010738373, 0.18565113842487335,
  0.17976099252700806, 0.18118734657764435, 0.1879345327615738,
  0.19310979545116425, 0.19436341524124146, 0.19506411254405975,
  0.1943339854478836, 0.18981783092021942, 0.18975932896137238,
  0.19656479358673096, 0.1962941437959671, 0.18925680220127106,
  0.1923559010028839, 0.20313109457492828, 0.20616741478443146,
  0.20085996389389038, 0.1953313946723938, 0.19712406396865845,
  0.2068731039762497, 0.21068641543388367, 0.20369462668895721,
  0.20056861639022827, 0.20306873321533203, 0.2011406570672989,
  0.19838228821754456, 0.19861964881420135, 0.1994062215089798,
  0.2034677118062973, 0.20606176555156708, 0.19784903526306152,
  0.18645797669887543, 0.18920832872390747, 0.21448412537574768,
  0.23793354630470276, 0.20946170389652252, 0.14300726354122162,
  0.12922453880310059, 0.1871481090784073, 0.23137596249580383,
  0.22051739692687988, 0.1915508657693863, 0.17121106386184692,
  0.16444459557533264, 0.17474579811096191, 0.19134633243083954,
  0.20033524930477142, 0.20012760162353516, 0.1935758888721466,
  0.1840551197528839, 0.1740964949131012, 0.16920454800128937,
  0.17783796787261963, 0.19018082320690155, 0.1868075579404831,
  0.17419466376304626, 0.16867463290691376, 0.1681862622499466,
  0.16923871636390686, 0.17178532481193542, 0.16903729736804962,
  0.16298964619636536, 0.16379551589488983, 0.1672741025686264,
  0.16346138715744019, 0.15454062819480896, 0.14787141978740692,
  0.1481178104877472, 0.1539829671382904, 0.15522781014442444,
  0.14653396606445312, 0.13860616087913513, 0.13903336226940155,
  0.1393769383430481, 0.13541267812252045, 0.13434964418411255,
  0.13459430634975433, 0.12912032008171082, 0.12390168011188507,
  0.12497784197330475, 0.12546290457248688, 0.1213844045996666,
  0.11631433665752411, 0.11042817682027817, 0.10470587015151978,
  0.10290859639644623, 0.10219541937112808, 0.09874220937490463,
  0.0958753377199173, 0.09448082000017166, 0.09006345272064209,
  0.08497335761785507, 0.08612868934869766, 0.08882464468479156,
  0.08274657279253006, 0.07273154705762863, 0.07052021473646164,
  0.07197590917348862, 0.0679328516125679, 0.06469567865133286,
  0.06822021305561066, 0.06737402081489563, 0.05644189193844795,
  0.04934109374880791, 0.052050620317459106, 0.05266571044921875,
  0.04995992034673691, 0.05188199132680893, 0.04946962371468544,
  0.03560458868741989, 0.026925506070256233, 0.03255942463874817,
  0.03872886300086975, 0.037530165165662766, 0.0329144261777401,
  0.027054553851485252, 0.022732097655534744, 0.021726245060563087,
  0.0212031751871109, 0.021021446213126183, 0.021244121715426445,
  0.018235499039292336, 0.013170566409826279, 0.009802376851439476,
  0.007011278532445431, 0.005739158019423485, 0.008736110292375088,
  0.010517232120037079, 0.004364983178675175, -0.00478350929915905,
  -0.0063426378183066845, -0.00043127613025717437, 0.000842181674670428,
  -0.007069415878504515, -0.013755543157458305, -0.013638527132570744,
  -0.013683263212442398, -0.01803368702530861, -0.024520302191376686,
  -0.03049967624247074, -0.030813779681921005, -0.02438606321811676,
  -0.021716579794883728, -0.02946465276181698, -0.03913067281246185,
  -0.04157501831650734, -0.038202349096536636, -0.03551545366644859,
  -0.038278430700302124, -0.0452444963157177, -0.050833072513341904,
  -0.05277246981859207, -0.052130427211523056, -0.05034095421433449,
  -0.051851749420166016, -0.05887148529291153, -0.0649852454662323,
  -0.0650380402803421, -0.06282515823841095, -0.06334767490625381,
  -0.06625013053417206, -0.06784163415431976, -0.06906814128160477,
  -0.07630214095115662, -0.08552025258541107, -0.08379574120044708,
  -0.0747251957654953, -0.07288719713687897, -0.07856988161802292,
  -0.08597850054502487, -0.09285026788711548, -0.09247925132513046,
  -0.08618169277906418, -0.0871010348200798, -0.09463566541671753,
  -0.09815036505460739, -0.09929848462343216, -0.10126771032810211,
  -0.10127099603414536, -0.102776437997818, -0.10705526918172836,
  -0.10620991140604019, -0.10182851552963257, -0.10529317706823349,
  -0.11433891952037811, -0.11502212285995483, -0.10812197625637054,
  -0.10974635183811188, -0.12067299336194992, -0.12293753027915955,
  -0.11488450318574905, -0.1128702163696289, -0.11832599341869354,
  -0.12339702248573303, -0.12872833013534546, -0.128272145986557,
  -0.11764980852603912, -0.11578671634197235, -0.13026461005210876,
  -0.13669846951961517, -0.12696057558059692, -0.12177103757858276,
  -0.12865860760211945, -0.1368667632341385, -0.13880321383476257,
  -0.1346110701560974, -0.12930718064308167, -0.12715119123458862,
  -0.1315387636423111, -0.14357160031795502, -0.14946065843105316,
  -0.13738073408603668, -0.1268700361251831, -0.13619062304496765,
  -0.14740507304668427, -0.144905686378479, -0.13783025741577148,
  -0.13359802961349487, -0.13775137066841125, -0.15119977295398712,
  -0.1547900140285492, -0.14355072379112244, -0.13897714018821716,
  -0.14206598699092865, -0.14288286864757538, -0.151199609041214,
  -0.16043619811534882, -0.15247198939323425, -0.14387793838977814,
  -0.14856871962547302, -0.15024752914905548, -0.15091031789779663,
  -0.1593923717737198, -0.15749475359916687, -0.14594975113868713,
  -0.14709687232971191, -0.1511668860912323, -0.14634302258491516,
  -0.15090511739253998, -0.16222631931304932, -0.15650512278079987,
  -0.14246590435504913, -0.14280183613300323, -0.15418113768100739,
  -0.16241100430488586, -0.16096487641334534, -0.15254536271095276,
  -0.14833964407444, -0.1529925912618637, -0.15840774774551392,
  -0.15883663296699524, -0.15586242079734802, -0.1532021164894104,
  -0.15323354303836823, -0.15269014239311218, -0.14959898591041565,
  -0.14985451102256775, -0.15350249409675598, -0.15301178395748138,
  -0.15063050389289856, -0.15115603804588318, -0.14834310114383698,
  -0.14277836680412292, -0.14653007686138153, -0.15458117425441742,
  -0.14912112057209015, -0.13774949312210083, -0.1421198546886444,
  -0.152602419257164, -0.14682917296886444, -0.13576054573059082,
  -0.13677525520324707, -0.13876472413539886, -0.13682736456394196,
  -0.14230485260486603, -0.1445300132036209, -0.1316368579864502,
  -0.12406910955905914, -0.13282474875450134, -0.1399625986814499,
  -0.13855057954788208, -0.13326801359653473, -0.12365018576383591,
  -0.11848506331443787, -0.12391511350870132, -0.1273786425590515,
  -0.12590675055980682, -0.1281498223543167, -0.12706045806407928,
  -0.11690293997526169, -0.11018847674131393, -0.11366048455238342,
  -0.12084876745939255, -0.1238359734416008, -0.11648531258106232,
  -0.10356380045413971, -0.09905117005109787, -0.10639304667711258,
  -0.11598173528909683, -0.11541299521923065, -0.10207497328519821,
  -0.09396996349096298, -0.10230045020580292, -0.10686858743429184,
  -0.0970258042216301, -0.09143028408288956, -0.09537824988365173,
  -0.09956858307123184, -0.10340758413076401, -0.09824502468109131,
  -0.0810064971446991, -0.07675345987081528, -0.09056802839040756,
  -0.09524775296449661, -0.0883815735578537, -0.08352511376142502,
  -0.07717988640069962, -0.07631642371416092, -0.08739988505840302,
  -0.08659231662750244, -0.07119342684745789, -0.07287884503602982,
  -0.08697744458913803, -0.08242897689342499, -0.06620441377162933,
  -0.06109745800495148, -0.06600457429885864, -0.07158509641885757,
  -0.07122037559747696, -0.06247280538082123, -0.0552995391190052,
  -0.058074142783880234, -0.06379691511392593, -0.06256821751594543,
  -0.054007116705179214, -0.05013235658407211, -0.06117825210094452,
  -0.07095640897750854, -0.058972083032131195, -0.04180881381034851,
  -0.044556111097335815, -0.05379185080528259, -0.043256256729364395,
  -0.019974425435066223, -0.012539644725620747, -0.028048787266016006,
  -0.04181183502078056, -0.032828740775585175, -0.013398222625255585,
  -0.005554680712521076, -0.01047020684927702, -0.02058352902531624,
  -0.028959546238183975, -0.025949301198124886, -0.016295313835144043,
  -0.012665259651839733, -0.008351770229637623, 0.0027468090411275625,
  0.00842338614165783, 0.007918978109955788, 0.0107246283441782,
  0.012084007263183594, 0.011788883246481419, 0.021175649017095566,
  0.036512650549411774, 0.043925940990448, 0.037479449063539505,
  0.022212877869606018, 0.019268404692411423, 0.03905465453863144,
  0.054367512464523315, 0.05085127428174019, 0.05570095777511597,
  0.0729389563202858, 0.07242067903280258, 0.05745160952210426,
  0.058229342103004456, 0.07462415099143982, 0.0856313556432724,
  0.08520680665969849, 0.08110161125659943, 0.08118609338998795,
  0.0879167839884758, 0.09859441220760345, 0.10749159753322601,
  0.10897545516490936, 0.10742419958114624, 0.11435014754533768,
  0.12751378118991852, 0.1340603083372116, 0.1316203773021698,
  0.12736165523529053, 0.12962612509727478, 0.14006663858890533,
  0.14540763199329376, 0.1391923725605011, 0.1376999169588089,
  0.14697179198265076, 0.1543540209531784, 0.15981359779834747,
  0.16647714376449585, 0.16641688346862793, 0.16614896059036255,
  0.17755459249019623, 0.18848839402198792, 0.1854480654001236,
  0.17513711750507355, 0.17062394320964813, 0.18017244338989258,
  0.19460077583789825, 0.1953684687614441, 0.18959736824035645,
  0.19302596151828766, 0.1952274739742279, 0.19049404561519623,
  0.19106292724609375, 0.19494251906871796, 0.20011919736862183,
  0.21355721354484558, 0.21835626661777496, 0.20066462457180023,
  0.18541119992733002, 0.19036801159381866, 0.20397211611270905,
  0.21486499905586243, 0.2142472118139267, 0.20600533485412598,
  0.20977461338043213, 0.2155267894268036, 0.20257088541984558,
  0.19676899909973145, 0.2165514975786209, 0.2298588752746582,
  0.22392699122428894, 0.21657012403011322, 0.2071073353290558,
  0.19977132976055145, 0.20949146151542664, 0.2201228141784668,
  0.21554550528526306, 0.21183094382286072, 0.2136419415473938,
  0.20990800857543945, 0.20653150975704193, 0.2085036039352417,
  0.20925229787826538, 0.21104416251182556, 0.21644850075244904,
  0.2179000824689865, 0.21206870675086975, 0.20387114584445953,
  0.20083363354206085, 0.20689009130001068, 0.21466508507728577,
  0.21373678743839264, 0.20322445034980774, 0.19134457409381866,
  0.19110773503780365, 0.20383445918560028, 0.21037615835666656,
  0.20151033997535706, 0.19036714732646942, 0.18514998257160187,
  0.18779289722442627, 0.1983354389667511, 0.2005976438522339,
  0.1886441856622696, 0.18468347191810608, 0.19056273996829987,
  0.18537753820419312, 0.17662346363067627, 0.17917439341545105,
  0.18065614998340607, 0.17515619099140167, 0.17267553508281708,
  0.17105595767498016, 0.16502846777439117, 0.159972682595253,
  0.1616884469985962, 0.16771556437015533, 0.16291119158267975,
  0.14064322412014008, 0.1273585706949234, 0.1422111690044403,
  0.15638716518878937, 0.14323067665100098, 0.12506863474845886,
  0.12949006259441376, 0.14362908899784088, 0.1382940113544464,
  0.11571618169546127, 0.10765896737575531, 0.12256750464439392,
  0.1341027021408081, 0.1271371841430664, 0.10852251946926117,
  0.09087236225605011, 0.09061028808355331, 0.10217718780040741,
  0.0998108983039856, 0.09189833700656891, 0.0958385318517685,
  0.08673109859228134, 0.06482768803834915, 0.07424626499414444,
  0.09691357612609863, 0.07788314670324326, 0.04433434084057808,
  0.05029032379388809, 0.07552824169397354, 0.07762393355369568,
  0.056744687259197235, 0.03828316554427147, 0.04449858516454697,
  0.06523215025663376, 0.05978957191109657, 0.023614518344402313,
  0.006799509283155203, 0.03344418853521347, 0.06015998125076294,
  0.04506645351648331, 0.010567069053649902, 0.001418348983861506,
  0.01873275637626648, 0.03273646533489227, 0.023679962381720543,
  -0.002562451409175992, -0.0077121383510529995, 0.026546750217676163,
  0.0393642783164978, -0.009124474599957466, -0.042966004461050034,
  -0.011536739766597748, 0.015944158658385277, -0.00017434920300729573,
  -0.01610417291522026, -0.020177345722913742, -0.024209165945649147,
  -0.010887403972446918, 0.003197127254679799, -0.021077103912830353,
  -0.05554908514022827, -0.05014369636774063, -0.0186624713242054,
  -0.004116898402571678, -0.02001318894326687, -0.04783422499895096,
  -0.06193646788597107, -0.05852675437927246, -0.04953639209270477,
  -0.03842061385512352, -0.03243405744433403, -0.04265063256025314,
  -0.05697927623987198, -0.06214970722794533, -0.06814417243003845,
  -0.07293815910816193, -0.062154822051525116, -0.052539996802806854,
  -0.0647687017917633, -0.07827365398406982, -0.07303530722856522,
  -0.06746172159910202, -0.07832784950733185, -0.09493833035230637,
  -0.09984780848026276, -0.08099856972694397, -0.051603998988866806,
  -0.0576070211827755, -0.10469361394643784, -0.12578928470611572,
  -0.09624592214822769, -0.08007947355508804, -0.10151905566453934,
  -0.11365630477666855, -0.10903056710958481, -0.11048515886068344,
  -0.10238071531057358, -0.08681712299585342, -0.10352397710084915,
  -0.13837461173534393, -0.1344635784626007, -0.09642931818962097,
  -0.08289644122123718, -0.1121579185128212, -0.13982127606868744,
  -0.13167020678520203, -0.1147814616560936, -0.12159615755081177,
  -0.13636815547943115, -0.14167410135269165, -0.14182111620903015,
  -0.12968719005584717, -0.11234690994024277, -0.12039097398519516,
  -0.14517509937286377, -0.15530060231685638, -0.15183435380458832,
  -0.1364416629076004, -0.11669105291366577, -0.13599228858947754,
  -0.1826574206352234, -0.17764456570148468, -0.12977267801761627,
  -0.12488224357366562, -0.16070136427879333, -0.17486484348773956,
  -0.16458800435066223, -0.1530897319316864, -0.14238803088665009,
  -0.1424792855978012, -0.15852215886116028, -0.17132554948329926,
  -0.1716921180486679, -0.16440008580684662, -0.14875663816928864,
  -0.14124630391597748, -0.16600152850151062, -0.1942744106054306,
  -0.177928164601326, -0.14760294556617737, -0.16036087274551392,
  -0.18505148589611053, -0.17348183691501617, -0.15443597733974457,
  -0.15108716487884521, -0.14816558361053467, -0.16776026785373688,
  -0.20749004185199738, -0.18864689767360687, -0.12036001682281494,
  -0.12082598358392715, -0.18527919054031372, -0.19423452019691467,
  -0.15054719150066376, -0.14876499772071838, -0.18580769002437592,
  -0.19443389773368835, -0.16367019712924957, -0.13303804397583008,
  -0.13965684175491333, -0.17789886891841888, -0.195139080286026,
  -0.16551761329174042, -0.13382217288017273, -0.1434771567583084,
  -0.175582155585289, -0.1858750283718109, -0.1606924831867218,
  -0.13117456436157227, -0.14184163510799408, -0.18679243326187134,
  -0.1997033655643463, -0.15041615068912506, -0.10525750368833542,
  -0.1288508176803589, -0.18535493314266205, -0.20246104896068573,
  -0.17236453294754028, -0.14118888974189758, -0.14119912683963776,
  -0.15744559466838837, -0.15962722897529602, -0.15325598418712616,
  -0.154175266623497, -0.14774161577224731, -0.13961149752140045,
  -0.16096393764019012, -0.1826818585395813, -0.15570102632045746,
  -0.11474653333425522, -0.11388473957777023, -0.1408318132162094,
  -0.1621459573507309, -0.15956996381282806, -0.13767725229263306,
  -0.1344408541917801, -0.15729692578315735, -0.1611199676990509,
  -0.1404954344034195, -0.13102973997592926, -0.13462518155574799,
  -0.13860352337360382, -0.13834607601165771, -0.12730377912521362,
  -0.12147172540426254, -0.12898536026477814, -0.12414011359214783,
  -0.12024344503879547, -0.13991115987300873, -0.13414403796195984,
  -0.0934939831495285, -0.09199141710996628, -0.12751053273677826,
  -0.13627707958221436, -0.12467950582504272, -0.11169052124023438,
  -0.08935661613941193, -0.08440376818180084, -0.10555851459503174,
  -0.11537517607212067, -0.10932040959596634, -0.09788881987333298,
  -0.07906703650951385, -0.07931312918663025, -0.10058683902025223,
  -0.10003665089607239, -0.08506214618682861, -0.08357778191566467,
  -0.07804656028747559, -0.07214342057704926, -0.07982711493968964,
  -0.0731348991394043, -0.061466384679079056, -0.0759621113538742,
  -0.07766933739185333, -0.05406542122364044, -0.061238810420036316,
  -0.083870068192482, -0.0657816082239151, -0.03460221737623215,
  -0.031330861151218414, -0.043968599289655685, -0.05831824615597725,
  -0.06145727261900902, -0.045180365443229675, -0.0308739822357893,
  -0.030316133052110672, -0.040611498057842255, -0.06408243626356125,
  -0.06816559284925461, -0.03175398334860802, -0.014414770528674126,
  -0.04104018583893776, -0.05003964155912399, -0.035952042788267136,
  -0.04092685133218765, -0.041278157383203506, -0.022791890427470207,
  -0.02626010589301586, -0.03916936740279198, -0.021814927458763123,
  -0.0014547471655532718, -0.005501687992364168, -0.011080337688326836,
  -0.0047860112972557545, 0.007240894250571728, 0.012228986248373985,
  -0.009194692596793175, -0.03208339214324951, -0.009201507084071636,
  0.029640592634677887, 0.03036533109843731, 0.010687093250453472,
  -0.011139423586428165, -0.04139632359147072, -0.031115936115384102,
  0.041305337101221085, 0.08285008370876312, 0.044310394674539566,
  0.0052526239305734634, 0.017236024141311646, 0.04522494971752167,
  0.058933284133672714, 0.05386427417397499, 0.045430947095155716,
  0.06047320365905762, 0.07655075192451477, 0.05746548995375633,
  0.03863796964287758, 0.05239612236618996, 0.06314244866371155,
  0.05652294680476189, 0.058291248977184296, 0.07181321829557419,
  0.09320890158414841, 0.11304248869419098, 0.10024405270814896,
  0.06690426915884018, 0.06726928800344467, 0.10275647789239883,
  0.12880980968475342, 0.1200481727719307, 0.08326427638530731,
  0.06530768424272537, 0.10429080575704575, 0.15255089104175568,
  0.14926190674304962, 0.11901689320802689, 0.10963728278875351,
  0.1276206225156784, 0.15768331289291382, 0.1683114469051361,
  0.14418239891529083, 0.1249527558684349, 0.14199033379554749,
  0.1683778017759323, 0.16950033605098724, 0.1405523419380188,
  0.11179311573505402, 0.12982983887195587, 0.1817179024219513,
  0.19734494388103485, 0.167636439204216, 0.15154100954532623,
  0.16430650651454926, 0.17149902880191803, 0.16744649410247803,
  0.17591778934001923, 0.19528290629386902, 0.19740436971187592,
  0.18020671606063843, 0.17324496805667877, 0.18310904502868652,
  0.18814492225646973, 0.1795825958251953, 0.16513709723949432,
  0.16425706446170807, 0.1929624229669571, 0.2180798202753067,
  0.19719137251377106, 0.16270600259304047, 0.164854496717453,
  0.19157303869724274, 0.21323269605636597, 0.2143511176109314,
  0.19279834628105164, 0.18430495262145996, 0.2038368135690689,
  0.20301608741283417, 0.17855310440063477, 0.1840537041425705,
  0.20795005559921265, 0.20952348411083221, 0.20210202038288116,
  0.19171454012393951, 0.17322421073913574, 0.18259947001934052,
  0.22218194603919983, 0.22920866310596466, 0.1829262524843216,
  0.13849960267543793, 0.15028223395347595, 0.20070195198059082,
  0.21232512593269348, 0.1696169376373291, 0.15093469619750977,
  0.177982896566391, 0.1933884471654892, 0.18923088908195496,
  0.18180391192436218, 0.16219569742679596, 0.1649305671453476,
  0.2065247744321823, 0.21079441905021667, 0.16498544812202454,
  0.15835216641426086, 0.18742473423480988, 0.17322483658790588,
  0.13510534167289734, 0.13789452612400055, 0.1730300486087799,
  0.19799251854419708, 0.1890009641647339, 0.15092895925045013,
  0.12444408237934113, 0.13517916202545166, 0.15391534566879272,
  0.15621061623096466, 0.1596435159444809, 0.17238125205039978,
  0.17253457009792328, 0.15208251774311066, 0.1318368911743164,
  0.12841439247131348, 0.13897733390331268, 0.15367360413074493,
  0.1569206565618515, 0.13633163273334503, 0.1119929626584053,
  0.11710494756698608, 0.1384074091911316, 0.13279739022254944,
  0.10291368514299393, 0.09225064516067505, 0.10952102392911911,
  0.12208958715200424, 0.11965572834014893, 0.11716540157794952,
  0.10841576755046844, 0.09024335443973541, 0.09181283414363861,
  0.11422538012266159, 0.12198065221309662, 0.11741848289966583,
  0.11225437372922897, 0.07866845279932022, 0.03449136018753052,
  0.04751197621226311, 0.1005975604057312, 0.11309731006622314,
  0.0772489532828331, 0.044998396188020706, 0.04402172937989235,
  0.06152838096022606, 0.06661543995141983, 0.05897991731762886,
  0.0663943663239479, 0.0742093175649643, 0.059132710099220276,
  0.05443586781620979, 0.0663299709558487, 0.05124911293387413,
  0.028393622487783432, 0.039396099746227264, 0.04941356182098389,
  0.03656217083334923, 0.03860790655016899, 0.04965723305940628,
  0.03510405495762825, 0.020239194855093956, 0.037547606974840164,
  0.05524992197751999, 0.035448119044303894, 0.009266315959393978,
  0.024267196655273438, 0.043071672320365906, 0.01001055259257555,
  -0.0177482757717371, 0.022149307653307915, 0.05679361894726753,
  0.02081824280321598, -0.030471518635749817, -0.04253168776631355,
  -0.011433900333940983, 0.041251689195632935, 0.04223531857132912,
  -0.027542054653167725, -0.05866670608520508, -0.01244136318564415,
  0.016911927610635757, 0.00509345717728138, -0.0065970653668046,
  -0.02824382670223713, -0.04090641438961029, -0.00936999823898077,
  0.0034220414236187935, -0.03907501697540283, -0.06223209574818611,
  -0.050060976296663284, -0.05163074657320976, -0.040783774107694626,
  -0.0007120915106497705, 0.0004441509081516415, -0.050483375787734985,
  -0.08833779394626617, -0.0878572091460228, -0.05025264248251915,
  0.017651695758104324, 0.03405170887708664, -0.06417902559041977,
  -0.16226035356521606, -0.12327228486537933, -0.02926298975944519,
  -0.029771005734801292, -0.08350512385368347, -0.08791611343622208,
  -0.07932605594396591, -0.10007616132497787, -0.09292156249284744,
  -0.0532430075109005, -0.05020860582590103, -0.07740676403045654,
  -0.08807999640703201, -0.10082324594259262, -0.12789605557918549,
  -0.1250448226928711, -0.08491714298725128, -0.06630364060401917,
  -0.10308869928121567, -0.14906296133995056, -0.1415770947933197,
  -0.09949207305908203, -0.0897737368941307, -0.10860530287027359,
  -0.10718327015638351, -0.09628183394670486, -0.10876591503620148,
  -0.13276246190071106, -0.14619523286819458, -0.13619506359100342,
  -0.10819276422262192, -0.10910115391016006, -0.14116482436656952,
  -0.12632711231708527, -0.08037620782852173, -0.11649276316165924,
  -0.19381751120090485, -0.16753727197647095, -0.07938369363546371,
  -0.07394732534885406, -0.1368868052959442, -0.17018595337867737,
  -0.1556890457868576, -0.1232028678059578, -0.11274980753660202,
  -0.14412619173526764, -0.15740430355072021, -0.116935595870018,
  -0.11115939170122147, -0.17627906799316406, -0.20259875059127808,
  -0.15227331221103668, -0.11941999197006226, -0.129060298204422,
  -0.13552764058113098, -0.15948396921157837, -0.20622390508651733,
  -0.19375430047512054, -0.11867111176252365, -0.09968994557857513,
  -0.1651657223701477, -0.2053573578596115, -0.17689819633960724,
  -0.1558038741350174, -0.17170673608779907, -0.16645239293575287,
  -0.1314517855644226, -0.13668780028820038, -0.19619235396385193,
  -0.22824004292488098, -0.18854504823684692, -0.1327531486749649,
  -0.11199744790792465, -0.12494906783103943, -0.17524391412734985,
  -0.23775945603847504, -0.22790496051311493, -0.14556199312210083,
  -0.1132265031337738, -0.16715310513973236, -0.2085656374692917,
  -0.1922367960214615, -0.1593567281961441, -0.13957999646663666,
  -0.1532554030418396, -0.19517937302589417, -0.20420405268669128,
  -0.16304457187652588, -0.1329408437013626, -0.14150403439998627,
  -0.1664872169494629, -0.18725766241550446, -0.1786399632692337,
  -0.15077510476112366, -0.15437725186347961, -0.17511844635009766,
  -0.16251231729984283, -0.14273999631404877, -0.15076206624507904,
  -0.16805993020534515, -0.18880636990070343, -0.19798864424228668,
  -0.161538228392601, -0.12170524150133133, -0.129585862159729,
  -0.1384020894765854, -0.12820404767990112, -0.15000131726264954,
  -0.18329784274101257, -0.17238211631774902, -0.13808579742908478,
  -0.11485684663057327, -0.11381252855062485, -0.1395939290523529,
  -0.15034322440624237, -0.12596495449543, -0.1311466246843338,
  -0.16629628837108612, -0.14685939252376556, -0.09329419583082199,
  -0.0905478373169899, -0.12181290239095688, -0.13696067035198212,
  -0.13686582446098328, -0.12236746400594711, -0.10017050802707672,
  -0.09499847888946533, -0.10517780482769012, -0.12045309692621231,
  -0.13196733593940735, -0.10831757634878159, -0.058992624282836914,
  -0.04821104556322098, -0.08663657307624817, -0.12870945036411285,
  -0.1464509814977646, -0.12240644544363022, -0.07200383394956589,
  -0.05979903042316437, -0.08950954675674438, -0.09298302233219147,
  -0.07222148030996323, -0.08439449220895767, -0.10855033993721008,
  -0.08159268647432327, -0.02505779080092907, -0.0266012754291296,
  -0.0966060534119606, -0.131122887134552, -0.08389275521039963,
  -0.053055290132761, -0.08726398646831512, -0.09601222723722458,
  -0.04744939133524895, -0.025780949741601944, -0.06267160922288895,
  -0.106348916888237, -0.1121465265750885, -0.0705481618642807,
  -0.022208798676729202, -0.022917604073882103, -0.05528968945145607,
  -0.06979041546583176, -0.06980627775192261, -0.06189591437578201,
  -0.03066740557551384, 0.0026462229434400797, 0.009022868238389492,
  0.00006783060962334275, -0.010613312013447285, -0.020574213936924934,
  -0.023036295548081398, -0.02144494652748108, -0.011701109819114208,
  0.017310459166765213, 0.025307612493634224, -0.03238043561577797,
  -0.09804267436265945, -0.08236832171678543, -0.001617654343135655,
  0.06069347634911537, 0.06606835871934891, 0.03430606424808502,
  0.0017357097240164876, -0.003384668380022049, 0.02702510915696621,
  0.07362224906682968, 0.10004225373268127, 0.08870347589254379,
  0.05924410745501518, 0.03538607805967331, 0.021226832643151283,
  0.022720320150256157, 0.048496946692466736, 0.08317036926746368,
  0.09898678213357925, 0.0911535993218422, 0.0788336768746376,
  0.07852920144796371, 0.08593573421239853, 0.08832703530788422,
  0.09262978285551071, 0.1120443344116211, 0.12497448921203613,
  0.1035737693309784, 0.07882820814847946, 0.09650525450706482,
  0.12859292328357697, 0.12746039032936096, 0.12022272497415543,
  0.14520679414272308, 0.17297974228858948, 0.17360463738441467,
  0.16523240506649017, 0.1600259393453598, 0.1518365442752838,
  0.14178583025932312, 0.12940926849842072, 0.12275518476963043,
  0.1387387067079544, 0.15859605371952057, 0.15008367598056793,
  0.12700118124485016, 0.12129972130060196, 0.14453688263893127,
  0.1890818327665329, 0.21612288057804108, 0.19913405179977417,
  0.1799176037311554, 0.1890908181667328, 0.18809349834918976,
  0.1607816517353058, 0.14136624336242676, 0.14963404834270477,
  0.18483686447143555, 0.21528257429599762, 0.18783064186573029,
  0.1318875253200531, 0.13195432722568512, 0.1711273044347763,
  0.17887622117996216, 0.1684175282716751, 0.17687062919139862,
  0.19112782180309296, 0.19421809911727905, 0.1818384975194931,
  0.16090621054172516, 0.1605711430311203, 0.18611247837543488,
  0.20419318974018097, 0.19699695706367493, 0.1760127693414688,
  0.1678304672241211, 0.1862080693244934, 0.19393698871135712,
  0.1706281304359436, 0.17114447057247162, 0.20360615849494934,
  0.19591060280799866, 0.15288104116916656, 0.14714406430721283,
  0.17594072222709656, 0.18765461444854736, 0.16493657231330872,
  0.13232974708080292, 0.14094364643096924, 0.18548250198364258,
  0.1883866935968399, 0.15439310669898987, 0.1624465435743332,
  0.1900785267353058, 0.178428515791893, 0.16293400526046753,
  0.16719681024551392, 0.1591392606496811, 0.15121330320835114,
  0.16705207526683807, 0.1799626648426056, 0.16288460791110992,
  0.1213473528623581, 0.10091670602560043, 0.1410141885280609,
  0.17938272655010223, 0.1521383672952652, 0.13170893490314484,
  0.15647941827774048, 0.1417195200920105, 0.10145474225282669,
  0.12505970895290375, 0.160551518201828, 0.14173197746276855,
  0.12625108659267426, 0.12997139990329742, 0.1120554506778717,
  0.10548888891935349, 0.13010071218013763, 0.13924844563007355,
  0.129566490650177, 0.12051129341125488, 0.10709115862846375,
  0.11313927918672562, 0.13621051609516144, 0.11451443284749985,
  0.06439121067523956, 0.06518413126468658, 0.10252746939659119,
  0.11709853261709213, 0.10897304862737656, 0.09363113343715668,
  0.08381687104701996, 0.09540881961584091, 0.10618282854557037,
  0.09728220850229263, 0.08831839263439178, 0.0761934444308281,
  0.0678270161151886, 0.10417933017015457, 0.13241030275821686,
  0.06747664511203766, -0.007435561623424292, 0.014528518542647362,
  0.06719918549060822, 0.08527939766645432, 0.09816554933786392,
  0.08632960170507431, 0.03821706399321556, 0.027299879118800163,
  0.054807137697935104, 0.059265218675136566, 0.06662635505199432,
  0.0907844752073288, 0.0733608528971672, 0.04123006388545036,
  0.060608554631471634, 0.07811007648706436, 0.03677075356245041,
  -0.00134330615401268, 0.01867208443582058, 0.057468656450510025,
  0.06636697798967361, 0.05018259957432747, 0.027276307344436646,
  -0.000016986505215754732, -0.00016688031610101461, 0.055474258959293365,
  0.08306150138378143, 0.00862039439380169, -0.04483732208609581,
  0.026721298694610596, 0.09226524829864502, 0.054195303469896317,
  0.0106102479621768, 0.008946647867560387, 0.0025705290026962757,
  0.011113271117210388, 0.04536746069788933, 0.047895219177007675,
  0.018710605800151825, 0.006585380993783474, 0.012225005775690079,
  0.012767567299306393, 0.005349737126380205, 0.007424089126288891,
  0.022331971675157547, 0.005514342337846756, -0.04476416856050491,
  -0.0404147133231163, 0.028703851625323296, 0.058574337512254715,
  0.016910269856452942, -0.039527084678411484, -0.06445939093828201,
  -0.028654346242547035, 0.02715892530977726, 0.008154869079589844,
  -0.055070795118808746, -0.051493506878614426, -0.004560278728604317,
  -0.00146153022069484, -0.027577389031648636, -0.03031424805521965,
  -0.015109033323824406, -0.02533608302474022, -0.06671769917011261,
  -0.07149576395750046, -0.01526164636015892, 0.015601168386638165,
  -0.01981152407824993, -0.07109245657920837, -0.11068201065063477,
  -0.1037701815366745, -0.027515951544046402, 0.018682939931750298,
  -0.022189529612660408, -0.056189391762018204, -0.07358229905366898,
  -0.12924043834209442, -0.12517298758029938, -0.0139850452542305,
  0.04280088096857071, -0.029884200543165207, -0.12311246246099472,
  -0.1558951437473297, -0.121603824198246, -0.062021493911743164,
  -0.04054231196641922, -0.03230415657162666, -0.005463700741529465,
  -0.04526117444038391, -0.15677571296691895, -0.1944401115179062,
  -0.13125260174274445, -0.07298675924539566, -0.04814591258764267,
  -0.04698367044329643, -0.09238434582948685, -0.1517494022846222,
  -0.15636880695819855, -0.10425148904323578, -0.0592334158718586,
  -0.07838720828294754, -0.13204360008239746, -0.14185959100723267,
  -0.12300559133291245, -0.13642139732837677, -0.14431443810462952,
  -0.11389274895191193, -0.11628809571266174, -0.16298887133598328,
  -0.16348527371883392, -0.11274393647909164, -0.09831982851028442,
  -0.1357710361480713, -0.1657072901725769, -0.16298744082450867,
  -0.14474999904632568, -0.12191841006278992, -0.10774295032024384,
  -0.1284312605857849, -0.1750352382659912, -0.20098663866519928,
  -0.1974203884601593, -0.1845303624868393, -0.1471482217311859,
  -0.09353677928447723, -0.0946621522307396, -0.1575068086385727,
  -0.1962192803621292, -0.18275007605552673, -0.16621507704257965,
  -0.1600014865398407, -0.14773719012737274, -0.1366693526506424,
  -0.13491158187389374, -0.14735902845859528, -0.17748861014842987,
  -0.1930854767560959, -0.17312082648277283, -0.16402296721935272,
  -0.18681679666042328, -0.19035229086875916, -0.16701160371303558,
  -0.16107288002967834, -0.16674315929412842, -0.16634728014469147,
  -0.17781205475330353, -0.1843601018190384, -0.16337832808494568,
  -0.15510335564613342, -0.17102165520191193, -0.16768209636211395,
  -0.15798497200012207, -0.1695847362279892, -0.16566148400306702,
  -0.14919328689575195, -0.17654797434806824, -0.21602864563465118,
  -0.1943325400352478, -0.14476728439331055, -0.13920117914676666,
  -0.16173139214515686, -0.16833806037902832, -0.1736450344324112,
  -0.18951427936553955, -0.1726527065038681, -0.11655576527118683,
  -0.08854476362466812, -0.11764886975288391, -0.16934654116630554,
  -0.20809562504291534, -0.18746691942214966, -0.11447259038686752,
  -0.10207396745681763, -0.16108304262161255, -0.16048818826675415,
  -0.11879628151655197, -0.14218877255916595, -0.1637192964553833,
  -0.14275555312633514, -0.17050574719905853, -0.19260713458061218,
  -0.1250593364238739, -0.08673113584518433, -0.12173469364643097,
  -0.12906591594219208, -0.1373617947101593, -0.17403145134449005,
  -0.1483391672372818, -0.09598351269960403, -0.09875968843698502,
  -0.1069541648030281, -0.12662406265735626, -0.17922592163085938,
  -0.16094602644443512, -0.10119844973087311, -0.13126476109027863,
  -0.16166619956493378, -0.11124201118946075, -0.1070803850889206,
  -0.15563809871673584, -0.14866535365581512, -0.12556812167167664,
  -0.12427341938018799, -0.10516490787267685, -0.09854642301797867,
  -0.1113089993596077, -0.09809094667434692, -0.0866003930568695,
  -0.07975539565086365, -0.045947425067424774, -0.04318474605679512,
  -0.07092983275651932, -0.04988739639520645, -0.03472479060292244,
  -0.07045286893844604, -0.06651245802640915, -0.0428309291601181,
  -0.07555544376373291, -0.09313276410102844, -0.07796001434326172,
  -0.10179700702428818, -0.10441872477531433, -0.045152775943279266,
  -0.018411919474601746, -0.02310519479215145, 0.001667988020926714,
  0.014982416294515133, 0.007330847438424826, 0.01975868083536625,
  0.028707368299365044, 0.031687650829553604, 0.05110658332705498,
  0.04377371445298195, 0.012783164158463478, 0.01981314830482006,
  0.0358702689409256, 0.02138597145676613, 0.023953473195433617,
  0.056524608284235, 0.0768674910068512, 0.07611450552940369,
  0.06290217489004135, 0.05165969580411911, 0.062268614768981934,
  0.08075065165758133, 0.09516045451164246, 0.11680807173252106,
  0.12338267266750336, 0.09982617199420929, 0.088772252202034,
  0.1111571416258812, 0.1405896693468094, 0.157243549823761, 0.1545587182044983,
  0.15156036615371704, 0.17165718972682953, 0.1771608293056488,
  0.14146947860717773, 0.12469946593046188, 0.15071983635425568,
  0.16077284514904022, 0.14504222571849823, 0.14415216445922852,
  0.15156961977481842, 0.1429113894701004, 0.13491487503051758,
  0.15604695677757263, 0.19642245769500732, 0.21397118270397186,
  0.20226524770259857, 0.1998397558927536, 0.20582741498947144,
  0.19094286859035492, 0.18096566200256348, 0.2027769684791565,
  0.21716932952404022, 0.19983208179473877, 0.18569684028625488,
  0.1944141685962677, 0.19941501319408417, 0.17983657121658325,
  0.1624908447265625, 0.18279269337654114, 0.215383380651474,
  0.21933476626873016, 0.21579872071743011, 0.2197912037372589,
  0.19957485795021057, 0.17766651511192322, 0.206770658493042,
  0.24462628364562988, 0.2305818349123001, 0.20391428470611572,
  0.206856831908226, 0.2055428922176361, 0.1869809627532959,
  0.19029349088668823, 0.21543043851852417, 0.22596293687820435,
  0.22616800665855408, 0.23192735016345978, 0.21998663246631622,
  0.18864493072032928, 0.1789446473121643, 0.19307787716388702,
  0.20104952156543732, 0.2052965760231018, 0.20695310831069946,
  0.19263270497322083, 0.18204718828201294, 0.18929874897003174,
  0.19361594319343567, 0.19844716787338257, 0.21375182271003723,
  0.21573412418365479, 0.2032848447561264, 0.19689758121967316,
  0.18875941634178162, 0.1776280701160431, 0.17417079210281372,
  0.17097659409046173, 0.17390389740467072, 0.17844100296497345,
  0.15150591731071472, 0.12777897715568542, 0.1621624231338501,
  0.19565239548683167, 0.1783347874879837, 0.1673387885093689,
  0.17431232333183289, 0.1623881608247757, 0.15422053635120392,
  0.15123800933361053, 0.13188529014587402, 0.14164216816425323,
  0.17883022129535675, 0.16915389895439148, 0.13314048945903778,
  0.12822510302066803, 0.13067485392093658, 0.13392728567123413,
  0.14791421592235565, 0.13565890491008759, 0.11601567268371582,
  0.13138127326965332, 0.13108427822589874, 0.10263021290302277,
  0.10693127661943436, 0.11053240299224854, 0.07601309567689896,
  0.08117105066776276, 0.13095852732658386, 0.13459107279777527,
  0.10671639442443848, 0.10448776930570602, 0.10015347599983215,
  0.08368255198001862, 0.08767090737819672, 0.09894154965877533,
  0.09846717119216919, 0.09748005867004395, 0.09006112068891525,
  0.07083617895841599, 0.0579109713435173, 0.05606354400515556,
  0.058193597942590714, 0.061218950897455215, 0.058038659393787384,
  0.05799451470375061, 0.07782603800296783, 0.0929623544216156,
  0.07529685646295547, 0.052319932729005814, 0.051766376942396164,
  0.056549981236457825, 0.05986035242676735, 0.07132627069950104,
  0.0689832791686058, 0.04181889444589615, 0.031204186379909515,
  0.0522434301674366, 0.061830341815948486, 0.04598035663366318,
  0.03499501198530197, 0.03661295026540756, 0.042087066918611526,
  0.0537409633398056, 0.059842973947525024, 0.04301205277442932,
  0.017940016463398933, 0.015086382627487183, 0.037397321313619614,
  0.051783666014671326, 0.03649609908461571, 0.02434861846268177,
  0.04231954365968704, 0.051210999488830566, 0.0272364504635334,
  0.012553826905786991, 0.023518580943346024, 0.027178457006812096,
  0.017012080177664757, 0.014258711598813534, 0.022285813465714455,
  0.01839003711938858, -0.01143583096563816, -0.021133800968527794,
  0.03054686263203621, 0.07112409174442291, 0.03190021589398384,
  -0.01816904917359352, -0.016814835369586945, 0.0025354798417538404,
  0.018866242840886116, 0.015507316216826439, -0.028740178793668747,
  -0.049031488597393036, -0.009395415894687176, 0.0022080696653574705,
  -0.023078929632902145, -0.007058089133352041, 0.0034543792717158794,
  -0.04462290555238724, -0.0639171376824379, -0.020726244896650314,
  0.012830609455704689, 0.013562262058258057, -0.015863949432969093,
  -0.06872723996639252, -0.08227774500846863, -0.04628415405750275,
  -0.03280435875058174, -0.044778238981962204, -0.03854486718773842,
  -0.029778122901916504, -0.03953799232840538, -0.061242420226335526,
  -0.0827697217464447, -0.07530765980482101, -0.04834870249032974,
  -0.05641776695847511, -0.0840439572930336, -0.07350774109363556,
  -0.04298420995473862, -0.04459761828184128, -0.08885462582111359,
  -0.13336363434791565, -0.1054246723651886, -0.023216208443045616,
  -0.010726955719292164, -0.09238869696855545, -0.16489653289318085,
  -0.169748917222023, -0.11152602732181549, -0.04228803515434265,
  -0.05792051553726196, -0.12925677001476288, -0.1279689520597458,
  -0.08878076076507568, -0.1062740758061409, -0.12161188572645187,
  -0.11467596143484116, -0.1567387878894806, -0.18568696081638336,
  -0.13818614184856415, -0.11657755821943283, -0.14413604140281677,
  -0.13001516461372375, -0.1136833131313324, -0.16002468764781952,
  -0.19025439023971558, -0.15378448367118835, -0.1151195764541626,
  -0.123462975025177, -0.1624545007944107, -0.1740735024213791,
  -0.13712282478809357, -0.1327364593744278, -0.19155526161193848,
  -0.21155105531215668, -0.17433661222457886, -0.16449272632598877,
  -0.16045662760734558, -0.13060322403907776, -0.15657083690166473,
  -0.22456830739974976, -0.21856927871704102, -0.1725662797689438,
  -0.18441987037658691, -0.2128203958272934, -0.2039615958929062,
  -0.19106462597846985, -0.18937242031097412, -0.1906556338071823,
  -0.1996641308069229, -0.20182079076766968, -0.20047152042388916,
  -0.21745435893535614, -0.21772512793540955, -0.18453313410282135,
  -0.18384242057800293, -0.22412678599357605, -0.24277418851852417,
  -0.24869179725646973, -0.26162707805633545, -0.23303717374801636,
  -0.1781059056520462, -0.17564339935779572, -0.21005380153656006,
  -0.2200368493795395, -0.2167326956987381, -0.22415384650230408,
  -0.2305184006690979, -0.2270190715789795, -0.21365207433700562,
  -0.20619386434555054, -0.2275758981704712, -0.25099971890449524,
  -0.23636101186275482, -0.21345342695713043, -0.22203204035758972,
  -0.23570698499679565, -0.22720091044902802, -0.22260305285453796,
  -0.2444555014371872, -0.26210394501686096, -0.2378370314836502,
  -0.20263700187206268, -0.21488632261753082, -0.24685633182525635,
  -0.2320583611726761, -0.19892309606075287, -0.21199916303157806,
  -0.2478753924369812, -0.25154218077659607, -0.2286895215511322,
  -0.21816976368427277, -0.23235072195529938, -0.2375718057155609,
  -0.20540428161621094, -0.17999477684497833, -0.20892676711082458,
  -0.24662305414676666, -0.24022556841373444, -0.2166082262992859,
  -0.2040044218301773, -0.19733533263206482, -0.2094966173171997,
  -0.23607048392295837, -0.23185722529888153, -0.19752106070518494,
  -0.1870250701904297, -0.2064165472984314, -0.20837518572807312,
  -0.1778235137462616, -0.14075930416584015, -0.10998372733592987,
  -0.08870642632246017, -0.09785444289445877, -0.13389891386032104,
  -0.14421819150447845, -0.10940361022949219, -0.08242223411798477,
  -0.0994538813829422, -0.13658404350280762, -0.16720424592494965,
  -0.18218031525611877, -0.17251083254814148, -0.1398807317018509,
  -0.09707624465227127, -0.058485761284828186, -0.04274934157729149,
  -0.04787478595972061, -0.03616047650575638, 0.011683665215969086,
  0.06044309213757515, 0.07145995646715164, 0.053316760808229446,
  0.03785780072212219, 0.029822612181305885, 0.01369304209947586,
  -0.0013773877872154117, 0.0038106776773929596, 0.013496735133230686,
  0.0073485709726810455, 0.0024281644728034735, 0.017149800434708595,
  0.04330035671591759, 0.07123343646526337, 0.09625905752182007,
  0.1142202690243721, 0.1283775418996811, 0.13668833673000336,
  0.1339767724275589, 0.13598737120628357, 0.15503770112991333,
  0.17082104086875916, 0.17068791389465332, 0.17560797929763794,
  0.1980559378862381, 0.2192215472459793, 0.21777623891830444,
  0.19503337144851685, 0.1721249371767044, 0.16790032386779785,
  0.18037402629852295, 0.19352665543556213, 0.19597573578357697,
  0.1887771040201187, 0.17975156009197235, 0.17433017492294312,
  0.17780593037605286, 0.19974951446056366, 0.2412036508321762,
  0.2846079468727112, 0.3066873252391815, 0.2990160286426544,
  0.27907341718673706, 0.2703310251235962, 0.2673080861568451,
  0.255258172750473, 0.2504160702228546, 0.2649092376232147,
  0.27021530270576477, 0.24465312063694, 0.20581823587417603,
  0.18259863555431366, 0.19646453857421875, 0.24058093130588531,
  0.2710607051849365, 0.2673114538192749, 0.25803184509277344,
  0.255919873714447, 0.24772143363952637, 0.24454282224178314,
  0.26121386885643005, 0.28046914935112, 0.28035008907318115,
  0.25639134645462036, 0.22248978912830353, 0.2095452845096588,
  0.2304774671792984, 0.2591213583946228, 0.26633521914482117,
  0.24534150958061218, 0.2115892618894577, 0.20082269608974457,
  0.22860130667686462, 0.25782984495162964, 0.2520102858543396,
  0.22422820329666138, 0.20333372056484222, 0.1986582726240158,
  0.20477013289928436, 0.2098538875579834, 0.2071463018655777,
  0.20290958881378174, 0.20574022829532623, 0.2186613231897354,
  0.2359507530927658, 0.23977407813072205, 0.21981003880500793,
  0.19334328174591064, 0.1839180439710617, 0.18989618122577667,
  0.19093455374240875, 0.1800599992275238, 0.17232602834701538,
  0.17306016385555267, 0.16399547457695007, 0.14436598122119904,
  0.14476589858531952, 0.17171616852283478, 0.18869702517986298,
  0.17460010945796967, 0.1549854576587677, 0.15942439436912537,
  0.1765131950378418, 0.17042873799800873, 0.1372203379869461,
  0.11412135511636734, 0.12175677716732025, 0.13791652023792267,
  0.14051517844200134, 0.13360457122325897, 0.12726832926273346,
  0.1252649575471878, 0.12704288959503174, 0.12569117546081543,
  0.1161913275718689, 0.10787332057952881, 0.1104755848646164,
  0.11031617224216461, 0.087701216340065, 0.056364934891462326,
  0.04677325859665871, 0.05906015634536743, 0.07886429131031036,
  0.10630228370428085, 0.12481307983398438, 0.10440734773874283,
  0.062889464199543, 0.04861830174922943, 0.067587710916996, 0.0907130315899849,
  0.0959857925772667, 0.07205954194068909, 0.029985997825860977,
  0.005115761421620846, 0.013036428019404411, 0.035204995423555374,
  0.05288376659154892, 0.06219416484236717, 0.0665137842297554,
  0.06662092357873917, 0.057443078607320786, 0.04539990425109863,
  0.04697706550359726, 0.0584266297519207, 0.06346505135297775,
  0.054226748645305634, 0.024422496557235718, -0.0058496561832726,
  0.00741841085255146, 0.044245216995477676, 0.040464162826538086,
  0.018474213778972626, 0.040677543729543686, 0.07550808787345886,
  0.06742601841688156, 0.04270755127072334, 0.04484899342060089,
  0.06792869418859482, 0.07770819962024689, 0.05084776133298874,
  0.013371789827942848, 0.005931389983743429, 0.014883208088576794,
  0.02168796956539154, 0.03602983057498932, 0.026124168187379837,
  -0.019343333318829536, -0.02037961408495903, 0.03206763416528702,
  0.04726521298289299, 0.0367630273103714, 0.052373647689819336,
  0.03311231732368469, -0.02320985496044159, -0.015837810933589935,
  0.026406362652778625, 0.02285189926624298, 0.033575739711523056,
  0.07551208883523941, 0.040621381253004074, -0.05766845867037773,
  -0.09625864773988724, -0.06369734555482864, -0.02079298347234726,
  0.00494247255846858, -0.00648917630314827, -0.04327438771724701,
  -0.059379059821367264, -0.05789753794670105, -0.0646081417798996,
  -0.06271126866340637, -0.038212381303310394, -0.0033034055959433317,
  0.02237144112586975, 0.0032008716370910406, -0.05741331726312637,
  -0.0871204137802124, -0.06695348024368286, -0.0629236102104187,
  -0.08547945320606232, -0.08877670764923096, -0.08792632073163986,
  -0.11520833522081375, -0.13831913471221924, -0.12732872366905212,
  -0.10259856283664703, -0.08351565897464752, -0.08003531396389008,
  -0.10619933903217316, -0.14051735401153564, -0.13677294552326202,
  -0.10128163546323776, -0.0865408256649971, -0.11126476526260376,
  -0.14969325065612793, -0.17659161984920502, -0.18086372315883636,
  -0.16244053840637207, -0.13868510723114014, -0.13553282618522644,
  -0.15647844970226288, -0.17219260334968567, -0.1661044955253601,
  -0.16270463168621063, -0.16792108118534088, -0.1549413651227951,
  -0.15233130753040314, -0.2053627222776413, -0.24543118476867676,
  -0.19505451619625092, -0.13334886729717255, -0.1539776772260666,
  -0.20574375987052917, -0.21924647688865662, -0.21007484197616577,
  -0.20535749197006226, -0.20906761288642883, -0.2113708257675171,
  -0.1924213171005249, -0.1729261428117752, -0.1974346935749054,
  -0.24216455221176147, -0.24789853394031525, -0.21526896953582764,
  -0.18774348497390747, -0.1947111189365387, -0.22724826633930206,
  -0.24980422854423523, -0.2553138732910156, -0.2595294713973999,
  -0.2490595281124115, -0.22457320988178253, -0.21975085139274597,
  -0.2308007776737213, -0.24633769690990448, -0.27734091877937317,
  -0.2777934670448303, -0.2198994904756546, -0.19765232503414154,
  -0.2459878921508789, -0.2653973400592804, -0.23981307446956635,
  -0.236404150724411, -0.24403494596481323, -0.24735024571418762,
  -0.26785993576049805, -0.2727587819099426, -0.25692981481552124,
  -0.2761556804180145, -0.29746589064598083, -0.26593461632728577,
  -0.2451973408460617, -0.2738610804080963, -0.29069653153419495,
  -0.2836436331272125, -0.2862018942832947, -0.28810298442840576,
  -0.28407537937164307, -0.2842208743095398, -0.27394741773605347,
  -0.2576131820678711, -0.25788065791130066, -0.26301348209381104,
  -0.26075977087020874, -0.26634567975997925, -0.2825697660446167,
  -0.2952079176902771, -0.2932834029197693, -0.2774880528450012,
  -0.27016952633857727, -0.2836878299713135, -0.29093071818351746,
  -0.27649006247520447, -0.26634177565574646, -0.2741139531135559,
  -0.2791450321674347, -0.2710394561290741, -0.2723197340965271,
  -0.29559987783432007, -0.30339834094047546, -0.2657879590988159,
  -0.22638842463493347, -0.23067398369312286, -0.25397995114326477,
  -0.26623669266700745, -0.26828598976135254, -0.2513095438480377,
  -0.21762847900390625, -0.19454437494277954, -0.18230964243412018,
  -0.16592557728290558, -0.1545945256948471, -0.15201407670974731,
  -0.15120209753513336, -0.15758810937404633, -0.16119949519634247,
  -0.14098815619945526, -0.11313683539628983, -0.10962022095918655,
  -0.13456284999847412, -0.17524011433124542, -0.21041664481163025,
  -0.2121744006872177, -0.1721990555524826, -0.11124125868082047,
  -0.060703035444021225, -0.03665908798575401, -0.023164743557572365,
  0.0005125095485709608, 0.030826225876808167, 0.0642552599310875,
  0.10174249857664108, 0.11915001273155212, 0.09755939245223999,
  0.060376498848199844, 0.03328592702746391, 0.016653407365083694,
  0.012620368972420692, 0.02517567202448845, 0.04188026115298271,
  0.04961268976330757, 0.053954675793647766, 0.07217630743980408,
  0.1126302108168602, 0.16332316398620605, 0.20355765521526337,
  0.22256165742874146, 0.22504922747612, 0.2248348444700241, 0.2295825481414795,
  0.2366863638162613, 0.24721133708953857, 0.2627105712890625,
  0.2771355211734772, 0.2922114133834839, 0.30773794651031494,
  0.30244743824005127, 0.274751216173172, 0.2605923116207123,
  0.2708539366722107, 0.27984461188316345, 0.27975237369537354,
  0.27668651938438416, 0.26552814245224, 0.25311923027038574,
  0.26080039143562317, 0.29334813356399536, 0.33485230803489685,
  0.3663788139820099, 0.38280636072158813, 0.393852561712265,
  0.40144023299217224, 0.39839038252830505, 0.38858047127723694,
  0.37570568919181824, 0.3562999367713928, 0.34135663509368896,
  0.341609925031662, 0.34264418482780457, 0.33099979162216187,
  0.31245890259742737, 0.2965807020664215, 0.2947041988372803,
  0.314154714345932, 0.342806875705719, 0.36234384775161743, 0.366171658039093,
  0.3577299416065216, 0.3480512201786041, 0.3446367383003235,
  0.34362080693244934, 0.3451346755027771, 0.3502087891101837,
  0.3456772565841675, 0.32658326625823975, 0.3089296221733093,
  0.301934152841568, 0.30193856358528137, 0.30598995089530945,
  0.30858924984931946, 0.3060339391231537, 0.3014262318611145,
  0.29303762316703796, 0.281439870595932, 0.2789961099624634,
  0.28535258769989014, 0.2807634770870209, 0.2599599063396454,
  0.24212513864040375, 0.23939546942710876, 0.23859049379825592,
  0.22701525688171387, 0.21872451901435852, 0.22906461358070374,
  0.2448323667049408, 0.24937015771865845, 0.24388325214385986,
  0.2303970754146576, 0.21309794485569, 0.20335069298744202, 0.1997046321630478,
  0.19459474086761475, 0.19157108664512634, 0.18685278296470642,
  0.1701277792453766, 0.15137289464473724, 0.14802613854408264,
  0.15951965749263763, 0.17162583768367767, 0.1698264628648758,
  0.15403027832508087, 0.13588064908981323, 0.11759643256664276,
  0.10234420001506805, 0.10128362476825714, 0.10087675601243973,
  0.08232344686985016, 0.06781581789255142, 0.07640745490789413,
  0.08908524364233017, 0.09892429411411285, 0.11213432252407074,
  0.11572433263063431, 0.11056329309940338, 0.11032126098871231,
  0.10023348778486252, 0.07280275970697403, 0.05025491863489151,
  0.03435100242495537, 0.015152250416576862, 0.006113860290497541,
  0.008239357732236385, 0.0049847085028886795, 0.0043129161931574345,
  0.01641911081969738, 0.026042986661195755, 0.02847820706665516,
  0.03824854642152786, 0.05267101526260376, 0.0524006150662899,
  0.036210235208272934, 0.026068322360515594, 0.030055904760956764,
  0.028778107836842537, 0.01930832490324974, 0.022926902398467064,
  0.03252703323960304, 0.025499669834971428, 0.016421150416135788,
  0.02196553163230419, 0.025005709379911423, 0.02054578810930252,
  0.02015417441725731, 0.012028258293867111, -0.006278504151850939,
  -0.0023932026233524084, 0.02966426871716976, 0.053177665919065475,
  0.05223142355680466, 0.04279334098100662, 0.03564947098493576,
  0.040029510855674744, 0.066627137362957, 0.09955231845378876,
  0.11009261757135391, 0.09580164402723312, 0.0682390108704567,
  0.03646332770586014, 0.02368404157459736, 0.04582594707608223,
  0.0772562026977539, 0.08818808197975159, 0.07998407632112503,
  0.05938826873898506, 0.035534169524908066, 0.03357500955462456,
  0.060768913477659225, 0.0915759801864624, 0.10351946949958801,
  0.092677041888237, 0.07122813165187836, 0.062365155667066574,
  0.0702538713812828, 0.07677556574344635, 0.07726138085126877,
  0.07893569022417068, 0.07888520509004593, 0.07084023207426071,
  0.05288538336753845, 0.03682313486933708, 0.0517570860683918,
  0.09377163648605347, 0.10129707306623459, 0.04185280576348305,
  -0.02602159045636654, -0.03180106356739998, 0.009322172030806541,
  0.026937762275338173, 0.006086290348321199, -0.0009567275992594659,
  0.01918492279946804, 0.021593637764453888, -0.0031910487450659275,
  -0.022240400314331055, -0.02256527543067932, -0.007418313529342413,
  0.010012435726821423, 0.0029242881573736668, -0.02246251329779625,
  -0.03841360658407211, -0.05558554828166962, -0.08015001565217972,
  -0.0828375369310379, -0.07022333145141602, -0.07530079782009125,
  -0.09211909770965576, -0.10984224826097488, -0.13150601089000702,
  -0.13942554593086243, -0.13020361959934235, -0.12936735153198242,
  -0.1347556710243225, -0.1331280916929245, -0.1383541226387024,
  -0.151847705245018, -0.1605042666196823, -0.17361386120319366,
  -0.19273993372917175, -0.19365327060222626, -0.17262716591358185,
  -0.15879260003566742, -0.173461452126503, -0.20217157900333405,
  -0.2188691943883896, -0.22944781184196472, -0.250006765127182,
  -0.26329898834228516, -0.25299137830734253, -0.23066775500774384,
  -0.2087477743625641, -0.21185363829135895, -0.2618120610713959,
  -0.31196993589401245, -0.30639320611953735, -0.2745114862918854,
  -0.260381281375885, -0.26081568002700806, -0.2772199809551239,
  -0.30300045013427734, -0.3048158586025238, -0.2929806411266327,
  -0.30202892422676086, -0.31136271357536316, -0.2990081310272217,
  -0.29701662063598633, -0.3214426338672638, -0.3402532637119293,
  -0.33619487285614014, -0.3323880434036255, -0.34436309337615967,
  -0.35050836205482483, -0.3394918441772461, -0.3373247981071472,
  -0.34486138820648193, -0.33425018191337585, -0.3257603049278259,
  -0.35117506980895996, -0.3785245418548584, -0.37529224157333374,
  -0.35917699337005615, -0.34733131527900696, -0.3452627956867218,
  -0.35714009404182434, -0.3680947721004486, -0.3678733706474304,
  -0.36148691177368164, -0.3454457223415375, -0.3350313901901245,
  -0.35436901450157166, -0.3771734833717346, -0.3788403570652008,
  -0.3845670521259308, -0.3933921754360199, -0.3821650445461273,
  -0.3743131160736084, -0.383949875831604, -0.38348087668418884,
  -0.37146881222724915, -0.3631362020969391, -0.35925987362861633,
  -0.37003594636917114, -0.3845098614692688, -0.3720754384994507,
  -0.3581595718860626, -0.3749662935733795, -0.38689789175987244,
  -0.3790620267391205, -0.3871450126171112, -0.39844197034835815,
  -0.37634003162384033, -0.34431833028793335, -0.33906856179237366,
  -0.3532673418521881, -0.36047640442848206, -0.3462384045124054,
  -0.32140061259269714, -0.30749040842056274, -0.3054424226284027,
  -0.30064648389816284, -0.27614864706993103, -0.22295153141021729,
  -0.1735340654850006, -0.17224830389022827, -0.19794785976409912,
  -0.2011885941028595, -0.18424805998802185, -0.16913117468357086,
  -0.16100995242595673, -0.17787322402000427, -0.2344910055398941,
  -0.29762160778045654, -0.30893072485923767, -0.2462548315525055,
  -0.14953508973121643, -0.07788081467151642, -0.04614481329917908,
  -0.031026046723127365, -0.010741095058619976, 0.03145582601428032,
  0.09491074085235596, 0.1474047303199768, 0.1618492156267166,
  0.13515324890613556, 0.07201467454433441, 0.0011048595188185573,
  -0.03137930855154991, -0.01631256937980652, 0.01957789808511734,
  0.05312275141477585, 0.06909187883138657, 0.07158327102661133,
  0.08899383246898651, 0.13174957036972046, 0.1836378574371338,
  0.2367294728755951, 0.28192389011383057, 0.29700011014938354,
  0.28544554114341736, 0.2824528217315674, 0.30310678482055664,
  0.32713285088539124, 0.34141889214515686, 0.35630959272384644,
  0.3715936243534088, 0.3712327182292938, 0.3570011556148529,
  0.33989283442497253, 0.31690266728401184, 0.299312561750412,
  0.3101694583892822, 0.3339307904243469, 0.338411420583725,
  0.32704484462738037, 0.3186827600002289, 0.32808351516723633,
  0.3702085614204407, 0.43026214838027954, 0.4713480472564697,
  0.48948073387145996, 0.5010619163513184, 0.5044359564781189,
  0.49551132321357727, 0.47717660665512085, 0.4528616666793823,
  0.43386828899383545, 0.42426595091819763, 0.4131605625152588,
  0.3999527096748352, 0.38655388355255127, 0.3658738136291504,
  0.35016801953315735, 0.35590770840644836, 0.3706159293651581,
  0.38925865292549133, 0.4238586723804474, 0.452907532453537,
  0.4430316686630249, 0.4082243740558624, 0.38654854893684387,
  0.39131560921669006, 0.40754884481430054, 0.41072219610214233,
  0.39058956503868103, 0.3577629029750824, 0.32653552293777466,
  0.30727145075798035, 0.3038986325263977, 0.311078280210495,
  0.32536637783050537, 0.339904248714447, 0.33632558584213257,
  0.31623879075050354, 0.30438241362571716, 0.3037315607070923,
  0.29971352219581604, 0.28881680965423584, 0.26822665333747864,
  0.24164092540740967, 0.22470207512378693, 0.2119295299053192,
  0.19173799455165863, 0.1833418905735016, 0.19723914563655853,
  0.21373483538627625, 0.22419863939285278, 0.2246057391166687,
  0.20252424478530884, 0.17349743843078613, 0.16363748908042908,
  0.16532859206199646, 0.16476179659366608, 0.16156569123268127,
  0.1489352583885193, 0.12808555364608765, 0.11351870745420456,
  0.10713383555412292, 0.10720809549093246, 0.11445573717355728,
  0.11235874891281128, 0.08813448250293732, 0.05463807284832001,
  0.02665630169212818, 0.012323773466050625, 0.012972310185432434,
  0.010754605755209923, -0.002507955301553011, -0.003571429755538702,
  0.01819191686809063, 0.044597215950489044, 0.0637330636382103,
  0.07241353392601013, 0.0673111230134964, 0.05555804446339607,
  0.043978478759527206, 0.030209099873900414, 0.015362303704023361,
  -0.003965279553085566, -0.03701314330101013, -0.07241155952215195,
  -0.08778510987758636, -0.08202727884054184, -0.06673283129930496,
  -0.049831077456474304, -0.03908992558717728, -0.03600813448429108,
  -0.0332053042948246, -0.026867179200053215, -0.016136109828948975,
  -0.0004742806777358055, 0.010751915164291859, 0.006805488374084234,
  -0.005468141753226519, -0.0119193559512496, -0.010759574361145496,
  -0.0058584497310221195, 0.003176921745762229, 0.010925179347395897,
  0.005740299355238676, -0.011288260109722614, -0.027498681098222733,
  -0.03727385029196739, -0.04021155834197998, -0.03542656823992729,
  -0.02655419521033764, -0.012753219343721867, 0.012095045298337936,
  0.0388377346098423, 0.05533888563513756, 0.07194069772958755,
  0.0973265990614891, 0.12035961449146271, 0.13607412576675415,
  0.1442098170518875, 0.1337382048368454, 0.10925864428281784,
  0.09357865154743195, 0.0879305899143219, 0.07763677090406418,
  0.06593000143766403, 0.0658455416560173, 0.07911872863769531,
  0.09779559075832367, 0.1143624559044838, 0.1272537112236023,
  0.13430501520633698, 0.13293588161468506, 0.13539166748523712,
  0.1537581980228424, 0.17277435958385468, 0.17654938995838165,
  0.17296718060970306, 0.1644318550825119, 0.14855049550533295,
  0.1445615291595459, 0.1594700664281845, 0.1648223102092743,
  0.1465248316526413, 0.12208004295825958, 0.10286259651184082,
  0.08952698856592178, 0.08471120148897171, 0.08458112180233002,
  0.08451547473669052, 0.08791614323854446, 0.09136395901441574,
  0.08782447129487991, 0.08473194390535355, 0.09002228081226349,
  0.09698925167322159, 0.09848497062921524, 0.09081868082284927,
  0.06813868135213852, 0.03659607842564583, 0.011331724934279919,
  -0.008734461851418018, -0.02671930007636547, -0.027067800983786583,
  -0.011501067318022251, -0.011847891844809055, -0.03485759347677231,
  -0.05527786538004875, -0.06735603511333466, -0.07792183756828308,
  -0.0772259458899498, -0.06838946789503098, -0.07716285437345505,
  -0.1096576601266861, -0.14683212339878082, -0.17180925607681274,
  -0.1756560057401657, -0.16147196292877197, -0.14704276621341705,
  -0.14817194640636444, -0.1662033051252365, -0.1880139708518982,
  -0.19864632189273834, -0.20404216647148132, -0.21721187233924866,
  -0.22854512929916382, -0.23215393722057343, -0.24346092343330383,
  -0.2571115791797638, -0.25414109230041504, -0.24948501586914062,
  -0.26202914118766785, -0.27973073720932007, -0.292423814535141,
  -0.2981613874435425, -0.29224181175231934, -0.29420003294944763,
  -0.32539886236190796, -0.3599912226200104, -0.3692544102668762,
  -0.365317165851593, -0.35545045137405396, -0.3314085602760315,
  -0.31021982431411743, -0.3141660690307617, -0.3330760598182678,
  -0.34796810150146484, -0.3578360080718994, -0.36598706245422363,
  -0.3674637973308563, -0.36377766728401184, -0.3750213086605072,
  -0.4116528034210205, -0.44595205783843994, -0.4469090700149536,
  -0.422380656003952, -0.39837291836738586, -0.39091214537620544,
  -0.4065455496311188, -0.4334182143211365, -0.4440281391143799,
  -0.4293420910835266, -0.40993061661720276, -0.40990859270095825,
  -0.43018287420272827, -0.4443473517894745, -0.43332886695861816,
  -0.4183312654495239, -0.4268854856491089, -0.447388619184494,
  -0.4537787139415741, -0.44624748826026917, -0.4416230320930481,
  -0.4482171833515167, -0.46397271752357483, -0.4843709468841553,
  -0.49822843074798584, -0.48618000745773315, -0.45111775398254395,
  -0.43163707852363586, -0.4435523748397827, -0.4513113796710968,
  -0.4390851855278015, -0.4363669455051422, -0.44684475660324097,
  -0.44545960426330566, -0.44356581568717957, -0.46144503355026245,
  -0.47029247879981995, -0.4457148313522339, -0.4232926070690155,
  -0.43383121490478516, -0.44812753796577454, -0.43543657660484314,
  -0.41017383337020874, -0.3892078697681427, -0.3672483563423157,
  -0.3432813882827759, -0.3147298991680145, -0.26744556427001953,
  -0.21701163053512573, -0.20684482157230377, -0.23256734013557434,
  -0.23860156536102295, -0.20477008819580078, -0.16797074675559998,
  -0.16089990735054016, -0.18926239013671875, -0.25204822421073914,
  -0.32629913091659546, -0.3539985716342926, -0.3030571937561035,
  -0.2156735062599182, -0.14446213841438293, -0.0900777205824852,
  -0.04166562110185623, -0.001418374595232308, 0.04824863374233246,
  0.11848288029432297, 0.17676758766174316, 0.19706758856773376,
  0.18415333330631256, 0.13531848788261414, 0.06274814158678055,
  0.01576486974954605, 0.013356332667171955, 0.025883903726935387,
  0.03996270149946213, 0.06209871917963028, 0.08581887185573578,
  0.11201362311840057, 0.15519970655441284, 0.2153167873620987,
  0.27781492471694946, 0.32451683282852173, 0.3414398431777954,
  0.33961552381515503, 0.3456125855445862, 0.3663330376148224,
  0.39343175292015076, 0.42165014147758484, 0.44263139367103577,
  0.45055726170539856, 0.4512495994567871, 0.4446791112422943,
  0.4246828556060791, 0.40078824758529663, 0.38744211196899414,
  0.3813127279281616, 0.3739122152328491, 0.36902549862861633,
  0.3725660443305969, 0.38340458273887634, 0.40447455644607544,
  0.44417905807495117, 0.49762386083602905, 0.5458438992500305,
  0.580678403377533, 0.6070277690887451, 0.618816077709198, 0.6014952063560486,
  0.5589264631271362, 0.5143881440162659, 0.4865557849407196,
  0.4767805337905884, 0.4740098714828491, 0.4642522633075714,
  0.44039446115493774, 0.41079410910606384, 0.39603692293167114,
  0.4092094600200653, 0.4424179196357727, 0.476317822933197,
  0.49487221240997314, 0.49277612566947937, 0.4777023494243622,
  0.4604649245738983, 0.4461233615875244, 0.4405301511287689,
  0.44401538372039795, 0.4377531409263611, 0.40856871008872986,
  0.37420758605003357, 0.3520011305809021, 0.3340179920196533,
  0.317380428314209, 0.31613609194755554, 0.32950371503829956,
  0.33796778321266174, 0.33239296078681946, 0.31861239671707153,
  0.3020585775375366, 0.28760826587677, 0.2802911102771759, 0.2729456126689911,
  0.25197842717170715, 0.22080224752426147, 0.1932099461555481,
  0.17049942910671234, 0.15268857777118683, 0.15045921504497528,
  0.1633232831954956, 0.17437167465686798, 0.17266806960105896,
  0.15629030764102936, 0.12899258732795715, 0.10613548010587692,
  0.10053832828998566, 0.10703220218420029, 0.11243076622486115,
  0.10366231203079224, 0.07627476006746292, 0.04921114444732666,
  0.045092083513736725, 0.05960754677653313, 0.0741315558552742,
  0.07426836341619492, 0.05094687268137932, 0.011748473159968853,
  -0.023352496325969696, -0.047268714755773544, -0.06413953006267548,
  -0.07846718281507492, -0.0951339602470398, -0.10369215160608292,
  -0.08489066362380981, -0.048219818621873856, -0.017320184037089348,
  0.005635417066514492, 0.018831545487046242, 0.015590525232255459,
  0.01136067695915699, 0.014392029494047165, 0.001623123767785728,
  -0.034763872623443604, -0.07670533657073975, -0.11710888892412186,
  -0.15065106749534607, -0.16274067759513855, -0.1538078337907791,
  -0.13375669717788696, -0.10946277529001236, -0.09655042737722397,
  -0.10012185573577881, -0.09889426082372665, -0.08400024473667145,
  -0.06979324668645859, -0.0587877482175827, -0.04512898623943329,
  -0.03338966891169548, -0.02527523785829544, -0.01846989430487156,
  -0.01618357002735138, -0.014284081757068634, -0.002411162480711937,
  0.014599751681089401, 0.020639585331082344, 0.009344554506242275,
  -0.011587301269173622, -0.03215867653489113, -0.050067998468875885,
  -0.061573173850774765, -0.05356951430439949, -0.025420501828193665,
  0.004243931267410517, 0.029125532135367393, 0.05970540642738342,
  0.09872955083847046, 0.1434229463338852, 0.18574273586273193,
  0.20292839407920837, 0.1907735913991928, 0.1818762719631195,
  0.19141100347042084, 0.19637414813041687, 0.18498608469963074,
  0.16634494066238403, 0.14354528486728668, 0.12403249740600586,
  0.12514108419418335, 0.1508527398109436, 0.1899644434452057,
  0.2239362895488739, 0.23021431267261505, 0.2104141265153885,
  0.19907493889331818, 0.21753725409507751, 0.25146248936653137,
  0.2800155282020569, 0.2920016944408417, 0.28513067960739136,
  0.27110013365745544, 0.2653404474258423, 0.27036845684051514,
  0.27639660239219666, 0.26792314648628235, 0.23903945088386536,
  0.20660169422626495, 0.18605129420757294, 0.1709607094526291,
  0.15875136852264404, 0.15869998931884766, 0.16558554768562317,
  0.1684092879295349, 0.1716930866241455, 0.17606133222579956,
  0.17136862874031067, 0.16170558333396912, 0.15736503899097443,
  0.15024812519550323, 0.12864689528942108, 0.09619347006082535,
  0.06165266036987305, 0.03139611333608627, 0.013629894703626633,
  0.012852865271270275, 0.021935511380434036, 0.026498543098568916,
  0.01606886088848114, -0.007528793066740036, -0.03149140253663063,
  -0.04586734622716904, -0.05525800585746765, -0.07058553397655487,
  -0.09436212480068207, -0.12322652339935303, -0.15335234999656677,
  -0.17563168704509735, -0.1833004206418991, -0.18644732236862183,
  -0.19788900017738342, -0.21343128383159637, -0.22309333086013794,
  -0.22549498081207275, -0.22541525959968567, -0.2297438085079193,
  -0.2400701642036438, -0.2525046765804291, -0.2696904242038727,
  -0.28957390785217285, -0.29338353872299194, -0.28228560090065,
  -0.2872454822063446, -0.3122008144855499, -0.3342233896255493,
  -0.36116304993629456, -0.4062036871910095, -0.4377026855945587,
  -0.4309988021850586, -0.4159863591194153, -0.4230370819568634,
  -0.4394803047180176, -0.44200626015663147, -0.4269464910030365,
  -0.40394043922424316, -0.38517558574676514, -0.38043013215065,
  -0.3902164697647095, -0.4054976999759674, -0.42359766364097595,
  -0.4522898495197296, -0.4817315936088562, -0.4868259131908417,
  -0.4805642366409302, -0.5031739473342896, -0.5387904644012451,
  -0.5324733853340149, -0.495134174823761, -0.4850770831108093,
  -0.5006645917892456, -0.501356303691864, -0.4983516037464142,
  -0.5183382034301758, -0.5326722264289856, -0.5163306593894958,
  -0.5036278367042542, -0.5142551064491272, -0.5168237686157227,
  -0.5077810287475586, -0.5193416476249695, -0.5416338443756104,
  -0.5408831238746643, -0.5275986790657043, -0.5263032913208008,
  -0.5299015045166016, -0.5298453569412231, -0.5349517464637756,
  -0.5461806058883667, -0.5505897402763367, -0.5372143983840942,
  -0.5123962759971619, -0.5004286170005798, -0.5085402727127075,
  -0.5074132084846497, -0.48036107420921326, -0.45732423663139343,
  -0.4659298062324524, -0.48981112241744995, -0.4998262822628021,
  -0.489515095949173, -0.4662184417247772, -0.4369122385978699,
  -0.4097807705402374, -0.38174375891685486, -0.33125975728034973,
  -0.2636966109275818, -0.2311040461063385, -0.24673663079738617,
  -0.24754005670547485, -0.20897477865219116, -0.18848326802253723,
  -0.2033466100692749, -0.2143435925245285, -0.2410493642091751,
  -0.3192969560623169, -0.3807962238788605, -0.3461107313632965,
  -0.2592734694480896, -0.1939937323331833, -0.14039866626262665,
  -0.07579787075519562, -0.020961707457900047, 0.0223074983805418,
  0.08091170340776443, 0.1516747623682022, 0.20613904297351837,
  0.22906580567359924, 0.21492622792720795, 0.16788387298583984,
  0.11450803279876709, 0.08087997138500214, 0.06911934167146683,
  0.07287898659706116, 0.09005682170391083, 0.115375816822052,
  0.14123639464378357, 0.16682030260562897, 0.20201797783374786,
  0.25900596380233765, 0.3264564871788025, 0.37029802799224854,
  0.38448724150657654, 0.4054339826107025, 0.4497024714946747,
  0.4884488582611084, 0.5043445825576782, 0.5164691805839539,
  0.5351295471191406, 0.544328510761261, 0.5350480079650879, 0.5187562704086304,
  0.5072674751281738, 0.4985499382019043, 0.4810235798358917,
  0.4527851641178131, 0.4345703721046448, 0.4422808885574341,
  0.4577144682407379, 0.4623112976551056, 0.4746204912662506,
  0.5140408873558044, 0.5653544068336487, 0.609986424446106, 0.646892249584198,
  0.6703956723213196, 0.6715905070304871, 0.6557943820953369,
  0.6301470398902893, 0.5967069864273071, 0.5689177513122559, 0.559101939201355,
  0.5490922927856445, 0.5129144787788391, 0.4628680646419525,
  0.43276605010032654, 0.42820701003074646, 0.43695512413978577,
  0.4623170793056488, 0.49770793318748474, 0.5100803375244141,
  0.4907993972301483, 0.4673014283180237, 0.4505055546760559,
  0.43549367785453796, 0.4299331605434418, 0.4265846908092499,
  0.40427106618881226, 0.37404903769493103, 0.35465022921562195,
  0.3337830603122711, 0.3107289969921112, 0.3060784935951233,
  0.3101331889629364, 0.3033692538738251, 0.296933114528656,
  0.29136791825294495, 0.2663564383983612, 0.2323557734489441,
  0.20972447097301483, 0.18385225534439087, 0.1483316272497177,
  0.12704838812351227, 0.11829791963100433, 0.09719755500555038,
  0.07262257486581802, 0.06792142242193222, 0.07306455820798874,
  0.0699201375246048, 0.06428905576467514, 0.06176573038101196,
  0.05177874490618706, 0.03030550479888916, 0.00889969989657402,
  -0.0025632039178162813, -0.00693648774176836, -0.015190678648650646,
  -0.034308429807424545, -0.05637671798467636, -0.06456637382507324,
  -0.056038420647382736, -0.04916958510875702, -0.05894599109888077,
  -0.08001523464918137, -0.104197196662426, -0.13275755941867828,
  -0.16166934370994568, -0.18175823986530304, -0.19535990059375763,
  -0.20617198944091797, -0.20660075545310974, -0.1954093724489212,
  -0.17983925342559814, -0.15754954516887665, -0.1293533444404602,
  -0.1099017858505249, -0.10156294703483582, -0.0931776612997055,
  -0.08767501264810562, -0.0956513062119484, -0.1174236312508583,
  -0.14867110550403595, -0.18117624521255493, -0.20418629050254822,
  -0.2156946361064911, -0.21469581127166748, -0.20024201273918152,
  -0.19004423916339874, -0.19787289202213287, -0.20393219590187073,
  -0.19068683683872223, -0.16831359267234802, -0.14384828507900238,
  -0.11886660754680634, -0.10563740879297256, -0.10284549742937088,
  -0.09133244305849075, -0.06545877456665039, -0.030852172523736954,
  0.006168897729367018, 0.02756018564105034, 0.021415140479803085,
  0.005912880413234234, 0.00481035653501749, 0.017461314797401428,
  0.022348174825310707, 0.0016860051546245813, -0.031328245997428894,
  -0.04576636105775833, -0.03440232574939728, -0.004510709084570408,
  0.04656153544783592, 0.10268519073724747, 0.12910103797912598,
  0.13092613220214844, 0.14743149280548096, 0.1906379908323288,
  0.24278093874454498, 0.28477251529693604, 0.30073949694633484,
  0.290295273065567, 0.27366742491722107, 0.2659389078617096,
  0.2693575918674469, 0.2851671874523163, 0.3050130307674408,
  0.3102578818798065, 0.29587236046791077, 0.274026483297348,
  0.2568485736846924, 0.25060227513313293, 0.2565666437149048,
  0.27304908633232117, 0.29960817098617554, 0.3299117386341095,
  0.3479657471179962, 0.34929952025413513, 0.3507291376590729,
  0.36548393964767456, 0.3870280086994171, 0.4007895290851593,
  0.3960151672363281, 0.3713930547237396, 0.3368629217147827,
  0.3018401563167572, 0.26982277631759644, 0.24954846501350403,
  0.24617138504981995, 0.24237993359565735, 0.22023271024227142,
  0.1903928816318512, 0.1722131222486496, 0.1677626222372055,
  0.1698206216096878, 0.1681550294160843, 0.15126127004623413,
  0.12355213612318039, 0.10280992090702057, 0.09513448923826218,
  0.09573003649711609, 0.10086362808942795, 0.10119734704494476,
  0.08643005788326263, 0.06117526814341545, 0.03423402085900307,
  0.005205829627811909, -0.026050716638565063, -0.058211226016283035,
  -0.0941765084862709, -0.13045381009578705, -0.1577162742614746,
  -0.17794877290725708, -0.19807840883731842, -0.21432511508464813,
  -0.22424818575382233, -0.2334095537662506, -0.2379922717809677,
  -0.23042595386505127, -0.22436513006687164, -0.2398831993341446,
  -0.27001509070396423, -0.2934452295303345, -0.3059922456741333,
  -0.3143267035484314, -0.31856340169906616, -0.31916117668151855,
  -0.32427528500556946, -0.3410738408565521, -0.3692867159843445,
  -0.40223217010498047, -0.4306260347366333, -0.4487144649028778,
  -0.4588790237903595, -0.47116604447364807, -0.49259892106056213,
  -0.5135021805763245, -0.5194445252418518, -0.5153378248214722,
  -0.5118733048439026, -0.50115567445755, -0.478762686252594,
  -0.46329399943351746, -0.4673336148262024, -0.48168694972991943,
  -0.49741843342781067, -0.513295590877533, -0.5290669202804565,
  -0.5447067022323608, -0.5540849566459656, -0.5541468262672424,
  -0.563755214214325, -0.5935105085372925, -0.6122158169746399,
  -0.5965180397033691, -0.5767026543617249, -0.5832750797271729,
  -0.6007637977600098, -0.6082867980003357, -0.6081883311271667,
  -0.6019912958145142, -0.5899487137794495, -0.5853794813156128,
  -0.589309811592102, -0.580188512802124, -0.5564935207366943,
  -0.545036256313324, -0.5513129234313965, -0.5550340414047241,
  -0.553739070892334, -0.5549196004867554, -0.5478687286376953,
  -0.5356156229972839, -0.54804527759552, -0.5807086229324341,
  -0.5860846638679504, -0.5527488589286804, -0.5219184160232544,
  -0.5164827704429626, -0.5203173756599426, -0.5199426412582397,
  -0.5129020810127258, -0.49340805411338806, -0.46551254391670227,
  -0.44530120491981506, -0.4267444610595703, -0.37890374660491943,
  -0.30226433277130127, -0.24725507199764252, -0.23966988921165466,
  -0.23521623015403748, -0.1967906355857849, -0.15884317457675934,
  -0.15763425827026367, -0.17211298644542694, -0.1908908486366272,
  -0.2420402318239212, -0.3064331114292145, -0.3143528997898102,
  -0.26355308294296265, -0.21859851479530334, -0.18411435186862946,
  -0.11099873483181, -0.011700637638568878, 0.06174864247441292,
  0.11587051302194595, 0.17961232364177704, 0.23574697971343994,
  0.2694118320941925, 0.2949911653995514, 0.2962781488895416,
  0.24869191646575928, 0.19377999007701874, 0.18232226371765137,
  0.1912519782781601, 0.1882173717021942, 0.1957937479019165,
  0.23066681623458862, 0.27005860209465027, 0.30267322063446045,
  0.33875572681427, 0.3786754012107849, 0.4149256646633148, 0.44573062658309937,
  0.4784995913505554, 0.5241513252258301, 0.572100818157196, 0.5956771373748779,
  0.6026632189750671, 0.6254265904426575, 0.6570383906364441,
  0.6667094230651855, 0.6551708579063416, 0.6356518864631653,
  0.6088730096817017, 0.585361659526825, 0.5745079517364502, 0.5603090524673462,
  0.5368374586105347, 0.5291569828987122, 0.5501852631568909,
  0.5826692581176758, 0.6110439896583557, 0.6350535750389099,
  0.6588010787963867, 0.6837636232376099, 0.7041456699371338,
  0.7092728614807129, 0.6943559646606445, 0.6642663478851318,
  0.6302540898323059, 0.6034085750579834, 0.5845155715942383,
  0.5648581385612488, 0.5400240421295166, 0.5154737830162048,
  0.49854299426078796, 0.4888595640659332, 0.48014140129089355,
  0.4758833646774292, 0.48701778054237366, 0.503113329410553,
  0.49818041920661926, 0.4721377193927765, 0.4446503818035126,
  0.4177619218826294, 0.38670721650123596, 0.35867854952812195,
  0.3349228799343109, 0.3105417490005493, 0.2877187430858612,
  0.2612971365451813, 0.22583307325839996, 0.20150737464427948,
  0.2050098329782486, 0.218545600771904, 0.22300425171852112,
  0.21650579571723938, 0.19748884439468384, 0.17357929050922394,
  0.15705132484436035, 0.1347416490316391, 0.09452182054519653,
  0.05951163172721863, 0.04208800941705704, 0.01732332445681095,
  -0.023144520819187164, -0.05564269796013832, -0.06915181130170822,
  -0.07403507828712463, -0.08054963499307632, -0.09459362179040909,
  -0.11459535360336304, -0.13043370842933655, -0.13614021241664886,
  -0.135679692029953, -0.13635574281215668, -0.13952520489692688,
  -0.1377929002046585, -0.12973818182945251, -0.12536530196666718,
  -0.12571898102760315, -0.1253073811531067, -0.13575832545757294,
  -0.16593189537525177, -0.19817014038562775, -0.22214271128177643,
  -0.25316673517227173, -0.2953086793422699, -0.3299359977245331,
  -0.34215331077575684, -0.3304961025714874, -0.30613118410110474,
  -0.2858833074569702, -0.26967400312423706, -0.24386410415172577,
  -0.21072913706302643, -0.18424931168556213, -0.16819605231285095,
  -0.16207288205623627, -0.16625119745731354, -0.17535631358623505,
  -0.18819302320480347, -0.2098844200372696, -0.23213830590248108,
  -0.2385568618774414, -0.23139744997024536, -0.22685368359088898,
  -0.22925543785095215, -0.22806669771671295, -0.2157542109489441,
  -0.19824989140033722, -0.18457652628421783, -0.1733378916978836,
  -0.16008377075195312, -0.14671000838279724, -0.12934701144695282,
  -0.09646493941545486, -0.050198718905448914, -0.007499757222831249,
  0.020601466298103333, 0.03263068571686745, 0.033591095358133316,
  0.03828532621264458, 0.05561569333076477, 0.0725373700261116,
  0.0736393854022026, 0.06074028089642525, 0.04209233447909355,
  0.025976398959755898, 0.0274360328912735, 0.0607130266726017,
  0.12188302725553513, 0.18287305533885956, 0.2098337858915329,
  0.20363342761993408, 0.20679792761802673, 0.2465500682592392,
  0.3000181019306183, 0.3338553011417389, 0.3370060920715332,
  0.3120322823524475, 0.281314879655838, 0.2867755889892578,
  0.33797913789749146, 0.38719552755355835, 0.38836535811424255,
  0.35273611545562744, 0.3284941017627716, 0.345657616853714,
  0.3883177936077118, 0.415010929107666, 0.4053143858909607, 0.3784908950328827,
  0.36445096135139465, 0.37318360805511475, 0.3946388363838196,
  0.4129456579685211, 0.4188595712184906, 0.4140733778476715,
  0.40389204025268555, 0.39208704233169556, 0.383487343788147,
  0.3767676055431366, 0.3590632677078247, 0.32831859588623047,
  0.30699169635772705, 0.3091352880001068, 0.31446823477745056,
  0.3002207279205322, 0.27419883012771606, 0.2544390559196472,
  0.2409438043832779, 0.22407874464988708, 0.1997545212507248,
  0.16735558211803436, 0.1289883702993393, 0.09296300262212753,
  0.06708670407533646, 0.05140915885567665, 0.04096851870417595,
  0.030440455302596092, 0.016676677390933037, 0.001966694602742791,
  -0.007978033274412155, -0.012498175725340843, -0.020282793790102005,
  -0.04382307827472687, -0.08598428964614868, -0.1322794407606125,
  -0.16628284752368927, -0.185760498046875, -0.19541272521018982,
  -0.20407690107822418, -0.23090948164463043, -0.27835381031036377,
  -0.31230220198631287, -0.31274303793907166, -0.3093342185020447,
  -0.3284609615802765, -0.3579838275909424, -0.3840295672416687,
  -0.4043910801410675, -0.4102906286716461, -0.4012412130832672,
  -0.39552876353263855, -0.40177229046821594, -0.4111228585243225,
  -0.4184596836566925, -0.424586683511734, -0.4318024814128876,
  -0.45137855410575867, -0.4929961562156677, -0.5412291288375854,
  -0.5679113268852234, -0.5713552236557007, -0.5740944147109985,
  -0.5833419561386108, -0.5891903042793274, -0.5908198356628418,
  -0.5868834853172302, -0.5694183111190796, -0.5530862212181091,
  -0.5596404075622559, -0.5728861093521118, -0.571015477180481,
  -0.5720195174217224, -0.592156171798706, -0.6118144989013672,
  -0.6145601868629456, -0.6100331544876099, -0.6145428419113159,
  -0.6325904130935669, -0.6462937593460083, -0.6359351873397827,
  -0.6161532998085022, -0.6123864650726318, -0.6204637289047241,
  -0.6319745182991028, -0.6515389084815979, -0.6628506779670715,
  -0.6406715512275696, -0.5998545289039612, -0.5782483220100403,
  -0.5857800841331482, -0.6015727519989014, -0.6015219688415527,
  -0.5817184448242188, -0.56460040807724, -0.569455087184906,
  -0.5799780488014221, -0.5688780546188354, -0.5455965399742126,
  -0.5420835614204407, -0.5593602657318115, -0.5675864219665527,
  -0.5497756004333496, -0.5141484141349792, -0.4789053797721863,
  -0.4607846140861511, -0.4590972661972046, -0.45514434576034546,
  -0.43304672837257385, -0.3773217499256134, -0.28351500630378723,
  -0.20669980347156525, -0.21095357835292816, -0.24360300600528717,
  -0.19804421067237854, -0.09940994530916214, -0.06207140162587166,
  -0.10434717684984207, -0.1627764254808426, -0.21086934208869934,
  -0.24128609895706177, -0.23036207258701324, -0.19171440601348877,
  -0.1670982539653778, -0.1463633030653, -0.08750078827142715,
  0.006217991467565298, 0.1039828360080719, 0.19707398116588593,
  0.2788744568824768, 0.33116865158081055, 0.3568049669265747,
  0.371747761964798, 0.3685814142227173, 0.33709079027175903,
  0.2975909113883972, 0.27490633726119995, 0.26572078466415405,
  0.2549608647823334, 0.25136274099349976, 0.2846516966819763,
  0.35379913449287415, 0.40953007340431213, 0.4258679449558258,
  0.4415193796157837, 0.48847419023513794, 0.5443385243415833,
  0.5853880047798157, 0.6164734363555908, 0.6408225297927856,
  0.6564769148826599, 0.6734727621078491, 0.7017043232917786,
  0.7329948544502258, 0.747227132320404, 0.7333125472068787, 0.7008723020553589,
  0.6677820086479187, 0.6428364515304565, 0.6233679056167603,
  0.5992134809494019, 0.5683650970458984, 0.5514487028121948, 0.568004310131073,
  0.607714056968689, 0.6478606462478638, 0.6783868074417114, 0.6998761296272278,
  0.7178633213043213, 0.7328241467475891, 0.733769953250885, 0.7169690132141113,
  0.68878173828125, 0.6465752124786377, 0.5943410396575928, 0.5541026592254639,
  0.5291385054588318, 0.5027380585670471, 0.47886744141578674,
  0.4676273465156555, 0.4567583501338959, 0.44145190715789795,
  0.43660929799079895, 0.4436126947402954, 0.44893983006477356,
  0.44461339712142944, 0.42506542801856995, 0.3914463222026825,
  0.35990971326828003, 0.33898255228996277, 0.31572744250297546,
  0.2796415388584137, 0.23649778962135315, 0.19548223912715912,
  0.1632121354341507, 0.14460641145706177, 0.13482250273227692,
  0.12298461049795151, 0.11151056736707687, 0.11139801144599915,
  0.11542781442403793, 0.10678019374608994, 0.09009615331888199,
  0.07684757560491562, 0.056353118270635605, 0.019613806158304214,
  -0.016866689547896385, -0.04187716543674469, -0.06740643829107285,
  -0.09911194443702698, -0.1262296587228775, -0.14481915533542633,
  -0.16032974421977997, -0.17342828214168549, -0.18305720388889313,
  -0.19301271438598633, -0.2051481157541275, -0.21630962193012238,
  -0.22496183216571808, -0.23123446106910706, -0.2308446764945984,
  -0.2188423126935959, -0.19926679134368896, -0.1832086145877838,
  -0.17593985795974731, -0.17428694665431976, -0.17680421471595764,
  -0.18558377027511597, -0.20103894174098969, -0.22668102383613586,
  -0.26798921823501587, -0.31594642996788025, -0.35077744722366333,
  -0.36674824357032776, -0.37061241269111633, -0.36288318037986755,
  -0.34142637252807617, -0.3081762194633484, -0.26481959223747253,
  -0.2192290872335434, -0.18442276120185852, -0.16006498038768768,
  -0.1382402777671814, -0.12340909242630005, -0.12379438430070877,
  -0.13909055292606354, -0.1638006567955017, -0.18368396162986755,
  -0.1854461282491684, -0.1807618886232376, -0.1879068911075592,
  -0.19537705183029175, -0.18131248652935028, -0.1506292223930359,
  -0.12458460032939911, -0.11325971782207489, -0.10967282205820084,
  -0.0997920110821724, -0.07692387700080872, -0.04511310160160065,
  -0.011059176176786423, 0.017352961003780365, 0.036135170608758926,
  0.056762538850307465, 0.09350224584341049, 0.13868296146392822,
  0.167755126953125, 0.16434894502162933, 0.13839039206504822,
  0.12437603622674942, 0.1453869491815567, 0.1783963292837143,
  0.18502821028232574, 0.16510075330734253, 0.1469280868768692,
  0.15015923976898193, 0.186539426445961, 0.25694403052330017,
  0.3232348561286926, 0.33378177881240845, 0.298928827047348,
  0.2860267162322998, 0.3261960446834564, 0.3817746341228485,
  0.40891242027282715, 0.4004157483577728, 0.37609630823135376,
  0.3676880896091461, 0.3940199017524719, 0.4364491105079651,
  0.46233975887298584, 0.46312975883483887, 0.446089506149292,
  0.41970500349998474, 0.40555354952812195, 0.4207649528980255,
  0.43992504477500916, 0.42443129420280457, 0.38606324791908264,
  0.36793920397758484, 0.3776065707206726, 0.39102044701576233,
  0.3957478106021881, 0.3897072672843933, 0.3724607229232788,
  0.35638123750686646, 0.352247953414917, 0.3503674566745758,
  0.33846840262413025, 0.31532198190689087, 0.2855968177318573,
  0.26410096883773804, 0.2645643353462219, 0.27301540970802307,
  0.2602373957633972, 0.21733690798282623, 0.16258113086223602,
  0.12259890884160995, 0.10381285846233368, 0.08239720016717911,
  0.040536992251873016, -0.008058716543018818, -0.04485625401139259,
  -0.06426351517438889, -0.06533607095479965, -0.057195041328668594,
  -0.05584721267223358, -0.06640931218862534, -0.08721034228801727,
  -0.11260747909545898, -0.12678804993629456, -0.1277334839105606,
  -0.14170591533184052, -0.1863539069890976, -0.24573703110218048,
  -0.2893591821193695, -0.3016193211078644, -0.3009624779224396,
  -0.318945974111557, -0.3540995419025421, -0.3819675147533417,
  -0.3992050886154175, -0.41473907232284546, -0.4254932105541229,
  -0.4302293360233307, -0.43694832921028137, -0.4499971866607666,
  -0.46324846148490906, -0.46353209018707275, -0.4502057135105133,
  -0.4432237446308136, -0.4521508812904358, -0.4673057496547699,
  -0.48687052726745605, -0.5081696510314941, -0.5182892084121704,
  -0.5294275283813477, -0.565903902053833, -0.609100878238678,
  -0.6227920055389404, -0.6102169752120972, -0.5951446294784546,
  -0.5872374176979065, -0.5885621309280396, -0.5960787534713745,
  -0.5953462719917297, -0.5773950219154358, -0.5598792433738708,
  -0.5703550577163696, -0.6046807765960693, -0.6320454478263855,
  -0.6440016627311707, -0.6494181752204895, -0.6389853954315186,
  -0.6162638664245605, -0.6169896721839905, -0.6463625431060791,
  -0.6646660566329956, -0.6521316766738892, -0.6220260858535767,
  -0.5925850868225098, -0.5882156491279602, -0.6209845542907715,
  -0.658588707447052, -0.6565770506858826, -0.6163357496261597,
  -0.5814226269721985, -0.5801991820335388, -0.5938841104507446,
  -0.5991394519805908, -0.605329155921936, -0.6109928488731384,
  -0.589693009853363, -0.5559173822402954, -0.5547440052032471,
  -0.5777620673179626, -0.585179328918457, -0.5769492983818054,
  -0.5648499727249146, -0.5380553007125854, -0.4986483156681061,
  -0.46761298179626465, -0.4480893611907959, -0.4216068983078003,
  -0.3712965250015259, -0.30165722966194153, -0.24402299523353577,
  -0.22430665791034698, -0.22310853004455566, -0.19357630610466003,
  -0.12371636927127838, -0.06371108442544937, -0.07309184968471527,
  -0.14286723732948303, -0.20198185741901398, -0.20420338213443756,
  -0.16925080120563507, -0.13849234580993652, -0.12838543951511383,
  -0.1286531537771225, -0.11611990630626678, -0.064116470515728,
  0.040904272347688675, 0.17422866821289062, 0.28175416588783264,
  0.3365294635295868, 0.36252784729003906, 0.3882278501987457,
  0.41392070055007935, 0.4270658493041992, 0.4162795841693878,
  0.3821711838245392, 0.3459782898426056, 0.3226466178894043,
  0.3066060543060303, 0.3043306767940521, 0.33246564865112305,
  0.3800804913043976, 0.4246399998664856, 0.46455124020576477,
  0.5035597085952759, 0.5401859283447266, 0.5773220658302307,
  0.6120954751968384, 0.6399459838867188, 0.6707122921943665,
  0.7062572836875916, 0.730632483959198, 0.743450403213501, 0.7543987035751343,
  0.7527240514755249, 0.7311533093452454, 0.7063062787055969,
  0.6862714886665344, 0.6608670353889465, 0.6262952089309692,
  0.5876880288124084, 0.5553058981895447, 0.5449873805046082,
  0.5592379570007324, 0.5809433460235596, 0.5997447371482849,
  0.6229123473167419, 0.6545474529266357, 0.6799917817115784,
  0.6812694072723389, 0.6642821431159973, 0.6476851105690002,
  0.6279579401016235, 0.5916334986686707, 0.5465524792671204,
  0.5045710206031799, 0.46724191308021545, 0.4428909718990326,
  0.4310946464538574, 0.4109385311603546, 0.38423487544059753,
  0.3764083981513977, 0.38182851672172546, 0.37520748376846313,
  0.35748687386512756, 0.33737316727638245, 0.31200286746025085,
  0.28619492053985596, 0.26468655467033386, 0.23883186280727386,
  0.2082834243774414, 0.18118007481098175, 0.15070609748363495,
  0.11046963185071945, 0.07604454457759857, 0.06029042229056358,
  0.053791292011737823, 0.04768829792737961, 0.04451194033026695,
  0.03999271243810654, 0.026608314365148544, 0.013810830190777779,
  0.012975062243640423, 0.01397843100130558, 0.002820015186443925,
  -0.02029147930443287, -0.051983535289764404, -0.08629605174064636,
  -0.11242730170488358, -0.13247829675674438, -0.15729886293411255,
  -0.18329060077667236, -0.20237471163272858, -0.21626342833042145,
  -0.22500073909759521, -0.228363037109375, -0.23225298523902893,
  -0.2356235384941101, -0.23280273377895355, -0.22569426894187927,
  -0.21323226392269135, -0.19026482105255127, -0.16309979557991028,
  -0.14198468625545502, -0.12932679057121277, -0.12418695539236069,
  -0.12261325865983963, -0.12461583316326141, -0.14213337004184723,
  -0.17900550365447998, -0.22156256437301636, -0.25794464349746704,
  -0.27917104959487915, -0.2785969376564026, -0.26932287216186523,
  -0.26542654633522034, -0.2535232901573181, -0.21905644237995148,
  -0.16833467781543732, -0.11162231862545013, -0.06275681406259537,
  -0.03693521395325661, -0.027258340269327164, -0.01870935596525669,
  -0.015432298183441162, -0.021582666784524918, -0.028637846931815147,
  -0.03456805646419525, -0.04123926907777786, -0.04380243271589279,
  -0.03991193324327469, -0.029762187972664833, -0.012439487501978874,
  0.007017416879534721, 0.023328809067606926, 0.038355402648448944,
  0.05224890261888504, 0.06513034552335739, 0.08425946533679962,
  0.11186345666646957, 0.13724969327449799, 0.15117612481117249,
  0.15894882380962372, 0.17555199563503265, 0.20595021545886993,
  0.23745469748973846, 0.26085782051086426, 0.27707022428512573,
  0.27815499901771545, 0.2608790993690491, 0.25031912326812744,
  0.2685846984386444, 0.3029509484767914, 0.331227570772171,
  0.34289225935935974, 0.3327908515930176, 0.3115990459918976,
  0.3077954351902008, 0.3347996771335602, 0.37566664814949036,
  0.4033513069152832, 0.39914318919181824, 0.3678364157676697,
  0.3433148264884949, 0.35373976826667786, 0.38551101088523865,
  0.409096896648407, 0.4199838936328888, 0.42570552229881287,
  0.42535626888275146, 0.42625635862350464, 0.4413943886756897,
  0.45961469411849976, 0.45886746048927307, 0.4410727918148041,
  0.4206339418888092, 0.4017307758331299, 0.3836176097393036,
  0.3616543412208557, 0.33006227016448975, 0.30161309242248535,
  0.2949931025505066, 0.2994314730167389, 0.29005664587020874,
  0.26687249541282654, 0.24776382744312286, 0.2413441389799118,
  0.2432704120874405, 0.2438654750585556, 0.2355036437511444,
  0.21767489612102509, 0.19500744342803955, 0.17399311065673828,
  0.159182608127594, 0.14774997532367706, 0.13216958940029144,
  0.1056007593870163, 0.06329772621393204, 0.011791273020207882,
  -0.029478538781404495, -0.05217314511537552, -0.07116676867008209,
  -0.09980478137731552, -0.1309858113527298, -0.14997436106204987,
  -0.15369699895381927, -0.15274320542812347, -0.1568889617919922,
  -0.16667716205120087, -0.18126419186592102, -0.19894304871559143,
  -0.21344172954559326, -0.22657768428325653, -0.24855603277683258,
  -0.27719202637672424, -0.30385181307792664, -0.3294741213321686,
  -0.3523825407028198, -0.3686840236186981, -0.3849535584449768,
  -0.3978961706161499, -0.39504384994506836, -0.39378881454467773,
  -0.41918420791625977, -0.45191895961761475, -0.46321842074394226,
  -0.4617474377155304, -0.46244075894355774, -0.46350234746932983,
  -0.4651064872741699, -0.4666972756385803, -0.46561920642852783,
  -0.4737553596496582, -0.4969693422317505, -0.511938750743866,
  -0.5047199726104736, -0.5016390085220337, -0.5252527594566345,
  -0.5538085699081421, -0.5616597533226013, -0.5667160153388977,
  -0.5866336226463318, -0.5903136730194092, -0.561774492263794,
  -0.5472639799118042, -0.5660436749458313, -0.57059246301651,
  -0.5500586032867432, -0.5539345741271973, -0.5903478860855103,
  -0.6159675121307373, -0.6158686876296997, -0.6113673448562622,
  -0.6153404712677002, -0.6224986910820007, -0.6269790530204773,
  -0.6310871839523315, -0.6324002146720886, -0.6215035319328308,
  -0.6052749156951904, -0.5946082472801208, -0.5803452730178833,
  -0.5724514126777649, -0.5955002903938293, -0.6123170852661133,
  -0.579042375087738, -0.5499377846717834, -0.5740830898284912,
  -0.5957282185554504, -0.5842725038528442, -0.5859127044677734,
  -0.5922389030456543, -0.5609189867973328, -0.5312753915786743,
  -0.5480234026908875, -0.5743930339813232, -0.5754024982452393,
  -0.5570343732833862, -0.514600932598114, -0.4537055492401123,
  -0.4074992537498474, -0.37317565083503723, -0.3222152888774872,
  -0.27929025888442993, -0.2833678424358368, -0.2935551702976227,
  -0.23929354548454285, -0.13537278771400452, -0.06148646026849747,
  -0.06119270250201225, -0.11967002600431442, -0.19045838713645935,
  -0.21041451394557953, -0.15647560358047485, -0.09416431933641434,
  -0.0948234349489212, -0.13656878471374512, -0.15795373916625977,
  -0.14064276218414307, -0.07821065187454224, 0.039318930357694626,
  0.1677912324666977, 0.2400800883769989, 0.2668110430240631,
  0.30862411856651306, 0.37073734402656555, 0.41491034626960754,
  0.43286311626434326, 0.43526577949523926, 0.4162271022796631,
  0.37285304069519043, 0.32245951890945435, 0.28714025020599365,
  0.28260913491249084, 0.3093147575855255, 0.347057044506073,
  0.37655648589134216, 0.4019192159175873, 0.4382005035877228,
  0.4827073812484741, 0.5124772787094116, 0.5216273069381714, 0.539864718914032,
  0.5829048752784729, 0.6235560774803162, 0.6448089480400085,
  0.6611137390136719, 0.6736720204353333, 0.6750858426094055,
  0.6769732236862183, 0.6770130395889282, 0.6516342163085938,
  0.6006609201431274, 0.5406569838523865, 0.48213982582092285,
  0.4483030438423157, 0.453830748796463, 0.46914780139923096,
  0.47042587399482727, 0.47814658284187317, 0.5041272640228271,
  0.5312345623970032, 0.5542858242988586, 0.5749755501747131,
  0.5772280693054199, 0.551253080368042, 0.5079643130302429, 0.4576423168182373,
  0.4110775291919708, 0.386597603559494, 0.37953415513038635,
  0.35623830556869507, 0.3089599013328552, 0.27471137046813965,
  0.2776661813259125, 0.2995563745498657, 0.3147573173046112,
  0.30966436862945557, 0.27933770418167114, 0.2401546835899353,
  0.2181635946035385, 0.21113544702529907, 0.19783496856689453,
  0.17311620712280273, 0.139228954911232, 0.09067222476005554,
  0.03440288081765175, -0.007607376668602228, -0.028398333117365837,
  -0.03881565481424332, -0.04284348338842392, -0.03956415876746178,
  -0.03867259994149208, -0.04523841291666031, -0.04522800073027611,
  -0.031423211097717285, -0.01809771917760372, -0.01559483539313078,
  -0.020769523456692696, -0.03154052421450615, -0.047864388674497604,
  -0.07148966193199158, -0.10677889734506607, -0.14550401270389557,
  -0.17048880457878113, -0.18058444559574127, -0.18559667468070984,
  -0.19215692579746246, -0.20768548548221588, -0.22834992408752441,
  -0.23611006140708923, -0.22924360632896423, -0.21962521970272064,
  -0.2062302529811859, -0.18974120914936066, -0.1774722933769226,
  -0.15878178179264069, -0.1223912388086319, -0.0810934379696846,
  -0.048357781022787094, -0.02804391272366047, -0.0271714199334383,
  -0.04442431777715683, -0.06692906469106674, -0.08748944103717804,
  -0.10534367710351944, -0.12401791661977768, -0.1481272280216217,
  -0.16784580051898956, -0.16859744489192963, -0.14933989942073822,
  -0.11087490618228912, -0.05967259407043457, -0.019356979057192802,
  0.0030858309473842382, 0.026571234688162804, 0.05322235822677612,
  0.07184697687625885, 0.08736813068389893, 0.10155964642763138,
  0.1059325635433197, 0.10602401196956635, 0.11114883422851562,
  0.11966592073440552, 0.13315877318382263, 0.15215371549129486,
  0.1638905256986618, 0.15818533301353455, 0.14281857013702393,
  0.13546085357666016, 0.14247271418571472, 0.1514628529548645,
  0.15555186569690704, 0.16542264819145203, 0.1854591965675354,
  0.21174819767475128, 0.24584953486919403, 0.27802860736846924,
  0.28925585746765137, 0.2825159430503845, 0.2752353250980377,
  0.2777769863605499, 0.2942097783088684, 0.3109394907951355,
  0.3034982681274414, 0.27847641706466675, 0.26766037940979004,
  0.28311678767204285, 0.3129650056362152, 0.3413509130477905,
  0.3623782992362976, 0.3806580901145935, 0.38705551624298096,
  0.36595645546913147, 0.33622506260871887, 0.32441380620002747,
  0.3194397985935211, 0.308580607175827, 0.2967643141746521,
  0.27651625871658325, 0.25210732221603394, 0.251257061958313,
  0.2703956961631775, 0.2860237658023834, 0.30480971932411194,
  0.32857200503349304, 0.3321714997291565, 0.3140871822834015,
  0.2973112463951111, 0.2864902913570404, 0.2749072015285492,
  0.26581481099128723, 0.2627667784690857, 0.2558499574661255,
  0.2319359928369522, 0.2005813717842102, 0.18164879083633423,
  0.16677573323249817, 0.14210808277130127, 0.12110713124275208,
  0.10703244805335999, 0.08607460558414459, 0.06863922625780106,
  0.0641622543334961, 0.057126834988594055, 0.05266159772872925,
  0.06551341712474823, 0.07414153963327408, 0.0626966804265976,
  0.04934932664036751, 0.038803987205028534, 0.020377425476908684,
  -0.002929101698100567, -0.029989661648869514, -0.06355492770671844,
  -0.09215839952230453, -0.10734677314758301, -0.11775905638933182,
  -0.13241751492023468, -0.14714393019676208, -0.14996163547039032,
  -0.14489325881004333, -0.15428800880908966, -0.1780177354812622,
  -0.19410715997219086, -0.20721788704395294, -0.23418714106082916,
  -0.2635141611099243, -0.2809096574783325, -0.2890450358390808,
  -0.287871778011322, -0.28050294518470764, -0.2775839865207672,
  -0.2789018154144287, -0.2830718755722046, -0.29629093408584595,
  -0.3135099411010742, -0.32351112365722656, -0.3249867856502533,
  -0.3236940801143646, -0.3333650231361389, -0.3624271750450134,
  -0.3903254568576813, -0.3942278325557709, -0.38480669260025024,
  -0.38322076201438904, -0.39282700419425964, -0.4048008620738983,
  -0.41145095229148865, -0.41896650195121765, -0.43668806552886963,
  -0.45329412817955017, -0.45836469531059265, -0.46270039677619934,
  -0.4657794237136841, -0.45774510502815247, -0.45329028367996216,
  -0.4606824815273285, -0.4581487476825714, -0.4434465169906616,
  -0.44178569316864014, -0.4598928689956665, -0.4884278178215027,
  -0.5165334939956665, -0.5225902199745178, -0.5072356462478638,
  -0.5127438902854919, -0.5485947132110596, -0.5602931380271912,
  -0.5320588946342468, -0.52718186378479, -0.5659002065658569,
  -0.5787444710731506, -0.5394523739814758, -0.5138307809829712,
  -0.5339505672454834, -0.5600928664207458, -0.5745686292648315,
  -0.586920440196991, -0.5807385444641113, -0.5495150089263916,
  -0.5223055481910706, -0.5165003538131714, -0.5336326360702515,
  -0.5736980438232422, -0.5992866158485413, -0.5652201175689697,
  -0.5109634399414062, -0.513860821723938, -0.5559719800949097,
  -0.5610570311546326, -0.528952419757843, -0.5046340227127075,
  -0.47183656692504883, -0.3980906903743744, -0.3151790201663971,
  -0.2690812945365906, -0.2604450285434723, -0.2573384940624237,
  -0.21384009718894958, -0.12448235601186752, -0.07126381993293762,
  -0.11470895260572433, -0.18728893995285034, -0.20522281527519226,
  -0.18627199530601501, -0.17881685495376587, -0.1885744035243988,
  -0.20373249053955078, -0.21171697974205017, -0.1958998292684555,
  -0.13739365339279175, -0.026635024696588516, 0.09667868912220001,
  0.16555006802082062, 0.18937982618808746, 0.23771461844444275,
  0.31592807173728943, 0.37131229043006897, 0.3821042776107788,
  0.36271557211875916, 0.3407151997089386, 0.3350782096385956,
  0.32211366295814514, 0.28302201628685, 0.2622651755809784,
  0.28802600502967834, 0.31524336338043213, 0.317747563123703,
  0.33067062497138977, 0.3724929094314575, 0.4159489572048187,
  0.43477770686149597, 0.4344727694988251, 0.4469486176967621,
  0.4949055016040802, 0.5582637786865234, 0.5959676504135132,
  0.5971469283103943, 0.5913047790527344, 0.6022204756736755, 0.612324595451355,
  0.6024460196495056, 0.5895958542823792, 0.5743901133537292,
  0.5204862356185913, 0.4385119378566742, 0.39299464225769043,
  0.398834764957428, 0.41208580136299133, 0.40981388092041016,
  0.4013656675815582, 0.4020153284072876, 0.4254455268383026,
  0.4612140357494354, 0.481338232755661, 0.48551130294799805,
  0.48539867997169495, 0.4628349542617798, 0.4073677957057953,
  0.3534700572490692, 0.330644428730011, 0.32165059447288513,
  0.2962389290332794, 0.2514081597328186, 0.21129243075847626,
  0.20051832497119904, 0.21411575376987457, 0.2234433889389038,
  0.21698150038719177, 0.20742543041706085, 0.19908112287521362,
  0.1869455873966217, 0.17925897240638733, 0.17970174551010132,
  0.16666293144226074, 0.12619927525520325, 0.07870613783597946,
  0.0483124740421772, 0.03357267752289772, 0.01719564199447632,
  -0.01425467524677515, -0.054304338991642, -0.07839802652597427,
  -0.07758430391550064, -0.07200184464454651, -0.07323770970106125,
  -0.06749185174703598, -0.05084700509905815, -0.03885062783956528,
  -0.03412032499909401, -0.025822103023529053, -0.018024886026978493,
  -0.024082880467176437, -0.04361670836806297, -0.06584572046995163,
  -0.08039280772209167, -0.08155500888824463, -0.07834936678409576,
  -0.08929591625928879, -0.11715909838676453, -0.14817798137664795,
  -0.17240838706493378, -0.18305443227291107, -0.17317640781402588,
  -0.14979802072048187, -0.1302081197500229, -0.12062465399503708,
  -0.11242662370204926, -0.09000200778245926, -0.04411672428250313,
  0.009700928814709187, 0.04531770199537277, 0.061619602143764496,
  0.07159863412380219, 0.07045791298151016, 0.05411791428923607,
  0.04085436463356018, 0.03939482569694519, 0.03093811310827732,
  0.0063177309930324554, -0.013858303427696228, -0.009131599217653275,
  0.01606661267578602, 0.04146465286612511, 0.053894516080617905,
  0.061305902898311615, 0.08143921941518784, 0.1147458404302597,
  0.14364489912986755, 0.1595960259437561, 0.16851061582565308,
  0.17397236824035645, 0.17847490310668945, 0.19007983803749084,
  0.2098558098077774, 0.22663934528827667, 0.23140491545200348,
  0.22643670439720154, 0.22183358669281006, 0.22497962415218353,
  0.22906358540058136, 0.2215331792831421, 0.20585525035858154,
  0.19627048075199127, 0.19576945900917053, 0.1995958834886551,
  0.20862235128879547, 0.22415409982204437, 0.24113775789737701,
  0.25257235765457153, 0.2549489140510559, 0.2520782947540283,
  0.2516515552997589, 0.25212717056274414, 0.24362994730472565,
  0.2270583212375641, 0.21433387696743011, 0.20733223855495453,
  0.1987091600894928, 0.191719651222229, 0.1965794712305069,
  0.21364104747772217, 0.23562690615653992, 0.2536334991455078,
  0.25813430547714233, 0.25108325481414795, 0.24597790837287903,
  0.2435532510280609, 0.2317543774843216, 0.21157124638557434,
  0.19384793937206268, 0.18018756806850433, 0.1679132729768753,
  0.1573827564716339, 0.14785528182983398, 0.13966868817806244,
  0.13599181175231934, 0.13895027339458466, 0.14888127148151398,
  0.1598030924797058, 0.15922757983207703, 0.14635373651981354,
  0.13824723660945892, 0.1458485871553421, 0.15699921548366547,
  0.15273858606815338, 0.13316385447978973, 0.11398765444755554,
  0.10041942447423935, 0.08696887642145157, 0.07388975471258163,
  0.056295137852430344, 0.023805472999811172, -0.009464995935559273,
  -0.02002643048763275, -0.016270006075501442, -0.016720429062843323,
  -0.019423453137278557, -0.021677179262042046, -0.023211464285850525,
  -0.016609041020274162, -0.010784885846078396, -0.023840585723519325,
  -0.04450919106602669, -0.051291607320308685, -0.04979051649570465,
  -0.05605519190430641, -0.07225918024778366, -0.0887182429432869,
  -0.09454138576984406, -0.093465656042099, -0.0987316370010376,
  -0.10797259211540222, -0.11104962974786758, -0.11322855204343796,
  -0.12508518993854523, -0.1454937756061554, -0.16447404026985168,
  -0.17607203125953674, -0.1877293437719345, -0.2056872546672821,
  -0.21974366903305054, -0.2241499125957489, -0.2272479087114334,
  -0.22918850183486938, -0.2288278043270111, -0.23480260372161865,
  -0.24155403673648834, -0.23782166838645935, -0.23533637821674347,
  -0.24416430294513702, -0.25512197613716125, -0.2655911147594452,
  -0.276917964220047, -0.27970150113105774, -0.27209681272506714,
  -0.26613909006118774, -0.27441516518592834, -0.2999818027019501,
  -0.3241601288318634, -0.32750505208969116, -0.3279256820678711,
  -0.35065069794654846, -0.3823820948600769, -0.4002076983451843,
  -0.40544214844703674, -0.404141366481781, -0.3921220898628235,
  -0.37326037883758545, -0.37317410111427307, -0.4031369686126709,
  -0.4225843846797943, -0.4045724868774414, -0.3979167640209198,
  -0.43194085359573364, -0.45751258730888367, -0.45991048216819763,
  -0.48218685388565063, -0.5168846249580383, -0.5246060490608215,
  -0.5126767158508301, -0.5012260675430298, -0.49348127841949463,
  -0.4928584098815918, -0.4948279857635498, -0.504477322101593,
  -0.5405912399291992, -0.5756021738052368, -0.5625175833702087,
  -0.5263717174530029, -0.5238146781921387, -0.5574594736099243,
  -0.592886745929718, -0.5991997122764587, -0.570482611656189,
  -0.5363591313362122, -0.5255892276763916, -0.5404390692710876,
  -0.5714424252510071, -0.59157395362854, -0.5746246576309204,
  -0.5382002592086792, -0.5092634558677673, -0.48987069725990295,
  -0.4830167293548584, -0.4666721820831299, -0.394776850938797,
  -0.30166879296302795, -0.274647980928421, -0.29490718245506287,
  -0.2663864493370056, -0.17896325886249542, -0.11496959626674652,
  -0.12862981855869293, -0.18336184322834015, -0.20927642285823822,
  -0.1996711641550064, -0.1939481496810913, -0.19701984524726868,
  -0.20017877221107483, -0.21433991193771362, -0.22051483392715454,
  -0.17852060496807098, -0.09096821397542953, 0.010045398026704788,
  0.09639659523963928, 0.1533120572566986, 0.2022159993648529,
  0.2702654004096985, 0.33438292145729065, 0.3620956242084503,
  0.3666597902774811, 0.3717513382434845, 0.37162360548973083,
  0.34600552916526794, 0.2992655634880066, 0.2731075584888458,
  0.2892007827758789, 0.3128536641597748, 0.3181297183036804,
  0.3203907608985901, 0.33207398653030396, 0.35663020610809326,
  0.39089715480804443, 0.41295096278190613, 0.4267757534980774,
  0.4613553583621979, 0.49649783968925476, 0.5046640634536743,
  0.522814929485321, 0.568934440612793, 0.5979956984519958, 0.595032811164856,
  0.5854518413543701, 0.57429438829422, 0.5572314858436584, 0.5294045805931091,
  0.4778195321559906, 0.41846275329589844, 0.387328565120697,
  0.3815023601055145, 0.3729636073112488, 0.3589302599430084,
  0.3578672707080841, 0.3748137652873993, 0.3892258405685425,
  0.3906332552433014, 0.4020724296569824, 0.4233063757419586,
  0.40734076499938965, 0.34336864948272705, 0.28195345401763916,
  0.2574605643749237, 0.25803661346435547, 0.25344133377075195,
  0.2217562198638916, 0.17978136241436005, 0.1635628193616867,
  0.16900625824928284, 0.17031414806842804, 0.16987799108028412,
  0.17308780550956726, 0.16357147693634033, 0.13512441515922546,
  0.10711721330881119, 0.09965699166059494, 0.10501635074615479,
  0.09401657432317734, 0.0624755397439003, 0.03777599334716797,
  0.025286901742219925, 0.003408859483897686, -0.03039286658167839,
  -0.0599861741065979, -0.07713878154754639, -0.08431587368249893,
  -0.09175494313240051, -0.10241632908582687, -0.09994494915008545,
  -0.07939848303794861, -0.06307017803192139, -0.06007077172398567,
  -0.0501379631459713, -0.02209172584116459, 0.005481717176735401,
  0.011532412841916084, 0.00326616782695055, 0.002323217922821641,
  0.008553541265428066, 0.007738285232335329, 0.001333232270553708,
  -0.00556150684133172, -0.019437264651060104, -0.04011860489845276,
  -0.057945575565099716, -0.06797993183135986, -0.0683538168668747,
  -0.061767857521772385, -0.057828549295663834, -0.05566301941871643,
  -0.040229327976703644, -0.003928931429982185, 0.0445377454161644,
  0.0897383913397789, 0.12291741371154785, 0.14803914725780487,
  0.16810768842697144, 0.17844699323177338, 0.1825742870569229,
  0.18886502087116241, 0.18937811255455017, 0.17102813720703125,
  0.1436249315738678, 0.13340523838996887, 0.1493142992258072,
  0.1704730987548828, 0.1757635772228241, 0.17141921818256378,
  0.17336411774158478, 0.18359307944774628, 0.19797584414482117,
  0.2118334025144577, 0.21932591497898102, 0.22410711646080017,
  0.23363962769508362, 0.24622473120689392, 0.2612718939781189,
  0.27971622347831726, 0.2916247546672821, 0.29142525792121887,
  0.28848111629486084, 0.2860434949398041, 0.2775489091873169,
  0.26178205013275146, 0.241273894906044, 0.21952107548713684,
  0.20251856744289398, 0.1938513219356537, 0.19589202105998993,
  0.20778989791870117, 0.21890515089035034, 0.22280530631542206,
  0.22581803798675537, 0.22970163822174072, 0.2303323894739151,
  0.22764623165130615, 0.21701908111572266, 0.19568508863449097,
  0.17558690905570984, 0.1599089801311493, 0.1364120990037918,
  0.1107904314994812, 0.10207264870405197, 0.11003578454256058,
  0.12380191683769226, 0.13618668913841248, 0.13429543375968933,
  0.11426905542612076, 0.10138607770204544, 0.11602362245321274,
  0.13652962446212769, 0.1316315084695816, 0.1072639673948288,
  0.08857523649930954, 0.08044993132352829, 0.07530813664197922,
  0.07190213352441788, 0.06775858253240585, 0.06065208464860916,
  0.05517125129699707, 0.04871368035674095, 0.03298776224255562,
  0.013247126713395119, -0.0007248885813169181, -0.007245977409183979,
  -0.0023564195726066828, 0.011600746773183346, 0.01460633147507906,
  0.0016357095446437597, -0.0019257180392742157, 0.014945441856980324,
  0.03032761812210083, 0.03138788416981697, 0.027409860864281654,
  0.015696585178375244, -0.01265341229736805, -0.03781485557556152,
  -0.03906327486038208, -0.035163819789886475, -0.04427305981516838,
  -0.05324006825685501, -0.05998858064413071, -0.0734662339091301,
  -0.07650355249643326, -0.06386341154575348, -0.06350179016590118,
  -0.07393828779459, -0.07015451043844223, -0.06764522939920425,
  -0.08629968762397766, -0.10041645914316177, -0.09039108455181122,
  -0.07479047775268555, -0.06951477378606796, -0.07402029633522034,
  -0.08589042723178864, -0.09841986000537872, -0.1038021668791771,
  -0.1045493632555008, -0.11092127859592438, -0.12747499346733093,
  -0.14420728385448456, -0.15131807327270508, -0.15573251247406006,
  -0.16155892610549927, -0.15857091546058655, -0.1499929577112198,
  -0.15276721119880676, -0.16540773212909698, -0.17315927147865295,
  -0.1749514788389206, -0.1819356381893158, -0.1988612711429596,
  -0.21498553454875946, -0.219745934009552, -0.22153842449188232,
  -0.22945594787597656, -0.23498567938804626, -0.2352176457643509,
  -0.24185845255851746, -0.25669801235198975, -0.2680862843990326,
  -0.2701719403266907, -0.2742043435573578, -0.2971084415912628,
  -0.32886141538619995, -0.3412010073661804, -0.3416603207588196,
  -0.35801681876182556, -0.3803214728832245, -0.38985246419906616,
  -0.39674144983291626, -0.40059006214141846, -0.3925691545009613,
  -0.39608412981033325, -0.42524582147598267, -0.4505150318145752,
  -0.4577699303627014, -0.46798059344291687, -0.4824410378932953,
  -0.4846096336841583, -0.48723629117012024, -0.5099297761917114,
  -0.5311509370803833, -0.5244565606117249, -0.5112389922142029,
  -0.5155472159385681, -0.5214022994041443, -0.5256328582763672,
  -0.5452412366867065, -0.5603671669960022, -0.5566021800041199,
  -0.5693519115447998, -0.5960658192634583, -0.5872944593429565,
  -0.5610491633415222, -0.5706706047058105, -0.5941728353500366,
  -0.5888896584510803, -0.568476140499115, -0.5533433556556702,
  -0.5427895784378052, -0.5444849729537964, -0.5599945783615112,
  -0.5662911534309387, -0.5458967685699463, -0.5113418698310852,
  -0.48373541235923767, -0.4572116434574127, -0.4121195673942566,
  -0.3588988482952118, -0.3205451965332031, -0.28506723046302795,
  -0.22708581387996674, -0.15347014367580414, -0.10021664947271347,
  -0.09647016227245331, -0.12472900748252869, -0.13863074779510498,
  -0.13697120547294617, -0.1506928652524948, -0.16899389028549194,
  -0.17407213151454926, -0.18699601292610168, -0.1973516047000885,
  -0.15476690232753754, -0.0672466978430748, 0.008014257997274399,
  0.060656726360321045, 0.12384586036205292, 0.204672709107399,
  0.27227982878685, 0.3074546754360199, 0.3296261131763458, 0.35315465927124023,
  0.3604165315628052, 0.3510042726993561, 0.34824568033218384,
  0.3462967276573181, 0.3323688209056854, 0.32829082012176514,
  0.34572434425354004, 0.36525043845176697, 0.38083508610725403,
  0.395509272813797, 0.3954123556613922, 0.37868738174438477,
  0.3705788850784302, 0.3852984309196472, 0.4066193401813507,
  0.4170147180557251, 0.4260583221912384, 0.4504469931125641,
  0.4804280996322632, 0.4987756013870239, 0.5112682580947876,
  0.5252330899238586, 0.5281926989555359, 0.5075160264968872,
  0.4672797918319702, 0.4248233139514923, 0.39526745676994324,
  0.37457793951034546, 0.35307130217552185, 0.33556196093559265,
  0.3248957395553589, 0.3155432343482971, 0.3144233822822571,
  0.3292551636695862, 0.34709882736206055, 0.35190287232398987,
  0.33832359313964844, 0.3068440556526184, 0.26796966791152954,
  0.2356988787651062, 0.2106381505727768, 0.18391770124435425,
  0.1496594399213791, 0.11085499078035355, 0.07946265488862991,
  0.06316501647233963, 0.05815193057060242, 0.059185974299907684,
  0.06144813075661659, 0.060988739132881165, 0.06192528456449509,
  0.06610717624425888, 0.06816738843917847, 0.06998419016599655,
  0.07204914838075638, 0.0645921379327774, 0.050375353544950485,
  0.039864055812358856, 0.02589930221438408, 0.002671360271051526,
  -0.01941804029047489, -0.037037283182144165, -0.053658660501241684,
  -0.0664292499423027, -0.07639675587415695, -0.08614447712898254,
  -0.09194788336753845, -0.09485706686973572, -0.09560345858335495,
  -0.0863812044262886, -0.06835097819566727, -0.0510403998196125,
  -0.03485343977808952, -0.016796724870800972, 0.005477158818393946,
  0.0347636379301548, 0.06306250393390656, 0.0829298347234726,
  0.10188517719507217, 0.11643955111503601, 0.1090891882777214,
  0.08727766573429108, 0.075382761657238, 0.07425507158041, 0.0686938688158989,
  0.05449709668755531, 0.03924647718667984, 0.034101735800504684,
  0.04532470926642418, 0.0690968707203865, 0.09858542680740356,
  0.13043789565563202, 0.16326592862606049, 0.19361138343811035,
  0.21506237983703613, 0.22805140912532806, 0.24133217334747314,
  0.25394028425216675, 0.25670626759529114, 0.25147855281829834,
  0.24629683792591095, 0.24537096917629242, 0.2523808181285858,
  0.26347479224205017, 0.2693345546722412, 0.27418601512908936,
  0.2857941687107086, 0.2962414622306824, 0.29737532138824463,
  0.29295584559440613, 0.2889862358570099, 0.2901386320590973,
  0.29567304253578186, 0.2974139153957367, 0.294185608625412, 0.292702853679657,
  0.2938285171985626, 0.29611843824386597, 0.29861980676651,
  0.29487213492393494, 0.2805878221988678, 0.2583310604095459,
  0.23194573819637299, 0.20836840569972992, 0.1932189166545868,
  0.1839076429605484, 0.17964692413806915, 0.18220843374729156,
  0.18647697567939758, 0.19007717072963715, 0.1967674046754837,
  0.20549015700817108, 0.21398712694644928, 0.2190369814634323,
  0.21425776183605194, 0.2036028504371643, 0.19082878530025482,
  0.1636904925107956, 0.12659311294555664, 0.10583146661520004,
  0.10008171945810318, 0.08869346976280212, 0.07352767139673233,
  0.05973280966281891, 0.04302868992090225, 0.03242668882012367,
  0.035289838910102844, 0.041880570352077484, 0.04742830619215965,
  0.051976993680000305, 0.05060793086886406, 0.0468050017952919,
  0.047596339136362076, 0.05089521035552025, 0.053469039499759674,
  0.05324117839336395, 0.05237507447600365, 0.05809691175818443,
  0.06118794530630112, 0.047118209302425385, 0.03046547994017601,
  0.026584526523947716, 0.02373785898089409, 0.017895203083753586,
  0.013741866685450077, 0.0009647905826568604, -0.017272084951400757,
  -0.021512547507882118, -0.012408608570694923, 0.0027450507041066885,
  0.02117193304002285, 0.025729317218065262, 0.014206252992153168,
  0.011904850602149963, 0.019145388156175613, 0.01684529520571232,
  0.01152847334742546, 0.010223801247775555, 0.002311411313712597,
  -0.008948761969804764, -0.012683999724686146, -0.009686371311545372,
  0.00046738923992961645, 0.015176097862422466, 0.022784393280744553,
  0.021902214735746384, 0.018418414518237114, 0.013147661462426186,
  0.010952898301184177, 0.013750658370554447, 0.012648281641304493,
  0.007681718561798334, 0.004325164947658777, -0.005230048671364784,
  -0.0235853623598814, -0.0371813140809536, -0.04274299740791321,
  -0.052894722670316696, -0.07260313630104065, -0.09218291938304901,
  -0.10243728011846542, -0.10639168322086334, -0.11087352782487869,
  -0.11390718817710876, -0.11212962120771408, -0.10889220982789993,
  -0.10633652657270432, -0.10469712316989899, -0.10653403401374817,
  -0.11181674152612686, -0.11926677823066711, -0.13092492520809174,
  -0.1469668447971344, -0.16517053544521332, -0.18406453728675842,
  -0.20115718245506287, -0.21801704168319702, -0.23956304788589478,
  -0.2594270408153534, -0.2676827907562256, -0.2718910276889801,
  -0.2865585386753082, -0.31221047043800354, -0.33880141377449036,
  -0.3565015494823456, -0.3617987036705017, -0.3621603548526764,
  -0.37054023146629333, -0.3889254629611969, -0.40140292048454285,
  -0.3961539566516876, -0.38769158720970154, -0.39356839656829834,
  -0.4103510081768036, -0.43443629145622253, -0.4634058475494385,
  -0.4779012203216553, -0.47700461745262146, -0.48962098360061646,
  -0.5135907530784607, -0.5234217643737793, -0.5295085906982422,
  -0.5422661304473877, -0.5402302145957947, -0.5277649164199829,
  -0.5326113700866699, -0.5482816696166992, -0.557145893573761,
  -0.5627509355545044, -0.5680663585662842, -0.5753449201583862,
  -0.5877529978752136, -0.590431272983551, -0.5751537680625916,
  -0.562115490436554, -0.5633619427680969, -0.565554678440094,
  -0.5559644103050232, -0.5396924018859863, -0.5339446067810059,
  -0.5397288799285889, -0.5371567606925964, -0.5268357396125793,
  -0.5225095748901367, -0.5119138956069946, -0.49100184440612793,
  -0.47117239236831665, -0.4250180423259735, -0.34070178866386414,
  -0.27998918294906616, -0.26262587308883667, -0.22437283396720886,
  -0.1571042686700821, -0.11723857372999191, -0.10523372143507004,
  -0.08982839435338974, -0.08523232489824295, -0.11347474157810211,
  -0.157400444149971, -0.18079037964344025, -0.17122769355773926,
  -0.15459716320037842, -0.14167548716068268, -0.1033424362540245,
  -0.04259827733039856, 0.002939344383776188, 0.04690845310688019,
  0.1212669089436531, 0.19712336361408234, 0.24078939855098724,
  0.26535892486572266, 0.28092285990715027, 0.2824367582798004,
  0.2787743806838989, 0.2791844606399536, 0.28382226824760437,
  0.2946160137653351, 0.31225186586380005, 0.3353204131126404,
  0.3563946783542633, 0.36089444160461426, 0.3551381826400757,
  0.3604797422885895, 0.36813604831695557, 0.3609640896320343,
  0.35422489047050476, 0.35591381788253784, 0.3483615517616272,
  0.3378602862358093, 0.3509489893913269, 0.3867584764957428,
  0.4214072525501251, 0.4362473487854004, 0.43197840452194214,
  0.4274645447731018, 0.4266730546951294, 0.40836769342422485,
  0.37243911623954773, 0.3426041901111603, 0.31551307439804077,
  0.2734932601451874, 0.2321700006723404, 0.21926726400852203,
  0.23312991857528687, 0.25390368700027466, 0.2691734731197357,
  0.2751356363296509, 0.2713344097137451, 0.26375484466552734,
  0.26111704111099243, 0.2582310140132904, 0.24007277190685272,
  0.20857247710227966, 0.17582106590270996, 0.1385299414396286,
  0.09688913822174072, 0.06818351149559021, 0.052851058542728424,
  0.03598903492093086, 0.023773331195116043, 0.02407090552151203,
  0.020904622972011566, 0.00840836577117443, 0.002750595100224018,
  0.008019698783755302, 0.015834353864192963, 0.02507627010345459,
  0.034425221383571625, 0.03836767002940178, 0.03693179786205292,
  0.03495842590928078, 0.03662840649485588, 0.0410308837890625,
  0.04230551794171333, 0.038511231541633606, 0.028674021363258362,
  0.00745754037052393, -0.017971504479646683, -0.03049810230731964,
  -0.032048407942056656, -0.034831319004297256, -0.03854217752814293,
  -0.04386766999959946, -0.05234667286276817, -0.051328226923942566,
  -0.03529946506023407, -0.01248808205127716, 0.019565394148230553,
  0.05879055708646774, 0.08715593069791794, 0.10658419132232666,
  0.13119269907474518, 0.14973261952400208, 0.15225021541118622,
  0.15255850553512573, 0.15413203835487366, 0.1470368504524231,
  0.13258130848407745, 0.11591383069753647, 0.10330522060394287,
  0.10523635894060135, 0.11797091364860535, 0.13142357766628265,
  0.15118663012981415, 0.1790185570716858, 0.20309370756149292,
  0.2200639545917511, 0.2311609536409378, 0.23553374409675598,
  0.2420458346605301, 0.2528781294822693, 0.2538815140724182,
  0.24578432738780975, 0.24333681166172028, 0.24580080807209015,
  0.24855650961399078, 0.2544361352920532, 0.2568928003311157,
  0.2505301833152771, 0.24587148427963257, 0.24788305163383484,
  0.24897487461566925, 0.2469167858362198, 0.24390803277492523,
  0.24002705514431, 0.23782067000865936, 0.23990653455257416,
  0.24639727175235748, 0.25822532176971436, 0.2727893590927124,
  0.281563937664032, 0.2780558466911316, 0.26252618432044983,
  0.24105297029018402, 0.22044309973716736, 0.20002734661102295,
  0.17410887777805328, 0.14341729879379272, 0.11566310375928879,
  0.09624817222356796, 0.08499971032142639, 0.08132230490446091,
  0.0861833393573761, 0.0958578884601593, 0.10396646708250046,
  0.11206980049610138, 0.1221596747636795, 0.12720468640327454,
  0.12692636251449585, 0.1287064254283905, 0.12468105554580688,
  0.10528619587421417, 0.08547902852296829, 0.0787222608923912,
  0.07288573682308197, 0.05841118469834328, 0.04354708641767502,
  0.03381924703717232, 0.028033656999468803, 0.028533292934298515,
  0.03553660213947296, 0.040444280952215195, 0.03659963235259056,
  0.031465206295251846, 0.03574211522936821, 0.04345899447798729,
  0.042189765721559525, 0.03534616529941559, 0.03136986121535301,
  0.029972640797495842, 0.034787867218256, 0.04968761280179024,
  0.061631057411432266, 0.05755431950092316, 0.045254383236169815,
  0.038962092250585556, 0.04206541180610657, 0.04821508005261421,
  0.04949560761451721, 0.045999880880117416, 0.04443824663758278,
  0.04871322959661484, 0.06032566353678703, 0.0769663006067276,
  0.08807754516601562, 0.08965566009283066, 0.09206689894199371,
  0.09736377745866776, 0.09353528171777725, 0.07864155620336533,
  0.06459389626979828, 0.058558229357004166, 0.0563872754573822,
  0.05051985755562782, 0.04052611067891121, 0.03400378301739693,
  0.03464195504784584, 0.03837944194674492, 0.04222477227449417,
  0.045792438089847565, 0.048577502369880676, 0.050896044820547104,
  0.05186552554368973, 0.05018732696771622, 0.04734687879681587,
  0.0440286360681057, 0.03985759615898132, 0.03579765185713768,
  0.028711700811982155, 0.015359926037490368, 0.0017816181061789393,
  -0.009684535674750805, -0.02725033089518547, -0.04784379526972771,
  -0.059002310037612915, -0.06581809371709824, -0.08079700171947479,
  -0.09912994503974915, -0.11135829240083694, -0.1177176684141159,
  -0.1187857985496521, -0.11679106950759888, -0.12137880176305771,
  -0.13541242480278015, -0.15078473091125488, -0.16411222517490387,
  -0.17660699784755707, -0.18444588780403137, -0.18879465758800507,
  -0.20001329481601715, -0.21852874755859375, -0.23359206318855286,
  -0.24390065670013428, -0.25509780645370483, -0.26590240001678467,
  -0.2751300036907196, -0.28673848509788513, -0.30147284269332886,
  -0.3170906901359558, -0.33073917031288147, -0.34021782875061035,
  -0.351643830537796, -0.36908307671546936, -0.3797464370727539,
  -0.37995579838752747, -0.3903661370277405, -0.4140574634075165,
  -0.42594945430755615, -0.4210030436515808, -0.420537531375885,
  -0.43228861689567566, -0.4452064633369446, -0.45133522152900696,
  -0.4529825747013092, -0.45526719093322754, -0.4558645784854889,
  -0.45203033089637756, -0.45364418625831604, -0.46593499183654785,
  -0.4769045114517212, -0.48151999711990356, -0.4866286814212799,
  -0.4905717074871063, -0.49101752042770386, -0.4937152862548828,
  -0.4982948303222656, -0.5009000301361084, -0.500022828578949,
  -0.49042820930480957, -0.4777515232563019, -0.47794246673583984,
  -0.4846097528934479, -0.4822375178337097, -0.47846370935440063,
  -0.47728225588798523, -0.46628716588020325, -0.45276960730552673,
  -0.4529571533203125, -0.45852065086364746, -0.457821786403656,
  -0.44762107729911804, -0.4157884120941162, -0.3637032210826874,
  -0.3201145827770233, -0.29632848501205444, -0.2682758569717407,
  -0.22029682993888855, -0.17053815722465515, -0.14261288940906525,
  -0.132072314620018, -0.1200513169169426, -0.11092791706323624,
  -0.1189252957701683, -0.13045434653759003, -0.12847009301185608,
  -0.12439347058534622, -0.1242344006896019, -0.11086276173591614,
  -0.0804557278752327, -0.04670686274766922, -0.01306226197630167,
  0.02167270891368389, 0.05225697159767151, 0.07819631695747375,
  0.10229772329330444, 0.12165500223636627, 0.13336056470870972,
  0.13885246217250824, 0.14244717359542847, 0.14998820424079895,
  0.16350990533828735, 0.18076585233211517, 0.20166409015655518,
  0.2239333987236023, 0.24037499725818634, 0.25201305747032166,
  0.2671236991882324, 0.2827596068382263, 0.2893265187740326,
  0.2885619103908539, 0.2858826816082001, 0.27830010652542114,
  0.26768508553504944, 0.2679646909236908, 0.2845802307128906,
  0.3023422360420227, 0.30572840571403503, 0.29941585659980774,
  0.2977226674556732, 0.3025321662425995, 0.3039341866970062,
  0.2970826029777527, 0.2842709720134735, 0.2649368345737457,
  0.2390274554491043, 0.21502511203289032, 0.2021436095237732,
  0.2009962797164917, 0.20645064115524292, 0.21248386800289154,
  0.21676111221313477, 0.22480887174606323, 0.2412315458059311,
  0.25723859667778015, 0.2593158781528473, 0.24752095341682434,
  0.234169602394104, 0.22686663269996643, 0.22047007083892822,
  0.206678107380867, 0.18660496175289154, 0.16829708218574524,
  0.1554669737815857, 0.14678242802619934, 0.1417810171842575,
  0.13905292749404907, 0.13363346457481384, 0.12293572723865509,
  0.1124449372291565, 0.10989915579557419, 0.1143941730260849,
  0.11795372515916824, 0.11667747795581818, 0.11228133738040924,
  0.10706853866577148, 0.10489081591367722, 0.10970741510391235,
  0.11999975889921188, 0.13089928030967712, 0.13701388239860535,
  0.1337527632713318, 0.12678931653499603, 0.12859714031219482,
  0.136233389377594, 0.13386601209640503, 0.11893066018819809,
  0.1025690957903862, 0.09230218082666397, 0.08992277830839157,
  0.09288813173770905, 0.09497801214456558, 0.09563855081796646,
  0.10007583349943161, 0.11005592346191406, 0.126161590218544,
  0.14760318398475647, 0.16445456445217133, 0.16790880262851715,
  0.16447226703166962, 0.16489113867282867, 0.16824032366275787,
  0.16563794016838074, 0.15305210649967194, 0.13635285198688507,
  0.12375835329294205, 0.11774314194917679, 0.11946471035480499,
  0.13058581948280334, 0.14504502713680267, 0.15425536036491394,
  0.15914496779441833, 0.1636744886636734, 0.16796377301216125,
  0.1738477647304535, 0.18079306185245514, 0.18132425844669342,
  0.17430205643177032, 0.1689501404762268, 0.16938500106334686,
  0.1708318293094635, 0.17031896114349365, 0.16915331780910492,
  0.16833864152431488, 0.16770584881305695, 0.16736885905265808,
  0.1672828197479248, 0.16500972211360931, 0.1571865826845169,
  0.14450760185718536, 0.13143335282802582, 0.12202740460634232,
  0.11842010915279388, 0.11932036280632019, 0.12016190588474274,
  0.11919098347425461, 0.11955922096967697, 0.12312489748001099,
  0.12824280560016632, 0.13197822868824005, 0.1296512335538864,
  0.11785724759101868, 0.1010773777961731, 0.08850718289613724,
  0.08177074044942856, 0.07299650460481644, 0.05821700394153595,
  0.04496083781123161, 0.041410405188798904, 0.04638400301337242,
  0.05543503910303116, 0.06556858122348785, 0.07080399245023727,
  0.06657110899686813, 0.058287180960178375, 0.054347485303878784,
  0.05530513450503349, 0.05621479079127312, 0.052218686789274216,
  0.04202580451965332, 0.03194450959563255, 0.030180245637893677,
  0.034981634467840195, 0.037897609174251556, 0.036949679255485535,
  0.0369635671377182, 0.040919579565525055, 0.04829693213105202,
  0.0564623661339283, 0.06055254489183426, 0.05882880836725235,
  0.05626576021313667, 0.05654739588499069, 0.05775074660778046,
  0.060071080923080444, 0.06661688536405563, 0.07487889379262924,
  0.07974493503570557, 0.08189757168292999, 0.08366348594427109,
  0.08470242470502853, 0.08514073491096497, 0.08441576361656189,
  0.08075720071792603, 0.07596587389707565, 0.07225244492292404,
  0.06690647453069687, 0.059669774025678635, 0.05660461261868477,
  0.06011234596371651, 0.06617996841669083, 0.07122551649808884,
  0.07260196655988693, 0.06908774375915527, 0.06514872610569,
  0.06545516103506088, 0.06683247536420822, 0.06387446075677872,
  0.05745416134595871, 0.050983257591724396, 0.04306882247328758,
  0.030756192281842232, 0.01821988634765148, 0.014148514717817307,
  0.01841709017753601, 0.020858412608504295, 0.01615617796778679,
  0.008598729968070984, 0.001215758384205401, -0.006641052663326263,
  -0.012830162420868874, -0.01542454119771719, -0.01764213852584362,
  -0.022373665124177933, -0.02900306135416031, -0.037352412939071655,
  -0.046586815267801285, -0.05334804207086563, -0.05708252638578415,
  -0.06084904074668884, -0.06635791063308716, -0.0739155188202858,
  -0.08217711001634598, -0.08720415085554123, -0.08970487862825394,
  -0.0950804054737091, -0.10056142508983612, -0.10047398507595062,
  -0.10219752043485641, -0.11235835403203964, -0.1208517998456955,
  -0.12042855471372604, -0.11839718371629715, -0.11805709451436996,
  -0.11635971069335938, -0.11725650727748871, -0.1241263896226883,
  -0.13119091093540192, -0.1345539391040802, -0.1365496963262558,
  -0.13831867277622223, -0.1392117440700531, -0.14025837182998657,
  -0.1458960920572281, -0.15933653712272644, -0.17307256162166595,
  -0.1776658296585083, -0.17964792251586914, -0.18769071996212006,
  -0.19429896771907806, -0.1960686892271042, -0.20361566543579102,
  -0.21496228873729706, -0.21638160943984985, -0.21113747358322144,
  -0.21181000769138336, -0.216237410902977, -0.2179323434829712,
  -0.2215975522994995, -0.2311221808195114, -0.23864178359508514,
  -0.23824061453342438, -0.23943814635276794, -0.25255444645881653,
  -0.27024003863334656, -0.2820203900337219, -0.2911354899406433,
  -0.29975008964538574, -0.3033725321292877, -0.3074066638946533,
  -0.3179421126842499, -0.32619133591651917, -0.32760438323020935,
  -0.3293682336807251, -0.3307419717311859, -0.3296222984790802,
  -0.3337162137031555, -0.340421199798584, -0.33938759565353394,
  -0.33881059288978577, -0.3490491211414337, -0.35907483100891113,
  -0.3622204661369324, -0.3703790605068207, -0.3853502869606018,
  -0.39435797929763794, -0.3964221775531769, -0.40087762475013733,
  -0.40591657161712646, -0.40363213419914246, -0.397735595703125,
  -0.39497241377830505, -0.38801124691963196, -0.3683145344257355,
  -0.3455284535884857, -0.3321293592453003, -0.3228413164615631,
  -0.3087296783924103, -0.29450759291648865, -0.286853551864624,
  -0.28106021881103516, -0.27133193612098694, -0.265224426984787,
  -0.2718869745731354, -0.2793039083480835, -0.2704465091228485,
  -0.2568243741989136, -0.2565177083015442, -0.2567896246910095,
  -0.24419966340065002, -0.2344190627336502, -0.2337571084499359,
  -0.2260628491640091, -0.21515880525112152, -0.21683722734451294,
  -0.21747395396232605, -0.19947977364063263, -0.17887075245380402,
  -0.1731751561164856, -0.1717662513256073, -0.1639004498720169,
  -0.1555681675672531, -0.1479167491197586, -0.13442224264144897,
  -0.11961091309785843, -0.11142992973327637, -0.1039852723479271,
  -0.090898796916008, -0.07882620394229889, -0.07169455289840698,
  -0.06432756036520004, -0.05592481046915054, -0.04799893498420715,
  -0.03858412802219391, -0.029610514640808105, -0.021504608914256096,
  -0.008414730429649353, 0.004377986304461956, 0.005244935862720013,
  0.0034891432151198387, 0.014942287467420101, 0.03347553685307503,
  0.048936523497104645, 0.06362389028072357, 0.07374390214681625,
  0.0722075030207634, 0.06980205327272415, 0.08139128237962723,
  0.10225057601928711, 0.11757742613554001, 0.12205975502729416,
  0.12184233963489532, 0.1260751634836197, 0.13881073892116547,
  0.15593230724334717, 0.17010188102722168, 0.17856894433498383,
  0.1857631802558899, 0.19632293283939362, 0.20808476209640503,
  0.2173168957233429, 0.2247919887304306, 0.2317865639925003,
  0.23856382071971893, 0.24793586134910583, 0.26111656427383423,
  0.2728845775127411, 0.2770938277244568, 0.274650901556015,
  0.27427154779434204, 0.28327980637550354, 0.297623872756958,
  0.30816537141799927, 0.3133537769317627, 0.3153310716152191,
  0.31358468532562256, 0.31266117095947266, 0.3203310966491699,
  0.3339584469795227, 0.3435635566711426, 0.3448706567287445,
  0.34084171056747437, 0.3381651043891907, 0.34254807233810425,
  0.35043954849243164, 0.352358341217041, 0.34760114550590515,
  0.34366583824157715, 0.34219563007354736, 0.3392910063266754,
  0.3369382917881012, 0.34023118019104004, 0.3462996184825897,
  0.3487344980239868, 0.3482751250267029, 0.3479917049407959,
  0.3460688591003418, 0.34239867329597473, 0.3413343131542206,
  0.3427685499191284, 0.34246397018432617, 0.3398483991622925,
  0.33607515692710876, 0.331245481967926, 0.3262294828891754,
  0.3203601539134979, 0.3129100501537323, 0.30880019068717957,
  0.3102649450302124, 0.3080407977104187, 0.2956966161727905,
  0.27993446588516235, 0.26699098944664, 0.2569921910762787, 0.2517862915992737,
  0.24989911913871765, 0.24401065707206726, 0.2359335571527481,
  0.23527343571186066, 0.24006184935569763, 0.240909606218338,
  0.23785190284252167, 0.23409029841423035, 0.22685213387012482,
  0.2164916694164276, 0.20831967890262604, 0.20189015567302704,
  0.19271495938301086, 0.1813412606716156, 0.1711207777261734,
  0.16328296065330505, 0.1570528745651245, 0.14995524287223816,
  0.14138180017471313, 0.13657160103321075, 0.1392589658498764,
  0.1430678814649582, 0.1402362734079361, 0.13312272727489471,
  0.1273452788591385, 0.12215060740709305, 0.11613496392965317,
  0.11224444210529327, 0.1109522357583046, 0.10873081535100937,
  0.10509520769119263, 0.10154158622026443, 0.09722328186035156,
  0.0923931747674942, 0.0886203870177269, 0.08436925709247589,
  0.07840530574321747, 0.0724746361374855, 0.06521221250295639,
  0.05371445044875145, 0.04210814833641052, 0.036857958883047104,
  0.0371810682117939, 0.038527362048625946, 0.03939231485128403,
  0.040834054350852966, 0.04375525936484337, 0.04692937806248665,
  0.04799053445458412, 0.047168850898742676, 0.04598556458950043,
  0.04365738108754158, 0.04075193032622337, 0.04114646092057228,
  0.044758956879377365, 0.04656374454498291, 0.044499654322862625,
  0.04034571349620819, 0.03663322329521179, 0.036529604345560074,
  0.039449386298656464, 0.03965016081929207, 0.036560025066137314,
  0.0366930328309536, 0.04066387936472893, 0.04271989315748215,
  0.04287968575954437, 0.044590357691049576, 0.04619476944208145,
  0.04604220390319824, 0.04658407345414162, 0.04766249656677246,
  0.04583723843097687, 0.04105363413691521, 0.036319904029369354,
  0.0337616391479969, 0.03436535596847534, 0.03719663619995117,
  0.03922271355986595, 0.039185360074043274, 0.03854485601186752,
  0.03758416324853897, 0.03590437397360802, 0.035816725343465805,
  0.03872937336564064, 0.04092248156666756, 0.03943642973899841,
  0.036429788917303085, 0.03251224383711815, 0.026451148092746735,
  0.02285444736480713, 0.024704158306121826, 0.024288291111588478,
  0.01796106994152069, 0.013195153325796127, 0.010125413537025452,
  0.0019936468452215195, -0.006260434165596962, -0.007174691651016474,
  -0.0056908451952040195, -0.006404748652130365, -0.007410530000925064,
  -0.010415739379823208, -0.015877969563007355, -0.01839371956884861,
  -0.017588557675480843, -0.016924075782299042, -0.016068430617451668,
  -0.01790214329957962, -0.02831478789448738, -0.04461146146059036,
  -0.05870551988482475, -0.06670235097408295, -0.06907467544078827,
  -0.06968864798545837, -0.07299680262804031, -0.07934948801994324,
  -0.08664427697658539, -0.09171299636363983, -0.09153974801301956,
  -0.08960851281881332, -0.09173742681741714, -0.0955391526222229,
  -0.09806662797927856, -0.10317887365818024, -0.11178416758775711,
  -0.12200010567903519, -0.13557656109333038, -0.1488511860370636,
  -0.154739111661911, -0.15825994312763214, -0.1670762151479721,
  -0.1771678328514099, -0.1855623722076416, -0.19555187225341797,
  -0.20384518802165985, -0.20543688535690308, -0.20605674386024475,
  -0.2126365303993225, -0.22286128997802734, -0.23276594281196594,
  -0.2425519824028015, -0.25188392400741577, -0.25777101516723633,
  -0.26040804386138916, -0.2665245831012726, -0.28013452887535095,
  -0.2936338186264038, -0.2995208203792572, -0.30460208654403687,
  -0.31812065839767456, -0.3343523144721985, -0.34339600801467896,
  -0.348266065120697, -0.3541524410247803, -0.35632359981536865,
  -0.35565996170043945, -0.3641057014465332, -0.3808273375034332,
  -0.39010506868362427, -0.38978591561317444, -0.3919737637042999,
  -0.39960116147994995, -0.4075946509838104, -0.4147491753101349,
  -0.4217296838760376, -0.4315418601036072, -0.44762128591537476,
  -0.46284574270248413, -0.4686461091041565, -0.4728292226791382,
  -0.483523964881897, -0.4908899664878845, -0.4882664382457733,
  -0.4867868721485138, -0.4923171401023865, -0.49633607268333435,
  -0.49590951204299927, -0.49446552991867065, -0.487874835729599,
  -0.4754149913787842, -0.4688332676887512, -0.4708744287490845,
  -0.4696024954319, -0.46240681409835815, -0.4554186761379242,
  -0.4462084174156189, -0.43556517362594604, -0.4330269992351532,
  -0.4329805076122284, -0.4208732545375824, -0.4061792194843292,
  -0.4080430567264557, -0.41777321696281433, -0.41557931900024414,
  -0.4064538776874542, -0.4035259485244751, -0.3981735408306122,
  -0.3813588619232178, -0.3680858016014099, -0.3649892807006836,
  -0.3498298227787018, -0.31738734245300293, -0.29507333040237427,
  -0.2888967990875244, -0.27672067284584045, -0.25810736417770386,
  -0.2455560714006424, -0.23012028634548187, -0.2082628458738327,
  -0.19804821908473969, -0.19995136559009552, -0.19277562201023102,
  -0.17117244005203247, -0.14695607125759125, -0.1275613009929657,
  -0.11368723213672638, -0.10220559686422348, -0.08873827755451202,
  -0.07298494130373001, -0.05662824958562851, -0.04020119830965996,
  -0.025165505707263947, -0.009925696067512035, 0.011878046207129955,
  0.042068254202604294, 0.07358153164386749, 0.09637907147407532,
  0.10610100626945496, 0.11072532087564468, 0.12281050533056259,
  0.14176002144813538, 0.15571872889995575, 0.16030912101268768,
  0.16177107393741608, 0.16471558809280396, 0.17160195112228394,
  0.18852566182613373, 0.21585151553153992, 0.24033643305301666,
  0.25225916504859924, 0.2593182921409607, 0.27024033665657043,
  0.283147931098938, 0.29807332158088684, 0.31601688265800476,
  0.3285507261753082, 0.33134129643440247, 0.33419838547706604,
  0.3447210490703583, 0.3614589273929596, 0.38033413887023926,
  0.39251095056533813, 0.39249494671821594, 0.3915848135948181,
  0.4011255204677582, 0.41293829679489136, 0.4156494736671448,
  0.4132968783378601, 0.4143102467060089, 0.41925182938575745,
  0.42725974321365356, 0.4396573603153229, 0.4510791599750519,
  0.45253661274909973, 0.44896072149276733, 0.4538321793079376,
  0.46482163667678833, 0.4688352644443512, 0.4640274941921234,
  0.4546380937099457, 0.4416247308254242, 0.43093690276145935,
  0.4291436970233917, 0.4317123293876648, 0.43263107538223267,
  0.43278560042381287, 0.4332784414291382, 0.43500185012817383,
  0.4396686553955078, 0.4448690116405487, 0.44620004296302795,
  0.4417133629322052, 0.43314129114151, 0.4274553954601288, 0.42851030826568604,
  0.4297041893005371, 0.42666006088256836, 0.42249736189842224,
  0.41567331552505493, 0.40445712208747864, 0.3952156603336334,
  0.38838809728622437, 0.37664297223091125, 0.36218178272247314,
  0.3519076704978943, 0.3435526192188263, 0.3341965973377228,
  0.3273862302303314, 0.3241088092327118, 0.3207639753818512,
  0.3165164887905121, 0.31414705514907837, 0.3135907053947449,
  0.30953097343444824, 0.299543559551239, 0.2876870334148407, 0.275246262550354,
  0.2607188820838928, 0.24839115142822266, 0.24075189232826233,
  0.23219022154808044, 0.22135686874389648, 0.21260252594947815,
  0.2052440047264099, 0.19815599918365479, 0.19381290674209595,
  0.18979156017303467, 0.1820700466632843, 0.17378829419612885,
  0.1683121621608734, 0.16383954882621765, 0.15905755758285522,
  0.154541477560997, 0.1508835256099701, 0.14869247376918793,
  0.14673422276973724, 0.14285841584205627, 0.1370604932308197,
  0.12939314544200897, 0.11843126267194748, 0.1037483736872673,
  0.08626709878444672, 0.06863723695278168, 0.054437898099422455,
  0.043621763586997986, 0.034669164568185806, 0.02991717867553234,
  0.02962958626449108, 0.028578611090779305, 0.025432167574763298,
  0.023439161479473114, 0.021815501153469086, 0.01840732805430889,
  0.014632140286266804, 0.010339377447962761, 0.0034276540391147137,
  -0.004236632492393255, -0.009324247017502785, -0.012115408666431904,
  -0.013913813978433609, -0.014813008718192577, -0.015339121222496033,
  -0.01631736196577549, -0.018476566299796104, -0.022904813289642334,
  -0.02866911143064499, -0.031808897852897644, -0.030677439644932747,
  -0.028968922793865204, -0.02963525988161564, -0.0307354386895895,
  -0.030415352433919907, -0.029901990666985512, -0.02991265244781971,
  -0.030141767114400864, -0.03099735639989376, -0.03190081939101219,
  -0.032301951199769974, -0.03473037853837013, -0.04030318185687065,
  -0.045080482959747314, -0.047701820731163025, -0.05261817201972008,
  -0.060493871569633484, -0.06541917473077774, -0.06412914395332336,
  -0.05865701287984848, -0.05039050057530403, -0.04020962119102478,
  -0.032155685126781464, -0.02906731516122818, -0.027679583057761192,
  -0.024905137717723846, -0.024323929101228714, -0.029361730441451073,
  -0.03632572293281555, -0.04163418337702751, -0.04745370149612427,
  -0.05369209870696068, -0.05603299289941788, -0.05554227903485298,
  -0.05618612840771675, -0.055209651589393616, -0.0502999983727932,
  -0.04621298983693123, -0.04417422041296959, -0.04043268412351608,
  -0.03731120377779007, -0.03871636465191841, -0.04097681865096092,
  -0.041068676859140396, -0.04178859293460846, -0.043697524815797806,
  -0.04568450152873993, -0.05045202374458313, -0.05982951819896698,
  -0.07119564712047577, -0.08128692209720612, -0.0887572318315506,
  -0.09449802339076996, -0.09974907338619232, -0.10399903357028961,
  -0.10772379487752914, -0.11204265803098679, -0.11406052112579346,
  -0.1114811822772026, -0.10727285593748093, -0.10220236331224442,
  -0.09482332319021225, -0.09040765464305878, -0.09380144625902176,
  -0.10116628557443619, -0.10992240160703659, -0.12109813094139099,
  -0.13115791976451874, -0.13846291601657867, -0.14832046627998352,
  -0.16115890443325043, -0.17207248508930206, -0.18188244104385376,
  -0.1924298107624054, -0.19965514540672302, -0.20257368683815002,
  -0.20659053325653076, -0.2130933552980423, -0.218039870262146,
  -0.221139594912529, -0.22671717405319214, -0.23631812632083893,
  -0.2459261119365692, -0.25150060653686523, -0.2555149495601654,
  -0.2649517357349396, -0.2800290286540985, -0.2916516959667206,
  -0.29770225286483765, -0.3080548942089081, -0.3221382200717926,
  -0.32693177461624146, -0.3269415497779846, -0.3383927643299103,
  -0.3523004353046417, -0.3506207764148712, -0.3457348346710205,
  -0.3552321791648865, -0.3668890595436096, -0.36935746669769287,
  -0.3753047585487366, -0.3878973126411438, -0.3919445276260376,
  -0.38988929986953735, -0.4005689024925232, -0.42114323377609253,
  -0.43150386214256287, -0.4323475956916809, -0.44073498249053955,
  -0.453794926404953, -0.45645657181739807, -0.45545393228530884,
  -0.46399715542793274, -0.47168201208114624, -0.46905070543289185,
  -0.46842706203460693, -0.47075748443603516, -0.4610100984573364,
  -0.4477491080760956, -0.44782087206840515, -0.4459834098815918,
  -0.42833849787712097, -0.41508057713508606, -0.41385453939437866,
  -0.4032254219055176, -0.3863351047039032, -0.38501179218292236,
  -0.38554880023002625, -0.3695267140865326, -0.36290091276168823,
  -0.3815807104110718, -0.3898310661315918, -0.3713330626487732,
  -0.36120057106018066, -0.36930373311042786, -0.3639710247516632,
  -0.34447619318962097, -0.335960328578949, -0.32640355825424194,
  -0.2935241162776947, -0.2585704028606415, -0.24587395787239075,
  -0.23852403461933136, -0.218758225440979, -0.1974603682756424,
  -0.18244458734989166, -0.16745738685131073, -0.15390071272850037,
  -0.14766064286231995, -0.14359182119369507, -0.13277855515480042,
  -0.11450886726379395, -0.09509260207414627, -0.08173221349716187,
  -0.07523097097873688, -0.06807225942611694, -0.05418363958597183,
  -0.03531050309538841, -0.01586279086768627, 0.0027846202719956636,
  0.02250763215124607, 0.04600800946354866, 0.07373649626970291,
  0.10292478650808334, 0.12869779765605927, 0.14604894816875458,
  0.15559567511081696, 0.16535340249538422, 0.17870113253593445,
  0.18795566260814667, 0.1875273436307907, 0.18223248422145844,
  0.17789429426193237, 0.17891468107700348, 0.19244785606861115,
  0.21714241802692413, 0.23818999528884888, 0.24922308325767517,
  0.26020264625549316, 0.27555128931999207, 0.29046234488487244,
  0.3072122633457184, 0.3266703188419342, 0.3376530408859253,
  0.33766987919807434, 0.33949318528175354, 0.34665337204933167,
  0.3536427617073059, 0.3635355532169342, 0.3760022222995758,
  0.3802485167980194, 0.3781144320964813, 0.3824751675128937,
  0.3907153010368347, 0.3920254111289978, 0.38879895210266113,
  0.38847142457962036, 0.3921109735965729, 0.39976054430007935,
  0.4112067222595215, 0.42352089285850525, 0.43446817994117737,
  0.4417808949947357, 0.44331496953964233, 0.4430708587169647,
  0.4458027184009552, 0.44661980867385864, 0.4390931725502014,
  0.42697617411613464, 0.4166368544101715, 0.4077536165714264,
  0.3988611400127411, 0.39256590604782104, 0.39097967743873596,
  0.3927706480026245, 0.39632779359817505, 0.4015892744064331,
  0.4075762629508972, 0.4108271598815918, 0.4092371463775635,
  0.4060007631778717, 0.4056749939918518, 0.4071609675884247,
  0.40538668632507324, 0.39902463555336, 0.39104655385017395,
  0.3832951784133911, 0.3754948079586029, 0.3675244450569153,
  0.35898298025131226, 0.3486465811729431, 0.33683276176452637,
  0.32607290148735046, 0.3176354169845581, 0.3097393214702606,
  0.3015398681163788, 0.2963024079799652, 0.29653623700141907,
  0.29884591698646545, 0.29865366220474243, 0.29596781730651855,
  0.2924005389213562, 0.2876761853694916, 0.2810082733631134,
  0.2721448540687561, 0.2606077194213867, 0.24561507999897003,
  0.22754743695259094, 0.20988452434539795, 0.1967981904745102,
  0.18815453350543976, 0.1815258264541626, 0.17653235793113708,
  0.172212615609169, 0.16633501648902893, 0.15973250567913055,
  0.15478983521461487, 0.1509351283311844, 0.14696496725082397,
  0.14363397657871246, 0.14148841798305511, 0.13987840712070465,
  0.13739745318889618, 0.1325487494468689, 0.12561911344528198,
  0.11793997138738632, 0.10963461548089981, 0.09987545758485794,
  0.08664577454328537, 0.06861221790313721, 0.04936550557613373,
  0.033954281359910965, 0.023020349442958832, 0.015268306247889996,
  0.009278494864702225, 0.002646310953423381, -0.0034034019336104393,
  -0.004111286718398333, 0.00025568687124177814, 0.00508071156218648,
  0.007920319214463234, 0.007268317975103855, 0.0022812492679804564,
  -0.005598229356110096, -0.015997428447008133, -0.0278557650744915,
  -0.03631952032446861, -0.03923102095723152, -0.03933851048350334,
  -0.038796231150627136, -0.04000759497284889, -0.045483674854040146,
  -0.052453115582466125, -0.05661185085773468, -0.05732875317335129,
  -0.053995825350284576, -0.04760400950908661, -0.0431477390229702,
  -0.04204179719090462, -0.041461024433374405, -0.04044579342007637,
  -0.036905571818351746, -0.02964782901108265, -0.025948869064450264,
  -0.031210124492645264, -0.039049938321113586, -0.044012270867824554,
  -0.04900271072983742, -0.055840812623500824, -0.06359755247831345,
  -0.072356216609478, -0.07919283211231232, -0.07896209508180618,
  -0.07131481915712357, -0.059495192021131516, -0.04601623862981796,
  -0.034028731286525726, -0.026578612625598907, -0.022759830579161644,
  -0.020310495048761368, -0.020058752968907356, -0.022338371723890305,
  -0.024193888530135155, -0.02595667727291584, -0.0325465090572834,
  -0.04361030086874962, -0.05227000266313553, -0.05498438701033592,
  -0.05414286255836487, -0.05334242805838585, -0.05431250110268593,
  -0.054688967764377594, -0.05059588700532913, -0.04327822104096413,
  -0.03689134865999222, -0.0321408249437809, -0.027639562264084816,
  -0.02263684757053852, -0.01781417429447174, -0.016883404925465584,
  -0.02297956869006157, -0.03344818577170372, -0.04324328899383545,
  -0.05005817487835884, -0.05538993328809738, -0.0636078342795372,
  -0.07552941143512726, -0.0864538922905922, -0.09548137336969376,
  -0.10470279306173325, -0.1092839315533638, -0.10506327450275421,
  -0.09859760850667953, -0.09556382149457932, -0.09275715053081512,
  -0.08809980750083923, -0.08497817069292068, -0.08618411421775818,
  -0.0903497114777565, -0.09442147612571716, -0.09938491135835648,
  -0.10753599554300308, -0.1155935674905777, -0.12265853583812714,
  -0.134945347905159, -0.15128964185714722, -0.1637924760580063,
  -0.174059197306633, -0.18613596260547638, -0.19598090648651123,
  -0.2029874473810196, -0.209375262260437, -0.2100292444229126,
  -0.20539766550064087, -0.20578651130199432, -0.2114744931459427,
  -0.21542468667030334, -0.22044353187084198, -0.22911381721496582,
  -0.2344389408826828, -0.23757237195968628, -0.2510841488838196,
  -0.27377134561538696, -0.2881295680999756, -0.2919687330722809,
  -0.30167442560195923, -0.3160623013973236, -0.3180357813835144,
  -0.31505703926086426, -0.3268096446990967, -0.3409940302371979,
  -0.3370457589626312, -0.33004486560821533, -0.33994603157043457,
  -0.3525502383708954, -0.3510226309299469, -0.34856200218200684,
  -0.3607756197452545, -0.37581005692481995, -0.3779332935810089,
  -0.3793420195579529, -0.39670050144195557, -0.4153940677642822,
  -0.4174625873565674, -0.4182671904563904, -0.4332009553909302,
  -0.445972204208374, -0.44452980160713196, -0.44263556599617004,
  -0.4465583264827728, -0.4452937841415405, -0.4378966987133026,
  -0.43462565541267395, -0.4354366958141327, -0.4319469928741455,
  -0.42568010091781616, -0.42452573776245117, -0.4231433570384979,
  -0.41069233417510986, -0.39545702934265137, -0.39000409841537476,
  -0.384052574634552, -0.36859118938446045, -0.3584735691547394,
  -0.3577798306941986, -0.3479500412940979, -0.33268654346466064,
  -0.33659815788269043, -0.35144859552383423, -0.34859615564346313,
  -0.33653587102890015, -0.3395332098007202, -0.3434211313724518,
  -0.3268592357635498, -0.30668905377388, -0.29710111021995544,
  -0.27757182717323303, -0.24150671064853668, -0.21509093046188354,
  -0.20814567804336548, -0.20347796380519867, -0.19222475588321686,
  -0.17691685259342194, -0.15786592662334442, -0.1390051692724228,
  -0.12691858410835266, -0.12048650532960892, -0.11337944120168686,
  -0.1003909483551979, -0.08176888525485992, -0.06537745147943497,
  -0.05615132302045822, -0.04789344593882561, -0.03504128381609917,
  -0.020313803106546402, -0.0064850361086428165, 0.007468346506357193,
  0.023284627124667168, 0.04296189919114113, 0.06862423568964005,
  0.10005713254213333, 0.1302349716424942, 0.14792682230472565,
  0.1523643583059311, 0.15569891035556793, 0.1642962098121643,
  0.17242522537708282, 0.17626704275608063, 0.17710460722446442,
  0.17507286369800568, 0.17230908572673798, 0.17640215158462524,
  0.1944914013147354, 0.22412435710430145, 0.25157180428504944,
  0.2657056748867035, 0.27104103565216064, 0.2792021334171295,
  0.2946057915687561, 0.31336510181427, 0.32718953490257263, 0.3312949240207672,
  0.3315521478652954, 0.33538541197776794, 0.3427969515323639,
  0.35297563672065735, 0.3643617331981659, 0.3703717291355133,
  0.3708633482456207, 0.3750733435153961, 0.38366374373435974,
  0.3871818482875824, 0.3831026554107666, 0.378412127494812, 0.3792237341403961,
  0.3857254683971405, 0.39429590106010437, 0.402664452791214,
  0.4109106957912445, 0.4179937541484833, 0.42311108112335205,
  0.42787131667137146, 0.43219414353370667, 0.4325314164161682,
  0.425315797328949, 0.4119698107242584, 0.40060222148895264,
  0.3957027494907379, 0.3914598226547241, 0.38631004095077515,
  0.38744014501571655, 0.39279407262802124, 0.39439523220062256,
  0.39584359526634216, 0.4013274908065796, 0.40492287278175354,
  0.40469083189964294, 0.4039490222930908, 0.4014776349067688,
  0.39725589752197266, 0.3936477601528168, 0.3885822296142578,
  0.382337361574173, 0.3793913722038269, 0.3767693340778351, 0.3686884343624115,
  0.3579048812389374, 0.34809061884880066, 0.33773311972618103,
  0.32635894417762756, 0.31563934683799744, 0.3063981533050537,
  0.29891374707221985, 0.2938772737979889, 0.29260629415512085,
  0.29476219415664673, 0.2967199385166168, 0.2958748936653137,
  0.2927456200122833, 0.2876933813095093, 0.2806536555290222,
  0.2715066075325012, 0.258470356464386, 0.2411184310913086,
  0.22176843881607056, 0.20237745344638824, 0.18678055703639984,
  0.17931245267391205, 0.17703235149383545, 0.17486020922660828,
  0.17313271760940552, 0.17109404504299164, 0.166987344622612,
  0.16408750414848328, 0.16284732520580292, 0.15840180218219757,
  0.15152493119239807, 0.1461414098739624, 0.14109684526920319,
  0.13584424555301666, 0.13224561512470245, 0.128916934132576,
  0.12390212714672089, 0.11654533445835114, 0.10575073212385178,
  0.09274962544441223, 0.0792616456747055, 0.06349639594554901,
  0.045710645616054535, 0.03042532689869404, 0.020063063129782677,
  0.01417467836290598, 0.011750446632504463, 0.01198869664222002,
  0.016337784007191658, 0.024363454431295395, 0.03074805997312069,
  0.0328974574804306, 0.030982356518507004, 0.021936573088169098,
  0.0074665481224656105, -0.003951657097786665, -0.011015345342457294,
  -0.017888253554701805, -0.023909272626042366, -0.02894236147403717,
  -0.034304309636354446, -0.036575816571712494, -0.03432316333055496,
  -0.03174242377281189, -0.030659658834338188, -0.030368687584996223,
  -0.03132164105772972, -0.03270507603883743, -0.03324493020772934,
  -0.032967835664749146, -0.03147104009985924, -0.029209936037659645,
  -0.02722558192908764, -0.025072414427995682, -0.0235650222748518,
  -0.023980433121323586, -0.025707989931106567, -0.03012959100306034,
  -0.03757122904062271, -0.04247137904167175, -0.04382069781422615,
  -0.048301901668310165, -0.05633081495761871, -0.0611235648393631,
  -0.0591011606156826, -0.051314979791641235, -0.04156920686364174,
  -0.03305906057357788, -0.02591286040842533, -0.02277737855911255,
  -0.025381451472640038, -0.02711818926036358, -0.026637058705091476,
  -0.031324706971645355, -0.03765317425131798, -0.038397446274757385,
  -0.040478482842445374, -0.04732502996921539, -0.05024109408259392,
  -0.048912134021520615, -0.0488940104842186, -0.04820147529244423,
  -0.04580223187804222, -0.04374668374657631, -0.04168406501412392,
  -0.041506338864564896, -0.043300189077854156, -0.041931141167879105,
  -0.038155511021614075, -0.035844411700963974, -0.03413800522685051,
  -0.03590266779065132, -0.04399382695555687, -0.05183786526322365,
  -0.05697231739759445, -0.06492189317941666, -0.07486803829669952,
  -0.08435660600662231, -0.09539305418729782, -0.10547041893005371,
  -0.11162963509559631, -0.11568040400743484, -0.11642991751432419,
  -0.11288224160671234, -0.10886795818805695, -0.10605834424495697,
  -0.1043422669172287, -0.10398483276367188, -0.10312391817569733,
  -0.10377562046051025, -0.10885924100875854, -0.11283723264932632,
  -0.11594367772340775, -0.12778733670711517, -0.1445537507534027,
  -0.15579046308994293, -0.16450345516204834, -0.17369487881660461,
  -0.1804846227169037, -0.1888803243637085, -0.19916637241840363,
  -0.20323528349399567, -0.2027619183063507, -0.2042689472436905,
  -0.20630265772342682, -0.20823511481285095, -0.21164721250534058,
  -0.21492166817188263, -0.21850091218948364, -0.22375313937664032,
  -0.2303175926208496, -0.2397845983505249, -0.251609206199646,
  -0.26308125257492065, -0.27556413412094116, -0.2875654995441437,
  -0.29560840129852295, -0.30398887395858765, -0.3123396039009094,
  -0.31408190727233887, -0.3161131739616394, -0.324087530374527,
  -0.32659226655960083, -0.3231188654899597, -0.3267439007759094,
  -0.3333509862422943, -0.3323255777359009, -0.3301223814487457,
  -0.33573994040489197, -0.34628069400787354, -0.35486456751823425,
  -0.3617875874042511, -0.373136967420578, -0.3839130699634552,
  -0.38494452834129333, -0.38910970091819763, -0.406232625246048,
  -0.4156951904296875, -0.41191986203193665, -0.4168117344379425,
  -0.42567339539527893, -0.420452356338501, -0.4140453636646271,
  -0.41691070795059204, -0.4162653386592865, -0.4107109308242798,
  -0.4073770344257355, -0.40477314591407776, -0.40431687235832214,
  -0.40345680713653564, -0.3942837119102478, -0.38525527715682983,
  -0.3826891779899597, -0.37160059809684753, -0.35165920853614807,
  -0.3431820869445801, -0.3436259925365448, -0.33240434527397156,
  -0.3148675560951233, -0.3159145712852478, -0.33127516508102417,
  -0.3284435570240021, -0.3099842667579651, -0.3125016689300537,
  -0.32478711009025574, -0.30681926012039185, -0.28176867961883545,
  -0.2832031548023224, -0.2734244167804718, -0.23194600641727448,
  -0.2078566551208496, -0.21327349543571472, -0.2067183405160904,
  -0.18587692081928253, -0.1741848737001419, -0.16325774788856506,
  -0.14300940930843353, -0.12773683667182922, -0.1253366470336914,
  -0.12259232252836227, -0.1061522513628006, -0.08326525241136551,
  -0.0694354772567749, -0.06021122261881828, -0.04513279348611832,
  -0.03306199982762337, -0.02725423127412796, -0.013136537745594978,
  0.009013027884066105, 0.026859942823648453, 0.04380550980567932,
  0.06725750863552094, 0.09427489340305328, 0.11900044977664948,
  0.13500146567821503, 0.1428873986005783, 0.15229655802249908,
  0.16111654043197632, 0.16091841459274292, 0.160405695438385,
  0.16603782773017883, 0.16520817577838898, 0.15683378279209137,
  0.15959347784519196, 0.17917370796203613, 0.20153647661209106,
  0.21727308630943298, 0.231472447514534, 0.2493194341659546,
  0.26439860463142395, 0.27393943071365356, 0.2884413003921509,
  0.30738309025764465, 0.31591302156448364, 0.3167019486427307,
  0.32431715726852417, 0.3350282311439514, 0.34011685848236084,
  0.3446601629257202, 0.35191723704338074, 0.3573552370071411,
  0.3592334985733032, 0.3577828109264374, 0.3548457622528076,
  0.3540410101413727, 0.35424819588661194, 0.35485032200813293,
  0.3610997498035431, 0.3723708391189575, 0.38166874647140503,
  0.38896700739860535, 0.39726144075393677, 0.4031183123588562,
  0.403080552816391, 0.3996410369873047, 0.39656487107276917,
  0.39232176542282104, 0.38231024146080017, 0.37010833621025085,
  0.3640160858631134, 0.3607431650161743, 0.3545433282852173,
  0.3534739315509796, 0.3619351387023926, 0.3683827221393585,
  0.3678245544433594, 0.36867421865463257, 0.3725043535232544,
  0.37310007214546204, 0.37113070487976074, 0.3713720738887787,
  0.3720179796218872, 0.36808669567108154, 0.36124613881111145,
  0.3569321632385254, 0.3544985055923462, 0.3501291871070862,
  0.34313270449638367, 0.33260494470596313, 0.31931713223457336,
  0.30793267488479614, 0.29739418625831604, 0.2843649983406067,
  0.2749747335910797, 0.27259576320648193, 0.2709166705608368,
  0.27073997259140015, 0.2752603590488434, 0.2768867611885071,
  0.27357712388038635, 0.2720527946949005, 0.2697063684463501,
  0.26254162192344666, 0.2542022168636322, 0.24294890463352203,
  0.2266111671924591, 0.21178314089775085, 0.19974955916404724,
  0.1872803270816803, 0.17880779504776, 0.17556898295879364, 0.1723780781030655,
  0.17021679878234863, 0.1708284616470337, 0.17120744287967682,
  0.1712154597043991, 0.1701403111219406, 0.16547046601772308,
  0.1613452285528183, 0.1608218252658844, 0.15907429158687592,
  0.15523290634155273, 0.15247106552124023, 0.14853908121585846,
  0.14014282822608948, 0.1278771311044693, 0.11445406824350357,
  0.10186278074979782, 0.08767542988061905, 0.07140882313251495,
  0.05929164960980415, 0.05184648185968399, 0.04460638761520386,
  0.04109906777739525, 0.042604416608810425, 0.04321734979748726,
  0.04586208239197731, 0.05296991392970085, 0.05430842936038971,
  0.04881981760263443, 0.04619830101728439, 0.04361376538872719,
  0.035721708089113235, 0.027359139174222946, 0.018643777817487717,
  0.0073129646480083466, -0.0014698023442178965, -0.006869188975542784,
  -0.012577190063893795, -0.01686718314886093, -0.018787292763590813,
  -0.019693035632371902, -0.01777413673698902, -0.013790556229650974,
  -0.01142856478691101, -0.010527529753744602, -0.009761626832187176,
  -0.00822129100561142, -0.004592405632138252, -0.0007136104395613074,
  0.0015918688150122762, 0.0020476188510656357, -0.0020158537663519382,
  -0.00877930037677288, -0.01311140600591898, -0.019985659047961235,
  -0.03335126116871834, -0.043907925486564636, -0.04856397211551666,
  -0.054124511778354645, -0.05828765407204628, -0.05702583119273186,
  -0.055563077330589294, -0.054765667766332626, -0.04862639680504799,
  -0.036708757281303406, -0.025286974385380745, -0.021258674561977386,
  -0.024362733587622643, -0.027048172429203987, -0.028455033898353577,
  -0.03272790461778641, -0.03546416759490967, -0.03478674218058586,
  -0.03934210538864136, -0.04936675354838371, -0.054664622992277145,
  -0.05292355641722679, -0.04999089613556862, -0.050163932144641876,
  -0.05286410450935364, -0.05370916798710823, -0.05224543437361717,
  -0.051563337445259094, -0.049788352102041245, -0.04681917279958725,
  -0.04731903225183487, -0.04811844229698181, -0.04511984437704086,
  -0.045129887759685516, -0.051199689507484436, -0.05837331712245941,
  -0.06757562607526779, -0.07977219671010971, -0.08963243663311005,
  -0.09706543385982513, -0.10661015659570694, -0.11736178398132324,
  -0.12431377172470093, -0.12387953698635101, -0.11833617836236954,
  -0.11344878375530243, -0.10920850187540054, -0.10423339903354645,
  -0.10205543786287308, -0.101043701171875, -0.09685725718736649,
  -0.09453678876161575, -0.0982474684715271, -0.10456433892250061,
  -0.11350306868553162, -0.12518997490406036, -0.13518613576889038,
  -0.14490538835525513, -0.15678730607032776, -0.16648727655410767,
  -0.173763707280159, -0.18166252970695496, -0.1881989687681198,
  -0.19353726506233215, -0.1994878202676773, -0.20222358405590057,
  -0.20058129727840424, -0.19936521351337433, -0.1997864842414856,
  -0.20054027438163757, -0.20299184322357178, -0.20900525152683258,
  -0.21763509511947632, -0.2245471477508545, -0.230052188038826,
  -0.24165426194667816, -0.25737422704696655, -0.26713958382606506,
  -0.27485620975494385, -0.2876025438308716, -0.2967323064804077,
  -0.29738232493400574, -0.29785504937171936, -0.3010507822036743,
  -0.30362191796302795, -0.30454033613204956, -0.3046180009841919,
  -0.3073059618473053, -0.31217682361602783, -0.3141797184944153,
  -0.31839776039123535, -0.32988396286964417, -0.3390120267868042,
  -0.34474626183509827, -0.35746216773986816, -0.37123918533325195,
  -0.3777858018875122, -0.384785532951355, -0.39267492294311523,
  -0.3942919671535492, -0.39481037855148315, -0.39810848236083984,
  -0.3989405333995819, -0.3975900709629059, -0.3971809148788452,
  -0.3977207839488983, -0.3981133699417114, -0.3951859474182129,
  -0.39327192306518555, -0.39991018176078796, -0.40396398305892944,
  -0.39616042375564575, -0.3925045132637024, -0.39726340770721436,
  -0.39178407192230225, -0.3767773509025574, -0.3663083016872406,
  -0.35588186979293823, -0.34049785137176514, -0.3300129771232605,
  -0.32694604992866516, -0.31947728991508484, -0.30455800890922546,
  -0.2983483374118805, -0.3064972460269928, -0.3079441487789154,
  -0.2997186481952667, -0.30492711067199707, -0.3106381893157959,
  -0.28919124603271484, -0.26788991689682007, -0.27034899592399597,
  -0.2619298994541168, -0.2329016476869583, -0.21800728142261505,
  -0.21575118601322174, -0.20399314165115356, -0.1906801015138626,
  -0.18103577196598053, -0.16530843079090118, -0.1460716426372528,
  -0.12948843836784363, -0.11878696084022522, -0.1149490475654602,
  -0.1032709851861, -0.07703443616628647, -0.056457724422216415,
  -0.0485185831785202, -0.04034585878252983, -0.03289511054754257,
  -0.027621129527688026, -0.015595922246575356, 0.000041856150346575305,
  0.015223491005599499, 0.03530631214380264, 0.05571582540869713,
  0.0735781341791153, 0.09708744287490845, 0.119095578789711,
  0.12869058549404144, 0.13601158559322357, 0.14557072520256042,
  0.14805006980895996, 0.14767538011074066, 0.15063565969467163,
  0.1490616798400879, 0.14471565186977386, 0.1513666957616806,
  0.1693497747182846, 0.1874186098575592, 0.20200860500335693,
  0.21673233807086945, 0.22979561984539032, 0.2371412217617035,
  0.2463589757680893, 0.26581239700317383, 0.2846418619155884,
  0.2914595901966095, 0.29487359523773193, 0.3022512197494507,
  0.3093121349811554, 0.3158567547798157, 0.322936087846756, 0.3278956115245819,
  0.3327580392360687, 0.3377756178379059, 0.33840057253837585,
  0.33791840076446533, 0.34086814522743225, 0.343014657497406,
  0.34360799193382263, 0.34755373001098633, 0.3549398183822632,
  0.36274638772010803, 0.3681597411632538, 0.3704964518547058,
  0.3740849196910858, 0.3785769045352936, 0.3771671950817108,
  0.37210342288017273, 0.3693510591983795, 0.3648085296154022,
  0.356658399105072, 0.35081052780151367, 0.34785017371177673,
  0.3460792601108551, 0.3470405340194702, 0.34891149401664734,
  0.349179744720459, 0.3492436707019806, 0.3505352735519409, 0.3540477156639099,
  0.3583363890647888, 0.3598717749118805, 0.3619951903820038,
  0.36713629961013794, 0.3663690686225891, 0.35853299498558044,
  0.35498732328414917, 0.3547004163265228, 0.34885498881340027,
  0.33995360136032104, 0.3303547501564026, 0.31756916642189026,
  0.30575186014175415, 0.29783836007118225, 0.2909586727619171,
  0.285959929227829, 0.28312087059020996, 0.28152066469192505,
  0.2857612371444702, 0.29326868057250977, 0.2934983968734741,
  0.2884529232978821, 0.2850404977798462, 0.2798263430595398,
  0.2722770571708679, 0.26614880561828613, 0.25744742155075073,
  0.24463458359241486, 0.23291130363941193, 0.22211220860481262,
  0.21228086948394775, 0.20809148252010345, 0.20684055984020233,
  0.202968031167984, 0.19865380227565765, 0.196452796459198,
  0.19597472250461578, 0.19725243747234344, 0.1977851241827011,
  0.19591213762760162, 0.19500182569026947, 0.1960059553384781,
  0.1959107667207718, 0.19393356144428253, 0.1896316558122635,
  0.18122079968452454, 0.16826733946800232, 0.15242919325828552,
  0.13865487277507782, 0.12961849570274353, 0.11914192140102386,
  0.1038263663649559, 0.09052179008722305, 0.08253190666437149,
  0.07736112177371979, 0.07644572854042053, 0.07927214354276657,
  0.08111592382192612, 0.08204209059476852, 0.08303160965442657,
  0.0820479542016983, 0.07964570075273514, 0.07547128200531006,
  0.06720999628305435, 0.057953644543886185, 0.050480738282203674,
  0.04258318245410919, 0.03536469489336014, 0.02907119132578373,
  0.018615948036313057, 0.006996776442974806, 0.0029956663493067026,
  0.004620329011231661, 0.005407725460827351, 0.005405875854194164,
  0.005442231427878141, 0.0049735307693481445, 0.005012723617255688,
  0.006794387940317392, 0.011115392670035362, 0.015255670994520187,
  0.013776740059256554, 0.008984848856925964, 0.006341010797768831,
  0.0015082224272191525, -0.00870322436094284, -0.018446817994117737,
  -0.02769005298614502, -0.040199894458055496, -0.05063851922750473,
  -0.05561640113592148, -0.06063336133956909, -0.06559046357870102,
  -0.0647316575050354, -0.059382304549217224, -0.053789615631103516,
  -0.04687242954969406, -0.038580868393182755, -0.034124940633773804,
  -0.03471817076206207, -0.034728728234767914, -0.033505961298942566,
  -0.035829342901706696, -0.03948979452252388, -0.041635479778051376,
  -0.04863249137997627, -0.06093139946460724, -0.0690685585141182,
  -0.07208805531263351, -0.07428848743438721, -0.07400334626436234,
  -0.07328864932060242, -0.0756302922964096, -0.0744992196559906,
  -0.06727935373783112, -0.06176598742604256, -0.059036094695329666,
  -0.05508076772093773, -0.052163656800985336, -0.052306097000837326,
  -0.05508869141340256, -0.06139726564288139, -0.06966236978769302,
  -0.07854973524808884, -0.08920411020517349, -0.09927147626876831,
  -0.10726616531610489, -0.11571559309959412, -0.12297766655683517,
  -0.12704609334468842, -0.13030557334423065, -0.13082122802734375,
  -0.12605999410152435, -0.1200316995382309, -0.11420617252588272,
  -0.10857278108596802, -0.10740774869918823, -0.10861202329397202,
  -0.10736650973558426, -0.10825417190790176, -0.11312634497880936,
  -0.11800447851419449, -0.12603390216827393, -0.13802389800548553,
  -0.14800526201725006, -0.15755920112133026, -0.16813534498214722,
  -0.1746431291103363, -0.1799456775188446, -0.18675290048122406,
  -0.1880682110786438, -0.1864052563905716, -0.18950727581977844,
  -0.191626638174057, -0.18975771963596344, -0.19015347957611084,
  -0.19097208976745605, -0.19114205241203308, -0.19837021827697754,
  -0.21160253882408142, -0.22190365195274353, -0.22680392861366272,
  -0.2303074300289154, -0.23953595757484436, -0.2544971704483032,
  -0.26399707794189453, -0.2660997211933136, -0.2691621482372284,
  -0.2713949382305145, -0.27135249972343445, -0.27576467394828796,
  -0.28007733821868896, -0.2774880528450012, -0.2755046784877777,
  -0.27903205156326294, -0.2868744134902954, -0.29837894439697266,
  -0.3043871223926544, -0.3027932643890381, -0.3079085946083069,
  -0.31827473640441895, -0.3249799609184265, -0.3396930992603302,
  -0.3582881689071655, -0.35737818479537964, -0.35060280561447144,
  -0.3619966208934784, -0.37501806020736694, -0.37766095995903015,
  -0.3827778995037079, -0.38379111886024475, -0.372894823551178,
  -0.37031617760658264, -0.3830127418041229, -0.39118334650993347,
  -0.38506969809532166, -0.3736622631549835, -0.3719410300254822,
  -0.3848935067653656, -0.39684033393859863, -0.3952884078025818,
  -0.38586610555648804, -0.37113523483276367, -0.35403111577033997,
  -0.34712862968444824, -0.3470572829246521, -0.3398756682872772,
  -0.32736721634864807, -0.31163570284843445, -0.29687610268592834,
  -0.3030717968940735, -0.32168152928352356, -0.3167658746242523,
  -0.30059218406677246, -0.307009220123291, -0.313481867313385,
  -0.29675933718681335, -0.287463903427124, -0.29224202036857605,
  -0.2731710970401764, -0.23607990145683289, -0.2247646301984787,
  -0.23414252698421478, -0.225641667842865, -0.20045506954193115,
  -0.18361662328243256, -0.17164957523345947, -0.15486764907836914,
  -0.1477290540933609, -0.15074029564857483, -0.1370534896850586,
  -0.10509362816810608, -0.08174566924571991, -0.07170142233371735,
  -0.0628419890999794, -0.05571335554122925, -0.048595327883958817,
  -0.03378815948963165, -0.01750837452709675, -0.007472517900168896,
  0.0028948262333869934, 0.02132253162562847, 0.048350218683481216,
  0.0767035037279129, 0.09379010647535324, 0.10079985857009888,
  0.11299937963485718, 0.1321018934249878, 0.14596585929393768,
  0.15064486861228943, 0.15009896457195282, 0.14669838547706604,
  0.14040909707546234, 0.13413375616073608, 0.1398075520992279,
  0.1618812531232834, 0.18055571615695953, 0.18540850281715393,
  0.19795319437980652, 0.22519062459468842, 0.24377979338169098,
  0.2507949769496918, 0.26541873812675476, 0.28474006056785583,
  0.29346147179603577, 0.29470184445381165, 0.298260360956192,
  0.3043072521686554, 0.3084389269351959, 0.3090420961380005,
  0.3118760585784912, 0.32311201095581055, 0.3351166844367981,
  0.33574920892715454, 0.32991376519203186, 0.3318614065647125,
  0.3420011103153229, 0.34878960251808167, 0.3493889570236206,
  0.35225725173950195, 0.3590530753135681, 0.3622211217880249,
  0.36205679178237915, 0.3650628328323364, 0.3691522181034088,
  0.3695999085903168, 0.368644118309021, 0.36765968799591064,
  0.3642260432243347, 0.35798484086990356, 0.34892940521240234,
  0.33906739950180054, 0.33629706501960754, 0.3424593210220337,
  0.3472771942615509, 0.34480416774749756, 0.3417870104312897,
  0.3450395166873932, 0.3525315225124359, 0.35933035612106323,
  0.3650452792644501, 0.3705649971961975, 0.3721362352371216,
  0.3678221106529236, 0.3636235296726227, 0.3624417185783386,
  0.3581636846065521, 0.34771230816841125, 0.3347006142139435,
  0.32247981429100037, 0.31403225660324097, 0.3095483183860779,
  0.3035371005535126, 0.2951659560203552, 0.29061833024024963,
  0.28999581933021545, 0.28921568393707275, 0.2903125584125519,
  0.29343467950820923, 0.2924434542655945, 0.2874089777469635,
  0.2841593027114868, 0.2824379801750183, 0.2783171236515045,
  0.27105987071990967, 0.2607032060623169, 0.24783366918563843,
  0.23638193309307098, 0.22872093319892883, 0.2209235429763794,
  0.20984482765197754, 0.20056971907615662, 0.1979856640100479,
  0.19740398228168488, 0.19514483213424683, 0.19600097835063934,
  0.1996242105960846, 0.19907329976558685, 0.19670870900154114,
  0.19846606254577637, 0.19970586895942688, 0.19558510184288025,
  0.18879903852939606, 0.1805819720029831, 0.1711774617433548,
  0.16192343831062317, 0.1501639485359192, 0.13565896451473236,
  0.12403351813554764, 0.11412601917982101, 0.10102085769176483,
  0.08930765837430954, 0.08300098031759262, 0.076663076877594,
  0.06823023408651352, 0.062499359250068665, 0.06147224083542824,
  0.06284508109092712, 0.06311988830566406, 0.06061564013361931,
  0.056879013776779175, 0.05258788913488388, 0.04754191264510155,
  0.04302869364619255, 0.03701445087790489, 0.027208583429455757,
  0.017909551039338112, 0.010669606737792492, 0.0017888162983581424,
  -0.00611115712672472, -0.010178504511713982, -0.014227560721337795,
  -0.016635531559586525, -0.013111641630530357, -0.008338576182723045,
  -0.006371854338794947, -0.005617152433842421, -0.005929616745561361,
  -0.0056717307306826115, -0.004508226178586483, -0.00871634017676115,
  -0.018574198707938194, -0.028203435242176056, -0.03860674425959587,
  -0.04971381649374962, -0.057638246566057205, -0.06523554772138596,
  -0.07241566479206085, -0.07471474260091782, -0.07811899483203888,
  -0.0841960459947586, -0.08235775679349899, -0.0766974538564682,
  -0.07708391547203064, -0.07431340217590332, -0.0650143027305603,
  -0.061751022934913635, -0.06350692361593246, -0.062100138515233994,
  -0.06192830577492714, -0.06487300992012024, -0.06694246828556061,
  -0.07028459012508392, -0.07577032595872879, -0.08140965551137924,
  -0.08829417079687119, -0.09408876299858093, -0.09628937393426895,
  -0.09812209755182266, -0.09937524050474167, -0.09818147867918015,
  -0.09650806337594986, -0.09202639758586884, -0.08400966227054596,
  -0.07965946197509766, -0.07816818356513977, -0.07354602217674255,
  -0.0708765760064125, -0.07392338663339615, -0.0779494121670723,
  -0.08405537158250809, -0.09443951398134232, -0.10348856449127197,
  -0.10888057947158813, -0.11459431052207947, -0.12210951000452042,
  -0.12871132791042328, -0.1312878429889679, -0.1306002140045166,
  -0.12831541895866394, -0.12185805290937424, -0.1130940169095993,
  -0.10836494714021683, -0.10380081087350845, -0.09584488719701767,
  -0.09256283938884735, -0.0938803032040596, -0.09322698414325714,
  -0.0960293784737587, -0.10370675474405289, -0.10847297310829163,
  -0.11456745862960815, -0.12659169733524323, -0.13630658388137817,
  -0.14284764230251312, -0.1508873850107193, -0.155936598777771,
  -0.1567496508359909, -0.1574842929840088, -0.15756462514400482,
  -0.1581243872642517, -0.1601433902978897, -0.15968966484069824,
  -0.15864644944667816, -0.15965530276298523, -0.1596064269542694,
  -0.16267924010753632, -0.17238003015518188, -0.18064899742603302,
  -0.18613076210021973, -0.19404751062393188, -0.20185774564743042,
  -0.209872767329216, -0.22015641629695892, -0.22725605964660645,
  -0.23288653790950775, -0.24092799425125122, -0.24434879422187805,
  -0.2456619143486023, -0.2523309588432312, -0.2543017864227295,
  -0.25015541911125183, -0.254490464925766, -0.26269111037254333,
  -0.2636018693447113, -0.2650713324546814, -0.27244457602500916,
  -0.28102439641952515, -0.28923967480659485, -0.2975780963897705,
  -0.310001015663147, -0.32545730471611023, -0.3322255313396454,
  -0.3349857032299042, -0.3456982374191284, -0.35101839900016785,
  -0.34743326902389526, -0.3538450598716736, -0.36058497428894043,
  -0.35377946496009827, -0.3543594181537628, -0.3641965091228485,
  -0.36355817317962646, -0.3651340901851654, -0.37829139828681946,
  -0.38414865732192993, -0.3842296302318573, -0.38966628909111023,
  -0.3926781117916107, -0.39362043142318726, -0.3941071629524231,
  -0.38301512598991394, -0.3666814863681793, -0.35605961084365845,
  -0.3422032594680786, -0.32513517141342163, -0.31386950612068176,
  -0.30505290627479553, -0.29748713970184326, -0.2916244566440582,
  -0.2883819341659546, -0.29831230640411377, -0.3096930682659149,
  -0.3023742139339447, -0.30205824971199036, -0.32007521390914917,
  -0.31565046310424805, -0.2955071032047272, -0.2966950833797455,
  -0.28732678294181824, -0.24805597960948944, -0.22828711569309235,
  -0.23173046112060547, -0.21864712238311768, -0.1978033185005188,
  -0.18323689699172974, -0.16084013879299164, -0.1376929134130478,
  -0.12736931443214417, -0.12344144284725189, -0.11832879483699799,
  -0.10172399133443832, -0.07516907155513763, -0.06280606985092163,
  -0.06167782470583916, -0.049718644469976425, -0.03947625309228897,
  -0.039868663996458054, -0.03081454709172249, -0.014219355769455433,
  -0.0027296356856822968, 0.013542640022933483, 0.038238998502492905,
  0.06565442681312561, 0.09418928623199463, 0.11182936280965805,
  0.11784075200557709, 0.131831094622612, 0.14947623014450073,
  0.15422047674655914, 0.15412920713424683, 0.15384019911289215,
  0.14571672677993774, 0.13583384454250336, 0.13399644196033478,
  0.14608906209468842, 0.17153652012348175, 0.19118739664554596,
  0.2003636360168457, 0.22022202610969543, 0.24455590546131134,
  0.25607576966285706, 0.2719414532184601, 0.2954879105091095,
  0.30357301235198975, 0.3031344413757324, 0.3080208897590637,
  0.30804863572120667, 0.3074260652065277, 0.3145265281200409,
  0.317044198513031, 0.31827762722969055, 0.328675240278244, 0.3336014747619629,
  0.32963827252388, 0.33212488889694214, 0.33720046281814575,
  0.33842456340789795, 0.3431188464164734, 0.3500700294971466,
  0.3559737503528595, 0.362983763217926, 0.3669426739215851, 0.3694861829280853,
  0.37677693367004395, 0.38136956095695496, 0.3798081576824188,
  0.37890946865081787, 0.37464040517807007, 0.3641878068447113,
  0.3549560606479645, 0.34603601694107056, 0.3373863697052002,
  0.3361242115497589, 0.33722585439682007, 0.33577001094818115,
  0.33900192379951477, 0.3456870913505554, 0.3524188995361328,
  0.36444544792175293, 0.37598687410354614, 0.37981554865837097,
  0.38387531042099, 0.3874216079711914, 0.38237789273262024,
  0.37547770142555237, 0.36952531337738037, 0.3573163151741028,
  0.3435460329055786, 0.33308953046798706, 0.3210134506225586,
  0.3098111152648926, 0.30267611145973206, 0.29492396116256714,
  0.2887209951877594, 0.2879299819469452, 0.2890664339065552,
  0.2924002408981323, 0.29668447375297546, 0.2948319911956787,
  0.29084470868110657, 0.29202747344970703, 0.29235270619392395,
  0.28783711791038513, 0.28228411078453064, 0.27346664667129517,
  0.25933727622032166, 0.2451973408460617, 0.23434901237487793,
  0.22567084431648254, 0.21651431918144226, 0.20613031089305878,
  0.19968728721141815, 0.19881637394428253, 0.19775791466236115,
  0.1976533681154251, 0.2021973878145218, 0.20453843474388123,
  0.2019934356212616, 0.20203329622745514, 0.20384062826633453,
  0.2009485810995102, 0.19411543011665344, 0.18511347472667694,
  0.1742657721042633, 0.16317810118198395, 0.15072010457515717,
  0.1365349143743515, 0.12291610985994339, 0.10785654932260513,
  0.0908016487956047, 0.0771901085972786, 0.06718529760837555,
  0.05786329135298729, 0.052374377846717834, 0.05084122344851494,
  0.04883061349391937, 0.047636065632104874, 0.049088310450315475,
  0.050803638994693756, 0.05242292210459709, 0.05221376195549965,
  0.04693560674786568, 0.04008887708187103, 0.03338995948433876,
  0.021534457802772522, 0.006505467463284731, -0.004972103983163834,
  -0.014477345161139965, -0.0236278735101223, -0.029022512957453728,
  -0.031527649611234665, -0.03286483511328697, -0.03074471279978752,
  -0.025886915624141693, -0.021522052586078644, -0.019593939185142517,
  -0.02157559245824814, -0.0246452484279871, -0.02592645213007927,
  -0.03086746297776699, -0.04147467017173767, -0.05074864625930786,
  -0.05831814184784889, -0.06741231679916382, -0.07347047328948975,
  -0.0764351636171341, -0.08222666382789612, -0.08792881667613983,
  -0.0896788239479065, -0.09098261594772339, -0.09174690395593643,
  -0.0901220366358757, -0.08863992244005203, -0.08676396310329437,
  -0.08320202678442001, -0.07965859770774841, -0.07353516668081284,
  -0.06550431251525879, -0.06389956176280975, -0.06696745753288269,
  -0.06699653714895248, -0.06782183796167374, -0.07275613397359848,
  -0.07789454609155655, -0.08519090712070465, -0.09541025757789612,
  -0.10153704136610031, -0.10225314646959305, -0.10030931234359741,
  -0.09603402018547058, -0.09301073849201202, -0.0905582457780838,
  -0.0824781060218811, -0.07399193197488785, -0.071806401014328,
  -0.06965302675962448, -0.0675261840224266, -0.07217516750097275,
  -0.07717949897050858, -0.07765988260507584, -0.08203653246164322,
  -0.09141188114881516, -0.09879299253225327, -0.10388747602701187,
  -0.10788866877555847, -0.11019689589738846, -0.11160916090011597,
  -0.11099577695131302, -0.10940957069396973, -0.10979283601045609,
  -0.10771481692790985, -0.10070572793483734, -0.0954972580075264,
  -0.09356524050235748, -0.09115603566169739, -0.08942709863185883,
  -0.08908078074455261, -0.09044307470321655, -0.09589289128780365,
  -0.1023687794804573, -0.10872388631105423, -0.1203315258026123,
  -0.1324623078107834, -0.13755281269550323, -0.14295901358127594,
  -0.1521906554698944, -0.1573939025402069, -0.16005757451057434,
  -0.16396349668502808, -0.16455024480819702, -0.16376662254333496,
  -0.16559627652168274, -0.1659373939037323, -0.16613627970218658,
  -0.16876646876335144, -0.1681710183620453, -0.16851192712783813,
  -0.1785440891981125, -0.1888372153043747, -0.1920452117919922,
  -0.19872723519802094, -0.2111731469631195, -0.22064127027988434,
  -0.22801537811756134, -0.2370537966489792, -0.24514836072921753,
  -0.24984592199325562, -0.2513987720012665, -0.2543741762638092,
  -0.2615801692008972, -0.26447153091430664, -0.26097363233566284,
  -0.26483386754989624, -0.2755148112773895, -0.27939099073410034,
  -0.28340768814086914, -0.29563969373703003, -0.3016062378883362,
  -0.3014335036277771, -0.3137385845184326, -0.33140280842781067,
  -0.33823487162590027, -0.3421258330345154, -0.34657537937164307,
  -0.34608709812164307, -0.3540736734867096, -0.37318095564842224,
  -0.37936729192733765, -0.3731737434864044, -0.3756932020187378,
  -0.3808005452156067, -0.38122087717056274, -0.391046404838562,
  -0.4033779799938202, -0.39771777391433716, -0.38510972261428833,
  -0.3856106400489807, -0.39512279629707336, -0.4005962610244751,
  -0.39360544085502625, -0.37587422132492065, -0.3602255880832672,
  -0.35015133023262024, -0.3402251601219177, -0.3335224688053131,
  -0.32653558254241943, -0.3092644512653351, -0.2938328683376312,
  -0.29769718647003174, -0.31426987051963806, -0.32726722955703735,
  -0.3251095116138458, -0.3114599883556366, -0.31006836891174316,
  -0.3230234384536743, -0.3233012557029724, -0.31262296438217163,
  -0.3047206401824951, -0.277662456035614, -0.2333659678697586,
  -0.21816112101078033, -0.22915872931480408, -0.21911704540252686,
  -0.18945284187793732, -0.16983914375305176, -0.1568828523159027,
  -0.14058849215507507, -0.1307838410139084, -0.13027498126029968,
  -0.12255565077066422, -0.09749520570039749, -0.07331264764070511,
  -0.07025769352912903, -0.07303812354803085, -0.0596124641597271,
  -0.04088685289025307, -0.031841568648815155, -0.026304466649889946,
  -0.017820363864302635, -0.00537828030064702, 0.01296979933977127,
  0.03678068518638611, 0.06617064774036407, 0.09395977854728699,
  0.10472536832094193, 0.10666555911302567, 0.12254221737384796,
  0.14280803501605988, 0.1462039351463318, 0.14050863683223724,
  0.13715186715126038, 0.13231848180294037, 0.12720045447349548,
  0.12998320162296295, 0.14544375240802765, 0.16869695484638214,
  0.18599939346313477, 0.19868174195289612, 0.22170813381671906,
  0.24699072539806366, 0.25893232226371765, 0.26882803440093994,
  0.28415465354919434, 0.2924574613571167, 0.29366376996040344,
  0.2945924997329712, 0.2928001880645752, 0.29285264015197754,
  0.29848089814186096, 0.30270421504974365, 0.3091389238834381,
  0.3220420777797699, 0.32905685901641846, 0.3291419446468353,
  0.3348967730998993, 0.3427979648113251, 0.3452414572238922,
  0.3468935489654541, 0.35002654790878296, 0.3547637164592743,
  0.36283954977989197, 0.3683488368988037, 0.36876964569091797,
  0.37147554755210876, 0.3762558400630951, 0.37776419520378113,
  0.3771842122077942, 0.37386947870254517, 0.3651241660118103,
  0.3535788059234619, 0.3436256945133209, 0.34081095457077026,
  0.3469495177268982, 0.3518263101577759, 0.350497841835022, 0.3541550636291504,
  0.36647501587867737, 0.37889978289604187, 0.3896413743495941,
  0.399539589881897, 0.40499329566955566, 0.40586361289024353,
  0.40293943881988525, 0.3966042697429657, 0.3918156027793884,
  0.38676947355270386, 0.37304505705833435, 0.3569222688674927,
  0.3502459228038788, 0.34661924839019775, 0.33688968420028687,
  0.32771265506744385, 0.3242035508155823, 0.3203578591346741,
  0.3156082034111023, 0.31684592366218567, 0.32271355390548706,
  0.32381582260131836, 0.3183819651603699, 0.3149837553501129,
  0.31733065843582153, 0.3174884617328644, 0.30987173318862915,
  0.30004075169563293, 0.2917749285697937, 0.2800343334674835,
  0.2649992108345032, 0.2546628415584564, 0.2481537014245987,
  0.23811234533786774, 0.22829319536685944, 0.22624120116233826,
  0.22791211307048798, 0.2277710735797882, 0.22847585380077362,
  0.23088251054286957, 0.2313096523284912, 0.2292749434709549,
  0.22653110325336456, 0.22272391617298126, 0.21573731303215027,
  0.2038256973028183, 0.18927885591983795, 0.17633722722530365,
  0.16413559019565582, 0.14983433485031128, 0.13495071232318878,
  0.12082120031118393, 0.10580656677484512, 0.09114290028810501,
  0.08063602447509766, 0.07409922778606415, 0.06788583099842072,
  0.061316560953855515, 0.057753000408411026, 0.05814264342188835,
  0.05899491161108017, 0.05880763381719589, 0.058457355946302414,
  0.05656415596604347, 0.05232464149594307, 0.04682713747024536,
  0.03880556672811508, 0.027221696451306343, 0.013781341724097729,
  -0.00007606926374137402, -0.011589965783059597, -0.018543077632784843,
  -0.025539053604006767, -0.03457310423254967, -0.03797387331724167,
  -0.03437201678752899, -0.03122086450457573, -0.02837797813117504,
  -0.023758485913276672, -0.02352007105946541, -0.02830539084970951,
  -0.030469059944152832, -0.031287021934986115, -0.0378539077937603,
  -0.04831944778561592, -0.05769162252545357, -0.06630849093198776,
  -0.07403477281332016, -0.0788297951221466, -0.08260492980480194,
  -0.08792410045862198, -0.09254635125398636, -0.09435902535915375,
  -0.0937129408121109, -0.08987999707460403, -0.08453941345214844,
  -0.08203337341547012, -0.08042999356985092, -0.07509952783584595,
  -0.06786879897117615, -0.06071491912007332, -0.05427166447043419,
  -0.054161593317985535, -0.060211438685655594, -0.06286902725696564,
  -0.06232524290680885, -0.06670626997947693, -0.07483797520399094,
  -0.08359173685312271, -0.09334627538919449, -0.09846123307943344,
  -0.09596225619316101, -0.0923490822315216, -0.08968604356050491,
  -0.08545801788568497, -0.07977116852998734, -0.07095998525619507,
  -0.06077846139669418, -0.05630746856331825, -0.05582509934902191,
  -0.054464567452669144, -0.05664708837866783, -0.06224695220589638,
  -0.06521579623222351, -0.06954499334096909, -0.07891426980495453,
  -0.0875413566827774, -0.09387523680925369, -0.0990820974111557,
  -0.10070836544036865, -0.10134042799472809, -0.10392199456691742,
  -0.10434731096029282, -0.10246601700782776, -0.10103800892829895,
  -0.09662685543298721, -0.0897534117102623, -0.08696985244750977,
  -0.0875743106007576, -0.08707763254642487, -0.08677735179662704,
  -0.08971759676933289, -0.09626197814941406, -0.10466936230659485,
  -0.11381970345973969, -0.12479279190301895, -0.1361827552318573,
  -0.14519576728343964, -0.15366779267787933, -0.16182111203670502,
  -0.16687721014022827, -0.17115408182144165, -0.17470674216747284,
  -0.1729993373155594, -0.17073063552379608, -0.17317205667495728,
  -0.17450571060180664, -0.17440634965896606, -0.17843511700630188,
  -0.18282711505889893, -0.1863902509212494, -0.19482140243053436,
  -0.2049715518951416, -0.21324457228183746, -0.22363999485969543,
  -0.23389990627765656, -0.24100154638290405, -0.2497568577528,
  -0.2598464787006378, -0.26733970642089844, -0.2728348970413208,
  -0.2750055193901062, -0.27571728825569153, -0.2808936834335327,
  -0.28573453426361084, -0.2863025665283203, -0.2903491258621216,
  -0.2962197959423065, -0.2971365451812744, -0.30073848366737366,
  -0.3114331066608429, -0.3228111267089844, -0.3325752913951874,
  -0.33835044503211975, -0.34246185421943665, -0.35607996582984924,
  -0.3720490038394928, -0.37680187821388245, -0.3834038972854614,
  -0.3951086103916168, -0.3915640115737915, -0.38380953669548035,
  -0.3949629068374634, -0.4061751663684845, -0.4001123607158661,
  -0.39360618591308594, -0.3938823640346527, -0.3933468759059906,
  -0.39300796389579773, -0.39358124136924744, -0.39451125264167786,
  -0.39408597350120544, -0.3829595744609833, -0.36716827750205994,
  -0.3636409640312195, -0.36185431480407715, -0.34738337993621826,
  -0.33260631561279297, -0.3227262794971466, -0.31166788935661316,
  -0.30918291211128235, -0.3194206655025482, -0.32971540093421936,
  -0.3317980170249939, -0.322211891412735, -0.3124992847442627,
  -0.32401803135871887, -0.33884570002555847, -0.3265707194805145,
  -0.30738770961761475, -0.2959100604057312, -0.26551201939582825,
  -0.23028048872947693, -0.22897596657276154, -0.23506972193717957,
  -0.21228031814098358, -0.1838361620903015, -0.17053207755088806,
  -0.1606149971485138, -0.1506679207086563, -0.14256276190280914,
  -0.13181185722351074, -0.11837418377399445, -0.0996219739317894,
  -0.07777706533670425, -0.06875255703926086, -0.0694487988948822,
  -0.05830530822277069, -0.03874474763870239, -0.029064398258924484,
  -0.02719207853078842, -0.022177496924996376, -0.00989985466003418,
  0.0115057909861207, 0.039329517632722855, 0.0660325139760971,
  0.0846826508641243, 0.0920046791434288, 0.09582701325416565,
  0.10816502571105957, 0.12479247897863388, 0.13163262605667114,
  0.12621715664863586, 0.11614129692316055, 0.10889710485935211,
  0.11007745563983917, 0.12308422476053238, 0.14607374370098114,
  0.16867057979106903, 0.18377293646335602, 0.2013036012649536,
  0.22695036232471466, 0.246251180768013, 0.25585246086120605,
  0.26859423518180847, 0.2786427140235901, 0.2755630910396576,
  0.2726570665836334, 0.277563214302063, 0.2795923948287964, 0.2794550359249115,
  0.2838842570781708, 0.28967925906181335, 0.298788845539093,
  0.31343626976013184, 0.32272863388061523, 0.3231876492500305,
  0.32417556643486023, 0.3267982304096222, 0.32898131012916565,
  0.336000919342041, 0.34753793478012085, 0.35573187470436096,
  0.35824936628341675, 0.3592219948768616, 0.36290329694747925,
  0.3696684241294861, 0.3748694956302643, 0.3736729323863983,
  0.36582109332084656, 0.35493651032447815, 0.34540534019470215,
  0.3380039930343628, 0.33255669474601746, 0.33358052372932434,
  0.3415588438510895, 0.34868860244750977, 0.35441866517066956,
  0.3664816915988922, 0.38345226645469666, 0.3968338370323181,
  0.4045112431049347, 0.40862247347831726, 0.4097132086753845,
  0.40732479095458984, 0.4017435312271118, 0.3952726721763611,
  0.38815051317214966, 0.37690573930740356, 0.36351799964904785,
  0.3555651009082794, 0.3531794250011444, 0.34904858469963074,
  0.3406234383583069, 0.3323730528354645, 0.328045129776001, 0.3254518508911133,
  0.32225531339645386, 0.3227597773075104, 0.3272755444049835,
  0.3264899253845215, 0.3203434348106384, 0.31963154673576355,
  0.3231229782104492, 0.3206774592399597, 0.3121267855167389,
  0.3022068738937378, 0.2911457121372223, 0.2781478464603424,
  0.2644631564617157, 0.25403785705566406, 0.24752582609653473,
  0.2380906194448471, 0.22697928547859192, 0.22565487027168274,
  0.23180080950260162, 0.23403066396713257, 0.23390403389930725,
  0.2336544394493103, 0.2282526195049286, 0.2222760170698166,
  0.22050058841705322, 0.2141600400209427, 0.20018532872200012,
  0.18535253405570984, 0.16992518305778503, 0.15412363409996033,
  0.14282488822937012, 0.13323579728603363, 0.12012277543544769,
  0.10535404086112976, 0.09104544669389725, 0.07859687507152557,
  0.06990337371826172, 0.062411870807409286, 0.054347049444913864,
  0.048328060656785965, 0.04460083693265915, 0.043215036392211914,
  0.04563429206609726, 0.047537047415971756, 0.0446615032851696,
  0.040595777332782745, 0.03730624541640282, 0.03248954564332962,
  0.026401208713650703, 0.01761746034026146, 0.00281029031611979,
  -0.01401644479483366, -0.02539902552962303, -0.030506549403071404,
  -0.03353150561451912, -0.03673813119530678, -0.0381174273788929,
  -0.03632006421685219, -0.03357010707259178, -0.029814984649419785,
  -0.024611352011561394, -0.023953085765242577, -0.02952049858868122,
  -0.033911291509866714, -0.036996204406023026, -0.04406970366835594,
  -0.05175643041729927, -0.05744435638189316, -0.06454407423734665,
  -0.07050205767154694, -0.07221909612417221, -0.07352641969919205,
  -0.07458211481571198, -0.07405553758144379, -0.0758453980088234,
  -0.07737935334444046, -0.0721881315112114, -0.06461519002914429,
  -0.05983617156744003, -0.0553702749311924, -0.051238734275102615,
  -0.04716895893216133, -0.039193738251924515, -0.03138652816414833,
  -0.030417170375585556, -0.03314680606126785, -0.03732483461499214,
  -0.045070830732584, -0.05196971446275711, -0.05555620789527893,
  -0.06216328963637352, -0.0709197074174881, -0.07313553988933563,
  -0.06935285776853561, -0.065639927983284, -0.06149885430932045,
  -0.054764941334724426, -0.04719090834259987, -0.040534090250730515,
  -0.035071007907390594, -0.03145803138613701, -0.030779585242271423,
  -0.0333993174135685, -0.03781277313828468, -0.0427083745598793,
  -0.048724476248025894, -0.05510026961565018, -0.06026440113782883,
  -0.06585544347763062, -0.07239168882369995, -0.07682053744792938,
  -0.0793222039937973, -0.08159256726503372, -0.0813237875699997,
  -0.07925920933485031, -0.07946176081895828, -0.07893373817205429,
  -0.07436903566122055, -0.07129961997270584, -0.07161343097686768,
  -0.07077480107545853, -0.07126563787460327, -0.07670331746339798,
  -0.08254697173833847, -0.08774518966674805, -0.09691967070102692,
  -0.10920730233192444, -0.1209915354847908, -0.1308126598596573,
  -0.1378002017736435, -0.1443389505147934, -0.15201103687286377,
  -0.15694673359394073, -0.1591709852218628, -0.16226956248283386,
  -0.1636638641357422, -0.16292399168014526, -0.1652897298336029,
  -0.16902290284633636, -0.170701801776886, -0.17354287207126617,
  -0.17795976996421814, -0.18356847763061523, -0.19313304126262665,
  -0.2027125060558319, -0.21004122495651245, -0.22069256007671356,
  -0.23024514317512512, -0.23435501754283905, -0.24485523998737335,
  -0.2601972222328186, -0.26381444931030273, -0.26202523708343506,
  -0.2668118476867676, -0.27008670568466187, -0.27250269055366516,
  -0.2818020284175873, -0.28766196966171265, -0.2861536145210266,
  -0.28791865706443787, -0.29353195428848267, -0.30494964122772217,
  -0.32421573996543884, -0.3336739242076874, -0.3323695957660675,
  -0.34360942244529724, -0.3598955273628235, -0.3648257851600647,
  -0.3749508857727051, -0.3883845806121826, -0.3826897144317627,
  -0.37692639231681824, -0.3898606300354004, -0.3964990973472595,
  -0.39273032546043396, -0.3967105448246002, -0.39381176233291626,
  -0.3799436390399933, -0.38333380222320557, -0.39777645468711853,
  -0.3975517749786377, -0.3935416042804718, -0.39417552947998047,
  -0.38410452008247375, -0.3703593909740448, -0.369432657957077,
  -0.3688669502735138, -0.35495010018348694, -0.3362467288970947,
  -0.3233756422996521, -0.3183748722076416, -0.31809747219085693,
  -0.32007914781570435, -0.326503187417984, -0.331077516078949,
  -0.3233417868614197, -0.31789371371269226, -0.3323715031147003,
  -0.34354928135871887, -0.327764093875885, -0.3081825375556946,
  -0.30127713084220886, -0.28269973397254944, -0.2513025104999542,
  -0.2398993819952011, -0.24273380637168884, -0.22527526319026947,
  -0.19581256806850433, -0.18236616253852844, -0.17649102210998535,
  -0.16249078512191772, -0.14823825657367706, -0.13694648444652557,
  -0.1215188130736351, -0.10422566533088684, -0.08851689100265503,
  -0.07527858763933182, -0.06896757334470749, -0.06392551958560944,
  -0.050897665321826935, -0.04066534340381622, -0.04089698567986488,
  -0.03450484201312065, -0.014714566059410572, 0.004878921899944544,
  0.024418260902166367, 0.049878671765327454, 0.06970404088497162,
  0.07861167192459106, 0.08925344049930573, 0.10397376120090485,
  0.11271660029888153, 0.1137290820479393, 0.11098270118236542,
  0.10595289617776871, 0.10131289064884186, 0.10181886702775955,
  0.11245086789131165, 0.13398303091526031, 0.15748830139636993,
  0.17505304515361786, 0.19240030646324158, 0.21428295969963074,
  0.2330273985862732, 0.24562674760818481, 0.25832512974739075,
  0.2687230110168457, 0.26983344554901123, 0.26705750823020935,
  0.2691166400909424, 0.27467915415763855, 0.27956950664520264,
  0.28363436460494995, 0.28933435678482056, 0.29889434576034546,
  0.3095633387565613, 0.3159642517566681, 0.31855064630508423,
  0.32042938470840454, 0.321565717458725, 0.3237646818161011,
  0.32996296882629395, 0.33800992369651794, 0.34513136744499207,
  0.35126540064811707, 0.35554078221321106, 0.35932281613349915,
  0.3657335340976715, 0.37170717120170593, 0.37193563580513, 0.3668479323387146,
  0.35879623889923096, 0.3494578003883362, 0.3415030241012573,
  0.337066650390625, 0.33812397718429565, 0.34438252449035645,
  0.35047972202301025, 0.35588347911834717, 0.3673379421234131,
  0.3835688531398773, 0.3963337242603302, 0.40431979298591614,
  0.4091886281967163, 0.4107234477996826, 0.410877525806427, 0.4086419939994812,
  0.40013644099235535, 0.3883439600467682, 0.37701430916786194,
  0.36471647024154663, 0.3543068766593933, 0.34872063994407654,
  0.3418232798576355, 0.33058667182922363, 0.32112109661102295,
  0.31690672039985657, 0.31586360931396484, 0.3149525821208954,
  0.3134121298789978, 0.31439411640167236, 0.3162999451160431,
  0.3125859200954437, 0.3082221448421478, 0.311260461807251,
  0.31219279766082764, 0.3020169734954834, 0.2894149720668793,
  0.2796330451965332, 0.2679271697998047, 0.25568586587905884,
  0.24577414989471436, 0.23521405458450317, 0.2245294749736786,
  0.21763001382350922, 0.21492303907871246, 0.21494288742542267,
  0.2147788256406784, 0.21198831498622894, 0.20856542885303497,
  0.20622941851615906, 0.2034367471933365, 0.19900956749916077,
  0.19165237247943878, 0.18068137764930725, 0.168105810880661,
  0.1546662449836731, 0.14065062999725342, 0.12858761847019196,
  0.11674793064594269, 0.10144267231225967, 0.08563020080327988,
  0.07147327065467834, 0.05664199963212013, 0.04435259848833084,
  0.03831475228071213, 0.03416895866394043, 0.02951662801206112,
  0.027545606717467308, 0.028933972120285034, 0.03239167109131813,
  0.03620506078004837, 0.03668978437781334, 0.03331804648041725,
  0.028563711792230606, 0.022086570039391518, 0.013534878380596638,
  0.003529156092554331, -0.009275680407881737, -0.023924445733428,
  -0.035833027213811874, -0.04151793569326401, -0.04095375910401344,
  -0.038832250982522964, -0.03937866538763046, -0.0392606183886528,
  -0.03560377284884453, -0.03117445856332779, -0.026357490569353104,
  -0.02423768676817417, -0.03176174312829971, -0.04165996238589287,
  -0.04299977421760559, -0.04402783885598183, -0.05080041289329529,
  -0.05411233380436897, -0.055665530264377594, -0.06251569092273712,
  -0.06626984477043152, -0.06354735791683197, -0.06312581151723862,
  -0.064995177090168, -0.06686915457248688, -0.07059869915246964,
  -0.07000452280044556, -0.0629819706082344, -0.05785902217030525,
  -0.051819972693920135, -0.03983519226312637, -0.032528482377529144,
  -0.031633004546165466, -0.024998558685183525, -0.017105692997574806,
  -0.02014974318444729, -0.02851218171417713, -0.03380662202835083,
  -0.039832111448049545, -0.05031760036945343, -0.06125740706920624,
  -0.06616823375225067, -0.06455491483211517, -0.06269264221191406,
  -0.06099768355488777, -0.05368729308247566, -0.04428865388035774,
  -0.04030662402510643, -0.03783336281776428, -0.03208455815911293,
  -0.028975674882531166, -0.03256886079907417, -0.037810180336236954,
  -0.040746934711933136, -0.04363732412457466, -0.04955100640654564,
  -0.056865088641643524, -0.060907259583473206, -0.06181461364030838,
  -0.06516880542039871, -0.0717005655169487, -0.07545164972543716,
  -0.07588560879230499, -0.07932054996490479, -0.08469223976135254,
  -0.08455991744995117, -0.08089572191238403, -0.08029855042695999,
  -0.08056959509849548, -0.07991710305213928, -0.08187911659479141,
  -0.08387065678834915, -0.08395225554704666, -0.08883464336395264,
  -0.09821894764900208, -0.10597967356443405, -0.11598210781812668,
  -0.12941060960292816, -0.13846945762634277, -0.14534088969230652,
  -0.15486648678779602, -0.16062884032726288, -0.16266517341136932,
  -0.1676265299320221, -0.171335831284523, -0.17208106815814972,
  -0.1758960336446762, -0.1783698946237564, -0.17598335444927216,
  -0.17779751121997833, -0.18413063883781433, -0.18812011182308197,
  -0.19436009228229523, -0.20394696295261383, -0.20838920772075653,
  -0.2113949954509735, -0.22108428180217743, -0.2317379117012024,
  -0.23990580439567566, -0.2509067952632904, -0.26084086298942566,
  -0.26254791021347046, -0.2629894018173218, -0.27079278230667114,
  -0.28068265318870544, -0.28560104966163635, -0.2884994447231293,
  -0.2923588156700134, -0.29560336470603943, -0.30181559920310974,
  -0.3143101632595062, -0.3249933123588562, -0.3283118009567261,
  -0.3322092890739441, -0.34137895703315735, -0.3506578505039215,
  -0.35788628458976746, -0.36441370844841003, -0.36902034282684326,
  -0.37064874172210693, -0.37137293815612793, -0.37679317593574524,
  -0.3880927264690399, -0.3940016031265259, -0.3887627124786377,
  -0.38602426648139954, -0.39250823855400085, -0.39597564935684204,
  -0.3932901620864868, -0.39393994212150574, -0.3964388966560364,
  -0.39178213477134705, -0.3804580569267273, -0.3710247278213501,
  -0.36735066771507263, -0.3614351451396942, -0.34674420952796936,
  -0.33122366666793823, -0.32108378410339355, -0.3127025067806244,
  -0.309169739484787, -0.3143842816352844, -0.3205680251121521,
  -0.3226594030857086, -0.32164230942726135, -0.3200128376483917,
  -0.3283994495868683, -0.3423325717449188, -0.3348109722137451,
  -0.31123462319374084, -0.3013575077056885, -0.2897518277168274,
  -0.2558353841304779, -0.23221105337142944, -0.2309410572052002,
  -0.21506483852863312, -0.1854686290025711, -0.1740557849407196,
  -0.17146149277687073, -0.15817902982234955, -0.14429619908332825,
  -0.1358082890510559, -0.12653984129428864, -0.11505965143442154,
  -0.09922755509614944, -0.08408571034669876, -0.07876641303300858,
  -0.07060133665800095, -0.04896478354930878, -0.03499287739396095,
  -0.0384615994989872, -0.035132817924022675, -0.014542529359459877,
  0.008515091612935066, 0.029195081442594528, 0.05130218714475632,
  0.06979750096797943, 0.08142135292291641, 0.09214696288108826,
  0.10554098337888718, 0.11750469356775284, 0.12152905017137527,
  0.11507932841777802, 0.10476482659578323, 0.10117229074239731,
  0.10513348877429962, 0.11138749867677689, 0.12423744052648544,
  0.1480608731508255, 0.1727040857076645, 0.19235989451408386,
  0.21607287228107452, 0.24275609850883484, 0.26070502400398254,
  0.2715900242328644, 0.28108182549476624, 0.28534916043281555,
  0.28518202900886536, 0.2851211428642273, 0.28345412015914917,
  0.28271758556365967, 0.2873053550720215, 0.29213693737983704,
  0.2977422773838043, 0.31076833605766296, 0.32249191403388977,
  0.3234384059906006, 0.3234352469444275, 0.3303449749946594,
  0.33860981464385986, 0.3448984920978546, 0.34985220432281494,
  0.3544065058231354, 0.3616904616355896, 0.369834303855896, 0.3741666376590729,
  0.37820321321487427, 0.384196013212204, 0.38504090905189514,
  0.37793365120887756, 0.36866262555122375, 0.36071839928627014,
  0.3522680103778839, 0.3424304127693176, 0.3371198773384094,
  0.34210070967674255, 0.35026296973228455, 0.3540463447570801,
  0.3619615137577057, 0.3793761730194092, 0.39470991492271423,
  0.4014413356781006, 0.4061082601547241, 0.4115787446498871,
  0.4137697219848633, 0.4086676239967346, 0.39706307649612427,
  0.38575246930122375, 0.375850647687912, 0.3613954186439514,
  0.34713539481163025, 0.34047937393188477, 0.3326740264892578,
  0.31815576553344727, 0.30776047706604004, 0.304789662361145,
  0.3017546832561493, 0.2989523708820343, 0.29953867197036743,
  0.3014545738697052, 0.30279606580734253, 0.30213668942451477,
  0.30060869455337524, 0.3023625612258911, 0.30281201004981995,
  0.2939096987247467, 0.28052186965942383, 0.2688288986682892,
  0.2550065219402313, 0.23906104266643524, 0.22545869648456573,
  0.21242307126522064, 0.19938616454601288, 0.19118425250053406,
  0.188878133893013, 0.189980611205101, 0.1918162703514099, 0.1913314312696457,
  0.19005239009857178, 0.1911429464817047, 0.19103610515594482,
  0.18645961582660675, 0.17975440621376038, 0.17038871347904205,
  0.156831756234169, 0.1432475447654724, 0.1312221735715866,
  0.11628901213407516, 0.09891846030950546, 0.08466503024101257,
  0.07279542088508606, 0.05786304175853729, 0.042051833122968674,
  0.03279045596718788, 0.02820678986608982, 0.0219736211001873,
  0.018642356619238853, 0.022910287603735924, 0.027332380414009094,
  0.028543241322040558, 0.03239207714796066, 0.03675882890820503,
  0.03596138209104538, 0.03284664452075958, 0.027580250054597855,
  0.016069689765572548, 0.0019107211846858263, -0.010821614414453506,
  -0.023112518712878227, -0.03327168524265289, -0.04081539064645767,
  -0.047633446753025055, -0.04918108507990837, -0.04356110468506813,
  -0.03800599277019501, -0.03386571258306503, -0.028490152209997177,
  -0.025965357199311256, -0.02576979249715805, -0.023089958354830742,
  -0.02284797467291355, -0.02842993661761284, -0.03403821960091591,
  -0.039480965584516525, -0.04605753347277641, -0.048475481569767,
  -0.048992183059453964, -0.05397763475775719, -0.05915531516075134,
  -0.0614207461476326, -0.0641273558139801, -0.06431446969509125,
  -0.059982780367136, -0.05645206570625305, -0.052654821425676346,
  -0.04492033272981644, -0.03729025647044182, -0.03022213652729988,
  -0.020219599828124046, -0.012028662487864494, -0.009774243459105492,
  -0.009813305921852589, -0.011915097944438457, -0.018963364884257317,
  -0.02914404310286045, -0.03926137089729309, -0.047810740768909454,
  -0.054985299706459045, -0.061911992728710175, -0.0657426044344902,
  -0.06322163343429565, -0.05851190164685249, -0.05503636226058006,
  -0.04932357743382454, -0.04087727516889572, -0.03341192379593849,
  -0.02829674631357193, -0.02636970579624176, -0.027400337159633636,
  -0.02922939322888851, -0.032709769904613495, -0.037877339869737625,
  -0.04143607243895531, -0.04618018865585327, -0.055988334119319916,
  -0.0651458352804184, -0.07048425078392029, -0.07717399299144745,
  -0.08464324474334717, -0.0879877358675003, -0.0884166732430458,
  -0.08889792114496231, -0.09016915410757065, -0.09124429523944855,
  -0.08891968429088593, -0.0846485123038292, -0.08389203995466232,
  -0.08510751277208328, -0.08573982119560242, -0.09030131250619888,
  -0.09926755726337433, -0.10817268490791321, -0.11798027157783508,
  -0.12941734492778778, -0.13941772282123566, -0.14876620471477509,
  -0.1583825647830963, -0.1666179746389389, -0.17458808422088623,
  -0.18091776967048645, -0.18189509212970734, -0.18176722526550293,
  -0.18488846719264984, -0.1865692287683487, -0.18439432978630066,
  -0.18312187492847443, -0.18685275316238403, -0.19502277672290802,
  -0.20111458003520966, -0.20232029259204865, -0.20811738073825836,
  -0.22186054289340973, -0.23338501155376434, -0.24249687790870667,
  -0.2558031678199768, -0.26614949107170105, -0.26826223731040955,
  -0.27066144347190857, -0.2776736319065094, -0.28571203351020813,
  -0.2907981276512146, -0.2900296747684479, -0.28962644934654236,
  -0.2971203625202179, -0.30477917194366455, -0.3083112835884094,
  -0.31665778160095215, -0.32607144117355347, -0.3290344178676605,
  -0.3359352648258209, -0.3493119478225708, -0.35693398118019104,
  -0.3597959578037262, -0.36354923248291016, -0.36698117852211,
  -0.3761201798915863, -0.38929563760757446, -0.3912794589996338,
  -0.38811272382736206, -0.3944915533065796, -0.3992162346839905,
  -0.3964061737060547, -0.3998222053050995, -0.4038154184818268,
  -0.3961154818534851, -0.38932543992996216, -0.39009401202201843,
  -0.3865370750427246, -0.3791641592979431, -0.3725178837776184,
  -0.36039653420448303, -0.3478498160839081, -0.3432513177394867,
  -0.3349425494670868, -0.3188025653362274, -0.312472403049469,
  -0.3173021972179413, -0.3190814256668091, -0.3229459524154663,
  -0.33310139179229736, -0.3331173360347748, -0.3262748718261719,
  -0.33312925696372986, -0.3403056561946869, -0.3281145691871643,
  -0.31378626823425293, -0.3003559708595276, -0.26533350348472595,
  -0.22996786236763, -0.22617317736148834, -0.2240472435951233,
  -0.1985965073108673, -0.1789133995771408, -0.1723855435848236,
  -0.15866395831108093, -0.1483192890882492, -0.14921754598617554,
  -0.14182832837104797, -0.1262562870979309, -0.1129845604300499,
  -0.09672559797763824, -0.08397914469242096, -0.07983028143644333,
  -0.06549271941184998, -0.044043149799108505, -0.03827938809990883,
  -0.03677120804786682, -0.022276263684034348, -0.005814281292259693,
  0.012628508731722832, 0.04233323782682419, 0.06864271312952042,
  0.08071879297494888, 0.08882921934127808, 0.09814237803220749,
  0.10880757868289948, 0.1204511821269989, 0.12201851606369019,
  0.11080022156238556, 0.10151521116495132, 0.09917597472667694,
  0.10087411105632782, 0.11421258002519608, 0.13983899354934692,
  0.1634388267993927, 0.18106846511363983, 0.20331662893295288,
  0.23186132311820984, 0.25460758805274963, 0.26580721139907837,
  0.27367597818374634, 0.28194916248321533, 0.28425610065460205,
  0.281930148601532, 0.28216588497161865, 0.2832163870334625,
  0.2836093604564667, 0.2876719534397125, 0.2956569194793701,
  0.30775585770606995, 0.32248175144195557, 0.3282838463783264,
  0.32441243529319763, 0.32737699151039124, 0.3377925157546997,
  0.3425257205963135, 0.34541934728622437, 0.3541123867034912,
  0.36209404468536377, 0.36624035239219666, 0.3705322742462158,
  0.3753083348274231, 0.3804149925708771, 0.38330087065696716,
  0.3788374960422516, 0.3705207109451294, 0.36338409781455994,
  0.3538992404937744, 0.3432636559009552, 0.339824378490448,
  0.34370991587638855, 0.34944555163383484, 0.3561127781867981,
  0.36496198177337646, 0.3769334554672241, 0.39110317826271057,
  0.40303945541381836, 0.41020193696022034, 0.4137730598449707,
  0.41405248641967773, 0.4101352393627167, 0.4017796516418457,
  0.39107945561408997, 0.380580335855484, 0.3675709664821625,
  0.35174867510795593, 0.3419249951839447, 0.3376169502735138,
  0.32694104313850403, 0.31337735056877136, 0.30887624621391296,
  0.3078182637691498, 0.3028770983219147, 0.3010886013507843,
  0.3046169877052307, 0.3067030906677246, 0.30592888593673706,
  0.3043508231639862, 0.3036569356918335, 0.3037358224391937,
  0.29841935634613037, 0.2856212854385376, 0.2726322114467621,
  0.2608252763748169, 0.24583807587623596, 0.2300180345773697,
  0.21671125292778015, 0.20569615066051483, 0.19778470695018768,
  0.19254545867443085, 0.19030795991420746, 0.19376643002033234,
  0.19820353388786316, 0.1970231682062149, 0.19445295631885529,
  0.19422127306461334, 0.19225957989692688, 0.18712285161018372,

  -0.11809175461530685, -0.12059067189693451, -0.11389921605587006,
  -0.11366792768239975, -0.12193839251995087, -0.11962637305259705,
  -0.11669503152370453, -0.12227118015289307, -0.11971704661846161,
  -0.11663499474525452, -0.12175194174051285, -0.12015548348426819,
  -0.12061696499586105, -0.12325246632099152, -0.10994356870651245,
  -0.11108742654323578, -0.11976288259029388, -0.07163883745670319,
  -0.04204116016626358, -0.13667073845863342, -0.21707311272621155,
  -0.14172323048114777, -0.05145002529025078, -0.08897113054990768,
  -0.16500972211360931, -0.1646035611629486, -0.11400232464075089,
  -0.10092282295227051, -0.14388535916805267, -0.17029275000095367,
  -0.13948160409927368, -0.10382982343435287, -0.10526163876056671,
  -0.12776243686676025, -0.14792533218860626, -0.1505197286605835,
  -0.1373925656080246, -0.13264580070972443, -0.145758718252182,
  -0.15946504473686218, -0.15682759881019592, -0.14349399507045746,
  -0.14347626268863678, -0.15750278532505035, -0.15811960399150848,
  -0.14408040046691895, -0.13695362210273743, -0.1397424042224884,
  -0.14915630221366882, -0.16085755825042725, -0.16020718216896057,
  -0.1493271440267563, -0.1458520144224167, -0.1498800665140152,
  -0.1515025645494461, -0.14941944181919098, -0.14755617082118988,
  -0.1494932472705841, -0.15291747450828552, -0.15399639308452606,
  -0.1540445238351822, -0.1521546095609665, -0.14790017902851105,
  -0.14469584822654724, -0.14101852476596832, -0.13879652321338654,
  -0.14326635003089905, -0.14556050300598145, -0.14055487513542175,
  -0.13945679366588593, -0.14156275987625122, -0.13824264705181122,
  -0.13609439134597778, -0.13955296576023102, -0.14109498262405396,
  -0.1377899944782257, -0.1328800618648529, -0.13077351450920105,
  -0.13307467103004456, -0.13367868959903717, -0.12998519837856293,
  -0.12766951322555542, -0.12634043395519257, -0.12303262948989868,
  -0.12319532036781311, -0.1275404840707779, -0.12601353228092194,
  -0.11773382872343063, -0.11447880417108536, -0.11840279400348663,
  -0.12037178128957748, -0.11899377405643463, -0.11789819598197937,
  -0.11557193845510483, -0.1127987876534462, -0.11202061921358109,
  -0.11138146370649338, -0.1111520305275917, -0.11070004105567932,
  -0.10607689619064331, -0.10197822004556656, -0.10423740744590759,
  -0.10730676352977753, -0.10627784579992294, -0.10232075303792953,
  -0.09880238026380539, -0.10059192031621933, -0.10366589576005936,
  -0.100401870906353, -0.09540083259344101, -0.0923868715763092,
  -0.08973242342472076, -0.09188288450241089, -0.09451325237751007,
  -0.08803978562355042, -0.0811043232679367, -0.08082340657711029,
  -0.08109766244888306, -0.08298798650503159, -0.08444830030202866,
  -0.07880555838346481, -0.07499051094055176, -0.07613406330347061,
  -0.07317282259464264, -0.07194588333368301, -0.07441383600234985,
  -0.07065749168395996, -0.06951654702425003, -0.07537208497524261,
  -0.07071125507354736, -0.05985242500901222, -0.05931112542748451,
  -0.0603681318461895, -0.05830948054790497, -0.061167918145656586,
  -0.061028365045785904, -0.05425747483968735, -0.05270744115114212,
  -0.054431866854429245, -0.053042080253362656, -0.05422961711883545,
  -0.0563352033495903, -0.05367417633533478, -0.05139787867665291,
  -0.050329748541116714, -0.04693564027547836, -0.04797997325658798,
  -0.05340176820755005, -0.051055822521448135, -0.043326664716005325,
  -0.041469816118478775, -0.0436847098171711, -0.04220721498131752,
  -0.03284847363829613, -0.021806811913847923, -0.023084230720996857,
  -0.03141353651881218, -0.028736356645822525, -0.01991625316441059,
  -0.015516260638833046, -0.009722438640892506, -0.007658492308109999,
  -0.018390266224741936, -0.024517452344298363, -0.013933884911239147,
  -0.0035293404944241047, -0.0013368293875828385, 0.0027998872101306915,
  0.008745526894927025, 0.009900077246129513, 0.00943021010607481,
  0.012626942247152328, 0.019103704020380974, 0.0258784256875515,
  0.029720021411776543, 0.029386920854449272, 0.027965156361460686,
  0.029925549402832985, 0.03537779301404953, 0.041517458856105804,
  0.04865729808807373, 0.05669613555073738, 0.06121364235877991,
  0.06254956126213074, 0.06612007319927216, 0.0704411193728447,
  0.07317692786455154, 0.07968897372484207, 0.08771829307079315,
  0.08639198541641235, 0.07972928136587143, 0.08196014165878296,
  0.092433400452137, 0.10101457685232162, 0.10565756261348724,
  0.10894089937210083, 0.11166507005691528, 0.11509838700294495,
  0.1205771267414093, 0.12728683650493622, 0.13191449642181396,
  0.13247548043727875, 0.1329980492591858, 0.13575749099254608,
  0.1359378695487976, 0.13581377267837524, 0.14342544972896576,
  0.15199707448482513, 0.15132401883602142, 0.14972253143787384,
  0.15571598708629608, 0.16243724524974823, 0.16510261595249176,
  0.16664893925189972, 0.16860634088516235, 0.17146340012550354,
  0.17309358716011047, 0.1713985800743103, 0.17340104281902313,
  0.1832481473684311, 0.18980740010738373, 0.18565113842487335,
  0.17976099252700806, 0.18118734657764435, 0.1879345327615738,
  0.19310979545116425, 0.19436341524124146, 0.19506411254405975,
  0.1943339854478836, 0.18981783092021942, 0.18975932896137238,
  0.19656479358673096, 0.1962941437959671, 0.18925680220127106,
  0.1923559010028839, 0.20313109457492828, 0.20616741478443146,
  0.20085996389389038, 0.1953313946723938, 0.19712406396865845,
  0.2068731039762497, 0.21068641543388367, 0.20369462668895721,
  0.20056861639022827, 0.20306873321533203, 0.2011406570672989,
  0.19838228821754456, 0.19861964881420135, 0.1994062215089798,
  0.2034677118062973, 0.20606176555156708, 0.19784903526306152,
  0.18645797669887543, 0.18920832872390747, 0.21448412537574768,
  0.23793354630470276, 0.20946170389652252, 0.14300726354122162,
  0.12922453880310059, 0.1871481090784073, 0.23137596249580383,
  0.22051739692687988, 0.1915508657693863, 0.17121106386184692,
  0.16444459557533264, 0.17474579811096191, 0.19134633243083954,
  0.20033524930477142, 0.20012760162353516, 0.1935758888721466,
  0.1840551197528839, 0.1740964949131012, 0.16920454800128937,
  0.17783796787261963, 0.19018082320690155, 0.1868075579404831,
  0.17419466376304626, 0.16867463290691376, 0.1681862622499466,
  0.16923871636390686, 0.17178532481193542, 0.16903729736804962,
  0.16298964619636536, 0.16379551589488983, 0.1672741025686264,
  0.16346138715744019, 0.15454062819480896, 0.14787141978740692,
  0.1481178104877472, 0.1539829671382904, 0.15522781014442444,
  0.14653396606445312, 0.13860616087913513, 0.13903336226940155,
  0.1393769383430481, 0.13541267812252045, 0.13434964418411255,
  0.13459430634975433, 0.12912032008171082, 0.12390168011188507,
  0.12497784197330475, 0.12546290457248688, 0.1213844045996666,
  0.11631433665752411, 0.11042817682027817, 0.10470587015151978,
  0.10290859639644623, 0.10219541937112808, 0.09874220937490463,
  0.0958753377199173, 0.09448082000017166, 0.09006345272064209,
  0.08497335761785507, 0.08612868934869766, 0.08882464468479156,
  0.08274657279253006, 0.07273154705762863, 0.07052021473646164,
  0.07197590917348862, 0.0679328516125679, 0.06469567865133286,
  0.06822021305561066, 0.06737402081489563, 0.05644189193844795,
  0.04934109374880791, 0.052050620317459106, 0.05266571044921875,
  0.04995992034673691, 0.05188199132680893, 0.04946962371468544,
  0.03560458868741989, 0.026925506070256233, 0.03255942463874817,
  0.03872886300086975, 0.037530165165662766, 0.0329144261777401,
  0.027054553851485252, 0.022732097655534744, 0.021726245060563087,
  0.0212031751871109, 0.021021446213126183, 0.021244121715426445,
  0.018235499039292336, 0.013170566409826279, 0.009802376851439476,
  0.007011278532445431, 0.005739158019423485, 0.008736110292375088,
  0.010517232120037079, 0.004364983178675175, -0.00478350929915905,
  -0.0063426378183066845, -0.00043127613025717437, 0.000842181674670428,
  -0.007069415878504515, -0.013755543157458305, -0.013638527132570744,
  -0.013683263212442398, -0.01803368702530861, -0.024520302191376686,
  -0.03049967624247074, -0.030813779681921005, -0.02438606321811676,
  -0.021716579794883728, -0.02946465276181698, -0.03913067281246185,
  -0.04157501831650734, -0.038202349096536636, -0.03551545366644859,
  -0.038278430700302124, -0.0452444963157177, -0.050833072513341904,
  -0.05277246981859207, -0.052130427211523056, -0.05034095421433449,
  -0.051851749420166016, -0.05887148529291153, -0.0649852454662323,
  -0.0650380402803421, -0.06282515823841095, -0.06334767490625381,
  -0.06625013053417206, -0.06784163415431976, -0.06906814128160477,
  -0.07630214095115662, -0.08552025258541107, -0.08379574120044708,
  -0.0747251957654953, -0.07288719713687897, -0.07856988161802292,
  -0.08597850054502487, -0.09285026788711548, -0.09247925132513046,
  -0.08618169277906418, -0.0871010348200798, -0.09463566541671753,
  -0.09815036505460739, -0.09929848462343216, -0.10126771032810211,
  -0.10127099603414536, -0.102776437997818, -0.10705526918172836,
  -0.10620991140604019, -0.10182851552963257, -0.10529317706823349,
  -0.11433891952037811, -0.11502212285995483, -0.10812197625637054,
  -0.10974635183811188, -0.12067299336194992, -0.12293753027915955,
  -0.11488450318574905, -0.1128702163696289, -0.11832599341869354,
  -0.12339702248573303, -0.12872833013534546, -0.128272145986557,
  -0.11764980852603912, -0.11578671634197235, -0.13026461005210876,
  -0.13669846951961517, -0.12696057558059692, -0.12177103757858276,
  -0.12865860760211945, -0.1368667632341385, -0.13880321383476257,
  -0.1346110701560974, -0.12930718064308167, -0.12715119123458862,
  -0.1315387636423111, -0.14357160031795502, -0.14946065843105316,
  -0.13738073408603668, -0.1268700361251831, -0.13619062304496765,
  -0.14740507304668427, -0.144905686378479, -0.13783025741577148,
  -0.13359802961349487, -0.13775137066841125, -0.15119977295398712,
  -0.1547900140285492, -0.14355072379112244, -0.13897714018821716,
  -0.14206598699092865, -0.14288286864757538, -0.151199609041214,
  -0.16043619811534882, -0.15247198939323425, -0.14387793838977814,
  -0.14856871962547302, -0.15024752914905548, -0.15091031789779663,
  -0.1593923717737198, -0.15749475359916687, -0.14594975113868713,
  -0.14709687232971191, -0.1511668860912323, -0.14634302258491516,
  -0.15090511739253998, -0.16222631931304932, -0.15650512278079987,
  -0.14246590435504913, -0.14280183613300323, -0.15418113768100739,
  -0.16241100430488586, -0.16096487641334534, -0.15254536271095276,
  -0.14833964407444, -0.1529925912618637, -0.15840774774551392,
  -0.15883663296699524, -0.15586242079734802, -0.1532021164894104,
  -0.15323354303836823, -0.15269014239311218, -0.14959898591041565,
  -0.14985451102256775, -0.15350249409675598, -0.15301178395748138,
  -0.15063050389289856, -0.15115603804588318, -0.14834310114383698,
  -0.14277836680412292, -0.14653007686138153, -0.15458117425441742,
  -0.14912112057209015, -0.13774949312210083, -0.1421198546886444,
  -0.152602419257164, -0.14682917296886444, -0.13576054573059082,
  -0.13677525520324707, -0.13876472413539886, -0.13682736456394196,
  -0.14230485260486603, -0.1445300132036209, -0.1316368579864502,
  -0.12406910955905914, -0.13282474875450134, -0.1399625986814499,
  -0.13855057954788208, -0.13326801359653473, -0.12365018576383591,
  -0.11848506331443787, -0.12391511350870132, -0.1273786425590515,
  -0.12590675055980682, -0.1281498223543167, -0.12706045806407928,
  -0.11690293997526169, -0.11018847674131393, -0.11366048455238342,
  -0.12084876745939255, -0.1238359734416008, -0.11648531258106232,
  -0.10356380045413971, -0.09905117005109787, -0.10639304667711258,
  -0.11598173528909683, -0.11541299521923065, -0.10207497328519821,
  -0.09396996349096298, -0.10230045020580292, -0.10686858743429184,
  -0.0970258042216301, -0.09143028408288956, -0.09537824988365173,
  -0.09956858307123184, -0.10340758413076401, -0.09824502468109131,
  -0.0810064971446991, -0.07675345987081528, -0.09056802839040756,
  -0.09524775296449661, -0.0883815735578537, -0.08352511376142502,
  -0.07717988640069962, -0.07631642371416092, -0.08739988505840302,
  -0.08659231662750244, -0.07119342684745789, -0.07287884503602982,
  -0.08697744458913803, -0.08242897689342499, -0.06620441377162933,
  -0.06109745800495148, -0.06600457429885864, -0.07158509641885757,
  -0.07122037559747696, -0.06247280538082123, -0.0552995391190052,
  -0.058074142783880234, -0.06379691511392593, -0.06256821751594543,
  -0.054007116705179214, -0.05013235658407211, -0.06117825210094452,
  -0.07095640897750854, -0.058972083032131195, -0.04180881381034851,
  -0.044556111097335815, -0.05379185080528259, -0.043256256729364395,
  -0.019974425435066223, -0.012539644725620747, -0.028048787266016006,
  -0.04181183502078056, -0.032828740775585175, -0.013398222625255585,
  -0.005554680712521076, -0.01047020684927702, -0.02058352902531624,
  -0.028959546238183975, -0.025949301198124886, -0.016295313835144043,
  -0.012665259651839733, -0.008351770229637623, 0.0027468090411275625,
  0.00842338614165783, 0.007918978109955788, 0.0107246283441782,
  0.012084007263183594, 0.011788883246481419, 0.021175649017095566,
  0.036512650549411774, 0.043925940990448, 0.037479449063539505,
  0.022212877869606018, 0.019268404692411423, 0.03905465453863144,
  0.054367512464523315, 0.05085127428174019, 0.05570095777511597,
  0.0729389563202858, 0.07242067903280258, 0.05745160952210426,
  0.058229342103004456, 0.07462415099143982, 0.0856313556432724,
  0.08520680665969849, 0.08110161125659943, 0.08118609338998795,
  0.0879167839884758, 0.09859441220760345, 0.10749159753322601,
  0.10897545516490936, 0.10742419958114624, 0.11435014754533768,
  0.12751378118991852, 0.1340603083372116, 0.1316203773021698,
  0.12736165523529053, 0.12962612509727478, 0.14006663858890533,
  0.14540763199329376, 0.1391923725605011, 0.1376999169588089,
  0.14697179198265076, 0.1543540209531784, 0.15981359779834747,
  0.16647714376449585, 0.16641688346862793, 0.16614896059036255,
  0.17755459249019623, 0.18848839402198792, 0.1854480654001236,
  0.17513711750507355, 0.17062394320964813, 0.18017244338989258,
  0.19460077583789825, 0.1953684687614441, 0.18959736824035645,
  0.19302596151828766, 0.1952274739742279, 0.19049404561519623,
  0.19106292724609375, 0.19494251906871796, 0.20011919736862183,
  0.21355721354484558, 0.21835626661777496, 0.20066462457180023,
  0.18541119992733002, 0.19036801159381866, 0.20397211611270905,
  0.21486499905586243, 0.2142472118139267, 0.20600533485412598,
  0.20977461338043213, 0.2155267894268036, 0.20257088541984558,
  0.19676899909973145, 0.2165514975786209, 0.2298588752746582,
  0.22392699122428894, 0.21657012403011322, 0.2071073353290558,
  0.19977132976055145, 0.20949146151542664, 0.2201228141784668,
  0.21554550528526306, 0.21183094382286072, 0.2136419415473938,
  0.20990800857543945, 0.20653150975704193, 0.2085036039352417,
  0.20925229787826538, 0.21104416251182556, 0.21644850075244904,
  0.2179000824689865, 0.21206870675086975, 0.20387114584445953,
  0.20083363354206085, 0.20689009130001068, 0.21466508507728577,
  0.21373678743839264, 0.20322445034980774, 0.19134457409381866,
  0.19110773503780365, 0.20383445918560028, 0.21037615835666656,
  0.20151033997535706, 0.19036714732646942, 0.18514998257160187,
  0.18779289722442627, 0.1983354389667511, 0.2005976438522339,
  0.1886441856622696, 0.18468347191810608, 0.19056273996829987,
  0.18537753820419312, 0.17662346363067627, 0.17917439341545105,
  0.18065614998340607, 0.17515619099140167, 0.17267553508281708,
  0.17105595767498016, 0.16502846777439117, 0.159972682595253,
  0.1616884469985962, 0.16771556437015533, 0.16291119158267975,
  0.14064322412014008, 0.1273585706949234, 0.1422111690044403,
  0.15638716518878937, 0.14323067665100098, 0.12506863474845886,
  0.12949006259441376, 0.14362908899784088, 0.1382940113544464,
  0.11571618169546127, 0.10765896737575531, 0.12256750464439392,
  0.1341027021408081, 0.1271371841430664, 0.10852251946926117,
  0.09087236225605011, 0.09061028808355331, 0.10217718780040741,
  0.0998108983039856, 0.09189833700656891, 0.0958385318517685,
  0.08673109859228134, 0.06482768803834915, 0.07424626499414444,
  0.09691357612609863, 0.07788314670324326, 0.04433434084057808,
  0.05029032379388809, 0.07552824169397354, 0.07762393355369568,
  0.056744687259197235, 0.03828316554427147, 0.04449858516454697,
  0.06523215025663376, 0.05978957191109657, 0.023614518344402313,
  0.006799509283155203, 0.03344418853521347, 0.06015998125076294,
  0.04506645351648331, 0.010567069053649902, 0.001418348983861506,
  0.01873275637626648, 0.03273646533489227, 0.023679962381720543,
  -0.002562451409175992, -0.0077121383510529995, 0.026546750217676163,
  0.0393642783164978, -0.009124474599957466, -0.042966004461050034,
  -0.011536739766597748, 0.015944158658385277, -0.00017434920300729573,
  -0.01610417291522026, -0.020177345722913742, -0.024209165945649147,
  -0.010887403972446918, 0.003197127254679799, -0.021077103912830353,
  -0.05554908514022827, -0.05014369636774063, -0.0186624713242054,
  -0.004116898402571678, -0.02001318894326687, -0.04783422499895096,
  -0.06193646788597107, -0.05852675437927246, -0.04953639209270477,
  -0.03842061385512352, -0.03243405744433403, -0.04265063256025314,
  -0.05697927623987198, -0.06214970722794533, -0.06814417243003845,
  -0.07293815910816193, -0.062154822051525116, -0.052539996802806854,
  -0.0647687017917633, -0.07827365398406982, -0.07303530722856522,
  -0.06746172159910202, -0.07832784950733185, -0.09493833035230637,
  -0.09984780848026276, -0.08099856972694397, -0.051603998988866806,
  -0.0576070211827755, -0.10469361394643784, -0.12578928470611572,
  -0.09624592214822769, -0.08007947355508804, -0.10151905566453934,
  -0.11365630477666855, -0.10903056710958481, -0.11048515886068344,
  -0.10238071531057358, -0.08681712299585342, -0.10352397710084915,
  -0.13837461173534393, -0.1344635784626007, -0.09642931818962097,
  -0.08289644122123718, -0.1121579185128212, -0.13982127606868744,
  -0.13167020678520203, -0.1147814616560936, -0.12159615755081177,
  -0.13636815547943115, -0.14167410135269165, -0.14182111620903015,
  -0.12968719005584717, -0.11234690994024277, -0.12039097398519516,
  -0.14517509937286377, -0.15530060231685638, -0.15183435380458832,
  -0.1364416629076004, -0.11669105291366577, -0.13599228858947754,
  -0.1826574206352234, -0.17764456570148468, -0.12977267801761627,
  -0.12488224357366562, -0.16070136427879333, -0.17486484348773956,
  -0.16458800435066223, -0.1530897319316864, -0.14238803088665009,
  -0.1424792855978012, -0.15852215886116028, -0.17132554948329926,
  -0.1716921180486679, -0.16440008580684662, -0.14875663816928864,
  -0.14124630391597748, -0.16600152850151062, -0.1942744106054306,
  -0.177928164601326, -0.14760294556617737, -0.16036087274551392,
  -0.18505148589611053, -0.17348183691501617, -0.15443597733974457,
  -0.15108716487884521, -0.14816558361053467, -0.16776026785373688,
  -0.20749004185199738, -0.18864689767360687, -0.12036001682281494,
  -0.12082598358392715, -0.18527919054031372, -0.19423452019691467,
  -0.15054719150066376, -0.14876499772071838, -0.18580769002437592,
  -0.19443389773368835, -0.16367019712924957, -0.13303804397583008,
  -0.13965684175491333, -0.17789886891841888, -0.195139080286026,
  -0.16551761329174042, -0.13382217288017273, -0.1434771567583084,
  -0.175582155585289, -0.1858750283718109, -0.1606924831867218,
  -0.13117456436157227, -0.14184163510799408, -0.18679243326187134,
  -0.1997033655643463, -0.15041615068912506, -0.10525750368833542,
  -0.1288508176803589, -0.18535493314266205, -0.20246104896068573,
  -0.17236453294754028, -0.14118888974189758, -0.14119912683963776,
  -0.15744559466838837, -0.15962722897529602, -0.15325598418712616,
  -0.154175266623497, -0.14774161577224731, -0.13961149752140045,
  -0.16096393764019012, -0.1826818585395813, -0.15570102632045746,
  -0.11474653333425522, -0.11388473957777023, -0.1408318132162094,
  -0.1621459573507309, -0.15956996381282806, -0.13767725229263306,
  -0.1344408541917801, -0.15729692578315735, -0.1611199676990509,
  -0.1404954344034195, -0.13102973997592926, -0.13462518155574799,
  -0.13860352337360382, -0.13834607601165771, -0.12730377912521362,
  -0.12147172540426254, -0.12898536026477814, -0.12414011359214783,
  -0.12024344503879547, -0.13991115987300873, -0.13414403796195984,
  -0.0934939831495285, -0.09199141710996628, -0.12751053273677826,
  -0.13627707958221436, -0.12467950582504272, -0.11169052124023438,
  -0.08935661613941193, -0.08440376818180084, -0.10555851459503174,
  -0.11537517607212067, -0.10932040959596634, -0.09788881987333298,
  -0.07906703650951385, -0.07931312918663025, -0.10058683902025223,
  -0.10003665089607239, -0.08506214618682861, -0.08357778191566467,
  -0.07804656028747559, -0.07214342057704926, -0.07982711493968964,
  -0.0731348991394043, -0.061466384679079056, -0.0759621113538742,
  -0.07766933739185333, -0.05406542122364044, -0.061238810420036316,
  -0.083870068192482, -0.0657816082239151, -0.03460221737623215,
  -0.031330861151218414, -0.043968599289655685, -0.05831824615597725,
  -0.06145727261900902, -0.045180365443229675, -0.0308739822357893,
  -0.030316133052110672, -0.040611498057842255, -0.06408243626356125,
  -0.06816559284925461, -0.03175398334860802, -0.014414770528674126,
  -0.04104018583893776, -0.05003964155912399, -0.035952042788267136,
  -0.04092685133218765, -0.041278157383203506, -0.022791890427470207,
  -0.02626010589301586, -0.03916936740279198, -0.021814927458763123,
  -0.0014547471655532718, -0.005501687992364168, -0.011080337688326836,
  -0.0047860112972557545, 0.007240894250571728, 0.012228986248373985,
  -0.009194692596793175, -0.03208339214324951, -0.009201507084071636,
  0.029640592634677887, 0.03036533109843731, 0.010687093250453472,
  -0.011139423586428165, -0.04139632359147072, -0.031115936115384102,
  0.041305337101221085, 0.08285008370876312, 0.044310394674539566,
  0.0052526239305734634, 0.017236024141311646, 0.04522494971752167,
  0.058933284133672714, 0.05386427417397499, 0.045430947095155716,
  0.06047320365905762, 0.07655075192451477, 0.05746548995375633,
  0.03863796964287758, 0.05239612236618996, 0.06314244866371155,
  0.05652294680476189, 0.058291248977184296, 0.07181321829557419,
  0.09320890158414841, 0.11304248869419098, 0.10024405270814896,
  0.06690426915884018, 0.06726928800344467, 0.10275647789239883,
  0.12880980968475342, 0.1200481727719307, 0.08326427638530731,
  0.06530768424272537, 0.10429080575704575, 0.15255089104175568,
  0.14926190674304962, 0.11901689320802689, 0.10963728278875351,
  0.1276206225156784, 0.15768331289291382, 0.1683114469051361,
  0.14418239891529083, 0.1249527558684349, 0.14199033379554749,
  0.1683778017759323, 0.16950033605098724, 0.1405523419380188,
  0.11179311573505402, 0.12982983887195587, 0.1817179024219513,
  0.19734494388103485, 0.167636439204216, 0.15154100954532623,
  0.16430650651454926, 0.17149902880191803, 0.16744649410247803,
  0.17591778934001923, 0.19528290629386902, 0.19740436971187592,
  0.18020671606063843, 0.17324496805667877, 0.18310904502868652,
  0.18814492225646973, 0.1795825958251953, 0.16513709723949432,
  0.16425706446170807, 0.1929624229669571, 0.2180798202753067,
  0.19719137251377106, 0.16270600259304047, 0.164854496717453,
  0.19157303869724274, 0.21323269605636597, 0.2143511176109314,
  0.19279834628105164, 0.18430495262145996, 0.2038368135690689,
  0.20301608741283417, 0.17855310440063477, 0.1840537041425705,
  0.20795005559921265, 0.20952348411083221, 0.20210202038288116,
  0.19171454012393951, 0.17322421073913574, 0.18259947001934052,
  0.22218194603919983, 0.22920866310596466, 0.1829262524843216,
  0.13849960267543793, 0.15028223395347595, 0.20070195198059082,
  0.21232512593269348, 0.1696169376373291, 0.15093469619750977,
  0.177982896566391, 0.1933884471654892, 0.18923088908195496,
  0.18180391192436218, 0.16219569742679596, 0.1649305671453476,
  0.2065247744321823, 0.21079441905021667, 0.16498544812202454,
  0.15835216641426086, 0.18742473423480988, 0.17322483658790588,
  0.13510534167289734, 0.13789452612400055, 0.1730300486087799,
  0.19799251854419708, 0.1890009641647339, 0.15092895925045013,
  0.12444408237934113, 0.13517916202545166, 0.15391534566879272,
  0.15621061623096466, 0.1596435159444809, 0.17238125205039978,
  0.17253457009792328, 0.15208251774311066, 0.1318368911743164,
  0.12841439247131348, 0.13897733390331268, 0.15367360413074493,
  0.1569206565618515, 0.13633163273334503, 0.1119929626584053,
  0.11710494756698608, 0.1384074091911316, 0.13279739022254944,
  0.10291368514299393, 0.09225064516067505, 0.10952102392911911,
  0.12208958715200424, 0.11965572834014893, 0.11716540157794952,
  0.10841576755046844, 0.09024335443973541, 0.09181283414363861,
  0.11422538012266159, 0.12198065221309662, 0.11741848289966583,
  0.11225437372922897, 0.07866845279932022, 0.03449136018753052,
  0.04751197621226311, 0.1005975604057312, 0.11309731006622314,
  0.0772489532828331, 0.044998396188020706, 0.04402172937989235,
  0.06152838096022606, 0.06661543995141983, 0.05897991731762886,
  0.0663943663239479, 0.0742093175649643, 0.059132710099220276,
  0.05443586781620979, 0.0663299709558487, 0.05124911293387413,
  0.028393622487783432, 0.039396099746227264, 0.04941356182098389,
  0.03656217083334923, 0.03860790655016899, 0.04965723305940628,
  0.03510405495762825, 0.020239194855093956, 0.037547606974840164,
  0.05524992197751999, 0.035448119044303894, 0.009266315959393978,
  0.024267196655273438, 0.043071672320365906, 0.01001055259257555,
  -0.0177482757717371, 0.022149307653307915, 0.05679361894726753,
  0.02081824280321598, -0.030471518635749817, -0.04253168776631355,
  -0.011433900333940983, 0.041251689195632935, 0.04223531857132912,
  -0.027542054653167725, -0.05866670608520508, -0.01244136318564415,
  0.016911927610635757, 0.00509345717728138, -0.0065970653668046,
  -0.02824382670223713, -0.04090641438961029, -0.00936999823898077,
  0.0034220414236187935, -0.03907501697540283, -0.06223209574818611,
  -0.050060976296663284, -0.05163074657320976, -0.040783774107694626,
  -0.0007120915106497705, 0.0004441509081516415, -0.050483375787734985,
  -0.08833779394626617, -0.0878572091460228, -0.05025264248251915,
  0.017651695758104324, 0.03405170887708664, -0.06417902559041977,
  -0.16226035356521606, -0.12327228486537933, -0.02926298975944519,
  -0.029771005734801292, -0.08350512385368347, -0.08791611343622208,
  -0.07932605594396591, -0.10007616132497787, -0.09292156249284744,
  -0.0532430075109005, -0.05020860582590103, -0.07740676403045654,
  -0.08807999640703201, -0.10082324594259262, -0.12789605557918549,
  -0.1250448226928711, -0.08491714298725128, -0.06630364060401917,
  -0.10308869928121567, -0.14906296133995056, -0.1415770947933197,
  -0.09949207305908203, -0.0897737368941307, -0.10860530287027359,
  -0.10718327015638351, -0.09628183394670486, -0.10876591503620148,
  -0.13276246190071106, -0.14619523286819458, -0.13619506359100342,
  -0.10819276422262192, -0.10910115391016006, -0.14116482436656952,
  -0.12632711231708527, -0.08037620782852173, -0.11649276316165924,
  -0.19381751120090485, -0.16753727197647095, -0.07938369363546371,
  -0.07394732534885406, -0.1368868052959442, -0.17018595337867737,
  -0.1556890457868576, -0.1232028678059578, -0.11274980753660202,
  -0.14412619173526764, -0.15740430355072021, -0.116935595870018,
  -0.11115939170122147, -0.17627906799316406, -0.20259875059127808,
  -0.15227331221103668, -0.11941999197006226, -0.129060298204422,
  -0.13552764058113098, -0.15948396921157837, -0.20622390508651733,
  -0.19375430047512054, -0.11867111176252365, -0.09968994557857513,
  -0.1651657223701477, -0.2053573578596115, -0.17689819633960724,
  -0.1558038741350174, -0.17170673608779907, -0.16645239293575287,
  -0.1314517855644226, -0.13668780028820038, -0.19619235396385193,
  -0.22824004292488098, -0.18854504823684692, -0.1327531486749649,
  -0.11199744790792465, -0.12494906783103943, -0.17524391412734985,
  -0.23775945603847504, -0.22790496051311493, -0.14556199312210083,
  -0.1132265031337738, -0.16715310513973236, -0.2085656374692917,
  -0.1922367960214615, -0.1593567281961441, -0.13957999646663666,
  -0.1532554030418396, -0.19517937302589417, -0.20420405268669128,
  -0.16304457187652588, -0.1329408437013626, -0.14150403439998627,
  -0.1664872169494629, -0.18725766241550446, -0.1786399632692337,
  -0.15077510476112366, -0.15437725186347961, -0.17511844635009766,
  -0.16251231729984283, -0.14273999631404877, -0.15076206624507904,
  -0.16805993020534515, -0.18880636990070343, -0.19798864424228668,
  -0.161538228392601, -0.12170524150133133, -0.129585862159729,
  -0.1384020894765854, -0.12820404767990112, -0.15000131726264954,
  -0.18329784274101257, -0.17238211631774902, -0.13808579742908478,
  -0.11485684663057327, -0.11381252855062485, -0.1395939290523529,
  -0.15034322440624237, -0.12596495449543, -0.1311466246843338,
  -0.16629628837108612, -0.14685939252376556, -0.09329419583082199,
  -0.0905478373169899, -0.12181290239095688, -0.13696067035198212,
  -0.13686582446098328, -0.12236746400594711, -0.10017050802707672,
  -0.09499847888946533, -0.10517780482769012, -0.12045309692621231,
  -0.13196733593940735, -0.10831757634878159, -0.058992624282836914,
  -0.04821104556322098, -0.08663657307624817, -0.12870945036411285,
  -0.1464509814977646, -0.12240644544363022, -0.07200383394956589,
  -0.05979903042316437, -0.08950954675674438, -0.09298302233219147,
  -0.07222148030996323, -0.08439449220895767, -0.10855033993721008,
  -0.08159268647432327, -0.02505779080092907, -0.0266012754291296,
  -0.0966060534119606, -0.131122887134552, -0.08389275521039963,
  -0.053055290132761, -0.08726398646831512, -0.09601222723722458,
  -0.04744939133524895, -0.025780949741601944, -0.06267160922288895,
  -0.106348916888237, -0.1121465265750885, -0.0705481618642807,
  -0.022208798676729202, -0.022917604073882103, -0.05528968945145607,
  -0.06979041546583176, -0.06980627775192261, -0.06189591437578201,
  -0.03066740557551384, 0.0026462229434400797, 0.009022868238389492,
  0.00006783060962334275, -0.010613312013447285, -0.020574213936924934,
  -0.023036295548081398, -0.02144494652748108, -0.011701109819114208,
  0.017310459166765213, 0.025307612493634224, -0.03238043561577797,
  -0.09804267436265945, -0.08236832171678543, -0.001617654343135655,
  0.06069347634911537, 0.06606835871934891, 0.03430606424808502,
  0.0017357097240164876, -0.003384668380022049, 0.02702510915696621,
  0.07362224906682968, 0.10004225373268127, 0.08870347589254379,
  0.05924410745501518, 0.03538607805967331, 0.021226832643151283,
  0.022720320150256157, 0.048496946692466736, 0.08317036926746368,
  0.09898678213357925, 0.0911535993218422, 0.0788336768746376,
  0.07852920144796371, 0.08593573421239853, 0.08832703530788422,
  0.09262978285551071, 0.1120443344116211, 0.12497448921203613,
  0.1035737693309784, 0.07882820814847946, 0.09650525450706482,
  0.12859292328357697, 0.12746039032936096, 0.12022272497415543,
  0.14520679414272308, 0.17297974228858948, 0.17360463738441467,
  0.16523240506649017, 0.1600259393453598, 0.1518365442752838,
  0.14178583025932312, 0.12940926849842072, 0.12275518476963043,
  0.1387387067079544, 0.15859605371952057, 0.15008367598056793,
  0.12700118124485016, 0.12129972130060196, 0.14453688263893127,
  0.1890818327665329, 0.21612288057804108, 0.19913405179977417,
  0.1799176037311554, 0.1890908181667328, 0.18809349834918976,
  0.1607816517353058, 0.14136624336242676, 0.14963404834270477,
  0.18483686447143555, 0.21528257429599762, 0.18783064186573029,
  0.1318875253200531, 0.13195432722568512, 0.1711273044347763,
  0.17887622117996216, 0.1684175282716751, 0.17687062919139862,
  0.19112782180309296, 0.19421809911727905, 0.1818384975194931,
  0.16090621054172516, 0.1605711430311203, 0.18611247837543488,
  0.20419318974018097, 0.19699695706367493, 0.1760127693414688,
  0.1678304672241211, 0.1862080693244934, 0.19393698871135712,
  0.1706281304359436, 0.17114447057247162, 0.20360615849494934,
  0.19591060280799866, 0.15288104116916656, 0.14714406430721283,
  0.17594072222709656, 0.18765461444854736, 0.16493657231330872,
  0.13232974708080292, 0.14094364643096924, 0.18548250198364258,
  0.1883866935968399, 0.15439310669898987, 0.1624465435743332,
  0.1900785267353058, 0.178428515791893, 0.16293400526046753,
  0.16719681024551392, 0.1591392606496811, 0.15121330320835114,
  0.16705207526683807, 0.1799626648426056, 0.16288460791110992,
  0.1213473528623581, 0.10091670602560043, 0.1410141885280609,
  0.17938272655010223, 0.1521383672952652, 0.13170893490314484,
  0.15647941827774048, 0.1417195200920105, 0.10145474225282669,
  0.12505970895290375, 0.160551518201828, 0.14173197746276855,
  0.12625108659267426, 0.12997139990329742, 0.1120554506778717,
  0.10548888891935349, 0.13010071218013763, 0.13924844563007355,
  0.129566490650177, 0.12051129341125488, 0.10709115862846375,
  0.11313927918672562, 0.13621051609516144, 0.11451443284749985,
  0.06439121067523956, 0.06518413126468658, 0.10252746939659119,
  0.11709853261709213, 0.10897304862737656, 0.09363113343715668,
  0.08381687104701996, 0.09540881961584091, 0.10618282854557037,
  0.09728220850229263, 0.08831839263439178, 0.0761934444308281,
  0.0678270161151886, 0.10417933017015457, 0.13241030275821686,
  0.06747664511203766, -0.007435561623424292, 0.014528518542647362,
  0.06719918549060822, 0.08527939766645432, 0.09816554933786392,
  0.08632960170507431, 0.03821706399321556, 0.027299879118800163,
  0.054807137697935104, 0.059265218675136566, 0.06662635505199432,
  0.0907844752073288, 0.0733608528971672, 0.04123006388545036,
  0.060608554631471634, 0.07811007648706436, 0.03677075356245041,
  -0.00134330615401268, 0.01867208443582058, 0.057468656450510025,
  0.06636697798967361, 0.05018259957432747, 0.027276307344436646,
  -0.000016986505215754732, -0.00016688031610101461, 0.055474258959293365,
  0.08306150138378143, 0.00862039439380169, -0.04483732208609581,
  0.026721298694610596, 0.09226524829864502, 0.054195303469896317,
  0.0106102479621768, 0.008946647867560387, 0.0025705290026962757,
  0.011113271117210388, 0.04536746069788933, 0.047895219177007675,
  0.018710605800151825, 0.006585380993783474, 0.012225005775690079,
  0.012767567299306393, 0.005349737126380205, 0.007424089126288891,
  0.022331971675157547, 0.005514342337846756, -0.04476416856050491,
  -0.0404147133231163, 0.028703851625323296, 0.058574337512254715,
  0.016910269856452942, -0.039527084678411484, -0.06445939093828201,
  -0.028654346242547035, 0.02715892530977726, 0.008154869079589844,
  -0.055070795118808746, -0.051493506878614426, -0.004560278728604317,
  -0.00146153022069484, -0.027577389031648636, -0.03031424805521965,
  -0.015109033323824406, -0.02533608302474022, -0.06671769917011261,
  -0.07149576395750046, -0.01526164636015892, 0.015601168386638165,
  -0.01981152407824993, -0.07109245657920837, -0.11068201065063477,
  -0.1037701815366745, -0.027515951544046402, 0.018682939931750298,
  -0.022189529612660408, -0.056189391762018204, -0.07358229905366898,
  -0.12924043834209442, -0.12517298758029938, -0.0139850452542305,
  0.04280088096857071, -0.029884200543165207, -0.12311246246099472,
  -0.1558951437473297, -0.121603824198246, -0.062021493911743164,
  -0.04054231196641922, -0.03230415657162666, -0.005463700741529465,
  -0.04526117444038391, -0.15677571296691895, -0.1944401115179062,
  -0.13125260174274445, -0.07298675924539566, -0.04814591258764267,
  -0.04698367044329643, -0.09238434582948685, -0.1517494022846222,
  -0.15636880695819855, -0.10425148904323578, -0.0592334158718586,
  -0.07838720828294754, -0.13204360008239746, -0.14185959100723267,
  -0.12300559133291245, -0.13642139732837677, -0.14431443810462952,
  -0.11389274895191193, -0.11628809571266174, -0.16298887133598328,
  -0.16348527371883392, -0.11274393647909164, -0.09831982851028442,
  -0.1357710361480713, -0.1657072901725769, -0.16298744082450867,
  -0.14474999904632568, -0.12191841006278992, -0.10774295032024384,
  -0.1284312605857849, -0.1750352382659912, -0.20098663866519928,
  -0.1974203884601593, -0.1845303624868393, -0.1471482217311859,
  -0.09353677928447723, -0.0946621522307396, -0.1575068086385727,
  -0.1962192803621292, -0.18275007605552673, -0.16621507704257965,
  -0.1600014865398407, -0.14773719012737274, -0.1366693526506424,
  -0.13491158187389374, -0.14735902845859528, -0.17748861014842987,
  -0.1930854767560959, -0.17312082648277283, -0.16402296721935272,
  -0.18681679666042328, -0.19035229086875916, -0.16701160371303558,
  -0.16107288002967834, -0.16674315929412842, -0.16634728014469147,
  -0.17781205475330353, -0.1843601018190384, -0.16337832808494568,
  -0.15510335564613342, -0.17102165520191193, -0.16768209636211395,
  -0.15798497200012207, -0.1695847362279892, -0.16566148400306702,
  -0.14919328689575195, -0.17654797434806824, -0.21602864563465118,
  -0.1943325400352478, -0.14476728439331055, -0.13920117914676666,
  -0.16173139214515686, -0.16833806037902832, -0.1736450344324112,
  -0.18951427936553955, -0.1726527065038681, -0.11655576527118683,
  -0.08854476362466812, -0.11764886975288391, -0.16934654116630554,
  -0.20809562504291534, -0.18746691942214966, -0.11447259038686752,
  -0.10207396745681763, -0.16108304262161255, -0.16048818826675415,
  -0.11879628151655197, -0.14218877255916595, -0.1637192964553833,
  -0.14275555312633514, -0.17050574719905853, -0.19260713458061218,
  -0.1250593364238739, -0.08673113584518433, -0.12173469364643097,
  -0.12906591594219208, -0.1373617947101593, -0.17403145134449005,
  -0.1483391672372818, -0.09598351269960403, -0.09875968843698502,
  -0.1069541648030281, -0.12662406265735626, -0.17922592163085938,
  -0.16094602644443512, -0.10119844973087311, -0.13126476109027863,
  -0.16166619956493378, -0.11124201118946075, -0.1070803850889206,
  -0.15563809871673584, -0.14866535365581512, -0.12556812167167664,
  -0.12427341938018799, -0.10516490787267685, -0.09854642301797867,
  -0.1113089993596077, -0.09809094667434692, -0.0866003930568695,
  -0.07975539565086365, -0.045947425067424774, -0.04318474605679512,
  -0.07092983275651932, -0.04988739639520645, -0.03472479060292244,
  -0.07045286893844604, -0.06651245802640915, -0.0428309291601181,
  -0.07555544376373291, -0.09313276410102844, -0.07796001434326172,
  -0.10179700702428818, -0.10441872477531433, -0.045152775943279266,
  -0.018411919474601746, -0.02310519479215145, 0.001667988020926714,
  0.014982416294515133, 0.007330847438424826, 0.01975868083536625,
  0.028707368299365044, 0.031687650829553604, 0.05110658332705498,
  0.04377371445298195, 0.012783164158463478, 0.01981314830482006,
  0.0358702689409256, 0.02138597145676613, 0.023953473195433617,
  0.056524608284235, 0.0768674910068512, 0.07611450552940369,
  0.06290217489004135, 0.05165969580411911, 0.062268614768981934,
  0.08075065165758133, 0.09516045451164246, 0.11680807173252106,
  0.12338267266750336, 0.09982617199420929, 0.088772252202034,
  0.1111571416258812, 0.1405896693468094, 0.157243549823761, 0.1545587182044983,
  0.15156036615371704, 0.17165718972682953, 0.1771608293056488,
  0.14146947860717773, 0.12469946593046188, 0.15071983635425568,
  0.16077284514904022, 0.14504222571849823, 0.14415216445922852,
  0.15156961977481842, 0.1429113894701004, 0.13491487503051758,
  0.15604695677757263, 0.19642245769500732, 0.21397118270397186,
  0.20226524770259857, 0.1998397558927536, 0.20582741498947144,
  0.19094286859035492, 0.18096566200256348, 0.2027769684791565,
  0.21716932952404022, 0.19983208179473877, 0.18569684028625488,
  0.1944141685962677, 0.19941501319408417, 0.17983657121658325,
  0.1624908447265625, 0.18279269337654114, 0.215383380651474,
  0.21933476626873016, 0.21579872071743011, 0.2197912037372589,
  0.19957485795021057, 0.17766651511192322, 0.206770658493042,
  0.24462628364562988, 0.2305818349123001, 0.20391428470611572,
  0.206856831908226, 0.2055428922176361, 0.1869809627532959,
  0.19029349088668823, 0.21543043851852417, 0.22596293687820435,
  0.22616800665855408, 0.23192735016345978, 0.21998663246631622,
  0.18864493072032928, 0.1789446473121643, 0.19307787716388702,
  0.20104952156543732, 0.2052965760231018, 0.20695310831069946,
  0.19263270497322083, 0.18204718828201294, 0.18929874897003174,
  0.19361594319343567, 0.19844716787338257, 0.21375182271003723,
  0.21573412418365479, 0.2032848447561264, 0.19689758121967316,
  0.18875941634178162, 0.1776280701160431, 0.17417079210281372,
  0.17097659409046173, 0.17390389740467072, 0.17844100296497345,
  0.15150591731071472, 0.12777897715568542, 0.1621624231338501,
  0.19565239548683167, 0.1783347874879837, 0.1673387885093689,
  0.17431232333183289, 0.1623881608247757, 0.15422053635120392,
  0.15123800933361053, 0.13188529014587402, 0.14164216816425323,
  0.17883022129535675, 0.16915389895439148, 0.13314048945903778,
  0.12822510302066803, 0.13067485392093658, 0.13392728567123413,
  0.14791421592235565, 0.13565890491008759, 0.11601567268371582,
  0.13138127326965332, 0.13108427822589874, 0.10263021290302277,
  0.10693127661943436, 0.11053240299224854, 0.07601309567689896,
  0.08117105066776276, 0.13095852732658386, 0.13459107279777527,
  0.10671639442443848, 0.10448776930570602, 0.10015347599983215,
  0.08368255198001862, 0.08767090737819672, 0.09894154965877533,
  0.09846717119216919, 0.09748005867004395, 0.09006112068891525,
  0.07083617895841599, 0.0579109713435173, 0.05606354400515556,
  0.058193597942590714, 0.061218950897455215, 0.058038659393787384,
  0.05799451470375061, 0.07782603800296783, 0.0929623544216156,
  0.07529685646295547, 0.052319932729005814, 0.051766376942396164,
  0.056549981236457825, 0.05986035242676735, 0.07132627069950104,
  0.0689832791686058, 0.04181889444589615, 0.031204186379909515,
  0.0522434301674366, 0.061830341815948486, 0.04598035663366318,
  0.03499501198530197, 0.03661295026540756, 0.042087066918611526,
  0.0537409633398056, 0.059842973947525024, 0.04301205277442932,
  0.017940016463398933, 0.015086382627487183, 0.037397321313619614,
  0.051783666014671326, 0.03649609908461571, 0.02434861846268177,
  0.04231954365968704, 0.051210999488830566, 0.0272364504635334,
  0.012553826905786991, 0.023518580943346024, 0.027178457006812096,
  0.017012080177664757, 0.014258711598813534, 0.022285813465714455,
  0.01839003711938858, -0.01143583096563816, -0.021133800968527794,
  0.03054686263203621, 0.07112409174442291, 0.03190021589398384,
  -0.01816904917359352, -0.016814835369586945, 0.0025354798417538404,
  0.018866242840886116, 0.015507316216826439, -0.028740178793668747,
  -0.049031488597393036, -0.009395415894687176, 0.0022080696653574705,
  -0.023078929632902145, -0.007058089133352041, 0.0034543792717158794,
  -0.04462290555238724, -0.0639171376824379, -0.020726244896650314,
  0.012830609455704689, 0.013562262058258057, -0.015863949432969093,
  -0.06872723996639252, -0.08227774500846863, -0.04628415405750275,
  -0.03280435875058174, -0.044778238981962204, -0.03854486718773842,
  -0.029778122901916504, -0.03953799232840538, -0.061242420226335526,
  -0.0827697217464447, -0.07530765980482101, -0.04834870249032974,
  -0.05641776695847511, -0.0840439572930336, -0.07350774109363556,
  -0.04298420995473862, -0.04459761828184128, -0.08885462582111359,
  -0.13336363434791565, -0.1054246723651886, -0.023216208443045616,
  -0.010726955719292164, -0.09238869696855545, -0.16489653289318085,
  -0.169748917222023, -0.11152602732181549, -0.04228803515434265,
  -0.05792051553726196, -0.12925677001476288, -0.1279689520597458,
  -0.08878076076507568, -0.1062740758061409, -0.12161188572645187,
  -0.11467596143484116, -0.1567387878894806, -0.18568696081638336,
  -0.13818614184856415, -0.11657755821943283, -0.14413604140281677,
  -0.13001516461372375, -0.1136833131313324, -0.16002468764781952,
  -0.19025439023971558, -0.15378448367118835, -0.1151195764541626,
  -0.123462975025177, -0.1624545007944107, -0.1740735024213791,
  -0.13712282478809357, -0.1327364593744278, -0.19155526161193848,
  -0.21155105531215668, -0.17433661222457886, -0.16449272632598877,
  -0.16045662760734558, -0.13060322403907776, -0.15657083690166473,
  -0.22456830739974976, -0.21856927871704102, -0.1725662797689438,
  -0.18441987037658691, -0.2128203958272934, -0.2039615958929062,
  -0.19106462597846985, -0.18937242031097412, -0.1906556338071823,
  -0.1996641308069229, -0.20182079076766968, -0.20047152042388916,
  -0.21745435893535614, -0.21772512793540955, -0.18453313410282135,
  -0.18384242057800293, -0.22412678599357605, -0.24277418851852417,
  -0.24869179725646973, -0.26162707805633545, -0.23303717374801636,
  -0.1781059056520462, -0.17564339935779572, -0.21005380153656006,
  -0.2200368493795395, -0.2167326956987381, -0.22415384650230408,
  -0.2305184006690979, -0.2270190715789795, -0.21365207433700562,
  -0.20619386434555054, -0.2275758981704712, -0.25099971890449524,
  -0.23636101186275482, -0.21345342695713043, -0.22203204035758972,
  -0.23570698499679565, -0.22720091044902802, -0.22260305285453796,
  -0.2444555014371872, -0.26210394501686096, -0.2378370314836502,
  -0.20263700187206268, -0.21488632261753082, -0.24685633182525635,
  -0.2320583611726761, -0.19892309606075287, -0.21199916303157806,
  -0.2478753924369812, -0.25154218077659607, -0.2286895215511322,
  -0.21816976368427277, -0.23235072195529938, -0.2375718057155609,
  -0.20540428161621094, -0.17999477684497833, -0.20892676711082458,
  -0.24662305414676666, -0.24022556841373444, -0.2166082262992859,
  -0.2040044218301773, -0.19733533263206482, -0.2094966173171997,
  -0.23607048392295837, -0.23185722529888153, -0.19752106070518494,
  -0.1870250701904297, -0.2064165472984314, -0.20837518572807312,
  -0.1778235137462616, -0.14075930416584015, -0.10998372733592987,
  -0.08870642632246017, -0.09785444289445877, -0.13389891386032104,
  -0.14421819150447845, -0.10940361022949219, -0.08242223411798477,
  -0.0994538813829422, -0.13658404350280762, -0.16720424592494965,
  -0.18218031525611877, -0.17251083254814148, -0.1398807317018509,
  -0.09707624465227127, -0.058485761284828186, -0.04274934157729149,
  -0.04787478595972061, -0.03616047650575638, 0.011683665215969086,
  0.06044309213757515, 0.07145995646715164, 0.053316760808229446,
  0.03785780072212219, 0.029822612181305885, 0.01369304209947586,
  -0.0013773877872154117, 0.0038106776773929596, 0.013496735133230686,
  0.0073485709726810455, 0.0024281644728034735, 0.017149800434708595,
  0.04330035671591759, 0.07123343646526337, 0.09625905752182007,
  0.1142202690243721, 0.1283775418996811, 0.13668833673000336,
  0.1339767724275589, 0.13598737120628357, 0.15503770112991333,
  0.17082104086875916, 0.17068791389465332, 0.17560797929763794,
  0.1980559378862381, 0.2192215472459793, 0.21777623891830444,
  0.19503337144851685, 0.1721249371767044, 0.16790032386779785,
  0.18037402629852295, 0.19352665543556213, 0.19597573578357697,
  0.1887771040201187, 0.17975156009197235, 0.17433017492294312,
  0.17780593037605286, 0.19974951446056366, 0.2412036508321762,
  0.2846079468727112, 0.3066873252391815, 0.2990160286426544,
  0.27907341718673706, 0.2703310251235962, 0.2673080861568451,
  0.255258172750473, 0.2504160702228546, 0.2649092376232147,
  0.27021530270576477, 0.24465312063694, 0.20581823587417603,
  0.18259863555431366, 0.19646453857421875, 0.24058093130588531,
  0.2710607051849365, 0.2673114538192749, 0.25803184509277344,
  0.255919873714447, 0.24772143363952637, 0.24454282224178314,
  0.26121386885643005, 0.28046914935112, 0.28035008907318115,
  0.25639134645462036, 0.22248978912830353, 0.2095452845096588,
  0.2304774671792984, 0.2591213583946228, 0.26633521914482117,
  0.24534150958061218, 0.2115892618894577, 0.20082269608974457,
  0.22860130667686462, 0.25782984495162964, 0.2520102858543396,
  0.22422820329666138, 0.20333372056484222, 0.1986582726240158,
  0.20477013289928436, 0.2098538875579834, 0.2071463018655777,
  0.20290958881378174, 0.20574022829532623, 0.2186613231897354,
  0.2359507530927658, 0.23977407813072205, 0.21981003880500793,
  0.19334328174591064, 0.1839180439710617, 0.18989618122577667,
  0.19093455374240875, 0.1800599992275238, 0.17232602834701538,
  0.17306016385555267, 0.16399547457695007, 0.14436598122119904,
  0.14476589858531952, 0.17171616852283478, 0.18869702517986298,
  0.17460010945796967, 0.1549854576587677, 0.15942439436912537,
  0.1765131950378418, 0.17042873799800873, 0.1372203379869461,
  0.11412135511636734, 0.12175677716732025, 0.13791652023792267,
  0.14051517844200134, 0.13360457122325897, 0.12726832926273346,
  0.1252649575471878, 0.12704288959503174, 0.12569117546081543,
  0.1161913275718689, 0.10787332057952881, 0.1104755848646164,
  0.11031617224216461, 0.087701216340065, 0.056364934891462326,
  0.04677325859665871, 0.05906015634536743, 0.07886429131031036,
  0.10630228370428085, 0.12481307983398438, 0.10440734773874283,
  0.062889464199543, 0.04861830174922943, 0.067587710916996, 0.0907130315899849,
  0.0959857925772667, 0.07205954194068909, 0.029985997825860977,
  0.005115761421620846, 0.013036428019404411, 0.035204995423555374,
  0.05288376659154892, 0.06219416484236717, 0.0665137842297554,
  0.06662092357873917, 0.057443078607320786, 0.04539990425109863,
  0.04697706550359726, 0.0584266297519207, 0.06346505135297775,
  0.054226748645305634, 0.024422496557235718, -0.0058496561832726,
  0.00741841085255146, 0.044245216995477676, 0.040464162826538086,
  0.018474213778972626, 0.040677543729543686, 0.07550808787345886,
  0.06742601841688156, 0.04270755127072334, 0.04484899342060089,
  0.06792869418859482, 0.07770819962024689, 0.05084776133298874,
  0.013371789827942848, 0.005931389983743429, 0.014883208088576794,
  0.02168796956539154, 0.03602983057498932, 0.026124168187379837,
  -0.019343333318829536, -0.02037961408495903, 0.03206763416528702,
  0.04726521298289299, 0.0367630273103714, 0.052373647689819336,
  0.03311231732368469, -0.02320985496044159, -0.015837810933589935,
  0.026406362652778625, 0.02285189926624298, 0.033575739711523056,
  0.07551208883523941, 0.040621381253004074, -0.05766845867037773,
  -0.09625864773988724, -0.06369734555482864, -0.02079298347234726,
  0.00494247255846858, -0.00648917630314827, -0.04327438771724701,
  -0.059379059821367264, -0.05789753794670105, -0.0646081417798996,
  -0.06271126866340637, -0.038212381303310394, -0.0033034055959433317,
  0.02237144112586975, 0.0032008716370910406, -0.05741331726312637,
  -0.0871204137802124, -0.06695348024368286, -0.0629236102104187,
  -0.08547945320606232, -0.08877670764923096, -0.08792632073163986,
  -0.11520833522081375, -0.13831913471221924, -0.12732872366905212,
  -0.10259856283664703, -0.08351565897464752, -0.08003531396389008,
  -0.10619933903217316, -0.14051735401153564, -0.13677294552326202,
  -0.10128163546323776, -0.0865408256649971, -0.11126476526260376,
  -0.14969325065612793, -0.17659161984920502, -0.18086372315883636,
  -0.16244053840637207, -0.13868510723114014, -0.13553282618522644,
  -0.15647844970226288, -0.17219260334968567, -0.1661044955253601,
  -0.16270463168621063, -0.16792108118534088, -0.1549413651227951,
  -0.15233130753040314, -0.2053627222776413, -0.24543118476867676,
  -0.19505451619625092, -0.13334886729717255, -0.1539776772260666,
  -0.20574375987052917, -0.21924647688865662, -0.21007484197616577,
  -0.20535749197006226, -0.20906761288642883, -0.2113708257675171,
  -0.1924213171005249, -0.1729261428117752, -0.1974346935749054,
  -0.24216455221176147, -0.24789853394031525, -0.21526896953582764,
  -0.18774348497390747, -0.1947111189365387, -0.22724826633930206,
  -0.24980422854423523, -0.2553138732910156, -0.2595294713973999,
  -0.2490595281124115, -0.22457320988178253, -0.21975085139274597,
  -0.2308007776737213, -0.24633769690990448, -0.27734091877937317,
  -0.2777934670448303, -0.2198994904756546, -0.19765232503414154,
  -0.2459878921508789, -0.2653973400592804, -0.23981307446956635,
  -0.236404150724411, -0.24403494596481323, -0.24735024571418762,
  -0.26785993576049805, -0.2727587819099426, -0.25692981481552124,
  -0.2761556804180145, -0.29746589064598083, -0.26593461632728577,
  -0.2451973408460617, -0.2738610804080963, -0.29069653153419495,
  -0.2836436331272125, -0.2862018942832947, -0.28810298442840576,
  -0.28407537937164307, -0.2842208743095398, -0.27394741773605347,
  -0.2576131820678711, -0.25788065791130066, -0.26301348209381104,
  -0.26075977087020874, -0.26634567975997925, -0.2825697660446167,
  -0.2952079176902771, -0.2932834029197693, -0.2774880528450012,
  -0.27016952633857727, -0.2836878299713135, -0.29093071818351746,
  -0.27649006247520447, -0.26634177565574646, -0.2741139531135559,
  -0.2791450321674347, -0.2710394561290741, -0.2723197340965271,
  -0.29559987783432007, -0.30339834094047546, -0.2657879590988159,
  -0.22638842463493347, -0.23067398369312286, -0.25397995114326477,
  -0.26623669266700745, -0.26828598976135254, -0.2513095438480377,
  -0.21762847900390625, -0.19454437494277954, -0.18230964243412018,
  -0.16592557728290558, -0.1545945256948471, -0.15201407670974731,
  -0.15120209753513336, -0.15758810937404633, -0.16119949519634247,
  -0.14098815619945526, -0.11313683539628983, -0.10962022095918655,
  -0.13456284999847412, -0.17524011433124542, -0.21041664481163025,
  -0.2121744006872177, -0.1721990555524826, -0.11124125868082047,
  -0.060703035444021225, -0.03665908798575401, -0.023164743557572365,
  0.0005125095485709608, 0.030826225876808167, 0.0642552599310875,
  0.10174249857664108, 0.11915001273155212, 0.09755939245223999,
  0.060376498848199844, 0.03328592702746391, 0.016653407365083694,
  0.012620368972420692, 0.02517567202448845, 0.04188026115298271,
  0.04961268976330757, 0.053954675793647766, 0.07217630743980408,
  0.1126302108168602, 0.16332316398620605, 0.20355765521526337,
  0.22256165742874146, 0.22504922747612, 0.2248348444700241, 0.2295825481414795,
  0.2366863638162613, 0.24721133708953857, 0.2627105712890625,
  0.2771355211734772, 0.2922114133834839, 0.30773794651031494,
  0.30244743824005127, 0.274751216173172, 0.2605923116207123,
  0.2708539366722107, 0.27984461188316345, 0.27975237369537354,
  0.27668651938438416, 0.26552814245224, 0.25311923027038574,
  0.26080039143562317, 0.29334813356399536, 0.33485230803489685,
  0.3663788139820099, 0.38280636072158813, 0.393852561712265,
  0.40144023299217224, 0.39839038252830505, 0.38858047127723694,
  0.37570568919181824, 0.3562999367713928, 0.34135663509368896,
  0.341609925031662, 0.34264418482780457, 0.33099979162216187,
  0.31245890259742737, 0.2965807020664215, 0.2947041988372803,
  0.314154714345932, 0.342806875705719, 0.36234384775161743, 0.366171658039093,
  0.3577299416065216, 0.3480512201786041, 0.3446367383003235,
  0.34362080693244934, 0.3451346755027771, 0.3502087891101837,
  0.3456772565841675, 0.32658326625823975, 0.3089296221733093,
  0.301934152841568, 0.30193856358528137, 0.30598995089530945,
  0.30858924984931946, 0.3060339391231537, 0.3014262318611145,
  0.29303762316703796, 0.281439870595932, 0.2789961099624634,
  0.28535258769989014, 0.2807634770870209, 0.2599599063396454,
  0.24212513864040375, 0.23939546942710876, 0.23859049379825592,
  0.22701525688171387, 0.21872451901435852, 0.22906461358070374,
  0.2448323667049408, 0.24937015771865845, 0.24388325214385986,
  0.2303970754146576, 0.21309794485569, 0.20335069298744202, 0.1997046321630478,
  0.19459474086761475, 0.19157108664512634, 0.18685278296470642,
  0.1701277792453766, 0.15137289464473724, 0.14802613854408264,
  0.15951965749263763, 0.17162583768367767, 0.1698264628648758,
  0.15403027832508087, 0.13588064908981323, 0.11759643256664276,
  0.10234420001506805, 0.10128362476825714, 0.10087675601243973,
  0.08232344686985016, 0.06781581789255142, 0.07640745490789413,
  0.08908524364233017, 0.09892429411411285, 0.11213432252407074,
  0.11572433263063431, 0.11056329309940338, 0.11032126098871231,
  0.10023348778486252, 0.07280275970697403, 0.05025491863489151,
  0.03435100242495537, 0.015152250416576862, 0.006113860290497541,
  0.008239357732236385, 0.0049847085028886795, 0.0043129161931574345,
  0.01641911081969738, 0.026042986661195755, 0.02847820706665516,
  0.03824854642152786, 0.05267101526260376, 0.0524006150662899,
  0.036210235208272934, 0.026068322360515594, 0.030055904760956764,
  0.028778107836842537, 0.01930832490324974, 0.022926902398467064,
  0.03252703323960304, 0.025499669834971428, 0.016421150416135788,
  0.02196553163230419, 0.025005709379911423, 0.02054578810930252,
  0.02015417441725731, 0.012028258293867111, -0.006278504151850939,
  -0.0023932026233524084, 0.02966426871716976, 0.053177665919065475,
  0.05223142355680466, 0.04279334098100662, 0.03564947098493576,
  0.040029510855674744, 0.066627137362957, 0.09955231845378876,
  0.11009261757135391, 0.09580164402723312, 0.0682390108704567,
  0.03646332770586014, 0.02368404157459736, 0.04582594707608223,
  0.0772562026977539, 0.08818808197975159, 0.07998407632112503,
  0.05938826873898506, 0.035534169524908066, 0.03357500955462456,
  0.060768913477659225, 0.0915759801864624, 0.10351946949958801,
  0.092677041888237, 0.07122813165187836, 0.062365155667066574,
  0.0702538713812828, 0.07677556574344635, 0.07726138085126877,
  0.07893569022417068, 0.07888520509004593, 0.07084023207426071,
  0.05288538336753845, 0.03682313486933708, 0.0517570860683918,
  0.09377163648605347, 0.10129707306623459, 0.04185280576348305,
  -0.02602159045636654, -0.03180106356739998, 0.009322172030806541,
  0.026937762275338173, 0.006086290348321199, -0.0009567275992594659,
  0.01918492279946804, 0.021593637764453888, -0.0031910487450659275,
  -0.022240400314331055, -0.02256527543067932, -0.007418313529342413,
  0.010012435726821423, 0.0029242881573736668, -0.02246251329779625,
  -0.03841360658407211, -0.05558554828166962, -0.08015001565217972,
  -0.0828375369310379, -0.07022333145141602, -0.07530079782009125,
  -0.09211909770965576, -0.10984224826097488, -0.13150601089000702,
  -0.13942554593086243, -0.13020361959934235, -0.12936735153198242,
  -0.1347556710243225, -0.1331280916929245, -0.1383541226387024,
  -0.151847705245018, -0.1605042666196823, -0.17361386120319366,
  -0.19273993372917175, -0.19365327060222626, -0.17262716591358185,
  -0.15879260003566742, -0.173461452126503, -0.20217157900333405,
  -0.2188691943883896, -0.22944781184196472, -0.250006765127182,
  -0.26329898834228516, -0.25299137830734253, -0.23066775500774384,
  -0.2087477743625641, -0.21185363829135895, -0.2618120610713959,
  -0.31196993589401245, -0.30639320611953735, -0.2745114862918854,
  -0.260381281375885, -0.26081568002700806, -0.2772199809551239,
  -0.30300045013427734, -0.3048158586025238, -0.2929806411266327,
  -0.30202892422676086, -0.31136271357536316, -0.2990081310272217,
  -0.29701662063598633, -0.3214426338672638, -0.3402532637119293,
  -0.33619487285614014, -0.3323880434036255, -0.34436309337615967,
  -0.35050836205482483, -0.3394918441772461, -0.3373247981071472,
  -0.34486138820648193, -0.33425018191337585, -0.3257603049278259,
  -0.35117506980895996, -0.3785245418548584, -0.37529224157333374,
  -0.35917699337005615, -0.34733131527900696, -0.3452627956867218,
  -0.35714009404182434, -0.3680947721004486, -0.3678733706474304,
  -0.36148691177368164, -0.3454457223415375, -0.3350313901901245,
  -0.35436901450157166, -0.3771734833717346, -0.3788403570652008,
  -0.3845670521259308, -0.3933921754360199, -0.3821650445461273,
  -0.3743131160736084, -0.383949875831604, -0.38348087668418884,
  -0.37146881222724915, -0.3631362020969391, -0.35925987362861633,
  -0.37003594636917114, -0.3845098614692688, -0.3720754384994507,
  -0.3581595718860626, -0.3749662935733795, -0.38689789175987244,
  -0.3790620267391205, -0.3871450126171112, -0.39844197034835815,
  -0.37634003162384033, -0.34431833028793335, -0.33906856179237366,
  -0.3532673418521881, -0.36047640442848206, -0.3462384045124054,
  -0.32140061259269714, -0.30749040842056274, -0.3054424226284027,
  -0.30064648389816284, -0.27614864706993103, -0.22295153141021729,
  -0.1735340654850006, -0.17224830389022827, -0.19794785976409912,
  -0.2011885941028595, -0.18424805998802185, -0.16913117468357086,
  -0.16100995242595673, -0.17787322402000427, -0.2344910055398941,
  -0.29762160778045654, -0.30893072485923767, -0.2462548315525055,
  -0.14953508973121643, -0.07788081467151642, -0.04614481329917908,
  -0.031026046723127365, -0.010741095058619976, 0.03145582601428032,
  0.09491074085235596, 0.1474047303199768, 0.1618492156267166,
  0.13515324890613556, 0.07201467454433441, 0.0011048595188185573,
  -0.03137930855154991, -0.01631256937980652, 0.01957789808511734,
  0.05312275141477585, 0.06909187883138657, 0.07158327102661133,
  0.08899383246898651, 0.13174957036972046, 0.1836378574371338,
  0.2367294728755951, 0.28192389011383057, 0.29700011014938354,
  0.28544554114341736, 0.2824528217315674, 0.30310678482055664,
  0.32713285088539124, 0.34141889214515686, 0.35630959272384644,
  0.3715936243534088, 0.3712327182292938, 0.3570011556148529,
  0.33989283442497253, 0.31690266728401184, 0.299312561750412,
  0.3101694583892822, 0.3339307904243469, 0.338411420583725,
  0.32704484462738037, 0.3186827600002289, 0.32808351516723633,
  0.3702085614204407, 0.43026214838027954, 0.4713480472564697,
  0.48948073387145996, 0.5010619163513184, 0.5044359564781189,
  0.49551132321357727, 0.47717660665512085, 0.4528616666793823,
  0.43386828899383545, 0.42426595091819763, 0.4131605625152588,
  0.3999527096748352, 0.38655388355255127, 0.3658738136291504,
  0.35016801953315735, 0.35590770840644836, 0.3706159293651581,
  0.38925865292549133, 0.4238586723804474, 0.452907532453537,
  0.4430316686630249, 0.4082243740558624, 0.38654854893684387,
  0.39131560921669006, 0.40754884481430054, 0.41072219610214233,
  0.39058956503868103, 0.3577629029750824, 0.32653552293777466,
  0.30727145075798035, 0.3038986325263977, 0.311078280210495,
  0.32536637783050537, 0.339904248714447, 0.33632558584213257,
  0.31623879075050354, 0.30438241362571716, 0.3037315607070923,
  0.29971352219581604, 0.28881680965423584, 0.26822665333747864,
  0.24164092540740967, 0.22470207512378693, 0.2119295299053192,
  0.19173799455165863, 0.1833418905735016, 0.19723914563655853,
  0.21373483538627625, 0.22419863939285278, 0.2246057391166687,
  0.20252424478530884, 0.17349743843078613, 0.16363748908042908,
  0.16532859206199646, 0.16476179659366608, 0.16156569123268127,
  0.1489352583885193, 0.12808555364608765, 0.11351870745420456,
  0.10713383555412292, 0.10720809549093246, 0.11445573717355728,
  0.11235874891281128, 0.08813448250293732, 0.05463807284832001,
  0.02665630169212818, 0.012323773466050625, 0.012972310185432434,
  0.010754605755209923, -0.002507955301553011, -0.003571429755538702,
  0.01819191686809063, 0.044597215950489044, 0.0637330636382103,
  0.07241353392601013, 0.0673111230134964, 0.05555804446339607,
  0.043978478759527206, 0.030209099873900414, 0.015362303704023361,
  -0.003965279553085566, -0.03701314330101013, -0.07241155952215195,
  -0.08778510987758636, -0.08202727884054184, -0.06673283129930496,
  -0.049831077456474304, -0.03908992558717728, -0.03600813448429108,
  -0.0332053042948246, -0.026867179200053215, -0.016136109828948975,
  -0.0004742806777358055, 0.010751915164291859, 0.006805488374084234,
  -0.005468141753226519, -0.0119193559512496, -0.010759574361145496,
  -0.0058584497310221195, 0.003176921745762229, 0.010925179347395897,
  0.005740299355238676, -0.011288260109722614, -0.027498681098222733,
  -0.03727385029196739, -0.04021155834197998, -0.03542656823992729,
  -0.02655419521033764, -0.012753219343721867, 0.012095045298337936,
  0.0388377346098423, 0.05533888563513756, 0.07194069772958755,
  0.0973265990614891, 0.12035961449146271, 0.13607412576675415,
  0.1442098170518875, 0.1337382048368454, 0.10925864428281784,
  0.09357865154743195, 0.0879305899143219, 0.07763677090406418,
  0.06593000143766403, 0.0658455416560173, 0.07911872863769531,
  0.09779559075832367, 0.1143624559044838, 0.1272537112236023,
  0.13430501520633698, 0.13293588161468506, 0.13539166748523712,
  0.1537581980228424, 0.17277435958385468, 0.17654938995838165,
  0.17296718060970306, 0.1644318550825119, 0.14855049550533295,
  0.1445615291595459, 0.1594700664281845, 0.1648223102092743,
  0.1465248316526413, 0.12208004295825958, 0.10286259651184082,
  0.08952698856592178, 0.08471120148897171, 0.08458112180233002,
  0.08451547473669052, 0.08791614323854446, 0.09136395901441574,
  0.08782447129487991, 0.08473194390535355, 0.09002228081226349,
  0.09698925167322159, 0.09848497062921524, 0.09081868082284927,
  0.06813868135213852, 0.03659607842564583, 0.011331724934279919,
  -0.008734461851418018, -0.02671930007636547, -0.027067800983786583,
  -0.011501067318022251, -0.011847891844809055, -0.03485759347677231,
  -0.05527786538004875, -0.06735603511333466, -0.07792183756828308,
  -0.0772259458899498, -0.06838946789503098, -0.07716285437345505,
  -0.1096576601266861, -0.14683212339878082, -0.17180925607681274,
  -0.1756560057401657, -0.16147196292877197, -0.14704276621341705,
  -0.14817194640636444, -0.1662033051252365, -0.1880139708518982,
  -0.19864632189273834, -0.20404216647148132, -0.21721187233924866,
  -0.22854512929916382, -0.23215393722057343, -0.24346092343330383,
  -0.2571115791797638, -0.25414109230041504, -0.24948501586914062,
  -0.26202914118766785, -0.27973073720932007, -0.292423814535141,
  -0.2981613874435425, -0.29224181175231934, -0.29420003294944763,
  -0.32539886236190796, -0.3599912226200104, -0.3692544102668762,
  -0.365317165851593, -0.35545045137405396, -0.3314085602760315,
  -0.31021982431411743, -0.3141660690307617, -0.3330760598182678,
  -0.34796810150146484, -0.3578360080718994, -0.36598706245422363,
  -0.3674637973308563, -0.36377766728401184, -0.3750213086605072,
  -0.4116528034210205, -0.44595205783843994, -0.4469090700149536,
  -0.422380656003952, -0.39837291836738586, -0.39091214537620544,
  -0.4065455496311188, -0.4334182143211365, -0.4440281391143799,
  -0.4293420910835266, -0.40993061661720276, -0.40990859270095825,
  -0.43018287420272827, -0.4443473517894745, -0.43332886695861816,
  -0.4183312654495239, -0.4268854856491089, -0.447388619184494,
  -0.4537787139415741, -0.44624748826026917, -0.4416230320930481,
  -0.4482171833515167, -0.46397271752357483, -0.4843709468841553,
  -0.49822843074798584, -0.48618000745773315, -0.45111775398254395,
  -0.43163707852363586, -0.4435523748397827, -0.4513113796710968,
  -0.4390851855278015, -0.4363669455051422, -0.44684475660324097,
  -0.44545960426330566, -0.44356581568717957, -0.46144503355026245,
  -0.47029247879981995, -0.4457148313522339, -0.4232926070690155,
  -0.43383121490478516, -0.44812753796577454, -0.43543657660484314,
  -0.41017383337020874, -0.3892078697681427, -0.3672483563423157,
  -0.3432813882827759, -0.3147298991680145, -0.26744556427001953,
  -0.21701163053512573, -0.20684482157230377, -0.23256734013557434,
  -0.23860156536102295, -0.20477008819580078, -0.16797074675559998,
  -0.16089990735054016, -0.18926239013671875, -0.25204822421073914,
  -0.32629913091659546, -0.3539985716342926, -0.3030571937561035,
  -0.2156735062599182, -0.14446213841438293, -0.0900777205824852,
  -0.04166562110185623, -0.001418374595232308, 0.04824863374233246,
  0.11848288029432297, 0.17676758766174316, 0.19706758856773376,
  0.18415333330631256, 0.13531848788261414, 0.06274814158678055,
  0.01576486974954605, 0.013356332667171955, 0.025883903726935387,
  0.03996270149946213, 0.06209871917963028, 0.08581887185573578,
  0.11201362311840057, 0.15519970655441284, 0.2153167873620987,
  0.27781492471694946, 0.32451683282852173, 0.3414398431777954,
  0.33961552381515503, 0.3456125855445862, 0.3663330376148224,
  0.39343175292015076, 0.42165014147758484, 0.44263139367103577,
  0.45055726170539856, 0.4512495994567871, 0.4446791112422943,
  0.4246828556060791, 0.40078824758529663, 0.38744211196899414,
  0.3813127279281616, 0.3739122152328491, 0.36902549862861633,
  0.3725660443305969, 0.38340458273887634, 0.40447455644607544,
  0.44417905807495117, 0.49762386083602905, 0.5458438992500305,
  0.580678403377533, 0.6070277690887451, 0.618816077709198, 0.6014952063560486,
  0.5589264631271362, 0.5143881440162659, 0.4865557849407196,
  0.4767805337905884, 0.4740098714828491, 0.4642522633075714,
  0.44039446115493774, 0.41079410910606384, 0.39603692293167114,
  0.4092094600200653, 0.4424179196357727, 0.476317822933197,
  0.49487221240997314, 0.49277612566947937, 0.4777023494243622,
  0.4604649245738983, 0.4461233615875244, 0.4405301511287689,
  0.44401538372039795, 0.4377531409263611, 0.40856871008872986,
  0.37420758605003357, 0.3520011305809021, 0.3340179920196533,
  0.317380428314209, 0.31613609194755554, 0.32950371503829956,
  0.33796778321266174, 0.33239296078681946, 0.31861239671707153,
  0.3020585775375366, 0.28760826587677, 0.2802911102771759, 0.2729456126689911,
  0.25197842717170715, 0.22080224752426147, 0.1932099461555481,
  0.17049942910671234, 0.15268857777118683, 0.15045921504497528,
  0.1633232831954956, 0.17437167465686798, 0.17266806960105896,
  0.15629030764102936, 0.12899258732795715, 0.10613548010587692,
  0.10053832828998566, 0.10703220218420029, 0.11243076622486115,
  0.10366231203079224, 0.07627476006746292, 0.04921114444732666,
  0.045092083513736725, 0.05960754677653313, 0.0741315558552742,
  0.07426836341619492, 0.05094687268137932, 0.011748473159968853,
  -0.023352496325969696, -0.047268714755773544, -0.06413953006267548,
  -0.07846718281507492, -0.0951339602470398, -0.10369215160608292,
  -0.08489066362380981, -0.048219818621873856, -0.017320184037089348,
  0.005635417066514492, 0.018831545487046242, 0.015590525232255459,
  0.01136067695915699, 0.014392029494047165, 0.001623123767785728,
  -0.034763872623443604, -0.07670533657073975, -0.11710888892412186,
  -0.15065106749534607, -0.16274067759513855, -0.1538078337907791,
  -0.13375669717788696, -0.10946277529001236, -0.09655042737722397,
  -0.10012185573577881, -0.09889426082372665, -0.08400024473667145,
  -0.06979324668645859, -0.0587877482175827, -0.04512898623943329,
  -0.03338966891169548, -0.02527523785829544, -0.01846989430487156,
  -0.01618357002735138, -0.014284081757068634, -0.002411162480711937,
  0.014599751681089401, 0.020639585331082344, 0.009344554506242275,
  -0.011587301269173622, -0.03215867653489113, -0.050067998468875885,
  -0.061573173850774765, -0.05356951430439949, -0.025420501828193665,
  0.004243931267410517, 0.029125532135367393, 0.05970540642738342,
  0.09872955083847046, 0.1434229463338852, 0.18574273586273193,
  0.20292839407920837, 0.1907735913991928, 0.1818762719631195,
  0.19141100347042084, 0.19637414813041687, 0.18498608469963074,
  0.16634494066238403, 0.14354528486728668, 0.12403249740600586,
  0.12514108419418335, 0.1508527398109436, 0.1899644434452057,
  0.2239362895488739, 0.23021431267261505, 0.2104141265153885,
  0.19907493889331818, 0.21753725409507751, 0.25146248936653137,
  0.2800155282020569, 0.2920016944408417, 0.28513067960739136,
  0.27110013365745544, 0.2653404474258423, 0.27036845684051514,
  0.27639660239219666, 0.26792314648628235, 0.23903945088386536,
  0.20660169422626495, 0.18605129420757294, 0.1709607094526291,
  0.15875136852264404, 0.15869998931884766, 0.16558554768562317,
  0.1684092879295349, 0.1716930866241455, 0.17606133222579956,
  0.17136862874031067, 0.16170558333396912, 0.15736503899097443,
  0.15024812519550323, 0.12864689528942108, 0.09619347006082535,
  0.06165266036987305, 0.03139611333608627, 0.013629894703626633,
  0.012852865271270275, 0.021935511380434036, 0.026498543098568916,
  0.01606886088848114, -0.007528793066740036, -0.03149140253663063,
  -0.04586734622716904, -0.05525800585746765, -0.07058553397655487,
  -0.09436212480068207, -0.12322652339935303, -0.15335234999656677,
  -0.17563168704509735, -0.1833004206418991, -0.18644732236862183,
  -0.19788900017738342, -0.21343128383159637, -0.22309333086013794,
  -0.22549498081207275, -0.22541525959968567, -0.2297438085079193,
  -0.2400701642036438, -0.2525046765804291, -0.2696904242038727,
  -0.28957390785217285, -0.29338353872299194, -0.28228560090065,
  -0.2872454822063446, -0.3122008144855499, -0.3342233896255493,
  -0.36116304993629456, -0.4062036871910095, -0.4377026855945587,
  -0.4309988021850586, -0.4159863591194153, -0.4230370819568634,
  -0.4394803047180176, -0.44200626015663147, -0.4269464910030365,
  -0.40394043922424316, -0.38517558574676514, -0.38043013215065,
  -0.3902164697647095, -0.4054976999759674, -0.42359766364097595,
  -0.4522898495197296, -0.4817315936088562, -0.4868259131908417,
  -0.4805642366409302, -0.5031739473342896, -0.5387904644012451,
  -0.5324733853340149, -0.495134174823761, -0.4850770831108093,
  -0.5006645917892456, -0.501356303691864, -0.4983516037464142,
  -0.5183382034301758, -0.5326722264289856, -0.5163306593894958,
  -0.5036278367042542, -0.5142551064491272, -0.5168237686157227,
  -0.5077810287475586, -0.5193416476249695, -0.5416338443756104,
  -0.5408831238746643, -0.5275986790657043, -0.5263032913208008,
  -0.5299015045166016, -0.5298453569412231, -0.5349517464637756,
  -0.5461806058883667, -0.5505897402763367, -0.5372143983840942,
  -0.5123962759971619, -0.5004286170005798, -0.5085402727127075,
  -0.5074132084846497, -0.48036107420921326, -0.45732423663139343,
  -0.4659298062324524, -0.48981112241744995, -0.4998262822628021,
  -0.489515095949173, -0.4662184417247772, -0.4369122385978699,
  -0.4097807705402374, -0.38174375891685486, -0.33125975728034973,
  -0.2636966109275818, -0.2311040461063385, -0.24673663079738617,
  -0.24754005670547485, -0.20897477865219116, -0.18848326802253723,
  -0.2033466100692749, -0.2143435925245285, -0.2410493642091751,
  -0.3192969560623169, -0.3807962238788605, -0.3461107313632965,
  -0.2592734694480896, -0.1939937323331833, -0.14039866626262665,
  -0.07579787075519562, -0.020961707457900047, 0.0223074983805418,
  0.08091170340776443, 0.1516747623682022, 0.20613904297351837,
  0.22906580567359924, 0.21492622792720795, 0.16788387298583984,
  0.11450803279876709, 0.08087997138500214, 0.06911934167146683,
  0.07287898659706116, 0.09005682170391083, 0.115375816822052,
  0.14123639464378357, 0.16682030260562897, 0.20201797783374786,
  0.25900596380233765, 0.3264564871788025, 0.37029802799224854,
  0.38448724150657654, 0.4054339826107025, 0.4497024714946747,
  0.4884488582611084, 0.5043445825576782, 0.5164691805839539,
  0.5351295471191406, 0.544328510761261, 0.5350480079650879, 0.5187562704086304,
  0.5072674751281738, 0.4985499382019043, 0.4810235798358917,
  0.4527851641178131, 0.4345703721046448, 0.4422808885574341,
  0.4577144682407379, 0.4623112976551056, 0.4746204912662506,
  0.5140408873558044, 0.5653544068336487, 0.609986424446106, 0.646892249584198,
  0.6703956723213196, 0.6715905070304871, 0.6557943820953369,
  0.6301470398902893, 0.5967069864273071, 0.5689177513122559, 0.559101939201355,
  0.5490922927856445, 0.5129144787788391, 0.4628680646419525,
  0.43276605010032654, 0.42820701003074646, 0.43695512413978577,
  0.4623170793056488, 0.49770793318748474, 0.5100803375244141,
  0.4907993972301483, 0.4673014283180237, 0.4505055546760559,
  0.43549367785453796, 0.4299331605434418, 0.4265846908092499,
  0.40427106618881226, 0.37404903769493103, 0.35465022921562195,
  0.3337830603122711, 0.3107289969921112, 0.3060784935951233,
  0.3101331889629364, 0.3033692538738251, 0.296933114528656,
  0.29136791825294495, 0.2663564383983612, 0.2323557734489441,
  0.20972447097301483, 0.18385225534439087, 0.1483316272497177,
  0.12704838812351227, 0.11829791963100433, 0.09719755500555038,
  0.07262257486581802, 0.06792142242193222, 0.07306455820798874,
  0.0699201375246048, 0.06428905576467514, 0.06176573038101196,
  0.05177874490618706, 0.03030550479888916, 0.00889969989657402,
  -0.0025632039178162813, -0.00693648774176836, -0.015190678648650646,
  -0.034308429807424545, -0.05637671798467636, -0.06456637382507324,
  -0.056038420647382736, -0.04916958510875702, -0.05894599109888077,
  -0.08001523464918137, -0.104197196662426, -0.13275755941867828,
  -0.16166934370994568, -0.18175823986530304, -0.19535990059375763,
  -0.20617198944091797, -0.20660075545310974, -0.1954093724489212,
  -0.17983925342559814, -0.15754954516887665, -0.1293533444404602,
  -0.1099017858505249, -0.10156294703483582, -0.0931776612997055,
  -0.08767501264810562, -0.0956513062119484, -0.1174236312508583,
  -0.14867110550403595, -0.18117624521255493, -0.20418629050254822,
  -0.2156946361064911, -0.21469581127166748, -0.20024201273918152,
  -0.19004423916339874, -0.19787289202213287, -0.20393219590187073,
  -0.19068683683872223, -0.16831359267234802, -0.14384828507900238,
  -0.11886660754680634, -0.10563740879297256, -0.10284549742937088,
  -0.09133244305849075, -0.06545877456665039, -0.030852172523736954,
  0.006168897729367018, 0.02756018564105034, 0.021415140479803085,
  0.005912880413234234, 0.00481035653501749, 0.017461314797401428,
  0.022348174825310707, 0.0016860051546245813, -0.031328245997428894,
  -0.04576636105775833, -0.03440232574939728, -0.004510709084570408,
  0.04656153544783592, 0.10268519073724747, 0.12910103797912598,
  0.13092613220214844, 0.14743149280548096, 0.1906379908323288,
  0.24278093874454498, 0.28477251529693604, 0.30073949694633484,
  0.290295273065567, 0.27366742491722107, 0.2659389078617096,
  0.2693575918674469, 0.2851671874523163, 0.3050130307674408,
  0.3102578818798065, 0.29587236046791077, 0.274026483297348,
  0.2568485736846924, 0.25060227513313293, 0.2565666437149048,
  0.27304908633232117, 0.29960817098617554, 0.3299117386341095,
  0.3479657471179962, 0.34929952025413513, 0.3507291376590729,
  0.36548393964767456, 0.3870280086994171, 0.4007895290851593,
  0.3960151672363281, 0.3713930547237396, 0.3368629217147827,
  0.3018401563167572, 0.26982277631759644, 0.24954846501350403,
  0.24617138504981995, 0.24237993359565735, 0.22023271024227142,
  0.1903928816318512, 0.1722131222486496, 0.1677626222372055,
  0.1698206216096878, 0.1681550294160843, 0.15126127004623413,
  0.12355213612318039, 0.10280992090702057, 0.09513448923826218,
  0.09573003649711609, 0.10086362808942795, 0.10119734704494476,
  0.08643005788326263, 0.06117526814341545, 0.03423402085900307,
  0.005205829627811909, -0.026050716638565063, -0.058211226016283035,
  -0.0941765084862709, -0.13045381009578705, -0.1577162742614746,
  -0.17794877290725708, -0.19807840883731842, -0.21432511508464813,
  -0.22424818575382233, -0.2334095537662506, -0.2379922717809677,
  -0.23042595386505127, -0.22436513006687164, -0.2398831993341446,
  -0.27001509070396423, -0.2934452295303345, -0.3059922456741333,
  -0.3143267035484314, -0.31856340169906616, -0.31916117668151855,
  -0.32427528500556946, -0.3410738408565521, -0.3692867159843445,
  -0.40223217010498047, -0.4306260347366333, -0.4487144649028778,
  -0.4588790237903595, -0.47116604447364807, -0.49259892106056213,
  -0.5135021805763245, -0.5194445252418518, -0.5153378248214722,
  -0.5118733048439026, -0.50115567445755, -0.478762686252594,
  -0.46329399943351746, -0.4673336148262024, -0.48168694972991943,
  -0.49741843342781067, -0.513295590877533, -0.5290669202804565,
  -0.5447067022323608, -0.5540849566459656, -0.5541468262672424,
  -0.563755214214325, -0.5935105085372925, -0.6122158169746399,
  -0.5965180397033691, -0.5767026543617249, -0.5832750797271729,
  -0.6007637977600098, -0.6082867980003357, -0.6081883311271667,
  -0.6019912958145142, -0.5899487137794495, -0.5853794813156128,
  -0.589309811592102, -0.580188512802124, -0.5564935207366943,
  -0.545036256313324, -0.5513129234313965, -0.5550340414047241,
  -0.553739070892334, -0.5549196004867554, -0.5478687286376953,
  -0.5356156229972839, -0.54804527759552, -0.5807086229324341,
  -0.5860846638679504, -0.5527488589286804, -0.5219184160232544,
  -0.5164827704429626, -0.5203173756599426, -0.5199426412582397,
  -0.5129020810127258, -0.49340805411338806, -0.46551254391670227,
  -0.44530120491981506, -0.4267444610595703, -0.37890374660491943,
  -0.30226433277130127, -0.24725507199764252, -0.23966988921165466,
  -0.23521623015403748, -0.1967906355857849, -0.15884317457675934,
  -0.15763425827026367, -0.17211298644542694, -0.1908908486366272,
  -0.2420402318239212, -0.3064331114292145, -0.3143528997898102,
  -0.26355308294296265, -0.21859851479530334, -0.18411435186862946,
  -0.11099873483181, -0.011700637638568878, 0.06174864247441292,
  0.11587051302194595, 0.17961232364177704, 0.23574697971343994,
  0.2694118320941925, 0.2949911653995514, 0.2962781488895416,
  0.24869191646575928, 0.19377999007701874, 0.18232226371765137,
  0.1912519782781601, 0.1882173717021942, 0.1957937479019165,
  0.23066681623458862, 0.27005860209465027, 0.30267322063446045,
  0.33875572681427, 0.3786754012107849, 0.4149256646633148, 0.44573062658309937,
  0.4784995913505554, 0.5241513252258301, 0.572100818157196, 0.5956771373748779,
  0.6026632189750671, 0.6254265904426575, 0.6570383906364441,
  0.6667094230651855, 0.6551708579063416, 0.6356518864631653,
  0.6088730096817017, 0.585361659526825, 0.5745079517364502, 0.5603090524673462,
  0.5368374586105347, 0.5291569828987122, 0.5501852631568909,
  0.5826692581176758, 0.6110439896583557, 0.6350535750389099,
  0.6588010787963867, 0.6837636232376099, 0.7041456699371338,
  0.7092728614807129, 0.6943559646606445, 0.6642663478851318,
  0.6302540898323059, 0.6034085750579834, 0.5845155715942383,
  0.5648581385612488, 0.5400240421295166, 0.5154737830162048,
  0.49854299426078796, 0.4888595640659332, 0.48014140129089355,
  0.4758833646774292, 0.48701778054237366, 0.503113329410553,
  0.49818041920661926, 0.4721377193927765, 0.4446503818035126,
  0.4177619218826294, 0.38670721650123596, 0.35867854952812195,
  0.3349228799343109, 0.3105417490005493, 0.2877187430858612,
  0.2612971365451813, 0.22583307325839996, 0.20150737464427948,
  0.2050098329782486, 0.218545600771904, 0.22300425171852112,
  0.21650579571723938, 0.19748884439468384, 0.17357929050922394,
  0.15705132484436035, 0.1347416490316391, 0.09452182054519653,
  0.05951163172721863, 0.04208800941705704, 0.01732332445681095,
  -0.023144520819187164, -0.05564269796013832, -0.06915181130170822,
  -0.07403507828712463, -0.08054963499307632, -0.09459362179040909,
  -0.11459535360336304, -0.13043370842933655, -0.13614021241664886,
  -0.135679692029953, -0.13635574281215668, -0.13952520489692688,
  -0.1377929002046585, -0.12973818182945251, -0.12536530196666718,
  -0.12571898102760315, -0.1253073811531067, -0.13575832545757294,
  -0.16593189537525177, -0.19817014038562775, -0.22214271128177643,
  -0.25316673517227173, -0.2953086793422699, -0.3299359977245331,
  -0.34215331077575684, -0.3304961025714874, -0.30613118410110474,
  -0.2858833074569702, -0.26967400312423706, -0.24386410415172577,
  -0.21072913706302643, -0.18424931168556213, -0.16819605231285095,
  -0.16207288205623627, -0.16625119745731354, -0.17535631358623505,
  -0.18819302320480347, -0.2098844200372696, -0.23213830590248108,
  -0.2385568618774414, -0.23139744997024536, -0.22685368359088898,
  -0.22925543785095215, -0.22806669771671295, -0.2157542109489441,
  -0.19824989140033722, -0.18457652628421783, -0.1733378916978836,
  -0.16008377075195312, -0.14671000838279724, -0.12934701144695282,
  -0.09646493941545486, -0.050198718905448914, -0.007499757222831249,
  0.020601466298103333, 0.03263068571686745, 0.033591095358133316,
  0.03828532621264458, 0.05561569333076477, 0.0725373700261116,
  0.0736393854022026, 0.06074028089642525, 0.04209233447909355,
  0.025976398959755898, 0.0274360328912735, 0.0607130266726017,
  0.12188302725553513, 0.18287305533885956, 0.2098337858915329,
  0.20363342761993408, 0.20679792761802673, 0.2465500682592392,
  0.3000181019306183, 0.3338553011417389, 0.3370060920715332,
  0.3120322823524475, 0.281314879655838, 0.2867755889892578,
  0.33797913789749146, 0.38719552755355835, 0.38836535811424255,
  0.35273611545562744, 0.3284941017627716, 0.345657616853714,
  0.3883177936077118, 0.415010929107666, 0.4053143858909607, 0.3784908950328827,
  0.36445096135139465, 0.37318360805511475, 0.3946388363838196,
  0.4129456579685211, 0.4188595712184906, 0.4140733778476715,
  0.40389204025268555, 0.39208704233169556, 0.383487343788147,
  0.3767676055431366, 0.3590632677078247, 0.32831859588623047,
  0.30699169635772705, 0.3091352880001068, 0.31446823477745056,
  0.3002207279205322, 0.27419883012771606, 0.2544390559196472,
  0.2409438043832779, 0.22407874464988708, 0.1997545212507248,
  0.16735558211803436, 0.1289883702993393, 0.09296300262212753,
  0.06708670407533646, 0.05140915885567665, 0.04096851870417595,
  0.030440455302596092, 0.016676677390933037, 0.001966694602742791,
  -0.007978033274412155, -0.012498175725340843, -0.020282793790102005,
  -0.04382307827472687, -0.08598428964614868, -0.1322794407606125,
  -0.16628284752368927, -0.185760498046875, -0.19541272521018982,
  -0.20407690107822418, -0.23090948164463043, -0.27835381031036377,
  -0.31230220198631287, -0.31274303793907166, -0.3093342185020447,
  -0.3284609615802765, -0.3579838275909424, -0.3840295672416687,
  -0.4043910801410675, -0.4102906286716461, -0.4012412130832672,
  -0.39552876353263855, -0.40177229046821594, -0.4111228585243225,
  -0.4184596836566925, -0.424586683511734, -0.4318024814128876,
  -0.45137855410575867, -0.4929961562156677, -0.5412291288375854,
  -0.5679113268852234, -0.5713552236557007, -0.5740944147109985,
  -0.5833419561386108, -0.5891903042793274, -0.5908198356628418,
  -0.5868834853172302, -0.5694183111190796, -0.5530862212181091,
  -0.5596404075622559, -0.5728861093521118, -0.571015477180481,
  -0.5720195174217224, -0.592156171798706, -0.6118144989013672,
  -0.6145601868629456, -0.6100331544876099, -0.6145428419113159,
  -0.6325904130935669, -0.6462937593460083, -0.6359351873397827,
  -0.6161532998085022, -0.6123864650726318, -0.6204637289047241,
  -0.6319745182991028, -0.6515389084815979, -0.6628506779670715,
  -0.6406715512275696, -0.5998545289039612, -0.5782483220100403,
  -0.5857800841331482, -0.6015727519989014, -0.6015219688415527,
  -0.5817184448242188, -0.56460040807724, -0.569455087184906,
  -0.5799780488014221, -0.5688780546188354, -0.5455965399742126,
  -0.5420835614204407, -0.5593602657318115, -0.5675864219665527,
  -0.5497756004333496, -0.5141484141349792, -0.4789053797721863,
  -0.4607846140861511, -0.4590972661972046, -0.45514434576034546,
  -0.43304672837257385, -0.3773217499256134, -0.28351500630378723,
  -0.20669980347156525, -0.21095357835292816, -0.24360300600528717,
  -0.19804421067237854, -0.09940994530916214, -0.06207140162587166,
  -0.10434717684984207, -0.1627764254808426, -0.21086934208869934,
  -0.24128609895706177, -0.23036207258701324, -0.19171440601348877,
  -0.1670982539653778, -0.1463633030653, -0.08750078827142715,
  0.006217991467565298, 0.1039828360080719, 0.19707398116588593,
  0.2788744568824768, 0.33116865158081055, 0.3568049669265747,
  0.371747761964798, 0.3685814142227173, 0.33709079027175903,
  0.2975909113883972, 0.27490633726119995, 0.26572078466415405,
  0.2549608647823334, 0.25136274099349976, 0.2846516966819763,
  0.35379913449287415, 0.40953007340431213, 0.4258679449558258,
  0.4415193796157837, 0.48847419023513794, 0.5443385243415833,
  0.5853880047798157, 0.6164734363555908, 0.6408225297927856,
  0.6564769148826599, 0.6734727621078491, 0.7017043232917786,
  0.7329948544502258, 0.747227132320404, 0.7333125472068787, 0.7008723020553589,
  0.6677820086479187, 0.6428364515304565, 0.6233679056167603,
  0.5992134809494019, 0.5683650970458984, 0.5514487028121948, 0.568004310131073,
  0.607714056968689, 0.6478606462478638, 0.6783868074417114, 0.6998761296272278,
  0.7178633213043213, 0.7328241467475891, 0.733769953250885, 0.7169690132141113,
  0.68878173828125, 0.6465752124786377, 0.5943410396575928, 0.5541026592254639,
  0.5291385054588318, 0.5027380585670471, 0.47886744141578674,
  0.4676273465156555, 0.4567583501338959, 0.44145190715789795,
  0.43660929799079895, 0.4436126947402954, 0.44893983006477356,
  0.44461339712142944, 0.42506542801856995, 0.3914463222026825,
  0.35990971326828003, 0.33898255228996277, 0.31572744250297546,
  0.2796415388584137, 0.23649778962135315, 0.19548223912715912,
  0.1632121354341507, 0.14460641145706177, 0.13482250273227692,
  0.12298461049795151, 0.11151056736707687, 0.11139801144599915,
  0.11542781442403793, 0.10678019374608994, 0.09009615331888199,
  0.07684757560491562, 0.056353118270635605, 0.019613806158304214,
  -0.016866689547896385, -0.04187716543674469, -0.06740643829107285,
  -0.09911194443702698, -0.1262296587228775, -0.14481915533542633,
  -0.16032974421977997, -0.17342828214168549, -0.18305720388889313,
  -0.19301271438598633, -0.2051481157541275, -0.21630962193012238,
  -0.22496183216571808, -0.23123446106910706, -0.2308446764945984,
  -0.2188423126935959, -0.19926679134368896, -0.1832086145877838,
  -0.17593985795974731, -0.17428694665431976, -0.17680421471595764,
  -0.18558377027511597, -0.20103894174098969, -0.22668102383613586,
  -0.26798921823501587, -0.31594642996788025, -0.35077744722366333,
  -0.36674824357032776, -0.37061241269111633, -0.36288318037986755,
  -0.34142637252807617, -0.3081762194633484, -0.26481959223747253,
  -0.2192290872335434, -0.18442276120185852, -0.16006498038768768,
  -0.1382402777671814, -0.12340909242630005, -0.12379438430070877,
  -0.13909055292606354, -0.1638006567955017, -0.18368396162986755,
  -0.1854461282491684, -0.1807618886232376, -0.1879068911075592,
  -0.19537705183029175, -0.18131248652935028, -0.1506292223930359,
  -0.12458460032939911, -0.11325971782207489, -0.10967282205820084,
  -0.0997920110821724, -0.07692387700080872, -0.04511310160160065,
  -0.011059176176786423, 0.017352961003780365, 0.036135170608758926,
  0.056762538850307465, 0.09350224584341049, 0.13868296146392822,
  0.167755126953125, 0.16434894502162933, 0.13839039206504822,
  0.12437603622674942, 0.1453869491815567, 0.1783963292837143,
  0.18502821028232574, 0.16510075330734253, 0.1469280868768692,
  0.15015923976898193, 0.186539426445961, 0.25694403052330017,
  0.3232348561286926, 0.33378177881240845, 0.298928827047348,
  0.2860267162322998, 0.3261960446834564, 0.3817746341228485,
  0.40891242027282715, 0.4004157483577728, 0.37609630823135376,
  0.3676880896091461, 0.3940199017524719, 0.4364491105079651,
  0.46233975887298584, 0.46312975883483887, 0.446089506149292,
  0.41970500349998474, 0.40555354952812195, 0.4207649528980255,
  0.43992504477500916, 0.42443129420280457, 0.38606324791908264,
  0.36793920397758484, 0.3776065707206726, 0.39102044701576233,
  0.3957478106021881, 0.3897072672843933, 0.3724607229232788,
  0.35638123750686646, 0.352247953414917, 0.3503674566745758,
  0.33846840262413025, 0.31532198190689087, 0.2855968177318573,
  0.26410096883773804, 0.2645643353462219, 0.27301540970802307,
  0.2602373957633972, 0.21733690798282623, 0.16258113086223602,
  0.12259890884160995, 0.10381285846233368, 0.08239720016717911,
  0.040536992251873016, -0.008058716543018818, -0.04485625401139259,
  -0.06426351517438889, -0.06533607095479965, -0.057195041328668594,
  -0.05584721267223358, -0.06640931218862534, -0.08721034228801727,
  -0.11260747909545898, -0.12678804993629456, -0.1277334839105606,
  -0.14170591533184052, -0.1863539069890976, -0.24573703110218048,
  -0.2893591821193695, -0.3016193211078644, -0.3009624779224396,
  -0.318945974111557, -0.3540995419025421, -0.3819675147533417,
  -0.3992050886154175, -0.41473907232284546, -0.4254932105541229,
  -0.4302293360233307, -0.43694832921028137, -0.4499971866607666,
  -0.46324846148490906, -0.46353209018707275, -0.4502057135105133,
  -0.4432237446308136, -0.4521508812904358, -0.4673057496547699,
  -0.48687052726745605, -0.5081696510314941, -0.5182892084121704,
  -0.5294275283813477, -0.565903902053833, -0.609100878238678,
  -0.6227920055389404, -0.6102169752120972, -0.5951446294784546,
  -0.5872374176979065, -0.5885621309280396, -0.5960787534713745,
  -0.5953462719917297, -0.5773950219154358, -0.5598792433738708,
  -0.5703550577163696, -0.6046807765960693, -0.6320454478263855,
  -0.6440016627311707, -0.6494181752204895, -0.6389853954315186,
  -0.6162638664245605, -0.6169896721839905, -0.6463625431060791,
  -0.6646660566329956, -0.6521316766738892, -0.6220260858535767,
  -0.5925850868225098, -0.5882156491279602, -0.6209845542907715,
  -0.658588707447052, -0.6565770506858826, -0.6163357496261597,
  -0.5814226269721985, -0.5801991820335388, -0.5938841104507446,
  -0.5991394519805908, -0.605329155921936, -0.6109928488731384,
  -0.589693009853363, -0.5559173822402954, -0.5547440052032471,
  -0.5777620673179626, -0.585179328918457, -0.5769492983818054,
  -0.5648499727249146, -0.5380553007125854, -0.4986483156681061,
  -0.46761298179626465, -0.4480893611907959, -0.4216068983078003,
  -0.3712965250015259, -0.30165722966194153, -0.24402299523353577,
  -0.22430665791034698, -0.22310853004455566, -0.19357630610466003,
  -0.12371636927127838, -0.06371108442544937, -0.07309184968471527,
  -0.14286723732948303, -0.20198185741901398, -0.20420338213443756,
  -0.16925080120563507, -0.13849234580993652, -0.12838543951511383,
  -0.1286531537771225, -0.11611990630626678, -0.064116470515728,
  0.040904272347688675, 0.17422866821289062, 0.28175416588783264,
  0.3365294635295868, 0.36252784729003906, 0.3882278501987457,
  0.41392070055007935, 0.4270658493041992, 0.4162795841693878,
  0.3821711838245392, 0.3459782898426056, 0.3226466178894043,
  0.3066060543060303, 0.3043306767940521, 0.33246564865112305,
  0.3800804913043976, 0.4246399998664856, 0.46455124020576477,
  0.5035597085952759, 0.5401859283447266, 0.5773220658302307,
  0.6120954751968384, 0.6399459838867188, 0.6707122921943665,
  0.7062572836875916, 0.730632483959198, 0.743450403213501, 0.7543987035751343,
  0.7527240514755249, 0.7311533093452454, 0.7063062787055969,
  0.6862714886665344, 0.6608670353889465, 0.6262952089309692,
  0.5876880288124084, 0.5553058981895447, 0.5449873805046082,
  0.5592379570007324, 0.5809433460235596, 0.5997447371482849,
  0.6229123473167419, 0.6545474529266357, 0.6799917817115784,
  0.6812694072723389, 0.6642821431159973, 0.6476851105690002,
  0.6279579401016235, 0.5916334986686707, 0.5465524792671204,
  0.5045710206031799, 0.46724191308021545, 0.4428909718990326,
  0.4310946464538574, 0.4109385311603546, 0.38423487544059753,
  0.3764083981513977, 0.38182851672172546, 0.37520748376846313,
  0.35748687386512756, 0.33737316727638245, 0.31200286746025085,
  0.28619492053985596, 0.26468655467033386, 0.23883186280727386,
  0.2082834243774414, 0.18118007481098175, 0.15070609748363495,
  0.11046963185071945, 0.07604454457759857, 0.06029042229056358,
  0.053791292011737823, 0.04768829792737961, 0.04451194033026695,
  0.03999271243810654, 0.026608314365148544, 0.013810830190777779,
  0.012975062243640423, 0.01397843100130558, 0.002820015186443925,
  -0.02029147930443287, -0.051983535289764404, -0.08629605174064636,
  -0.11242730170488358, -0.13247829675674438, -0.15729886293411255,
  -0.18329060077667236, -0.20237471163272858, -0.21626342833042145,
  -0.22500073909759521, -0.228363037109375, -0.23225298523902893,
  -0.2356235384941101, -0.23280273377895355, -0.22569426894187927,
  -0.21323226392269135, -0.19026482105255127, -0.16309979557991028,
  -0.14198468625545502, -0.12932679057121277, -0.12418695539236069,
  -0.12261325865983963, -0.12461583316326141, -0.14213337004184723,
  -0.17900550365447998, -0.22156256437301636, -0.25794464349746704,
  -0.27917104959487915, -0.2785969376564026, -0.26932287216186523,
  -0.26542654633522034, -0.2535232901573181, -0.21905644237995148,
  -0.16833467781543732, -0.11162231862545013, -0.06275681406259537,
  -0.03693521395325661, -0.027258340269327164, -0.01870935596525669,
  -0.015432298183441162, -0.021582666784524918, -0.028637846931815147,
  -0.03456805646419525, -0.04123926907777786, -0.04380243271589279,
  -0.03991193324327469, -0.029762187972664833, -0.012439487501978874,
  0.007017416879534721, 0.023328809067606926, 0.038355402648448944,
  0.05224890261888504, 0.06513034552335739, 0.08425946533679962,
  0.11186345666646957, 0.13724969327449799, 0.15117612481117249,
  0.15894882380962372, 0.17555199563503265, 0.20595021545886993,
  0.23745469748973846, 0.26085782051086426, 0.27707022428512573,
  0.27815499901771545, 0.2608790993690491, 0.25031912326812744,
  0.2685846984386444, 0.3029509484767914, 0.331227570772171,
  0.34289225935935974, 0.3327908515930176, 0.3115990459918976,
  0.3077954351902008, 0.3347996771335602, 0.37566664814949036,
  0.4033513069152832, 0.39914318919181824, 0.3678364157676697,
  0.3433148264884949, 0.35373976826667786, 0.38551101088523865,
  0.409096896648407, 0.4199838936328888, 0.42570552229881287,
  0.42535626888275146, 0.42625635862350464, 0.4413943886756897,
  0.45961469411849976, 0.45886746048927307, 0.4410727918148041,
  0.4206339418888092, 0.4017307758331299, 0.3836176097393036,
  0.3616543412208557, 0.33006227016448975, 0.30161309242248535,
  0.2949931025505066, 0.2994314730167389, 0.29005664587020874,
  0.26687249541282654, 0.24776382744312286, 0.2413441389799118,
  0.2432704120874405, 0.2438654750585556, 0.2355036437511444,
  0.21767489612102509, 0.19500744342803955, 0.17399311065673828,
  0.159182608127594, 0.14774997532367706, 0.13216958940029144,
  0.1056007593870163, 0.06329772621393204, 0.011791273020207882,
  -0.029478538781404495, -0.05217314511537552, -0.07116676867008209,
  -0.09980478137731552, -0.1309858113527298, -0.14997436106204987,
  -0.15369699895381927, -0.15274320542812347, -0.1568889617919922,
  -0.16667716205120087, -0.18126419186592102, -0.19894304871559143,
  -0.21344172954559326, -0.22657768428325653, -0.24855603277683258,
  -0.27719202637672424, -0.30385181307792664, -0.3294741213321686,
  -0.3523825407028198, -0.3686840236186981, -0.3849535584449768,
  -0.3978961706161499, -0.39504384994506836, -0.39378881454467773,
  -0.41918420791625977, -0.45191895961761475, -0.46321842074394226,
  -0.4617474377155304, -0.46244075894355774, -0.46350234746932983,
  -0.4651064872741699, -0.4666972756385803, -0.46561920642852783,
  -0.4737553596496582, -0.4969693422317505, -0.511938750743866,
  -0.5047199726104736, -0.5016390085220337, -0.5252527594566345,
  -0.5538085699081421, -0.5616597533226013, -0.5667160153388977,
  -0.5866336226463318, -0.5903136730194092, -0.561774492263794,
  -0.5472639799118042, -0.5660436749458313, -0.57059246301651,
  -0.5500586032867432, -0.5539345741271973, -0.5903478860855103,
  -0.6159675121307373, -0.6158686876296997, -0.6113673448562622,
  -0.6153404712677002, -0.6224986910820007, -0.6269790530204773,
  -0.6310871839523315, -0.6324002146720886, -0.6215035319328308,
  -0.6052749156951904, -0.5946082472801208, -0.5803452730178833,
  -0.5724514126777649, -0.5955002903938293, -0.6123170852661133,
  -0.579042375087738, -0.5499377846717834, -0.5740830898284912,
  -0.5957282185554504, -0.5842725038528442, -0.5859127044677734,
  -0.5922389030456543, -0.5609189867973328, -0.5312753915786743,
  -0.5480234026908875, -0.5743930339813232, -0.5754024982452393,
  -0.5570343732833862, -0.514600932598114, -0.4537055492401123,
  -0.4074992537498474, -0.37317565083503723, -0.3222152888774872,
  -0.27929025888442993, -0.2833678424358368, -0.2935551702976227,
  -0.23929354548454285, -0.13537278771400452, -0.06148646026849747,
  -0.06119270250201225, -0.11967002600431442, -0.19045838713645935,
  -0.21041451394557953, -0.15647560358047485, -0.09416431933641434,
  -0.0948234349489212, -0.13656878471374512, -0.15795373916625977,
  -0.14064276218414307, -0.07821065187454224, 0.039318930357694626,
  0.1677912324666977, 0.2400800883769989, 0.2668110430240631,
  0.30862411856651306, 0.37073734402656555, 0.41491034626960754,
  0.43286311626434326, 0.43526577949523926, 0.4162271022796631,
  0.37285304069519043, 0.32245951890945435, 0.28714025020599365,
  0.28260913491249084, 0.3093147575855255, 0.347057044506073,
  0.37655648589134216, 0.4019192159175873, 0.4382005035877228,
  0.4827073812484741, 0.5124772787094116, 0.5216273069381714, 0.539864718914032,
  0.5829048752784729, 0.6235560774803162, 0.6448089480400085,
  0.6611137390136719, 0.6736720204353333, 0.6750858426094055,
  0.6769732236862183, 0.6770130395889282, 0.6516342163085938,
  0.6006609201431274, 0.5406569838523865, 0.48213982582092285,
  0.4483030438423157, 0.453830748796463, 0.46914780139923096,
  0.47042587399482727, 0.47814658284187317, 0.5041272640228271,
  0.5312345623970032, 0.5542858242988586, 0.5749755501747131,
  0.5772280693054199, 0.551253080368042, 0.5079643130302429, 0.4576423168182373,
  0.4110775291919708, 0.386597603559494, 0.37953415513038635,
  0.35623830556869507, 0.3089599013328552, 0.27471137046813965,
  0.2776661813259125, 0.2995563745498657, 0.3147573173046112,
  0.30966436862945557, 0.27933770418167114, 0.2401546835899353,
  0.2181635946035385, 0.21113544702529907, 0.19783496856689453,
  0.17311620712280273, 0.139228954911232, 0.09067222476005554,
  0.03440288081765175, -0.007607376668602228, -0.028398333117365837,
  -0.03881565481424332, -0.04284348338842392, -0.03956415876746178,
  -0.03867259994149208, -0.04523841291666031, -0.04522800073027611,
  -0.031423211097717285, -0.01809771917760372, -0.01559483539313078,
  -0.020769523456692696, -0.03154052421450615, -0.047864388674497604,
  -0.07148966193199158, -0.10677889734506607, -0.14550401270389557,
  -0.17048880457878113, -0.18058444559574127, -0.18559667468070984,
  -0.19215692579746246, -0.20768548548221588, -0.22834992408752441,
  -0.23611006140708923, -0.22924360632896423, -0.21962521970272064,
  -0.2062302529811859, -0.18974120914936066, -0.1774722933769226,
  -0.15878178179264069, -0.1223912388086319, -0.0810934379696846,
  -0.048357781022787094, -0.02804391272366047, -0.0271714199334383,
  -0.04442431777715683, -0.06692906469106674, -0.08748944103717804,
  -0.10534367710351944, -0.12401791661977768, -0.1481272280216217,
  -0.16784580051898956, -0.16859744489192963, -0.14933989942073822,
  -0.11087490618228912, -0.05967259407043457, -0.019356979057192802,
  0.0030858309473842382, 0.026571234688162804, 0.05322235822677612,
  0.07184697687625885, 0.08736813068389893, 0.10155964642763138,
  0.1059325635433197, 0.10602401196956635, 0.11114883422851562,
  0.11966592073440552, 0.13315877318382263, 0.15215371549129486,
  0.1638905256986618, 0.15818533301353455, 0.14281857013702393,
  0.13546085357666016, 0.14247271418571472, 0.1514628529548645,
  0.15555186569690704, 0.16542264819145203, 0.1854591965675354,
  0.21174819767475128, 0.24584953486919403, 0.27802860736846924,
  0.28925585746765137, 0.2825159430503845, 0.2752353250980377,
  0.2777769863605499, 0.2942097783088684, 0.3109394907951355,
  0.3034982681274414, 0.27847641706466675, 0.26766037940979004,
  0.28311678767204285, 0.3129650056362152, 0.3413509130477905,
  0.3623782992362976, 0.3806580901145935, 0.38705551624298096,
  0.36595645546913147, 0.33622506260871887, 0.32441380620002747,
  0.3194397985935211, 0.308580607175827, 0.2967643141746521,
  0.27651625871658325, 0.25210732221603394, 0.251257061958313,
  0.2703956961631775, 0.2860237658023834, 0.30480971932411194,
  0.32857200503349304, 0.3321714997291565, 0.3140871822834015,
  0.2973112463951111, 0.2864902913570404, 0.2749072015285492,
  0.26581481099128723, 0.2627667784690857, 0.2558499574661255,
  0.2319359928369522, 0.2005813717842102, 0.18164879083633423,
  0.16677573323249817, 0.14210808277130127, 0.12110713124275208,
  0.10703244805335999, 0.08607460558414459, 0.06863922625780106,
  0.0641622543334961, 0.057126834988594055, 0.05266159772872925,
  0.06551341712474823, 0.07414153963327408, 0.0626966804265976,
  0.04934932664036751, 0.038803987205028534, 0.020377425476908684,
  -0.002929101698100567, -0.029989661648869514, -0.06355492770671844,
  -0.09215839952230453, -0.10734677314758301, -0.11775905638933182,
  -0.13241751492023468, -0.14714393019676208, -0.14996163547039032,
  -0.14489325881004333, -0.15428800880908966, -0.1780177354812622,
  -0.19410715997219086, -0.20721788704395294, -0.23418714106082916,
  -0.2635141611099243, -0.2809096574783325, -0.2890450358390808,
  -0.287871778011322, -0.28050294518470764, -0.2775839865207672,
  -0.2789018154144287, -0.2830718755722046, -0.29629093408584595,
  -0.3135099411010742, -0.32351112365722656, -0.3249867856502533,
  -0.3236940801143646, -0.3333650231361389, -0.3624271750450134,
  -0.3903254568576813, -0.3942278325557709, -0.38480669260025024,
  -0.38322076201438904, -0.39282700419425964, -0.4048008620738983,
  -0.41145095229148865, -0.41896650195121765, -0.43668806552886963,
  -0.45329412817955017, -0.45836469531059265, -0.46270039677619934,
  -0.4657794237136841, -0.45774510502815247, -0.45329028367996216,
  -0.4606824815273285, -0.4581487476825714, -0.4434465169906616,
  -0.44178569316864014, -0.4598928689956665, -0.4884278178215027,
  -0.5165334939956665, -0.5225902199745178, -0.5072356462478638,
  -0.5127438902854919, -0.5485947132110596, -0.5602931380271912,
  -0.5320588946342468, -0.52718186378479, -0.5659002065658569,
  -0.5787444710731506, -0.5394523739814758, -0.5138307809829712,
  -0.5339505672454834, -0.5600928664207458, -0.5745686292648315,
  -0.586920440196991, -0.5807385444641113, -0.5495150089263916,
  -0.5223055481910706, -0.5165003538131714, -0.5336326360702515,
  -0.5736980438232422, -0.5992866158485413, -0.5652201175689697,
  -0.5109634399414062, -0.513860821723938, -0.5559719800949097,
  -0.5610570311546326, -0.528952419757843, -0.5046340227127075,
  -0.47183656692504883, -0.3980906903743744, -0.3151790201663971,
  -0.2690812945365906, -0.2604450285434723, -0.2573384940624237,
  -0.21384009718894958, -0.12448235601186752, -0.07126381993293762,
  -0.11470895260572433, -0.18728893995285034, -0.20522281527519226,
  -0.18627199530601501, -0.17881685495376587, -0.1885744035243988,
  -0.20373249053955078, -0.21171697974205017, -0.1958998292684555,
  -0.13739365339279175, -0.026635024696588516, 0.09667868912220001,
  0.16555006802082062, 0.18937982618808746, 0.23771461844444275,
  0.31592807173728943, 0.37131229043006897, 0.3821042776107788,
  0.36271557211875916, 0.3407151997089386, 0.3350782096385956,
  0.32211366295814514, 0.28302201628685, 0.2622651755809784,
  0.28802600502967834, 0.31524336338043213, 0.317747563123703,
  0.33067062497138977, 0.3724929094314575, 0.4159489572048187,
  0.43477770686149597, 0.4344727694988251, 0.4469486176967621,
  0.4949055016040802, 0.5582637786865234, 0.5959676504135132,
  0.5971469283103943, 0.5913047790527344, 0.6022204756736755, 0.612324595451355,
  0.6024460196495056, 0.5895958542823792, 0.5743901133537292,
  0.5204862356185913, 0.4385119378566742, 0.39299464225769043,
  0.398834764957428, 0.41208580136299133, 0.40981388092041016,
  0.4013656675815582, 0.4020153284072876, 0.4254455268383026,
  0.4612140357494354, 0.481338232755661, 0.48551130294799805,
  0.48539867997169495, 0.4628349542617798, 0.4073677957057953,
  0.3534700572490692, 0.330644428730011, 0.32165059447288513,
  0.2962389290332794, 0.2514081597328186, 0.21129243075847626,
  0.20051832497119904, 0.21411575376987457, 0.2234433889389038,
  0.21698150038719177, 0.20742543041706085, 0.19908112287521362,
  0.1869455873966217, 0.17925897240638733, 0.17970174551010132,
  0.16666293144226074, 0.12619927525520325, 0.07870613783597946,
  0.0483124740421772, 0.03357267752289772, 0.01719564199447632,
  -0.01425467524677515, -0.054304338991642, -0.07839802652597427,
  -0.07758430391550064, -0.07200184464454651, -0.07323770970106125,
  -0.06749185174703598, -0.05084700509905815, -0.03885062783956528,
  -0.03412032499909401, -0.025822103023529053, -0.018024886026978493,
  -0.024082880467176437, -0.04361670836806297, -0.06584572046995163,
  -0.08039280772209167, -0.08155500888824463, -0.07834936678409576,
  -0.08929591625928879, -0.11715909838676453, -0.14817798137664795,
  -0.17240838706493378, -0.18305443227291107, -0.17317640781402588,
  -0.14979802072048187, -0.1302081197500229, -0.12062465399503708,
  -0.11242662370204926, -0.09000200778245926, -0.04411672428250313,
  0.009700928814709187, 0.04531770199537277, 0.061619602143764496,
  0.07159863412380219, 0.07045791298151016, 0.05411791428923607,
  0.04085436463356018, 0.03939482569694519, 0.03093811310827732,
  0.0063177309930324554, -0.013858303427696228, -0.009131599217653275,
  0.01606661267578602, 0.04146465286612511, 0.053894516080617905,
  0.061305902898311615, 0.08143921941518784, 0.1147458404302597,
  0.14364489912986755, 0.1595960259437561, 0.16851061582565308,
  0.17397236824035645, 0.17847490310668945, 0.19007983803749084,
  0.2098558098077774, 0.22663934528827667, 0.23140491545200348,
  0.22643670439720154, 0.22183358669281006, 0.22497962415218353,
  0.22906358540058136, 0.2215331792831421, 0.20585525035858154,
  0.19627048075199127, 0.19576945900917053, 0.1995958834886551,
  0.20862235128879547, 0.22415409982204437, 0.24113775789737701,
  0.25257235765457153, 0.2549489140510559, 0.2520782947540283,
  0.2516515552997589, 0.25212717056274414, 0.24362994730472565,
  0.2270583212375641, 0.21433387696743011, 0.20733223855495453,
  0.1987091600894928, 0.191719651222229, 0.1965794712305069,
  0.21364104747772217, 0.23562690615653992, 0.2536334991455078,
  0.25813430547714233, 0.25108325481414795, 0.24597790837287903,
  0.2435532510280609, 0.2317543774843216, 0.21157124638557434,
  0.19384793937206268, 0.18018756806850433, 0.1679132729768753,
  0.1573827564716339, 0.14785528182983398, 0.13966868817806244,
  0.13599181175231934, 0.13895027339458466, 0.14888127148151398,
  0.1598030924797058, 0.15922757983207703, 0.14635373651981354,
  0.13824723660945892, 0.1458485871553421, 0.15699921548366547,
  0.15273858606815338, 0.13316385447978973, 0.11398765444755554,
  0.10041942447423935, 0.08696887642145157, 0.07388975471258163,
  // adding after 15k points
  -0.19375430047512054, -0.11867111176252365, -0.09968994557857513,
  -0.1651657223701477, -0.2053573578596115, -0.17689819633960724,
  -0.1558038741350174, -0.17170673608779907, -0.16645239293575287,
  -0.1314517855644226, -0.13668780028820038, -0.19619235396385193,
  -0.22824004292488098, -0.18854504823684692, -0.1327531486749649,
  -0.11199744790792465, -0.12494906783103943, -0.17524391412734985,
  -0.23775945603847504, -0.22790496051311493, -0.14556199312210083,
  -0.1132265031337738, -0.16715310513973236, -0.2085656374692917,
  -0.1922367960214615, -0.1593567281961441, -0.13957999646663666,
  -0.1532554030418396, -0.19517937302589417, -0.20420405268669128,
  -0.16304457187652588, -0.1329408437013626, -0.14150403439998627,
  -0.1664872169494629, -0.18725766241550446, -0.1786399632692337,
  -0.15077510476112366, -0.15437725186347961, -0.17511844635009766,
  -0.16251231729984283, -0.14273999631404877, -0.15076206624507904,
  -0.16805993020534515, -0.18880636990070343, -0.19798864424228668,
  -0.161538228392601, -0.12170524150133133, -0.129585862159729,
  -0.1384020894765854, -0.12820404767990112, -0.15000131726264954,
  -0.18329784274101257, -0.17238211631774902, -0.13808579742908478,
  -0.11485684663057327, -0.11381252855062485, -0.1395939290523529,
  -0.15034322440624237, -0.12596495449543, -0.1311466246843338,
  -0.16629628837108612, -0.14685939252376556, -0.09329419583082199,
  -0.0905478373169899, -0.12181290239095688, -0.13696067035198212,
  -0.13686582446098328, -0.12236746400594711, -0.10017050802707672,
  -0.09499847888946533, -0.10517780482769012, -0.12045309692621231,
  -0.13196733593940735, -0.10831757634878159, -0.058992624282836914,
  -0.04821104556322098, -0.08663657307624817, -0.12870945036411285,
  -0.1464509814977646, -0.12240644544363022, -0.07200383394956589,
  -0.05979903042316437, -0.08950954675674438, -0.09298302233219147,
  -0.07222148030996323, -0.08439449220895767, -0.10855033993721008,
  -0.08159268647432327, -0.02505779080092907, -0.0266012754291296,
  -0.0966060534119606, -0.131122887134552, -0.08389275521039963,
  -0.053055290132761, -0.08726398646831512, -0.09601222723722458,
  -0.04744939133524895, -0.025780949741601944, -0.06267160922288895,
  -0.106348916888237, -0.1121465265750885, -0.0705481618642807,
  -0.022208798676729202, -0.022917604073882103, -0.05528968945145607,
  -0.06979041546583176, -0.06980627775192261, -0.06189591437578201,
  -0.03066740557551384, 0.0026462229434400797, 0.009022868238389492,
  0.00006783060962334275, -0.010613312013447285, -0.020574213936924934,
  -0.023036295548081398, -0.02144494652748108, -0.011701109819114208,
  0.017310459166765213, 0.025307612493634224, -0.03238043561577797,
  -0.09804267436265945, -0.08236832171678543, -0.001617654343135655,
  0.06069347634911537, 0.06606835871934891, 0.03430606424808502,
  0.0017357097240164876, -0.003384668380022049, 0.02702510915696621,
  0.07362224906682968, 0.10004225373268127, 0.08870347589254379,
  0.05924410745501518, 0.03538607805967331, 0.021226832643151283,
  0.022720320150256157, 0.048496946692466736, 0.08317036926746368,
  0.09898678213357925, 0.0911535993218422, 0.0788336768746376,
  0.07852920144796371, 0.08593573421239853, 0.08832703530788422,
  0.09262978285551071, 0.1120443344116211, 0.12497448921203613,
  0.1035737693309784, 0.07882820814847946, 0.09650525450706482,
  0.12859292328357697, 0.12746039032936096, 0.12022272497415543,
  0.14520679414272308, 0.17297974228858948, 0.17360463738441467,
  0.16523240506649017, 0.1600259393453598, 0.1518365442752838,
  0.14178583025932312, 0.12940926849842072, 0.12275518476963043,
  0.1387387067079544, 0.15859605371952057, 0.15008367598056793,
  0.12700118124485016, 0.12129972130060196, 0.14453688263893127,
  0.1890818327665329, 0.21612288057804108, 0.19913405179977417,
  0.1799176037311554, 0.1890908181667328, 0.18809349834918976,
  0.1607816517353058, 0.14136624336242676, 0.14963404834270477,
  0.18483686447143555, 0.21528257429599762, 0.18783064186573029,
  0.1318875253200531, 0.13195432722568512, 0.1711273044347763,
  0.17887622117996216, 0.1684175282716751, 0.17687062919139862,
  0.19112782180309296, 0.19421809911727905, 0.1818384975194931,
  0.16090621054172516, 0.1605711430311203, 0.18611247837543488,
  0.20419318974018097, 0.19699695706367493, 0.1760127693414688,
  0.1678304672241211, 0.1862080693244934, 0.19393698871135712,
  0.1706281304359436, 0.17114447057247162, 0.20360615849494934,
  0.19591060280799866, 0.15288104116916656, 0.14714406430721283,
  0.17594072222709656, 0.18765461444854736, 0.16493657231330872,
  0.13232974708080292, 0.14094364643096924, 0.18548250198364258,
  0.1883866935968399, 0.15439310669898987, 0.1624465435743332,
  0.1900785267353058, 0.178428515791893, 0.16293400526046753,
  0.16719681024551392, 0.1591392606496811, 0.15121330320835114,
  0.16705207526683807, 0.1799626648426056, 0.16288460791110992,
  0.1213473528623581, 0.10091670602560043, 0.1410141885280609,
  0.17938272655010223, 0.1521383672952652, 0.13170893490314484,
  0.15647941827774048, 0.1417195200920105, 0.10145474225282669,
  0.12505970895290375, 0.160551518201828, 0.14173197746276855,
  0.12625108659267426, 0.12997139990329742, 0.1120554506778717,
  0.10548888891935349, 0.13010071218013763, 0.13924844563007355,
  0.129566490650177, 0.12051129341125488, 0.10709115862846375,
  0.11313927918672562, 0.13621051609516144, 0.11451443284749985,
  0.06439121067523956, 0.06518413126468658, 0.10252746939659119,
  0.11709853261709213, 0.10897304862737656, 0.09363113343715668,
  0.08381687104701996, 0.09540881961584091, 0.10618282854557037,
  0.09728220850229263, 0.08831839263439178, 0.0761934444308281,
  0.0678270161151886, 0.10417933017015457, 0.13241030275821686,
  0.06747664511203766, -0.007435561623424292, 0.014528518542647362,
  0.06719918549060822, 0.08527939766645432, 0.09816554933786392,
  0.08632960170507431, 0.03821706399321556, 0.027299879118800163,
  0.054807137697935104, 0.059265218675136566, 0.06662635505199432,
  0.0907844752073288, 0.0733608528971672, 0.04123006388545036,
  0.060608554631471634, 0.07811007648706436, 0.03677075356245041,
  -0.00134330615401268, 0.01867208443582058, 0.057468656450510025,
  0.06636697798967361, 0.05018259957432747, 0.027276307344436646,
  -0.000016986505215754732, -0.00016688031610101461, 0.055474258959293365,
  0.08306150138378143, 0.00862039439380169, -0.04483732208609581,
  0.026721298694610596, 0.09226524829864502, 0.054195303469896317,
  0.0106102479621768, 0.008946647867560387, 0.0025705290026962757,
  0.011113271117210388, 0.04536746069788933, 0.047895219177007675,
  0.018710605800151825, 0.006585380993783474, 0.012225005775690079,
  0.012767567299306393, 0.005349737126380205, 0.007424089126288891,
  0.022331971675157547, 0.005514342337846756, -0.04476416856050491,
  -0.0404147133231163, 0.028703851625323296, 0.058574337512254715,
  0.016910269856452942, -0.039527084678411484, -0.06445939093828201,
  -0.028654346242547035, 0.02715892530977726, 0.008154869079589844,
  -0.055070795118808746, -0.051493506878614426, -0.004560278728604317,
  -0.00146153022069484, -0.027577389031648636, -0.03031424805521965,
  -0.015109033323824406, -0.02533608302474022, -0.06671769917011261,
  -0.07149576395750046, -0.01526164636015892, 0.015601168386638165,
  -0.01981152407824993, -0.07109245657920837, -0.11068201065063477,
  -0.1037701815366745, -0.027515951544046402, 0.018682939931750298,
  -0.022189529612660408, -0.056189391762018204, -0.07358229905366898,
  -0.12924043834209442, -0.12517298758029938, -0.0139850452542305,
  0.04280088096857071, -0.029884200543165207, -0.12311246246099472,
  -0.1558951437473297, -0.121603824198246, -0.062021493911743164,
  -0.04054231196641922, -0.03230415657162666, -0.005463700741529465,
  -0.04526117444038391, -0.15677571296691895, -0.1944401115179062,
  -0.13125260174274445, -0.07298675924539566, -0.04814591258764267,
  -0.04698367044329643, -0.09238434582948685, -0.1517494022846222,
  -0.15636880695819855, -0.10425148904323578, -0.0592334158718586,
  -0.07838720828294754, -0.13204360008239746, -0.14185959100723267,
  -0.12300559133291245, -0.13642139732837677, -0.14431443810462952,
  -0.11389274895191193, -0.11628809571266174, -0.16298887133598328,
  -0.16348527371883392, -0.11274393647909164, -0.09831982851028442,
  -0.1357710361480713, -0.1657072901725769, -0.16298744082450867,
  -0.14474999904632568, -0.12191841006278992, -0.10774295032024384,
  -0.1284312605857849, -0.1750352382659912, -0.20098663866519928,
  -0.1974203884601593, -0.1845303624868393, -0.1471482217311859,
  -0.09353677928447723, -0.0946621522307396, -0.1575068086385727,
  -0.1962192803621292, -0.18275007605552673, -0.16621507704257965,
  -0.1600014865398407, -0.14773719012737274, -0.1366693526506424,
  -0.13491158187389374, -0.14735902845859528, -0.17748861014842987,
  -0.1930854767560959, -0.17312082648277283, -0.16402296721935272,
  -0.18681679666042328, -0.19035229086875916, -0.16701160371303558,
  -0.16107288002967834, -0.16674315929412842, -0.16634728014469147,
  -0.17781205475330353, -0.1843601018190384, -0.16337832808494568,
  -0.15510335564613342, -0.17102165520191193, -0.16768209636211395,
  -0.15798497200012207, -0.1695847362279892, -0.16566148400306702,
  -0.14919328689575195, -0.17654797434806824, -0.21602864563465118,
  -0.1943325400352478, -0.14476728439331055, -0.13920117914676666,
  -0.16173139214515686, -0.16833806037902832, -0.1736450344324112,
  -0.18951427936553955, -0.1726527065038681, -0.11655576527118683,
  -0.08854476362466812, -0.11764886975288391, -0.16934654116630554,
  -0.20809562504291534, -0.18746691942214966, -0.11447259038686752,
  -0.10207396745681763, -0.16108304262161255, -0.16048818826675415,
  -0.11879628151655197, -0.14218877255916595, -0.1637192964553833,
  -0.14275555312633514, -0.17050574719905853, -0.19260713458061218,
  -0.1250593364238739, -0.08673113584518433, -0.12173469364643097,
  -0.12906591594219208, -0.1373617947101593, -0.17403145134449005,
  -0.1483391672372818, -0.09598351269960403, -0.09875968843698502,
  -0.1069541648030281, -0.12662406265735626, -0.17922592163085938,
  -0.16094602644443512, -0.10119844973087311, -0.13126476109027863,
  -0.16166619956493378, -0.11124201118946075, -0.1070803850889206,
  -0.15563809871673584, -0.14866535365581512, -0.12556812167167664,
  -0.12427341938018799, -0.10516490787267685, -0.09854642301797867,
  -0.1113089993596077, -0.09809094667434692, -0.0866003930568695,
  -0.07975539565086365, -0.045947425067424774, -0.04318474605679512,
  -0.07092983275651932, -0.04988739639520645, -0.03472479060292244,
  -0.07045286893844604, -0.06651245802640915, -0.0428309291601181,
  -0.07555544376373291, -0.09313276410102844, -0.07796001434326172,
  -0.10179700702428818, -0.10441872477531433, -0.045152775943279266,
  -0.018411919474601746, -0.02310519479215145, 0.001667988020926714,
  0.014982416294515133, 0.007330847438424826, 0.01975868083536625,
  0.028707368299365044, 0.031687650829553604, 0.05110658332705498,
  0.04377371445298195, 0.012783164158463478, 0.01981314830482006,
  0.0358702689409256, 0.02138597145676613, 0.023953473195433617,
  0.056524608284235, 0.0768674910068512, 0.07611450552940369,
  0.06290217489004135, 0.05165969580411911, 0.062268614768981934,
  0.08075065165758133, 0.09516045451164246, 0.11680807173252106,
  0.12338267266750336, 0.09982617199420929, 0.088772252202034,
  0.1111571416258812, 0.1405896693468094, 0.157243549823761, 0.1545587182044983,
  0.15156036615371704, 0.17165718972682953, 0.1771608293056488,
  0.14146947860717773, 0.12469946593046188, 0.15071983635425568,
  0.16077284514904022, 0.14504222571849823, 0.14415216445922852,
  0.15156961977481842, 0.1429113894701004, 0.13491487503051758,
  0.15604695677757263, 0.19642245769500732, 0.21397118270397186,
  0.20226524770259857, 0.1998397558927536, 0.20582741498947144,
  0.19094286859035492, 0.18096566200256348, 0.2027769684791565,
  0.21716932952404022, 0.19983208179473877, 0.18569684028625488,
  0.1944141685962677, 0.19941501319408417, 0.17983657121658325,
  0.1624908447265625, 0.18279269337654114, 0.215383380651474,
  0.21933476626873016, 0.21579872071743011, 0.2197912037372589,
  0.19957485795021057, 0.17766651511192322, 0.206770658493042,
  0.24462628364562988, 0.2305818349123001, 0.20391428470611572,
  0.206856831908226, 0.2055428922176361, 0.1869809627532959,
  0.19029349088668823, 0.21543043851852417, 0.22596293687820435,
  0.22616800665855408, 0.23192735016345978, 0.21998663246631622,
  0.18864493072032928, 0.1789446473121643, 0.19307787716388702,
  0.20104952156543732, 0.2052965760231018, 0.20695310831069946,
  0.19263270497322083, 0.18204718828201294, 0.18929874897003174,
  0.19361594319343567, 0.19844716787338257, 0.21375182271003723,
  0.21573412418365479, 0.2032848447561264, 0.19689758121967316,
  0.18875941634178162, 0.1776280701160431, 0.17417079210281372,
  0.17097659409046173, 0.17390389740467072, 0.17844100296497345,
  0.15150591731071472, 0.12777897715568542, 0.1621624231338501,
  0.19565239548683167, 0.1783347874879837, 0.1673387885093689,
  0.17431232333183289, 0.1623881608247757, 0.15422053635120392,
  0.15123800933361053, 0.13188529014587402, 0.14164216816425323,
  0.17883022129535675, 0.16915389895439148, 0.13314048945903778,
  0.12822510302066803, 0.13067485392093658, 0.13392728567123413,
  0.14791421592235565, 0.13565890491008759, 0.11601567268371582,
  0.13138127326965332, 0.13108427822589874, 0.10263021290302277,
  0.10693127661943436, 0.11053240299224854, 0.07601309567689896,
  0.08117105066776276, 0.13095852732658386, 0.13459107279777527,
  0.10671639442443848, 0.10448776930570602, 0.10015347599983215,
  0.08368255198001862, 0.08767090737819672, 0.09894154965877533,
  0.09846717119216919, 0.09748005867004395, 0.09006112068891525,
  0.07083617895841599, 0.0579109713435173, 0.05606354400515556,
  0.058193597942590714, 0.061218950897455215, 0.058038659393787384,
  0.05799451470375061, 0.07782603800296783, 0.0929623544216156,
  0.07529685646295547, 0.052319932729005814, 0.051766376942396164,
  0.056549981236457825, 0.05986035242676735, 0.07132627069950104,
  0.0689832791686058, 0.04181889444589615, 0.031204186379909515,
  0.0522434301674366, 0.061830341815948486, 0.04598035663366318,
  0.03499501198530197, 0.03661295026540756, 0.042087066918611526,
  0.0537409633398056, 0.059842973947525024, 0.04301205277442932,
  0.017940016463398933, 0.015086382627487183, 0.037397321313619614,
  0.051783666014671326, 0.03649609908461571, 0.02434861846268177,
  0.04231954365968704, 0.051210999488830566, 0.0272364504635334,
  0.012553826905786991, 0.023518580943346024, 0.027178457006812096,
  0.017012080177664757, 0.014258711598813534, 0.022285813465714455,
  0.01839003711938858, -0.01143583096563816, -0.021133800968527794,
  0.03054686263203621, 0.07112409174442291, 0.03190021589398384,
  -0.01816904917359352, -0.016814835369586945, 0.0025354798417538404,
  0.018866242840886116, 0.015507316216826439, -0.028740178793668747,
  -0.049031488597393036, -0.009395415894687176, 0.0022080696653574705,
  -0.023078929632902145, -0.007058089133352041, 0.0034543792717158794,
  -0.04462290555238724, -0.0639171376824379, -0.020726244896650314,
  0.012830609455704689, 0.013562262058258057, -0.015863949432969093,
  -0.06872723996639252, -0.08227774500846863, -0.04628415405750275,
  -0.03280435875058174, -0.044778238981962204, -0.03854486718773842,
  -0.029778122901916504, -0.03953799232840538, -0.061242420226335526,
  -0.0827697217464447, -0.07530765980482101, -0.04834870249032974,
  -0.05641776695847511, -0.0840439572930336, -0.07350774109363556,
  -0.04298420995473862, -0.04459761828184128, -0.08885462582111359,
  -0.13336363434791565, -0.1054246723651886, -0.023216208443045616,
  -0.010726955719292164, -0.09238869696855545, -0.16489653289318085,
  -0.169748917222023, -0.11152602732181549, -0.04228803515434265,
  -0.05792051553726196, -0.12925677001476288, -0.1279689520597458,
  -0.08878076076507568, -0.1062740758061409, -0.12161188572645187,
  -0.11467596143484116, -0.1567387878894806, -0.18568696081638336,
  -0.13818614184856415, -0.11657755821943283, -0.14413604140281677,
  -0.13001516461372375, -0.1136833131313324, -0.16002468764781952,
  -0.19025439023971558, -0.15378448367118835, -0.1151195764541626,
  -0.123462975025177, -0.1624545007944107, -0.1740735024213791,
  -0.13712282478809357, -0.1327364593744278, -0.19155526161193848,
  -0.21155105531215668, -0.17433661222457886, -0.16449272632598877,
  -0.16045662760734558, -0.13060322403907776, -0.15657083690166473,
  -0.22456830739974976, -0.21856927871704102, -0.1725662797689438,
  -0.18441987037658691, -0.2128203958272934, -0.2039615958929062,
  -0.19106462597846985, -0.18937242031097412, -0.1906556338071823,
  -0.1996641308069229, -0.20182079076766968, -0.20047152042388916,
  -0.21745435893535614, -0.21772512793540955, -0.18453313410282135,
  -0.18384242057800293, -0.22412678599357605, -0.24277418851852417,
  -0.24869179725646973, -0.26162707805633545, -0.23303717374801636,
  -0.1781059056520462, -0.17564339935779572, -0.21005380153656006,
  -0.2200368493795395, -0.2167326956987381, -0.22415384650230408,
  -0.2305184006690979, -0.2270190715789795, -0.21365207433700562,
  -0.20619386434555054, -0.2275758981704712, -0.25099971890449524,
  -0.23636101186275482, -0.21345342695713043, -0.22203204035758972,
  -0.23570698499679565, -0.22720091044902802, -0.22260305285453796,
  -0.2444555014371872, -0.26210394501686096, -0.2378370314836502,
  -0.20263700187206268, -0.21488632261753082, -0.24685633182525635,
  -0.2320583611726761, -0.19892309606075287, -0.21199916303157806,
  -0.2478753924369812, -0.25154218077659607, -0.2286895215511322,
  -0.21816976368427277, -0.23235072195529938, -0.2375718057155609,
  -0.20540428161621094, -0.17999477684497833, -0.20892676711082458,
  -0.24662305414676666, -0.24022556841373444, -0.2166082262992859,
  -0.2040044218301773, -0.19733533263206482, -0.2094966173171997,
  -0.23607048392295837, -0.23185722529888153, -0.19752106070518494,
  -0.1870250701904297, -0.2064165472984314, -0.20837518572807312,
  -0.1778235137462616, -0.14075930416584015, -0.10998372733592987,
  -0.08870642632246017, -0.09785444289445877, -0.13389891386032104,
  -0.14421819150447845, -0.10940361022949219, -0.08242223411798477,
  -0.0994538813829422, -0.13658404350280762, -0.16720424592494965,
  -0.18218031525611877, -0.17251083254814148, -0.1398807317018509,
  -0.09707624465227127, -0.058485761284828186, -0.04274934157729149,
  -0.04787478595972061, -0.03616047650575638, 0.011683665215969086,
  0.06044309213757515, 0.07145995646715164, 0.053316760808229446,
  0.03785780072212219, 0.029822612181305885, 0.01369304209947586,
  -0.0013773877872154117, 0.0038106776773929596, 0.013496735133230686,
  0.0073485709726810455, 0.0024281644728034735, 0.017149800434708595,
  0.04330035671591759, 0.07123343646526337, 0.09625905752182007,
  0.1142202690243721, 0.1283775418996811, 0.13668833673000336,
  0.1339767724275589, 0.13598737120628357, 0.15503770112991333,
  0.17082104086875916, 0.17068791389465332, 0.17560797929763794,
  0.1980559378862381, 0.2192215472459793, 0.21777623891830444,
  0.19503337144851685, 0.1721249371767044, 0.16790032386779785,
  0.18037402629852295, 0.19352665543556213, 0.19597573578357697,
  0.1887771040201187, 0.17975156009197235, 0.17433017492294312,
  0.17780593037605286, 0.19974951446056366, 0.2412036508321762,
  0.2846079468727112, 0.3066873252391815, 0.2990160286426544,
  0.27907341718673706, 0.2703310251235962, 0.2673080861568451,
  0.255258172750473, 0.2504160702228546, 0.2649092376232147,
  0.27021530270576477, 0.24465312063694, 0.20581823587417603,
  0.18259863555431366, 0.19646453857421875, 0.24058093130588531,
  0.2710607051849365, 0.2673114538192749, 0.25803184509277344,
  0.255919873714447, 0.24772143363952637, 0.24454282224178314,
  0.26121386885643005, 0.28046914935112, 0.28035008907318115,
  0.25639134645462036, 0.22248978912830353, 0.2095452845096588,
  0.2304774671792984, 0.2591213583946228, 0.26633521914482117,
  0.24534150958061218, 0.2115892618894577, 0.20082269608974457,
  0.22860130667686462, 0.25782984495162964, 0.2520102858543396,
  0.22422820329666138, 0.20333372056484222, 0.1986582726240158,
  0.20477013289928436, 0.2098538875579834, 0.2071463018655777,
  0.20290958881378174, 0.20574022829532623, 0.2186613231897354,
  0.2359507530927658, 0.23977407813072205, 0.21981003880500793,
  0.19334328174591064, 0.1839180439710617, 0.18989618122577667,
  0.19093455374240875, 0.1800599992275238, 0.17232602834701538,
  0.17306016385555267, 0.16399547457695007, 0.14436598122119904,
  0.14476589858531952, 0.17171616852283478, 0.18869702517986298,
  0.17460010945796967, 0.1549854576587677, 0.15942439436912537,
  0.1765131950378418, 0.17042873799800873, 0.1372203379869461,
  0.11412135511636734, 0.12175677716732025, 0.13791652023792267,
  0.14051517844200134, 0.13360457122325897, 0.12726832926273346,
  0.1252649575471878, 0.12704288959503174, 0.12569117546081543,
  0.1161913275718689, 0.10787332057952881, 0.1104755848646164,
  0.11031617224216461, 0.087701216340065, 0.056364934891462326,
  0.04677325859665871, 0.05906015634536743, 0.07886429131031036,
  0.10630228370428085, 0.12481307983398438, 0.10440734773874283,
  0.062889464199543, 0.04861830174922943, 0.067587710916996, 0.0907130315899849,
  0.0959857925772667, 0.07205954194068909, 0.029985997825860977,
  0.005115761421620846, 0.013036428019404411, 0.035204995423555374,
  0.05288376659154892, 0.06219416484236717, 0.0665137842297554,
  0.06662092357873917, 0.057443078607320786, 0.04539990425109863,
  0.04697706550359726, 0.0584266297519207, 0.06346505135297775,
  0.054226748645305634, 0.024422496557235718, -0.0058496561832726,
  0.00741841085255146, 0.044245216995477676, 0.040464162826538086,
  0.018474213778972626, 0.040677543729543686, 0.07550808787345886,
  0.06742601841688156, 0.04270755127072334, 0.04484899342060089,
  0.06792869418859482, 0.07770819962024689, 0.05084776133298874,
  0.013371789827942848, 0.005931389983743429, 0.014883208088576794,
  0.02168796956539154, 0.03602983057498932, 0.026124168187379837,
  -0.019343333318829536, -0.02037961408495903, 0.03206763416528702,
  0.04726521298289299, 0.0367630273103714, 0.052373647689819336,
  0.03311231732368469, -0.02320985496044159, -0.015837810933589935,
  0.026406362652778625, 0.02285189926624298, 0.033575739711523056,
  0.07551208883523941, 0.040621381253004074, -0.05766845867037773,
  -0.09625864773988724, -0.06369734555482864, -0.02079298347234726,
  0.00494247255846858, -0.00648917630314827, -0.04327438771724701,
  -0.059379059821367264, -0.05789753794670105, -0.0646081417798996,
  -0.06271126866340637, -0.038212381303310394, -0.0033034055959433317,
  0.02237144112586975, 0.0032008716370910406, -0.05741331726312637,
  -0.0871204137802124, -0.06695348024368286, -0.0629236102104187,
  -0.08547945320606232, -0.08877670764923096, -0.08792632073163986,
  -0.11520833522081375, -0.13831913471221924, -0.12732872366905212,
  -0.10259856283664703, -0.08351565897464752, -0.08003531396389008,
  -0.10619933903217316, -0.14051735401153564, -0.13677294552326202,
  -0.10128163546323776, -0.0865408256649971, -0.11126476526260376,
  -0.14969325065612793, -0.17659161984920502, -0.18086372315883636,
  -0.16244053840637207, -0.13868510723114014, -0.13553282618522644,
  -0.15647844970226288, -0.17219260334968567, -0.1661044955253601,
  -0.16270463168621063, -0.16792108118534088, -0.1549413651227951,
  -0.15233130753040314, -0.2053627222776413, -0.24543118476867676,
  -0.19505451619625092, -0.13334886729717255, -0.1539776772260666,
  -0.20574375987052917, -0.21924647688865662, -0.21007484197616577,
  -0.20535749197006226, -0.20906761288642883, -0.2113708257675171,
  -0.1924213171005249, -0.1729261428117752, -0.1974346935749054,
  -0.24216455221176147, -0.24789853394031525, -0.21526896953582764,
  -0.18774348497390747, -0.1947111189365387, -0.22724826633930206,
  -0.24980422854423523, -0.2553138732910156, -0.2595294713973999,
  -0.2490595281124115, -0.22457320988178253, -0.21975085139274597,
  -0.2308007776737213, -0.24633769690990448, -0.27734091877937317,
  -0.2777934670448303, -0.2198994904756546, -0.19765232503414154,
  -0.2459878921508789, -0.2653973400592804, -0.23981307446956635,
  -0.236404150724411, -0.24403494596481323, -0.24735024571418762,
  -0.26785993576049805, -0.2727587819099426, -0.25692981481552124,
  -0.2761556804180145, -0.29746589064598083, -0.26593461632728577,
  -0.2451973408460617, -0.2738610804080963, -0.29069653153419495,
  -0.2836436331272125, -0.2862018942832947, -0.28810298442840576,
  -0.28407537937164307, -0.2842208743095398, -0.27394741773605347,
  -0.2576131820678711, -0.25788065791130066, -0.26301348209381104,
  -0.26075977087020874, -0.26634567975997925, -0.2825697660446167,
  -0.2952079176902771, -0.2932834029197693, -0.2774880528450012,
  -0.27016952633857727, -0.2836878299713135, -0.29093071818351746,
  -0.27649006247520447, -0.26634177565574646, -0.2741139531135559,
  -0.2791450321674347, -0.2710394561290741, -0.2723197340965271,
  -0.29559987783432007, -0.30339834094047546, -0.2657879590988159,
  -0.22638842463493347, -0.23067398369312286, -0.25397995114326477,
  -0.26623669266700745, -0.26828598976135254, -0.2513095438480377,
  -0.21762847900390625, -0.19454437494277954, -0.18230964243412018,
  -0.16592557728290558, -0.1545945256948471, -0.15201407670974731,
  -0.15120209753513336, -0.15758810937404633, -0.16119949519634247,
  -0.14098815619945526, -0.11313683539628983, -0.10962022095918655,
  -0.13456284999847412, -0.17524011433124542, -0.21041664481163025,
  -0.2121744006872177, -0.1721990555524826, -0.11124125868082047,
  -0.060703035444021225, -0.03665908798575401, -0.023164743557572365,
  0.0005125095485709608, 0.030826225876808167, 0.0642552599310875,
  0.10174249857664108, 0.11915001273155212, 0.09755939245223999,
  0.060376498848199844, 0.03328592702746391, 0.016653407365083694,
  0.012620368972420692, 0.02517567202448845, 0.04188026115298271,
  0.04961268976330757, 0.053954675793647766, 0.07217630743980408,
  0.1126302108168602, 0.16332316398620605, 0.20355765521526337,
  0.22256165742874146, 0.22504922747612, 0.2248348444700241, 0.2295825481414795,
  0.2366863638162613, 0.24721133708953857, 0.2627105712890625,
  0.2771355211734772, 0.2922114133834839, 0.30773794651031494,
  0.30244743824005127, 0.274751216173172, 0.2605923116207123,
  0.2708539366722107, 0.27984461188316345, 0.27975237369537354,
  0.27668651938438416, 0.26552814245224, 0.25311923027038574,
  0.26080039143562317, 0.29334813356399536, 0.33485230803489685,
  0.3663788139820099, 0.38280636072158813, 0.393852561712265,
  0.40144023299217224, 0.39839038252830505, 0.38858047127723694,
  0.37570568919181824, 0.3562999367713928, 0.34135663509368896,
  0.341609925031662, 0.34264418482780457, 0.33099979162216187,
  0.31245890259742737, 0.2965807020664215, 0.2947041988372803,
  0.314154714345932, 0.342806875705719, 0.36234384775161743, 0.366171658039093,
  0.3577299416065216, 0.3480512201786041, 0.3446367383003235,
  0.34362080693244934, 0.3451346755027771, 0.3502087891101837,
  0.3456772565841675, 0.32658326625823975, 0.3089296221733093,
  0.301934152841568, 0.30193856358528137, 0.30598995089530945,
  0.30858924984931946, 0.3060339391231537, 0.3014262318611145,
  0.29303762316703796, 0.281439870595932, 0.2789961099624634,
  0.28535258769989014, 0.2807634770870209, 0.2599599063396454,
  0.24212513864040375, 0.23939546942710876, 0.23859049379825592,
  0.22701525688171387, 0.21872451901435852, 0.22906461358070374,
  0.2448323667049408, 0.24937015771865845, 0.24388325214385986,
  0.2303970754146576, 0.21309794485569, 0.20335069298744202, 0.1997046321630478,
  0.19459474086761475, 0.19157108664512634, 0.18685278296470642,
  0.1701277792453766, 0.15137289464473724, 0.14802613854408264,
  0.15951965749263763, 0.17162583768367767, 0.1698264628648758,
  0.15403027832508087, 0.13588064908981323, 0.11759643256664276,
  0.10234420001506805, 0.10128362476825714, 0.10087675601243973,
  0.08232344686985016, 0.06781581789255142, 0.07640745490789413,
  0.08908524364233017, 0.09892429411411285, 0.11213432252407074,
  0.11572433263063431, 0.11056329309940338, 0.11032126098871231,
  0.10023348778486252, 0.07280275970697403, 0.05025491863489151,
  0.03435100242495537, 0.015152250416576862, 0.006113860290497541,
  0.008239357732236385, 0.0049847085028886795, 0.0043129161931574345,
  0.01641911081969738, 0.026042986661195755, 0.02847820706665516,
  0.03824854642152786, 0.05267101526260376, 0.0524006150662899,
  0.036210235208272934, 0.026068322360515594, 0.030055904760956764,
  0.028778107836842537, 0.01930832490324974, 0.022926902398467064,
  0.03252703323960304, 0.025499669834971428, 0.016421150416135788,
  0.02196553163230419, 0.025005709379911423, 0.02054578810930252,
  0.02015417441725731, 0.012028258293867111, -0.006278504151850939,
  -0.0023932026233524084, 0.02966426871716976, 0.053177665919065475,
  0.05223142355680466, 0.04279334098100662, 0.03564947098493576,
  0.040029510855674744, 0.066627137362957, 0.09955231845378876,
  0.11009261757135391, 0.09580164402723312, 0.0682390108704567,
  0.03646332770586014, 0.02368404157459736, 0.04582594707608223,
  0.0772562026977539, 0.08818808197975159, 0.07998407632112503,
  0.05938826873898506, 0.035534169524908066, 0.03357500955462456,
  0.060768913477659225, 0.0915759801864624, 0.10351946949958801,
  0.092677041888237, 0.07122813165187836, 0.062365155667066574,
  0.0702538713812828, 0.07677556574344635, 0.07726138085126877,
  0.07893569022417068, 0.07888520509004593, 0.07084023207426071,
  0.05288538336753845, 0.03682313486933708, 0.0517570860683918,
  0.09377163648605347, 0.10129707306623459, 0.04185280576348305,
  -0.02602159045636654, -0.03180106356739998, 0.009322172030806541,
  0.026937762275338173, 0.006086290348321199, -0.0009567275992594659,
  0.01918492279946804, 0.021593637764453888, -0.0031910487450659275,
  -0.022240400314331055, -0.02256527543067932, -0.007418313529342413,
  0.010012435726821423, 0.0029242881573736668, -0.02246251329779625,
  -0.03841360658407211, -0.05558554828166962, -0.08015001565217972,
  -0.0828375369310379, -0.07022333145141602, -0.07530079782009125,
  -0.09211909770965576, -0.10984224826097488, -0.13150601089000702,
  -0.13942554593086243, -0.13020361959934235, -0.12936735153198242,
  -0.1347556710243225, -0.1331280916929245, -0.1383541226387024,
  -0.151847705245018, -0.1605042666196823, -0.17361386120319366,
  -0.19273993372917175, -0.19365327060222626, -0.17262716591358185,
  -0.15879260003566742, -0.173461452126503, -0.20217157900333405,
  -0.2188691943883896, -0.22944781184196472, -0.250006765127182,
  -0.26329898834228516, -0.25299137830734253, -0.23066775500774384,
  -0.2087477743625641, -0.21185363829135895, -0.2618120610713959,
  -0.31196993589401245, -0.30639320611953735, -0.2745114862918854,
  -0.260381281375885, -0.26081568002700806, -0.2772199809551239,
  -0.30300045013427734, -0.3048158586025238, -0.2929806411266327,
  -0.30202892422676086, -0.31136271357536316, -0.2990081310272217,
  -0.29701662063598633, -0.3214426338672638, -0.3402532637119293,
  -0.33619487285614014, -0.3323880434036255, -0.34436309337615967,
  -0.35050836205482483, -0.3394918441772461, -0.3373247981071472,
  -0.34486138820648193, -0.33425018191337585, -0.3257603049278259,
  -0.35117506980895996, -0.3785245418548584, -0.37529224157333374,
  -0.35917699337005615, -0.34733131527900696, -0.3452627956867218,
  -0.35714009404182434, -0.3680947721004486, -0.3678733706474304,
  -0.36148691177368164, -0.3454457223415375, -0.3350313901901245,
  -0.35436901450157166, -0.3771734833717346, -0.3788403570652008,
  -0.3845670521259308, -0.3933921754360199, -0.3821650445461273,
  -0.3743131160736084, -0.383949875831604, -0.38348087668418884,
  -0.37146881222724915, -0.3631362020969391, -0.35925987362861633,
  -0.37003594636917114, -0.3845098614692688, -0.3720754384994507,
  -0.3581595718860626, -0.3749662935733795, -0.38689789175987244,
  -0.3790620267391205, -0.3871450126171112, -0.39844197034835815,
  -0.37634003162384033, -0.34431833028793335, -0.33906856179237366,
  -0.3532673418521881, -0.36047640442848206, -0.3462384045124054,
  -0.32140061259269714, -0.30749040842056274, -0.3054424226284027,
  -0.30064648389816284, -0.27614864706993103, -0.22295153141021729,
  -0.1735340654850006, -0.17224830389022827, -0.19794785976409912,
  -0.2011885941028595, -0.18424805998802185, -0.16913117468357086,
  -0.16100995242595673, -0.17787322402000427, -0.2344910055398941,
  -0.29762160778045654, -0.30893072485923767, -0.2462548315525055,
  -0.14953508973121643, -0.07788081467151642, -0.04614481329917908,
  -0.031026046723127365, -0.010741095058619976, 0.03145582601428032,
  0.09491074085235596, 0.1474047303199768, 0.1618492156267166,
  0.13515324890613556, 0.07201467454433441, 0.0011048595188185573,
  -0.03137930855154991, -0.01631256937980652, 0.01957789808511734,
  0.05312275141477585, 0.06909187883138657, 0.07158327102661133,
  0.08899383246898651, 0.13174957036972046, 0.1836378574371338,
  0.2367294728755951, 0.28192389011383057, 0.29700011014938354,
  0.28544554114341736, 0.2824528217315674, 0.30310678482055664,
  0.32713285088539124, 0.34141889214515686, 0.35630959272384644,
  0.3715936243534088, 0.3712327182292938, 0.3570011556148529,
  0.33989283442497253, 0.31690266728401184, 0.299312561750412,
  0.3101694583892822, 0.3339307904243469, 0.338411420583725,
  0.32704484462738037, 0.3186827600002289, 0.32808351516723633,
  0.3702085614204407, 0.43026214838027954, 0.4713480472564697,
  0.48948073387145996, 0.5010619163513184, 0.5044359564781189,
  0.49551132321357727, 0.47717660665512085, 0.4528616666793823,
  0.43386828899383545, 0.42426595091819763, 0.4131605625152588,
  0.3999527096748352, 0.38655388355255127, 0.3658738136291504,
  0.35016801953315735, 0.35590770840644836, 0.3706159293651581,
  0.38925865292549133, 0.4238586723804474, 0.452907532453537,
  0.4430316686630249, 0.4082243740558624, 0.38654854893684387,
  0.39131560921669006, 0.40754884481430054, 0.41072219610214233,
  0.39058956503868103, 0.3577629029750824, 0.32653552293777466,
  0.30727145075798035, 0.3038986325263977, 0.311078280210495,
  0.32536637783050537, 0.339904248714447, 0.33632558584213257,
  0.31623879075050354, 0.30438241362571716, 0.3037315607070923,
  0.29971352219581604, 0.28881680965423584, 0.26822665333747864,
  0.24164092540740967, 0.22470207512378693, 0.2119295299053192,
  0.19173799455165863, 0.1833418905735016, 0.19723914563655853,
  0.21373483538627625, 0.22419863939285278, 0.2246057391166687,
  0.20252424478530884, 0.17349743843078613, 0.16363748908042908,
  0.16532859206199646, 0.16476179659366608, 0.16156569123268127,
  0.1489352583885193, 0.12808555364608765, 0.11351870745420456,
  0.10713383555412292, 0.10720809549093246, 0.11445573717355728,
  0.11235874891281128, 0.08813448250293732, 0.05463807284832001,
  0.02665630169212818, 0.012323773466050625, 0.012972310185432434,
  0.010754605755209923, -0.002507955301553011, -0.003571429755538702,
  0.01819191686809063, 0.044597215950489044, 0.0637330636382103,
  0.07241353392601013, 0.0673111230134964, 0.05555804446339607,
  0.043978478759527206, 0.030209099873900414, 0.015362303704023361,
  -0.003965279553085566, -0.03701314330101013, -0.07241155952215195,
  -0.08778510987758636, -0.08202727884054184, -0.06673283129930496,
  -0.049831077456474304, -0.03908992558717728, -0.03600813448429108,
  -0.0332053042948246, -0.026867179200053215, -0.016136109828948975,
  -0.0004742806777358055, 0.010751915164291859, 0.006805488374084234,
  -0.005468141753226519, -0.0119193559512496, -0.010759574361145496,
  -0.0058584497310221195, 0.003176921745762229, 0.010925179347395897,
  0.005740299355238676, -0.011288260109722614, -0.027498681098222733,
  -0.03727385029196739, -0.04021155834197998, -0.03542656823992729,
  -0.02655419521033764, -0.012753219343721867, 0.012095045298337936,
  0.0388377346098423, 0.05533888563513756, 0.07194069772958755,
  0.0973265990614891, 0.12035961449146271, 0.13607412576675415,
  0.1442098170518875, 0.1337382048368454, 0.10925864428281784,
  0.09357865154743195, 0.0879305899143219, 0.07763677090406418,
  0.06593000143766403, 0.0658455416560173, 0.07911872863769531,
  0.09779559075832367, 0.1143624559044838, 0.1272537112236023,
  0.13430501520633698, 0.13293588161468506, 0.13539166748523712,
  0.1537581980228424, 0.17277435958385468, 0.17654938995838165,
  0.17296718060970306, 0.1644318550825119, 0.14855049550533295,
  0.1445615291595459, 0.1594700664281845, 0.1648223102092743,
  0.1465248316526413, 0.12208004295825958, 0.10286259651184082,
  0.08952698856592178, 0.08471120148897171, 0.08458112180233002,
  0.08451547473669052, 0.08791614323854446, 0.09136395901441574,
  0.08782447129487991, 0.08473194390535355, 0.09002228081226349,
  0.09698925167322159, 0.09848497062921524, 0.09081868082284927,
  0.06813868135213852, 0.03659607842564583, 0.011331724934279919,
  -0.008734461851418018, -0.02671930007636547, -0.027067800983786583,
  -0.011501067318022251, -0.011847891844809055, -0.03485759347677231,
  -0.05527786538004875, -0.06735603511333466, -0.07792183756828308,
  -0.0772259458899498, -0.06838946789503098, -0.07716285437345505,
  -0.1096576601266861, -0.14683212339878082, -0.17180925607681274,
  -0.1756560057401657, -0.16147196292877197, -0.14704276621341705,
  -0.14817194640636444, -0.1662033051252365, -0.1880139708518982,
  -0.19864632189273834, -0.20404216647148132, -0.21721187233924866,
  -0.22854512929916382, -0.23215393722057343, -0.24346092343330383,
  -0.2571115791797638, -0.25414109230041504, -0.24948501586914062,
  -0.26202914118766785, -0.27973073720932007, -0.292423814535141,
  -0.2981613874435425, -0.29224181175231934, -0.29420003294944763,
  -0.32539886236190796, -0.3599912226200104, -0.3692544102668762,
  -0.365317165851593, -0.35545045137405396, -0.3314085602760315,
  -0.31021982431411743, -0.3141660690307617, -0.3330760598182678,
  -0.34796810150146484, -0.3578360080718994, -0.36598706245422363,
  -0.3674637973308563, -0.36377766728401184, -0.3750213086605072,
  -0.4116528034210205, -0.44595205783843994, -0.4469090700149536,
  -0.422380656003952, -0.39837291836738586, -0.39091214537620544,
  -0.4065455496311188, -0.4334182143211365, -0.4440281391143799,
  -0.4293420910835266, -0.40993061661720276, -0.40990859270095825,
  -0.43018287420272827, -0.4443473517894745, -0.43332886695861816,
  -0.4183312654495239, -0.4268854856491089, -0.447388619184494,
  -0.4537787139415741, -0.44624748826026917, -0.4416230320930481,
  -0.4482171833515167, -0.46397271752357483, -0.4843709468841553,
  -0.49822843074798584, -0.48618000745773315, -0.45111775398254395,
  -0.43163707852363586, -0.4435523748397827, -0.4513113796710968,
  -0.4390851855278015, -0.4363669455051422, -0.44684475660324097,
  -0.44545960426330566, -0.44356581568717957, -0.46144503355026245,
  -0.47029247879981995, -0.4457148313522339, -0.4232926070690155,
  -0.43383121490478516, -0.44812753796577454, -0.43543657660484314,
  -0.41017383337020874, -0.3892078697681427, -0.3672483563423157,
  -0.3432813882827759, -0.3147298991680145, -0.26744556427001953,
  -0.21701163053512573, -0.20684482157230377, -0.23256734013557434,
  -0.23860156536102295, -0.20477008819580078, -0.16797074675559998,
  -0.16089990735054016, -0.18926239013671875, -0.25204822421073914,
  -0.32629913091659546, -0.3539985716342926, -0.3030571937561035,
  -0.2156735062599182, -0.14446213841438293, -0.0900777205824852,
  -0.04166562110185623, -0.001418374595232308, 0.04824863374233246,
  0.11848288029432297, 0.17676758766174316, 0.19706758856773376,
  0.18415333330631256, 0.13531848788261414, 0.06274814158678055,
  0.01576486974954605, 0.013356332667171955, 0.025883903726935387,
  0.03996270149946213, 0.06209871917963028, 0.08581887185573578,
  0.11201362311840057, 0.15519970655441284, 0.2153167873620987,
  0.27781492471694946, 0.32451683282852173, 0.3414398431777954,
  0.33961552381515503, 0.3456125855445862, 0.3663330376148224,
  0.39343175292015076, 0.42165014147758484, 0.44263139367103577,
  0.45055726170539856, 0.4512495994567871, 0.4446791112422943,
  0.4246828556060791, 0.40078824758529663, 0.38744211196899414,
  0.3813127279281616, 0.3739122152328491, 0.36902549862861633,
  0.3725660443305969, 0.38340458273887634, 0.40447455644607544,
  0.44417905807495117, 0.49762386083602905, 0.5458438992500305,
  0.580678403377533, 0.6070277690887451, 0.618816077709198, 0.6014952063560486,
  0.5589264631271362, 0.5143881440162659, 0.4865557849407196,
  0.4767805337905884, 0.4740098714828491, 0.4642522633075714,
  0.44039446115493774, 0.41079410910606384, 0.39603692293167114,
  0.4092094600200653, 0.4424179196357727, 0.476317822933197,
  0.49487221240997314, 0.49277612566947937, 0.4777023494243622,
  0.4604649245738983, 0.4461233615875244, 0.4405301511287689,
  0.44401538372039795, 0.4377531409263611, 0.40856871008872986,
  0.37420758605003357, 0.3520011305809021, 0.3340179920196533,
  0.317380428314209, 0.31613609194755554, 0.32950371503829956,
  0.33796778321266174, 0.33239296078681946, 0.31861239671707153,
  0.3020585775375366, 0.28760826587677, 0.2802911102771759, 0.2729456126689911,
  0.25197842717170715, 0.22080224752426147, 0.1932099461555481,
  0.17049942910671234, 0.15268857777118683, 0.15045921504497528,
  0.1633232831954956, 0.17437167465686798, 0.17266806960105896,
  0.15629030764102936, 0.12899258732795715, 0.10613548010587692,
  0.10053832828998566, 0.10703220218420029, 0.11243076622486115,
  0.10366231203079224, 0.07627476006746292, 0.04921114444732666,
  0.045092083513736725, 0.05960754677653313, 0.0741315558552742,
  0.07426836341619492, 0.05094687268137932, 0.011748473159968853,
  -0.023352496325969696, -0.047268714755773544, -0.06413953006267548,
  -0.07846718281507492, -0.0951339602470398, -0.10369215160608292,
  -0.08489066362380981, -0.048219818621873856, -0.017320184037089348,
  0.005635417066514492, 0.018831545487046242, 0.015590525232255459,
  0.01136067695915699, 0.014392029494047165, 0.001623123767785728,
  -0.034763872623443604, -0.07670533657073975, -0.11710888892412186,
  -0.15065106749534607, -0.16274067759513855, -0.1538078337907791,
  -0.13375669717788696, -0.10946277529001236, -0.09655042737722397,
  -0.10012185573577881, -0.09889426082372665, -0.08400024473667145,
  -0.06979324668645859, -0.0587877482175827, -0.04512898623943329,
  -0.03338966891169548, -0.02527523785829544, -0.01846989430487156,
  -0.01618357002735138, -0.014284081757068634, -0.002411162480711937,
  0.014599751681089401, 0.020639585331082344, 0.009344554506242275,
  -0.011587301269173622, -0.03215867653489113, -0.050067998468875885,
  -0.061573173850774765, -0.05356951430439949, -0.025420501828193665,
  0.004243931267410517, 0.029125532135367393, 0.05970540642738342,
  0.09872955083847046, 0.1434229463338852, 0.18574273586273193,
  0.20292839407920837, 0.1907735913991928, 0.1818762719631195,
  0.19141100347042084, 0.19637414813041687, 0.18498608469963074,
  0.16634494066238403, 0.14354528486728668, 0.12403249740600586,
  0.12514108419418335, 0.1508527398109436, 0.1899644434452057,
  0.2239362895488739, 0.23021431267261505, 0.2104141265153885,
  0.19907493889331818, 0.21753725409507751, 0.25146248936653137,
  0.2800155282020569, 0.2920016944408417, 0.28513067960739136,
  0.27110013365745544, 0.2653404474258423, 0.27036845684051514,
  0.27639660239219666, 0.26792314648628235, 0.23903945088386536,
  0.20660169422626495, 0.18605129420757294, 0.1709607094526291,
  0.15875136852264404, 0.15869998931884766, 0.16558554768562317,
  0.1684092879295349, 0.1716930866241455, 0.17606133222579956,
  0.17136862874031067, 0.16170558333396912, 0.15736503899097443,
  0.15024812519550323, 0.12864689528942108, 0.09619347006082535,
  0.06165266036987305, 0.03139611333608627, 0.013629894703626633,
  0.012852865271270275, 0.021935511380434036, 0.026498543098568916,
  0.01606886088848114, -0.007528793066740036, -0.03149140253663063,
  -0.04586734622716904, -0.05525800585746765, -0.07058553397655487,
  -0.09436212480068207, -0.12322652339935303, -0.15335234999656677,
  -0.17563168704509735, -0.1833004206418991, -0.18644732236862183,
  -0.19788900017738342, -0.21343128383159637, -0.22309333086013794,
  -0.22549498081207275, -0.22541525959968567, -0.2297438085079193,
  -0.2400701642036438, -0.2525046765804291, -0.2696904242038727,
  -0.28957390785217285, -0.29338353872299194, -0.28228560090065,
  -0.2872454822063446, -0.3122008144855499, -0.3342233896255493,
  -0.36116304993629456, -0.4062036871910095, -0.4377026855945587,
  -0.4309988021850586, -0.4159863591194153, -0.4230370819568634,
  -0.4394803047180176, -0.44200626015663147, -0.4269464910030365,
  -0.40394043922424316, -0.38517558574676514, -0.38043013215065,
  -0.3902164697647095, -0.4054976999759674, -0.42359766364097595,
  -0.4522898495197296, -0.4817315936088562, -0.4868259131908417,
  -0.4805642366409302, -0.5031739473342896, -0.5387904644012451,
  -0.5324733853340149, -0.495134174823761, -0.4850770831108093,
  -0.5006645917892456, -0.501356303691864, -0.4983516037464142,
  -0.5183382034301758, -0.5326722264289856, -0.5163306593894958,
  -0.5036278367042542, -0.5142551064491272, -0.5168237686157227,
  -0.5077810287475586, -0.5193416476249695, -0.5416338443756104,
  -0.5408831238746643, -0.5275986790657043, -0.5263032913208008,
  -0.5299015045166016, -0.5298453569412231, -0.5349517464637756,
  -0.5461806058883667, -0.5505897402763367, -0.5372143983840942,
  -0.5123962759971619, -0.5004286170005798, -0.5085402727127075,
  -0.5074132084846497, -0.48036107420921326, -0.45732423663139343,
  -0.4659298062324524, -0.48981112241744995, -0.4998262822628021,
  -0.489515095949173, -0.4662184417247772, -0.4369122385978699,
  -0.4097807705402374, -0.38174375891685486, -0.33125975728034973,
  -0.2636966109275818, -0.2311040461063385, -0.24673663079738617,
  -0.24754005670547485, -0.20897477865219116, -0.18848326802253723,
  -0.2033466100692749, -0.2143435925245285, -0.2410493642091751,
  -0.3192969560623169, -0.3807962238788605, -0.3461107313632965,
  -0.2592734694480896, -0.1939937323331833, -0.14039866626262665,
  -0.07579787075519562, -0.020961707457900047, 0.0223074983805418,
  0.08091170340776443, 0.1516747623682022, 0.20613904297351837,
  0.22906580567359924, 0.21492622792720795, 0.16788387298583984,
  0.11450803279876709, 0.08087997138500214, 0.06911934167146683,
  0.07287898659706116, 0.09005682170391083, 0.115375816822052,
  0.14123639464378357, 0.16682030260562897, 0.20201797783374786,
  0.25900596380233765, 0.3264564871788025, 0.37029802799224854,
  0.38448724150657654, 0.4054339826107025, 0.4497024714946747,
  0.4884488582611084, 0.5043445825576782, 0.5164691805839539,
  0.5351295471191406, 0.544328510761261, 0.5350480079650879, 0.5187562704086304,
  0.5072674751281738, 0.4985499382019043, 0.4810235798358917,
  0.4527851641178131, 0.4345703721046448, 0.4422808885574341,
  0.4577144682407379, 0.4623112976551056, 0.4746204912662506,
  0.5140408873558044, 0.5653544068336487, 0.609986424446106, 0.646892249584198,
  0.6703956723213196, 0.6715905070304871, 0.6557943820953369,
  0.6301470398902893, 0.5967069864273071, 0.5689177513122559, 0.559101939201355,
  0.5490922927856445, 0.5129144787788391, 0.4628680646419525,
  0.43276605010032654, 0.42820701003074646, 0.43695512413978577,
  0.4623170793056488, 0.49770793318748474, 0.5100803375244141,
  0.4907993972301483, 0.4673014283180237, 0.4505055546760559,
  0.43549367785453796, 0.4299331605434418, 0.4265846908092499,
  0.40427106618881226, 0.37404903769493103, 0.35465022921562195,
  0.3337830603122711, 0.3107289969921112, 0.3060784935951233,
  0.3101331889629364, 0.3033692538738251, 0.296933114528656,
  0.29136791825294495, 0.2663564383983612, 0.2323557734489441,
  0.20972447097301483, 0.18385225534439087, 0.1483316272497177,
  0.12704838812351227, 0.11829791963100433, 0.09719755500555038,
  0.07262257486581802, 0.06792142242193222, 0.07306455820798874,
  0.0699201375246048, 0.06428905576467514, 0.06176573038101196,
  0.05177874490618706, 0.03030550479888916, 0.00889969989657402,
  -0.0025632039178162813, -0.00693648774176836, -0.015190678648650646,
  -0.034308429807424545, -0.05637671798467636, -0.06456637382507324,
  -0.056038420647382736, -0.04916958510875702, -0.05894599109888077,
  -0.08001523464918137, -0.104197196662426, -0.13275755941867828,
  -0.16166934370994568, -0.18175823986530304, -0.19535990059375763,
  -0.20617198944091797, -0.20660075545310974, -0.1954093724489212,
  -0.17983925342559814, -0.15754954516887665, -0.1293533444404602,
  -0.1099017858505249, -0.10156294703483582, -0.0931776612997055,
  -0.08767501264810562, -0.0956513062119484, -0.1174236312508583,
  -0.14867110550403595, -0.18117624521255493, -0.20418629050254822,
  -0.2156946361064911, -0.21469581127166748, -0.20024201273918152,
  -0.19004423916339874, -0.19787289202213287, -0.20393219590187073,
  -0.19068683683872223, -0.16831359267234802, -0.14384828507900238,
  -0.11886660754680634, -0.10563740879297256, -0.10284549742937088,
  -0.09133244305849075, -0.06545877456665039, -0.030852172523736954,
  0.006168897729367018, 0.02756018564105034, 0.021415140479803085,
  0.005912880413234234, 0.00481035653501749, 0.017461314797401428,
  0.022348174825310707, 0.0016860051546245813, -0.031328245997428894,
  -0.04576636105775833, -0.03440232574939728, -0.004510709084570408,
  0.04656153544783592, 0.10268519073724747, 0.12910103797912598,
  0.13092613220214844, 0.14743149280548096, 0.1906379908323288,
  0.24278093874454498, 0.28477251529693604, 0.30073949694633484,
  0.290295273065567, 0.27366742491722107, 0.2659389078617096,
  0.2693575918674469, 0.2851671874523163, 0.3050130307674408,
  0.3102578818798065, 0.29587236046791077, 0.274026483297348,
  0.2568485736846924, 0.25060227513313293, 0.2565666437149048,
  0.27304908633232117, 0.29960817098617554, 0.3299117386341095,
  0.3479657471179962, 0.34929952025413513, 0.3507291376590729,
  0.36548393964767456, 0.3870280086994171, 0.4007895290851593,
  0.3960151672363281, 0.3713930547237396, 0.3368629217147827,
  0.3018401563167572, 0.26982277631759644, 0.24954846501350403,
  0.24617138504981995, 0.24237993359565735, 0.22023271024227142,
  0.1903928816318512, 0.1722131222486496, 0.1677626222372055,
  0.1698206216096878, 0.1681550294160843, 0.15126127004623413,
  0.12355213612318039, 0.10280992090702057, 0.09513448923826218,
  0.09573003649711609, 0.10086362808942795, 0.10119734704494476,
  0.08643005788326263, 0.06117526814341545, 0.03423402085900307,
  0.005205829627811909, -0.026050716638565063, -0.058211226016283035,
  -0.0941765084862709, -0.13045381009578705, -0.1577162742614746,
  -0.17794877290725708, -0.19807840883731842, -0.21432511508464813,
  -0.22424818575382233, -0.2334095537662506, -0.2379922717809677,
  -0.23042595386505127, -0.22436513006687164, -0.2398831993341446,
  -0.27001509070396423, -0.2934452295303345, -0.3059922456741333,
  -0.3143267035484314, -0.31856340169906616, -0.31916117668151855,
  -0.32427528500556946, -0.3410738408565521, -0.3692867159843445,
  -0.40223217010498047, -0.4306260347366333, -0.4487144649028778,
  -0.4588790237903595, -0.47116604447364807, -0.49259892106056213,
  -0.5135021805763245, -0.5194445252418518, -0.5153378248214722,
  -0.5118733048439026, -0.50115567445755, -0.478762686252594,
  -0.46329399943351746, -0.4673336148262024, -0.48168694972991943,
  -0.49741843342781067, -0.513295590877533, -0.5290669202804565,
  -0.5447067022323608, -0.5540849566459656, -0.5541468262672424,
  -0.563755214214325, -0.5935105085372925, -0.6122158169746399,
  -0.5965180397033691, -0.5767026543617249, -0.5832750797271729,
  -0.6007637977600098, -0.6082867980003357, -0.6081883311271667,
  -0.6019912958145142, -0.5899487137794495, -0.5853794813156128,
  -0.589309811592102, -0.580188512802124, -0.5564935207366943,
  -0.545036256313324, -0.5513129234313965, -0.5550340414047241,
  -0.553739070892334, -0.5549196004867554, -0.5478687286376953,
  -0.5356156229972839, -0.54804527759552, -0.5807086229324341,
  -0.5860846638679504, -0.5527488589286804, -0.5219184160232544,
  -0.5164827704429626, -0.5203173756599426, -0.5199426412582397,
  -0.5129020810127258, -0.49340805411338806, -0.46551254391670227,
  -0.44530120491981506, -0.4267444610595703, -0.37890374660491943,
  -0.30226433277130127, -0.24725507199764252, -0.23966988921165466,
  -0.23521623015403748, -0.1967906355857849, -0.15884317457675934,
  -0.15763425827026367, -0.17211298644542694, -0.1908908486366272,
  -0.2420402318239212, -0.3064331114292145, -0.3143528997898102,
  -0.26355308294296265, -0.21859851479530334, -0.18411435186862946,
  -0.11099873483181, -0.011700637638568878, 0.06174864247441292,
  0.11587051302194595, 0.17961232364177704, 0.23574697971343994,
  0.2694118320941925, 0.2949911653995514, 0.2962781488895416,
  0.24869191646575928, 0.19377999007701874, 0.18232226371765137,
  0.1912519782781601, 0.1882173717021942, 0.1957937479019165,
  0.23066681623458862, 0.27005860209465027, 0.30267322063446045,
  0.33875572681427, 0.3786754012107849, 0.4149256646633148, 0.44573062658309937,
  0.4784995913505554, 0.5241513252258301, 0.572100818157196, 0.5956771373748779,
  0.6026632189750671, 0.6254265904426575, 0.6570383906364441,
  0.6667094230651855, 0.6551708579063416, 0.6356518864631653,
  0.6088730096817017, 0.585361659526825, 0.5745079517364502, 0.5603090524673462,
  0.5368374586105347, 0.5291569828987122, 0.5501852631568909,
  0.5826692581176758, 0.6110439896583557, 0.6350535750389099,
  0.6588010787963867, 0.6837636232376099, 0.7041456699371338,
  0.7092728614807129, 0.6943559646606445, 0.6642663478851318,
  0.6302540898323059, 0.6034085750579834, 0.5845155715942383,
  0.5648581385612488, 0.5400240421295166, 0.5154737830162048,
  0.49854299426078796, 0.4888595640659332, 0.48014140129089355,
  0.4758833646774292, 0.48701778054237366, 0.503113329410553,
  0.49818041920661926, 0.4721377193927765, 0.4446503818035126,
  0.4177619218826294, 0.38670721650123596, 0.35867854952812195,
  0.3349228799343109, 0.3105417490005493, 0.2877187430858612,
  0.2612971365451813, 0.22583307325839996, 0.20150737464427948,
  0.2050098329782486, 0.218545600771904, 0.22300425171852112,
  0.21650579571723938, 0.19748884439468384, 0.17357929050922394,
  0.15705132484436035, 0.1347416490316391, 0.09452182054519653,
  0.05951163172721863, 0.04208800941705704, 0.01732332445681095,
  -0.023144520819187164, -0.05564269796013832, -0.06915181130170822,
  -0.07403507828712463, -0.08054963499307632, -0.09459362179040909,
  -0.11459535360336304, -0.13043370842933655, -0.13614021241664886,
  -0.135679692029953, -0.13635574281215668, -0.13952520489692688,
  -0.1377929002046585, -0.12973818182945251, -0.12536530196666718,
  -0.12571898102760315, -0.1253073811531067, -0.13575832545757294,
  -0.16593189537525177, -0.19817014038562775, -0.22214271128177643,
  -0.25316673517227173, -0.2953086793422699, -0.3299359977245331,
  -0.34215331077575684, -0.3304961025714874, -0.30613118410110474,
  -0.2858833074569702, -0.26967400312423706, -0.24386410415172577,
  -0.21072913706302643, -0.18424931168556213, -0.16819605231285095,
  -0.16207288205623627, -0.16625119745731354, -0.17535631358623505,
  -0.18819302320480347, -0.2098844200372696, -0.23213830590248108,
  -0.2385568618774414, -0.23139744997024536, -0.22685368359088898,
  -0.22925543785095215, -0.22806669771671295, -0.2157542109489441,
  -0.19824989140033722, -0.18457652628421783, -0.1733378916978836,
  -0.16008377075195312, -0.14671000838279724, -0.12934701144695282,
  -0.09646493941545486, -0.050198718905448914, -0.007499757222831249,
  0.020601466298103333, 0.03263068571686745, 0.033591095358133316,
  0.03828532621264458, 0.05561569333076477, 0.0725373700261116,
  0.0736393854022026, 0.06074028089642525, 0.04209233447909355,
  0.025976398959755898, 0.0274360328912735, 0.0607130266726017,
  0.12188302725553513, 0.18287305533885956, 0.2098337858915329,
  0.20363342761993408, 0.20679792761802673, 0.2465500682592392,
  0.3000181019306183, 0.3338553011417389, 0.3370060920715332,
  0.3120322823524475, 0.281314879655838, 0.2867755889892578,
  0.33797913789749146, 0.38719552755355835, 0.38836535811424255,
  0.35273611545562744, 0.3284941017627716, 0.345657616853714,
  0.3883177936077118, 0.415010929107666, 0.4053143858909607, 0.3784908950328827,
  0.36445096135139465, 0.37318360805511475, 0.3946388363838196,
  0.4129456579685211, 0.4188595712184906, 0.4140733778476715,
  0.40389204025268555, 0.39208704233169556, 0.383487343788147,
  0.3767676055431366, 0.3590632677078247, 0.32831859588623047,
  0.30699169635772705, 0.3091352880001068, 0.31446823477745056,
  0.3002207279205322, 0.27419883012771606, 0.2544390559196472,
  0.2409438043832779, 0.22407874464988708, 0.1997545212507248,
  0.16735558211803436, 0.1289883702993393, 0.09296300262212753,
  0.06708670407533646, 0.05140915885567665, 0.04096851870417595,
  0.030440455302596092, 0.016676677390933037, 0.001966694602742791,
  -0.007978033274412155, -0.012498175725340843, -0.020282793790102005,
  -0.04382307827472687, -0.08598428964614868, -0.1322794407606125,
  -0.16628284752368927, -0.185760498046875, -0.19541272521018982,
  -0.20407690107822418, -0.23090948164463043, -0.27835381031036377,
  -0.31230220198631287, -0.31274303793907166, -0.3093342185020447,
  -0.3284609615802765, -0.3579838275909424, -0.3840295672416687,
  -0.4043910801410675, -0.4102906286716461, -0.4012412130832672,
  -0.39552876353263855, -0.40177229046821594, -0.4111228585243225,
  -0.4184596836566925, -0.424586683511734, -0.4318024814128876,
  -0.45137855410575867, -0.4929961562156677, -0.5412291288375854,
  -0.5679113268852234, -0.5713552236557007, -0.5740944147109985,
  -0.5833419561386108, -0.5891903042793274, -0.5908198356628418,
  -0.5868834853172302, -0.5694183111190796, -0.5530862212181091,
  -0.5596404075622559, -0.5728861093521118, -0.571015477180481,
  -0.5720195174217224, -0.592156171798706, -0.6118144989013672,
  -0.6145601868629456, -0.6100331544876099, -0.6145428419113159,
  -0.6325904130935669, -0.6462937593460083, -0.6359351873397827,
  -0.6161532998085022, -0.6123864650726318, -0.6204637289047241,
  -0.6319745182991028, -0.6515389084815979, -0.6628506779670715,
  -0.6406715512275696, -0.5998545289039612, -0.5782483220100403,
  -0.5857800841331482, -0.6015727519989014, -0.6015219688415527,
  -0.5817184448242188, -0.56460040807724, -0.569455087184906,
  -0.5799780488014221, -0.5688780546188354, -0.5455965399742126,
  -0.5420835614204407, -0.5593602657318115, -0.5675864219665527,
  -0.5497756004333496, -0.5141484141349792, -0.4789053797721863,
  -0.4607846140861511, -0.4590972661972046, -0.45514434576034546,
  -0.43304672837257385, -0.3773217499256134, -0.28351500630378723,
  -0.20669980347156525, -0.21095357835292816, -0.24360300600528717,
  -0.19804421067237854, -0.09940994530916214, -0.06207140162587166,
  -0.10434717684984207, -0.1627764254808426, -0.21086934208869934,
  -0.24128609895706177, -0.23036207258701324, -0.19171440601348877,
  -0.1670982539653778, -0.1463633030653, -0.08750078827142715,
  0.006217991467565298, 0.1039828360080719, 0.19707398116588593,
  0.2788744568824768, 0.33116865158081055, 0.3568049669265747,
  0.371747761964798, 0.3685814142227173, 0.33709079027175903,
  0.2975909113883972, 0.27490633726119995, 0.26572078466415405,
  0.2549608647823334, 0.25136274099349976, 0.2846516966819763,
  0.35379913449287415, 0.40953007340431213, 0.4258679449558258,
  0.4415193796157837, 0.48847419023513794, 0.5443385243415833,
  0.5853880047798157, 0.6164734363555908, 0.6408225297927856,
  0.6564769148826599, 0.6734727621078491, 0.7017043232917786,
  0.7329948544502258, 0.747227132320404, 0.7333125472068787, 0.7008723020553589,
  0.6677820086479187, 0.6428364515304565, 0.6233679056167603,
  0.5992134809494019, 0.5683650970458984, 0.5514487028121948, 0.568004310131073,
  0.607714056968689, 0.6478606462478638, 0.6783868074417114, 0.6998761296272278,
  0.7178633213043213, 0.7328241467475891, 0.733769953250885, 0.7169690132141113,
  0.68878173828125, 0.6465752124786377, 0.5943410396575928, 0.5541026592254639,
  0.5291385054588318, 0.5027380585670471, 0.47886744141578674,
  0.4676273465156555, 0.4567583501338959, 0.44145190715789795,
  0.43660929799079895, 0.4436126947402954, 0.44893983006477356,
  0.44461339712142944, 0.42506542801856995, 0.3914463222026825,
  0.35990971326828003, 0.33898255228996277, 0.31572744250297546,
  0.2796415388584137, 0.23649778962135315, 0.19548223912715912,
  0.1632121354341507, 0.14460641145706177, 0.13482250273227692,
  0.12298461049795151, 0.11151056736707687, 0.11139801144599915,
  0.11542781442403793, 0.10678019374608994, 0.09009615331888199,
  0.07684757560491562, 0.056353118270635605, 0.019613806158304214,
  -0.016866689547896385, -0.04187716543674469, -0.06740643829107285,
  -0.09911194443702698, -0.1262296587228775, -0.14481915533542633,
  -0.16032974421977997, -0.17342828214168549, -0.18305720388889313,
  -0.19301271438598633, -0.2051481157541275, -0.21630962193012238,
  -0.22496183216571808, -0.23123446106910706, -0.2308446764945984,
  -0.2188423126935959, -0.19926679134368896, -0.1832086145877838,
  -0.17593985795974731, -0.17428694665431976, -0.17680421471595764,
  -0.18558377027511597, -0.20103894174098969, -0.22668102383613586,
  -0.26798921823501587, -0.31594642996788025, -0.35077744722366333,
  -0.36674824357032776, -0.37061241269111633, -0.36288318037986755,
  -0.34142637252807617, -0.3081762194633484, -0.26481959223747253,
  -0.2192290872335434, -0.18442276120185852, -0.16006498038768768,
  -0.1382402777671814, -0.12340909242630005, -0.12379438430070877,
  -0.13909055292606354, -0.1638006567955017, -0.18368396162986755,
  -0.1854461282491684, -0.1807618886232376, -0.1879068911075592,
  -0.19537705183029175, -0.18131248652935028, -0.1506292223930359,
  -0.12458460032939911, -0.11325971782207489, -0.10967282205820084,
  -0.0997920110821724, -0.07692387700080872, -0.04511310160160065,
  -0.011059176176786423, 0.017352961003780365, 0.036135170608758926,
  0.056762538850307465, 0.09350224584341049, 0.13868296146392822,
  0.167755126953125, 0.16434894502162933, 0.13839039206504822,
  0.12437603622674942, 0.1453869491815567, 0.1783963292837143,
  0.18502821028232574, 0.16510075330734253, 0.1469280868768692,
  0.15015923976898193, 0.186539426445961, 0.25694403052330017,
  0.3232348561286926, 0.33378177881240845, 0.298928827047348,
  0.2860267162322998, 0.3261960446834564, 0.3817746341228485,
  0.40891242027282715, 0.4004157483577728, 0.37609630823135376,
  0.3676880896091461, 0.3940199017524719, 0.4364491105079651,
  0.46233975887298584, 0.46312975883483887, 0.446089506149292,
  0.41970500349998474, 0.40555354952812195, 0.4207649528980255,
  0.43992504477500916, 0.42443129420280457, 0.38606324791908264,
  0.36793920397758484, 0.3776065707206726, 0.39102044701576233,
  0.3957478106021881, 0.3897072672843933, 0.3724607229232788,
  0.35638123750686646, 0.352247953414917, 0.3503674566745758,
  0.33846840262413025, 0.31532198190689087, 0.2855968177318573,
  0.26410096883773804, 0.2645643353462219, 0.27301540970802307,
  0.2602373957633972, 0.21733690798282623, 0.16258113086223602,
  0.12259890884160995, 0.10381285846233368, 0.08239720016717911,
  0.040536992251873016, -0.008058716543018818, -0.04485625401139259,
  -0.06426351517438889, -0.06533607095479965, -0.057195041328668594,
  -0.05584721267223358, -0.06640931218862534, -0.08721034228801727,
  -0.11260747909545898, -0.12678804993629456, -0.1277334839105606,
  -0.14170591533184052, -0.1863539069890976, -0.24573703110218048,
  -0.2893591821193695, -0.3016193211078644, -0.3009624779224396,
  -0.318945974111557, -0.3540995419025421, -0.3819675147533417,
  -0.3992050886154175, -0.41473907232284546, -0.4254932105541229,
  -0.4302293360233307, -0.43694832921028137, -0.4499971866607666,
  -0.46324846148490906, -0.46353209018707275, -0.4502057135105133,
  -0.4432237446308136, -0.4521508812904358, -0.4673057496547699,
  -0.48687052726745605, -0.5081696510314941, -0.5182892084121704,
  -0.5294275283813477, -0.565903902053833, -0.609100878238678,
  -0.6227920055389404, -0.6102169752120972, -0.5951446294784546,
  -0.5872374176979065, -0.5885621309280396, -0.5960787534713745,
  -0.5953462719917297, -0.5773950219154358, -0.5598792433738708,
  -0.5703550577163696, -0.6046807765960693, -0.6320454478263855,
  -0.6440016627311707, -0.6494181752204895, -0.6389853954315186,
  -0.6162638664245605, -0.6169896721839905, -0.6463625431060791,
  -0.6646660566329956, -0.6521316766738892, -0.6220260858535767,
  -0.5925850868225098, -0.5882156491279602, -0.6209845542907715,
  -0.658588707447052, -0.6565770506858826, -0.6163357496261597,
  -0.5814226269721985, -0.5801991820335388, -0.5938841104507446,
  -0.5991394519805908, -0.605329155921936, -0.6109928488731384,
  -0.589693009853363, -0.5559173822402954, -0.5547440052032471,
  -0.5777620673179626, -0.585179328918457, -0.5769492983818054,
  -0.5648499727249146, -0.5380553007125854, -0.4986483156681061,
  -0.46761298179626465, -0.4480893611907959, -0.4216068983078003,
  -0.3712965250015259, -0.30165722966194153, -0.24402299523353577,
  -0.22430665791034698, -0.22310853004455566, -0.19357630610466003,
  -0.12371636927127838, -0.06371108442544937, -0.07309184968471527,
  -0.14286723732948303, -0.20198185741901398, -0.20420338213443756,
  -0.16925080120563507, -0.13849234580993652, -0.12838543951511383,
  -0.1286531537771225, -0.11611990630626678, -0.064116470515728,
  0.040904272347688675, 0.17422866821289062, 0.28175416588783264,
  0.3365294635295868, 0.36252784729003906, 0.3882278501987457,
  0.41392070055007935, 0.4270658493041992, 0.4162795841693878,
  0.3821711838245392, 0.3459782898426056, 0.3226466178894043,
  0.3066060543060303, 0.3043306767940521, 0.33246564865112305,
  0.3800804913043976, 0.4246399998664856, 0.46455124020576477,
  0.5035597085952759, 0.5401859283447266, 0.5773220658302307,
  0.6120954751968384, 0.6399459838867188, 0.6707122921943665,
  0.7062572836875916, 0.730632483959198, 0.743450403213501, 0.7543987035751343,
  0.7527240514755249, 0.7311533093452454, 0.7063062787055969,
  0.6862714886665344, 0.6608670353889465, 0.6262952089309692,
  0.5876880288124084, 0.5553058981895447, 0.5449873805046082,
  0.5592379570007324, 0.5809433460235596, 0.5997447371482849,
  0.6229123473167419, 0.6545474529266357, 0.6799917817115784,
  0.6812694072723389, 0.6642821431159973, 0.6476851105690002,
  0.6279579401016235, 0.5916334986686707, 0.5465524792671204,
  0.5045710206031799, 0.46724191308021545, 0.4428909718990326,
  0.4310946464538574, 0.4109385311603546, 0.38423487544059753,
  0.3764083981513977, 0.38182851672172546, 0.37520748376846313,
  0.35748687386512756, 0.33737316727638245, 0.31200286746025085,
  0.28619492053985596, 0.26468655467033386, 0.23883186280727386,
  0.2082834243774414, 0.18118007481098175, 0.15070609748363495,
  0.11046963185071945, 0.07604454457759857, 0.06029042229056358,
  0.053791292011737823, 0.04768829792737961, 0.04451194033026695,
  0.03999271243810654, 0.026608314365148544, 0.013810830190777779,
  0.012975062243640423, 0.01397843100130558, 0.002820015186443925,
  -0.02029147930443287, -0.051983535289764404, -0.08629605174064636,
  -0.11242730170488358, -0.13247829675674438, -0.15729886293411255,
  -0.18329060077667236, -0.20237471163272858, -0.21626342833042145,
  -0.22500073909759521, -0.228363037109375, -0.23225298523902893,
  -0.2356235384941101, -0.23280273377895355, -0.22569426894187927,
  -0.21323226392269135, -0.19026482105255127, -0.16309979557991028,
  -0.14198468625545502, -0.12932679057121277, -0.12418695539236069,
  -0.12261325865983963, -0.12461583316326141, -0.14213337004184723,
  -0.17900550365447998, -0.22156256437301636, -0.25794464349746704,
  -0.27917104959487915, -0.2785969376564026, -0.26932287216186523,
  -0.26542654633522034, -0.2535232901573181, -0.21905644237995148,
  -0.16833467781543732, -0.11162231862545013, -0.06275681406259537,
  -0.03693521395325661, -0.027258340269327164, -0.01870935596525669,
  -0.015432298183441162, -0.021582666784524918, -0.028637846931815147,
  -0.03456805646419525, -0.04123926907777786, -0.04380243271589279,
  -0.03991193324327469, -0.029762187972664833, -0.012439487501978874,
  0.007017416879534721, 0.023328809067606926, 0.038355402648448944,
  0.05224890261888504, 0.06513034552335739, 0.08425946533679962,
  0.11186345666646957, 0.13724969327449799, 0.15117612481117249,
  0.15894882380962372, 0.17555199563503265, 0.20595021545886993,
  0.23745469748973846, 0.26085782051086426, 0.27707022428512573,
  0.27815499901771545, 0.2608790993690491, 0.25031912326812744,
  0.2685846984386444, 0.3029509484767914, 0.331227570772171,
  0.34289225935935974, 0.3327908515930176, 0.3115990459918976,
  0.3077954351902008, 0.3347996771335602, 0.37566664814949036,
  0.4033513069152832, 0.39914318919181824, 0.3678364157676697,
  0.3433148264884949, 0.35373976826667786, 0.38551101088523865,
  0.409096896648407, 0.4199838936328888, 0.42570552229881287,
  0.42535626888275146, 0.42625635862350464, 0.4413943886756897,
  0.45961469411849976, 0.45886746048927307, 0.4410727918148041,
  0.4206339418888092, 0.4017307758331299, 0.3836176097393036,
  0.3616543412208557, 0.33006227016448975, 0.30161309242248535,
  0.2949931025505066, 0.2994314730167389, 0.29005664587020874,
  0.26687249541282654, 0.24776382744312286, 0.2413441389799118,
  0.2432704120874405, 0.2438654750585556, 0.2355036437511444,
  0.21767489612102509, 0.19500744342803955, 0.17399311065673828,
  0.159182608127594, 0.14774997532367706, 0.13216958940029144,
  0.1056007593870163, 0.06329772621393204, 0.011791273020207882,
  -0.029478538781404495, -0.05217314511537552, -0.07116676867008209,
  -0.09980478137731552, -0.1309858113527298, -0.14997436106204987,
  -0.15369699895381927, -0.15274320542812347, -0.1568889617919922,
  -0.16667716205120087, -0.18126419186592102, -0.19894304871559143,
  -0.21344172954559326, -0.22657768428325653, -0.24855603277683258,
  -0.27719202637672424, -0.30385181307792664, -0.3294741213321686,
  -0.3523825407028198, -0.3686840236186981, -0.3849535584449768,
  -0.3978961706161499, -0.39504384994506836, -0.39378881454467773,
  -0.41918420791625977, -0.45191895961761475, -0.46321842074394226,
  -0.4617474377155304, -0.46244075894355774, -0.46350234746932983,
  -0.4651064872741699, -0.4666972756385803, -0.46561920642852783,
  -0.4737553596496582, -0.4969693422317505, -0.511938750743866,
  -0.5047199726104736, -0.5016390085220337, -0.5252527594566345,
  -0.5538085699081421, -0.5616597533226013, -0.5667160153388977,
  -0.5866336226463318, -0.5903136730194092, -0.561774492263794,
  -0.5472639799118042, -0.5660436749458313, -0.57059246301651,
  -0.5500586032867432, -0.5539345741271973, -0.5903478860855103,
  -0.6159675121307373, -0.6158686876296997, -0.6113673448562622,
  -0.6153404712677002, -0.6224986910820007, -0.6269790530204773,
  -0.6310871839523315, -0.6324002146720886, -0.6215035319328308,
  -0.6052749156951904, -0.5946082472801208, -0.5803452730178833,
  -0.5724514126777649, -0.5955002903938293, -0.6123170852661133,
  -0.579042375087738, -0.5499377846717834, -0.5740830898284912,
  -0.5957282185554504, -0.5842725038528442, -0.5859127044677734,
  -0.5922389030456543, -0.5609189867973328, -0.5312753915786743,
  -0.5480234026908875, -0.5743930339813232, -0.5754024982452393,
  -0.5570343732833862, -0.514600932598114, -0.4537055492401123,
  -0.4074992537498474, -0.37317565083503723, -0.3222152888774872,
  -0.27929025888442993, -0.2833678424358368, -0.2935551702976227,
  -0.23929354548454285, -0.13537278771400452, -0.06148646026849747,
  -0.06119270250201225, -0.11967002600431442, -0.19045838713645935,
  -0.21041451394557953, -0.15647560358047485, -0.09416431933641434,
  -0.0948234349489212, -0.13656878471374512, -0.15795373916625977,
  -0.14064276218414307, -0.07821065187454224, 0.039318930357694626,
  0.1677912324666977, 0.2400800883769989, 0.2668110430240631,
  0.30862411856651306, 0.37073734402656555, 0.41491034626960754,
  0.43286311626434326, 0.43526577949523926, 0.4162271022796631,
  0.37285304069519043, 0.32245951890945435, 0.28714025020599365,
  0.28260913491249084, 0.3093147575855255, 0.347057044506073,
  0.37655648589134216, 0.4019192159175873, 0.4382005035877228,
  0.4827073812484741, 0.5124772787094116, 0.5216273069381714, 0.539864718914032,
  0.5829048752784729, 0.6235560774803162, 0.6448089480400085,
  0.6611137390136719, 0.6736720204353333, 0.6750858426094055,
  0.6769732236862183, 0.6770130395889282, 0.6516342163085938,
  0.6006609201431274, 0.5406569838523865, 0.48213982582092285,
  0.4483030438423157, 0.453830748796463, 0.46914780139923096,
  0.47042587399482727, 0.47814658284187317, 0.5041272640228271,
  0.5312345623970032, 0.5542858242988586, 0.5749755501747131,
  0.5772280693054199, 0.551253080368042, 0.5079643130302429, 0.4576423168182373,
  0.4110775291919708, 0.386597603559494, 0.37953415513038635,
  0.35623830556869507, 0.3089599013328552, 0.27471137046813965,
  0.2776661813259125, 0.2995563745498657, 0.3147573173046112,
  0.30966436862945557, 0.27933770418167114, 0.2401546835899353,
  0.2181635946035385, 0.21113544702529907, 0.19783496856689453,
  0.17311620712280273, 0.139228954911232, 0.09067222476005554,
  0.03440288081765175, -0.007607376668602228, -0.028398333117365837,
  -0.03881565481424332, -0.04284348338842392, -0.03956415876746178,
  -0.03867259994149208, -0.04523841291666031, -0.04522800073027611,
  -0.031423211097717285, -0.01809771917760372, -0.01559483539313078,
  -0.020769523456692696, -0.03154052421450615, -0.047864388674497604,
  -0.07148966193199158, -0.10677889734506607, -0.14550401270389557,
  -0.17048880457878113, -0.18058444559574127, -0.18559667468070984,
  -0.19215692579746246, -0.20768548548221588, -0.22834992408752441,
  -0.23611006140708923, -0.22924360632896423, -0.21962521970272064,
  -0.2062302529811859, -0.18974120914936066, -0.1774722933769226,
  -0.15878178179264069, -0.1223912388086319, -0.0810934379696846,
  -0.048357781022787094, -0.02804391272366047, -0.0271714199334383,
  -0.04442431777715683, -0.06692906469106674, -0.08748944103717804,
  -0.10534367710351944, -0.12401791661977768, -0.1481272280216217,
  -0.16784580051898956, -0.16859744489192963, -0.14933989942073822,
  -0.11087490618228912, -0.05967259407043457, -0.019356979057192802,
  0.0030858309473842382, 0.026571234688162804, 0.05322235822677612,
  0.07184697687625885, 0.08736813068389893, 0.10155964642763138,
  0.1059325635433197, 0.10602401196956635, 0.11114883422851562,
  0.11966592073440552, 0.13315877318382263, 0.15215371549129486,
  0.1638905256986618, 0.15818533301353455, 0.14281857013702393,
  0.13546085357666016, 0.14247271418571472, 0.1514628529548645,
  0.15555186569690704, 0.16542264819145203, 0.1854591965675354,
  0.21174819767475128, 0.24584953486919403, 0.27802860736846924,
  0.28925585746765137, 0.2825159430503845, 0.2752353250980377,
  0.2777769863605499, 0.2942097783088684, 0.3109394907951355,
  0.3034982681274414, 0.27847641706466675, 0.26766037940979004,
  0.28311678767204285, 0.3129650056362152, 0.3413509130477905,
  0.3623782992362976, 0.3806580901145935, 0.38705551624298096,
  0.36595645546913147, 0.33622506260871887, 0.32441380620002747,
  0.3194397985935211, 0.308580607175827, 0.2967643141746521,
  0.27651625871658325, 0.25210732221603394, 0.251257061958313,
  0.2703956961631775, 0.2860237658023834, 0.30480971932411194,
  0.32857200503349304, 0.3321714997291565, 0.3140871822834015,
  0.2973112463951111, 0.2864902913570404, 0.2749072015285492,
  0.26581481099128723, 0.2627667784690857, 0.2558499574661255,
  0.2319359928369522, 0.2005813717842102, 0.18164879083633423,
  0.16677573323249817, 0.14210808277130127, 0.12110713124275208,
  0.10703244805335999, 0.08607460558414459, 0.06863922625780106,
  0.0641622543334961, 0.057126834988594055, 0.05266159772872925,
  0.06551341712474823, 0.07414153963327408, 0.0626966804265976,
  0.04934932664036751, 0.038803987205028534, 0.020377425476908684,
  -0.002929101698100567, -0.029989661648869514, -0.06355492770671844,
  -0.09215839952230453, -0.10734677314758301, -0.11775905638933182,
  -0.13241751492023468, -0.14714393019676208, -0.14996163547039032,
  -0.14489325881004333, -0.15428800880908966, -0.1780177354812622,
  -0.19410715997219086, -0.20721788704395294, -0.23418714106082916,
  -0.2635141611099243, -0.2809096574783325, -0.2890450358390808,
  -0.287871778011322, -0.28050294518470764, -0.2775839865207672,
  -0.2789018154144287, -0.2830718755722046, -0.29629093408584595,
  -0.3135099411010742, -0.32351112365722656, -0.3249867856502533,
  -0.3236940801143646, -0.3333650231361389, -0.3624271750450134,
  -0.3903254568576813, -0.3942278325557709, -0.38480669260025024,
  -0.38322076201438904, -0.39282700419425964, -0.4048008620738983,
  -0.41145095229148865, -0.41896650195121765, -0.43668806552886963,
  -0.45329412817955017, -0.45836469531059265, -0.46270039677619934,
  -0.4657794237136841, -0.45774510502815247, -0.45329028367996216,
  -0.4606824815273285, -0.4581487476825714, -0.4434465169906616,
  -0.44178569316864014, -0.4598928689956665, -0.4884278178215027,
  -0.5165334939956665, -0.5225902199745178, -0.5072356462478638,
  -0.5127438902854919, -0.5485947132110596, -0.5602931380271912,
  -0.5320588946342468, -0.52718186378479, -0.5659002065658569,
  -0.5787444710731506, -0.5394523739814758, -0.5138307809829712,
  -0.5339505672454834, -0.5600928664207458, -0.5745686292648315,
  -0.586920440196991, -0.5807385444641113, -0.5495150089263916,
  -0.5223055481910706, -0.5165003538131714, -0.5336326360702515,
  -0.5736980438232422, -0.5992866158485413, -0.5652201175689697,
  -0.5109634399414062, -0.513860821723938, -0.5559719800949097,
  -0.5610570311546326, -0.528952419757843, -0.5046340227127075,
  -0.47183656692504883, -0.3980906903743744, -0.3151790201663971,
  -0.2690812945365906, -0.2604450285434723, -0.2573384940624237,
  -0.21384009718894958, -0.12448235601186752, -0.07126381993293762,
  -0.11470895260572433, -0.18728893995285034, -0.20522281527519226,
  -0.18627199530601501, -0.17881685495376587, -0.1885744035243988,
  -0.20373249053955078, -0.21171697974205017, -0.1958998292684555,
  -0.13739365339279175, -0.026635024696588516, 0.09667868912220001,
  0.16555006802082062, 0.18937982618808746, 0.23771461844444275,
  0.31592807173728943, 0.37131229043006897, 0.3821042776107788,
  0.36271557211875916, 0.3407151997089386, 0.3350782096385956,
  0.32211366295814514, 0.28302201628685, 0.2622651755809784,
  0.28802600502967834, 0.31524336338043213, 0.317747563123703,
  0.33067062497138977, 0.3724929094314575, 0.4159489572048187,
  0.43477770686149597, 0.4344727694988251, 0.4469486176967621,
  0.4949055016040802, 0.5582637786865234, 0.5959676504135132,
  0.5971469283103943, 0.5913047790527344, 0.6022204756736755, 0.612324595451355,
  0.6024460196495056, 0.5895958542823792, 0.5743901133537292,
  0.5204862356185913, 0.4385119378566742, 0.39299464225769043,
  0.398834764957428, 0.41208580136299133, 0.40981388092041016,
  0.4013656675815582, 0.4020153284072876, 0.4254455268383026,
  0.4612140357494354, 0.481338232755661, 0.48551130294799805,
  0.48539867997169495, 0.4628349542617798, 0.4073677957057953,
  0.3534700572490692, 0.330644428730011, 0.32165059447288513,
  0.2962389290332794, 0.2514081597328186, 0.21129243075847626,
  0.20051832497119904, 0.21411575376987457, 0.2234433889389038,
  0.21698150038719177, 0.20742543041706085, 0.19908112287521362,
  0.1869455873966217, 0.17925897240638733, 0.17970174551010132,
  0.16666293144226074, 0.12619927525520325, 0.07870613783597946,
  0.0483124740421772, 0.03357267752289772, 0.01719564199447632,
  -0.01425467524677515, -0.054304338991642, -0.07839802652597427,
  -0.07758430391550064, -0.07200184464454651, -0.07323770970106125,
  -0.06749185174703598, -0.05084700509905815, -0.03885062783956528,
  -0.03412032499909401, -0.025822103023529053, -0.018024886026978493,
  -0.024082880467176437, -0.04361670836806297, -0.06584572046995163,
  -0.08039280772209167, -0.08155500888824463, -0.07834936678409576,
  -0.08929591625928879, -0.11715909838676453, -0.14817798137664795,
  -0.17240838706493378, -0.18305443227291107, -0.17317640781402588,
  -0.14979802072048187, -0.1302081197500229, -0.12062465399503708,
  -0.11242662370204926, -0.09000200778245926, -0.04411672428250313,
  0.009700928814709187, 0.04531770199537277, 0.061619602143764496,
  0.07159863412380219, 0.07045791298151016, 0.05411791428923607,
  0.04085436463356018, 0.03939482569694519, 0.03093811310827732,
  0.0063177309930324554, -0.013858303427696228, -0.009131599217653275,
  0.01606661267578602, 0.04146465286612511, 0.053894516080617905,
  0.061305902898311615, 0.08143921941518784, 0.1147458404302597,
  0.14364489912986755, 0.1595960259437561, 0.16851061582565308,
  0.17397236824035645, 0.17847490310668945, 0.19007983803749084,
  0.2098558098077774, 0.22663934528827667, 0.23140491545200348,
  0.22643670439720154, 0.22183358669281006, 0.22497962415218353,
  0.22906358540058136, 0.2215331792831421, 0.20585525035858154,
  0.19627048075199127, 0.19576945900917053, 0.1995958834886551,
  0.20862235128879547, 0.22415409982204437, 0.24113775789737701,
  0.25257235765457153, 0.2549489140510559, 0.2520782947540283,
  0.2516515552997589, 0.25212717056274414, 0.24362994730472565,
  0.2270583212375641, 0.21433387696743011, 0.20733223855495453,
  0.1987091600894928, 0.191719651222229, 0.1965794712305069,
  0.21364104747772217, 0.23562690615653992, 0.2536334991455078,
  0.25813430547714233, 0.25108325481414795, 0.24597790837287903,
  0.2435532510280609, 0.2317543774843216, 0.21157124638557434,
  0.19384793937206268, 0.18018756806850433, 0.1679132729768753,
  0.1573827564716339, 0.14785528182983398, 0.13966868817806244,
  0.13599181175231934, 0.13895027339458466, 0.14888127148151398,
  0.1598030924797058, 0.15922757983207703, 0.14635373651981354,
  0.13824723660945892, 0.1458485871553421, 0.15699921548366547,
  0.15273858606815338, 0.13316385447978973, 0.11398765444755554,
  0.10041942447423935, 0.08696887642145157, 0.07388975471258163,
  // adding after 18k points
  -0.19375430047512054, -0.11867111176252365, -0.09968994557857513,
  -0.1651657223701477, -0.2053573578596115, -0.17689819633960724,
  -0.1558038741350174, -0.17170673608779907, -0.16645239293575287,
  -0.1314517855644226, -0.13668780028820038, -0.19619235396385193,
  -0.22824004292488098, -0.18854504823684692, -0.1327531486749649,
  -0.11199744790792465, -0.12494906783103943, -0.17524391412734985,
  -0.23775945603847504, -0.22790496051311493, -0.14556199312210083,
  -0.1132265031337738, -0.16715310513973236, -0.2085656374692917,
  -0.1922367960214615, -0.1593567281961441, -0.13957999646663666,
  -0.1532554030418396, -0.19517937302589417, -0.20420405268669128,
  -0.16304457187652588, -0.1329408437013626, -0.14150403439998627,
  -0.1664872169494629, -0.18725766241550446, -0.1786399632692337,
  -0.15077510476112366, -0.15437725186347961, -0.17511844635009766,
  -0.16251231729984283, -0.14273999631404877, -0.15076206624507904,
  -0.16805993020534515, -0.18880636990070343, -0.19798864424228668,
  -0.161538228392601, -0.12170524150133133, -0.129585862159729,
  -0.1384020894765854, -0.12820404767990112, -0.15000131726264954,
  -0.18329784274101257, -0.17238211631774902, -0.13808579742908478,
  -0.11485684663057327, -0.11381252855062485, -0.1395939290523529,
  -0.15034322440624237, -0.12596495449543, -0.1311466246843338,
  -0.16629628837108612, -0.14685939252376556, -0.09329419583082199,
  -0.0905478373169899, -0.12181290239095688, -0.13696067035198212,
  -0.13686582446098328, -0.12236746400594711, -0.10017050802707672,
  -0.09499847888946533, -0.10517780482769012, -0.12045309692621231,
  -0.13196733593940735, -0.10831757634878159, -0.058992624282836914,
  -0.04821104556322098, -0.08663657307624817, -0.12870945036411285,
  -0.1464509814977646, -0.12240644544363022, -0.07200383394956589,
  -0.05979903042316437, -0.08950954675674438, -0.09298302233219147,
  -0.07222148030996323, -0.08439449220895767, -0.10855033993721008,
  -0.08159268647432327, -0.02505779080092907, -0.0266012754291296,
  -0.0966060534119606, -0.131122887134552, -0.08389275521039963,
  -0.053055290132761, -0.08726398646831512, -0.09601222723722458,
  -0.04744939133524895, -0.025780949741601944, -0.06267160922288895,
  -0.106348916888237, -0.1121465265750885, -0.0705481618642807,
  -0.022208798676729202, -0.022917604073882103, -0.05528968945145607,
  -0.06979041546583176, -0.06980627775192261, -0.06189591437578201,
  -0.03066740557551384, 0.0026462229434400797, 0.009022868238389492,
  0.00006783060962334275, -0.010613312013447285, -0.020574213936924934,
  -0.023036295548081398, -0.02144494652748108, -0.011701109819114208,
  0.017310459166765213, 0.025307612493634224, -0.03238043561577797,
  -0.09804267436265945, -0.08236832171678543, -0.001617654343135655,
  0.06069347634911537, 0.06606835871934891, 0.03430606424808502,
  0.0017357097240164876, -0.003384668380022049, 0.02702510915696621,
  0.07362224906682968, 0.10004225373268127, 0.08870347589254379,
  0.05924410745501518, 0.03538607805967331, 0.021226832643151283,
  0.022720320150256157, 0.048496946692466736, 0.08317036926746368,
  0.09898678213357925, 0.0911535993218422, 0.0788336768746376,
  0.07852920144796371, 0.08593573421239853, 0.08832703530788422,
  0.09262978285551071, 0.1120443344116211, 0.12497448921203613,
  0.1035737693309784, 0.07882820814847946, 0.09650525450706482,
  0.12859292328357697, 0.12746039032936096, 0.12022272497415543,
  0.14520679414272308, 0.17297974228858948, 0.17360463738441467,
  0.16523240506649017, 0.1600259393453598, 0.1518365442752838,
  0.14178583025932312, 0.12940926849842072, 0.12275518476963043,
  0.1387387067079544, 0.15859605371952057, 0.15008367598056793,
  0.12700118124485016, 0.12129972130060196, 0.14453688263893127,
  0.1890818327665329, 0.21612288057804108, 0.19913405179977417,
  0.1799176037311554, 0.1890908181667328, 0.18809349834918976,
  0.1607816517353058, 0.14136624336242676, 0.14963404834270477,
  0.18483686447143555, 0.21528257429599762, 0.18783064186573029,
  0.1318875253200531, 0.13195432722568512, 0.1711273044347763,
  0.17887622117996216, 0.1684175282716751, 0.17687062919139862,
  0.19112782180309296, 0.19421809911727905, 0.1818384975194931,
  0.16090621054172516, 0.1605711430311203, 0.18611247837543488,
  0.20419318974018097, 0.19699695706367493, 0.1760127693414688,
  0.1678304672241211, 0.1862080693244934, 0.19393698871135712,
  0.1706281304359436, 0.17114447057247162, 0.20360615849494934,
  0.19591060280799866, 0.15288104116916656, 0.14714406430721283,
  0.17594072222709656, 0.18765461444854736, 0.16493657231330872,
  0.13232974708080292, 0.14094364643096924, 0.18548250198364258,
  0.1883866935968399, 0.15439310669898987, 0.1624465435743332,
  0.1900785267353058, 0.178428515791893, 0.16293400526046753,
  0.16719681024551392, 0.1591392606496811, 0.15121330320835114,
  0.16705207526683807, 0.1799626648426056, 0.16288460791110992,
  0.1213473528623581, 0.10091670602560043, 0.1410141885280609,
  0.17938272655010223, 0.1521383672952652, 0.13170893490314484,
  0.15647941827774048, 0.1417195200920105, 0.10145474225282669,
  0.12505970895290375, 0.160551518201828, 0.14173197746276855,
  0.12625108659267426, 0.12997139990329742, 0.1120554506778717,
  0.10548888891935349, 0.13010071218013763, 0.13924844563007355,
  0.129566490650177, 0.12051129341125488, 0.10709115862846375,
  0.11313927918672562, 0.13621051609516144, 0.11451443284749985,
  0.06439121067523956, 0.06518413126468658, 0.10252746939659119,
  0.11709853261709213, 0.10897304862737656, 0.09363113343715668,
  0.08381687104701996, 0.09540881961584091, 0.10618282854557037,
  0.09728220850229263, 0.08831839263439178, 0.0761934444308281,
  0.0678270161151886, 0.10417933017015457, 0.13241030275821686,
  0.06747664511203766, -0.007435561623424292, 0.014528518542647362,
  0.06719918549060822, 0.08527939766645432, 0.09816554933786392,
  0.08632960170507431, 0.03821706399321556, 0.027299879118800163,
  0.054807137697935104, 0.059265218675136566, 0.06662635505199432,
  0.0907844752073288, 0.0733608528971672, 0.04123006388545036,
  0.060608554631471634, 0.07811007648706436, 0.03677075356245041,
  -0.00134330615401268, 0.01867208443582058, 0.057468656450510025,
  0.06636697798967361, 0.05018259957432747, 0.027276307344436646,
  -0.000016986505215754732, -0.00016688031610101461, 0.055474258959293365,
  0.08306150138378143, 0.00862039439380169, -0.04483732208609581,
  0.026721298694610596, 0.09226524829864502, 0.054195303469896317,
  0.0106102479621768, 0.008946647867560387, 0.0025705290026962757,
  0.011113271117210388, 0.04536746069788933, 0.047895219177007675,
  0.018710605800151825, 0.006585380993783474, 0.012225005775690079,
  0.012767567299306393, 0.005349737126380205, 0.007424089126288891,
  0.022331971675157547, 0.005514342337846756, -0.04476416856050491,
  -0.0404147133231163, 0.028703851625323296, 0.058574337512254715,
  0.016910269856452942, -0.039527084678411484, -0.06445939093828201,
  -0.028654346242547035, 0.02715892530977726, 0.008154869079589844,
  -0.055070795118808746, -0.051493506878614426, -0.004560278728604317,
  -0.00146153022069484, -0.027577389031648636, -0.03031424805521965,
  -0.015109033323824406, -0.02533608302474022, -0.06671769917011261,
  -0.07149576395750046, -0.01526164636015892, 0.015601168386638165,
  -0.01981152407824993, -0.07109245657920837, -0.11068201065063477,
  -0.1037701815366745, -0.027515951544046402, 0.018682939931750298,
  -0.022189529612660408, -0.056189391762018204, -0.07358229905366898,
  -0.12924043834209442, -0.12517298758029938, -0.0139850452542305,
  0.04280088096857071, -0.029884200543165207, -0.12311246246099472,
  -0.1558951437473297, -0.121603824198246, -0.062021493911743164,
  -0.04054231196641922, -0.03230415657162666, -0.005463700741529465,
  -0.04526117444038391, -0.15677571296691895, -0.1944401115179062,
  -0.13125260174274445, -0.07298675924539566, -0.04814591258764267,
  -0.04698367044329643, -0.09238434582948685, -0.1517494022846222,
  -0.15636880695819855, -0.10425148904323578, -0.0592334158718586,
  -0.07838720828294754, -0.13204360008239746, -0.14185959100723267,
  -0.12300559133291245, -0.13642139732837677, -0.14431443810462952,
  -0.11389274895191193, -0.11628809571266174, -0.16298887133598328,
  -0.16348527371883392, -0.11274393647909164, -0.09831982851028442,
  -0.1357710361480713, -0.1657072901725769, -0.16298744082450867,
  -0.14474999904632568, -0.12191841006278992, -0.10774295032024384,
  -0.1284312605857849, -0.1750352382659912, -0.20098663866519928,
  -0.1974203884601593, -0.1845303624868393, -0.1471482217311859,
  -0.09353677928447723, -0.0946621522307396, -0.1575068086385727,
  -0.1962192803621292, -0.18275007605552673, -0.16621507704257965,
  -0.1600014865398407, -0.14773719012737274, -0.1366693526506424,
  -0.13491158187389374, -0.14735902845859528, -0.17748861014842987,
  -0.1930854767560959, -0.17312082648277283, -0.16402296721935272,
  -0.18681679666042328, -0.19035229086875916, -0.16701160371303558,
  -0.16107288002967834, -0.16674315929412842, -0.16634728014469147,
  -0.17781205475330353, -0.1843601018190384, -0.16337832808494568,
  -0.15510335564613342, -0.17102165520191193, -0.16768209636211395,
  -0.15798497200012207, -0.1695847362279892, -0.16566148400306702,
  -0.14919328689575195, -0.17654797434806824, -0.21602864563465118,
  -0.1943325400352478, -0.14476728439331055, -0.13920117914676666,
  -0.16173139214515686, -0.16833806037902832, -0.1736450344324112,
  -0.18951427936553955, -0.1726527065038681, -0.11655576527118683,
  -0.08854476362466812, -0.11764886975288391, -0.16934654116630554,
  -0.20809562504291534, -0.18746691942214966, -0.11447259038686752,
  -0.10207396745681763, -0.16108304262161255, -0.16048818826675415,
  -0.11879628151655197, -0.14218877255916595, -0.1637192964553833,
  -0.14275555312633514, -0.17050574719905853, -0.19260713458061218,
  -0.1250593364238739, -0.08673113584518433, -0.12173469364643097,
  -0.12906591594219208, -0.1373617947101593, -0.17403145134449005,
  -0.1483391672372818, -0.09598351269960403, -0.09875968843698502,
  -0.1069541648030281, -0.12662406265735626, -0.17922592163085938,
  -0.16094602644443512, -0.10119844973087311, -0.13126476109027863,
  -0.16166619956493378, -0.11124201118946075, -0.1070803850889206,
  -0.15563809871673584, -0.14866535365581512, -0.12556812167167664,
  -0.12427341938018799, -0.10516490787267685, -0.09854642301797867,
  -0.1113089993596077, -0.09809094667434692, -0.0866003930568695,
  -0.07975539565086365, -0.045947425067424774, -0.04318474605679512,
  -0.07092983275651932, -0.04988739639520645, -0.03472479060292244,
  -0.07045286893844604, -0.06651245802640915, -0.0428309291601181,
  -0.07555544376373291, -0.09313276410102844, -0.07796001434326172,
  -0.10179700702428818, -0.10441872477531433, -0.045152775943279266,
  -0.018411919474601746, -0.02310519479215145, 0.001667988020926714,
  0.014982416294515133, 0.007330847438424826, 0.01975868083536625,
  0.028707368299365044, 0.031687650829553604, 0.05110658332705498,
  0.04377371445298195, 0.012783164158463478, 0.01981314830482006,
  0.0358702689409256, 0.02138597145676613, 0.023953473195433617,
  0.056524608284235, 0.0768674910068512, 0.07611450552940369,
  0.06290217489004135, 0.05165969580411911, 0.062268614768981934,
  0.08075065165758133, 0.09516045451164246, 0.11680807173252106,
  0.12338267266750336, 0.09982617199420929, 0.088772252202034,
  0.1111571416258812, 0.1405896693468094, 0.157243549823761, 0.1545587182044983,
  0.15156036615371704, 0.17165718972682953, 0.1771608293056488,
  0.14146947860717773, 0.12469946593046188, 0.15071983635425568,
  0.16077284514904022, 0.14504222571849823, 0.14415216445922852,
  0.15156961977481842, 0.1429113894701004, 0.13491487503051758,
  0.15604695677757263, 0.19642245769500732, 0.21397118270397186,
  0.20226524770259857, 0.1998397558927536, 0.20582741498947144,
  0.19094286859035492, 0.18096566200256348, 0.2027769684791565,
  0.21716932952404022, 0.19983208179473877, 0.18569684028625488,
  0.1944141685962677, 0.19941501319408417, 0.17983657121658325,
  0.1624908447265625, 0.18279269337654114, 0.215383380651474,
  0.21933476626873016, 0.21579872071743011, 0.2197912037372589,
  0.19957485795021057, 0.17766651511192322, 0.206770658493042,
  0.24462628364562988, 0.2305818349123001, 0.20391428470611572,
  0.206856831908226, 0.2055428922176361, 0.1869809627532959,
  0.19029349088668823, 0.21543043851852417, 0.22596293687820435,
  0.22616800665855408, 0.23192735016345978, 0.21998663246631622,
  0.18864493072032928, 0.1789446473121643, 0.19307787716388702,
  0.20104952156543732, 0.2052965760231018, 0.20695310831069946,
  0.19263270497322083, 0.18204718828201294, 0.18929874897003174,
  0.19361594319343567, 0.19844716787338257, 0.21375182271003723,
  0.21573412418365479, 0.2032848447561264, 0.19689758121967316,
  0.18875941634178162, 0.1776280701160431, 0.17417079210281372,
  0.17097659409046173, 0.17390389740467072, 0.17844100296497345,
  0.15150591731071472, 0.12777897715568542, 0.1621624231338501,
  0.19565239548683167, 0.1783347874879837, 0.1673387885093689,
  0.17431232333183289, 0.1623881608247757, 0.15422053635120392,
  0.15123800933361053, 0.13188529014587402, 0.14164216816425323,
  0.17883022129535675, 0.16915389895439148, 0.13314048945903778,
  0.12822510302066803, 0.13067485392093658, 0.13392728567123413,
  0.14791421592235565, 0.13565890491008759, 0.11601567268371582,
  0.13138127326965332, 0.13108427822589874, 0.10263021290302277,
  0.10693127661943436, 0.11053240299224854, 0.07601309567689896,
  0.08117105066776276, 0.13095852732658386, 0.13459107279777527,
  0.10671639442443848, 0.10448776930570602, 0.10015347599983215,
  0.08368255198001862, 0.08767090737819672, 0.09894154965877533,
  0.09846717119216919, 0.09748005867004395, 0.09006112068891525,
  0.07083617895841599, 0.0579109713435173, 0.05606354400515556,
  0.058193597942590714, 0.061218950897455215, 0.058038659393787384,
  0.05799451470375061, 0.07782603800296783, 0.0929623544216156,
  0.07529685646295547, 0.052319932729005814, 0.051766376942396164,
  0.056549981236457825, 0.05986035242676735, 0.07132627069950104,
  0.0689832791686058, 0.04181889444589615, 0.031204186379909515,
  0.0522434301674366, 0.061830341815948486, 0.04598035663366318,
  0.03499501198530197, 0.03661295026540756, 0.042087066918611526,
  0.0537409633398056, 0.059842973947525024, 0.04301205277442932,
  0.017940016463398933, 0.015086382627487183, 0.037397321313619614,
  0.051783666014671326, 0.03649609908461571, 0.02434861846268177,
  0.04231954365968704, 0.051210999488830566, 0.0272364504635334,
  0.012553826905786991, 0.023518580943346024, 0.027178457006812096,
  0.017012080177664757, 0.014258711598813534, 0.022285813465714455,
  0.01839003711938858, -0.01143583096563816, -0.021133800968527794,
  0.03054686263203621, 0.07112409174442291, 0.03190021589398384,
  -0.01816904917359352, -0.016814835369586945, 0.0025354798417538404,
  0.018866242840886116, 0.015507316216826439, -0.028740178793668747,
  -0.049031488597393036, -0.009395415894687176, 0.0022080696653574705,
  -0.023078929632902145, -0.007058089133352041, 0.0034543792717158794,
  -0.04462290555238724, -0.0639171376824379, -0.020726244896650314,
  0.012830609455704689, 0.013562262058258057, -0.015863949432969093,
  -0.06872723996639252, -0.08227774500846863, -0.04628415405750275,
  -0.03280435875058174, -0.044778238981962204, -0.03854486718773842,
  -0.029778122901916504, -0.03953799232840538, -0.061242420226335526,
  -0.0827697217464447, -0.07530765980482101, -0.04834870249032974,
  -0.05641776695847511, -0.0840439572930336, -0.07350774109363556,
  -0.04298420995473862, -0.04459761828184128, -0.08885462582111359,
  -0.13336363434791565, -0.1054246723651886, -0.023216208443045616,
  -0.010726955719292164, -0.09238869696855545, -0.16489653289318085,
  -0.169748917222023, -0.11152602732181549, -0.04228803515434265,
  -0.05792051553726196, -0.12925677001476288, -0.1279689520597458,
  -0.08878076076507568, -0.1062740758061409, -0.12161188572645187,
  -0.11467596143484116, -0.1567387878894806, -0.18568696081638336,
  -0.13818614184856415, -0.11657755821943283, -0.14413604140281677,
  -0.13001516461372375, -0.1136833131313324, -0.16002468764781952,
  -0.19025439023971558, -0.15378448367118835, -0.1151195764541626,
  -0.123462975025177, -0.1624545007944107, -0.1740735024213791,
  -0.13712282478809357, -0.1327364593744278, -0.19155526161193848,
  -0.21155105531215668, -0.17433661222457886, -0.16449272632598877,
  -0.16045662760734558, -0.13060322403907776, -0.15657083690166473,
  -0.22456830739974976, -0.21856927871704102, -0.1725662797689438,
  -0.18441987037658691, -0.2128203958272934, -0.2039615958929062,
  -0.19106462597846985, -0.18937242031097412, -0.1906556338071823,
  -0.1996641308069229, -0.20182079076766968, -0.20047152042388916,
  -0.21745435893535614, -0.21772512793540955, -0.18453313410282135,
  -0.18384242057800293, -0.22412678599357605, -0.24277418851852417,
  -0.24869179725646973, -0.26162707805633545, -0.23303717374801636,
  -0.1781059056520462, -0.17564339935779572, -0.21005380153656006,
  -0.2200368493795395, -0.2167326956987381, -0.22415384650230408,
  -0.2305184006690979, -0.2270190715789795, -0.21365207433700562,
  -0.20619386434555054, -0.2275758981704712, -0.25099971890449524,
  -0.23636101186275482, -0.21345342695713043, -0.22203204035758972,
  -0.23570698499679565, -0.22720091044902802, -0.22260305285453796,
  -0.2444555014371872, -0.26210394501686096, -0.2378370314836502,
  -0.20263700187206268, -0.21488632261753082, -0.24685633182525635,
  -0.2320583611726761, -0.19892309606075287, -0.21199916303157806,
  -0.2478753924369812, -0.25154218077659607, -0.2286895215511322,
  -0.21816976368427277, -0.23235072195529938, -0.2375718057155609,
  -0.20540428161621094, -0.17999477684497833, -0.20892676711082458,
  -0.24662305414676666, -0.24022556841373444, -0.2166082262992859,
  -0.2040044218301773, -0.19733533263206482, -0.2094966173171997,
  -0.23607048392295837, -0.23185722529888153, -0.19752106070518494,
  -0.1870250701904297, -0.2064165472984314, -0.20837518572807312,
  -0.1778235137462616, -0.14075930416584015, -0.10998372733592987,
  -0.08870642632246017, -0.09785444289445877, -0.13389891386032104,
  -0.14421819150447845, -0.10940361022949219, -0.08242223411798477,
  -0.0994538813829422, -0.13658404350280762, -0.16720424592494965,
  -0.18218031525611877, -0.17251083254814148, -0.1398807317018509,
  -0.09707624465227127, -0.058485761284828186, -0.04274934157729149,
  -0.04787478595972061, -0.03616047650575638, 0.011683665215969086,
  0.06044309213757515, 0.07145995646715164, 0.053316760808229446,
  0.03785780072212219, 0.029822612181305885, 0.01369304209947586,
  -0.0013773877872154117, 0.0038106776773929596, 0.013496735133230686,
  0.0073485709726810455, 0.0024281644728034735, 0.017149800434708595,
  0.04330035671591759, 0.07123343646526337, 0.09625905752182007,
  0.1142202690243721, 0.1283775418996811, 0.13668833673000336,
  0.1339767724275589, 0.13598737120628357, 0.15503770112991333,
  0.17082104086875916, 0.17068791389465332, 0.17560797929763794,
  0.1980559378862381, 0.2192215472459793, 0.21777623891830444,
  0.19503337144851685, 0.1721249371767044, 0.16790032386779785,
  0.18037402629852295, 0.19352665543556213, 0.19597573578357697,
  0.1887771040201187, 0.17975156009197235, 0.17433017492294312,
  0.17780593037605286, 0.19974951446056366, 0.2412036508321762,
  0.2846079468727112, 0.3066873252391815, 0.2990160286426544,
  0.27907341718673706, 0.2703310251235962, 0.2673080861568451,
  0.255258172750473, 0.2504160702228546, 0.2649092376232147,
  0.27021530270576477, 0.24465312063694, 0.20581823587417603,
  0.18259863555431366, 0.19646453857421875, 0.24058093130588531,
  0.2710607051849365, 0.2673114538192749, 0.25803184509277344,
  0.255919873714447, 0.24772143363952637, 0.24454282224178314,
  0.26121386885643005, 0.28046914935112, 0.28035008907318115,
  0.25639134645462036, 0.22248978912830353, 0.2095452845096588,
  0.2304774671792984, 0.2591213583946228, 0.26633521914482117,
  0.24534150958061218, 0.2115892618894577, 0.20082269608974457,
  0.22860130667686462, 0.25782984495162964, 0.2520102858543396,
  0.22422820329666138, 0.20333372056484222, 0.1986582726240158,
  0.20477013289928436, 0.2098538875579834, 0.2071463018655777,
  0.20290958881378174, 0.20574022829532623, 0.2186613231897354,
  0.2359507530927658, 0.23977407813072205, 0.21981003880500793,
  0.19334328174591064, 0.1839180439710617, 0.18989618122577667,
  0.19093455374240875, 0.1800599992275238, 0.17232602834701538,
  0.17306016385555267, 0.16399547457695007, 0.14436598122119904,
  0.14476589858531952, 0.17171616852283478, 0.18869702517986298,
  0.17460010945796967, 0.1549854576587677, 0.15942439436912537,
  0.1765131950378418, 0.17042873799800873, 0.1372203379869461,
  0.11412135511636734, 0.12175677716732025, 0.13791652023792267,
  0.14051517844200134, 0.13360457122325897, 0.12726832926273346,
  0.1252649575471878, 0.12704288959503174, 0.12569117546081543,
  0.1161913275718689, 0.10787332057952881, 0.1104755848646164,
  0.11031617224216461, 0.087701216340065, 0.056364934891462326,
  0.04677325859665871, 0.05906015634536743, 0.07886429131031036,
  0.10630228370428085, 0.12481307983398438, 0.10440734773874283,
  0.062889464199543, 0.04861830174922943, 0.067587710916996, 0.0907130315899849,
  0.0959857925772667, 0.07205954194068909, 0.029985997825860977,
  0.005115761421620846, 0.013036428019404411, 0.035204995423555374,
  0.05288376659154892, 0.06219416484236717, 0.0665137842297554,
  0.06662092357873917, 0.057443078607320786, 0.04539990425109863,
  0.04697706550359726, 0.0584266297519207, 0.06346505135297775,
  0.054226748645305634, 0.024422496557235718, -0.0058496561832726,
  0.00741841085255146, 0.044245216995477676, 0.040464162826538086,
  0.018474213778972626, 0.040677543729543686, 0.07550808787345886,
  0.06742601841688156, 0.04270755127072334, 0.04484899342060089,
  0.06792869418859482, 0.07770819962024689, 0.05084776133298874,
  0.013371789827942848, 0.005931389983743429, 0.014883208088576794,
  0.02168796956539154, 0.03602983057498932, 0.026124168187379837,
  -0.019343333318829536, -0.02037961408495903, 0.03206763416528702,
  0.04726521298289299, 0.0367630273103714, 0.052373647689819336,
  0.03311231732368469, -0.02320985496044159, -0.015837810933589935,
  0.026406362652778625, 0.02285189926624298, 0.033575739711523056,
  0.07551208883523941, 0.040621381253004074, -0.05766845867037773,
  -0.09625864773988724, -0.06369734555482864, -0.02079298347234726,
  0.00494247255846858, -0.00648917630314827, -0.04327438771724701,
  -0.059379059821367264, -0.05789753794670105, -0.0646081417798996,
  -0.06271126866340637, -0.038212381303310394, -0.0033034055959433317,
  0.02237144112586975, 0.0032008716370910406, -0.05741331726312637,
  -0.0871204137802124, -0.06695348024368286, -0.0629236102104187,
  -0.08547945320606232, -0.08877670764923096, -0.08792632073163986,
  -0.11520833522081375, -0.13831913471221924, -0.12732872366905212,
  -0.10259856283664703, -0.08351565897464752, -0.08003531396389008,
  -0.10619933903217316, -0.14051735401153564, -0.13677294552326202,
  -0.10128163546323776, -0.0865408256649971, -0.11126476526260376,
  -0.14969325065612793, -0.17659161984920502, -0.18086372315883636,
  -0.16244053840637207, -0.13868510723114014, -0.13553282618522644,
  -0.15647844970226288, -0.17219260334968567, -0.1661044955253601,
  -0.16270463168621063, -0.16792108118534088, -0.1549413651227951,
  -0.15233130753040314, -0.2053627222776413, -0.24543118476867676,
  -0.19505451619625092, -0.13334886729717255, -0.1539776772260666,
  -0.20574375987052917, -0.21924647688865662, -0.21007484197616577,
  -0.20535749197006226, -0.20906761288642883, -0.2113708257675171,
  -0.1924213171005249, -0.1729261428117752, -0.1974346935749054,
  -0.24216455221176147, -0.24789853394031525, -0.21526896953582764,
  -0.18774348497390747, -0.1947111189365387, -0.22724826633930206,
  -0.24980422854423523, -0.2553138732910156, -0.2595294713973999,
  -0.2490595281124115, -0.22457320988178253, -0.21975085139274597,
  -0.2308007776737213, -0.24633769690990448, -0.27734091877937317,
  -0.2777934670448303, -0.2198994904756546, -0.19765232503414154,
  -0.2459878921508789, -0.2653973400592804, -0.23981307446956635,
  -0.236404150724411, -0.24403494596481323, -0.24735024571418762,
  -0.26785993576049805, -0.2727587819099426, -0.25692981481552124,
  -0.2761556804180145, -0.29746589064598083, -0.26593461632728577,
  -0.2451973408460617, -0.2738610804080963, -0.29069653153419495,
  -0.2836436331272125, -0.2862018942832947, -0.28810298442840576,
  -0.28407537937164307, -0.2842208743095398, -0.27394741773605347,
  -0.2576131820678711, -0.25788065791130066, -0.26301348209381104,
  -0.26075977087020874, -0.26634567975997925, -0.2825697660446167,
  -0.2952079176902771, -0.2932834029197693, -0.2774880528450012,
  -0.27016952633857727, -0.2836878299713135, -0.29093071818351746,
  -0.27649006247520447, -0.26634177565574646, -0.2741139531135559,
  -0.2791450321674347, -0.2710394561290741, -0.2723197340965271,
  -0.29559987783432007, -0.30339834094047546, -0.2657879590988159,
  -0.22638842463493347, -0.23067398369312286, -0.25397995114326477,
  -0.26623669266700745, -0.26828598976135254, -0.2513095438480377,
  -0.21762847900390625, -0.19454437494277954, -0.18230964243412018,
  -0.16592557728290558, -0.1545945256948471, -0.15201407670974731,
  -0.15120209753513336, -0.15758810937404633, -0.16119949519634247,
  -0.14098815619945526, -0.11313683539628983, -0.10962022095918655,
  -0.13456284999847412, -0.17524011433124542, -0.21041664481163025,
  -0.2121744006872177, -0.1721990555524826, -0.11124125868082047,
  -0.060703035444021225, -0.03665908798575401, -0.023164743557572365,
  0.0005125095485709608, 0.030826225876808167, 0.0642552599310875,
  0.10174249857664108, 0.11915001273155212, 0.09755939245223999,
  0.060376498848199844, 0.03328592702746391, 0.016653407365083694,
  0.012620368972420692, 0.02517567202448845, 0.04188026115298271,
  0.04961268976330757, 0.053954675793647766, 0.07217630743980408,
  0.1126302108168602, 0.16332316398620605, 0.20355765521526337,
  0.22256165742874146, 0.22504922747612, 0.2248348444700241, 0.2295825481414795,
  0.2366863638162613, 0.24721133708953857, 0.2627105712890625,
  0.2771355211734772, 0.2922114133834839, 0.30773794651031494,
  0.30244743824005127, 0.274751216173172, 0.2605923116207123,
  0.2708539366722107, 0.27984461188316345, 0.27975237369537354,
  0.27668651938438416, 0.26552814245224, 0.25311923027038574,
  0.26080039143562317, 0.29334813356399536, 0.33485230803489685,
  0.3663788139820099, 0.38280636072158813, 0.393852561712265,
  0.40144023299217224, 0.39839038252830505, 0.38858047127723694,
  0.37570568919181824, 0.3562999367713928, 0.34135663509368896,
  0.341609925031662, 0.34264418482780457, 0.33099979162216187,
  0.31245890259742737, 0.2965807020664215, 0.2947041988372803,
  0.314154714345932, 0.342806875705719, 0.36234384775161743, 0.366171658039093,
  0.3577299416065216, 0.3480512201786041, 0.3446367383003235,
  0.34362080693244934, 0.3451346755027771, 0.3502087891101837,
  0.3456772565841675, 0.32658326625823975, 0.3089296221733093,
  0.301934152841568, 0.30193856358528137, 0.30598995089530945,
  0.30858924984931946, 0.3060339391231537, 0.3014262318611145,
  0.29303762316703796, 0.281439870595932, 0.2789961099624634,
  0.28535258769989014, 0.2807634770870209, 0.2599599063396454,
  0.24212513864040375, 0.23939546942710876, 0.23859049379825592,
  0.22701525688171387, 0.21872451901435852, 0.22906461358070374,
  0.2448323667049408, 0.24937015771865845, 0.24388325214385986,
  0.2303970754146576, 0.21309794485569, 0.20335069298744202, 0.1997046321630478,
  0.19459474086761475, 0.19157108664512634, 0.18685278296470642,
  0.1701277792453766, 0.15137289464473724, 0.14802613854408264,
  0.15951965749263763, 0.17162583768367767, 0.1698264628648758,
  0.15403027832508087, 0.13588064908981323, 0.11759643256664276,
  0.10234420001506805, 0.10128362476825714, 0.10087675601243973,
  0.08232344686985016, 0.06781581789255142, 0.07640745490789413,
  0.08908524364233017, 0.09892429411411285, 0.11213432252407074,
  0.11572433263063431, 0.11056329309940338, 0.11032126098871231,
  0.10023348778486252, 0.07280275970697403, 0.05025491863489151,
  0.03435100242495537, 0.015152250416576862, 0.006113860290497541,
  0.008239357732236385, 0.0049847085028886795, 0.0043129161931574345,
  0.01641911081969738, 0.026042986661195755, 0.02847820706665516,
  0.03824854642152786, 0.05267101526260376, 0.0524006150662899,
  0.036210235208272934, 0.026068322360515594, 0.030055904760956764,
  0.028778107836842537, 0.01930832490324974, 0.022926902398467064,
  0.03252703323960304, 0.025499669834971428, 0.016421150416135788,
  0.02196553163230419, 0.025005709379911423, 0.02054578810930252,
  0.02015417441725731, 0.012028258293867111, -0.006278504151850939,
  -0.0023932026233524084, 0.02966426871716976, 0.053177665919065475,
  0.05223142355680466, 0.04279334098100662, 0.03564947098493576,
  0.040029510855674744, 0.066627137362957, 0.09955231845378876,
  0.11009261757135391, 0.09580164402723312, 0.0682390108704567,
  0.03646332770586014, 0.02368404157459736, 0.04582594707608223,
  0.0772562026977539, 0.08818808197975159, 0.07998407632112503,
  0.05938826873898506, 0.035534169524908066, 0.03357500955462456,
  0.060768913477659225, 0.0915759801864624, 0.10351946949958801,
  0.092677041888237, 0.07122813165187836, 0.062365155667066574,
  0.0702538713812828, 0.07677556574344635, 0.07726138085126877,
  0.07893569022417068, 0.07888520509004593, 0.07084023207426071,
  0.05288538336753845, 0.03682313486933708, 0.0517570860683918,
  0.09377163648605347, 0.10129707306623459, 0.04185280576348305,
  -0.02602159045636654, -0.03180106356739998, 0.009322172030806541,
  0.026937762275338173, 0.006086290348321199, -0.0009567275992594659,
  0.01918492279946804, 0.021593637764453888, -0.0031910487450659275,
  -0.022240400314331055, -0.02256527543067932, -0.007418313529342413,
  0.010012435726821423, 0.0029242881573736668, -0.02246251329779625,
  -0.03841360658407211, -0.05558554828166962, -0.08015001565217972,
  -0.0828375369310379, -0.07022333145141602, -0.07530079782009125,
  -0.09211909770965576, -0.10984224826097488, -0.13150601089000702,
  -0.13942554593086243, -0.13020361959934235, -0.12936735153198242,
  -0.1347556710243225, -0.1331280916929245, -0.1383541226387024,
  -0.151847705245018, -0.1605042666196823, -0.17361386120319366,
  -0.19273993372917175, -0.19365327060222626, -0.17262716591358185,
  -0.15879260003566742, -0.173461452126503, -0.20217157900333405,
  -0.2188691943883896, -0.22944781184196472, -0.250006765127182,
  -0.26329898834228516, -0.25299137830734253, -0.23066775500774384,
  -0.2087477743625641, -0.21185363829135895, -0.2618120610713959,
  -0.31196993589401245, -0.30639320611953735, -0.2745114862918854,
  -0.260381281375885, -0.26081568002700806, -0.2772199809551239,
  -0.30300045013427734, -0.3048158586025238, -0.2929806411266327,
  -0.30202892422676086, -0.31136271357536316, -0.2990081310272217,
  -0.29701662063598633, -0.3214426338672638, -0.3402532637119293,
  -0.33619487285614014, -0.3323880434036255, -0.34436309337615967,
  -0.35050836205482483, -0.3394918441772461, -0.3373247981071472,
  -0.34486138820648193, -0.33425018191337585, -0.3257603049278259,
  -0.35117506980895996, -0.3785245418548584, -0.37529224157333374,
  -0.35917699337005615, -0.34733131527900696, -0.3452627956867218,
  -0.35714009404182434, -0.3680947721004486, -0.3678733706474304,
  -0.36148691177368164, -0.3454457223415375, -0.3350313901901245,
  -0.35436901450157166, -0.3771734833717346, -0.3788403570652008,
  -0.3845670521259308, -0.3933921754360199, -0.3821650445461273,
  -0.3743131160736084, -0.383949875831604, -0.38348087668418884,
  -0.37146881222724915, -0.3631362020969391, -0.35925987362861633,
  -0.37003594636917114, -0.3845098614692688, -0.3720754384994507,
  -0.3581595718860626, -0.3749662935733795, -0.38689789175987244,
  -0.3790620267391205, -0.3871450126171112, -0.39844197034835815,
  -0.37634003162384033, -0.34431833028793335, -0.33906856179237366,
  -0.3532673418521881, -0.36047640442848206, -0.3462384045124054,
  -0.32140061259269714, -0.30749040842056274, -0.3054424226284027,
  -0.30064648389816284, -0.27614864706993103, -0.22295153141021729,
  -0.1735340654850006, -0.17224830389022827, -0.19794785976409912,
  -0.2011885941028595, -0.18424805998802185, -0.16913117468357086,
  -0.16100995242595673, -0.17787322402000427, -0.2344910055398941,
  -0.29762160778045654, -0.30893072485923767, -0.2462548315525055,
  -0.14953508973121643, -0.07788081467151642, -0.04614481329917908,
  -0.031026046723127365, -0.010741095058619976, 0.03145582601428032,
  0.09491074085235596, 0.1474047303199768, 0.1618492156267166,
  0.13515324890613556, 0.07201467454433441, 0.0011048595188185573,
  -0.03137930855154991, -0.01631256937980652, 0.01957789808511734,
  0.05312275141477585, 0.06909187883138657, 0.07158327102661133,
  0.08899383246898651, 0.13174957036972046, 0.1836378574371338,
  0.2367294728755951, 0.28192389011383057, 0.29700011014938354,
  0.28544554114341736, 0.2824528217315674, 0.30310678482055664,
  0.32713285088539124, 0.34141889214515686, 0.35630959272384644,
  0.3715936243534088, 0.3712327182292938, 0.3570011556148529,
  0.33989283442497253, 0.31690266728401184, 0.299312561750412,
  0.3101694583892822, 0.3339307904243469, 0.338411420583725,
  0.32704484462738037, 0.3186827600002289, 0.32808351516723633,
  0.3702085614204407, 0.43026214838027954, 0.4713480472564697,
  0.48948073387145996, 0.5010619163513184, 0.5044359564781189,
  0.49551132321357727, 0.47717660665512085, 0.4528616666793823,
  0.43386828899383545, 0.42426595091819763, 0.4131605625152588,
  0.3999527096748352, 0.38655388355255127, 0.3658738136291504,
  0.35016801953315735, 0.35590770840644836, 0.3706159293651581,
  0.38925865292549133, 0.4238586723804474, 0.452907532453537,
  0.4430316686630249, 0.4082243740558624, 0.38654854893684387,
  0.39131560921669006, 0.40754884481430054, 0.41072219610214233,
  0.39058956503868103, 0.3577629029750824, 0.32653552293777466,
  0.30727145075798035, 0.3038986325263977, 0.311078280210495,
  0.32536637783050537, 0.339904248714447, 0.33632558584213257,
  0.31623879075050354, 0.30438241362571716, 0.3037315607070923,
  0.29971352219581604, 0.28881680965423584, 0.26822665333747864,
  0.24164092540740967, 0.22470207512378693, 0.2119295299053192,
  0.19173799455165863, 0.1833418905735016, 0.19723914563655853,
  0.21373483538627625, 0.22419863939285278, 0.2246057391166687,
  0.20252424478530884, 0.17349743843078613, 0.16363748908042908,
  0.16532859206199646, 0.16476179659366608, 0.16156569123268127,
  0.1489352583885193, 0.12808555364608765, 0.11351870745420456,
  0.10713383555412292, 0.10720809549093246, 0.11445573717355728,
  0.11235874891281128, 0.08813448250293732, 0.05463807284832001,
  0.02665630169212818, 0.012323773466050625, 0.012972310185432434,
  0.010754605755209923, -0.002507955301553011, -0.003571429755538702,
  0.01819191686809063, 0.044597215950489044, 0.0637330636382103,
  0.07241353392601013, 0.0673111230134964, 0.05555804446339607,
  0.043978478759527206, 0.030209099873900414, 0.015362303704023361,
  -0.003965279553085566, -0.03701314330101013, -0.07241155952215195,
  -0.08778510987758636, -0.08202727884054184, -0.06673283129930496,
  -0.049831077456474304, -0.03908992558717728, -0.03600813448429108,
  -0.0332053042948246, -0.026867179200053215, -0.016136109828948975,
  -0.0004742806777358055, 0.010751915164291859, 0.006805488374084234,
  -0.005468141753226519, -0.0119193559512496, -0.010759574361145496,
  -0.0058584497310221195, 0.003176921745762229, 0.010925179347395897,
  0.005740299355238676, -0.011288260109722614, -0.027498681098222733,
  -0.03727385029196739, -0.04021155834197998, -0.03542656823992729,
  -0.02655419521033764, -0.012753219343721867, 0.012095045298337936,
  0.0388377346098423, 0.05533888563513756, 0.07194069772958755,
  0.0973265990614891, 0.12035961449146271, 0.13607412576675415,
  0.1442098170518875, 0.1337382048368454, 0.10925864428281784,
  0.09357865154743195, 0.0879305899143219, 0.07763677090406418,
  0.06593000143766403, 0.0658455416560173, 0.07911872863769531,
  0.09779559075832367, 0.1143624559044838, 0.1272537112236023,
  0.13430501520633698, 0.13293588161468506, 0.13539166748523712,
  0.1537581980228424, 0.17277435958385468, 0.17654938995838165,
  0.17296718060970306, 0.1644318550825119, 0.14855049550533295,
  0.1445615291595459, 0.1594700664281845, 0.1648223102092743,
  0.1465248316526413, 0.12208004295825958, 0.10286259651184082,
  0.08952698856592178, 0.08471120148897171, 0.08458112180233002,
  0.08451547473669052, 0.08791614323854446, 0.09136395901441574,
  0.08782447129487991, 0.08473194390535355, 0.09002228081226349,
  0.09698925167322159, 0.09848497062921524, 0.09081868082284927,
  0.06813868135213852, 0.03659607842564583, 0.011331724934279919,
  -0.008734461851418018, -0.02671930007636547, -0.027067800983786583,
  -0.011501067318022251, -0.011847891844809055, -0.03485759347677231,
  -0.05527786538004875, -0.06735603511333466, -0.07792183756828308,
  -0.0772259458899498, -0.06838946789503098, -0.07716285437345505,
  -0.1096576601266861, -0.14683212339878082, -0.17180925607681274,
  -0.1756560057401657, -0.16147196292877197, -0.14704276621341705,
  -0.14817194640636444, -0.1662033051252365, -0.1880139708518982,
  -0.19864632189273834, -0.20404216647148132, -0.21721187233924866,
  -0.22854512929916382, -0.23215393722057343, -0.24346092343330383,
  -0.2571115791797638, -0.25414109230041504, -0.24948501586914062,
  -0.26202914118766785, -0.27973073720932007, -0.292423814535141,
  -0.2981613874435425, -0.29224181175231934, -0.29420003294944763,
  -0.32539886236190796, -0.3599912226200104, -0.3692544102668762,
  -0.365317165851593, -0.35545045137405396, -0.3314085602760315,
  -0.31021982431411743, -0.3141660690307617, -0.3330760598182678,
  -0.34796810150146484, -0.3578360080718994, -0.36598706245422363,
  -0.3674637973308563, -0.36377766728401184, -0.3750213086605072,
  -0.4116528034210205, -0.44595205783843994, -0.4469090700149536,
  -0.422380656003952, -0.39837291836738586, -0.39091214537620544,
  -0.4065455496311188, -0.4334182143211365, -0.4440281391143799,
  -0.4293420910835266, -0.40993061661720276, -0.40990859270095825,
  -0.43018287420272827, -0.4443473517894745, -0.43332886695861816,
  -0.4183312654495239, -0.4268854856491089, -0.447388619184494,
  -0.4537787139415741, -0.44624748826026917, -0.4416230320930481,
  -0.4482171833515167, -0.46397271752357483, -0.4843709468841553,
  -0.49822843074798584, -0.48618000745773315, -0.45111775398254395,
  -0.43163707852363586, -0.4435523748397827, -0.4513113796710968,
  -0.4390851855278015, -0.4363669455051422, -0.44684475660324097,
  -0.44545960426330566, -0.44356581568717957, -0.46144503355026245,
  -0.47029247879981995, -0.4457148313522339, -0.4232926070690155,
  -0.43383121490478516, -0.44812753796577454, -0.43543657660484314,
  -0.41017383337020874, -0.3892078697681427, -0.3672483563423157,
  -0.3432813882827759, -0.3147298991680145, -0.26744556427001953,
  -0.21701163053512573, -0.20684482157230377, -0.23256734013557434,
  -0.23860156536102295, -0.20477008819580078, -0.16797074675559998,
  -0.16089990735054016, -0.18926239013671875, -0.25204822421073914,
  -0.32629913091659546, -0.3539985716342926, -0.3030571937561035,
  -0.2156735062599182, -0.14446213841438293, -0.0900777205824852,
  -0.04166562110185623, -0.001418374595232308, 0.04824863374233246,
  0.11848288029432297, 0.17676758766174316, 0.19706758856773376,
  0.18415333330631256, 0.13531848788261414, 0.06274814158678055,
  0.01576486974954605, 0.013356332667171955, 0.025883903726935387,
  0.03996270149946213, 0.06209871917963028, 0.08581887185573578,
  0.11201362311840057, 0.15519970655441284, 0.2153167873620987,
  0.27781492471694946, 0.32451683282852173, 0.3414398431777954,
  0.33961552381515503, 0.3456125855445862, 0.3663330376148224,
  0.39343175292015076, 0.42165014147758484, 0.44263139367103577,
  0.45055726170539856, 0.4512495994567871, 0.4446791112422943,
  0.4246828556060791, 0.40078824758529663, 0.38744211196899414,
  0.3813127279281616, 0.3739122152328491, 0.36902549862861633,
  0.3725660443305969, 0.38340458273887634, 0.40447455644607544,
  0.44417905807495117, 0.49762386083602905, 0.5458438992500305,
  0.580678403377533, 0.6070277690887451, 0.618816077709198, 0.6014952063560486,
  0.5589264631271362, 0.5143881440162659, 0.4865557849407196,
  0.4767805337905884, 0.4740098714828491, 0.4642522633075714,
  0.44039446115493774, 0.41079410910606384, 0.39603692293167114,
  0.4092094600200653, 0.4424179196357727, 0.476317822933197,
  0.49487221240997314, 0.49277612566947937, 0.4777023494243622,
  0.4604649245738983, 0.4461233615875244, 0.4405301511287689,
  0.44401538372039795, 0.4377531409263611, 0.40856871008872986,
  0.37420758605003357, 0.3520011305809021, 0.3340179920196533,
  0.317380428314209, 0.31613609194755554, 0.32950371503829956,
  0.33796778321266174, 0.33239296078681946, 0.31861239671707153,
  0.3020585775375366, 0.28760826587677, 0.2802911102771759, 0.2729456126689911,
  0.25197842717170715, 0.22080224752426147, 0.1932099461555481,
  0.17049942910671234, 0.15268857777118683, 0.15045921504497528,
  0.1633232831954956, 0.17437167465686798, 0.17266806960105896,
  0.15629030764102936, 0.12899258732795715, 0.10613548010587692,
  0.10053832828998566, 0.10703220218420029, 0.11243076622486115,
  0.10366231203079224, 0.07627476006746292, 0.04921114444732666,
  0.045092083513736725, 0.05960754677653313, 0.0741315558552742,
  0.07426836341619492, 0.05094687268137932, 0.011748473159968853,
  -0.023352496325969696, -0.047268714755773544, -0.06413953006267548,
  -0.07846718281507492, -0.0951339602470398, -0.10369215160608292,
  -0.08489066362380981, -0.048219818621873856, -0.017320184037089348,
  0.005635417066514492, 0.018831545487046242, 0.015590525232255459,
  0.01136067695915699, 0.014392029494047165, 0.001623123767785728,
  -0.034763872623443604, -0.07670533657073975, -0.11710888892412186,
  -0.15065106749534607, -0.16274067759513855, -0.1538078337907791,
  -0.13375669717788696, -0.10946277529001236, -0.09655042737722397,
  -0.10012185573577881, -0.09889426082372665, -0.08400024473667145,
  -0.06979324668645859, -0.0587877482175827, -0.04512898623943329,
  -0.03338966891169548, -0.02527523785829544, -0.01846989430487156,
  -0.01618357002735138, -0.014284081757068634, -0.002411162480711937,
  0.014599751681089401, 0.020639585331082344, 0.009344554506242275,
  -0.011587301269173622, -0.03215867653489113, -0.050067998468875885,
  -0.061573173850774765, -0.05356951430439949, -0.025420501828193665,
  0.004243931267410517, 0.029125532135367393, 0.05970540642738342,
  0.09872955083847046, 0.1434229463338852, 0.18574273586273193,
  0.20292839407920837, 0.1907735913991928, 0.1818762719631195,
  0.19141100347042084, 0.19637414813041687, 0.18498608469963074,
  0.16634494066238403, 0.14354528486728668, 0.12403249740600586,
  0.12514108419418335, 0.1508527398109436, 0.1899644434452057,
  0.2239362895488739, 0.23021431267261505, 0.2104141265153885,
  0.19907493889331818, 0.21753725409507751, 0.25146248936653137,
  0.2800155282020569, 0.2920016944408417, 0.28513067960739136,
  0.27110013365745544, 0.2653404474258423, 0.27036845684051514,
  0.27639660239219666, 0.26792314648628235, 0.23903945088386536,
  0.20660169422626495, 0.18605129420757294, 0.1709607094526291,
  0.15875136852264404, 0.15869998931884766, 0.16558554768562317,
  0.1684092879295349, 0.1716930866241455, 0.17606133222579956,
  0.17136862874031067, 0.16170558333396912, 0.15736503899097443,
  0.15024812519550323, 0.12864689528942108, 0.09619347006082535,
  0.06165266036987305, 0.03139611333608627, 0.013629894703626633,
  0.012852865271270275, 0.021935511380434036, 0.026498543098568916,
  0.01606886088848114, -0.007528793066740036, -0.03149140253663063,
  -0.04586734622716904, -0.05525800585746765, -0.07058553397655487,
  -0.09436212480068207, -0.12322652339935303, -0.15335234999656677,
  -0.17563168704509735, -0.1833004206418991, -0.18644732236862183,
  -0.19788900017738342, -0.21343128383159637, -0.22309333086013794,
  -0.22549498081207275, -0.22541525959968567, -0.2297438085079193,
  -0.2400701642036438, -0.2525046765804291, -0.2696904242038727,
  -0.28957390785217285, -0.29338353872299194, -0.28228560090065,
  -0.2872454822063446, -0.3122008144855499, -0.3342233896255493,
  -0.36116304993629456, -0.4062036871910095, -0.4377026855945587,
  -0.4309988021850586, -0.4159863591194153, -0.4230370819568634,
  -0.4394803047180176, -0.44200626015663147, -0.4269464910030365,
  -0.40394043922424316, -0.38517558574676514, -0.38043013215065,
  -0.3902164697647095, -0.4054976999759674, -0.42359766364097595,
  -0.4522898495197296, -0.4817315936088562, -0.4868259131908417,
  -0.4805642366409302, -0.5031739473342896, -0.5387904644012451,
  -0.5324733853340149, -0.495134174823761, -0.4850770831108093,
  -0.5006645917892456, -0.501356303691864, -0.4983516037464142,
  -0.5183382034301758, -0.5326722264289856, -0.5163306593894958,
  -0.5036278367042542, -0.5142551064491272, -0.5168237686157227,
  -0.5077810287475586, -0.5193416476249695, -0.5416338443756104,
  -0.5408831238746643, -0.5275986790657043, -0.5263032913208008,
  -0.5299015045166016, -0.5298453569412231, -0.5349517464637756,
  -0.5461806058883667, -0.5505897402763367, -0.5372143983840942,
  -0.5123962759971619, -0.5004286170005798, -0.5085402727127075,
  -0.5074132084846497, -0.48036107420921326, -0.45732423663139343,
  -0.4659298062324524, -0.48981112241744995, -0.4998262822628021,
  -0.489515095949173, -0.4662184417247772, -0.4369122385978699,
  -0.4097807705402374, -0.38174375891685486, -0.33125975728034973,
  -0.2636966109275818, -0.2311040461063385, -0.24673663079738617,
  -0.24754005670547485, -0.20897477865219116, -0.18848326802253723,
  -0.2033466100692749, -0.2143435925245285, -0.2410493642091751,
  -0.3192969560623169, -0.3807962238788605, -0.3461107313632965,
  -0.2592734694480896, -0.1939937323331833, -0.14039866626262665,
  -0.07579787075519562, -0.020961707457900047, 0.0223074983805418,
  0.08091170340776443, 0.1516747623682022, 0.20613904297351837,
  0.22906580567359924, 0.21492622792720795, 0.16788387298583984,
  0.11450803279876709, 0.08087997138500214, 0.06911934167146683,
  0.07287898659706116, 0.09005682170391083, 0.115375816822052,
  0.14123639464378357, 0.16682030260562897, 0.20201797783374786,
  0.25900596380233765, 0.3264564871788025, 0.37029802799224854,
  0.38448724150657654, 0.4054339826107025, 0.4497024714946747,
  0.4884488582611084, 0.5043445825576782, 0.5164691805839539,
  0.5351295471191406, 0.544328510761261, 0.5350480079650879, 0.5187562704086304,
  0.5072674751281738, 0.4985499382019043, 0.4810235798358917,
  0.4527851641178131, 0.4345703721046448, 0.4422808885574341,
  0.4577144682407379, 0.4623112976551056, 0.4746204912662506,
  0.5140408873558044, 0.5653544068336487, 0.609986424446106, 0.646892249584198,
  0.6703956723213196, 0.6715905070304871, 0.6557943820953369,
  0.6301470398902893, 0.5967069864273071, 0.5689177513122559, 0.559101939201355,
  0.5490922927856445, 0.5129144787788391, 0.4628680646419525,
  0.43276605010032654, 0.42820701003074646, 0.43695512413978577,
  0.4623170793056488, 0.49770793318748474, 0.5100803375244141,
  0.4907993972301483, 0.4673014283180237, 0.4505055546760559,
  0.43549367785453796, 0.4299331605434418, 0.4265846908092499,
  0.40427106618881226, 0.37404903769493103, 0.35465022921562195,
  0.3337830603122711, 0.3107289969921112, 0.3060784935951233,
  0.3101331889629364, 0.3033692538738251, 0.296933114528656,
  0.29136791825294495, 0.2663564383983612, 0.2323557734489441,
  0.20972447097301483, 0.18385225534439087, 0.1483316272497177,
  0.12704838812351227, 0.11829791963100433, 0.09719755500555038,
  0.07262257486581802, 0.06792142242193222, 0.07306455820798874,
  0.0699201375246048, 0.06428905576467514, 0.06176573038101196,
  0.05177874490618706, 0.03030550479888916, 0.00889969989657402,
  -0.0025632039178162813, -0.00693648774176836, -0.015190678648650646,
  -0.034308429807424545, -0.05637671798467636, -0.06456637382507324,
  -0.056038420647382736, -0.04916958510875702, -0.05894599109888077,
  -0.08001523464918137, -0.104197196662426, -0.13275755941867828,
  -0.16166934370994568, -0.18175823986530304, -0.19535990059375763,
  -0.20617198944091797, -0.20660075545310974, -0.1954093724489212,
  -0.17983925342559814, -0.15754954516887665, -0.1293533444404602,
  -0.1099017858505249, -0.10156294703483582, -0.0931776612997055,
  -0.08767501264810562, -0.0956513062119484, -0.1174236312508583,
  -0.14867110550403595, -0.18117624521255493, -0.20418629050254822,
  -0.2156946361064911, -0.21469581127166748, -0.20024201273918152,
  -0.19004423916339874, -0.19787289202213287, -0.20393219590187073,
  -0.19068683683872223, -0.16831359267234802, -0.14384828507900238,
  -0.11886660754680634, -0.10563740879297256, -0.10284549742937088,
  -0.09133244305849075, -0.06545877456665039, -0.030852172523736954,
  0.006168897729367018, 0.02756018564105034, 0.021415140479803085,
  0.005912880413234234, 0.00481035653501749, 0.017461314797401428,
  0.022348174825310707, 0.0016860051546245813, -0.031328245997428894,
  -0.04576636105775833, -0.03440232574939728, -0.004510709084570408,
  0.04656153544783592, 0.10268519073724747, 0.12910103797912598,
  0.13092613220214844, 0.14743149280548096, 0.1906379908323288,
  0.24278093874454498, 0.28477251529693604, 0.30073949694633484,
  0.290295273065567, 0.27366742491722107, 0.2659389078617096,
  0.2693575918674469, 0.2851671874523163, 0.3050130307674408,
  0.3102578818798065, 0.29587236046791077, 0.274026483297348,
  0.2568485736846924, 0.25060227513313293, 0.2565666437149048,
  0.27304908633232117, 0.29960817098617554, 0.3299117386341095,
  0.3479657471179962, 0.34929952025413513, 0.3507291376590729,
  0.36548393964767456, 0.3870280086994171, 0.4007895290851593,
  0.3960151672363281, 0.3713930547237396, 0.3368629217147827,
  0.3018401563167572, 0.26982277631759644, 0.24954846501350403,
  0.24617138504981995, 0.24237993359565735, 0.22023271024227142,
  0.1903928816318512, 0.1722131222486496, 0.1677626222372055,
  0.1698206216096878, 0.1681550294160843, 0.15126127004623413,
  0.12355213612318039, 0.10280992090702057, 0.09513448923826218,
  0.09573003649711609, 0.10086362808942795, 0.10119734704494476,
  0.08643005788326263, 0.06117526814341545, 0.03423402085900307,
  0.005205829627811909, -0.026050716638565063, -0.058211226016283035,
  -0.0941765084862709, -0.13045381009578705, -0.1577162742614746,
  -0.17794877290725708, -0.19807840883731842, -0.21432511508464813,
  -0.22424818575382233, -0.2334095537662506, -0.2379922717809677,
  -0.23042595386505127, -0.22436513006687164, -0.2398831993341446,
  -0.27001509070396423, -0.2934452295303345, -0.3059922456741333,
  -0.3143267035484314, -0.31856340169906616, -0.31916117668151855,
  -0.32427528500556946, -0.3410738408565521, -0.3692867159843445,
  -0.40223217010498047, -0.4306260347366333, -0.4487144649028778,
  -0.4588790237903595, -0.47116604447364807, -0.49259892106056213,
  -0.5135021805763245, -0.5194445252418518, -0.5153378248214722,
  -0.5118733048439026, -0.50115567445755, -0.478762686252594,
  -0.46329399943351746, -0.4673336148262024, -0.48168694972991943,
  -0.49741843342781067, -0.513295590877533, -0.5290669202804565,
  -0.5447067022323608, -0.5540849566459656, -0.5541468262672424,
  -0.563755214214325, -0.5935105085372925, -0.6122158169746399,
  -0.5965180397033691, -0.5767026543617249, -0.5832750797271729,
  -0.6007637977600098, -0.6082867980003357, -0.6081883311271667,
  -0.6019912958145142, -0.5899487137794495, -0.5853794813156128,
  -0.589309811592102, -0.580188512802124, -0.5564935207366943,
  -0.545036256313324, -0.5513129234313965, -0.5550340414047241,
  -0.553739070892334, -0.5549196004867554, -0.5478687286376953,
  -0.5356156229972839, -0.54804527759552, -0.5807086229324341,
  -0.5860846638679504, -0.5527488589286804, -0.5219184160232544,
  -0.5164827704429626, -0.5203173756599426, -0.5199426412582397,
  -0.5129020810127258, -0.49340805411338806, -0.46551254391670227,
  -0.44530120491981506, -0.4267444610595703, -0.37890374660491943,
  -0.30226433277130127, -0.24725507199764252, -0.23966988921165466,
  -0.23521623015403748, -0.1967906355857849, -0.15884317457675934,
  -0.15763425827026367, -0.17211298644542694, -0.1908908486366272,
  -0.2420402318239212, -0.3064331114292145, -0.3143528997898102,
  -0.26355308294296265, -0.21859851479530334, -0.18411435186862946,
  -0.11099873483181, -0.011700637638568878, 0.06174864247441292,
  0.11587051302194595, 0.17961232364177704, 0.23574697971343994,
  0.2694118320941925, 0.2949911653995514, 0.2962781488895416,
  0.24869191646575928, 0.19377999007701874, 0.18232226371765137,
  0.1912519782781601, 0.1882173717021942, 0.1957937479019165,
  0.23066681623458862, 0.27005860209465027, 0.30267322063446045,
  0.33875572681427, 0.3786754012107849, 0.4149256646633148, 0.44573062658309937,
  0.4784995913505554, 0.5241513252258301, 0.572100818157196, 0.5956771373748779,
  0.6026632189750671, 0.6254265904426575, 0.6570383906364441,
  0.6667094230651855, 0.6551708579063416, 0.6356518864631653,
  0.6088730096817017, 0.585361659526825, 0.5745079517364502, 0.5603090524673462,
  0.5368374586105347, 0.5291569828987122, 0.5501852631568909,
  0.5826692581176758, 0.6110439896583557, 0.6350535750389099,
  0.6588010787963867, 0.6837636232376099, 0.7041456699371338,
  0.7092728614807129, 0.6943559646606445, 0.6642663478851318,
  0.6302540898323059, 0.6034085750579834, 0.5845155715942383,
  0.5648581385612488, 0.5400240421295166, 0.5154737830162048,
  0.49854299426078796, 0.4888595640659332, 0.48014140129089355,
  0.4758833646774292, 0.48701778054237366, 0.503113329410553,
  0.49818041920661926, 0.4721377193927765, 0.4446503818035126,
  0.4177619218826294, 0.38670721650123596, 0.35867854952812195,
  0.3349228799343109, 0.3105417490005493, 0.2877187430858612,
  0.2612971365451813, 0.22583307325839996, 0.20150737464427948,
  0.2050098329782486, 0.218545600771904, 0.22300425171852112,
  0.21650579571723938, 0.19748884439468384, 0.17357929050922394,
  0.15705132484436035, 0.1347416490316391, 0.09452182054519653,
  0.05951163172721863, 0.04208800941705704, 0.01732332445681095,
  -0.023144520819187164, -0.05564269796013832, -0.06915181130170822,
  -0.07403507828712463, -0.08054963499307632, -0.09459362179040909,
  -0.11459535360336304, -0.13043370842933655, -0.13614021241664886,
  -0.135679692029953, -0.13635574281215668, -0.13952520489692688,
  -0.1377929002046585, -0.12973818182945251, -0.12536530196666718,
  -0.12571898102760315, -0.1253073811531067, -0.13575832545757294,
  -0.16593189537525177, -0.19817014038562775, -0.22214271128177643,
  -0.25316673517227173, -0.2953086793422699, -0.3299359977245331,
  -0.34215331077575684, -0.3304961025714874, -0.30613118410110474,
  -0.2858833074569702, -0.26967400312423706, -0.24386410415172577,
  -0.21072913706302643, -0.18424931168556213, -0.16819605231285095,
  -0.16207288205623627, -0.16625119745731354, -0.17535631358623505,
  -0.18819302320480347, -0.2098844200372696, -0.23213830590248108,
  -0.2385568618774414, -0.23139744997024536, -0.22685368359088898,
  -0.22925543785095215, -0.22806669771671295, -0.2157542109489441,
  -0.19824989140033722, -0.18457652628421783, -0.1733378916978836,
  -0.16008377075195312, -0.14671000838279724, -0.12934701144695282,
  -0.09646493941545486, -0.050198718905448914, -0.007499757222831249,
  0.020601466298103333, 0.03263068571686745, 0.033591095358133316,
  0.03828532621264458, 0.05561569333076477, 0.0725373700261116,
  0.0736393854022026, 0.06074028089642525, 0.04209233447909355,
  0.025976398959755898, 0.0274360328912735, 0.0607130266726017,
  0.12188302725553513, 0.18287305533885956, 0.2098337858915329,
  0.20363342761993408, 0.20679792761802673, 0.2465500682592392,
  0.3000181019306183, 0.3338553011417389, 0.3370060920715332,
  0.3120322823524475, 0.281314879655838, 0.2867755889892578,
  0.33797913789749146, 0.38719552755355835, 0.38836535811424255,
  0.35273611545562744, 0.3284941017627716, 0.345657616853714,
  0.3883177936077118, 0.415010929107666, 0.4053143858909607, 0.3784908950328827,
  0.36445096135139465, 0.37318360805511475, 0.3946388363838196,
  0.4129456579685211, 0.4188595712184906, 0.4140733778476715,
  0.40389204025268555, 0.39208704233169556, 0.383487343788147,
  0.3767676055431366, 0.3590632677078247, 0.32831859588623047,
  0.30699169635772705, 0.3091352880001068, 0.31446823477745056,
  0.3002207279205322, 0.27419883012771606, 0.2544390559196472,
  0.2409438043832779, 0.22407874464988708, 0.1997545212507248,
  0.16735558211803436, 0.1289883702993393, 0.09296300262212753,
  0.06708670407533646, 0.05140915885567665, 0.04096851870417595,
  0.030440455302596092, 0.016676677390933037, 0.001966694602742791,
  -0.007978033274412155, -0.012498175725340843, -0.020282793790102005,
  -0.04382307827472687, -0.08598428964614868, -0.1322794407606125,
  -0.16628284752368927, -0.185760498046875, -0.19541272521018982,
  -0.20407690107822418, -0.23090948164463043, -0.27835381031036377,
  -0.31230220198631287, -0.31274303793907166, -0.3093342185020447,
  -0.3284609615802765, -0.3579838275909424, -0.3840295672416687,
  -0.4043910801410675, -0.4102906286716461, -0.4012412130832672,
  -0.39552876353263855, -0.40177229046821594, -0.4111228585243225,
  -0.4184596836566925, -0.424586683511734, -0.4318024814128876,
  -0.45137855410575867, -0.4929961562156677, -0.5412291288375854,
  -0.5679113268852234, -0.5713552236557007, -0.5740944147109985,
  -0.5833419561386108, -0.5891903042793274, -0.5908198356628418,
  -0.5868834853172302, -0.5694183111190796, -0.5530862212181091,
  -0.5596404075622559, -0.5728861093521118, -0.571015477180481,
  -0.5720195174217224, -0.592156171798706, -0.6118144989013672,
  -0.6145601868629456, -0.6100331544876099, -0.6145428419113159,
  -0.6325904130935669, -0.6462937593460083, -0.6359351873397827,
  -0.6161532998085022, -0.6123864650726318, -0.6204637289047241,
  -0.6319745182991028, -0.6515389084815979, -0.6628506779670715,
  -0.6406715512275696, -0.5998545289039612, -0.5782483220100403,
  -0.5857800841331482, -0.6015727519989014, -0.6015219688415527,
  -0.5817184448242188, -0.56460040807724, -0.569455087184906,
  -0.5799780488014221, -0.5688780546188354, -0.5455965399742126,
  -0.5420835614204407, -0.5593602657318115, -0.5675864219665527,
  -0.5497756004333496, -0.5141484141349792, -0.4789053797721863,
  -0.4607846140861511, -0.4590972661972046, -0.45514434576034546,
  -0.43304672837257385, -0.3773217499256134, -0.28351500630378723,
  -0.20669980347156525, -0.21095357835292816, -0.24360300600528717,
  -0.19804421067237854, -0.09940994530916214, -0.06207140162587166,
  -0.10434717684984207, -0.1627764254808426, -0.21086934208869934,
  -0.24128609895706177, -0.23036207258701324, -0.19171440601348877,
  -0.1670982539653778, -0.1463633030653, -0.08750078827142715,
  0.006217991467565298, 0.1039828360080719, 0.19707398116588593,
  0.2788744568824768, 0.33116865158081055, 0.3568049669265747,
  0.371747761964798, 0.3685814142227173, 0.33709079027175903,
  0.2975909113883972, 0.27490633726119995, 0.26572078466415405,
  0.2549608647823334, 0.25136274099349976, 0.2846516966819763,
  0.35379913449287415, 0.40953007340431213, 0.4258679449558258,
  0.4415193796157837, 0.48847419023513794, 0.5443385243415833,
  0.5853880047798157, 0.6164734363555908, 0.6408225297927856,
  0.6564769148826599, 0.6734727621078491, 0.7017043232917786,
  0.7329948544502258, 0.747227132320404, 0.7333125472068787, 0.7008723020553589,
  0.6677820086479187, 0.6428364515304565, 0.6233679056167603,
  0.5992134809494019, 0.5683650970458984, 0.5514487028121948, 0.568004310131073,
  0.607714056968689, 0.6478606462478638, 0.6783868074417114, 0.6998761296272278,
  0.7178633213043213, 0.7328241467475891, 0.733769953250885, 0.7169690132141113,
  0.68878173828125, 0.6465752124786377, 0.5943410396575928, 0.5541026592254639,
  0.5291385054588318, 0.5027380585670471, 0.47886744141578674,
  0.4676273465156555, 0.4567583501338959, 0.44145190715789795,
  0.43660929799079895, 0.4436126947402954, 0.44893983006477356,
  0.44461339712142944, 0.42506542801856995, 0.3914463222026825,
  0.35990971326828003, 0.33898255228996277, 0.31572744250297546,
  0.2796415388584137, 0.23649778962135315, 0.19548223912715912,
  0.1632121354341507, 0.14460641145706177, 0.13482250273227692,
  0.12298461049795151, 0.11151056736707687, 0.11139801144599915,
  0.11542781442403793, 0.10678019374608994, 0.09009615331888199,
  0.07684757560491562, 0.056353118270635605, 0.019613806158304214,
  -0.016866689547896385, -0.04187716543674469, -0.06740643829107285,
  -0.09911194443702698, -0.1262296587228775, -0.14481915533542633,
  -0.16032974421977997, -0.17342828214168549, -0.18305720388889313,
  -0.19301271438598633, -0.2051481157541275, -0.21630962193012238,
  -0.22496183216571808, -0.23123446106910706, -0.2308446764945984,
  -0.2188423126935959, -0.19926679134368896, -0.1832086145877838,
  -0.17593985795974731, -0.17428694665431976, -0.17680421471595764,
  -0.18558377027511597, -0.20103894174098969, -0.22668102383613586,
  -0.26798921823501587, -0.31594642996788025, -0.35077744722366333,
  -0.36674824357032776, -0.37061241269111633, -0.36288318037986755,
  -0.34142637252807617, -0.3081762194633484, -0.26481959223747253,
  -0.2192290872335434, -0.18442276120185852, -0.16006498038768768,
  -0.1382402777671814, -0.12340909242630005, -0.12379438430070877,
  -0.13909055292606354, -0.1638006567955017, -0.18368396162986755,
  -0.1854461282491684, -0.1807618886232376, -0.1879068911075592,
  -0.19537705183029175, -0.18131248652935028, -0.1506292223930359,
  -0.12458460032939911, -0.11325971782207489, -0.10967282205820084,
  -0.0997920110821724, -0.07692387700080872, -0.04511310160160065,
  -0.011059176176786423, 0.017352961003780365, 0.036135170608758926,
  0.056762538850307465, 0.09350224584341049, 0.13868296146392822,
  0.167755126953125, 0.16434894502162933, 0.13839039206504822,
  0.12437603622674942, 0.1453869491815567, 0.1783963292837143,
  0.18502821028232574, 0.16510075330734253, 0.1469280868768692,
  0.15015923976898193, 0.186539426445961, 0.25694403052330017,
  0.3232348561286926, 0.33378177881240845, 0.298928827047348,
  0.2860267162322998, 0.3261960446834564, 0.3817746341228485,
  0.40891242027282715, 0.4004157483577728, 0.37609630823135376,
  0.3676880896091461, 0.3940199017524719, 0.4364491105079651,
  0.46233975887298584, 0.46312975883483887, 0.446089506149292,
  0.41970500349998474, 0.40555354952812195, 0.4207649528980255,
  0.43992504477500916, 0.42443129420280457, 0.38606324791908264,
  0.36793920397758484, 0.3776065707206726, 0.39102044701576233,
  0.3957478106021881, 0.3897072672843933, 0.3724607229232788,
  0.35638123750686646, 0.352247953414917, 0.3503674566745758,
  0.33846840262413025, 0.31532198190689087, 0.2855968177318573,
  0.26410096883773804, 0.2645643353462219, 0.27301540970802307,
  0.2602373957633972, 0.21733690798282623, 0.16258113086223602,
  0.12259890884160995, 0.10381285846233368, 0.08239720016717911,
  0.040536992251873016, -0.008058716543018818, -0.04485625401139259,
  -0.06426351517438889, -0.06533607095479965, -0.057195041328668594,
  -0.05584721267223358, -0.06640931218862534, -0.08721034228801727,
  -0.11260747909545898, -0.12678804993629456, -0.1277334839105606,
  -0.14170591533184052, -0.1863539069890976, -0.24573703110218048,
  -0.2893591821193695, -0.3016193211078644, -0.3009624779224396,
  -0.318945974111557, -0.3540995419025421, -0.3819675147533417,
  -0.3992050886154175, -0.41473907232284546, -0.4254932105541229,
  -0.4302293360233307, -0.43694832921028137, -0.4499971866607666,
  -0.46324846148490906, -0.46353209018707275, -0.4502057135105133,
  -0.4432237446308136, -0.4521508812904358, -0.4673057496547699,
  -0.48687052726745605, -0.5081696510314941, -0.5182892084121704,
  -0.5294275283813477, -0.565903902053833, -0.609100878238678,
  -0.6227920055389404, -0.6102169752120972, -0.5951446294784546,
  -0.5872374176979065, -0.5885621309280396, -0.5960787534713745,
  -0.5953462719917297, -0.5773950219154358, -0.5598792433738708,
  -0.5703550577163696, -0.6046807765960693, -0.6320454478263855,
  -0.6440016627311707, -0.6494181752204895, -0.6389853954315186,
  -0.6162638664245605, -0.6169896721839905, -0.6463625431060791,
  -0.6646660566329956, -0.6521316766738892, -0.6220260858535767,
  -0.5925850868225098, -0.5882156491279602, -0.6209845542907715,
  -0.658588707447052, -0.6565770506858826, -0.6163357496261597,
  -0.5814226269721985, -0.5801991820335388, -0.5938841104507446,
  -0.5991394519805908, -0.605329155921936, -0.6109928488731384,
  -0.589693009853363, -0.5559173822402954, -0.5547440052032471,
  -0.5777620673179626, -0.585179328918457, -0.5769492983818054,
  -0.5648499727249146, -0.5380553007125854, -0.4986483156681061,
  -0.46761298179626465, -0.4480893611907959, -0.4216068983078003,
  -0.3712965250015259, -0.30165722966194153, -0.24402299523353577,
  -0.22430665791034698, -0.22310853004455566, -0.19357630610466003,
  -0.12371636927127838, -0.06371108442544937, -0.07309184968471527,
  -0.14286723732948303, -0.20198185741901398, -0.20420338213443756,
  -0.16925080120563507, -0.13849234580993652, -0.12838543951511383,
  -0.1286531537771225, -0.11611990630626678, -0.064116470515728,
  0.040904272347688675, 0.17422866821289062, 0.28175416588783264,
  0.3365294635295868, 0.36252784729003906, 0.3882278501987457,
  0.41392070055007935, 0.4270658493041992, 0.4162795841693878,
  0.3821711838245392, 0.3459782898426056, 0.3226466178894043,
  0.3066060543060303, 0.3043306767940521, 0.33246564865112305,
  0.3800804913043976, 0.4246399998664856, 0.46455124020576477,
  0.5035597085952759, 0.5401859283447266, 0.5773220658302307,
  0.6120954751968384, 0.6399459838867188, 0.6707122921943665,
  0.7062572836875916, 0.730632483959198, 0.743450403213501, 0.7543987035751343,
  0.7527240514755249, 0.7311533093452454, 0.7063062787055969,
  0.6862714886665344, 0.6608670353889465, 0.6262952089309692,
  0.5876880288124084, 0.5553058981895447, 0.5449873805046082,
  0.5592379570007324, 0.5809433460235596, 0.5997447371482849,
  0.6229123473167419, 0.6545474529266357, 0.6799917817115784,
  0.6812694072723389, 0.6642821431159973, 0.6476851105690002,
  0.6279579401016235, 0.5916334986686707, 0.5465524792671204,
  0.5045710206031799, 0.46724191308021545, 0.4428909718990326,
  0.4310946464538574, 0.4109385311603546, 0.38423487544059753,
  0.3764083981513977, 0.38182851672172546, 0.37520748376846313,
  0.35748687386512756, 0.33737316727638245, 0.31200286746025085,
  0.28619492053985596, 0.26468655467033386, 0.23883186280727386,
  0.2082834243774414, 0.18118007481098175, 0.15070609748363495,
  0.11046963185071945, 0.07604454457759857, 0.06029042229056358,
  0.053791292011737823, 0.04768829792737961, 0.04451194033026695,
  0.03999271243810654, 0.026608314365148544, 0.013810830190777779,
  0.012975062243640423, 0.01397843100130558, 0.002820015186443925,
  -0.02029147930443287, -0.051983535289764404, -0.08629605174064636,
  -0.11242730170488358, -0.13247829675674438, -0.15729886293411255,
  -0.18329060077667236, -0.20237471163272858, -0.21626342833042145,
  -0.22500073909759521, -0.228363037109375, -0.23225298523902893,
  -0.2356235384941101, -0.23280273377895355, -0.22569426894187927,
  -0.21323226392269135, -0.19026482105255127, -0.16309979557991028,
  -0.14198468625545502, -0.12932679057121277, -0.12418695539236069,
  -0.12261325865983963, -0.12461583316326141, -0.14213337004184723,
  -0.17900550365447998, -0.22156256437301636, -0.25794464349746704,
  -0.27917104959487915, -0.2785969376564026, -0.26932287216186523,
  -0.26542654633522034, -0.2535232901573181, -0.21905644237995148,
  -0.16833467781543732, -0.11162231862545013, -0.06275681406259537,
  -0.03693521395325661, -0.027258340269327164, -0.01870935596525669,
  -0.015432298183441162, -0.021582666784524918, -0.028637846931815147,
  -0.03456805646419525, -0.04123926907777786, -0.04380243271589279,
  -0.03991193324327469, -0.029762187972664833, -0.012439487501978874,
  0.007017416879534721, 0.023328809067606926, 0.038355402648448944,
  0.05224890261888504, 0.06513034552335739, 0.08425946533679962,
  0.11186345666646957, 0.13724969327449799, 0.15117612481117249,
  0.15894882380962372, 0.17555199563503265, 0.20595021545886993,
  0.23745469748973846, 0.26085782051086426, 0.27707022428512573,
  0.27815499901771545, 0.2608790993690491, 0.25031912326812744,
  0.2685846984386444, 0.3029509484767914, 0.331227570772171,
  0.34289225935935974, 0.3327908515930176, 0.3115990459918976,
  0.3077954351902008, 0.3347996771335602, 0.37566664814949036,
  0.4033513069152832, 0.39914318919181824, 0.3678364157676697,
  0.3433148264884949, 0.35373976826667786, 0.38551101088523865,
  0.409096896648407, 0.4199838936328888, 0.42570552229881287,
  0.42535626888275146, 0.42625635862350464, 0.4413943886756897,
  0.45961469411849976, 0.45886746048927307, 0.4410727918148041,
  0.4206339418888092, 0.4017307758331299, 0.3836176097393036,
  0.3616543412208557, 0.33006227016448975, 0.30161309242248535,
  0.2949931025505066, 0.2994314730167389, 0.29005664587020874,
  0.26687249541282654, 0.24776382744312286, 0.2413441389799118,
  0.2432704120874405, 0.2438654750585556, 0.2355036437511444,
  0.21767489612102509, 0.19500744342803955, 0.17399311065673828,
  0.159182608127594, 0.14774997532367706, 0.13216958940029144,
  0.1056007593870163, 0.06329772621393204, 0.011791273020207882,
  -0.029478538781404495, -0.05217314511537552, -0.07116676867008209,
  -0.09980478137731552, -0.1309858113527298, -0.14997436106204987,
  -0.15369699895381927, -0.15274320542812347, -0.1568889617919922,
  -0.16667716205120087, -0.18126419186592102, -0.19894304871559143,
  -0.21344172954559326, -0.22657768428325653, -0.24855603277683258,
  -0.27719202637672424, -0.30385181307792664, -0.3294741213321686,
  -0.3523825407028198, -0.3686840236186981, -0.3849535584449768,
  -0.3978961706161499, -0.39504384994506836, -0.39378881454467773,
  -0.41918420791625977, -0.45191895961761475, -0.46321842074394226,
  -0.4617474377155304, -0.46244075894355774, -0.46350234746932983,
  -0.4651064872741699, -0.4666972756385803, -0.46561920642852783,
  -0.4737553596496582, -0.4969693422317505, -0.511938750743866,
  -0.5047199726104736, -0.5016390085220337, -0.5252527594566345,
  -0.5538085699081421, -0.5616597533226013, -0.5667160153388977,
  -0.5866336226463318, -0.5903136730194092, -0.561774492263794,
  -0.5472639799118042, -0.5660436749458313, -0.57059246301651,
  -0.5500586032867432, -0.5539345741271973, -0.5903478860855103,
  -0.6159675121307373, -0.6158686876296997, -0.6113673448562622,
  -0.6153404712677002, -0.6224986910820007, -0.6269790530204773,
  -0.6310871839523315, -0.6324002146720886, -0.6215035319328308,
  -0.6052749156951904, -0.5946082472801208, -0.5803452730178833,
  -0.5724514126777649, -0.5955002903938293, -0.6123170852661133,
  -0.579042375087738, -0.5499377846717834, -0.5740830898284912,
  -0.5957282185554504, -0.5842725038528442, -0.5859127044677734,
  -0.5922389030456543, -0.5609189867973328, -0.5312753915786743,
  -0.5480234026908875, -0.5743930339813232, -0.5754024982452393,
  -0.5570343732833862, -0.514600932598114, -0.4537055492401123,
  -0.4074992537498474, -0.37317565083503723, -0.3222152888774872,
  -0.27929025888442993, -0.2833678424358368, -0.2935551702976227,
  -0.23929354548454285, -0.13537278771400452, -0.06148646026849747,
  -0.06119270250201225, -0.11967002600431442, -0.19045838713645935,
  -0.21041451394557953, -0.15647560358047485, -0.09416431933641434,
  -0.0948234349489212, -0.13656878471374512, -0.15795373916625977,
  -0.14064276218414307, -0.07821065187454224, 0.039318930357694626,
  0.1677912324666977, 0.2400800883769989, 0.2668110430240631,
  0.30862411856651306, 0.37073734402656555, 0.41491034626960754,
  0.43286311626434326, 0.43526577949523926, 0.4162271022796631,
  0.37285304069519043, 0.32245951890945435, 0.28714025020599365,
  0.28260913491249084, 0.3093147575855255, 0.347057044506073,
  0.37655648589134216, 0.4019192159175873, 0.4382005035877228,
  0.4827073812484741, 0.5124772787094116, 0.5216273069381714, 0.539864718914032,
  0.5829048752784729, 0.6235560774803162, 0.6448089480400085,
  0.6611137390136719, 0.6736720204353333, 0.6750858426094055,
  0.6769732236862183, 0.6770130395889282, 0.6516342163085938,
  0.6006609201431274, 0.5406569838523865, 0.48213982582092285,
  0.4483030438423157, 0.453830748796463, 0.46914780139923096,
  0.47042587399482727, 0.47814658284187317, 0.5041272640228271,
  0.5312345623970032, 0.5542858242988586, 0.5749755501747131,
  0.5772280693054199, 0.551253080368042, 0.5079643130302429, 0.4576423168182373,
  0.4110775291919708, 0.386597603559494, 0.37953415513038635,
  0.35623830556869507, 0.3089599013328552, 0.27471137046813965,
  0.2776661813259125, 0.2995563745498657, 0.3147573173046112,
  0.30966436862945557, 0.27933770418167114, 0.2401546835899353,
  0.2181635946035385, 0.21113544702529907, 0.19783496856689453,
  0.17311620712280273, 0.139228954911232, 0.09067222476005554,
  0.03440288081765175, -0.007607376668602228, -0.028398333117365837,
  -0.03881565481424332, -0.04284348338842392, -0.03956415876746178,
  -0.03867259994149208, -0.04523841291666031, -0.04522800073027611,
  -0.031423211097717285, -0.01809771917760372, -0.01559483539313078,
  -0.020769523456692696, -0.03154052421450615, -0.047864388674497604,
  -0.07148966193199158, -0.10677889734506607, -0.14550401270389557,
  -0.17048880457878113, -0.18058444559574127, -0.18559667468070984,
  -0.19215692579746246, -0.20768548548221588, -0.22834992408752441,
  -0.23611006140708923, -0.22924360632896423, -0.21962521970272064,
  -0.2062302529811859, -0.18974120914936066, -0.1774722933769226,
  -0.15878178179264069, -0.1223912388086319, -0.0810934379696846,
  -0.048357781022787094, -0.02804391272366047, -0.0271714199334383,
  -0.04442431777715683, -0.06692906469106674, -0.08748944103717804,
  -0.10534367710351944, -0.12401791661977768, -0.1481272280216217,
  -0.16784580051898956, -0.16859744489192963, -0.14933989942073822,
  -0.11087490618228912, -0.05967259407043457, -0.019356979057192802,
  0.0030858309473842382, 0.026571234688162804, 0.05322235822677612,
  0.07184697687625885, 0.08736813068389893, 0.10155964642763138,
  0.1059325635433197, 0.10602401196956635, 0.11114883422851562,
  0.11966592073440552, 0.13315877318382263, 0.15215371549129486,
  0.1638905256986618, 0.15818533301353455, 0.14281857013702393,
  0.13546085357666016, 0.14247271418571472, 0.1514628529548645,
  0.15555186569690704, 0.16542264819145203, 0.1854591965675354,
  0.21174819767475128, 0.24584953486919403, 0.27802860736846924,
  0.28925585746765137, 0.2825159430503845, 0.2752353250980377,
  0.2777769863605499, 0.2942097783088684, 0.3109394907951355,
  0.3034982681274414, 0.27847641706466675, 0.26766037940979004,
  0.28311678767204285, 0.3129650056362152, 0.3413509130477905,
  0.3623782992362976, 0.3806580901145935, 0.38705551624298096,
  0.36595645546913147, 0.33622506260871887, 0.32441380620002747,
  0.3194397985935211, 0.308580607175827, 0.2967643141746521,
  0.27651625871658325, 0.25210732221603394, 0.251257061958313,
  0.2703956961631775, 0.2860237658023834, 0.30480971932411194,
  0.32857200503349304, 0.3321714997291565, 0.3140871822834015,
  0.2973112463951111, 0.2864902913570404, 0.2749072015285492,
  0.26581481099128723, 0.2627667784690857, 0.2558499574661255,
  0.2319359928369522, 0.2005813717842102, 0.18164879083633423,
  0.16677573323249817, 0.14210808277130127, 0.12110713124275208,
  0.10703244805335999, 0.08607460558414459, 0.06863922625780106,
  0.0641622543334961, 0.057126834988594055, 0.05266159772872925,
  0.06551341712474823, 0.07414153963327408, 0.0626966804265976,
  0.04934932664036751, 0.038803987205028534, 0.020377425476908684,
  -0.002929101698100567, -0.029989661648869514, -0.06355492770671844,
  -0.09215839952230453, -0.10734677314758301, -0.11775905638933182,
  -0.13241751492023468, -0.14714393019676208, -0.14996163547039032,
  -0.14489325881004333, -0.15428800880908966, -0.1780177354812622,
  -0.19410715997219086, -0.20721788704395294, -0.23418714106082916,
  -0.2635141611099243, -0.2809096574783325, -0.2890450358390808,
  -0.287871778011322, -0.28050294518470764, -0.2775839865207672,
  -0.2789018154144287, -0.2830718755722046, -0.29629093408584595,
  -0.3135099411010742, -0.32351112365722656, -0.3249867856502533,
  -0.3236940801143646, -0.3333650231361389, -0.3624271750450134,
  -0.3903254568576813, -0.3942278325557709, -0.38480669260025024,
  -0.38322076201438904, -0.39282700419425964, -0.4048008620738983,
  -0.41145095229148865, -0.41896650195121765, -0.43668806552886963,
  -0.45329412817955017, -0.45836469531059265, -0.46270039677619934,
  -0.4657794237136841, -0.45774510502815247, -0.45329028367996216,
  -0.4606824815273285, -0.4581487476825714, -0.4434465169906616,
  -0.44178569316864014, -0.4598928689956665, -0.4884278178215027,
  -0.5165334939956665, -0.5225902199745178, -0.5072356462478638,
  -0.5127438902854919, -0.5485947132110596, -0.5602931380271912,
  -0.5320588946342468, -0.52718186378479, -0.5659002065658569,
  -0.5787444710731506, -0.5394523739814758, -0.5138307809829712,
  -0.5339505672454834, -0.5600928664207458, -0.5745686292648315,
  -0.586920440196991, -0.5807385444641113, -0.5495150089263916,
  -0.5223055481910706, -0.5165003538131714, -0.5336326360702515,
  -0.5736980438232422, -0.5992866158485413, -0.5652201175689697,
  -0.5109634399414062, -0.513860821723938, -0.5559719800949097,
  -0.5610570311546326, -0.528952419757843, -0.5046340227127075,
  -0.47183656692504883, -0.3980906903743744, -0.3151790201663971,
  -0.2690812945365906, -0.2604450285434723, -0.2573384940624237,
  -0.21384009718894958, -0.12448235601186752, -0.07126381993293762,
  -0.11470895260572433, -0.18728893995285034, -0.20522281527519226,
  -0.18627199530601501, -0.17881685495376587, -0.1885744035243988,
  -0.20373249053955078, -0.21171697974205017, -0.1958998292684555,
  -0.13739365339279175, -0.026635024696588516, 0.09667868912220001,
  0.16555006802082062, 0.18937982618808746, 0.23771461844444275,
  0.31592807173728943, 0.37131229043006897, 0.3821042776107788,
  0.36271557211875916, 0.3407151997089386, 0.3350782096385956,
  0.32211366295814514, 0.28302201628685, 0.2622651755809784,
  0.28802600502967834, 0.31524336338043213, 0.317747563123703,
  0.33067062497138977, 0.3724929094314575, 0.4159489572048187,
  0.43477770686149597, 0.4344727694988251, 0.4469486176967621,
  0.4949055016040802, 0.5582637786865234, 0.5959676504135132,
  0.5971469283103943, 0.5913047790527344, 0.6022204756736755, 0.612324595451355,
  0.6024460196495056, 0.5895958542823792, 0.5743901133537292,
  0.5204862356185913, 0.4385119378566742, 0.39299464225769043,
  0.398834764957428, 0.41208580136299133, 0.40981388092041016,
  0.4013656675815582, 0.4020153284072876, 0.4254455268383026,
  0.4612140357494354, 0.481338232755661, 0.48551130294799805,
  0.48539867997169495, 0.4628349542617798, 0.4073677957057953,
  0.3534700572490692, 0.330644428730011, 0.32165059447288513,
  0.2962389290332794, 0.2514081597328186, 0.21129243075847626,
  0.20051832497119904, 0.21411575376987457, 0.2234433889389038,
  0.21698150038719177, 0.20742543041706085, 0.19908112287521362,
  0.1869455873966217, 0.17925897240638733, 0.17970174551010132,
  0.16666293144226074, 0.12619927525520325, 0.07870613783597946,
  0.0483124740421772, 0.03357267752289772, 0.01719564199447632,
  -0.01425467524677515, -0.054304338991642, -0.07839802652597427,
  -0.07758430391550064, -0.07200184464454651, -0.07323770970106125,
  -0.06749185174703598, -0.05084700509905815, -0.03885062783956528,
  -0.03412032499909401, -0.025822103023529053, -0.018024886026978493,
  -0.024082880467176437, -0.04361670836806297, -0.06584572046995163,
  -0.08039280772209167, -0.08155500888824463, -0.07834936678409576,
  -0.08929591625928879, -0.11715909838676453, -0.14817798137664795,
  -0.17240838706493378, -0.18305443227291107, -0.17317640781402588,
  -0.14979802072048187, -0.1302081197500229, -0.12062465399503708,
  -0.11242662370204926, -0.09000200778245926, -0.04411672428250313,
  0.009700928814709187, 0.04531770199537277, 0.061619602143764496,
  0.07159863412380219, 0.07045791298151016, 0.05411791428923607,
  0.04085436463356018, 0.03939482569694519, 0.03093811310827732,
  0.0063177309930324554, -0.013858303427696228, -0.009131599217653275,
  0.01606661267578602, 0.04146465286612511, 0.053894516080617905,
  0.061305902898311615, 0.08143921941518784, 0.1147458404302597,
  0.14364489912986755, 0.1595960259437561, 0.16851061582565308,
  0.17397236824035645, 0.17847490310668945, 0.19007983803749084,
  0.2098558098077774, 0.22663934528827667, 0.23140491545200348,
  0.22643670439720154, 0.22183358669281006, 0.22497962415218353,
  0.22906358540058136, 0.2215331792831421, 0.20585525035858154,
  0.19627048075199127, 0.19576945900917053, 0.1995958834886551,
  0.20862235128879547, 0.22415409982204437, 0.24113775789737701,
  0.25257235765457153, 0.2549489140510559, 0.2520782947540283,
  0.2516515552997589, 0.25212717056274414, 0.24362994730472565,
  0.2270583212375641, 0.21433387696743011, 0.20733223855495453,
  0.1987091600894928, 0.191719651222229, 0.1965794712305069,
  0.21364104747772217, 0.23562690615653992, 0.2536334991455078,
  0.25813430547714233, 0.25108325481414795, 0.24597790837287903,
  0.2435532510280609, 0.2317543774843216, 0.21157124638557434,
  0.19384793937206268, 0.18018756806850433, 0.1679132729768753,
  0.1573827564716339, 0.14785528182983398, 0.13966868817806244,
  0.13599181175231934, 0.13895027339458466, 0.14888127148151398,
  0.1598030924797058, 0.15922757983207703, 0.14635373651981354,
  0.13824723660945892, 0.1458485871553421, 0.15699921548366547,
  0.15273858606815338, 0.13316385447978973, 0.11398765444755554,
  0.10041942447423935, 0.08696887642145157, 0.07388975471258163,
];
