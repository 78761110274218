import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

import BaseModal from "@/components/BaseModal/BaseModal";

const DeleteConfirmationModal = ({
  showDeleteConfirmationModal,
  heading,
  children,
}: {
  showDeleteConfirmationModal: boolean;
  heading: string;
  children: React.ReactNode;
}) => {
  return (
    <BaseModal
      isModalOpen={showDeleteConfirmationModal}
      handelModalOpen={() => {}}
    >
      <div className="max-w-screen-sm w-[550px] bg-white p-6 pb-10 pt-8">
        <div className="mb-2 flex items-center">
          <ExclamationTriangleIcon
            className="h-8 mb-4"
            fill="#ef4444"
          />
          <p className="ml-2 mb-4 text-center text-2xl font-bold text-gray-900">
            {heading}
          </p>
        </div>

        {children}
      </div>
    </BaseModal>
  );
};

export default DeleteConfirmationModal;
