import { useQuery } from "@tanstack/react-query";

import { getEditorDrafts } from "@/api/requests";

export default function useGetEditorDrafts(userId: string, isFreePlan = false) {
  return useQuery({
    queryKey: ["editor-drafts", userId, isFreePlan],
    queryFn: () => getEditorDrafts(userId, isFreePlan),
    refetchOnWindowFocus: false,
    staleTime: 300000,
  });
}
