import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { clearLocalStorage } from "@/helpers";

type AmplitudeState = {
  homeScreen: any;
  reviewClipsScreen: any;
  editorScreen: any;
  downloadScreen: any;
  brandkitScreen: any;
  myTemplatesScreen: any;
};

const initialState: AmplitudeState = {
  homeScreen: null,
  reviewClipsScreen: null,
  editorScreen: null,
  downloadScreen: null,
  brandkitScreen: null,
  myTemplatesScreen: null,
};

const amplitudeSlice = createSlice({
  name: "amplitude-state",
  initialState,
  reducers: {
    setHomeAnalytics(state, action: PayloadAction<any>) {
      state.homeScreen = action.payload;
    },
    setReviewClipsAnalytics(state, action: PayloadAction<any>) {
      state.reviewClipsScreen = action.payload;
    },
    setEditorAnalytics(state, action: PayloadAction<any>) {
      state.editorScreen = action.payload;
    },
    setDownloadAnalytics(state, action: PayloadAction<any>) {
      state.downloadScreen = action.payload;
    },
    setBrandkitAnalytics(state, action: PayloadAction<any>) {
      state.brandkitScreen = action.payload;
    },
    setMyTemplatesAnalytics(state, action: PayloadAction<any>) {
      state.myTemplatesScreen = action.payload;
    },
  },
});

export const {
  setHomeAnalytics,
  setReviewClipsAnalytics,
  setEditorAnalytics,
  setDownloadAnalytics,
  setBrandkitAnalytics,
  setMyTemplatesAnalytics,
} = amplitudeSlice.actions;
export default amplitudeSlice;
