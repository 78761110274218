import * as React from "react";

import clsx from "clsx";

interface TimeFieldProps {
  time: string;
  handleTimeChange: (time: string) => string | undefined;
  className?: string;
  disabled?: boolean;
}

export const TimeField: React.FC<TimeFieldProps> = ({
  time: initialTime,
  handleTimeChange,
  className,
  disabled = false,
}) => {
  const [time, setTime] = React.useState<string>(initialTime);
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    setTime(initialTime);
  }, [initialTime]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const validFormat = /^[0-9:.]+$/;
    if (!e.target.value || validFormat.test(e.target.value)) {
      setTime(e.target.value);
    }
  };

  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.select();
    }
  };

  const onBlur = () => {
    // calcuate the time change
    const newTime = handleTimeChange(time);
    console.log({ newTime, time });
    if (newTime === undefined) {
      console.log("undefined", newTime, initialTime);
      setTime(initialTime);
      return;
    }
  };

  return (
    <input
      className={clsx(
        className,
        "border border-gray-300 px-2 py-1 m-0 text-sm font-light rounded w-24 focus:border-blue-500 focus:ring-blue-50",
        "disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500"
      )}
      type="text"
      id="time"
      onFocus={handleFocus}
      ref={inputRef}
      value={time}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
    />
  );
};
