import { useQuery } from "@tanstack/react-query";

import { getDraftById } from "@/api/requests";

import { FirebaseCollection } from "@/enums";

export default function useGetDraftById(
  userId: string,
  draftType = FirebaseCollection.EDITOR_DRAFTS,
  draftId: string,
  projectId: string,
  enabledQuery = false
) {
  return useQuery({
    queryKey: ["draft", userId, draftId],
    queryFn: () => getDraftById(userId, draftType, draftId, projectId),
    refetchOnWindowFocus: false,
    staleTime: 0,
    enabled: enabledQuery,
  });
}
