import { SVGProps, memo } from "react";

import clsx from "clsx";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    className={clsx(props.className, "fill-current")}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.81 9.999H21.2l.049.001H2.596l-.539-1.987a1.55 1.55 0 0 1 .155-1.2 1.575 1.575 0 0 1 .97-.74l15.228-4.02a1.593 1.593 0 0 1 1.942 1.113l.816 3a.8.8 0 0 1-.568.984L9.81 9.999ZM2.8 11v8.598a1.6 1.6 0 0 0 1.6 1.6h16a1.6 1.6 0 0 0 1.599-1.6V11H2.801Zm13.256-4.301L13.245 5.07l-5.89 1.555 2.813 1.628 5.89-1.555Zm-1.195 8.9a.363.363 0 0 1-.175.31l-3.325 2.035a.369.369 0 0 1-.56-.312v-4.068a.366.366 0 0 1 .374-.366.37.37 0 0 1 .186.055l3.325 2.033a.363.363 0 0 1 .175.312Z"
      clipRule="evenodd"
    />
  </svg>
);
const CutMagicIcon = memo(SvgComponent);
export default CutMagicIcon;
