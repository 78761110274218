import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  MinusCircleIcon,
  PlusCircleIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { Button, Card, Checkbox, TextInput } from "flowbite-react";

import { toggleAddMoreMinModal } from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { addMoreMinutes, createOneTimeCharge } from "@/api/requests";

import { COST_PER_ADD_ON_MINUTE, sleep } from "@/helpers";

import { trackAddMoreMinutesEvent } from "@/utils/amplitudeAnalytcs";

import { Country, RouterPath } from "@/enums";

import BaseModal from "@/components/BaseModal/BaseModal";
import Spinner from "@/components/Loader/Spinner";

const DEFAULT_QUANTITY = 100;
const MAX_QUANTITY = 10000;

const PROCESSING_PAYMENT_BTN_TEXT = "Processing payment";
const ADD_MINUTES_BTN_TEXT = "Pay & add minutes to balance";
const UPDATING_MINUTES_BTN_TEXT = "Updating subscription";

const AddMoreMinModal = ({ remainingMins }: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector((state) => state.authState.currentUser);
  const isPaddle = useAppSelector(
    (state) => state.authState.userSubscription.isPaddle
  );
  const paddleSubscription = useAppSelector(
    (state) => state.authState.paddleSubscription
  );
  const subscriptionId = useAppSelector(
    (state) => state.authState.userSubscription.subscriptionId
  );
  const isOpen = useAppSelector((state) => state.homeState.showAddMoreMinModal);
  const MIN_QUANTITY = isPaddle ? 40 : 1;

  //Stripe support
  const userCountry = useAppSelector((state) => state.authState.country);
  const isIndia = userCountry === Country.INDIA;

  const [quantity, setQuantity] = useState(DEFAULT_QUANTITY);
  const [isLoading, setIsLoading] = useState(false);
  const [allowAutoCharge, setAllowAutoCharge] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [processingBtnText, setProcessingBtnText] =
    useState(ADD_MINUTES_BTN_TEXT);
  const [pricePerQuantity, setPricePerQuantity] = useState(0.15);
  const [currency, setCurrency] = useState("USD");

  useEffect(() => {
    if (isPaddle && paddleSubscription?.last_payment) {
      const { last_payment } = paddleSubscription;
      if (last_payment) {
        setCurrency(last_payment.currency);
        setPricePerQuantity(COST_PER_ADD_ON_MINUTE[last_payment.currency]);
      }
    } else {
      setPricePerQuantity(
        isIndia ? COST_PER_ADD_ON_MINUTE["INR"] : COST_PER_ADD_ON_MINUTE["USD"]
      );
      setCurrency(isIndia ? "INR" : "USD");
    }
  }, [paddleSubscription]);

  const totalCost = (quantity * pricePerQuantity).toFixed(2);

  const onClose = () => {
    dispatch(toggleAddMoreMinModal(false));

    // reset modal state when modal is closed
    setQuantity(DEFAULT_QUANTITY);
    setAllowAutoCharge(false);
    setShowSuccessModal(false);
    setShowErrorModal(false);
    setIsLoading(false);
    setProcessingBtnText(ADD_MINUTES_BTN_TEXT);
  };

  const onConfirm = async () => {
    setIsLoading(true);
    setProcessingBtnText(PROCESSING_PAYMENT_BTN_TEXT);
    try {
      if (isPaddle && subscriptionId) {
        const { success }: any = await createOneTimeCharge(subscriptionId, {
          quantity,
          amount: totalCost,
          currency: paddleSubscription.last_payment.currency,
          charge_name: `${quantity} add on minutes`,
        });
        if (!success) {
          throw new Error("Payment failed");
        } else {
          trackAddMoreMinutesEvent(quantity, totalCost);
        }
      } else {
        const response: any = await addMoreMinutes({
          uid: currentUser.uid,
          email: currentUser.email,
          quantity,
          pricePerQuantity,
        });
        const { status, error } = response;
        if (status === "error") {
          throw new Error(error);
        } else {
          trackAddMoreMinutesEvent(quantity, totalCost);
        }
      }

      isOpen && setProcessingBtnText(UPDATING_MINUTES_BTN_TEXT);
      await sleep(20000); // wait for 20s to simulate payment processing for webhook to update user's minutes
      isOpen && setShowSuccessModal(true);
    } catch (err) {
      console.error(err);
      setShowErrorModal(true);
    }
    setIsLoading(false);
    setProcessingBtnText(ADD_MINUTES_BTN_TEXT);
  };

  const onChangePaymentMethod = () => {
    if (isPaddle && subscriptionId) {
      const Paddle = (window as any).Paddle;
      Paddle.Checkout.open({
        override: paddleSubscription.update_url,
      });
    } else {
      navigate(RouterPath.MANAGE_SUBSCRIPTION);
    }
  };

  const renderModalContent = () => {
    if (showErrorModal) {
      return (
        <>
          <XCircleIcon className="h-10 w-10 text-red-600 self-center mt-16 mb-12" />
          <div className="mb-5 text-2xl font-extrabold text-gray-900 self-center">
            Your payment did not go through
          </div>
          <div className="text-sm text-gray-500 text-center mb-16">
            Kindly update your payment method from <b>Manage Subscription</b>
            <br />
            or contact your bank for more details.
          </div>
          <div className="flex gap-5">
            <Button
              type="button"
              outline
              id="change-payment-method-btn"
              onClick={onChangePaymentMethod}
              fullSized
            >
              Change Payment Method
            </Button>
            <Button
              type="button"
              id="retry-payment-btn"
              onClick={() => {
                setShowErrorModal(false);
                onConfirm();
              }}
              fullSized
            >
              Retry Payment
            </Button>
          </div>
        </>
      );
    }

    if (showSuccessModal) {
      return (
        <>
          <CheckCircleIcon className="h-10 w-10 text-green-400 self-center mt-16 mb-12" />
          <div className="mb-5 text-2xl font-extrabold text-gray-900 self-center">
            {quantity} Minutes Added! 🎉
          </div>
          <div className="text-sm text-gray-500 text-center mb-16">
            Your payment method has been charged <b>{currency + totalCost}</b>
            <br />
            These minutes <b>do not expire or reset.</b>
          </div>
          <div className="flex gap-5">
            <Button
              type="button"
              id="change-payment-method-btn"
              onClick={onClose}
              fullSized
            >
              Done
            </Button>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="mb-3 text-2xl font-bold text-gray-900 flex justify-between items-center">
          Your minutes balance
        </div>
        <div className="text-sm text-gray-500">
          You have <b>{remainingMins}</b> minutes available.
          <br />
          Purchase more top-up minutes here, use forever
        </div>
        <Card className="mt-7">
          <div className="flex items-center justify-between">
            <div className="flex flex-col">
              <div className="font-medium text-gray-900">Minutes top-up</div>
              <div className="text-sm text-gray-500">
                Add mins @ {currency + pricePerQuantity} per min
              </div>
              <div className="text-sm text-gray-500">
                Valid until you cancel your subscription
              </div>
            </div>
            <div className="flex gap-2 items-center font-semibold select-none">
              <MinusCircleIcon
                className={`h-5 w-5 ${
                  quantity === MIN_QUANTITY
                    ? "text-gray-400 cursor-not-allowed"
                    : "text-blue-600 cursor-pointer"
                }`}
                onClick={() => {
                  if (quantity > MIN_QUANTITY) {
                    setQuantity(quantity - 1);
                  }
                }}
              />
              <TextInput
                type="text"
                id="number-of-minutes"
                value={quantity}
                onChange={(e) => {
                  // value is not a number
                  const value = parseInt(e.target.value);
                  if (isNaN(value)) {
                    setQuantity(MIN_QUANTITY);
                    return;
                  }

                  if (value > MAX_QUANTITY) {
                    setQuantity(MAX_QUANTITY);
                  } else if (value < MIN_QUANTITY) {
                    setQuantity(MIN_QUANTITY);
                  } else {
                    setQuantity(value);
                  }
                }}
                className="w-[4.5rem] text-center"
                pattern="[0-9]{5}"
              />
              <PlusCircleIcon
                className={`h-5 w-5 ${
                  quantity === MAX_QUANTITY
                    ? "text-gray-400 cursor-not-allowed"
                    : "text-blue-600 cursor-pointer"
                }`}
                onClick={() =>
                  quantity + 1 > MAX_QUANTITY ? null : setQuantity(quantity + 1)
                }
              />
            </div>
          </div>
          {isPaddle && (
            <div className="text-sm text-gray-500 select-none">
              *To complete your transaction, please note that the payment
              processor requires a{" "}
              <span className="underline">
                minimum payment of {currency + pricePerQuantity * MIN_QUANTITY}
              </span>
            </div>
          )}

          <hr className="mt-5" />
          <div className="flex items-center justify-between">
            <div className="font-medium text-gray-900">Total Cost</div>
            <div className="font-medium text-gray-900">
              {currency + totalCost}
            </div>
          </div>
          <div className="my-2">
            <Checkbox
              id="accept-auto-charge"
              onChange={(e) => setAllowAutoCharge(e.target.checked)}
              checked={allowAutoCharge}
            />
            <label
              htmlFor="accept-auto-charge"
              className="text-sm text-gray-500 ml-2 select-none"
            >
              I authorize audyo to auto-charge my payment method.
            </label>
          </div>
          <Button
            type="button"
            id="add-on-min-continue-btn"
            onClick={onConfirm}
            disabled={isLoading || !allowAutoCharge}
          >
            {isLoading && <Spinner className="h-4 w-4" />}
            {processingBtnText}
          </Button>
        </Card>
      </>
    );
  };

  return (
    <BaseModal
      isModalOpen={isOpen}
      handelModalOpen={() => {}}
    >
      <div className={`sm:w-[33rem] h-[${isPaddle ? 34 : 30}rem]`}>
        <XMarkIcon
          className="h-6 w-6 text-gray-400 cursor-pointer absolute top-7 right-7"
          onClick={onClose}
        />
        <div className="flex flex-col justify-center bg-white px-10 pt-7 pb-8">
          {renderModalContent()}
        </div>
      </div>
    </BaseModal>
  );
};

export default AddMoreMinModal;
