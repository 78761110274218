import { useState } from "react";

import { ampli } from "@/ampli";
import {
  createUserWithEmailAndPassword,
  deleteUser,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";
import { Button, Label, TextInput } from "flowbite-react";

import { LOGIN } from "@/constants";

import { saveValueToLocalStorage } from "@/helpers";

import { trackSignupEvent } from "@/utils/amplitudeAnalytcs";
import { notificationType } from "@/utils/constants";
import { auth } from "@/utils/firebase";
import { showNotification } from "@/utils/showNotification";

import {
  validateEmail,
  validateName,
  validatePassword,
} from "@/views/auth/validator";

const HELPER_COMPONENT = "HELPER_COMPONENT";

const SignUpPage = ({
  updateCurrentTab,
  updateHelperComponentDetails,
}: {
  updateCurrentTab: (value: string) => void;
  updateHelperComponentDetails: (headline: string, message: string) => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const actionCodeSettings = {
    url: `${window.location.origin}/auth/login?isNewUser=${true}`,
    handleCodeInApp: true,
  };

  const handelSubmitForm = (e: any) => {
    e.preventDefault();
    const userEmailId = e.target.email.value.trim();
    const userPassword = e.target.password.value.trim();
    const userDisplayName = e.target.fullName.value.trim();
    if (
      validateName(userDisplayName, setNameError) &&
      validateEmail(userEmailId, setEmailError, true) &&
      validatePassword(userPassword, setPasswordError)
    ) {
      setIsLoading(true);

      createUserWithEmailAndPassword(auth, userEmailId, userPassword)
        .then(({ user }) => {
          updateProfile(user, { displayName: userDisplayName })
            .then(() => {
              sendEmailVerification(user, actionCodeSettings)
                .then(() => {
                  saveValueToLocalStorage("userEmail", userEmailId);
                  setIsLoading(false);
                  updateHelperComponentDetails(
                    "Please confirm your email! ✅",
                    "Please click on the link of the email we have sent to you to confirm your account and start using vidyo.ai ✨"
                  );
                  updateCurrentTab(HELPER_COMPONENT);
                  trackSignupEvent(user);
                })
                .catch((err) => {
                  // if email verification fails
                  // deleting the user object created in firebase, (user is not saved in database yet, so it can be deleted here)
                  // so that user can retry signup, else it will show that email already registered login again

                  showNotification(
                    "Failed to create your account, please try again shortly",
                    notificationType.FAIL
                  );
                  e.target.email.value = "";
                  e.target.password.value = "";
                  e.target.fullName.value = "";
                  setIsLoading(false);
                  deleteUser(user);
                  console.error("Failed to send email verification: ", err);
                });
            })
            .catch((error) => {
              showNotification(
                "Failed to save your name",
                notificationType.FAIL
              );
            });
        })
        .catch((error) => {
          setIsLoading(false);
          switch (error.code) {
            case "auth/email-already-in-use":
              showNotification(
                "Email already registered, please Sign-in",
                notificationType.FAIL
              );
              break;

            default:
              showNotification(
                "Error while registering. Please try again.",
                notificationType.FAIL
              );
          }
          setIsLoading(false);
        });
    }
  };

  const handleEmailChangeValidation = (e: any) => {
    validateEmail(e.target.value, setEmailError);
  };

  const handleNameChange = (e: any) => {
    validateName(e.target.value.trim(), setNameError);
  };

  const handlePasswordChangeValidation = (e: any) => {
    const password = e.target.value;
    if (!password.length) {
      setPasswordError("*Required");
    }
    if (password.length < 8) {
      setPasswordError("Password must be 8 characters long");
    } else {
      setPasswordError("");
    }
  };

  return (
    <form onSubmit={handelSubmitForm}>
      <h1 className="text-lg xl:text-2xl font-semibold text-gray-700 dark:text-white pt-7 xl:pb-10 py-3">
        Sign up with your email
      </h1>

      <div className="flex flex-col xl:gap-5 xl:mb-7 gap-3 mb-5">
        <div className="">
          <Label
            htmlFor="fullName"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Full Name <span className="text-red-600">*</span>
          </Label>

          <div className="w-full relative">
            <TextInput
              name="fullName"
              id="fullName"
              placeholder="Enter your name"
              type="text"
              onChange={handleNameChange}
              className=" text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              color={`${nameError && "failure"}`}
              helperText={
                <span style={{ marginTop: "-10px" }}>{<>{nameError}</>}</span>
              }
            />
          </div>
        </div>

        <div className="">
          <Label
            htmlFor="email"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Email <span className="text-red-600">*</span>
          </Label>
          <TextInput
            type="email"
            name="email"
            id="email"
            onChange={handleEmailChangeValidation}
            className=" text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
            placeholder="Enter your email"
            color={`${emailError && "failure"}`}
            helperText={
              <span style={{ marginTop: "-10px" }}>
                {emailError && <>{emailError}</>}
              </span>
            }
          />
        </div>

        <div className="">
          <Label
            htmlFor="password"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Password <span className="text-red-600">*</span>
          </Label>

          <div className="w-full relative">
            <TextInput
              type="password"
              name="password"
              id="password"
              placeholder="Enter your password"
              onChange={handlePasswordChangeValidation}
              className=" text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              color={`${passwordError && "failure"}`}
              helperText={
                <span style={{ marginTop: "-10px" }}>
                  {passwordError && <>{passwordError}</>}
                </span>
              }
            />
          </div>
        </div>
      </div>

      <Button
        type={isLoading ? "button" : "submit"}
        id="sign-up-button"
        className={`w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 ${
          isLoading ? "cursor-wait opacity-70" : ""
        }`}
      >
        {isLoading ? (
          <div
            role="status"
            className="flex mx-auto relative w-max"
          >
            <svg
              aria-hidden="true"
              className="mr-2 w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">processing...</span>
            <span>Sign up</span>
          </div>
        ) : (
          <span>Sign up</span>
        )}
      </Button>
      <div className="flex xl:py-6 py-4">
        <p
          className="text-left font-lighttext-xs w-2/3 text-xs"
          style={{ color: "#6B7280" }}
        >
          By signing up, you agree with our{" "}
          <a
            href="https://vidyo.ai/terms-of-service"
            className="hover:underline cursor-pointer"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#1C64F2" }}
          >
            terms & conditions
          </a>
        </p>
        <p
          className="text-right text-xs w-1/3 underline cursor-pointer"
          style={{ color: "#1C64F2" }}
          onClick={() => updateCurrentTab(LOGIN)}
        >
          Sign in instead
        </p>
      </div>
    </form>
  );
};

export default SignUpPage;
