import { useMutation } from "@tanstack/react-query";

import api from "@/api/api";
import { ApiEndpoints } from "@/api/constants/ApiEndPoints";

const redirectToManageSubscription = async (body: any) => {
  const { data } = await api.post(ApiEndpoints.MANAGE_SUBSCRIPTION, body);
  return data;
};

export default function useManageSubscriptionWithStripe() {
  return useMutation((body: any) => redirectToManageSubscription(body), {
    onSuccess: (redirectUrl: any) => {
      window.location.replace(redirectUrl);
    },
    retryDelay: 2000,
    retry: 1,
  });
}
