import { useState } from "react";

import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { updateEmail } from "firebase/auth";
import { Button, Label, TextInput } from "flowbite-react";

import { checkMainDBLogin } from "@/utils/FirebaseHelpers";
import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

import BaseModal from "@/components/BaseModal/BaseModal";
import Spinner from "@/components/Loader/Spinner";

import { validateEmail } from "@/views/auth/validator";

const CustomEmailModal = ({
  setDisplayModal,
  firebaseUserData,
  user,
  displayModal,
}: {
  setDisplayModal: (value: boolean) => void;
  firebaseUserData: any;
  displayModal: boolean;
  user: any;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [email, setEmail] = useState("");

  const handleEmailChangeValidation = (e: any) => {
    setEmail(e.target.value);
    validateEmail(email, setEmailError);
  };

  const formSubmitForEmail = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    if (validateEmail(email, setEmailError)) {
      firebaseUserData.email = email;

      user &&
        updateEmail(user, email)
          .then(() => {
            setIsLoading(false);
            setDisplayModal(false);
            checkMainDBLogin(firebaseUserData, setIsLoading);
          })
          .catch((error) => {
            setIsLoading(false);
            if (error.code === "auth/email-already-in-use") {
              showNotification(
                "This email is already in use with another account",
                notificationType.FAIL
              );
            } else {
              showNotification(
                "Something went wrong, please try again in sometime",
                notificationType.FAIL
              );
            }
          });
    } else {
      setIsLoading(false);
      setEmailError("Invalid email address");
    }
  };

  const buttonDisable = () => {
    if (email.length) return false;
    return true;
  };

  return (
    <BaseModal
      isModalOpen={displayModal}
      handelModalOpen={() => {}}
    >
      <form
        className="px-8 py-12 w-96"
        onSubmit={formSubmitForEmail}
      >
        <h1 className="font-semibold font-2xl">Enter your email</h1>

        <div className="w-full flex mt-2 mb-4">
          <InformationCircleIcon className="mr-1.5 h-7 w-7 text-gray-600" />
          <div
            className="text-left ml-1.5 text-sm"
            style={{ color: "#6B7280" }}
          >
            <p>We cannot find your email, please enter it manually</p>
          </div>
        </div>

        <div className="mt-6">
          <div>
            <Label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Email
            </Label>
            {emailError ? (
              <TextInput
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={handleEmailChangeValidation}
                className="bg-red-50 border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full dark:text-red-500 dark:placeholder-red-500 dark:border-red-500"
                placeholder="Enter your email"
              />
            ) : (
              <TextInput
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={handleEmailChangeValidation}
                className=" text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Enter your email"
              />
            )}
            <p className="mt-2 text-sm text-red-600 dark:text-red-500">
              {emailError}
            </p>
          </div>
        </div>
        <div className="flex gap-10 mt-6 flex-row-reverse">
          <Button
            disabled={buttonDisable()}
            type={isLoading ? "button" : "submit"}
            id="submit-custom-email-btn"
            className={`flex-1 text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800  ${
              isLoading ? "cursor-wait opacity-70" : ""
            }`}
          >
            {isLoading ? (
              <Spinner className="h-4 w-4" />
            ) : (
              <span>Continue</span>
            )}
          </Button>
          <Button
            color="light"
            className="flex-1 text-center text-blue-600 cursor-pointer"
            onClick={() => setDisplayModal(false)}
            id="cancel-custom-email-btn"
          >
            Cancel
          </Button>
        </div>
      </form>
    </BaseModal>
  );
};
export default CustomEmailModal;
