import { store } from "@/store";
import { updateSelectedDraft } from "@/store/draftSlice";
import {
  changeSelectedLayout,
  setSubtitles,
  toggleSceneChange,
  updateAllSceneChanges,
  updateAutoAddEmojisToSubtitles,
} from "@/store/editorSlice";
import { updateCurrentSelectedProject } from "@/store/homeSlice";

import { EditorDraft } from "@/interfaces";

export const applyCurrentDraft = (draftData: EditorDraft) => {
  store.dispatch(changeSelectedLayout(draftData.aspectRatio));
  store.dispatch(
    updateSelectedDraft({ ...draftData, fromDownloadPage: false })
  );
  store.dispatch(setSubtitles(draftData.subtitleOption));
  draftData?.stayOnEditor ||
    store.dispatch(updateCurrentSelectedProject(draftData.project));
  store.dispatch(updateAllSceneChanges(draftData.allSceneChanges));
  store.dispatch(toggleSceneChange(draftData.allSceneChanges.length > 0));
  store.dispatch(
    updateAutoAddEmojisToSubtitles(draftData.autoAddEmojisToSubtitles)
  );
};
