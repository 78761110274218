import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteField, doc, updateDoc } from "firebase/firestore";

import { notificationType } from "@/utils/constants";
import { DB } from "@/utils/firebase";
import { showNotification } from "@/utils/showNotification";

type DeleteTemplateMutationType = {
  userId: string;
  templateId: string;
};

const deleteProject = async ({
  userId,
  templateId,
}: DeleteTemplateMutationType) => {
  const docRef = doc(DB, "userTemplate", userId);

  const data = await updateDoc(docRef, {
    [templateId]: deleteField(),
  });

  return data;
};

export default function useDeleteUserTemplate() {
  const queryClient = useQueryClient();

  return useMutation(
    (data: DeleteTemplateMutationType) => deleteProject(data),
    {
      onSuccess: () => {
        showNotification(
          "Template deleted successfully",
          notificationType.SUCCESS
        );

        queryClient.invalidateQueries({ queryKey: ["all-projects"] });
        queryClient.invalidateQueries({ queryKey: ["user-template"] });
        queryClient.invalidateQueries({ queryKey: ["micro-content-data"] });
      },
      onError: () => {
        showNotification(
          "Something went wrong, please try again!",
          notificationType.FAIL
        );
      },
    }
  );
}
