import { UseQueryResult, useQuery } from "@tanstack/react-query";

import api from "@/api/api";
import { ApiEndpoints } from "@/api/constants/ApiEndPoints";

import { Downloads } from "@/interfaces";

import { DownloadedClipType, VideoLayout } from "@/enums";

const parseData = (data: any): Downloads => {
  const downloads: Downloads = {
    [DownloadedClipType.SQUARE]: [],
    [DownloadedClipType.PORTRAIT]: [],
    [DownloadedClipType.LANDSCAPE]: [],
  };

  data.forEach((item: any) => {
    const {
      renderedVideoUrl,
      createdAt,
      aspect_ratio,
      projectId,
      status,
      filename,
      render_task_id,
      clip_start,
      clip_end,
      clip_id,
    } = item;
    const transformedItem = {
      renderedVideoUrl,
      createdAt,
      projectId,
      status,
      filename,
      render_task_id,
      clip_start,
      clip_end,
      clip_id,
    };

    switch (aspect_ratio) {
      case VideoLayout.LAYOUT_9_16_1:
      case VideoLayout.LAYOUT_9_16_2:
        downloads[DownloadedClipType.PORTRAIT].push(transformedItem);
        break;
      case VideoLayout.LAYOUT_16_9:
        downloads[DownloadedClipType.LANDSCAPE].push(transformedItem);
        break;
      case VideoLayout.LAYOUT_1_1:
        downloads[DownloadedClipType.SQUARE].push(transformedItem);
        break;
      default:
        break;
    }
  });
  return downloads;
};

const getDownloads = async () => {
  const data = await api.get(ApiEndpoints.DOWNLOADS_LIST);
  return parseData(data.data);
};

export default function useGetDownloads(): UseQueryResult<Downloads> {
  return useQuery({
    queryKey: ["all-downloads"],
    queryFn: () => getDownloads(),
    refetchOnWindowFocus: false,
  });
}
