import clsx from "clsx";

import { useAppSelector } from "@/store/hooks";

import { getColonSeparatedTimeWithMillis } from "@/helpers";

import { DEFAULT_COLON_SEPARATED_TIME_IN_MILLIS } from "../../constant";

export const VideoPlayTime = ({
  outroTimeLeft,
  videoElRef,
  isMainCanvasLoaded,
}: any) => {
  const editorCurrentVideoTime = useAppSelector(
    (state) => state.editorState.editorCurrentVideoTime
  );
  const currentSelectedMicroContent = useAppSelector(
    (state) => state.homeState.currentSelectedMicroContent
  );

  // to give constant width to each character, we have to do this.
  const beforeSlashItems = !isMainCanvasLoaded
    ? DEFAULT_COLON_SEPARATED_TIME_IN_MILLIS
    : getColonSeparatedTimeWithMillis(
        editorCurrentVideoTime * 1000 - currentSelectedMicroContent.start
      );

  const afterSlashItems = getColonSeparatedTimeWithMillis(
    currentSelectedMicroContent.end - currentSelectedMicroContent.start
  );

  const timeItemsMapper = (i: string, index: number) => (
    <span
      key={`${i}-${index}`}
      className={clsx("block", ![":", "."].includes(i) && "w-2")}
    >
      {i}
    </span>
  );

  return (
    <div
      className={clsx(
        "flex rounded-md  bg-gray-100 p-1.5 text-xs font-bold",
        outroTimeLeft <= 0 && !videoElRef.current?.paused
          ? "border-blue-500"
          : "border-gray-200",
        "text-slate-600 font-normal text-center"
      )}
    >
      <div className="flex mx-2 mr-1">
        {beforeSlashItems.split("").map(timeItemsMapper)}
      </div>
      /
      <div className="flex mx-2 ml-1">
        {afterSlashItems.split("").map(timeItemsMapper)}
      </div>
    </div>
  );
};

export default VideoPlayTime;
