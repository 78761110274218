import { Fragment, ReactNode, useRef } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { clsx } from "clsx";

type BaseModelProps = {
  children: ReactNode;
  isModalOpen: boolean;
  handelModalOpen?: any;
  notClosable?: boolean;
  isFullScreenLoader?: boolean;
  isDarkTheme?: boolean;
};

export default function BaseModal({
  children,
  isModalOpen,
  handelModalOpen,
  notClosable = false,
  isFullScreenLoader = false,
  isDarkTheme = false,
}: BaseModelProps) {
  const cancelButtonRef = useRef(null);

  if (notClosable) {
    return (
      <Transition.Root
        appear={true}
        show={isModalOpen}
        as={Fragment}
      >
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={() => {}}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-700 bg-opacity-80 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 mx-4 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className={clsx(
                    "relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-8",
                    isDarkTheme ? "bg-halloween-gray" : "bg-white"
                  )}
                >
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }

  return (
    <Transition.Root
      show={isModalOpen}
      as={Fragment}
      appear={true}
    >
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={handelModalOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-700 bg-opacity-80 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 mx-4 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={clsx(
                  isFullScreenLoader
                    ? "bg-transparent shadow-none"
                    : "relative bg-white transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-8",
                  isDarkTheme && "!bg-halloween-gray"
                )}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
