import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  doc, // enableIndexedDbPersistence,
  enableMultiTabIndexedDbPersistence,
  getFirestore,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";

import { isProd } from "@/helpers";

import { PlanType, SubscriptionIntervalInMonths } from "@/enums";

const stageFirebaseConfig = {
  apiKey: "AIzaSyDddt8WRl-yybHaTos1O69GXZO3K3YezW0",
  authDomain: "vidyo-ai-poc.firebaseapp.com",
  databaseURL:
    "https://vidyo-ai-poc-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "vidyo-ai-poc",
  storageBucket: "vidyo-ai-poc.appspot.com",
  messagingSenderId: "368192008638",
  appId: "1:368192008638:web:a265de5c0113bb999f89ef",
  measurementId: "G-Q9HBRS0X45",
};

const firebaseConfig = {
  apiKey: "AIzaSyB1GkN3JdZdEGbjrb-mMzVemFkjfMufRVo",
  authDomain: "vidyoai.firebaseapp.com",
  projectId: "vidyoai",
  storageBucket: "vidyoai.appspot.com",
  messagingSenderId: "496499201626",
  appId: "1:496499201626:web:492b9f871b46b64a57ea63",
  measurementId: "G-MBJ2EYFS45",
};

let app;
let database;

app = initializeApp(isProd() ? firebaseConfig : stageFirebaseConfig);
database = getFirestore(app);

enableMultiTabIndexedDbPersistence(database).catch((err) => {
  console.warn(err);
});

// enableMultiTabIndexedDbPersistence(firestore)

export const DB = database;

export const auth = getAuth(app);

const FREE_USER_ALLOWED_MINUTES = 75;

export const updateUserSubscription = async (uid: string) => {
  const updatedSubscriptionData = {
    subscriptionStartedAt: serverTimestamp(),
    allowedMinutes: FREE_USER_ALLOWED_MINUTES,
    intervalInMonths: SubscriptionIntervalInMonths.MONTHLY,
    planType: PlanType.FREE,
    cancelOn: null,
    isPaddle: false,
    nextBillDate: null,
    subscriptionId: null,
  };
  console.log("updateUserSubscription", uid, updatedSubscriptionData);
  await setDoc(doc(DB, "subscriptions", uid), updatedSubscriptionData, {
    merge: true,
  });
};
