import { useCallback, useRef, useState } from "react";
import { usePopper } from "react-popper";

import { TooltipProps } from "@/interfaces";

const Tooltip: React.FC<TooltipProps> = (props) => {
  const {
    children,
    label,
    hideTooltip = false,
    enterDelay = 150,
    leaveDelay = 250,
    placement = "top-start",
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  let [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(
    null
  );
  let [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [{ name: "offset", options: { offset: [0, 6] } }],
  });

  let enterTimeout = useRef<any>();
  let leaveTimeout = useRef<any>();

  const handleMouseEnter = useCallback(() => {
    leaveTimeout.current && clearTimeout(leaveTimeout.current);
    enterTimeout.current = setTimeout(() => setIsOpen(true), enterDelay);
  }, [enterDelay]);

  const handleMouseLeave = useCallback(() => {
    enterTimeout.current && clearTimeout(enterTimeout.current);
    leaveTimeout.current = setTimeout(() => setIsOpen(false), leaveDelay);
  }, [leaveDelay]);

  return (
    <>
      <div
        ref={setReferenceElement}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="relative self-center"
      >
        {children}
        <div
          id="arrow"
          className={`h-3 w-3 rotate-45 bg-gray-900 ${
            isOpen ? "visible" : "hidden"
          } left-1.5 before:visible before:absolute before:content-none`}
          data-popper-arrow
        />
      </div>

      <div
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        className={`${hideTooltip ? "hidden" : ""}  ${
          isOpen ? "opacity-100" : "opacity-0"
        }`}
      >
        {label}
      </div>
    </>
  );
};

export default Tooltip;
