import { useState } from "react";

import { GoogleAuthProvider } from "firebase/auth";
import { Spinner } from "flowbite-react";

import { signInWithFirebase } from "@/utils/FirebaseHelpers";

const GoogleAuth = ({
  setDisplayModal,
  setFirebaseUserData,
}: {
  setDisplayModal: (value: boolean) => void;
  setFirebaseUserData: (value: {}) => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const signInWithGoogle = (e: any) => {
    e.preventDefault();
    const provider = new GoogleAuthProvider();
    signInWithFirebase(
      provider,
      setIsLoading,
      setDisplayModal,
      setFirebaseUserData
    );
  };

  return (
    <button
      onClick={signInWithGoogle}
      className={`h-11 border-none shrink inline-flex w-full items-center justify-center rounded-md border text-sm font-medium text-gray-900  focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400  dark:focus:ring-gray-700 bg-[#4284F4]  transition-colors ${
        isLoading
          ? " border-black/60 opacity-60"
          : "hover:bg-[#206ae8] hover:text-gray-900 dark:hover:bg-gray-700 dark:hover:text-white"
      }`}
      id="sign-in-with-google"
      disabled={isLoading}
    >
      {isLoading ? (
        <Spinner className="h-4 w-4" />
      ) : (
        <>
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.1562 8.92969C17.1562 8.36719 17.0859 7.94531 17.0156 7.48828H8.71875V10.4766H13.6406C13.4648 11.7773 12.1641 14.2383 8.71875 14.2383C5.73047 14.2383 3.30469 11.7773 3.30469 8.71875C3.30469 3.83203 9.07031 1.58203 12.1641 4.57031L14.5547 2.28516C13.043 0.878906 11.0391 0 8.71875 0C3.86719 0 0 3.90234 0 8.71875C0 13.5703 3.86719 17.4375 8.71875 17.4375C13.7461 17.4375 17.1562 13.9219 17.1562 8.92969Z"
              fill="white"
            />
          </svg>
          <span className="hidden xl:block text-white text-md ml-4">
            Google
          </span>
        </>
      )}
    </button>
  );
};

export default GoogleAuth;
