import React, { useEffect, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { ArrowDownTrayIcon } from "@heroicons/react/20/solid";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import {
  TrashIcon as TrashIconSolid,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { useVirtualizer } from "@tanstack/react-virtual";
import { Alert, Badge, Button, Spinner, Tooltip } from "flowbite-react";
import "resize-observer-polyfill";

import { SubsType, updateAutoAddEmojisToSubtitles } from "@/store/editorSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import {
  createSrtFromSubsArray,
  findElementAfterIterations,
  formatSeconds,
  getSecondsFromHMSM,
} from "@/helpers";
import { findOverlappedSubtitles } from "@/helpers/subtitle";

import {
  ANALYTICS_CONSTANTS,
  eventsDataToRedux,
} from "@/utils/amplitudeAnalytcs";
import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

import { SubArrItem } from "@/interfaces";

import { SubtitleStyle, TextAlignment, TextEffect } from "@/enums";

import DropDownColorPicker from "@/components/DropDownColorPicker";
import EmojiInput from "@/components/EmojiInput/EmojiInput";
import TimeField from "@/components/TimeField";

import { TextStyleEditor } from "@/views/editor/components/TextStyleEditor";

import Default from "@/assets/images/editor/default.mp4";
import OneWord from "@/assets/images/editor/one_word.mp4";
import RandomWordColor from "@/assets/images/editor/random_word_color.mp4";
import TwoWord from "@/assets/images/editor/two_word.mp4";
import WordAppend from "@/assets/images/editor/word_appended.mp4";
import WordBackgroundChange from "@/assets/images/editor/word_background_change.mp4";
import WordColorChange from "@/assets/images/editor/word_color_change.mp4";
import EnableCutMagicIllustration from "@/assets/images/enable-cutmagic.svg";

const SUBTITLE_EDITOR = "editor";
const STYLES = "styles";
const FONTS = "fonts";

const SUBTITLE_STYLE_OPTIONS: {
  src: string;
  value: SubtitleStyle;
}[] = [
  {
    src: Default,
    value: SubtitleStyle.DEFAULT,
  },
  {
    src: OneWord,
    value: SubtitleStyle.ONE_WORD,
  },
  {
    src: TwoWord,
    value: SubtitleStyle.TWO_WORD,
  },
  {
    src: WordColorChange,
    value: SubtitleStyle.WORD_COLOR_CHANGE,
  },
  {
    src: WordBackgroundChange,
    value: SubtitleStyle.WORD_BACKGROUND_CHANGE,
  },
  {
    src: WordAppend,
    value: SubtitleStyle.WORD_APPENDED,
  },
  {
    src: RandomWordColor,
    value: SubtitleStyle.RANDOM_WORD_COLOR_CHANGE,
  },
];

const RenderSub = React.memo(
  ({
    sub,
    onSubsChange,
    hasFocus,
    nextSub,
    microContentEnd,
    microContentStart,
    onAddSubLine,
    onRemoveSubLine,
    onSubtitleEmojiChange,
    handleSeek,
    overlap,
  }: any) => {
    const inputRef = useRef<any>();
    const subREF = useRef<any>();
    const [cursorPosition, setCursorPosition] = useState(0);

    const subsArr = useAppSelector(
      (state) => state.editorState.subtitles.subsArr
    );

    // const subRefs = subsArr.reduce((acc: any, value: any) => {
    //   acc[value.id] = subREF;
    //   return acc;
    // }, {});

    const handleClick = (id: any) => {
      handleSeek(sub.start);
      window.scrollTo(0, subREF.current.offsetTop);
    };

    useEffect(() => {
      if (hasFocus) {
        inputRef.current.focus();
        // handleClick(sub.id);
        window.scrollTo(0, subREF.current.offsetTop);
      }
    }, [hasFocus]);

    const contentEnd = microContentEnd - microContentStart;

    // on start time change
    // 1. format the time.
    // 2. check if it crosses the lenght of the video, if yes, then return, show notification error.
    // 3. if it crosses the end time, then move the end time by the same amount as it was earlier, i.e duration should be same
    const handleStartTimeUpdate = (value: string) => {
      const valueInS = getSecondsFromHMSM(value);
      if (valueInS !== 0 && !valueInS) {
        return;
      }
      const valueInMs = valueInS * 1000;
      // as the subtitle timing exceeds the timing of the content
      // we have to exit the function
      if (valueInMs > contentEnd) {
        showNotification(
          "Time entered cannot be greated than the content length.",
          notificationType.FAIL
        );
        return;
      }

      let formattedValue = formatSeconds(valueInS);
      const newStartTimeInSec = valueInMs / 1000;
      const upperBound = sub.end;

      const differentBetweenNewAndOldStartTime = sub.end - sub.start;

      // we will check if the new start time exceeds the end time, if yes, then we will move the end time by the same amount
      const payload = {
        ...sub,
        start: newStartTimeInSec,
        end:
          newStartTimeInSec > upperBound
            ? newStartTimeInSec + differentBetweenNewAndOldStartTime
            : sub.end,
      };
      onSubsChange(sub.id, payload);
      return formattedValue;
    };

    // on start time change
    // 1. format the time.
    // 2. check if it crosses the lenght of the video, if yes, then return, show notification error.
    // 3. if it crosses the end time, then move the end time by the same amount as it was earlier, i.e duration should be same
    const handleEndTimeUpdate = (value: string) => {
      const valueInS = getSecondsFromHMSM(value);
      if (!valueInS) {
        return;
      }
      const valueInMs = valueInS * 1000;
      // as the subtitle timing exceeds the timing of the content
      // we have to exit the function
      if (valueInMs > contentEnd || valueInS <= sub.start) {
        showNotification(
          valueInMs <= sub.start
            ? "Time entered cannot be less than the start time of subtitle."
            : "Time entered cannot be greated than the content length.",
          notificationType.FAIL
        );
        return;
      }

      let formattedValue = formatSeconds(valueInS);
      const newEndTimeInSec = valueInMs / 1000;
      const lowerBound = sub.start;

      // if the end is not before than the current start time, only then apply the newEndTime
      const payload = {
        ...sub,
        end: newEndTimeInSec > lowerBound ? newEndTimeInSec : sub.end,
      };
      onSubsChange(sub.id, payload);
      return formattedValue;
    };
    const setEmojiValue = (updatedEmoji: any) => {
      const updatedSub = {
        ...sub,
        emoji: { ...sub.emoji, text: updatedEmoji },
      };
      onSubtitleEmojiChange(sub.id, updatedSub);
    };

    const onRemoveEmoji = (subId: any) => {
      const updatedSub = {
        ...sub,
        emoji: null,
      };
      onSubtitleEmojiChange(subId, updatedSub);
    };

    useEffect(() => {
      // hack by ChatGPT

      // set the cursor position after rendering
      inputRef.current?.setSelectionRange(cursorPosition, cursorPosition);
    }, [cursorPosition]);

    return (
      <div
        id={`sub-${sub.id}`}
        className={`flex flex-col gap-1`}
      >
        <div
          className={`flex flex-col rounded-lg p-2 gap-3 bg-white ${
            overlap && "border border-red-500"
          }`}
          ref={subREF}
        >
          <div className="flex justify-between items-center w-30">
            <div className="flex bg-100 items-center gap-2">
              <TimeField
                time={formatSeconds(sub.start)}
                handleTimeChange={handleStartTimeUpdate}
              />
              <span>&#45;</span>
              <TimeField
                time={formatSeconds(sub.end)}
                handleTimeChange={handleEndTimeUpdate}
              />
            </div>
            <TrashIconSolid
              className="h-4 text-red-500 cursor-pointer"
              onClick={() => onRemoveSubLine(sub.id)}
              id="delete-subtitle-line"
            />
          </div>
          <div className="flex flex-1 items-center justify-between">
            <input
              className="mb-1 mr-1 block w-[85%] rounded border border-gray-300 bg-gray-50 p-3 text-sm text-gray-90 focus:outline-0 focus:outline-blue-500 focus:border-blue-500 focus:ring-blue-50"
              onChange={(e) => {
                onSubsChange(sub.id, { ...sub, text: e.target.value });
                setCursorPosition(inputRef.current?.selectionStart || 0);
              }}
              value={sub.text}
              ref={inputRef}
              onClick={handleClick}
            />
            <div
              className={`relative flex-1 mb-1 block rounded border border-gray-300 bg-gray-50 text-gray-900 focus:border-blue-500 focus:ring-blue-500 ${
                sub.emoji?.text ? "p-1.5" : "p-2.5"
              }`}
              onClick={handleClick}
            >
              {sub.emoji?.text && (
                <XCircleIcon
                  className="absolute -top-2 -right-2 cursor-pointer text-red-500"
                  width={16}
                  height={16}
                  onClick={() => onRemoveEmoji(sub.id)}
                />
              )}
              <EmojiInput
                id={sub.id}
                setterFunction={setEmojiValue}
                presetEmoji={sub.emoji?.text || null}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center flex-1 opacity-0 hover:opacity-100">
          <div className="border-[0.5px] border-blue-500 flex-1" />
          <div
            className="text-[10px] font-medium text-blue-500 flex gap-1 border border-blue-500 rounded-xl py-0.5 px-2 items-center  hover:cursor-pointer hover:bg-blue-100"
            onClick={() => {
              onAddSubLine(sub.id, sub.end, nextSub?.start || sub.end + 1);
            }}
          >
            Add Line <PlusIcon className="h-3 w-3" />
          </div>
          <div className="border-[0.5px] border-blue-500 flex-1" />
        </div>
      </div>
    );
  }
);

const SUBTITLE_STYLES_ALLOWED_DURATION_THRESHOLD = 300;

const generateSRTFile = (subsArr: SubsType[]) => {
  const srt = createSrtFromSubsArray(subsArr);
  const blob = new Blob([srt], { type: "text/plain;charset=utf-8" });
  const a = document.createElement("a");
  const url = URL.createObjectURL(blob);
  a.href = url;
  a.download = "filename.srt";
  document.body.appendChild(a);
  a.click();
  setTimeout(() => {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);
};

const initialCurrentTab = STYLES;
const initialLoadingSubtitles = false;
const initialShowStyleColorPicker = false;
const initialAllowSubtitleStyles = true;
const SubtitleEditor = () => {
  const dispatch = useAppDispatch();
  const [currentTab, setCurrentTab] = useState<string>(initialCurrentTab);
  const [loadingSubtitles, setLoadingSubtitles] = useState(
    initialLoadingSubtitles
  );
  const [showStyleColorPicker, setShowStyleColorPicker] = useState(
    initialShowStyleColorPicker
  );
  const [allowSubtitleStyles, setAllowSubtitleStyles] = useState(
    initialAllowSubtitleStyles
  );

  const currentSelectedMicroContent = useAppSelector(
    (state) => state.homeState.currentSelectedMicroContent
  );

  const subsArr = useAppSelector(
    (state) => state.editorState.subtitles.subsArr
  );
  const autoAddEmojis = useAppSelector(
    (state) => state.editorState.autoAddEmojisToSubtitles
  );

  const currentSubIndex = useAppSelector(
    (state) => state.editorState.currentSubIndex
  );

  const overlappedIndices = findOverlappedSubtitles(subsArr as SubArrItem[]);

  const [overlapViewClickCount, setClickCount] = React.useState(1);

  const scrollContainerRef = React.useRef<HTMLDivElement | null>(null);

  const {
    setSubsConfig,
    subsConfig,
    onSubChange,
    onSubsDelete,
    fetchSubtitles,
    onAddSubLine,
    onRemoveSubLine,
    onSubtitleEmojiChange,
    handleSeek,
    onFormatSubtitleText,
  } = useOutletContext<any>();

  const loadSubtitles = async () => {
    setLoadingSubtitles(true);
    await fetchSubtitles(
      Math.floor(currentSelectedMicroContent.start),
      Math.floor(currentSelectedMicroContent.end)
    );
    setLoadingSubtitles(false);
  };

  useEffect(() => {
    const durationInSeconds =
      (currentSelectedMicroContent.end - currentSelectedMicroContent.start) /
      1000;
    const isAllowed =
      durationInSeconds < SUBTITLE_STYLES_ALLOWED_DURATION_THRESHOLD;
    setAllowSubtitleStyles(isAllowed);
    isAllowed ||
      setSubsConfig({
        ...subsConfig,
        style: {
          ...subsConfig?.style,
          type: SubtitleStyle.DEFAULT,
        },
      });
  }, [currentSelectedMicroContent.start, currentSelectedMicroContent.end]);

  useEffect(() => {
    if (
      subsConfig?.style?.type === SubtitleStyle.DEFAULT ||
      subsConfig?.style?.type === SubtitleStyle.ONE_WORD ||
      subsConfig?.style?.type === SubtitleStyle.TWO_WORD
    ) {
      setShowStyleColorPicker(false);
    } else {
      setShowStyleColorPicker(true);
    }
  }, [subsConfig]);

  const getEffectTypeForStyle = (style: SubtitleStyle) => {
    switch (style) {
      case SubtitleStyle.RANDOM_WORD_COLOR_CHANGE:
      case SubtitleStyle.WORD_APPENDED:
      case SubtitleStyle.WORD_BACKGROUND_CHANGE:
      case SubtitleStyle.WORD_COLOR_CHANGE:
        return subsConfig.effect_type === TextEffect.SHADOW
          ? TextEffect.NONE
          : subsConfig.effect_type;
      default:
        return subsConfig.effect_type;
    }
  };

  const parentElementRef = useRef<any>();
  const count = subsArr.length;

  const virtualizer = useVirtualizer({
    count,
    getScrollElement: () => parentElementRef.current,
    estimateSize: () => 30,
    overscan: 100,
  });

  const sub = virtualizer.getVirtualItems();

  const renderTab = () => {
    if (subsArr.length === 0) {
      return (
        <div className="flex flex-1 flex-col justify-center pb-20 gap-8">
          <img
            src={EnableCutMagicIllustration}
            alt="enable cut magic illustration"
            className="self-center p-4"
          />
          <div className="font-bold text-gray-500 text-sm text-center">
            Make videos more engaging by <br /> adding subtitles & captions
          </div>
          <Button
            onClick={loadSubtitles}
            disabled={loadingSubtitles}
          >
            {loadingSubtitles && (
              <div className="mr-3">
                <Spinner
                  size="sm"
                  light={true}
                />
              </div>
            )}
            Enable Subtitles
          </Button>
        </div>
      );
    }

    switch (currentTab) {
      case SUBTITLE_EDITOR:
        return (
          <div
            className="flex-1 flex flex-col gap-1"
            ref={parentElementRef}
            style={{
              height: `${virtualizer.getTotalSize()}px`,
            }}
          >
            {sub.map((sub: any) => {
              const subtitle: any = subsArr[sub.index];
              return (
                <RenderSub
                  overlap={overlappedIndices.includes(subtitle.id)}
                  key={subtitle.id}
                  sub={subtitle}
                  onSubsChange={onSubChange}
                  hasFocus={currentSubIndex === subtitle.id}
                  previousSub={subsArr[sub.index - 1]}
                  nextSub={subsArr[sub.index + 1]}
                  microContentEnd={currentSelectedMicroContent.end}
                  microContentStart={currentSelectedMicroContent.start}
                  onAddSubLine={onAddSubLine}
                  onRemoveSubLine={onRemoveSubLine}
                  onSubtitleEmojiChange={onSubtitleEmojiChange}
                  handleSeek={handleSeek}
                />
              );
            })}
          </div>
        );
      case STYLES:
        return (
          <div className="flex flex-col">
            <div>
              {allowSubtitleStyles || (
                <Alert
                  color="warning"
                  className="mb-4 font-medium"
                >
                  Subtitle styling is only available for videos less than 5
                  minutes at the moment.
                </Alert>
              )}
              <div className="flex flex-1 flex-row flex-wrap justify-between content-start gap-3">
                {SUBTITLE_STYLE_OPTIONS.map(({ value, src }) => (
                  <div
                    id={value}
                    className={`basis-[48%] select-none cursor-pointer rounded-xl p-0.5 ${
                      value !== SubtitleStyle.DEFAULT && !allowSubtitleStyles
                        ? "cursor-not-allowed"
                        : ""
                    } text-center border-2 ${
                      subsConfig.style.type === value
                        ? "border-blue-700 bg-white"
                        : "border-transparent"
                    }`}
                    key={value}
                    onClick={() => {
                      allowSubtitleStyles &&
                        setSubsConfig({
                          ...subsConfig,
                          style: {
                            ...subsConfig.style,
                            type: value,
                          },
                          alignment:
                            value === SubtitleStyle.WORD_APPENDED
                              ? TextAlignment.LEFT
                              : TextAlignment.CENTER,
                          effect_type: getEffectTypeForStyle(value),
                        });

                      eventsDataToRedux(
                        ANALYTICS_CONSTANTS.SUBTITLE_STYLE_CLICK
                      );
                    }}
                  >
                    <video
                      src={src}
                      onMouseOver={(event: React.MouseEvent) => {
                        const target = event.target as HTMLVideoElement;
                        // do nothing if the video is immediately paused after play
                        // handles following error - The play() request was interrupted by a call to pause().
                        target.play().catch(() => {});
                      }}
                      onMouseOut={(event: React.MouseEvent) => {
                        const target = event.target as HTMLVideoElement;
                        target.pause();
                        target.currentTime = 0;
                      }}
                      className="rounded-lg shadow-2xl shadow-gray-400"
                      playsInline
                      muted
                      loop
                    />
                  </div>
                ))}
              </div>
              {showStyleColorPicker && (
                <div className="flex items-center justify-between rounded-lg p-1 pl-2 mt-8 bg-white border-gray-400 border">
                  <p className="text-gray-800 text-sm font-semibold select-none">
                    Effect color
                  </p>
                  <DropDownColorPicker
                    position="top"
                    color={subsConfig.style.color}
                    onChange={(color: string) => {
                      setSubsConfig({
                        ...subsConfig,
                        style: {
                          ...subsConfig.style,
                          color,
                        },
                      });
                    }}
                  />
                </div>
              )}
            </div>
            <div
              className="text-blue-500 text-sm mt-8 text-center font-semibold underline cursor-pointer"
              onClick={() => setCurrentTab(FONTS)}
            >
              Customize Font
            </div>
          </div>
        );
      case FONTS:
        return (
          <TextStyleEditor
            styles={subsConfig}
            onChange={setSubsConfig}
            onFormatSubtitleText={onFormatSubtitleText}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="relative w-full h-full flex flex-col">
      <div
        className={`flex justify-between items-center bg-white p-2 ${
          subsArr.length > 0 ? "pt-6" : "p-6"
        }`}
      >
        <p className="text-lg text-gray-800">Subtitles</p>
        {subsArr.length > 0 && (
          <div className="flex gap-2">
            <Tooltip
              content="Remove Subtitles"
              placement="bottom"
            >
              <button
                type="button"
                className="
              items-center justify-center rounded-lg 
              border  bg-transparent px-2.5 py-2.5 text-center 
              text-xs font-medium focus:outline-none
              border-red-600 text-red-600 hover:bg-red-50"
                onClick={() => {
                  onSubsDelete();
                  eventsDataToRedux(ANALYTICS_CONSTANTS.SUBTITLE_REMOVED);
                }}
                id="add-emojis-button"
              >
                <TrashIcon className="h-4" />
              </button>
            </Tooltip>
            <Tooltip
              content="Download SRT"
              placement="bottom"
            >
              <button
                type="button"
                className="
              items-center justify-center rounded-lg 
              border  bg-transparent px-2.5 py-2.5 text-center 
              text-xs font-medium focus:outline-none
              border-gray-800 text-gray-900 hover:bg-gray-200"
                onClick={() => {
                  generateSRTFile(subsArr);
                  eventsDataToRedux(ANALYTICS_CONSTANTS.DOWNLOAD_SRT);
                }}
                id="add-emojis-button"
              >
                <ArrowDownTrayIcon className="h-4" />
              </button>
            </Tooltip>
            <button
              type="button"
              className={`
              items-center justify-center rounded-lg w-36
              border  bg-transparent px-3 py-2.5 text-center 
              text-xs font-medium focus:outline-none
              ${
                autoAddEmojis
                  ? "border-red-600 text-red-600 hover:bg-red-50"
                  : "border-blue-500 text-blue-500 hover:bg-blue-50"
              }`}
              onClick={() =>
                dispatch(updateAutoAddEmojisToSubtitles(!autoAddEmojis))
              }
              id="add-emojis-button"
            >
              {autoAddEmojis ? "Remove Emojis 🙁" : "Auto-add Emojis 😎"}
            </button>
          </div>
        )}
      </div>

      {subsArr.length === 0 || (
        <div className="border-b border-gray-200 bg-white text-center text-sm font-medium text-gray-500 dark:border-gray-700 dark:text-gray-400">
          <ul className="-mb-px flex transition-colors">
            <li
              className="mr-2 w-36 cursor-pointer"
              onClick={() => setCurrentTab(STYLES)}
            >
              <div
                className={`flex items-center justify-center p-4 ${
                  currentTab === STYLES
                    ? "border-blue-600 text-blue-600 dark:text-blue-500"
                    : "border-transparent hover:border-gray-300 hover:text-gray-600"
                } whitespace-nowrap rounded-t-lg border-b-4`}
              >
                Styles
                <Badge
                  className="ml-1"
                  color="purple"
                >
                  NEW
                </Badge>
              </div>
            </li>
            <li
              className="mr-2 w-36 cursor-pointer"
              onClick={() => setCurrentTab(SUBTITLE_EDITOR)}
            >
              <p
                className={`inline-block p-4 ${
                  currentTab === SUBTITLE_EDITOR
                    ? "border-blue-600 text-blue-600 dark:text-blue-500"
                    : "border-transparent hover:border-gray-300 hover:text-gray-600"
                } whitespace-nowrap rounded-t-lg border-b-4`}
              >
                Editor
              </p>
            </li>
            <li
              className="mr-2 w-36 cursor-pointer"
              onClick={() => {
                setCurrentTab(FONTS);
                eventsDataToRedux(ANALYTICS_CONSTANTS.FONT_SETTINGS_ACCESSED);
              }}
            >
              <p
                className={`inline-block p-4 ${
                  currentTab === FONTS
                    ? "border-blue-600 text-blue-600 dark:text-blue-500"
                    : "border-transparent hover:border-gray-300 hover:text-gray-600"
                } whitespace-nowrap rounded-t-lg border-b-4`}
              >
                Font Settings
              </p>
            </li>
          </ul>
        </div>
      )}
      <div className="p-2">
        {overlappedIndices.length > 0 && (
          <Alert
            color="failure"
            className="mb-0 font-medium flex flex-row justify-between sticky top-0 z-10"
            additionalContent={
              <Button
                size="xs"
                color="failure"
                onClick={() => {
                  const indexToSelect = findElementAfterIterations(
                    overlappedIndices,
                    overlapViewClickCount
                  );
                  const targetElement = document.getElementById(
                    `sub-${indexToSelect}`
                  );
                  const rect = targetElement?.getBoundingClientRect();
                  const scrollTop =
                    scrollContainerRef.current?.scrollTop ||
                    document.documentElement.scrollTop;
                  const offset = -180; // Change this value to your desired offset
                  if (rect) {
                    scrollContainerRef.current?.scrollTo({
                      top: scrollTop + rect.top - rect.height + offset,
                      behavior: "smooth",
                    });
                  }
                  setClickCount((prev) => prev + 1);
                }}
              >
                View
              </Button>
            }
          >
            <div className="w-full">
              Some of your subtitles are overlapping.
            </div>
          </Alert>
        )}
      </div>
      <div
        className="px-2 py-4 flex flex-1 overflow-y-auto"
        ref={scrollContainerRef}
      >
        {renderTab()}
      </div>
    </div>
  );
};

export default SubtitleEditor;
