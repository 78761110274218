import { useQuery } from "@tanstack/react-query";

import { useAppSelector } from "@/store/hooks";

import { getAssetCount } from "@/api/requests";

import { PlanType } from "@/enums";

export default function useGetAssetsCount(disableQuery = false) {
  const planType = useAppSelector(
    (state) => state.authState.userSubscription.planType
  );

  const uid = useAppSelector((state) => state.authState.currentUser.uid);

  return useQuery({
    queryKey: ["Assets-count", uid],
    queryFn: () => getAssetCount(planType === PlanType.FREE),
    staleTime: 300000,
    enabled: !disableQuery,
  });
}
