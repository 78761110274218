import { useAppSelector } from "@/store/hooks";

import { PARALLEL_UPLOAD_LIMITS } from "@/constants";

import { PlanType } from "@/enums";

import BaseModal from "@/components/BaseModal/BaseModal";

const ProcessingLimitModal = ({ isOpen, onClose }: any) => {
  const planType = useAppSelector(
    (state) => state.authState.userSubscription.planType
  );
  const isPaidUser = planType !== PlanType.FREE;
  const parallelUploadLimit = PARALLEL_UPLOAD_LIMITS[planType];

  return (
    <BaseModal
      isModalOpen={isOpen}
      handelModalOpen={() => {}}
      notClosable
    >
      <div className="sm:w-[30rem]">
        <div className="flex flex-col justify-center bg-white px-11 pt-7 pb-8 text-center">
          <div className="mb-5 text-2xl font-bold text-gray-900">
            Hold your horses, cowboy! 🤠
          </div>
          <div className="text-base font-normal text-gray-500">
            {isPaidUser
              ? `${
                  parallelUploadLimit > 1
                    ? `${parallelUploadLimit} of your videos are already being processed.`
                    : `One of your video is already being processed.`
                } 
			  	For smooth processing, 
			  	allow these files to get ready before uploading more.`
              : `One file at a time, please. 
			  	Take it easy and upload one file at a time for smooth processing. 
			  	Let's not overload the system.`}
          </div>
          <div className="mt-5 flex justify-center">
            <button
              type="button"
              id="ok-parallel-upload"
              className={`flex items-center justify-center 
              rounded-lg bg-blue-600 px-5 py-2.5 
              text-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 
              focus:ring-blue-300`}
              onClick={onClose}
            >
              Okay 😏
            </button>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default ProcessingLimitModal;
