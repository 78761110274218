import { useMemo } from "react";
import { useOutletContext } from "react-router-dom";
import { useUpdateEffect } from "react-use";

import clsx from "clsx";

import { SimpleAssetType } from "@/enums";

import PanelContent from "./PanelContent";
import PanelHeader from "./PanelHeader";

type BottomBRollPanelProps = {
  isOpen: boolean;
  toggleDrawer: () => void;
  bottomPanelFlashDisable: boolean;
  resetBottomPanelFlashDisable: () => void;
};

const BottomBRollPanel: React.FC<BottomBRollPanelProps> = ({
  isOpen,
  toggleDrawer,
  bottomPanelFlashDisable,
  resetBottomPanelFlashDisable,
}) => {
  const { bRolls, audioElements } = useOutletContext<any>();

  const aggregatedMedia = [...bRolls, ...Object.values(audioElements)];
  const isDrawerEmpty = aggregatedMedia.length === 0;

  const aggregateMediaCounts = (assets: any) => {
    return assets.reduce(
      (acc: any, asset: any) => {
        acc[asset.assetType]++;
        return acc;
      },
      {
        [SimpleAssetType.IMAGE]: 0,
        [SimpleAssetType.VIDEO]: 0,
        [SimpleAssetType.AUDIO]: 0,
      }
    );
  };

  const constructMediaString = (counts: {
    [key in SimpleAssetType]: number;
  }) => {
    const parts = Object.entries(counts)
      .filter(([_, count]) => count > 0)
      .map(
        ([type, count]) =>
          `${count} ${type.toLowerCase()}${count > 1 ? "s" : ""}`
      );

    if (parts.length === 0) return "No media added";

    return `${parts.slice(0, -1).join(", ")}${
      parts.length > 1 ? ", " : ""
    }${parts.slice(-1)}`;
  };

  const mediaCountString = useMemo(() => {
    const counts = aggregateMediaCounts(aggregatedMedia);
    return constructMediaString(counts);
  }, [aggregatedMedia.length]);

  const drawerClass = isDrawerEmpty ? "" : "bg-green-600 text-white";
  const flashClass =
    !bottomPanelFlashDisable && !isDrawerEmpty ? "b-roll-header-flash" : "";

  const combinedClass = clsx(drawerClass, flashClass);

  useUpdateEffect(() => {
    resetBottomPanelFlashDisable();
  }, [isDrawerEmpty]);

  return (
    <section
      className="sticky bottom-0 z-50 w-full bg-white rounded-t-lg shadow flex flex-col justify-center"
      style={{
        boxShadow: "10px 4px 40px 0px #0000001C",
      }}
      id="bottom-b-roll-panel"
    >
      <PanelHeader
        toggleDrawer={toggleDrawer}
        isOpen={isOpen}
        hidden={!isOpen}
        mediaCountString={mediaCountString}
      />

      <div
        className={clsx(
          "transition-all duration-200",
          isOpen
            ? `h-[calc(100vh_-_16rem)]
              overflow-y-auto`
            : "h-0 overflow-y-hidden"
        )}
      >
        <div className={clsx(isOpen ? "translate-y-0" : "translate-y-full")}>
          <PanelContent
            bRolls={aggregatedMedia}
            isDrawerEmpty={isDrawerEmpty}
          />
        </div>
      </div>

      <PanelHeader
        toggleDrawer={toggleDrawer}
        isOpen={isOpen}
        hidden={isOpen}
        mediaCountString={mediaCountString}
        className={combinedClass}
        buttonClassName={isDrawerEmpty ? "" : "bg-green-700"}
      />
    </section>
  );
};

export default BottomBRollPanel;
