import { SVGProps, memo } from "react";

import clsx from "clsx";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    className={clsx(props.className, "fill-current")}
    {...props}
  >
    <path
      fill="currentColor"
      d="M5.75 18.5H5v-.75a.75.75 0 1 0-1.5 0v.75h-.75a.75.75 0 1 0 0 1.5h.75v.75a.75.75 0 1 0 1.5 0V20h.75a.75.75 0 1 0 0-1.5ZM2.75 5.75h1.5v1.5a.75.75 0 0 0 1.5 0v-1.5h1.5a.75.75 0 0 0 0-1.5h-1.5v-1.5a.75.75 0 0 0-1.5 0v1.5h-1.5a.75.75 0 0 0 0 1.5ZM22.004 11.997a1.481 1.481 0 0 1-.977 1.402l-4.842 1.782-1.781 4.842a1.493 1.493 0 0 1-2.802 0l-1.786-4.836-4.84-1.783a1.492 1.492 0 0 1 0-2.803L9.82 8.82 11.6 3.978a1.493 1.493 0 0 1 2.802 0l1.78 4.845 4.84 1.782a1.48 1.48 0 0 1 .982 1.392Z"
    />
  </svg>
);
const BackgroundIcon = memo(SvgComponent);
export default BackgroundIcon;
