import { IconPropType } from "@/utils/constants";

export const TwitterIcon = ({ fillColor }: IconPropType) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.03168 14.5008C11.0694 14.5008 14.3718 9.49859 14.3718 5.16067C14.3718 5.01859 14.3718 4.87715 14.3622 4.73635C15.0047 4.27166 15.5593 3.69628 16 3.03715C15.4009 3.30275 14.7654 3.47686 14.1146 3.55363C14.7999 3.14343 15.3128 2.49815 15.5578 1.73795C14.9134 2.12036 14.2084 2.38984 13.4733 2.53475C12.9783 2.00847 12.3237 1.65998 11.6108 1.54321C10.8978 1.42644 10.1663 1.5479 9.52931 1.88879C8.89234 2.22968 8.38548 2.771 8.08716 3.42898C7.78884 4.08697 7.71569 4.82493 7.87904 5.52867C6.57393 5.4632 5.29717 5.12403 4.13164 4.53316C2.9661 3.94228 1.93784 3.11293 1.1136 2.09891C0.693819 2.82158 0.565248 3.67706 0.754066 4.49119C0.942885 5.30532 1.43489 6.01688 2.12992 6.48099C1.60749 6.46568 1.09643 6.32475 0.64 6.07011V6.11171C0.640207 6.86961 0.902567 7.60411 1.38258 8.19062C1.86259 8.77714 2.53071 9.17956 3.2736 9.32963C2.79032 9.46146 2.28325 9.48072 1.79136 9.38595C2.00121 10.0382 2.40962 10.6085 2.95949 11.0172C3.50937 11.426 4.17322 11.6527 4.85824 11.6656C4.17763 12.2006 3.39821 12.5962 2.56458 12.8296C1.73096 13.0631 0.859476 13.1299 0 13.0263C1.50122 13.9896 3.24795 14.5006 5.03168 14.4983"
        fill={fillColor}
      />
    </svg>
  );
};
