import { SimpleAssetType } from "@/enums";

const stockPhotos = [
  {
    id: 1438863,
    width: 6000,
    height: 4000,
    url: "https://www.pexels.com/photo/round-black-chronograph-watch-on-table-near-germany-passport-an-banknote-on-table-1438863/",
    photographer: "Omar Markhieh",
    photographer_url: "https://www.pexels.com/@omar-markhieh-637410",
    photographer_id: 637410,
    avg_color: "#776B64",
    src: {
      original:
        "https://images.pexels.com/photos/1438863/pexels-photo-1438863.jpeg",
      large2x:
        "https://images.pexels.com/photos/1438863/pexels-photo-1438863.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      large:
        "https://images.pexels.com/photos/1438863/pexels-photo-1438863.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
      medium:
        "https://images.pexels.com/photos/1438863/pexels-photo-1438863.jpeg?auto=compress&cs=tinysrgb&h=350",
      small:
        "https://images.pexels.com/photos/1438863/pexels-photo-1438863.jpeg?auto=compress&cs=tinysrgb&h=130",
      portrait:
        "https://images.pexels.com/photos/1438863/pexels-photo-1438863.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
      landscape:
        "https://images.pexels.com/photos/1438863/pexels-photo-1438863.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
      tiny: "https://images.pexels.com/photos/1438863/pexels-photo-1438863.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    },
    liked: false,
    alt: "Round Black Chronograph Watch on Table Near Germany Passport an Banknote on Table",
  },
  {
    id: 15912095,
    width: 3472,
    height: 4624,
    url: "https://www.pexels.com/photo/page-of-kazakhstan-passport-15912095/",
    photographer: "Natalie Dmay",
    photographer_url: "https://www.pexels.com/@natalinadmay",
    photographer_id: 2800326,
    avg_color: "#806456",
    src: {
      original:
        "https://images.pexels.com/photos/15912095/pexels-photo-15912095.jpeg",
      large2x:
        "https://images.pexels.com/photos/15912095/pexels-photo-15912095.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      large:
        "https://images.pexels.com/photos/15912095/pexels-photo-15912095.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
      medium:
        "https://images.pexels.com/photos/15912095/pexels-photo-15912095.jpeg?auto=compress&cs=tinysrgb&h=350",
      small:
        "https://images.pexels.com/photos/15912095/pexels-photo-15912095.jpeg?auto=compress&cs=tinysrgb&h=130",
      portrait:
        "https://images.pexels.com/photos/15912095/pexels-photo-15912095.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
      landscape:
        "https://images.pexels.com/photos/15912095/pexels-photo-15912095.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
      tiny: "https://images.pexels.com/photos/15912095/pexels-photo-15912095.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    },
    liked: false,
    alt: "Page of Kazakhstan Passport",
  },
  {
    id: 1346347,
    width: 3558,
    height: 3558,
    url: "https://www.pexels.com/photo/clear-glass-bottle-filled-with-broccoli-shake-1346347/",
    photographer: "Alisha Mishra",
    photographer_url: "https://www.pexels.com/@alisha-mishra-579430",
    photographer_id: 579430,
    avg_color: "#B9B5B2",
    src: {
      original:
        "https://images.pexels.com/photos/1346347/pexels-photo-1346347.jpeg",
      large2x:
        "https://images.pexels.com/photos/1346347/pexels-photo-1346347.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      large:
        "https://images.pexels.com/photos/1346347/pexels-photo-1346347.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
      medium:
        "https://images.pexels.com/photos/1346347/pexels-photo-1346347.jpeg?auto=compress&cs=tinysrgb&h=350",
      small:
        "https://images.pexels.com/photos/1346347/pexels-photo-1346347.jpeg?auto=compress&cs=tinysrgb&h=130",
      portrait:
        "https://images.pexels.com/photos/1346347/pexels-photo-1346347.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
      landscape:
        "https://images.pexels.com/photos/1346347/pexels-photo-1346347.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
      tiny: "https://images.pexels.com/photos/1346347/pexels-photo-1346347.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    },
    liked: false,
    alt: "Clear Glass Bottle Filled With Broccoli Shake",
  },
  {
    id: 699459,
    width: 3024,
    height: 4032,
    url: "https://www.pexels.com/photo/turned-on-gray-laptop-computer-on-table-699459/",
    photographer: "Flo Dahm",
    photographer_url: "https://www.pexels.com/@flodahm",
    photographer_id: 154317,
    avg_color: "#AAA3A1",
    src: {
      original:
        "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg",
      large2x:
        "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      large:
        "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
      medium:
        "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg?auto=compress&cs=tinysrgb&h=350",
      small:
        "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg?auto=compress&cs=tinysrgb&h=130",
      portrait:
        "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
      landscape:
        "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
      tiny: "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    },
    liked: false,
    alt: "Turned-on Gray Laptop Computer on Table",
  },
  {
    id: 1481105,
    width: 3722,
    height: 4000,
    url: "https://www.pexels.com/photo/architectural-photography-of-buildings-1481105/",
    photographer: "Nextvoyage",
    photographer_url: "https://www.pexels.com/@nextvoyage",
    photographer_id: 146718,
    avg_color: "#757A77",
    src: {
      original:
        "https://images.pexels.com/photos/1481105/pexels-photo-1481105.jpeg",
      large2x:
        "https://images.pexels.com/photos/1481105/pexels-photo-1481105.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      large:
        "https://images.pexels.com/photos/1481105/pexels-photo-1481105.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
      medium:
        "https://images.pexels.com/photos/1481105/pexels-photo-1481105.jpeg?auto=compress&cs=tinysrgb&h=350",
      small:
        "https://images.pexels.com/photos/1481105/pexels-photo-1481105.jpeg?auto=compress&cs=tinysrgb&h=130",
      portrait:
        "https://images.pexels.com/photos/1481105/pexels-photo-1481105.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
      landscape:
        "https://images.pexels.com/photos/1481105/pexels-photo-1481105.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
      tiny: "https://images.pexels.com/photos/1481105/pexels-photo-1481105.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    },
    liked: false,
    alt: "Architectural Photography of Buildings",
  },
  {
    id: 7598552,
    width: 4480,
    height: 2987,
    url: "https://www.pexels.com/photo/women-in-a-studio-7598552/",
    photographer: "cottonbro studio",
    photographer_url: "https://www.pexels.com/@cottonbro",
    photographer_id: 1437723,
    avg_color: "#657F92",
    src: {
      original:
        "https://images.pexels.com/photos/7598552/pexels-photo-7598552.jpeg",
      large2x:
        "https://images.pexels.com/photos/7598552/pexels-photo-7598552.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      large:
        "https://images.pexels.com/photos/7598552/pexels-photo-7598552.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
      medium:
        "https://images.pexels.com/photos/7598552/pexels-photo-7598552.jpeg?auto=compress&cs=tinysrgb&h=350",
      small:
        "https://images.pexels.com/photos/7598552/pexels-photo-7598552.jpeg?auto=compress&cs=tinysrgb&h=130",
      portrait:
        "https://images.pexels.com/photos/7598552/pexels-photo-7598552.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
      landscape:
        "https://images.pexels.com/photos/7598552/pexels-photo-7598552.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
      tiny: "https://images.pexels.com/photos/7598552/pexels-photo-7598552.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    },
    liked: false,
    alt: "\r\nWomen in a Studio ",
  },
  {
    id: 2513606,
    width: 2305,
    height: 3457,
    url: "https://www.pexels.com/photo/crowd-sitting-on-green-grass-2513606/",
    photographer: "Wendy Wei",
    photographer_url: "https://www.pexels.com/@wendywei",
    photographer_id: 387315,
    avg_color: "#C0B49E",
    src: {
      original:
        "https://images.pexels.com/photos/2513606/pexels-photo-2513606.jpeg",
      large2x:
        "https://images.pexels.com/photos/2513606/pexels-photo-2513606.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      large:
        "https://images.pexels.com/photos/2513606/pexels-photo-2513606.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
      medium:
        "https://images.pexels.com/photos/2513606/pexels-photo-2513606.jpeg?auto=compress&cs=tinysrgb&h=350",
      small:
        "https://images.pexels.com/photos/2513606/pexels-photo-2513606.jpeg?auto=compress&cs=tinysrgb&h=130",
      portrait:
        "https://images.pexels.com/photos/2513606/pexels-photo-2513606.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
      landscape:
        "https://images.pexels.com/photos/2513606/pexels-photo-2513606.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
      tiny: "https://images.pexels.com/photos/2513606/pexels-photo-2513606.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    },
    liked: false,
    alt: "Crowd Sitting on Green Grass",
  },
  {
    id: 5052877,
    width: 4160,
    height: 6240,
    url: "https://www.pexels.com/photo/liking-a-photo-on-instagram-5052877/",
    photographer: "cottonbro studio",
    photographer_url: "https://www.pexels.com/@cottonbro",
    photographer_id: 1437723,
    avg_color: "#998E8D",
    src: {
      original:
        "https://images.pexels.com/photos/5052877/pexels-photo-5052877.jpeg",
      large2x:
        "https://images.pexels.com/photos/5052877/pexels-photo-5052877.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      large:
        "https://images.pexels.com/photos/5052877/pexels-photo-5052877.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
      medium:
        "https://images.pexels.com/photos/5052877/pexels-photo-5052877.jpeg?auto=compress&cs=tinysrgb&h=350",
      small:
        "https://images.pexels.com/photos/5052877/pexels-photo-5052877.jpeg?auto=compress&cs=tinysrgb&h=130",
      portrait:
        "https://images.pexels.com/photos/5052877/pexels-photo-5052877.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
      landscape:
        "https://images.pexels.com/photos/5052877/pexels-photo-5052877.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
      tiny: "https://images.pexels.com/photos/5052877/pexels-photo-5052877.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    },
    liked: false,
    alt: "Liking a Photo on Instagram",
  },
  {
    id: 7242263,
    width: 3224,
    height: 3224,
    url: "https://www.pexels.com/photo/wall-of-building-with-window-of-white-room-7242263/",
    photographer: "John Lee",
    photographer_url: "https://www.pexels.com/@john",
    photographer_id: 3916129,
    avg_color: "#364057",
    src: {
      original:
        "https://images.pexels.com/photos/7242263/pexels-photo-7242263.jpeg",
      large2x:
        "https://images.pexels.com/photos/7242263/pexels-photo-7242263.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      large:
        "https://images.pexels.com/photos/7242263/pexels-photo-7242263.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
      medium:
        "https://images.pexels.com/photos/7242263/pexels-photo-7242263.jpeg?auto=compress&cs=tinysrgb&h=350",
      small:
        "https://images.pexels.com/photos/7242263/pexels-photo-7242263.jpeg?auto=compress&cs=tinysrgb&h=130",
      portrait:
        "https://images.pexels.com/photos/7242263/pexels-photo-7242263.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
      landscape:
        "https://images.pexels.com/photos/7242263/pexels-photo-7242263.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
      tiny: "https://images.pexels.com/photos/7242263/pexels-photo-7242263.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    },
    liked: false,
    alt: "Wall of building with window of white room",
  },
  {
    id: 6954162,
    width: 6000,
    height: 4000,
    url: "https://www.pexels.com/photo/positive-black-woman-talking-to-radio-host-6954162/",
    photographer: "George Milton",
    photographer_url: "https://www.pexels.com/@george-milton",
    photographer_id: 19162611,
    avg_color: "#898B8D",
    src: {
      original:
        "https://images.pexels.com/photos/6954162/pexels-photo-6954162.jpeg",
      large2x:
        "https://images.pexels.com/photos/6954162/pexels-photo-6954162.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      large:
        "https://images.pexels.com/photos/6954162/pexels-photo-6954162.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
      medium:
        "https://images.pexels.com/photos/6954162/pexels-photo-6954162.jpeg?auto=compress&cs=tinysrgb&h=350",
      small:
        "https://images.pexels.com/photos/6954162/pexels-photo-6954162.jpeg?auto=compress&cs=tinysrgb&h=130",
      portrait:
        "https://images.pexels.com/photos/6954162/pexels-photo-6954162.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
      landscape:
        "https://images.pexels.com/photos/6954162/pexels-photo-6954162.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
      tiny: "https://images.pexels.com/photos/6954162/pexels-photo-6954162.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    },
    liked: false,
    alt: "Smiling African American female guest gesticulating while having interview with journalist sitting near mic",
  },
  {
    id: 46924,
    width: 4272,
    height: 2848,
    url: "https://www.pexels.com/photo/whatsapp-application-screenshot-46924/",
    photographer: "Anton",
    photographer_url: "https://www.pexels.com/@anton-8100",
    photographer_id: 8100,
    avg_color: "#4A5D5D",
    src: {
      original:
        "https://images.pexels.com/photos/46924/pexels-photo-46924.jpeg",
      large2x:
        "https://images.pexels.com/photos/46924/pexels-photo-46924.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      large:
        "https://images.pexels.com/photos/46924/pexels-photo-46924.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
      medium:
        "https://images.pexels.com/photos/46924/pexels-photo-46924.jpeg?auto=compress&cs=tinysrgb&h=350",
      small:
        "https://images.pexels.com/photos/46924/pexels-photo-46924.jpeg?auto=compress&cs=tinysrgb&h=130",
      portrait:
        "https://images.pexels.com/photos/46924/pexels-photo-46924.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
      landscape:
        "https://images.pexels.com/photos/46924/pexels-photo-46924.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
      tiny: "https://images.pexels.com/photos/46924/pexels-photo-46924.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    },
    liked: false,
    alt: "Whatsapp Application Screenshot",
  },
  {
    id: 6686429,
    width: 4160,
    height: 6240,
    url: "https://www.pexels.com/photo/overhead-shot-of-a-person-listening-to-podcast-6686429/",
    photographer: "cottonbro studio",
    photographer_url: "https://www.pexels.com/@cottonbro",
    photographer_id: 1437723,
    avg_color: "#45464A",
    src: {
      original:
        "https://images.pexels.com/photos/6686429/pexels-photo-6686429.jpeg",
      large2x:
        "https://images.pexels.com/photos/6686429/pexels-photo-6686429.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      large:
        "https://images.pexels.com/photos/6686429/pexels-photo-6686429.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
      medium:
        "https://images.pexels.com/photos/6686429/pexels-photo-6686429.jpeg?auto=compress&cs=tinysrgb&h=350",
      small:
        "https://images.pexels.com/photos/6686429/pexels-photo-6686429.jpeg?auto=compress&cs=tinysrgb&h=130",
      portrait:
        "https://images.pexels.com/photos/6686429/pexels-photo-6686429.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
      landscape:
        "https://images.pexels.com/photos/6686429/pexels-photo-6686429.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
      tiny: "https://images.pexels.com/photos/6686429/pexels-photo-6686429.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    },
    liked: false,
    alt: "Overhead Shot of a Person Listening to Podcast",
  },
];

const stockVideos = [
  {
    id: 1739010,
    width: 1920,
    height: 1080,
    duration: 21,
    full_res: null,
    tags: [],
    url: "https://www.pexels.com/video/a-beautiful-beach-with-turquoise-water-1739010/",
    image:
      "https://images.pexels.com/videos/1739010/free-video-1739010.jpg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
    avg_color: null,
    user: {
      id: 148897,
      name: "Nino Souza",
      url: "https://www.pexels.com/@ninosouza",
    },
    video_files: [
      {
        id: 71480,
        quality: "sd",
        file_type: "video/mp4",
        width: 640,
        height: 360,
        fps: 29.97,
        link: "https://player.vimeo.com/external/308735032.sd.mp4?s=1eb70c0f0f328cfbaae7f5b03123a387aea10e50&profile_id=164&oauth2_token_id=57447761",
      },
      {
        id: 71481,
        quality: "hd",
        file_type: "video/mp4",
        width: 1280,
        height: 720,
        fps: 59.94,
        link: "https://player.vimeo.com/external/308735032.hd.mp4?s=7d8072fdd91b21ec87b37e09b51fd577cc803e7c&profile_id=174&oauth2_token_id=57447761",
      },
      {
        id: 71482,
        quality: "hd",
        file_type: "video/mp4",
        width: 1920,
        height: 1080,
        fps: null,
        link: "https://player.vimeo.com/external/308735032.hd.mp4?s=7d8072fdd91b21ec87b37e09b51fd577cc803e7c&profile_id=175&oauth2_token_id=57447761",
      },
      {
        id: 71483,
        quality: "sd",
        file_type: "video/mp4",
        width: 960,
        height: 540,
        fps: 29.97,
        link: "https://player.vimeo.com/external/308735032.sd.mp4?s=1eb70c0f0f328cfbaae7f5b03123a387aea10e50&profile_id=165&oauth2_token_id=57447761",
      },
      {
        id: 71484,
        quality: "hd",
        file_type: "video/mp4",
        width: 1920,
        height: 1080,
        fps: 59.94,
        link: "https://player.vimeo.com/external/308735032.hd.mp4?s=7d8072fdd91b21ec87b37e09b51fd577cc803e7c&profile_id=169&oauth2_token_id=57447761",
      },
    ],
    video_pictures: [
      {
        id: 171968,
        nr: 0,
        picture:
          "https://images.pexels.com/videos/1739010/pictures/preview-0.jpg",
      },
      {
        id: 171969,
        nr: 1,
        picture:
          "https://images.pexels.com/videos/1739010/pictures/preview-1.jpg",
      },
      {
        id: 171970,
        nr: 2,
        picture:
          "https://images.pexels.com/videos/1739010/pictures/preview-2.jpg",
      },
      {
        id: 171971,
        nr: 3,
        picture:
          "https://images.pexels.com/videos/1739010/pictures/preview-3.jpg",
      },
      {
        id: 171972,
        nr: 4,
        picture:
          "https://images.pexels.com/videos/1739010/pictures/preview-4.jpg",
      },
      {
        id: 171973,
        nr: 5,
        picture:
          "https://images.pexels.com/videos/1739010/pictures/preview-5.jpg",
      },
      {
        id: 171974,
        nr: 6,
        picture:
          "https://images.pexels.com/videos/1739010/pictures/preview-6.jpg",
      },
      {
        id: 171975,
        nr: 7,
        picture:
          "https://images.pexels.com/videos/1739010/pictures/preview-7.jpg",
      },
      {
        id: 171976,
        nr: 8,
        picture:
          "https://images.pexels.com/videos/1739010/pictures/preview-8.jpg",
      },
      {
        id: 171977,
        nr: 9,
        picture:
          "https://images.pexels.com/videos/1739010/pictures/preview-9.jpg",
      },
      {
        id: 171978,
        nr: 10,
        picture:
          "https://images.pexels.com/videos/1739010/pictures/preview-10.jpg",
      },
      {
        id: 171979,
        nr: 11,
        picture:
          "https://images.pexels.com/videos/1739010/pictures/preview-11.jpg",
      },
      {
        id: 171980,
        nr: 12,
        picture:
          "https://images.pexels.com/videos/1739010/pictures/preview-12.jpg",
      },
      {
        id: 171981,
        nr: 13,
        picture:
          "https://images.pexels.com/videos/1739010/pictures/preview-13.jpg",
      },
      {
        id: 171982,
        nr: 14,
        picture:
          "https://images.pexels.com/videos/1739010/pictures/preview-14.jpg",
      },
    ],
  },
  {
    id: 3578881,
    width: 3840,
    height: 2160,
    duration: 24,
    full_res: null,
    tags: [],
    url: "https://www.pexels.com/video/the-rocky-coastline-of-in-northern-california-3578881/",
    image:
      "https://images.pexels.com/videos/3578881/free-video-3578881.jpg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
    avg_color: null,
    user: {
      id: 1945523,
      name: "Paulo Renato",
      url: "https://www.pexels.com/@paulo-renato-1945523",
    },
    video_files: [
      {
        id: 370357,
        quality: "hd",
        file_type: "video/mp4",
        width: 2560,
        height: 1440,
        fps: 29.97,
        link: "https://player.vimeo.com/external/385147580.hd.mp4?s=3277cfbe8d03b150112cb488330815eb4b255315&profile_id=170&oauth2_token_id=57447761",
      },
      {
        id: 370358,
        quality: "sd",
        file_type: "video/mp4",
        width: 640,
        height: 360,
        fps: 29.97,
        link: "https://player.vimeo.com/external/385147580.sd.mp4?s=e6a0ffe2125b290636bd7a765e492e900b9e4013&profile_id=164&oauth2_token_id=57447761",
      },
      {
        id: 370359,
        quality: "sd",
        file_type: "video/mp4",
        width: 960,
        height: 540,
        fps: 29.97,
        link: "https://player.vimeo.com/external/385147580.sd.mp4?s=e6a0ffe2125b290636bd7a765e492e900b9e4013&profile_id=165&oauth2_token_id=57447761",
      },
      {
        id: 370360,
        quality: "hd",
        file_type: "video/mp4",
        width: 1920,
        height: 1080,
        fps: 29.97,
        link: "https://player.vimeo.com/external/385147580.hd.mp4?s=3277cfbe8d03b150112cb488330815eb4b255315&profile_id=175&oauth2_token_id=57447761",
      },
      {
        id: 370361,
        quality: "hd",
        file_type: "video/mp4",
        width: 3840,
        height: 2160,
        fps: 29.97,
        link: "https://player.vimeo.com/external/385147580.hd.mp4?s=3277cfbe8d03b150112cb488330815eb4b255315&profile_id=172&oauth2_token_id=57447761",
      },
      {
        id: 370362,
        quality: "sd",
        file_type: "video/mp4",
        width: 426,
        height: 240,
        fps: 29.97,
        link: "https://player.vimeo.com/external/385147580.sd.mp4?s=e6a0ffe2125b290636bd7a765e492e900b9e4013&profile_id=139&oauth2_token_id=57447761",
      },
      {
        id: 370363,
        quality: "hd",
        file_type: "video/mp4",
        width: 1280,
        height: 720,
        fps: 29.97,
        link: "https://player.vimeo.com/external/385147580.hd.mp4?s=3277cfbe8d03b150112cb488330815eb4b255315&profile_id=174&oauth2_token_id=57447761",
      },
    ],
    video_pictures: [
      {
        id: 818730,
        nr: 0,
        picture:
          "https://images.pexels.com/videos/3578881/pictures/preview-0.jpg",
      },
      {
        id: 818734,
        nr: 1,
        picture:
          "https://images.pexels.com/videos/3578881/pictures/preview-1.jpg",
      },
      {
        id: 818738,
        nr: 2,
        picture:
          "https://images.pexels.com/videos/3578881/pictures/preview-2.jpg",
      },
      {
        id: 818743,
        nr: 3,
        picture:
          "https://images.pexels.com/videos/3578881/pictures/preview-3.jpg",
      },
      {
        id: 818749,
        nr: 4,
        picture:
          "https://images.pexels.com/videos/3578881/pictures/preview-4.jpg",
      },
      {
        id: 818753,
        nr: 5,
        picture:
          "https://images.pexels.com/videos/3578881/pictures/preview-5.jpg",
      },
      {
        id: 818758,
        nr: 6,
        picture:
          "https://images.pexels.com/videos/3578881/pictures/preview-6.jpg",
      },
      {
        id: 818764,
        nr: 7,
        picture:
          "https://images.pexels.com/videos/3578881/pictures/preview-7.jpg",
      },
      {
        id: 818770,
        nr: 8,
        picture:
          "https://images.pexels.com/videos/3578881/pictures/preview-8.jpg",
      },
      {
        id: 818775,
        nr: 9,
        picture:
          "https://images.pexels.com/videos/3578881/pictures/preview-9.jpg",
      },
      {
        id: 818780,
        nr: 10,
        picture:
          "https://images.pexels.com/videos/3578881/pictures/preview-10.jpg",
      },
      {
        id: 818783,
        nr: 11,
        picture:
          "https://images.pexels.com/videos/3578881/pictures/preview-11.jpg",
      },
      {
        id: 818787,
        nr: 12,
        picture:
          "https://images.pexels.com/videos/3578881/pictures/preview-12.jpg",
      },
      {
        id: 818789,
        nr: 13,
        picture:
          "https://images.pexels.com/videos/3578881/pictures/preview-13.jpg",
      },
      {
        id: 818793,
        nr: 14,
        picture:
          "https://images.pexels.com/videos/3578881/pictures/preview-14.jpg",
      },
    ],
  },
  {
    id: 7068548,
    width: 2160,
    height: 3840,
    duration: 10,
    full_res: null,
    tags: [],
    url: "https://www.pexels.com/video/drone-footage-of-a-city-at-night-7068548/",
    image:
      "https://images.pexels.com/videos/7068548/pexels-photo-7068548.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=630",
    avg_color: null,
    user: {
      id: 32566274,
      name: "Laura Tancredi",
      url: "https://www.pexels.com/@laura-tancredi",
    },
    video_files: [
      {
        id: 2741803,
        quality: "hd",
        file_type: "video/mp4",
        width: 720,
        height: 1280,
        fps: 23.976,
        link: "https://player.vimeo.com/external/520601116.hd.mp4?s=4ae23e66227f0cbc9671dd36587dd0c49f1a13a2&profile_id=174&oauth2_token_id=57447761",
      },
      {
        id: 2741804,
        quality: "hd",
        file_type: "video/mp4",
        width: 2160,
        height: 3840,
        fps: 23.976,
        link: "https://player.vimeo.com/external/520601116.hd.mp4?s=4ae23e66227f0cbc9671dd36587dd0c49f1a13a2&profile_id=172&oauth2_token_id=57447761",
      },
      {
        id: 2741805,
        quality: "hd",
        file_type: "video/mp4",
        width: 1440,
        height: 2560,
        fps: 23.976,
        link: "https://player.vimeo.com/external/520601116.hd.mp4?s=4ae23e66227f0cbc9671dd36587dd0c49f1a13a2&profile_id=170&oauth2_token_id=57447761",
      },
      {
        id: 2741806,
        quality: "hd",
        file_type: "video/mp4",
        width: 1080,
        height: 1920,
        fps: 23.976,
        link: "https://player.vimeo.com/external/520601116.hd.mp4?s=4ae23e66227f0cbc9671dd36587dd0c49f1a13a2&profile_id=175&oauth2_token_id=57447761",
      },
      {
        id: 2741807,
        quality: "sd",
        file_type: "video/mp4",
        width: 540,
        height: 960,
        fps: 23.976,
        link: "https://player.vimeo.com/external/520601116.sd.mp4?s=48833f67d455d6042186c0bf5f4fc12298ba54ce&profile_id=165&oauth2_token_id=57447761",
      },
      {
        id: 2741808,
        quality: "sd",
        file_type: "video/mp4",
        width: 360,
        height: 640,
        fps: 23.976,
        link: "https://player.vimeo.com/external/520601116.sd.mp4?s=48833f67d455d6042186c0bf5f4fc12298ba54ce&profile_id=164&oauth2_token_id=57447761",
      },
      {
        id: 2741809,
        quality: "sd",
        file_type: "video/mp4",
        width: 240,
        height: 426,
        fps: 23.976,
        link: "https://player.vimeo.com/external/520601116.sd.mp4?s=48833f67d455d6042186c0bf5f4fc12298ba54ce&profile_id=139&oauth2_token_id=57447761",
      },
    ],
    video_pictures: [
      {
        id: 5682252,
        nr: 0,
        picture:
          "https://images.pexels.com/videos/7068548/pictures/preview-0.jpeg",
      },
      {
        id: 5682253,
        nr: 1,
        picture:
          "https://images.pexels.com/videos/7068548/pictures/preview-1.jpeg",
      },
      {
        id: 5682254,
        nr: 2,
        picture:
          "https://images.pexels.com/videos/7068548/pictures/preview-2.jpeg",
      },
      {
        id: 5682255,
        nr: 3,
        picture:
          "https://images.pexels.com/videos/7068548/pictures/preview-3.jpeg",
      },
      {
        id: 5682256,
        nr: 4,
        picture:
          "https://images.pexels.com/videos/7068548/pictures/preview-4.jpeg",
      },
      {
        id: 5682257,
        nr: 5,
        picture:
          "https://images.pexels.com/videos/7068548/pictures/preview-5.jpeg",
      },
      {
        id: 5682258,
        nr: 6,
        picture:
          "https://images.pexels.com/videos/7068548/pictures/preview-6.jpeg",
      },
      {
        id: 5682259,
        nr: 7,
        picture:
          "https://images.pexels.com/videos/7068548/pictures/preview-7.jpeg",
      },
      {
        id: 5682260,
        nr: 8,
        picture:
          "https://images.pexels.com/videos/7068548/pictures/preview-8.jpeg",
      },
      {
        id: 5682261,
        nr: 9,
        picture:
          "https://images.pexels.com/videos/7068548/pictures/preview-9.jpeg",
      },
      {
        id: 5682262,
        nr: 10,
        picture:
          "https://images.pexels.com/videos/7068548/pictures/preview-10.jpeg",
      },
      {
        id: 5682263,
        nr: 11,
        picture:
          "https://images.pexels.com/videos/7068548/pictures/preview-11.jpeg",
      },
      {
        id: 5682264,
        nr: 12,
        picture:
          "https://images.pexels.com/videos/7068548/pictures/preview-12.jpeg",
      },
      {
        id: 5682265,
        nr: 13,
        picture:
          "https://images.pexels.com/videos/7068548/pictures/preview-13.jpeg",
      },
      {
        id: 5682266,
        nr: 14,
        picture:
          "https://images.pexels.com/videos/7068548/pictures/preview-14.jpeg",
      },
    ],
  },
  {
    id: 6892734,
    width: 4096,
    height: 2160,
    duration: 46,
    full_res: null,
    tags: [],
    url: "https://www.pexels.com/video/plugging-in-the-microphone-6892734/",
    image:
      "https://images.pexels.com/videos/6892734/pexels-photo-6892734.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
    avg_color: null,
    user: {
      id: 1437723,
      name: "cottonbro studio",
      url: "https://www.pexels.com/@cottonbro",
    },
    video_files: [
      {
        id: 2574444,
        quality: "hd",
        file_type: "video/mp4",
        width: 2732,
        height: 1440,
        fps: 25,
        link: "https://player.vimeo.com/external/514577229.hd.mp4?s=3d9f6608ae395ff9551f01914f8fd5acb9a74329&profile_id=170&oauth2_token_id=57447761",
      },
      {
        id: 2574445,
        quality: "sd",
        file_type: "video/mp4",
        width: 640,
        height: 338,
        fps: 25,
        link: "https://player.vimeo.com/external/514577229.sd.mp4?s=8c8e6e4f18eed00fccab6a57dc59dc2e9b09a4ce&profile_id=164&oauth2_token_id=57447761",
      },
      {
        id: 2574446,
        quality: "hd",
        file_type: "video/mp4",
        width: 2048,
        height: 1080,
        fps: 25,
        link: "https://player.vimeo.com/external/514577229.hd.mp4?s=3d9f6608ae395ff9551f01914f8fd5acb9a74329&profile_id=175&oauth2_token_id=57447761",
      },
      {
        id: 2574447,
        quality: "hd",
        file_type: "video/mp4",
        width: 4096,
        height: 2160,
        fps: 25,
        link: "https://player.vimeo.com/external/514577229.hd.mp4?s=3d9f6608ae395ff9551f01914f8fd5acb9a74329&profile_id=172&oauth2_token_id=57447761",
      },
      {
        id: 2574448,
        quality: "sd",
        file_type: "video/mp4",
        width: 426,
        height: 226,
        fps: 25,
        link: "https://player.vimeo.com/external/514577229.sd.mp4?s=8c8e6e4f18eed00fccab6a57dc59dc2e9b09a4ce&profile_id=139&oauth2_token_id=57447761",
      },
      {
        id: 2574449,
        quality: "hd",
        file_type: "video/mp4",
        width: 1366,
        height: 720,
        fps: 25,
        link: "https://player.vimeo.com/external/514577229.hd.mp4?s=3d9f6608ae395ff9551f01914f8fd5acb9a74329&profile_id=174&oauth2_token_id=57447761",
      },
      {
        id: 2574450,
        quality: "sd",
        file_type: "video/mp4",
        width: 960,
        height: 506,
        fps: 25,
        link: "https://player.vimeo.com/external/514577229.sd.mp4?s=8c8e6e4f18eed00fccab6a57dc59dc2e9b09a4ce&profile_id=165&oauth2_token_id=57447761",
      },
    ],
    video_pictures: [
      {
        id: 5313079,
        nr: 0,
        picture:
          "https://images.pexels.com/videos/6892734/pictures/preview-0.jpg",
      },
      {
        id: 5313086,
        nr: 1,
        picture:
          "https://images.pexels.com/videos/6892734/pictures/preview-1.jpg",
      },
      {
        id: 5313089,
        nr: 2,
        picture:
          "https://images.pexels.com/videos/6892734/pictures/preview-2.jpg",
      },
      {
        id: 5313097,
        nr: 3,
        picture:
          "https://images.pexels.com/videos/6892734/pictures/preview-3.jpg",
      },
      {
        id: 5313110,
        nr: 4,
        picture:
          "https://images.pexels.com/videos/6892734/pictures/preview-4.jpg",
      },
      {
        id: 5313119,
        nr: 5,
        picture:
          "https://images.pexels.com/videos/6892734/pictures/preview-5.jpg",
      },
      {
        id: 5313128,
        nr: 6,
        picture:
          "https://images.pexels.com/videos/6892734/pictures/preview-6.jpg",
      },
      {
        id: 5313138,
        nr: 7,
        picture:
          "https://images.pexels.com/videos/6892734/pictures/preview-7.jpg",
      },
      {
        id: 5313146,
        nr: 8,
        picture:
          "https://images.pexels.com/videos/6892734/pictures/preview-8.jpg",
      },
      {
        id: 5313156,
        nr: 9,
        picture:
          "https://images.pexels.com/videos/6892734/pictures/preview-9.jpg",
      },
      {
        id: 5313165,
        nr: 10,
        picture:
          "https://images.pexels.com/videos/6892734/pictures/preview-10.jpg",
      },
      {
        id: 5313175,
        nr: 11,
        picture:
          "https://images.pexels.com/videos/6892734/pictures/preview-11.jpg",
      },
      {
        id: 5313187,
        nr: 12,
        picture:
          "https://images.pexels.com/videos/6892734/pictures/preview-12.jpg",
      },
      {
        id: 5313195,
        nr: 13,
        picture:
          "https://images.pexels.com/videos/6892734/pictures/preview-13.jpg",
      },
      {
        id: 5313209,
        nr: 14,
        picture:
          "https://images.pexels.com/videos/6892734/pictures/preview-14.jpg",
      },
    ],
  },
  {
    id: 3752548,
    width: 1920,
    height: 1080,
    duration: 25,
    full_res: null,
    tags: [],
    url: "https://www.pexels.com/video/close-up-view-of-paper-money-inside-a-bottle-3752548/",
    image:
      "https://images.pexels.com/videos/3752548/pexels-photo-3752548.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
    avg_color: null,
    user: {
      id: 722822,
      name: "Ricky Esquivel",
      url: "https://www.pexels.com/@rickyrecap",
    },
    video_files: [
      {
        id: 412654,
        quality: "sd",
        file_type: "video/mp4",
        width: 640,
        height: 360,
        fps: 23.976,
        link: "https://player.vimeo.com/external/391913728.sd.mp4?s=27dc9a39611371221cc5c3b353b26da1f4aee897&profile_id=164&oauth2_token_id=57447761",
      },
      {
        id: 412655,
        quality: "hd",
        file_type: "video/mp4",
        width: 1280,
        height: 720,
        fps: 23.976,
        link: "https://player.vimeo.com/external/391913728.hd.mp4?s=84ce36816c2a3c67db739f91b2772e43b2cc93e9&profile_id=174&oauth2_token_id=57447761",
      },
      {
        id: 412656,
        quality: "hd",
        file_type: "video/mp4",
        width: 1920,
        height: 1080,
        fps: 23.976,
        link: "https://player.vimeo.com/external/391913728.hd.mp4?s=84ce36816c2a3c67db739f91b2772e43b2cc93e9&profile_id=175&oauth2_token_id=57447761",
      },
      {
        id: 412657,
        quality: "sd",
        file_type: "video/mp4",
        width: 960,
        height: 540,
        fps: 23.976,
        link: "https://player.vimeo.com/external/391913728.sd.mp4?s=27dc9a39611371221cc5c3b353b26da1f4aee897&profile_id=165&oauth2_token_id=57447761",
      },
      {
        id: 412658,
        quality: "sd",
        file_type: "video/mp4",
        width: 426,
        height: 240,
        fps: 23.976,
        link: "https://player.vimeo.com/external/391913728.sd.mp4?s=27dc9a39611371221cc5c3b353b26da1f4aee897&profile_id=139&oauth2_token_id=57447761",
      },
    ],
    video_pictures: [
      {
        id: 904058,
        nr: 0,
        picture:
          "https://images.pexels.com/videos/3752548/pictures/preview-0.jpg",
      },
      {
        id: 904063,
        nr: 1,
        picture:
          "https://images.pexels.com/videos/3752548/pictures/preview-1.jpg",
      },
      {
        id: 904071,
        nr: 2,
        picture:
          "https://images.pexels.com/videos/3752548/pictures/preview-2.jpg",
      },
      {
        id: 904077,
        nr: 3,
        picture:
          "https://images.pexels.com/videos/3752548/pictures/preview-3.jpg",
      },
      {
        id: 904083,
        nr: 4,
        picture:
          "https://images.pexels.com/videos/3752548/pictures/preview-4.jpg",
      },
      {
        id: 904088,
        nr: 5,
        picture:
          "https://images.pexels.com/videos/3752548/pictures/preview-5.jpg",
      },
      {
        id: 904093,
        nr: 6,
        picture:
          "https://images.pexels.com/videos/3752548/pictures/preview-6.jpg",
      },
      {
        id: 904099,
        nr: 7,
        picture:
          "https://images.pexels.com/videos/3752548/pictures/preview-7.jpg",
      },
      {
        id: 904103,
        nr: 8,
        picture:
          "https://images.pexels.com/videos/3752548/pictures/preview-8.jpg",
      },
      {
        id: 904105,
        nr: 9,
        picture:
          "https://images.pexels.com/videos/3752548/pictures/preview-9.jpg",
      },
      {
        id: 904107,
        nr: 10,
        picture:
          "https://images.pexels.com/videos/3752548/pictures/preview-10.jpg",
      },
      {
        id: 904109,
        nr: 11,
        picture:
          "https://images.pexels.com/videos/3752548/pictures/preview-11.jpg",
      },
      {
        id: 904111,
        nr: 12,
        picture:
          "https://images.pexels.com/videos/3752548/pictures/preview-12.jpg",
      },
      {
        id: 904113,
        nr: 13,
        picture:
          "https://images.pexels.com/videos/3752548/pictures/preview-13.jpg",
      },
      {
        id: 904114,
        nr: 14,
        picture:
          "https://images.pexels.com/videos/3752548/pictures/preview-14.jpg",
      },
    ],
  },
  {
    id: 4167404,
    width: 3024,
    height: 4032,
    duration: 19,
    full_res: null,
    tags: [],
    url: "https://www.pexels.com/video/apple-macbook-ipad-imac-4167404/",
    image:
      "https://images.pexels.com/videos/4167404/alta-tecnologia-apple-dron-drone-video-4167404.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=630",
    avg_color: null,
    user: {
      id: 1272071,
      name: "Alan Quirván",
      url: "https://www.pexels.com/@quirva",
    },
    video_files: [
      {
        id: 597441,
        quality: "hd",
        file_type: "video/mp4",
        width: 2160,
        height: 2880,
        fps: 24,
        link: "https://player.vimeo.com/external/408278747.hd.mp4?s=582abd94ed00a6e0e052c7933381eeaa37ad8948&profile_id=172&oauth2_token_id=57447761",
      },
      {
        id: 597442,
        quality: "hd",
        file_type: "video/mp4",
        width: 1440,
        height: 1920,
        fps: 24,
        link: "https://player.vimeo.com/external/408278747.hd.mp4?s=582abd94ed00a6e0e052c7933381eeaa37ad8948&profile_id=170&oauth2_token_id=57447761",
      },
      {
        id: 597443,
        quality: "sd",
        file_type: "video/mp4",
        width: 360,
        height: 480,
        fps: 24,
        link: "https://player.vimeo.com/external/408278747.sd.mp4?s=22ce19663174765b4faafb6e11fca46620149ad3&profile_id=164&oauth2_token_id=57447761",
      },
      {
        id: 597444,
        quality: "sd",
        file_type: "video/mp4",
        width: 540,
        height: 720,
        fps: 24,
        link: "https://player.vimeo.com/external/408278747.sd.mp4?s=22ce19663174765b4faafb6e11fca46620149ad3&profile_id=165&oauth2_token_id=57447761",
      },
      {
        id: 597445,
        quality: "sd",
        file_type: "video/mp4",
        width: 240,
        height: 320,
        fps: 24,
        link: "https://player.vimeo.com/external/408278747.sd.mp4?s=22ce19663174765b4faafb6e11fca46620149ad3&profile_id=139&oauth2_token_id=57447761",
      },
      {
        id: 597446,
        quality: "hd",
        file_type: "video/mp4",
        width: 720,
        height: 960,
        fps: 24,
        link: "https://player.vimeo.com/external/408278747.hd.mp4?s=582abd94ed00a6e0e052c7933381eeaa37ad8948&profile_id=174&oauth2_token_id=57447761",
      },
      {
        id: 597447,
        quality: "hd",
        file_type: "video/mp4",
        width: 1080,
        height: 1440,
        fps: 24,
        link: "https://player.vimeo.com/external/408278747.hd.mp4?s=582abd94ed00a6e0e052c7933381eeaa37ad8948&profile_id=175&oauth2_token_id=57447761",
      },
    ],
    video_pictures: [
      {
        id: 1222699,
        nr: 0,
        picture:
          "https://images.pexels.com/videos/4167404/pictures/preview-0.jpeg",
      },
      {
        id: 1222700,
        nr: 1,
        picture:
          "https://images.pexels.com/videos/4167404/pictures/preview-1.jpeg",
      },
      {
        id: 1222703,
        nr: 2,
        picture:
          "https://images.pexels.com/videos/4167404/pictures/preview-2.jpeg",
      },
      {
        id: 1222707,
        nr: 3,
        picture:
          "https://images.pexels.com/videos/4167404/pictures/preview-3.jpeg",
      },
      {
        id: 1222711,
        nr: 4,
        picture:
          "https://images.pexels.com/videos/4167404/pictures/preview-4.jpeg",
      },
      {
        id: 1222715,
        nr: 5,
        picture:
          "https://images.pexels.com/videos/4167404/pictures/preview-5.jpeg",
      },
      {
        id: 1222718,
        nr: 6,
        picture:
          "https://images.pexels.com/videos/4167404/pictures/preview-6.jpeg",
      },
      {
        id: 1222721,
        nr: 7,
        picture:
          "https://images.pexels.com/videos/4167404/pictures/preview-7.jpeg",
      },
      {
        id: 1222722,
        nr: 8,
        picture:
          "https://images.pexels.com/videos/4167404/pictures/preview-8.jpeg",
      },
      {
        id: 1222723,
        nr: 9,
        picture:
          "https://images.pexels.com/videos/4167404/pictures/preview-9.jpeg",
      },
      {
        id: 1222724,
        nr: 10,
        picture:
          "https://images.pexels.com/videos/4167404/pictures/preview-10.jpeg",
      },
      {
        id: 1222725,
        nr: 11,
        picture:
          "https://images.pexels.com/videos/4167404/pictures/preview-11.jpeg",
      },
      {
        id: 1222726,
        nr: 12,
        picture:
          "https://images.pexels.com/videos/4167404/pictures/preview-12.jpeg",
      },
      {
        id: 1222727,
        nr: 13,
        picture:
          "https://images.pexels.com/videos/4167404/pictures/preview-13.jpeg",
      },
      {
        id: 1222728,
        nr: 14,
        picture:
          "https://images.pexels.com/videos/4167404/pictures/preview-14.jpeg",
      },
    ],
  },
  {
    id: 2887463,
    width: 1920,
    height: 1080,
    duration: 10,
    full_res: null,
    tags: [],
    url: "https://www.pexels.com/video/a-computer-monitor-flashing-digital-information-2887463/",
    image:
      "https://images.pexels.com/videos/2887463/free-video-2887463.jpg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
    avg_color: null,
    user: {
      id: 1284006,
      name: "Bedrijfsfilmspecialist.nl",
      url: "https://www.pexels.com/@bedrijfsfilmspecialist-nl-1284006",
    },
    video_files: [
      {
        id: 175062,
        quality: "sd",
        file_type: "video/mp4",
        width: 640,
        height: 360,
        fps: 25,
        link: "https://player.vimeo.com/external/357563488.sd.mp4?s=b1cfbc7c4eb11d8a10dc5dfe36ed63466cb3da61&profile_id=164&oauth2_token_id=57447761",
      },
      {
        id: 175063,
        quality: "hd",
        file_type: "video/mp4",
        width: 1920,
        height: 1080,
        fps: 25,
        link: "https://player.vimeo.com/external/357563488.hd.mp4?s=42dbf432200b04ea74f2cbab4c9d455656dea0ff&profile_id=175&oauth2_token_id=57447761",
      },
      {
        id: 175064,
        quality: "sd",
        file_type: "video/mp4",
        width: 960,
        height: 540,
        fps: 25,
        link: "https://player.vimeo.com/external/357563488.sd.mp4?s=b1cfbc7c4eb11d8a10dc5dfe36ed63466cb3da61&profile_id=165&oauth2_token_id=57447761",
      },
      {
        id: 175065,
        quality: "sd",
        file_type: "video/mp4",
        width: 426,
        height: 240,
        fps: 25,
        link: "https://player.vimeo.com/external/357563488.sd.mp4?s=b1cfbc7c4eb11d8a10dc5dfe36ed63466cb3da61&profile_id=139&oauth2_token_id=57447761",
      },
      {
        id: 175066,
        quality: "hd",
        file_type: "video/mp4",
        width: 1280,
        height: 720,
        fps: 25,
        link: "https://player.vimeo.com/external/357563488.hd.mp4?s=42dbf432200b04ea74f2cbab4c9d455656dea0ff&profile_id=174&oauth2_token_id=57447761",
      },
    ],
    video_pictures: [
      {
        id: 419715,
        nr: 0,
        picture:
          "https://images.pexels.com/videos/2887463/pictures/preview-0.jpg",
      },
      {
        id: 419716,
        nr: 1,
        picture:
          "https://images.pexels.com/videos/2887463/pictures/preview-1.jpg",
      },
      {
        id: 419718,
        nr: 2,
        picture:
          "https://images.pexels.com/videos/2887463/pictures/preview-2.jpg",
      },
      {
        id: 419720,
        nr: 3,
        picture:
          "https://images.pexels.com/videos/2887463/pictures/preview-3.jpg",
      },
      {
        id: 419722,
        nr: 4,
        picture:
          "https://images.pexels.com/videos/2887463/pictures/preview-4.jpg",
      },
      {
        id: 419724,
        nr: 5,
        picture:
          "https://images.pexels.com/videos/2887463/pictures/preview-5.jpg",
      },
      {
        id: 419725,
        nr: 6,
        picture:
          "https://images.pexels.com/videos/2887463/pictures/preview-6.jpg",
      },
      {
        id: 419727,
        nr: 7,
        picture:
          "https://images.pexels.com/videos/2887463/pictures/preview-7.jpg",
      },
      {
        id: 419729,
        nr: 8,
        picture:
          "https://images.pexels.com/videos/2887463/pictures/preview-8.jpg",
      },
      {
        id: 419730,
        nr: 9,
        picture:
          "https://images.pexels.com/videos/2887463/pictures/preview-9.jpg",
      },
      {
        id: 419732,
        nr: 10,
        picture:
          "https://images.pexels.com/videos/2887463/pictures/preview-10.jpg",
      },
      {
        id: 419734,
        nr: 11,
        picture:
          "https://images.pexels.com/videos/2887463/pictures/preview-11.jpg",
      },
      {
        id: 419736,
        nr: 12,
        picture:
          "https://images.pexels.com/videos/2887463/pictures/preview-12.jpg",
      },
      {
        id: 419738,
        nr: 13,
        picture:
          "https://images.pexels.com/videos/2887463/pictures/preview-13.jpg",
      },
      {
        id: 419739,
        nr: 14,
        picture:
          "https://images.pexels.com/videos/2887463/pictures/preview-14.jpg",
      },
    ],
  },
  {
    id: 2932301,
    width: 4096,
    height: 2160,
    duration: 15,
    full_res: null,
    tags: [],
    url: "https://www.pexels.com/video/group-of-boys-plays-soccer-in-a-soccer-field-2932301/",
    image:
      "https://images.pexels.com/videos/2932301/free-video-2932301.jpg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
    avg_color: null,
    user: {
      id: 1179532,
      name: "Kelly",
      url: "https://www.pexels.com/@kelly-1179532",
    },
    video_files: [
      {
        id: 181566,
        quality: "sd",
        file_type: "video/mp4",
        width: 426,
        height: 226,
        fps: 24,
        link: "https://player.vimeo.com/external/359643426.sd.mp4?s=4a75f52542fa75a86e6780921525b7bb83f2c16c&profile_id=139&oauth2_token_id=57447761",
      },
      {
        id: 181567,
        quality: "hd",
        file_type: "video/mp4",
        width: 4096,
        height: 2160,
        fps: 24,
        link: "https://player.vimeo.com/external/359643426.hd.mp4?s=3628f602f874c418c2828b980cc77b2d8a44e0f4&profile_id=172&oauth2_token_id=57447761",
      },
      {
        id: 181568,
        quality: "hd",
        file_type: "video/mp4",
        width: 1366,
        height: 720,
        fps: 24,
        link: "https://player.vimeo.com/external/359643426.hd.mp4?s=3628f602f874c418c2828b980cc77b2d8a44e0f4&profile_id=174&oauth2_token_id=57447761",
      },
      {
        id: 181569,
        quality: "sd",
        file_type: "video/mp4",
        width: 640,
        height: 338,
        fps: 24,
        link: "https://player.vimeo.com/external/359643426.sd.mp4?s=4a75f52542fa75a86e6780921525b7bb83f2c16c&profile_id=164&oauth2_token_id=57447761",
      },
      {
        id: 181570,
        quality: "hd",
        file_type: "video/mp4",
        width: 2732,
        height: 1440,
        fps: 24,
        link: "https://player.vimeo.com/external/359643426.hd.mp4?s=3628f602f874c418c2828b980cc77b2d8a44e0f4&profile_id=170&oauth2_token_id=57447761",
      },
      {
        id: 181571,
        quality: "hd",
        file_type: "video/mp4",
        width: 2048,
        height: 1080,
        fps: 24,
        link: "https://player.vimeo.com/external/359643426.hd.mp4?s=3628f602f874c418c2828b980cc77b2d8a44e0f4&profile_id=175&oauth2_token_id=57447761",
      },
      {
        id: 181572,
        quality: "sd",
        file_type: "video/mp4",
        width: 960,
        height: 506,
        fps: 24,
        link: "https://player.vimeo.com/external/359643426.sd.mp4?s=4a75f52542fa75a86e6780921525b7bb83f2c16c&profile_id=165&oauth2_token_id=57447761",
      },
    ],
    video_pictures: [
      {
        id: 433902,
        nr: 0,
        picture:
          "https://images.pexels.com/videos/2932301/pictures/preview-0.jpg",
      },
      {
        id: 433906,
        nr: 1,
        picture:
          "https://images.pexels.com/videos/2932301/pictures/preview-1.jpg",
      },
      {
        id: 433912,
        nr: 2,
        picture:
          "https://images.pexels.com/videos/2932301/pictures/preview-2.jpg",
      },
      {
        id: 433917,
        nr: 3,
        picture:
          "https://images.pexels.com/videos/2932301/pictures/preview-3.jpg",
      },
      {
        id: 433922,
        nr: 4,
        picture:
          "https://images.pexels.com/videos/2932301/pictures/preview-4.jpg",
      },
      {
        id: 433925,
        nr: 5,
        picture:
          "https://images.pexels.com/videos/2932301/pictures/preview-5.jpg",
      },
      {
        id: 433930,
        nr: 6,
        picture:
          "https://images.pexels.com/videos/2932301/pictures/preview-6.jpg",
      },
      {
        id: 433934,
        nr: 7,
        picture:
          "https://images.pexels.com/videos/2932301/pictures/preview-7.jpg",
      },
      {
        id: 433937,
        nr: 8,
        picture:
          "https://images.pexels.com/videos/2932301/pictures/preview-8.jpg",
      },
      {
        id: 433940,
        nr: 9,
        picture:
          "https://images.pexels.com/videos/2932301/pictures/preview-9.jpg",
      },
      {
        id: 433944,
        nr: 10,
        picture:
          "https://images.pexels.com/videos/2932301/pictures/preview-10.jpg",
      },
      {
        id: 433946,
        nr: 11,
        picture:
          "https://images.pexels.com/videos/2932301/pictures/preview-11.jpg",
      },
      {
        id: 433949,
        nr: 12,
        picture:
          "https://images.pexels.com/videos/2932301/pictures/preview-12.jpg",
      },
      {
        id: 433951,
        nr: 13,
        picture:
          "https://images.pexels.com/videos/2932301/pictures/preview-13.jpg",
      },
      {
        id: 433955,
        nr: 14,
        picture:
          "https://images.pexels.com/videos/2932301/pictures/preview-14.jpg",
      },
    ],
  },
  {
    id: 4065388,
    width: 3840,
    height: 2160,
    duration: 16,
    full_res: null,
    tags: [],
    url: "https://www.pexels.com/video/sunrise-training-young-man-early-morning-4065388/",
    image:
      "https://images.pexels.com/videos/4065388/pexels-photo-4065388.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
    avg_color: null,
    user: {
      id: 2297095,
      name: "Pavel Danilyuk",
      url: "https://www.pexels.com/@pavel-danilyuk",
    },
    video_files: [
      {
        id: 533523,
        quality: "hd",
        file_type: "video/mp4",
        width: 1920,
        height: 1080,
        fps: 29.97,
        link: "https://player.vimeo.com/external/403637875.hd.mp4?s=89538d99c3b9ed017181ccd4d1c6438b9481e272&profile_id=175&oauth2_token_id=57447761",
      },
      {
        id: 533524,
        quality: "sd",
        file_type: "video/mp4",
        width: 960,
        height: 540,
        fps: 29.97,
        link: "https://player.vimeo.com/external/403637875.sd.mp4?s=203929c386ecb701b2d70b551df5450a45a38ec0&profile_id=165&oauth2_token_id=57447761",
      },
      {
        id: 533525,
        quality: "hd",
        file_type: "video/mp4",
        width: 2560,
        height: 1440,
        fps: 29.97,
        link: "https://player.vimeo.com/external/403637875.hd.mp4?s=89538d99c3b9ed017181ccd4d1c6438b9481e272&profile_id=170&oauth2_token_id=57447761",
      },
      {
        id: 533526,
        quality: "sd",
        file_type: "video/mp4",
        width: 426,
        height: 240,
        fps: 29.97,
        link: "https://player.vimeo.com/external/403637875.sd.mp4?s=203929c386ecb701b2d70b551df5450a45a38ec0&profile_id=139&oauth2_token_id=57447761",
      },
      {
        id: 533527,
        quality: "sd",
        file_type: "video/mp4",
        width: 640,
        height: 360,
        fps: 29.97,
        link: "https://player.vimeo.com/external/403637875.sd.mp4?s=203929c386ecb701b2d70b551df5450a45a38ec0&profile_id=164&oauth2_token_id=57447761",
      },
      {
        id: 533528,
        quality: "hd",
        file_type: "video/mp4",
        width: 1280,
        height: 720,
        fps: 29.97,
        link: "https://player.vimeo.com/external/403637875.hd.mp4?s=89538d99c3b9ed017181ccd4d1c6438b9481e272&profile_id=174&oauth2_token_id=57447761",
      },
      {
        id: 533529,
        quality: "hd",
        file_type: "video/mp4",
        width: 3840,
        height: 2160,
        fps: 29.97,
        link: "https://player.vimeo.com/external/403637875.hd.mp4?s=89538d99c3b9ed017181ccd4d1c6438b9481e272&profile_id=172&oauth2_token_id=57447761",
      },
    ],
    video_pictures: [
      {
        id: 1115121,
        nr: 0,
        picture:
          "https://images.pexels.com/videos/4065388/pictures/preview-0.jpg",
      },
      {
        id: 1115123,
        nr: 1,
        picture:
          "https://images.pexels.com/videos/4065388/pictures/preview-1.jpg",
      },
      {
        id: 1115126,
        nr: 2,
        picture:
          "https://images.pexels.com/videos/4065388/pictures/preview-2.jpg",
      },
      {
        id: 1115132,
        nr: 3,
        picture:
          "https://images.pexels.com/videos/4065388/pictures/preview-3.jpg",
      },
      {
        id: 1115137,
        nr: 4,
        picture:
          "https://images.pexels.com/videos/4065388/pictures/preview-4.jpg",
      },
      {
        id: 1115140,
        nr: 5,
        picture:
          "https://images.pexels.com/videos/4065388/pictures/preview-5.jpg",
      },
      {
        id: 1115146,
        nr: 6,
        picture:
          "https://images.pexels.com/videos/4065388/pictures/preview-6.jpg",
      },
      {
        id: 1115149,
        nr: 7,
        picture:
          "https://images.pexels.com/videos/4065388/pictures/preview-7.jpg",
      },
      {
        id: 1115155,
        nr: 8,
        picture:
          "https://images.pexels.com/videos/4065388/pictures/preview-8.jpg",
      },
      {
        id: 1115160,
        nr: 9,
        picture:
          "https://images.pexels.com/videos/4065388/pictures/preview-9.jpg",
      },
      {
        id: 1115166,
        nr: 10,
        picture:
          "https://images.pexels.com/videos/4065388/pictures/preview-10.jpg",
      },
      {
        id: 1115169,
        nr: 11,
        picture:
          "https://images.pexels.com/videos/4065388/pictures/preview-11.jpg",
      },
      {
        id: 1115173,
        nr: 12,
        picture:
          "https://images.pexels.com/videos/4065388/pictures/preview-12.jpg",
      },
      {
        id: 1115178,
        nr: 13,
        picture:
          "https://images.pexels.com/videos/4065388/pictures/preview-13.jpg",
      },
      {
        id: 1115183,
        nr: 14,
        picture:
          "https://images.pexels.com/videos/4065388/pictures/preview-14.jpg",
      },
    ],
  },
  {
    id: 3192305,
    width: 3840,
    height: 2160,
    duration: 26,
    full_res: null,
    tags: [],
    url: "https://www.pexels.com/video/people-inside-a-room-having-a-discussion-3192305/",
    image:
      "https://images.pexels.com/videos/3192305/free-video-3192305.jpg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
    avg_color: null,
    user: {
      id: 1583460,
      name: "Pressmaster",
      url: "https://www.pexels.com/@pressmaster",
    },
    video_files: [
      {
        id: 254736,
        quality: "hd",
        file_type: "video/mp4",
        width: 2560,
        height: 1440,
        fps: 25,
        link: "https://player.vimeo.com/external/371618575.hd.mp4?s=5e6ba4a53580a41fd10e438bcffad8249037b9fb&profile_id=170&oauth2_token_id=57447761",
      },
      {
        id: 254737,
        quality: "hd",
        file_type: "video/mp4",
        width: 3840,
        height: 2160,
        fps: 25,
        link: "https://player.vimeo.com/external/371618575.hd.mp4?s=5e6ba4a53580a41fd10e438bcffad8249037b9fb&profile_id=172&oauth2_token_id=57447761",
      },
      {
        id: 254738,
        quality: "hd",
        file_type: "video/mp4",
        width: 1920,
        height: 1080,
        fps: 25,
        link: "https://player.vimeo.com/external/371618575.hd.mp4?s=5e6ba4a53580a41fd10e438bcffad8249037b9fb&profile_id=175&oauth2_token_id=57447761",
      },
      {
        id: 254739,
        quality: "hd",
        file_type: "video/mp4",
        width: 1280,
        height: 720,
        fps: 25,
        link: "https://player.vimeo.com/external/371618575.hd.mp4?s=5e6ba4a53580a41fd10e438bcffad8249037b9fb&profile_id=174&oauth2_token_id=57447761",
      },
      {
        id: 254740,
        quality: "sd",
        file_type: "video/mp4",
        width: 960,
        height: 540,
        fps: 25,
        link: "https://player.vimeo.com/external/371618575.sd.mp4?s=686dd3e858d207a06bf12e7f36b73c748f1d3600&profile_id=165&oauth2_token_id=57447761",
      },
      {
        id: 254741,
        quality: "sd",
        file_type: "video/mp4",
        width: 640,
        height: 360,
        fps: 25,
        link: "https://player.vimeo.com/external/371618575.sd.mp4?s=686dd3e858d207a06bf12e7f36b73c748f1d3600&profile_id=164&oauth2_token_id=57447761",
      },
      {
        id: 254742,
        quality: "sd",
        file_type: "video/mp4",
        width: 426,
        height: 240,
        fps: 25,
        link: "https://player.vimeo.com/external/371618575.sd.mp4?s=686dd3e858d207a06bf12e7f36b73c748f1d3600&profile_id=139&oauth2_token_id=57447761",
      },
    ],
    video_pictures: [
      {
        id: 574298,
        nr: 0,
        picture:
          "https://images.pexels.com/videos/3192305/pictures/preview-0.jpg",
      },
      {
        id: 574300,
        nr: 1,
        picture:
          "https://images.pexels.com/videos/3192305/pictures/preview-1.jpg",
      },
      {
        id: 574303,
        nr: 2,
        picture:
          "https://images.pexels.com/videos/3192305/pictures/preview-2.jpg",
      },
      {
        id: 574306,
        nr: 3,
        picture:
          "https://images.pexels.com/videos/3192305/pictures/preview-3.jpg",
      },
      {
        id: 574309,
        nr: 4,
        picture:
          "https://images.pexels.com/videos/3192305/pictures/preview-4.jpg",
      },
      {
        id: 574311,
        nr: 5,
        picture:
          "https://images.pexels.com/videos/3192305/pictures/preview-5.jpg",
      },
      {
        id: 574314,
        nr: 6,
        picture:
          "https://images.pexels.com/videos/3192305/pictures/preview-6.jpg",
      },
      {
        id: 574316,
        nr: 7,
        picture:
          "https://images.pexels.com/videos/3192305/pictures/preview-7.jpg",
      },
      {
        id: 574318,
        nr: 8,
        picture:
          "https://images.pexels.com/videos/3192305/pictures/preview-8.jpg",
      },
      {
        id: 574320,
        nr: 9,
        picture:
          "https://images.pexels.com/videos/3192305/pictures/preview-9.jpg",
      },
      {
        id: 574322,
        nr: 10,
        picture:
          "https://images.pexels.com/videos/3192305/pictures/preview-10.jpg",
      },
      {
        id: 574324,
        nr: 11,
        picture:
          "https://images.pexels.com/videos/3192305/pictures/preview-11.jpg",
      },
      {
        id: 574326,
        nr: 12,
        picture:
          "https://images.pexels.com/videos/3192305/pictures/preview-12.jpg",
      },
      {
        id: 574328,
        nr: 13,
        picture:
          "https://images.pexels.com/videos/3192305/pictures/preview-13.jpg",
      },
      {
        id: 574329,
        nr: 14,
        picture:
          "https://images.pexels.com/videos/3192305/pictures/preview-14.jpg",
      },
    ],
  },
  {
    id: 3196061,
    width: 3840,
    height: 2160,
    duration: 10,
    full_res: null,
    tags: [],
    url: "https://www.pexels.com/video/a-person-working-on-a-computer-and-writing-notes-on-pieces-of-papers-3196061/",
    image:
      "https://images.pexels.com/videos/3196061/free-video-3196061.jpg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
    avg_color: null,
    user: {
      id: 1583460,
      name: "Pressmaster",
      url: "https://www.pexels.com/@pressmaster",
    },
    video_files: [
      {
        id: 255963,
        quality: "hd",
        file_type: "video/mp4",
        width: 1920,
        height: 1080,
        fps: 25,
        link: "https://player.vimeo.com/external/371837723.hd.mp4?s=6c442c05151176fd2546300958ed6b8be142a32b&profile_id=175&oauth2_token_id=57447761",
      },
      {
        id: 255964,
        quality: "sd",
        file_type: "video/mp4",
        width: 960,
        height: 540,
        fps: 25,
        link: "https://player.vimeo.com/external/371837723.sd.mp4?s=7a4920e3315f22617298c9f072d1f11f03843be2&profile_id=165&oauth2_token_id=57447761",
      },
      {
        id: 255965,
        quality: "hd",
        file_type: "video/mp4",
        width: 2560,
        height: 1440,
        fps: 25,
        link: "https://player.vimeo.com/external/371837723.hd.mp4?s=6c442c05151176fd2546300958ed6b8be142a32b&profile_id=170&oauth2_token_id=57447761",
      },
      {
        id: 255966,
        quality: "hd",
        file_type: "video/mp4",
        width: 1280,
        height: 720,
        fps: 25,
        link: "https://player.vimeo.com/external/371837723.hd.mp4?s=6c442c05151176fd2546300958ed6b8be142a32b&profile_id=174&oauth2_token_id=57447761",
      },
      {
        id: 255967,
        quality: "hd",
        file_type: "video/mp4",
        width: 3840,
        height: 2160,
        fps: 25,
        link: "https://player.vimeo.com/external/371837723.hd.mp4?s=6c442c05151176fd2546300958ed6b8be142a32b&profile_id=172&oauth2_token_id=57447761",
      },
      {
        id: 255968,
        quality: "sd",
        file_type: "video/mp4",
        width: 426,
        height: 240,
        fps: 25,
        link: "https://player.vimeo.com/external/371837723.sd.mp4?s=7a4920e3315f22617298c9f072d1f11f03843be2&profile_id=139&oauth2_token_id=57447761",
      },
      {
        id: 255969,
        quality: "sd",
        file_type: "video/mp4",
        width: 640,
        height: 360,
        fps: 25,
        link: "https://player.vimeo.com/external/371837723.sd.mp4?s=7a4920e3315f22617298c9f072d1f11f03843be2&profile_id=164&oauth2_token_id=57447761",
      },
    ],
    video_pictures: [
      {
        id: 576783,
        nr: 0,
        picture:
          "https://images.pexels.com/videos/3196061/pictures/preview-0.jpg",
      },
      {
        id: 576785,
        nr: 1,
        picture:
          "https://images.pexels.com/videos/3196061/pictures/preview-1.jpg",
      },
      {
        id: 576787,
        nr: 2,
        picture:
          "https://images.pexels.com/videos/3196061/pictures/preview-2.jpg",
      },
      {
        id: 576789,
        nr: 3,
        picture:
          "https://images.pexels.com/videos/3196061/pictures/preview-3.jpg",
      },
      {
        id: 576791,
        nr: 4,
        picture:
          "https://images.pexels.com/videos/3196061/pictures/preview-4.jpg",
      },
      {
        id: 576794,
        nr: 5,
        picture:
          "https://images.pexels.com/videos/3196061/pictures/preview-5.jpg",
      },
      {
        id: 576796,
        nr: 6,
        picture:
          "https://images.pexels.com/videos/3196061/pictures/preview-6.jpg",
      },
      {
        id: 576800,
        nr: 7,
        picture:
          "https://images.pexels.com/videos/3196061/pictures/preview-7.jpg",
      },
      {
        id: 576805,
        nr: 8,
        picture:
          "https://images.pexels.com/videos/3196061/pictures/preview-8.jpg",
      },
      {
        id: 576809,
        nr: 9,
        picture:
          "https://images.pexels.com/videos/3196061/pictures/preview-9.jpg",
      },
      {
        id: 576813,
        nr: 10,
        picture:
          "https://images.pexels.com/videos/3196061/pictures/preview-10.jpg",
      },
      {
        id: 576817,
        nr: 11,
        picture:
          "https://images.pexels.com/videos/3196061/pictures/preview-11.jpg",
      },
      {
        id: 576819,
        nr: 12,
        picture:
          "https://images.pexels.com/videos/3196061/pictures/preview-12.jpg",
      },
      {
        id: 576820,
        nr: 13,
        picture:
          "https://images.pexels.com/videos/3196061/pictures/preview-13.jpg",
      },
      {
        id: 576821,
        nr: 14,
        picture:
          "https://images.pexels.com/videos/3196061/pictures/preview-14.jpg",
      },
    ],
  },
  {
    id: 7579667,
    width: 2160,
    height: 4096,
    duration: 9,
    full_res: null,
    tags: [],
    url: "https://www.pexels.com/video/person-using-smartphone-while-looking-bitcoin-7579667/",
    image:
      "https://images.pexels.com/videos/7579667/pexels-photo-7579667.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=630",
    avg_color: null,
    user: {
      id: 3088726,
      name: "Tima Miroshnichenko",
      url: "https://www.pexels.com/@tima-miroshnichenko",
    },
    video_files: [
      {
        id: 3144421,
        quality: "hd",
        file_type: "video/mp4",
        width: 1440,
        height: 2732,
        fps: 25,
        link: "https://player.vimeo.com/external/539033394.hd.mp4?s=4a924ed8ab86e02f62f2458f134d2374ee6b6907&profile_id=170&oauth2_token_id=57447761",
      },
      {
        id: 3144422,
        quality: "hd",
        file_type: "video/mp4",
        width: 720,
        height: 1366,
        fps: 25,
        link: "https://player.vimeo.com/external/539033394.hd.mp4?s=4a924ed8ab86e02f62f2458f134d2374ee6b6907&profile_id=174&oauth2_token_id=57447761",
      },
      {
        id: 3144423,
        quality: "hd",
        file_type: "video/mp4",
        width: 2160,
        height: 4096,
        fps: 25,
        link: "https://player.vimeo.com/external/539033394.hd.mp4?s=4a924ed8ab86e02f62f2458f134d2374ee6b6907&profile_id=172&oauth2_token_id=57447761",
      },
      {
        id: 3144424,
        quality: "hd",
        file_type: "video/mp4",
        width: 1080,
        height: 2048,
        fps: 25,
        link: "https://player.vimeo.com/external/539033394.hd.mp4?s=4a924ed8ab86e02f62f2458f134d2374ee6b6907&profile_id=175&oauth2_token_id=57447761",
      },
      {
        id: 3144425,
        quality: "sd",
        file_type: "video/mp4",
        width: 506,
        height: 960,
        fps: 25,
        link: "https://player.vimeo.com/external/539033394.sd.mp4?s=8813ecbaf896b6ea024b4fff6eab1246e81758ac&profile_id=165&oauth2_token_id=57447761",
      },
      {
        id: 3144426,
        quality: "sd",
        file_type: "video/mp4",
        width: 338,
        height: 640,
        fps: 25,
        link: "https://player.vimeo.com/external/539033394.sd.mp4?s=8813ecbaf896b6ea024b4fff6eab1246e81758ac&profile_id=164&oauth2_token_id=57447761",
      },
    ],
    video_pictures: [
      {
        id: 6664855,
        nr: 0,
        picture:
          "https://images.pexels.com/videos/7579667/pictures/preview-0.jpeg",
      },
      {
        id: 6664874,
        nr: 1,
        picture:
          "https://images.pexels.com/videos/7579667/pictures/preview-1.jpeg",
      },
      {
        id: 6664891,
        nr: 2,
        picture:
          "https://images.pexels.com/videos/7579667/pictures/preview-2.jpeg",
      },
      {
        id: 6664916,
        nr: 3,
        picture:
          "https://images.pexels.com/videos/7579667/pictures/preview-3.jpeg",
      },
      {
        id: 6664936,
        nr: 4,
        picture:
          "https://images.pexels.com/videos/7579667/pictures/preview-4.jpeg",
      },
      {
        id: 6664950,
        nr: 5,
        picture:
          "https://images.pexels.com/videos/7579667/pictures/preview-5.jpeg",
      },
      {
        id: 6664971,
        nr: 6,
        picture:
          "https://images.pexels.com/videos/7579667/pictures/preview-6.jpeg",
      },
      {
        id: 6664983,
        nr: 7,
        picture:
          "https://images.pexels.com/videos/7579667/pictures/preview-7.jpeg",
      },
      {
        id: 6664996,
        nr: 8,
        picture:
          "https://images.pexels.com/videos/7579667/pictures/preview-8.jpeg",
      },
      {
        id: 6665016,
        nr: 9,
        picture:
          "https://images.pexels.com/videos/7579667/pictures/preview-9.jpeg",
      },
      {
        id: 6665035,
        nr: 10,
        picture:
          "https://images.pexels.com/videos/7579667/pictures/preview-10.jpeg",
      },
      {
        id: 6665059,
        nr: 11,
        picture:
          "https://images.pexels.com/videos/7579667/pictures/preview-11.jpeg",
      },
      {
        id: 6665095,
        nr: 12,
        picture:
          "https://images.pexels.com/videos/7579667/pictures/preview-12.jpeg",
      },
      {
        id: 6665120,
        nr: 13,
        picture:
          "https://images.pexels.com/videos/7579667/pictures/preview-13.jpeg",
      },
      {
        id: 6665147,
        nr: 14,
        picture:
          "https://images.pexels.com/videos/7579667/pictures/preview-14.jpeg",
      },
    ],
  },
];

const stockAudios = [
  {
    url: "https://storage.googleapis.com/vidyo-bucket-stage/music/abstract-fashion-pop-131283.mp3",
    id: "editor-audio-1",
  },
  {
    url: "https://storage.googleapis.com/vidyo-bucket-stage/music/Future-Glider-Brian-Bolger.mp3",
    id: "editor-audio-2",
  },
  {
    url: "https://storage.googleapis.com/vidyo-bucket-stage/music/whip-110235.mp3",
    id: "editor-audio-3",
  },
  {
    url: "https://storage.googleapis.com/vidyo-bucket-stage/music/Coast-Anno-Domini-Beats.mp3",
    id: "editor-audio-4",
  },
];

export const stockMedia = {
  [SimpleAssetType.IMAGE]: stockPhotos,
  [SimpleAssetType.VIDEO]: stockVideos,
  [SimpleAssetType.AUDIO]: stockAudios,
};
