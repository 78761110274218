import { useEffect } from "react";
import ReactTooltip from "react-tooltip";

import {
  updateOnBoardingDataById,
  updateUserSelectedLayouts,
} from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import useGetDefaultBaseTemplates from "@/api/useGetDefaultBaseTemplates";
import useGetUserPreference from "@/api/useGetUserPreference";
import useGetUserTemplates from "@/api/useGetUserTemplates";

import { layoutDataArray } from "@/constants/aspect-ratio";

import {
  getAllBaseTemplates,
  updatedUserSavedPreferencesData,
} from "@/helpers";

import { OnBoardingStep } from "@/enums";

import Spinner from "@/components/Loader/Spinner";

import LayoutCard from "./LayoutCard";

const LayoutPreference = ({
  handleChangeActiveStep,
  isLoading,
  handelCancelUpload,
}: any) => {
  const onBoardingData = useAppSelector(
    (state) => state.homeState.onBoardingData
  );
  const onBoardingId = useAppSelector(
    (state) => state.homeState.currentOnBoardingId
  );

  const uid = useAppSelector((state) => state.authState.currentUser.uid);

  const dispatch = useAppDispatch();

  const { data: userPreferenceData } = useGetUserPreference(uid);

  const defaultBaseTemplates = useGetDefaultBaseTemplates();
  const userTemplatesData = useGetUserTemplates(uid);

  useEffect(() => {
    if (userPreferenceData?.savePreference) {
      const baseTemplates = getAllBaseTemplates(
        defaultBaseTemplates?.data,
        userTemplatesData?.data
      );

      dispatch(updateUserSelectedLayouts(userPreferenceData?.selectedLayouts));

      dispatch(
        updateOnBoardingDataById({
          id: onBoardingId,
          data: {
            selectedLayouts: userPreferenceData?.selectedLayouts,
            baseTemplates: updatedUserSavedPreferencesData(
              baseTemplates,
              userPreferenceData.baseTemplates
            ),
          },
        })
      );
    }
  }, [userPreferenceData]);

  return (
    <div className="relative rounded-lg  py-4 dark:bg-gray-700">
      <div className="relative px-4 lg:px-6">
        <section className="bg-white ">
          <h2 className="mb-2 text-center text-2xl font-medium tracking-tight text-gray-900 dark:text-white sm:text-4xl">
            What format videos do you want?
          </h2>

          <div className="mx-auto">
            <p className=" text-center text-sm font-light text-gray-400 sm:text-lg">
              You can select multiple options
            </p>

            <div className="relative my-8 mx-auto flex w-full justify-center space-x-12 xl:w-[80%]">
              {layoutDataArray.map((layoutData) => (
                <LayoutCard
                  key={layoutData.id}
                  layoutData={layoutData}
                />
              ))}
            </div>
          </div>
        </section>
      </div>
      <div className="relative mt-20">
        <div className="flex justify-center pb-4">
          <button
            onClick={handelCancelUpload}
            disabled={isLoading}
            type="button"
            id="cancel-button-layout-preference"
            className={`mr-4 inline-flex w-44 items-center justify-center rounded-lg border border-blue-500 bg-white px-5 py-2.5 text-center text-sm font-medium text-blue-500 hover:bg-blue-50 focus:outline-none  focus:ring-4 focus:ring-blue-300 sm:py-3.5 ${
              isLoading ? "cursor-progress opacity-70" : ""
            }`}
          >
            {isLoading ? (
              <div className="flex justify-center">
                <Spinner className="h-5 w-5" />
                <span>Cancel</span>
              </div>
            ) : (
              "Cancel"
            )}
          </button>

          <button
            data-tip={
              onBoardingData[onBoardingId].selectedLayouts?.length > 0
                ? ""
                : "Please select at least 1 layout to move ahead"
            }
            type="button"
            id="continue-button-layout-preference"
            className={`inline-flex w-44 items-center justify-center rounded-lg bg-blue-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 sm:py-3.5 ${
              !onBoardingData[onBoardingId].selectedLayouts?.length
                ? "cursor-not-allowed opacity-70"
                : ""
            }`}
            onClick={() => {
              if (
                onBoardingData[onBoardingId].selectedLayouts?.length &&
                !isLoading
              ) {
                handleChangeActiveStep(OnBoardingStep.SELECT_TEMPLATES);
              }
            }}
          >
            Continue
          </button>
        </div>
      </div>
      <ReactTooltip
        effect="solid"
        arrowColor="transparent"
        backgroundColor="#EFF6FF"
        textColor="#71717A"
        padding="8px 10px"
      />
    </div>
  );
};

export default LayoutPreference;
