import { VideoLayout } from "@/enums";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const getResponsivePropertiesByLayout = (
  layout: String,
  isOnBoarding = false
) => {
  const responsiveProps = { ...responsive };
  switch (layout) {
    case VideoLayout.LAYOUT_16_9:
      responsiveProps.superLargeDesktop.items = 4;
      responsiveProps.desktop.items = 2;
      responsiveProps.tablet.items = 1;
      break;
    case VideoLayout.LAYOUT_9_16_1:
    case VideoLayout.LAYOUT_9_16_2:
      responsiveProps.superLargeDesktop.items = 5;
      responsiveProps.desktop.items = 4;
      responsiveProps.tablet.items = 2;
      break;
    case VideoLayout.LAYOUT_1_1:
      responsiveProps.superLargeDesktop.items = 4;
      responsiveProps.desktop.items = 3;
      responsiveProps.tablet.items = 2;
      break;
    default:
      break;
  }
  return responsiveProps;
};

export const reactSlickSettings = (layout: string) => {
  let slidesToShow = 3;
  let slidesToScroll = 3;

  switch (layout) {
    case VideoLayout.LAYOUT_16_9:
      slidesToShow = 2;
      break;
    case VideoLayout.LAYOUT_9_16_1:
    case VideoLayout.LAYOUT_9_16_2:
      slidesToShow = 4;
      break;
    case VideoLayout.LAYOUT_1_1:
      slidesToShow = 3;
      break;
    default:
      break;
  }
  return { slidesToShow, slidesToScroll: slidesToShow };
};
