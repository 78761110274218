import { Route, Routes, Navigate } from "react-router-dom";

import { useAppSelector } from "@/store/hooks";

import Editor from "@/views/editor/Editor";
import { BackgroundTab } from "@/views/editor/components/BackgroundTab";
import { CaptionTab } from "@/views/editor/components/CaptionTab";
import { ElementsTab } from "@/views/editor/components/ElementsTab";
import MediaTab from "@/views/editor/components/Media/MediaTab";
import { SceneChangeTab } from "@/views/editor/components/SceneChangeTab";
import { SubtitleTab } from "@/views/editor/components/SubtitleTab";
import { TemplatesTab } from "@/views/editor/components/TemplatesTab";
import { TextEditor } from "@/views/editor/components/TextEditor";

const EditorRoutes = () => {
  const currentSelectedMicroContent = useAppSelector(
    (state) => state.homeState.currentSelectedMicroContent
  );

  if (!currentSelectedMicroContent) {
    return (
      <Navigate
        replace
        to="/"
      />
    );
  }

  return (
    <Routes>
      <Route
        path=":fileID/*"
        element={<Editor />}
      >
        <Route
          path="templates"
          element={<TemplatesTab />}
        />
        <Route
          path="background"
          element={<BackgroundTab />}
        />
        <Route
          path="elements"
          element={<ElementsTab />}
        />
        <Route
          path="texts"
          element={<TextEditor />}
        />
        <Route
          path="subtitles"
          element={<SubtitleTab />}
        />
        <Route
          path="scene-change"
          element={<SceneChangeTab />}
        />
        <Route
          path="caption"
          element={<CaptionTab />}
        />

        <Route
          path="media"
          element={<MediaTab />}
        />
      </Route>
    </Routes>
  );
};

export default EditorRoutes;
