import { useMutation, useQueryClient } from "@tanstack/react-query";

import { finalizeAssetUpload } from "@/api/requests";

import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

import { FinalizeAsset } from "@/interfaces";

import { AssetTags } from "@/enums";

export default function useUploadAsset(assetTag?: AssetTags) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (body: FinalizeAsset) => finalizeAssetUpload(body),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["Assets", ...(assetTag ? [assetTag] : [])],
      });

      queryClient.invalidateQueries({ queryKey: ["Assets-count"] });
    },
    onError: (error) => {
      console.log(error);
      showNotification(
        "Something went wrong, please try again!",
        notificationType.FAIL
      );
    },
  });
}
