import { Fragment, memo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUpdateEffect } from "react-use";

import { Menu, Transition } from "@headlessui/react";
import { ClockIcon } from "@heroicons/react/24/outline";
import { SparklesIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import { Player } from "@lottiefiles/react-lottie-player";
import clsx from "clsx";
import { Tooltip, Badge } from "flowbite-react";

import {
  toggleAddMoreMinModal,
  toggleUpgradeToProModal,
} from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import useUserConsumedProcessingTime from "@/api/useUserConsumedProcessingTime";

import { BUTTON_IDS } from "@/constants/segment-analytics";

import {
  isAdminTemplateEditor,
  calculateRemainingProcessingTimeInMinutes,
  getOrdinalDayOfMonthFromTimestamp,
  saveValueToLocalStorage,
} from "@/helpers";

import {
  AMPLITUDE_CLICK_EVENTS,
  ANALYTICS_CONSTANTS,
  addMoreMinutesButtonClick,
  eventsDataToRedux,
  trackDiscordIconClick,
  trackDownloadsTabClick,
  trackMediaTabClick,
  trackUpgradeButton,
  trackUserUsage,
  trackWhatsNewIconClick,
} from "@/utils/amplitudeAnalytcs";

import { PlanType, RouterPath, ScreenName } from "@/enums";

import Banner from "@/components/Banner/Banner";

import UserOptionDropdown from "./UserOptionDropdown";

import AudyoAILogo from "@/assets/icons/audyo.png";
import DiscordIcon from "@/assets/icons/social/discord.svg";

type HeaderTypes = {
  showHomeOnly?: boolean;
};

const REMAINING_RED = 15;
const REMAINING_YELLOW = 30;

const { HEADER_OPEN_UPGRADE_MODAL } = BUTTON_IDS.HEADER;

const getLabelColorByRemainingProcessingTime = (remainingMins: number) => {
  if (remainingMins < REMAINING_RED) {
    return "text-red-800 bg-red-100";
  } else if (remainingMins < REMAINING_YELLOW) {
    return "text-yellow-800 bg-yellow-100";
  } else {
    return "text-green-800 bg-green-100";
  }
};

const Header = ({ showHomeOnly }: HeaderTypes) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const userSubscription = useAppSelector(
    (state) => state.authState.userSubscription
  );
  const {
    subscriptionStartedAt,
    planType,
    allowedMinutes,
    intervalInMonths,
    nextBillDate,
  } = userSubscription;

  const { data: usageData, isLoading: usageDataIsLoading } =
    userSubscription && useUserConsumedProcessingTime();

  const dispatch = useAppDispatch();
  const handelShowUpgradeModal = () => {
    dispatch(toggleUpgradeToProModal(true));
    eventsDataToRedux(ANALYTICS_CONSTANTS.PAYMENT_SCREEN_NAME);
  };

  const remainingProcessingTime =
    calculateRemainingProcessingTimeInMinutes(usageData);

  const nextBillDateTooltip =
    nextBillDate && planType !== PlanType.FREE
      ? `Renews on ${new Date(nextBillDate).toLocaleDateString()}`
      : null;

  const tooltip =
    intervalInMonths === 12
      ? nextBillDateTooltip
      : nextBillDateTooltip
      ? nextBillDateTooltip
      : `Renews on the ${
          subscriptionStartedAt
            ? getOrdinalDayOfMonthFromTimestamp(subscriptionStartedAt)
            : "1st"
        } of every month`;

  let remainingMins = `${remainingProcessingTime} / ${allowedMinutes}`;
  if (planType === PlanType.UNLIMITED) {
    remainingMins = "∞";
  }

  const handleLogoClick = () => {
    saveValueToLocalStorage("homePageOffset", 0);
    console;
    navigate("/");
  };

  useUpdateEffect(() => {
    if (!usageDataIsLoading) {
      trackUserUsage(usageData?.usage);
    }
  }, [usageDataIsLoading]);

  return (
    <>
      <Banner
        bannerVariant="halloweenOffer"
        isClosable
      />
      <header className="bg-white dark:bg-gray-700">
        {/* <AccountDeleteModal
        setAccountDeleteModal={setAccountDeleteModal}
        displayProjectDeleteModal={displayProjectDeleteModal}
      /> */}
        <nav className="border-gray-200 bg-white px-4 py-2 dark:bg-gray-800 lg:px-6 z-50 relative">
          <div className="mx-auto grid max-w-screen-2xl grid-cols-2 items-center">
            {!pathname.includes(RouterPath.ON_BOARDING) ? (
              <div className="order-3  col-span-1 flex gap-7 items-center justify-end">
                <div
                  className={
                    planType === PlanType.FREE
                      ? "cursor-default"
                      : "cursor-pointer"
                  }
                  onClick={() => {
                    if (planType !== PlanType.FREE) {
                      dispatch(toggleAddMoreMinModal(true));
                      addMoreMinutesButtonClick(AMPLITUDE_CLICK_EVENTS.HEADER);
                    }
                  }}
                >
                  {usageDataIsLoading || (
                    <Tooltip
                      content={tooltip}
                      className={tooltip ? "" : "hidden"}
                    >
                      <div
                        className={`flex items-center gap-1.5 px-3 py-1 text-sm font-semibold select-none rounded-md ${getLabelColorByRemainingProcessingTime(
                          remainingProcessingTime
                        )}`}
                      >
                        <ClockIcon className="w-4" />
                        {remainingMins + " mins left"}
                      </div>
                    </Tooltip>
                  )}
                </div>
                <button
                  id={HEADER_OPEN_UPGRADE_MODAL.ID}
                  className="relative inline-flex items-center justify-center 
                  p-0.5 overflow-hidden text-sm font-medium text-gray-900 
                  rounded-lg group bg-gradient-to-r from-fuchsia-600
                  via-purple-600 to-halloween-yellow hover:text-white dark:text-white 
                  focus:ring-4 focus:outline-none focus:ring-fuchsia-700 dark:focus:ring-blue-800"
                  onClick={() => {
                    handelShowUpgradeModal();
                    trackUpgradeButton();
                  }}
                >
                  <span className="relative w-[107px] h-[38px] transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0 flex items-center justify-center">
                    Upgrade
                    <span className="group-hover:hidden h-[30px]">
                      {/* for icon animation */}
                      <Player
                        autoplay={true}
                        loop={true}
                        controls={true}
                        src="https://lottie.host/5e4acda8-ae43-4c93-ad8c-59daf70a321d/N5xqs2VvZv.json"
                        style={{
                          height: "30px",
                          width: "30px",
                          margin: "0",
                          display: "inline-block",
                        }}
                        id="pumpkin-with-wings-animation"
                      />
                    </span>
                  </span>
                </button>
                <div className="flex items-center">
                  <div className="mr-3 h-8 w-[1px] bg-gray-200"></div>
                  <UserOptionDropdown />
                </div>
              </div>
            ) : null}

            <div
              className="order-1 col-span-1 flex w-auto items-center justify-between"
              id="mobile-menu-2"
            >
              {pathname.includes(RouterPath.ON_BOARDING) ? (
                <ul className="flex flex-col  items-center md:flex-row md:space-x-8">
                  <li>
                    <p className="flex cursor-pointer items-center md:order-2 md:justify-center">
                      <img
                        src={AudyoAILogo}
                        className="mr-1 h-6 sm:h-7"
                        alt="VidyoAI Logo"
                      />
                      <span className="self-center whitespace-nowrap text-lg font-semibold text-violet-700 dark:text-white">
                        audyo
                      </span>
                    </p>
                  </li>
                </ul>
              ) : (
                <ul className="flex flex-col items-center sm:flex-row sm:space-x-6">
                  <li>
                    <p
                      onClick={handleLogoClick}
                      className="flex cursor-pointer items-center md:order-2 md:justify-center"
                    >
                      <img
                        src={AudyoAILogo}
                        className="mr-1 h-6 sm:h-7"
                        alt="VidyoAI Logo"
                      />
                      <span className="self-center whitespace-nowrap text-lg  text-violet-700 font-semibold dark:text-white">
                        audyo
                      </span>
                    </p>
                  </li>
                  <li>
                    <Link to={RouterPath.HOME}>
                      <div
                        className={`hidden md:inline-block ${
                          pathname === RouterPath.HOME
                            ? "border-blue-600 font-semibold text-blue-600 dark:text-blue-500"
                            : "border-transparent hover:border-gray-300 hover:text-gray-600"
                        } rounded-t-lg`}
                      >
                        Home
                      </div>
                    </Link>
                  </li>
                  {showHomeOnly ? null : (
                    <li>
                      <Link
                        to={RouterPath.DOWNLOADS}
                        onClick={() => trackDownloadsTabClick()}
                      >
                        <div
                          className={`hidden md:inline-block  ${
                            pathname === RouterPath.DOWNLOADS
                              ? "border-blue-600 font-semibold text-blue-600 dark:text-blue-500"
                              : "border-transparent hover:border-gray-300 hover:text-gray-600"
                          }`}
                        >
                          Downloads
                        </div>
                      </Link>
                    </li>
                  )}
                  {showHomeOnly ? null : (
                    <li>
                      <Link to={RouterPath.SCHEDULED_POSTS}>
                        <div
                          className={`md:inline-block ${
                            pathname === RouterPath.SCHEDULED_POSTS
                              ? "border-blue-600 font-semibold text-blue-600 dark:text-blue-500"
                              : "border-transparent hover:border-gray-300 hover:text-gray-600"
                          } rounded-t-lg min-w-[195px]`}
                        >
                          Social Media Posts
                        </div>
                      </Link>
                    </li>
                  )}
                </ul>
              )}
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default memo(Header);
