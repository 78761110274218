import { useMutation, useQueryClient } from "@tanstack/react-query";

import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

import api from "./api";
import { ApiEndpoints } from "./constants/ApiEndPoints";

const deleteCurrentSelectedMicrocontent = async (
  project_id: string,
  start: number,
  end: number,
  type: string
) => {
  const response = await api.post(`${ApiEndpoints.MICROCONTENT}/delete`, {
    project_id,
    start,
    end,
    type,
  });
  return response;
};

export default function useDeleteMicroContent() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      projectId,
      start,
      end,
      type,
    }: {
      projectId: string;
      start: number;
      end: number;
      type: string;
    }) => deleteCurrentSelectedMicrocontent(projectId, start, end, type),
    {
      onSuccess: () => {
        showNotification(
          "Video Clip deleted successfully",
          notificationType.SUCCESS
        );

        queryClient.invalidateQueries({ queryKey: ["micro-content-data"] });
      },
      onError: () => {
        showNotification(
          "Something went wrong, please try again!",
          notificationType.FAIL
        );
      },
    }
  );
}
