import { memo, useCallback } from "react";

import { AlignGuidelines } from "fabric-guideline-plugin";

import { getNewFabricCanvas } from "@/helpers";

const DEFAULT_CANVAS_BACKGROUND_COLOR = "#eef";

const EditorCanvas = ({ fabricRef, updateSelectedTextId }: any) => {
  const canvasRef = useCallback((element: any) => {
    if (!element) {
      if (fabricRef && fabricRef.current) {
        fabricRef.current.dispose();
      }
      fabricRef.current = null;
      return fabricRef;
    }

    fabricRef.current = getNewFabricCanvas(
      element,
      DEFAULT_CANVAS_BACKGROUND_COLOR
    );

    fabricRef.current.on("selection:updated", updateSelectedTextId);
    fabricRef.current.on("selection:cleared", updateSelectedTextId);
    fabricRef.current.on("selection:created", updateSelectedTextId);
    const guideline = new AlignGuidelines({
      canvas: fabricRef.current,
      aligningOptions: {
        lineColor: "#DC2626",
        lineWidth: 1.3,
      },
    });

    guideline.init();

    return fabricRef;
  }, []);

  return (
    <>
      <div className="flex flex-1 flex-col rounded">
        <div className="flex-1">
          <canvas ref={canvasRef} />
        </div>
      </div>
    </>
  );
};

export default memo(EditorCanvas);
