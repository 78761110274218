import { memo } from "react";

import { Listbox } from "@headlessui/react";
import {
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";

const playbackRates = [1, 1.25, 1.5, 1.75, 2];

function PlaybackRateController({
  selectedRate,
  setSelectedRate,
}: {
  selectedRate: number;
  setSelectedRate: (rate: number) => void;
}) {
  return (
    <div className="flex items-center bg-black/80 w-full text-white rounded-b-lg">
      <Listbox
        as="div"
        value={selectedRate}
        onChange={setSelectedRate}
        className="w-full text-left text-sm"
      >
        {({ open }) => (
          <>
            <div className="w-full relative">
              <Listbox.Button
                as="button"
                className={clsx(
                  "text-left w-full items-center justify-between",
                  open ? "hidden" : "px-2 py-3 flex"
                )}
              >
                <p>Playback speed : {selectedRate}x</p>
                <ChevronRightIcon
                  className="w-3"
                  strokeWidth={3}
                />
              </Listbox.Button>

              <Listbox.Options
                as="ul"
                className="bg-black/80 rounded-b-lg absolute z-10 w-full bottom-full pb-4"
              >
                <Listbox.Button
                  as="button"
                  className="flex items-center py-3 pl-1"
                >
                  <ChevronLeftIcon
                    className="w-4 mr-2 cursor-pointer"
                    strokeWidth={3}
                  />
                  <p>Playback speed</p>
                </Listbox.Button>
                <div className="h-px w-full bg-white/40"></div>
                {playbackRates.map((rate) => (
                  <Listbox.Option
                    key={rate}
                    value={rate}
                    id={`play-back-rate-${rate}`}
                  >
                    {({ active, selected }) => (
                      <li
                        className={clsx(
                          "text-xs p-2 cursor-pointer",
                          active ? "bg-[#383838]" : "bg-transparent",
                          selected && "font-bold"
                        )}
                        id={`play-rate-${rate}`}
                      >
                        <CheckIcon
                          className={clsx(
                            selected ? "opacity-100" : "opacity-0",
                            "w-3 mr-2 inline-block"
                          )}
                          strokeWidth={5}
                        />
                        {rate}x
                      </li>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
}

export default memo(PlaybackRateController);
