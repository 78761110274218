import { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { Button } from "flowbite-react";

import { LOGIN } from "@/constants";

import { getValueFromLocalStorage } from "@/helpers";

import { auth } from "@/utils/firebase";

import ForgotPassword from "@/views/auth/ForgotPassword";
import HelperComponent from "@/views/auth/HelperComponent";
import Login from "@/views/auth/Login";
import SignUpPage from "@/views/auth/SignUp";
import CustomEmailModal from "@/views/auth/social/CustomEmailModal";
import FacebookAuth from "@/views/auth/social/FacebookAuth";
import GoogleAuth from "@/views/auth/social/GoogleAuth";
import MicrosoftAuth from "@/views/auth/social/MicrosoftAuth";

const SIGN_UP = "SIGN_UP";
const FORGOT_PASSWORD = "FORGOT_PASSWORD";
const HELPER_COMPONENT = "HELPER_COMPONENT";

const AuthPage = ({ userCountry }: { userCountry: string }) => {
  const [helperComponentDetails, setHelperComponentDetails] = useState({
    helperHeadline: "",
    helperMessage: "",
  });
  const [showHelperComponent, setShowHelperComponent] =
    useState<boolean>(false);
  const userEmailId = getValueFromLocalStorage("userEmail");

  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [firebaseUserData, setFirebaseUserData] = useState<any>();
  const [currentComponent, setCurrentComponent] = useState("");

  const user = auth.currentUser;

  // useEffect(() => {
  //   if (firebaseUserData) {
  //     checkMainDBLogin();
  //   }
  // }, [firebaseUserData]);

  useEffect(() => {
    if (userEmailId) updateCurrentTab(LOGIN);
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
  };

  const CustomDot = (props: any) => {
    return (
      <div
        className={`mb-3 mr-2 h-2 rounded-full bg-white ${
          props.active ? "w-10" : "w-2 cursor-pointer opacity-50"
        }`}
        onClick={props.onClick}
      ></div>
    );
  };

  const sliderImageList = [
    {
      id: "slider_1",
      imageSrc: "https://assets.vidyo.ai/login_slider_time.jpg",
    },
    {
      id: "slider_2",
      imageSrc: "https://assets.vidyo.ai/login_slider_template.jpg",
    },
    {
      id: "slider_3",
      imageSrc: "https://assets.vidyo.ai/login_slider_creator.jpg",
    },
  ];

  const updateCurrentTab = (component: string) => {
    setCurrentComponent(component);
  };

  const updateHelperComponentDetails = (headline: string, message: string) => {
    setHelperComponentDetails({
      helperHeadline: headline,
      helperMessage: message,
    });
  };

  const displayComponent = () => {
    if (currentComponent === LOGIN)
      return (
        <Login
          updateCurrentTab={updateCurrentTab}
          showHelperComponent={showHelperComponent}
          setShowHelperComponent={setShowHelperComponent}
          updateHelperComponentDetails={updateHelperComponentDetails}
        />
      );
    else if (currentComponent === SIGN_UP)
      return (
        <SignUpPage
          updateCurrentTab={updateCurrentTab}
          updateHelperComponentDetails={updateHelperComponentDetails}
        />
      );
    else if (currentComponent === FORGOT_PASSWORD)
      return (
        <ForgotPassword
          updateCurrentTab={updateCurrentTab}
          showHelperComponent={showHelperComponent}
          setShowHelperComponent={setShowHelperComponent}
        />
      );
    else if (currentComponent === HELPER_COMPONENT)
      return (
        <HelperComponent
          headline={helperComponentDetails.helperHeadline}
          message={helperComponentDetails.helperMessage}
          updateCurrentTab={updateCurrentTab}
          setShowHelperComponent={setShowHelperComponent}
        />
      );
    else {
      return (
        <>
          <div className="xl:max-w-md h-[450px]">
            <div className="mb-10">
              {displayModal && (
                <CustomEmailModal
                  user={user}
                  firebaseUserData={firebaseUserData}
                  setDisplayModal={setDisplayModal}
                  displayModal={displayModal}
                />
              )}
              <h1 className="text-lg xl:text-2xl font-semibold text-gray-700 dark:text-white pt-7 pb-3">
                Sign up for your free account
              </h1>
              <p className="text-xs xl:text-sm text-gray-400 ">
                Get 75 minutes of free uploads every month with your free
                account
              </p>
            </div>
            <div className="">
              <GoogleAuth
                setDisplayModal={setDisplayModal}
                setFirebaseUserData={setFirebaseUserData}
              />

              <div className="mt-4 items-center space-x-0 space-y-3 sm:flex sm:space-x-4 sm:space-y-0">
                <FacebookAuth
                  setDisplayModal={setDisplayModal}
                  setFirebaseUserData={setFirebaseUserData}
                />
                <MicrosoftAuth
                  setDisplayModal={setDisplayModal}
                  setFirebaseUserData={setFirebaseUserData}
                />
              </div>

              <div className="flex items-center xl:py-8 py-4">
                <div className="w-full h-0.5 bg-gray-200 dark:bg-gray-700"></div>
                <div className="px-5 text-center text-gray-500 dark:text-gray-400">
                  or
                </div>
                <div className="w-full h-0.5 bg-gray-200 dark:bg-gray-700"></div>
              </div>

              <Button
                onClick={() => setCurrentComponent(SIGN_UP)}
                type="button"
                color={"white"}
                className="border-black/60 inline-flex w-full items-center justify-center rounded-md border text-sm font-medium text-gray-900  focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400  dark:focus:ring-gray-700 bg-[fff] hover:bg-[#f2f2f2]"
              >
                <span className="font-normal text-sm">
                  {" "}
                  Use Email & Password
                </span>
              </Button>
            </div>

            <p className="text-gray-500 text-sm mt-4 text-center">
              Already have an account?{" "}
              <span
                className="text-right text-xs w-1/3 underline cursor-pointer"
                style={{ color: "#1C64F2" }}
                onClick={() => updateCurrentTab(LOGIN)}
              >
                Sign in instead
              </span>
            </p>

            <div className="flex xl:py-16 py-14">
              <p
                className="text-left font-lighttext-xs w-full text-xs"
                style={{ color: "#6B7280" }}
              >
                By signing up, you agree with our{" "}
                <a
                  href="https://vidyo.ai/terms-of-service"
                  className="hover:underline cursor-pointer"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#1C64F2" }}
                >
                  terms & conditions
                </a>
              </p>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <section className="m-auto rounded-xl bg-white shadow-xl dark:bg-gray-900 h-[80%]">
      <div className="flex">
        <div className="relative hidden items-center justify-center rounded-br-xl md:flex">
          <div className="flex gap-4 px-20 text-5xl font-extrabold text-center text-fuchsia-500">
            <div>
              World's <div />
              <div>only</div>
              <div>AI Powered</div>
              <div className="text-violet-700">audyo</div>
              <div>repurposing</div>
              <div>tool</div>
            </div>
          </div>
        </div>
        <div className="px-6 lg:w-[35vw] md:w-[50vw] md:space-y-10 xl:max-w-md relative">
          {displayComponent()}
        </div>
      </div>
    </section>
  );
};

export default AuthPage;
