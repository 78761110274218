import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useBoolean } from "react-use";

import { XMarkIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";

// import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { toggleTopBanner, toggleUpgradeToProModal } from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import useUserConsumedProcessingTime from "@/api/useUserConsumedProcessingTime";

import {
  calculateRemainingProcessingTime,
  checkIfProcessingHourisBellowThresholdLimit,
  getValueFromLocalStorage,
  saveValueToLocalStorage,
  secondsToHm,
} from "@/helpers";

import {
  ANALYTICS_CONSTANTS,
  eventsDataToRedux,
} from "@/utils/amplitudeAnalytcs";

import { PlanType, RouterPath } from "@/enums";

import Hand from "@/components/Icons/Halloween/Hand";
import Spider from "@/components/Icons/Halloween/Spider";

import InfoIconDanger from "@/assets/icons/infoDanger.svg";
import InfoIcon from "@/assets/icons/infoWhite.svg";

interface bannerProps {
  bannerVariant?: string;
  bannerText?: string;
  isClosable?: boolean;
}

const Banner = ({
  bannerVariant,
  bannerText,
  isClosable = true,
}: bannerProps) => {
  const navigate = useNavigate();

  const showTopBanner = useAppSelector(
    (state) => state.homeState.topBannerVisible
  );

  const currentUserSubscriptionDetails = useAppSelector(
    (state) => state.authState.userSubscription
  );

  const dispatch = useAppDispatch();

  const handelShowUpgradeModal = () => {
    dispatch(toggleUpgradeToProModal(true));
    eventsDataToRedux(ANALYTICS_CONSTANTS.PAYMENT_SCREEN_NAME);
  };

  const handelToggleTopBanner = (state: boolean) => {
    dispatch(toggleTopBanner(state));
  };

  const ishideBanner = getValueFromLocalStorage("hideScheduleBanner");

  const [hideBanner, setHideBanner] = useBoolean(ishideBanner);

  const { data: usageData } = useUserConsumedProcessingTime();

  const closeBanner = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    saveValueToLocalStorage("hideScheduleBanner", true);
    setHideBanner(true);
  };

  useEffect(() => {
    if (currentUserSubscriptionDetails) {
      if (currentUserSubscriptionDetails.planType !== PlanType.FREE) {
        handelToggleTopBanner(false);
      } else {
        if (!showTopBanner) {
          handelToggleTopBanner(true);
        }
      }
    }
  }, [currentUserSubscriptionDetails.planType]);

  if (bannerVariant === "halloweenOffer") {
    if (!hideBanner) {
      return (
        <div
          id="banner"
          className={clsx(
            "z-50 flex h-[38px] w-full items-start justify-center gap-8 bg-gradient-to-r from-halloween-yellow via-purple-600 to-fuchsia-600  px-4 dark:border-gray-700 dark:bg-gray-800 sm:items-center relative",
            isClosable && "cursor-pointer select-none max-w-screen-2xl mx-auto"
          )}
          onClick={isClosable ? () => handelShowUpgradeModal() : () => {}}
        >
          <div className="flex items-start justify-center">
            <span className="absolute left-[250px] top-[18px]">
              <Spider />
            </span>
            <p className="ml-2 text-sm font-normal text-white text-center ">
              Save upto 50% on our ghoulishly good Halloween offers this October
              👻
            </p>
            <span className="absolute right-[90px] top-[4px]">
              <Hand />
            </span>
            {isClosable && (
              <span
                className="absolute right-[20px] cursor-pointer"
                onClick={closeBanner}
              >
                <XMarkIcon className="h-5 w-5 text-white" />
              </span>
            )}
          </div>
        </div>
      );
    }
    return null;
  }
  if (bannerVariant === "publishClips") {
    if (!hideBanner) {
      return (
        <div
          id="banner"
          className="z-50 flex h-[3rem] w-full items-start justify-center gap-8 bg-blue-500 px-4 dark:border-gray-700 dark:bg-gray-800 sm:items-center relative cursor-pointer"
          onClick={() => navigate(RouterPath.DOWNLOADS)}
        >
          <div className="flex items-start justify-center">
            <p className="ml-2 text-sm font-normal text-white text-center ">
              Schedule and Publish posts directly to 6 social platforms from
              audyo! Click on downloads to learn more
            </p>
            <span
              className="absolute right-[20px] cursor-pointer"
              onClick={closeBanner}
            >
              <XMarkIcon className="h-5 w-5 text-white" />
            </span>
          </div>
        </div>
      );
    }
    return null;
  }

  if (bannerVariant === "outrage") {
    return (
      <div
        id="banner"
        className="z-50 flex h-[3rem] w-full items-start justify-center gap-8 bg-blue-500 px-4 dark:border-gray-700 dark:bg-gray-800 sm:items-center"
      >
        <div className="flex items-start justify-center">
          {/* <img
            src={InfoIcon}
            alt="Info"
          /> */}
          {/* <ExclamationTriangleIcon className="h-5  text-white" /> */}
          {/* <p className="ml-2 text-sm font-light text-white text-center w-[85%]">
            We're facing some unexpected server issues, which may impact your
            usage.Our team is diligently working towards a resolution, which we
            expect to be in place within the next{" "}
            <span className=" font-extrabold text-[16px]">1am to 3am PST</span>. We
            deeply appreciate your understanding and patience in this regard.
          </p> */}
          <p className="ml-2 text-sm font-light text-white text-center w-[85%]">
            Please note, due to scheduled maintenance on{" "}
            <span className=" font-extrabold text-[16px]">
              {" "}
              June 20, 2023, from 12:30 AM to 2:30 AM PDT
            </span>
            , our website may not operate as usual. We apologize for any
            inconvenience this may cause and sincerely appreciate your patience
            and understanding.
          </p>
        </div>
      </div>
    );
  }

  if (
    usageData !== undefined && // new users won't see red banner
    showTopBanner &&
    currentUserSubscriptionDetails.planType === PlanType.FREE
  ) {
    if (checkIfProcessingHourisBellowThresholdLimit(usageData)) {
      return (
        <div
          id="banner"
          className="z-50 flex h-[3rem] w-full items-start justify-center gap-8 bg-blue-600 px-4 dark:border-gray-700 dark:bg-gray-800 sm:items-center"
        >
          <div className="flex items-center">
            <img
              src={InfoIcon}
              alt="Info"
            />
            <p className="ml-2 text-sm font-light text-white">
              {`You’ve ${secondsToHm(
                calculateRemainingProcessingTime(usageData)
              )} of processing time remaining on your trial!`}
            </p>
            <button
              onClick={handelShowUpgradeModal}
              id="top-info-banner-upgrade-to-pro-button"
              type="button"
              className="mr-2 ml-6 rounded-lg border border-gray-200 py-1.5 px-5 text-xs font-medium text-white hover:bg-gray-50 hover:text-blue-700 focus:z-10 focus:outline-none focus:ring-2  focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700"
            >
              Upgrade for Unlimited
            </button>
          </div>
        </div>
      );
    }
    if (!checkIfProcessingHourisBellowThresholdLimit(usageData)) {
      return (
        <div className="z-50 flex h-[3rem] w-full items-start justify-center gap-8 bg-red-100 px-4 dark:bg-red-100 sm:items-center">
          <div className="flex items-center">
            <img
              src={InfoIconDanger}
              alt="Info"
            />
            <p className="ml-2 text-sm font-light text-red-600">
              {`You’ve ${secondsToHm(
                calculateRemainingProcessingTime(usageData)
              )} of processing time remaining on your trial!`}
            </p>
            <button
              onClick={handelShowUpgradeModal}
              id="top-danger-banner-upgrade-to-pro-button"
              type="button"
              className="mr-2 ml-6 rounded-lg  border border-red-500 py-1.5 px-5 text-xs font-medium text-red-600 hover:bg-gray-50 hover:text-red-700 focus:z-10 focus:outline-none focus:ring-2  focus:ring-red-300 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700"
            >
              Upgrade for Unlimited
            </button>
          </div>
        </div>
      );
    }
  }

  return <></>;
};

export default Banner;
