import { FolderIcon } from "@heroicons/react/24/outline";

const EmptyAssetsState = () => {
  return (
    <div className="text-center text-gray-400 py-2 col-span-3">
      <FolderIcon
        className="w-8 h-8 mx-auto mb-2"
        strokeWidth={1}
      />
      <p className="text-sm">Your brand kit assets will appear here</p>
    </div>
  );
};

export default EmptyAssetsState;
