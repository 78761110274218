const HorizontalMenuTabContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <nav
      className="sticky z-40 flex top-0 w-full bg-gray-100"
      aria-label="Tabs"
    >
      {children}
      <span
        aria-hidden="true"
        className="absolute inset-x-0 bottom-0 h-px bg-gray-100"
      />
    </nav>
  );
};

export default HorizontalMenuTabContainer;
