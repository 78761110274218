import { memo } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "flowbite-react";

import DeletedImg from "@/assets/images/deleted.svg";

export const ErrorDisplay = memo(() => {
  const navigate = useNavigate();

  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <img
        src={DeletedImg}
        className="w-40 mb-10 -mt-10"
      />

      <div className="text-lg font-medium">
        <p className="text-center">
          We are sorry, but we couldn't find the clips for this video.
          <br /> It seems that the project associated with it has been deleted.
        </p>
      </div>

      <div className="flex items-center mt-8 gap-10">
        <Button
          onClick={() => navigate("/")}
          id="go-to-home"
        >
          Go to Home
        </Button>
      </div>
    </div>
  );
});
