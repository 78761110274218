import { useAppSelector } from "@/store/hooks";

import { BUTTON_IDS } from "@/constants/segment-analytics";

import { trackUserButtonEvent } from "@/utils/SegmentAnalytics";

import { OnBoardingStep, SocialMediaId, VideoLayout } from "@/enums";

import { SOCIAL_MEDIA_HANDLES } from "@/views/editor/constant";

import SocialInput from "./SocialInput";

import demoSocialImage from "@/assets/images/on-boarding/demo-social.jpeg";

const { DONE_BUTTON_PERSONALIZE_VIDEO_FORM } =
  BUTTON_IDS.PERSONALIZE_VIDEO_FORM;

const PersonalizeVideoForm = ({
  handleChangeActiveStep,
  validationForSelectedTemplates,
  userHasExistingProject,
  handelCancelUpload,
  isLoading,
}: any) => {
  const onBoardingData = useAppSelector(
    (state) => state.homeState.onBoardingData
  );
  const onBoardingId = useAppSelector(
    (state) => state.homeState.currentOnBoardingId
  );

  const selectedLayoutsArray = onBoardingData[onBoardingId].selectedLayouts;
  // cases
  // 1. if selected only 16:9 layout then show only youtube
  // 2. if selected only 1:1 layout then show only linkedin and facebook
  // 3. if selected only 9:16:1 layout then show only instagram and tiktok
  // 4. if selected 16:9 and 1:1 layout then show youtube, linkedin and facebook
  // 5. if selected 16:9 and 9:16:1 layout then show youtube, instagram and tiktok
  // 6. if selected 1:1 and 9:16:1 layout then show linkedin, facebook, instagram  and tiktok
  // 7. if selected 16:9, 1:1 and 9:16:1 layout then show youtube, linkedin, facebook, instagram and tiktok

  const isOnly16_9 =
    selectedLayoutsArray.length === 1 &&
    selectedLayoutsArray[0] === VideoLayout.LAYOUT_16_9;

  const isOnly1_1 =
    selectedLayoutsArray.length === 1 &&
    selectedLayoutsArray[0] === VideoLayout.LAYOUT_1_1;

  const isOnly9_16 =
    selectedLayoutsArray.length === 2 &&
    selectedLayoutsArray.includes(VideoLayout.LAYOUT_9_16_1) &&
    selectedLayoutsArray.includes(VideoLayout.LAYOUT_9_16_2);

  const is16_9_1_1 =
    selectedLayoutsArray.includes(VideoLayout.LAYOUT_16_9) &&
    selectedLayoutsArray.includes(VideoLayout.LAYOUT_1_1);

  const is16_9_9_16 =
    selectedLayoutsArray.includes(VideoLayout.LAYOUT_16_9) &&
    selectedLayoutsArray.includes(VideoLayout.LAYOUT_9_16_1);

  const is1_1_9_16 =
    selectedLayoutsArray.includes(VideoLayout.LAYOUT_1_1) &&
    selectedLayoutsArray.includes(VideoLayout.LAYOUT_9_16_1);

  const isAllLayoutSelected = selectedLayoutsArray.length === 4;

  const getSocialMediaHandles = SOCIAL_MEDIA_HANDLES.filter((socialData) => {
    if (isAllLayoutSelected) {
      return socialData.id !== SocialMediaId.WHYZZER;
    } else if (isOnly16_9) {
      return socialData.id === SocialMediaId.YOUTUBE;
    } else if (isOnly1_1) {
      return (
        socialData.id === SocialMediaId.LINKEDIN ||
        socialData.id === SocialMediaId.FACEBOOK
      );
    } else if (isOnly9_16) {
      return (
        socialData.id === SocialMediaId.INSTAGRAM ||
        socialData.id === SocialMediaId.TIKTOK ||
        socialData.id === SocialMediaId.YOUTUBE
      );
    } else if (is16_9_1_1) {
      return (
        socialData.id === SocialMediaId.YOUTUBE ||
        socialData.id === SocialMediaId.LINKEDIN ||
        socialData.id === SocialMediaId.FACEBOOK ||
        socialData.id === SocialMediaId.TWITTER
      );
    } else if (is16_9_9_16) {
      return (
        socialData.id === SocialMediaId.YOUTUBE ||
        socialData.id === SocialMediaId.INSTAGRAM ||
        socialData.id === SocialMediaId.TIKTOK
      );
    } else if (is1_1_9_16) {
      return (
        socialData.id === SocialMediaId.LINKEDIN ||
        socialData.id === SocialMediaId.INSTAGRAM ||
        socialData.id === SocialMediaId.TIKTOK
      );
    }
    return socialData.showAtOnboarding;
  });

  return (
    <div className="relative rounded-lg  dark:bg-gray-700">
      <div className="py-6 px-4 lg:px-6">
        <section className="bg-white ">
          <h2 className="mb-2 text-center  text-2xl font-medium tracking-tight text-gray-900 dark:text-white sm:text-4xl">
            Personalize your videos{" "}
            <span className=" font-thin">(Optional)</span>
          </h2>

          <div className="mx-auto">
            <p className=" text-center text-sm font-light text-gray-400 sm:text-lg">
              Add your social accounts to personalize your videos
            </p>

            <div className="relative mx-auto mt-8 flex w-full justify-center xl:w-[80%]">
              <div className="flex h-[22rem]  w-72 justify-center bg-white">
                <img
                  src={demoSocialImage}
                  className="h-full rounded-md"
                />
              </div>
              <div className="grid grid-cols-2 grid-rows-4 gap-y-4 gap-x-8">
                {getSocialMediaHandles.map((socialData) => (
                  <SocialInput
                    key={socialData.id}
                    socialData={socialData}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="flex justify-center space-x-4 pb-4">
        <button
          onClick={() => {
            if (userHasExistingProject) {
              handelCancelUpload();
            } else {
              handleChangeActiveStep(OnBoardingStep.SELECT_TEMPLATES);
            }
          }}
          disabled={isLoading}
          type="button"
          id="back-button-personalize-video-form"
          className={`mt-2 inline-flex w-44 items-center justify-center rounded-lg border border-blue-500 bg-white px-5 py-2.5 text-center text-sm font-medium text-blue-500 hover:bg-blue-50 focus:outline-none focus:ring-4 focus:ring-blue-300 sm:py-3.5`}
        >
          Back
        </button>
        <button
          type="button"
          id={DONE_BUTTON_PERSONALIZE_VIDEO_FORM.ID}
          className={`mt-2 inline-flex w-44 items-center justify-center rounded-lg bg-blue-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 sm:py-3.5`}
          onClick={() => {
            trackUserButtonEvent(
              DONE_BUTTON_PERSONALIZE_VIDEO_FORM.ID,
              DONE_BUTTON_PERSONALIZE_VIDEO_FORM.ACTION
            );
            if (!userHasExistingProject) {
              validationForSelectedTemplates();
            }
            handleChangeActiveStep(OnBoardingStep.COMPLETE_ONBOARDING);
          }}
        >
          Done
        </button>
      </div>
    </div>
  );
};

export default PersonalizeVideoForm;
