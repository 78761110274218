import { memo, useRef, useState } from "react";
import { useUnmount, useUpdateEffect } from "react-use";

import { useSelectedClips } from "@/context/ReviewClips";
import { Button } from "flowbite-react";

import { updateExportQuality } from "@/store/editorSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import api from "@/api/api";
import { ApiEndpoints } from "@/api/constants/ApiEndPoints";
import useBulkRenderVideo from "@/api/useBulkRenderVideo";
import useGetUserTemplates from "@/api/useGetUserTemplates";

import {
  downloadS3Url,
  getTemplatesForLayout,
  removeDuplicateObjectsFromArray,
  sleep,
} from "@/helpers";

import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

import {
  ExportQuality,
  PlanType,
  TemplateTabVariant,
  VideoLayout,
} from "@/enums";

import BaseModal from "@/components/BaseModal/BaseModal";
import Spinner from "@/components/Loader/Spinner";

import ClipPreviewEditor from "@/views/home/components/BulkExportClipsModal/ClipPreviewEditor";
import TemplateSlider from "@/views/home/components/BulkExportClipsModal/TemplateSlider";

import downloadFilesIlls from "@/assets/images/cloud-files.svg";

const TEMPLATE_SELECTION = "TEMPLATE_SELECTION";
const GENERATE_PAYLOAD = "GENERATE_PAYLOAD";
const DOWNLOADING = "DOWNLOADING";

type BulkExportTemplateSelectionModalProps = {
  isSelectExportMultipleClipsModalOpen: boolean;
  toggleSelectExportMultipleClipsModal: () => void;
};

const BulkExportTemplateSelectionModal = memo(
  ({
    isSelectExportMultipleClipsModalOpen,
    toggleSelectExportMultipleClipsModal,
  }: BulkExportTemplateSelectionModalProps) => {
    const dispatch = useAppDispatch();

    const [currentStep, setCurrentStep] = useState<string>(TEMPLATE_SELECTION);
    const [currentSelectedTab, setCurrentSelectedTab] = useState(
      TemplateTabVariant.DEFAULT_TEMPLATE
    );
    const pollAbortController = useRef<any>(null);

    const selectedLayout = useAppSelector(
      (state) => state.editorState.selectedLayout
    );
    const {
      toggleMultipleRenderMode,
      multipleRenderModeOn,
      renderVideoPayloadForMultipleClips,
      resetSelectedTemplatesId,
      isPreviewClipEditorLoaded,
      updateRenderVideoPayloadForMultipleClips,
    } = useSelectedClips();

    const baseTemplates = useAppSelector(
      (state) => state.homeState.baseTemplates
    );

    const currentSelectedProject = useAppSelector(
      (state) => state.homeState.currentSelectedProject
    );

    const uid = useAppSelector((state) => state.authState.currentUser.uid);

    const planType = useAppSelector(
      (state) => state.authState.userSubscription.planType
    );
    const isPaidUser = planType !== PlanType.FREE;

    const userTemplatesData = useGetUserTemplates(uid);

    const isUserTemplateEmpty = (layoutId: VideoLayout) => {
      return (
        baseTemplates[layoutId]?.filter((template: any) => !template.isDefault)
          ?.length <= 0
      );
    };

    const generateTemplatesData = (
      layoutId: string,
      currentSelectedTab: TemplateTabVariant
    ) => {
      const userSavedTemplates = getTemplatesForLayout(
        layoutId,
        userTemplatesData?.data
      );

      const templatesArr =
        currentSelectedTab === TemplateTabVariant.USER_TEMPLATE
          ? userSavedTemplates
          : baseTemplates[layoutId]?.filter(
              (template: any) => template.isDefault
            );

      return removeDuplicateObjectsFromArray([...templatesArr]);
    };

    const handleDownload = () => {
      if (currentStep === TEMPLATE_SELECTION) {
        dispatch(
          updateExportQuality(isPaidUser ? ExportQuality.FHD : ExportQuality.HD)
        );
        setCurrentStep(GENERATE_PAYLOAD);
      }
      if (currentStep === GENERATE_PAYLOAD) {
        toggleMultipleRenderMode();
      }
    };

    const pollForBulkRenderVideo = async (renderTaskId: string) => {
      await sleep(5000);
      try {
        let controller;
        if (!pollAbortController.current) {
          controller = new AbortController();
          pollAbortController.current = controller;
        } else {
          controller = pollAbortController.current;
        }

        const response: any = await api.get(
          ApiEndpoints.GET_BULK_RENDER_VIDEO,
          {
            params: {
              render_task_id: renderTaskId,
              project_id: currentSelectedProject && currentSelectedProject.id,
            },
            signal: controller.signal,
          }
        );

        if (!response.data.zip_url) {
          await sleep(10000);
          await pollForBulkRenderVideo(renderTaskId);
        } else if (response.data.zip_url) {
          currentSelectedProject &&
            downloadS3Url(
              response.data.zip_url,
              response.data?.filename || "",
              currentSelectedProject.id,
              true
            );
          // const currentRenderId = store.getState().editorState.currentRenderId;
          // currentRenderId === response.data.render_task_id &&
          //   closeDownloadModal();
        } else {
          showNotification(
            `Failed to process your exports. Please try again.`,
            notificationType.FAIL
          );
        }
      } catch (e: any) {
        console.log(e);
        if (e?.message === "canceled") {
          pollAbortController.current = null;
        } else {
          showNotification(
            `Failed to process your exports. Please try again.`,
            notificationType.FAIL
          );
        }
      }
    };
    const bulkRenderVideoErrorCB = () => {
      showNotification(
        "Could not process with exports. Please try again!",
        notificationType.FAIL
      );
    };
    const bulkRenderVideoSuccessCB = () => {
      updateRenderVideoPayloadForMultipleClips([]);
      setCurrentStep(DOWNLOADING);
      setCurrentSelectedTab(TemplateTabVariant.DEFAULT_TEMPLATE);
    };

    const { mutate: renderVideoMutate, isLoading } = useBulkRenderVideo(
      pollForBulkRenderVideo,
      bulkRenderVideoErrorCB,
      bulkRenderVideoSuccessCB
    );

    const handleBulkExport = () => {
      renderVideoMutate({
        request_list: renderVideoPayloadForMultipleClips,
      });
    };

    useUpdateEffect(() => {
      if (renderVideoPayloadForMultipleClips?.length) {
        handleBulkExport();
      }
    }, [renderVideoPayloadForMultipleClips?.length]);

    const handleDone = () => {
      toggleSelectExportMultipleClipsModal();
      setCurrentStep(TEMPLATE_SELECTION);
      setCurrentSelectedTab(TemplateTabVariant.DEFAULT_TEMPLATE);
    };

    const renderComponent = (step: string) => {
      switch (step) {
        case TEMPLATE_SELECTION:
          return (
            <>
              <div className="text-2xl font-bold text-gray-900">
                Please select template for your clip
              </div>

              <div className="flex flex-col gap-6 mt-4 mb-4 select-none items-center bg-red">
                <div className="flex items-center justify-around w-full py-10">
                  <div className=" relative mx-auto flex justify-center">
                    <TemplateSlider
                      selectedLayout={selectedLayout}
                      generateTemplatesData={generateTemplatesData}
                      currentSelectedTab={currentSelectedTab}
                      setCurrentSelectedTab={setCurrentSelectedTab}
                      isUserTemplateEmpty={isUserTemplateEmpty}
                    />
                  </div>
                </div>
              </div>
            </>
          );
        case GENERATE_PAYLOAD:
          return (
            <div className="h-96">
              <div className="text-2xl font-bold text-gray-900">
                Preview your export
              </div>

              <ClipPreviewEditor />
            </div>
          );
        case DOWNLOADING:
          return (
            <div className="h-96">
              <div className="text-2xl font-bold text-gray-900">
                Wohoo! Your exports are being generated.
              </div>
              <p className="mt-2">
                Your clips will be available in the{" "}
                <span className="font-bold">Home {">"} Downloads</span>{" "}
              </p>

              <div className="flex flex-col gap-6 mt-4 mb-9 select-none items-center">
                <img
                  src={downloadFilesIlls}
                  className="h-56 mt-8"
                />
              </div>
            </div>
          );
        default:
          return <></>;
      }
    };

    const generateCTA = (step: string) => {
      switch (step) {
        case TEMPLATE_SELECTION:
          return (
            <div className="flex justify-center gap-10">
              <Button
                color="light"
                id="multiple-clips-select-template-cancel-btn"
                className="w-36"
                onClick={() => {
                  toggleSelectExportMultipleClipsModal();
                  setCurrentStep(TEMPLATE_SELECTION);
                  setCurrentSelectedTab(TemplateTabVariant.DEFAULT_TEMPLATE);
                  resetSelectedTemplatesId();
                }}
              >
                Cancel
              </Button>
              <Button
                id="proceed-multiple-clips-btn"
                onClick={handleDownload}
                size="md"
                className="w-36"
                disabled={multipleRenderModeOn || isLoading}
              >
                Next
              </Button>
            </div>
          );
        case GENERATE_PAYLOAD:
          return (
            <div className="flex justify-center gap-10">
              <Button
                color="light"
                id="multiple-clips-cancel-btn"
                className="w-36"
                onClick={() => setCurrentStep(TEMPLATE_SELECTION)}
              >
                Back
              </Button>
              <Button
                id="download-multiple-clips-btn"
                onClick={handleDownload}
                size="md"
                className="w-36"
                disabled={
                  multipleRenderModeOn ||
                  isLoading ||
                  !isPreviewClipEditorLoaded
                }
              >
                {multipleRenderModeOn || isLoading ? (
                  <Spinner className="h-4 w-4" />
                ) : null}
                Download
              </Button>
            </div>
          );
        case DOWNLOADING:
          return (
            <div className="flex justify-center gap-10">
              <Button
                id="done-multiple-clips-btn"
                size="md"
                className="w-60"
                onClick={handleDone}
              >
                Done
              </Button>
            </div>
          );
        default:
          return <></>;
      }
    };

    useUnmount(() => {
      pollAbortController.current && pollAbortController.current.abort();
    });

    return (
      <BaseModal
        isModalOpen={isSelectExportMultipleClipsModalOpen}
        notClosable
      >
        <div className="sm:w-[65rem]">
          <div className="flex flex-col justify-center bg-white px-11 pt-7 pb-8 text-center">
            {renderComponent(currentStep)}

            <div className="flex justify-center gap-10">
              {generateCTA(currentStep)}
            </div>
          </div>
        </div>
      </BaseModal>
    );
  }
);

export default BulkExportTemplateSelectionModal;
