import clsx from "clsx";

import { IconPropType } from "@/utils/constants";

export const ForwardIcon = ({
  fillColor,
  height,
  width,
  className,
  hoverFillColor,
}: IconPropType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={clsx(
        className,
        fillColor && `text-${fillColor}`,
        hoverFillColor && `hover:text-${hoverFillColor}`,
        "fill-current"
      )}
      color=""
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M11.244 3a8.947 8.947 0 0 1 6.368 2.64c.681.69 1.287 1.353 1.882 2.048V6a.75.75 0 0 1 1.5 0v3.75a.75.75 0 0 1-.75.75h-3.75a.75.75 0 0 1 0-1.5h2.156a40.532 40.532 0 0 0-2.099-2.303 7.5 7.5 0 1 0-.155 10.757.752.752 0 0 1 1.031 1.092A9 9 0 1 1 11.244 3Z"
      />
      <path
        fill="currentColor"
        d="M10.81 16.744c-.569 0-1.077-.106-1.526-.32a2.72 2.72 0 0 1-1.07-.886 2.346 2.346 0 0 1-.417-1.287H9.33c.028.358.183.651.464.878.282.225.62.337 1.015.337a1.6 1.6 0 0 0 .826-.213c.242-.142.432-.34.571-.592a1.73 1.73 0 0 0 .205-.865 1.744 1.744 0 0 0-.209-.878 1.56 1.56 0 0 0-.584-.601 1.628 1.628 0 0 0-.852-.222 2.028 2.028 0 0 0-.771.145c-.253.1-.453.23-.601.392l-1.428-.234.456-4.5h5.063v1.32H9.732l-.252 2.315h.051c.162-.19.39-.348.686-.473a2.42 2.42 0 0 1 .972-.192c.528 0 1 .125 1.415.375.415.247.741.588.98 1.023.238.434.358.931.358 1.491 0 .577-.134 1.091-.4 1.543a2.833 2.833 0 0 1-1.104 1.06c-.47.256-1.012.384-1.628.384Z"
      />
    </svg>
  );
};
