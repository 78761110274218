import { useMutation } from "@tanstack/react-query";

import api from "@/api/api";

import { ApiEndpoints } from "./constants/ApiEndPoints";

const postRequestForRenderVideo = async (
  body: any,
  enableSceneChange: boolean
) => {
  const { data } = await api.post(
    enableSceneChange
      ? ApiEndpoints.RENDER_VIDEO_TEMP
      : ApiEndpoints.POST_RENDER_VIDEO,
    { ...body }
  );
  return data;
};

export function useRenderVideoCutMagic(
  pollForRenderVideo: any,
  renderVideoErrorCB?: any,
  renderVideoSuccessCB?: any
) {
  return useMutation(
    ({ ...body }: any) => postRequestForRenderVideo(body, true),
    {
      onSuccess: (res: any) => {
        renderVideoSuccessCB && renderVideoSuccessCB(res.render_task_id);
        pollForRenderVideo(res.render_task_id);
      },
      onError: () => {
        renderVideoErrorCB && renderVideoErrorCB();
      },
    }
  );
}

export default function useRenderVideo(
  pollForRenderVideo: any,
  renderVideoErrorCB?: any,
  renderVideoSuccessCB?: any
) {
  return useMutation(
    ({ ...body }: any) => postRequestForRenderVideo(body, false),
    {
      onSuccess: (res: any) => {
        renderVideoSuccessCB && renderVideoSuccessCB(res.render_task_id);
        pollForRenderVideo(res.render_task_id);
      },
      onError: () => {
        renderVideoErrorCB && renderVideoErrorCB();
      },
    }
  );
}
