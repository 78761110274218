import { ExportQuality, PlanType, VideoLayout } from "@/enums";

export const SUBTITLE_ASSET_PREFIX = "subtitle_asset_";
export const TEXT_ASSET_PREFIX = "text_asset_";
export const SUBTITLE_EMOJI_PREFIX = "subtitle_emoji_";
export const ID_TITLE_TEXT = "title-text";
export const LOGIN = "LOGIN";
export const DEFAULT_SOCIAL_IMAGE_SIZE = 25;
export const EXPORT_RESOLUTIONS = {
  [ExportQuality.HD]: {
    [VideoLayout.LAYOUT_16_9]: {
      width: 1280,
      height: 720,
    },
    [VideoLayout.LAYOUT_9_16_1]: {
      width: 720,
      height: 1280,
    },
    [VideoLayout.LAYOUT_9_16_2]: {
      width: 720,
      height: 1280,
    },
    [VideoLayout.LAYOUT_1_1]: {
      width: 720,
      height: 720,
    },
  },
  [ExportQuality.FHD]: {
    [VideoLayout.LAYOUT_16_9]: {
      width: 1920,
      height: 1080,
    },
    [VideoLayout.LAYOUT_9_16_1]: {
      width: 1080,
      height: 1920,
    },
    [VideoLayout.LAYOUT_9_16_2]: {
      width: 1080,
      height: 1920,
    },
    [VideoLayout.LAYOUT_1_1]: {
      width: 1080,
      height: 1080,
    },
  },
  [ExportQuality.UHD]: {
    [VideoLayout.LAYOUT_16_9]: {
      width: 3840,
      height: 2160,
    },
    [VideoLayout.LAYOUT_9_16_1]: {
      width: 2160,
      height: 3840,
    },
    [VideoLayout.LAYOUT_9_16_2]: {
      width: 2160,
      height: 3840,
    },
    [VideoLayout.LAYOUT_1_1]: {
      width: 2160,
      height: 2160,
    },
  },
};
export const VIDEO_CURRENT_TIME_POLLING_INTERVAL = 25;
export const TIME_TO_START_POLLING_BEFORE_CLIP_END = 1000;

export const FREE_USER_UPLOAD_FILE_SIZE_LIMIT = 5368709120; //5GB
export const PRO_USER_UPLOAD_FILE_SIZE_LIMIT = 16106127360; //15GB

export const PARALLEL_UPLOAD_LIMITS = {
  [PlanType.FREE]: 1,
  [PlanType.PRO]: 3,
  [PlanType.UNLIMITED]: 3,
  [PlanType.PRO_PLUS]: 5,
  [PlanType.STARTER]: 1,
  [PlanType.FULL_SERVICE]: 5,
};

export const PLAN_LABEL_TEXT = {
  [PlanType.FREE]: "FREE",
  [PlanType.PRO]: "PRO",
  [PlanType.STARTER]: "STARTER",
  [PlanType.UNLIMITED]: "UNLIMITED",
  [PlanType.PRO_PLUS]: "AUDYO+",
  [PlanType.FULL_SERVICE]: "FULL SERVICE",
};

export const DEFAULT_TEMPLATE_DIMENSIONS = {
  [VideoLayout.LAYOUT_9_16_1]: { width: 153, height: 272 },
  [VideoLayout.LAYOUT_9_16_2]: { width: 153, height: 272 },
  [VideoLayout.LAYOUT_1_1]: { width: 200, height: 200 },
  [VideoLayout.LAYOUT_16_9]: { width: 304, height: 171 },
};

export const EDITOR_TEMPLATE_DIMENSIONS = {
  [VideoLayout.LAYOUT_9_16_1]: { width: 153, height: 272 },
  [VideoLayout.LAYOUT_9_16_2]: { width: 153, height: 272 },
  [VideoLayout.LAYOUT_1_1]: { width: 250, height: 250 },
  [VideoLayout.LAYOUT_16_9]: { width: 304, height: 171 },
};

export const BUFFER_TIME_FREE_USER_IN_MINS = 15;
export const BUFFER_TIME_PAID_USER_IN_MINS = 30;

export const BUFFER_DIMENSIONS = {
  usernameLeft: {
    tiktok: 30,
    instagram: 160,
    youtube: 150,
  },
  usernameTop: {
    tiktok: 1630,
    instagram: 1630,
    youtube: 1680,
  },
  titleLeft: {
    tiktok: 30,
    instagram: 35,
    youtube: 40,
  },
  titleTop: {
    tiktok: 1710,
    instagram: 1750,
    youtube: 1780,
  },
  musicLeft: {
    tiktok: 90,
    instagram: 70,
  },
  musicTop: {
    tiktok: 1790,
    instagram: 1830,
  },
  profileImageLeft: {
    tiktok: 920,
    instagram: 20,
    youtube: 35,
  },
  profileImageTop: {
    tiktok: 720,
    instagram: 1600,
    youtube: 1660,
  },
  profileImage2Left: {
    tiktok: 900,
    instagram: 940,
    youtube: 930,
  },
  profileImage2Top: {
    tiktok: 1670,
    instagram: 1760,
    youtube: 1760,
  },
};

export const BUFFER_FOR_CENTERED = {
  logo: {
    left: 100,
    top: 0,
  },
  text: {
    left: 50,
    top: 80,
  },
};

export const SOCIAL_MEDIA_SELECTED_IMAGE = {
  TIKTOK: "tiktok",
  INSTAGRAM: "instagram",
  YOUTUBE: "youtube",
};

export const INTELLI_CLIP_TAG = "004";

export const INTELLI_CLIP = "IntelliClip™";

export const AUDIO_PLAYING_STATUS = " AUDIO_PLAYING_STATUS";
