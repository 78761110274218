import { useMemo } from "react";

import { useSelectedClips } from "@/context/ReviewClips";
import clsx from "clsx";

import { TemplateTabVariant, TemplateVariant, VideoLayout } from "@/enums";

import TemplateStaticPreview from "@/views/home/components/TemplateStaticPreview";

type TemplateContainerProps = {
  templateData: any;
  selectedLayout: VideoLayout;
};

const TemplateContainer = ({
  templateData,
  selectedLayout,
}: TemplateContainerProps) => {
  const { setSelectedTemplatesId, selectedTemplatesId } = useSelectedClips();

  const dimensions = useMemo(
    () =>
      selectedLayout === VideoLayout.LAYOUT_1_1
        ? { width: 200, height: 200 }
        : selectedLayout === VideoLayout.LAYOUT_16_9
        ? { width: 304, height: 171 }
        : { width: 153, height: 272 },
    []
  );

  const generateTemplatePreviewProps = (
    templateData: any,
    selectedLayout: VideoLayout
  ) => {
    const props = {
      template: templateData,
      variant: TemplateVariant.BULK_EXPORT,
      key: templateData.id,
      showDuration: false,
      layout: selectedLayout,
      dimensions: dimensions,
      handelChangeTemplate: () => {
        setSelectedTemplatesId((prev: any) => {
          return {
            ...prev,
            [selectedLayout]: templateData.id,
          };
        });
      },
      isSelected: selectedTemplatesId[selectedLayout] === templateData.id,
    };

    if (selectedLayout === VideoLayout.LAYOUT_16_9) {
      props.layout = VideoLayout.LAYOUT_16_9;
      props.dimensions = { width: 304, height: 171 };
    } else {
      // props.layout = templateData.hasTwoFace
      //   ? VideoLayout.LAYOUT_9_16_2
      //   : selectedLayout;
      props.layout = selectedLayout;
      props.dimensions =
        selectedLayout === VideoLayout.LAYOUT_1_1
          ? { width: 200, height: 200 }
          : { width: 153, height: 272 };
    }

    return props;
  };

  return (
    <div
      className={clsx(
        "h-full relative  flex cursor-pointer select-none justify-center"
      )}
    >
      <input
        id={templateData.id}
        type="checkbox"
        value=""
        className="hidden rounded-full border-gray-300 bg-red-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
      />
      <label
        htmlFor={templateData.id}
        className={`flex cursor-pointer justify-center w-[${dimensions.width}px]`}
      >
        <TemplateStaticPreview
          {...generateTemplatePreviewProps(templateData, selectedLayout)}
        />
      </label>
    </div>
  );
};

export default TemplateContainer;
