import { useState } from "react";
import { TwitterPicker } from "react-color";

import { useAppSelector } from "@/store/hooks";

import useGetUserPreference from "@/api/useGetUserPreference";

import { defaultColorsPalette } from "@/constants/colors";

import ColorUploadModal from "@/views/home/components/UploadMedia/ColorUploadModal";

import BrandColorPicker from "./BrandColorPicker";

import { ReactComponent as BrandKitIcon } from "@/assets/icons/brand-kit/media-type-brand-kit.svg";
import { ReactComponent as ColorsIcon } from "@/assets/icons/brand-kit/media-type-colors.svg";

type ColorPickerProps = {
  colorInHex: string;
  handleChangeColor: (color: string) => void;
};

const ColorPicker = (props: ColorPickerProps) => {
  const [showColorUploadModal, setShowColorUploadModal] = useState(false);

  const { colorInHex, handleChangeColor } = props;

  const uid = useAppSelector((state) => state.authState.currentUser.uid);

  const { data: userPreferenceData } = useGetUserPreference(uid);

  return (
    <div>
      <div className="flex items-center space-x-1.5 mt-2 mb-3">
        <BrandKitIcon className="w-4 h-4" />
        <p className="text-sm">Brand Kit Colors</p>
      </div>

      <BrandColorPicker
        onChange={handleChangeColor}
        color={colorInHex}
        colors={userPreferenceData?.colorPallets}
        setShowColorUploadModal={setShowColorUploadModal}
      />
      <div className="flex items-center space-x-1.5 mt-4 mb-3">
        <ColorsIcon className="w-4 h-4" />
        <p className="text-sm">All Colors</p>
      </div>
      <TwitterPicker
        width="100%"
        colors={defaultColorsPalette}
        triangle="hide"
        color={colorInHex}
        onChange={handleChangeColor}
      />

      <ColorUploadModal
        showColorUploadModal={showColorUploadModal}
        setShowColorUploadModal={setShowColorUploadModal}
      />
    </div>
  );
};

export default ColorPicker;
