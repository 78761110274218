import { useDispatch } from "react-redux";

import { toggleUpgradeToProModal } from "@/store/homeSlice";

import UpdateToProModal from "@/views/home/components/UpdateToProModal";

export const UpgradeToPro = ({
  activeTab,
  setActiveTab,
  closeShareModal,
}: {
  activeTab: string;
  setActiveTab: (a: string) => any;
  closeShareModal: () => void;
}) => {
  const dispatch = useDispatch();

  const handleUpgradeToPro = () => {
    dispatch(toggleUpgradeToProModal(true));
    closeShareModal();
  };

  return (
    <div className="mt-16 text-center">
      <h3 className="text-xl font-semibold">
        Upgrade to post directly to{" "}
        {`${activeTab[0].toUpperCase()}${activeTab.slice(1)}`}
      </h3>
      <p className="text-sm font-light mt-5">
        Post videos directly from audyo
        <br />
        Free users can post to Tiktok <br />
        PRO users can post everywhere
      </p>
      <button
        className="flex items-center justify-center m-auto text-sm font-medium mt-12 bg-primary-600 hover:bg-primary-700 rounded text-white h-[40px] w-[180px]"
        onClick={handleUpgradeToPro}
      >
        Upgrade to PRO
      </button>
      <button
        className="flex items-center justify-center m-auto text-sm font-medium mt-6 bg-white border border-gray-200 rounded text-gray-800 h-[40px] w-[180px] hover:bg-primary-600 hover:text-white"
        onClick={() => setActiveTab("tiktok")}
      >
        Post to Tiktok for Free
      </button>

      <UpdateToProModal />
    </div>
  );
};
