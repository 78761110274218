import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router";
import { useBoolean } from "react-use";

import {
  CheckCircleIcon,
  CheckIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { Spinner } from "flowbite-react";

import { useAppSelector } from "@/store/hooks";

import {
  deleteScheduledPost,
  getScheduledPostsList,
  updateScheduledPost,
} from "@/api/requests";
import useGetScheduledPostsHistory from "@/api/useGetScheduledPostsHistory";

import { convertTimeStampToDateTime } from "@/helpers";

import { trackSchedulePostUpdateEvent } from "@/utils/amplitudeAnalytcs";
import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

import { RouterPath } from "@/enums";

import {
  FbIcon,
  InstagramIcon,
  LinkedinIcon,
  TiktokIcon,
  TwitterIcon,
  YoutubeIcon,
} from "@/views/home/components/DownloadVideoCard/SocialMediaSharing/icons";

import { VideoPreviewModal } from "./videoPreviewModal";

export const ScheduledPosts = () => {
  const navigate = useNavigate();

  const [selectedDate, setSelectedDate] = useState<any>({});
  const [isEditLoading, setIsEditLoading] = useState<any>({});
  const [showEditConfirmation, setShowEditConfirmation] = useState<any>({});
  const [isDeleteLoading, setIsDeleteLoading] = useState<any>({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<any>({});
  const [showPreviewModal, setShowPreviewModal] = useState<any>({});
  const [scheduledList, setScheduledList] = useState<any>({});
  const [showNoScheduledPostsMsg, setShowNoScheduledPostsMsg] =
    useBoolean(false);
  const [isLoading, setIsLoading] = useBoolean(true);
  const [isEditSuccess, setIsEditSuccess] = useState<any>({});

  const { uid } = useAppSelector((state) => state.authState.currentUser);

  useGetScheduledPostsHistory({
    successCallback: (res) => onPendingListSuccess(res),
    errorCallback: (error) => onPendingListError(error),
  });

  const onPendingListSuccess = (response: any) => {
    const { data, status } = response;
    if (status === 200) {
      if (!data.data.length) {
        setShowNoScheduledPostsMsg(true);
      } else {
        const sortedScheduledPosts = data.data.sort(
          (post1: any, post2: any) =>
            new Date(post1.scheduleDate.utc).getTime() -
            new Date(post2.scheduleDate.utc).getTime()
        );
        let postsList: any = {};

        sortedScheduledPosts.forEach((post: any) => {
          const month = new Date(post.scheduleDate.utc).toLocaleString(
            "default",
            {
              month: "long",
            }
          );
          if (postsList[month]) {
            postsList[month].push(post);
          } else {
            postsList[month] = [post];
          }
        });
        setScheduledList(postsList);
      }
    }
    setIsLoading(false);
  };

  const onPendingListError = (err: any) => {
    setIsLoading(false);
    if (err.response?.status === 404) {
      setShowNoScheduledPostsMsg(true);
      return;
    }
    if (err.response.data.code !== 221) {
      showNotification(
        "Something went wrong. Please refresh the page to try again!",
        notificationType.FAIL
      );
    }
  };

  const getPosts = async () => {
    try {
      const resposne = await getScheduledPostsList({
        user_id: uid,
        status: "pending",
        recent_posts: 500,
      });
      if (resposne.status === 200) {
        onPendingListSuccess(resposne);
      }
    } catch (error) {
      onPendingListError(error);
    }
  };

  const editScheduledPost = async (
    scheduledDate: Date | null,
    videoId: string,
    clipId: string
  ) => {
    try {
      const payload = {
        user_id: uid,
        video_id: videoId,
        paid_version: {
          schedule_dt: new Date(scheduledDate!).toISOString(),
        },
      };
      const response = await updateScheduledPost(payload);
      if (response.status === 200) {
        await getPosts();
        setIsEditSuccess({
          [videoId]: true,
        });
        scheduledDate &&
          trackSchedulePostUpdateEvent({
            clipId,
            scheduledDate,
            isDelete: false,
          });
      }
      setIsEditLoading({
        [videoId]: false,
      });
    } catch (err) {
      showNotification(
        "Something went wrong. Please try again!",
        notificationType.FAIL
      );
      setIsEditLoading({
        [videoId]: false,
      });
    }
  };

  const deletePost = async (videoId: string, clipId: string) => {
    try {
      const payload = {
        user_id: uid,
        video_id: videoId,
      };
      const response = await deleteScheduledPost(payload);
      if (response.status === 200) {
        await getPosts();
        trackSchedulePostUpdateEvent({ clipId, isDelete: true });
      }
      setIsDeleteLoading({
        [videoId]: false,
      });
    } catch (err) {
      showNotification(
        "Something went wrong. Please try again!",
        notificationType.FAIL
      );
      setIsDeleteLoading({
        [videoId]: false,
      });
    }
  };

  const getIconForSocialMedia = (platformName: string) => {
    switch (platformName) {
      case "tiktok":
        return <TiktokIcon fillColor="#6B7280" />;
      case "youtube":
        return <YoutubeIcon fillColor="#6B7280" />;
      case "facebook":
        return <FbIcon fillColor="#6B7280" />;
      case "instagram":
        return <InstagramIcon fillColor="#6B7280" />;
      case "linkedin":
        return <LinkedinIcon fillColor="#6B7280" />;
      case "twitter":
        return <TwitterIcon fillColor="#6B7280" />;
    }
  };

  const handleCalendarChange = (value: any, videoId: string) => {
    setSelectedDate({
      [videoId]: value,
    });
    setShowEditConfirmation({
      [videoId]: true,
    });
  };

  const openPreviewModal = (videoId: string) => {
    setShowPreviewModal({
      [videoId]: true,
    });
  };

  const onEditConfirm = async (videoId: string, clipId: string) => {
    setIsEditLoading({
      [videoId]: true,
    });
    setShowEditConfirmation({
      [videoId]: false,
    });
    editScheduledPost(selectedDate[videoId], videoId, clipId);
  };

  const onDeleteConfirm = async (videoId: string, clipId: string) => {
    setIsDeleteLoading({
      [videoId]: true,
    });
    setShowDeleteConfirmation({
      [videoId]: false,
    });
    deletePost(videoId, clipId);
  };

  useEffect(() => {
    let timer: any = null;

    if (isEditSuccess) {
      timer = setTimeout(() => {
        setIsEditSuccess({});
      }, 4000);
    }

    return () => clearTimeout(timer);
  }, [isEditSuccess]);

  useEffect(() => {
    Object.keys(scheduledList).forEach((key: any) => {
      scheduledList[key].forEach((post: any) => {
        setSelectedDate((prevState: any) => ({
          ...prevState,
          [post.id]: new Date(post.scheduleDate.utc),
        }));
      });
    });
  }, []);

  if (isLoading) {
    return (
      <div className="h-[84%] flex items-center justify-center ">
        <Spinner className="w-[22px] h-[22px]" />
      </div>
    );
  }
  if (showNoScheduledPostsMsg) {
    return (
      <p className="h-[78%] text-xl flex flex-col items-center justify-center font-medium text-gray-800">
        No posts scheduled yet! 🙁
        <p className="mt-3 text-base text-black w-full text-center">
          You can Schedule post via Share in{" "}
          <span
            className="text-blue-600 cursor-pointer"
            onClick={() => navigate(RouterPath.DOWNLOADS)}
          >
            Downloads.
          </span>
          <br />
          Connect your social media accounts and set up your post timings. 🚀
        </p>
      </p>
    );
  }
  return (
    <>
      {Object.keys(scheduledList).map((month: string) => (
        <div
          key={month}
          className="last-of-type:pt-4 pb-2 last-of-type:pb-0"
        >
          <h5 className="text-gray-800 font-medium text-md pb-3">
            {month} ({scheduledList[month].length})
          </h5>
          <div className="flex justify-between flex-wrap">
            {scheduledList[month].map((post: any): any => {
              const { platforms, scheduleDate, title, mediaUrls, id, clip_id } =
                post;

              return (
                <div
                  key={post.id}
                  className="w-[49%]"
                >
                  <div
                    className="relative px-3 items-center bg-gray-50 rounded-lg shadow-md border sm:flex h-auto py-2 border-gray-200 bg-transparent mb-5"
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.4) 3px 3px 10px -4px",
                    }}
                  >
                    <video
                      className="rounded-lg h-[130px] w-[150px] border border-gray-300 cursor-pointer min-w-[150px]"
                      src={mediaUrls[0]}
                      onClick={() => openPreviewModal(id)}
                    />
                    <div className="pl-3">
                      <h3
                        className="text-sm font-medium tracking-tight text-gray-700 dark:text-white w-[85%] mb-2 max-w-sm"
                        style={{ overflowWrap: "break-word" }}
                      >
                        {title.replace(".mp4", "")}
                      </h3>
                      <span className="text-gray-500 dark:text-gray-400 text-sm">
                        Scheduled Time: <br />
                        <span className="font-bold text-md text-gray-900">
                          {convertTimeStampToDateTime(scheduleDate.utc)}
                        </span>
                      </span>
                      <div className="mt-3 flex items-center mt-4 pb-2">
                        {isEditSuccess[id] ? (
                          <div className="rounded h-8 flex text-green-600 items-center pr-2">
                            <CheckCircleIcon className="text-green-600 w-5 h-5 mr-2" />{" "}
                            Success
                          </div>
                        ) : showEditConfirmation[id] ? (
                          <div className="rounded flex items-center pr-2">
                            <span className="text-sm font-medium mr-4">
                              {convertTimeStampToDateTime(selectedDate[id])}
                            </span>
                            <button
                              type="button"
                              onClick={() => onEditConfirm(id, clip_id)}
                              className={`inline-flex items-center justify-center  
                  rounded bg-white border border-green-500 h-8 w-8 focus:outline-none mr-4 cursor-pointer`}
                              style={{ position: "relative", left: "5px" }}
                            >
                              <CheckIcon className="w-5 h-5 text-green-500" />
                            </button>
                            <button
                              type="button"
                              onClick={() => setShowEditConfirmation(false)}
                              className={`inline-flex items-center justify-center  
                  rounded bg-white border border-red-700 h-8 w-8 focus:outline-none cursor-pointer`}
                            >
                              <XMarkIcon className="w-5 h-5 text-red-700" />
                            </button>
                          </div>
                        ) : (
                          <DatePicker
                            selected={selectedDate[id]}
                            onChange={(date) => handleCalendarChange(date, id)}
                            timeInputLabel="Time:"
                            dateFormat="MM/dd/yyyy h:mm aa"
                            customInput={
                              <span className="inline-flex items-center justify-center text-blue-600 h-[32px] cursor-pointer w-17">
                                {isEditLoading[id] && (
                                  <Spinner className="w-[16px] h-[16px] mr-2" />
                                )}
                                Edit Date
                              </span>
                            }
                            minDate={new Date()}
                            showTimeInput
                            calendarClassName="absolute"
                          />
                        )}
                        {showDeleteConfirmation[id] ? (
                          <div className="flex items-center">
                            <span className="text-sm font-medium mr-4 ml-5">
                              Are you sure?
                            </span>
                            <button
                              type="button"
                              onClick={() => onDeleteConfirm(id, clip_id)}
                              className={`inline-flex items-center justify-center  
          rounded bg-white border border-red-600 h-8 w-8 focus:outline-none mr-4 cursor-pointer`}
                            >
                              <CheckIcon className="w-5 h-5 text-red-700" />
                            </button>
                            <button
                              type="button"
                              onClick={() =>
                                setShowDeleteConfirmation({
                                  [id]: false,
                                })
                              }
                              className={`inline-flex items-center justify-center  
          rounded bg-white border border-green-500 h-8 w-8 focus:outline-none cursor-pointer`}
                            >
                              <XMarkIcon className="w-5 h-5 text-red-700" />
                            </button>
                          </div>
                        ) : (
                          <button
                            className="inline-flex items-center justify-center ml-7 bg-transparent rounded h-[32px] text-red-600 cursor-pointer w-14"
                            onClick={() =>
                              setShowDeleteConfirmation({
                                [id]: true,
                              })
                            }
                          >
                            {isDeleteLoading[id] && (
                              <Spinner className="w-[16px] h-[16px] mr-2" />
                            )}
                            Delete
                          </button>
                        )}
                      </div>

                      <div className="absolute top-[20px] right-[20px]">
                        {getIconForSocialMedia(platforms[0])}
                      </div>
                    </div>
                    {showPreviewModal[id] && (
                      <VideoPreviewModal
                        videoData={post}
                        showPreviewModal={showPreviewModal}
                        setShowPreviewModal={setShowPreviewModal}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </>
  );
};
