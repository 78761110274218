import { useMutation, useQueryClient } from "@tanstack/react-query";

import { deleteDraftById } from "@/api/requests";

import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

export default function useDeleteDraftById() {
  const queryClient = useQueryClient();

  return useMutation((draftData: any) => deleteDraftById(draftData), {
    onSuccess: () => {
      showNotification("Draft deleted successfully", notificationType.SUCCESS);
      queryClient.invalidateQueries({ queryKey: ["editor-drafts"] });
    },
    onError: () => {
      showNotification(
        "Something went wrong, please try again!",
        notificationType.FAIL
      );
    },
  });
}
