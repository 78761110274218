import noDraftsImg from "@/assets/images/blank-canvas.svg";

const NoDrafts = () => {
  return (
    <div className="flex h-full flex-col justify-center">
      <img
        src={noDraftsImg}
        className="mx-auto w-56"
      />
      <p className="mx-auto mt-10 mb-6 w-80 text-center text-[#5E6473] text-sm">
        <span className="font-semibold">Drafts</span> you save from the editor
        will show up here.
      </p>
    </div>
  );
};

export default NoDrafts;
