import { useState } from "react";

import { USER_REFERRAL_LIST } from "@/constants/on-boarding";

import { OnboardingReferer, UserOnboardingSteps } from "@/enums";

import { OnBoardingRadio } from "@/views/home/components/UserOnBoardingStages/OnBoardingInputs";
import OtherRefererModal from "@/views/home/components/UserOnBoardingStages/OtherRefererModal";
import { HandelOnboardingDataType } from "@/views/home/components/UserOnBoardingStages/types";

export const Referer = ({
  handleOnBoardingData,
  referer,
  handleNext,
  isMutationLoading,
}: {
  handleOnBoardingData: (data: HandelOnboardingDataType) => void;
  referer: string;
  handleNext: (nextStep?: number, value?: any) => void;
  isMutationLoading: boolean;
}) => {
  const [selectedReferer, setSelectedReferer] = useState<string>(referer);

  const [showOtherRefererModal, setShowOtherRefererModal] = useState(false);

  const handleReferer = (value: string) => {
    setSelectedReferer(value);
    if (value === OnboardingReferer.OTHERS) {
      setShowOtherRefererModal(true);
      return;
    }

    handleOnBoardingData({
      onBoardingData: {
        referer: value,
      },
    });
    handleNext(UserOnboardingSteps.REFERER, value);
  };

  return (
    <>
      <div>
        <p className="mt-6 text-xs leading-6 text-gray-400 lg:text-base">
          and finally..
        </p>

        <p className="my-4 text-xl font-semibold leading-6 text-gray-900 sm:text-2xl">
          You heard about us from?
        </p>

        {isMutationLoading && (
          <p className="mt-4 text-sm text-gray-400">
            Please wait while your response is submitting...
          </p>
        )}

        <div className="mx-auto mt-8 grid w-11/12 gap-y-1 gap-x-3 sm:grid-cols-2 md:w-10/12 lg:w-1/2">
          {USER_REFERRAL_LIST.map((referer) => (
            <OnBoardingRadio
              key={referer.id}
              id={referer.id}
              label={referer.value}
              value={referer.value}
              onChange={(e) => handleReferer(e.currentTarget.value)}
              isSelected={selectedReferer === referer.value}
              isDisabled={isMutationLoading}
            />
          ))}
        </div>
      </div>
      <OtherRefererModal
        showOtherRefererModal={showOtherRefererModal}
        handleOnBoardingData={handleOnBoardingData}
        setShowOtherRefererModal={setShowOtherRefererModal}
        setSelectedReferer={setSelectedReferer}
        handleNext={handleNext}
      />
    </>
  );
};
