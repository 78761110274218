import { SubArrItem } from "@/interfaces";

export const getSecondsFromHMS = (hms: string) => {
  const a = hms.split(":"); // split it at the colons

  // minutes are worth 60 seconds. Hours are worth 60 minutes.
  const seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
  return seconds;
};

export const getMillisecondsFromHMSM = (hms: string) => {
  // split to get milliseconds
  const a = hms.split(".");
  // split to get seconds
  const b = a[0].split(":"); // split it at the colons

  // minutes are worth 60 seconds. Hours are worth 60 minutes.
  const seconds = +b[0] * 60 * 60 + +b[1] * 60 + +b[2];
  const milliseconds = seconds * 1000 + +a[1];
  return milliseconds;
};

export const getSecondsFromHMSM = (timeStr: string) => {
  // If it's a simple float without colons, return it directly after converting to float
  if (/^\d+(\.\d+)?$/.test(timeStr)) {
    return parseFloat(parseFloat(timeStr).toFixed(3));
  }

  // Validate extended format
  const isValid = /^(\d+:)?(\d{1,2}:)?\d{1,2}(\.\d+)?$/.test(timeStr);
  if (!isValid) {
    return undefined;
  }

  const parts = timeStr.split(":").reverse();
  let totalSeconds = 0;

  if (parts.length >= 1) {
    // seconds and milliseconds
    const [seconds, milliseconds] = parts[0].split(".");
    totalSeconds += parseFloat(seconds);
    if (milliseconds) {
      totalSeconds += parseFloat("0." + milliseconds.slice(0, 3));
    }
  }

  if (parts.length >= 2) {
    // minutes
    totalSeconds += parseInt(parts[1], 10) * 60;
  }

  if (parts.length >= 3) {
    // hours
    totalSeconds += parseInt(parts[2], 10) * 3600;
  }

  return parseFloat(totalSeconds.toFixed(3));
};
// convert seconds into format hh:mm:ss.ms
// seconds is a string of number of seconds in format `seconds.milliseconds`
export const formatSeconds = (secondsWithMillis: number | string) => {
  if (typeof secondsWithMillis === "number") {
    secondsWithMillis = secondsWithMillis.toString();
  }

  // Separate seconds and milliseconds
  let parts = String(secondsWithMillis).split(".");
  let seconds = parseInt(parts[0], 10);
  let millis = parts[1] || "000";

  let totalMinutes = Math.floor(seconds / 60);
  seconds -= totalMinutes * 60;

  let totalHours = Math.floor(totalMinutes / 60);
  totalMinutes -= totalHours * 60;

  // Convert each unit to a string. Ensure milliseconds have three digits
  let hoursStr = totalHours.toString();
  let minutesStr = totalMinutes.toString();
  let secondsStr = seconds.toString().padStart(2, "0");
  let millisStr = millis.slice(0, 3).padEnd(3, "0");

  // Build the formatted time string with hours
  let formattedTime = `${hoursStr}:${minutesStr}:${secondsStr}.${millisStr}`;

  return formattedTime;
};

export const formatVideoDuration = (time: number) => {
  // convert time to seconds
  time = time / 1000;
  // Hours, minutes and seconds
  const hrs = ~~(time / 3600);
  const mins = ~~((time % 3600) / 60);
  const secs = ~~time % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
};

// function to calculate hour and minutes from seconds
// also round off the seconds to nearest minute
export const secondsToHm = (d: number) => {
  d = Number(d);

  if (d === 0) return "0m";

  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);

  const hDisplay = h > 0 ? h + (h == 1 ? "h" : "h") : "";
  const mDisplay = m > 0 ? m + (m == 1 ? "m" : "m") : "";

  return `${hDisplay} ${mDisplay}`;
};

export const convertFireBaseTimeToDate = (timeObj?: any) => {
  if (timeObj && !timeObj?.seconds) {
    return new Date(timeObj);
    // return new Date(timeObj.seconds * 1000 + timeObj.nanoseconds / 1000000);
  }
  return;
};

export const currentTimeInISOString = () => {
  return new Date().toISOString();
};

export const getDaysDifference = (startDate: any, endDate: any) =>
  Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));

export const formatMilliseconds = (milliseconds: number): string => {
  // format: 1h30m45s
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  const remainingSeconds = seconds % 60;
  const remainingMinutes = minutes % 60;

  let formattedTime = "";
  if (hours > 0) {
    formattedTime += `${hours}h`;
  }

  if (remainingMinutes > 0) {
    formattedTime += `${remainingMinutes.toString().padStart(2, "0")}m`;
  }
  formattedTime += `${remainingSeconds.toString().padStart(2, "0")}s`;

  return formattedTime;
};

export const formatMillisecondsToHHMMSS = (milliseconds: number): string => {
  // format: 01:30:45
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  const remainingSeconds = seconds % 60;
  const remainingMinutes = minutes % 60;

  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = remainingMinutes.toString().padStart(2, "0");
  const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

export const secondsToMinSec = (seconds: number | undefined): string => {
  if (!seconds) return "00:00";
  const trimmedSeconds = Math.floor(seconds);
  const min = Math.floor(trimmedSeconds / 60);
  const sec = trimmedSeconds % 60;

  // Ensuring that min always has two digits, e.g., "00" instead of "0"
  const formattedMin = min < 10 ? `0${min}` : min.toString();

  // Ensuring that sec always has two digits, e.g., "03" instead of "3"
  const formattedSec = sec < 10 ? `0${sec}` : sec.toString();

  return `${formattedMin}:${formattedSec}`;
};

export const roundTimeToNextHour = (date: Date) => {
  date.setHours(date.getHours() + 1);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};
