import { useMutation, useQueryClient } from "@tanstack/react-query";

import { deleteAllDraftByUserId } from "@/api/requests";

import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

export default function useDeleteAllDraftByUserId() {
  const queryClient = useQueryClient();

  return useMutation((userId: string) => deleteAllDraftByUserId(userId), {
    onSuccess: () => {
      showNotification("Draft deleted successfully", notificationType.SUCCESS);
      queryClient.invalidateQueries({ queryKey: ["editor-drafts"] });
    },
    onError: () => {
      showNotification(
        "Something went wrong, please try again!",
        notificationType.FAIL
      );
    },
  });
}
