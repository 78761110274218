import { useState } from "react";

import BaseModal from "@/components/BaseModal/BaseModal";

import { HandelOnboardingDataType } from "./types";

const UserOccupationModal = ({
  showOtherOccupationModal,
  handleOnBoardingData,
  setShowOtherOccupationModal,
  setSelectedOccupation,
  handleNext,
}: {
  showOtherOccupationModal: boolean;
  handleOnBoardingData: (data: HandelOnboardingDataType) => void;
  setShowOtherOccupationModal: (value: boolean) => void;
  setSelectedOccupation: (value: string) => void;
  handleNext: (nextStep?: number, value?: any) => void;
}) => {
  const [occupation, setOccupation] = useState("");

  const handelCloseOtherOccupationModal = () => {
    setOccupation("");
    setSelectedOccupation("");
    setShowOtherOccupationModal(false);
  };
  return (
    <BaseModal
      isModalOpen={showOtherOccupationModal}
      handelModalOpen={() => {}}
      notClosable
    >
      {showOtherOccupationModal && (
        <div className="w-full bg-white pt-6 pb-6 md:w-[30rem] md:p-6 md:pb-10 md:pt-8">
          <>
            <p className="mb-6 text-center font-bold text-gray-900 md:text-2xl">
              What do you do?
            </p>

            <div className="mx-auto mb-8 w-full">
              <input
                type="text"
                name="template-name"
                id="user-template-name"
                onChange={(e) => setOccupation(e.target.value)}
                className="mx-auto block w-[80%] rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
                placeholder="Please enter an answer"
                value={occupation}
                spellCheck="false"
              />
            </div>

            <div className="mx-auto flex w-[60%] justify-center">
              <button
                onClick={handelCloseOtherOccupationModal}
                type="button"
                id="user-occupation-back-btn"
                className="mr-4 inline-flex w-40 items-center justify-center rounded-lg border border-blue-500 bg-white px-5 py-2.5 text-center text-sm font-medium text-blue-500 hover:bg-blue-50  focus:outline-none focus:ring-4 focus:ring-blue-300"
              >
                Back
              </button>

              <button
                type="button"
                id="user-occupation-continue-btn"
                className={`flex w-40 items-center justify-center rounded-lg bg-blue-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 ${
                  !occupation && "opacity-70 hover:bg-blue-600"
                }`}
                disabled={!occupation}
                onClick={() => {
                  handleOnBoardingData({
                    onBoardingData: {
                      occupation,
                    },
                  });
                  handleNext(1);
                  setShowOtherOccupationModal(false);
                }}
              >
                Continue
              </button>
            </div>
          </>
        </div>
      )}
    </BaseModal>
  );
};

export default UserOccupationModal;
