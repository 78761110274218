import clsx from "clsx";

import { IconPropType } from "@/utils/constants";

export const InvertedHalfTriangleIcon = ({
  fillColor,
  height = "12",
  width = "12",
  className,
}: IconPropType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={clsx(className)}
      color={fillColor}
      viewBox="0 0 12 12"
    >
      <g clipPath="url(#a)">
        <path
          fill="currentColor"
          d="M6.049 10.488A.096.096 0 0 1 6 10.5V1.513l5.142-.013a.09.09 0 0 1 .05.013.122.122 0 0 1 .04.045.131.131 0 0 1-.001.137l-5.142 8.75a.11.11 0 0 1-.04.043h-.001Zm-.783.337a.848.848 0 0 0 1.47 0l5.143-8.75c.343-.583-.069-1.325-.736-1.325H.86c-.667 0-1.078.742-.735 1.325l5.141 8.75Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <rect
            width="100%"
            height="100%"
            fill="#fff"
            transform="matrix(1 0 0 -1 0 12)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
