import { useState } from "react";
import { useDropzone } from "react-dropzone";

import { PlusCircleIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { Spinner } from "flowbite-react";

import { useAppSelector } from "@/store/hooks";

import { uploadFileToS3 } from "@/api/requests";
import useUploadAsset from "@/api/useUploadAsset";

import { AssetTags, SimpleAssetType } from "@/enums";

import { ReactComponent as CrownIcon } from "@/assets/icons/crown.svg";

type MediaUploaderProps = {
  supportedMediaTypes: Record<string, string[]>;
  assetTypeText: string;
  maxFiles?: number;
  assetTag: AssetTags;
  isColorUploader?: boolean;
  handelShowColorUploadModal?: () => void;
  handleOpenUpgradeToProModal: () => void;
  isFreeUserLimitReached: boolean;
};

const MediaUploaderCard = ({
  supportedMediaTypes,
  assetTypeText,
  maxFiles = 1,
  assetTag,
  isColorUploader,
  handelShowColorUploadModal,
  isFreeUserLimitReached,
  handleOpenUpgradeToProModal,
}: MediaUploaderProps) => {
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const uid = useAppSelector((state) => state.authState.currentUser.uid);

  const { mutate: finalizeUpload, isLoading: finalizeUploadLoading } =
    useUploadAsset(assetTag);

  const onDrop = async (acceptedFiles: any) => {
    const file = acceptedFiles?.[0];

    if (!file) {
      return;
    }
    try {
      setIsUploadLoading(true);

      if (file.type?.includes(SimpleAssetType.VIDEO)) {
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        });

        const video = document.createElement(SimpleAssetType.VIDEO);
        video.src = file.preview;
        video.preload = "metadata";
        video.muted = true;
        video.autoplay = false;
        video.playsInline = true;

        video.addEventListener("loadedmetadata", async () => {
          const res = await uploadFileToS3(file);

          res?.s3Url &&
            finalizeUpload({
              assetType: file.type,
              assetId: res?.assetId,
              ownerId: uid,
              parentId: null,
              assetTag: assetTag,
              data: {
                assetName: file.name.split(".")[0],
                remoteUrl: res?.s3Url,
                duration: Math.round(video.duration) || 0,
              },
            });
          removeAll();
          setIsUploadLoading(false);
        });
      } else {
        const res = await uploadFileToS3(file);

        res?.s3Url &&
          finalizeUpload({
            assetType: file.type,
            assetId: res?.assetId,
            ownerId: uid,
            parentId: null,
            assetTag: assetTag,
            data: {
              assetName: file.name.split(".")[0],
              remoteUrl: res?.s3Url,
              duration: 0,
            },
          });
        removeAll();
        setIsUploadLoading(false);
      }
    } catch (error) {
      removeAll();
      console.log(error);
      setIsUploadLoading(false);
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    inputRef,
  }: any = useDropzone({
    onDrop,
    accept: supportedMediaTypes,
    maxFiles: maxFiles,
  });

  const removeAll = () => {
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
    if (inputRef.current) inputRef.current.value = "";
  };

  if (isFreeUserLimitReached) {
    return (
      <button
        id={`free-user-consumed-dropzone-file-media-uploader-card`}
        onClick={handleOpenUpgradeToProModal}
        className="flex flex-col justify-center items-center w-full h-full px-4  bg-gray-50 rounded-lg border-2 border-gray-400 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-500"
      >
        {" "}
        <p className="flex items-center justify-center text-gray-500 dark:text-gray-400 font-medium text-sm py-3.5">
          Add {assetTypeText} <CrownIcon className="ml-2 w-5 h-5" />
        </p>
      </button>
    );
  }

  if (isColorUploader) {
    return (
      <section
        className="flex justify-center items-center min-w-[12rem] min-h-[12rem]"
        onClick={handelShowColorUploadModal}
      >
        <label
          htmlFor="dropzone-file-media-uploader-card"
          className="flex flex-col justify-center items-center w-full h-full px-4  bg-gray-50 rounded-lg border-2 border-gray-400 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-500"
        >
          <div className="flex  justify-center items-center pt-5 pb-6 text-sm">
            <PlusCircleIcon className=" text-gray-500 w-5 h-5 mr-1" />

            <p className=" text-gray-500 dark:text-gray-400 font-medium">
              Add Color
            </p>
          </div>
        </label>
      </section>
    );
  }

  return (
    <section
      className="flex justify-center items-center min-w-[12rem] min-h-[12rem]"
      {...getRootProps()}
      onClick={(e) => e.stopPropagation()}
    >
      <label
        htmlFor="dropzone-file-media-uploader-card"
        className={clsx(
          isUploadLoading && "opacity-60",
          "flex flex-col justify-center items-center w-full h-full px-4 bg-gray-50 rounded-lg border-2 border-gray-400 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-500"
        )}
      >
        <div className="flex  justify-center items-center pt-5 pb-6 text-sm">
          {isUploadLoading && <Spinner className="h-4 w-4 mr-2" />}
          {!isDragActive && !isUploadLoading && (
            <PlusCircleIcon className=" text-gray-500 w-5 h-5 mr-1" />
          )}

          {isDragActive && !isUploadLoading ? (
            <p className=" text-gray-500 dark:text-gray-400 font-medium">
              Drop files here...
            </p>
          ) : (
            <p className=" text-gray-500 dark:text-gray-400 font-medium">
              {isUploadLoading ? "Adding" : "Add"} {assetTypeText}
            </p>
          )}
        </div>
        <input
          {...getInputProps()}
          id="dropzone-file-media-uploader-card"
          disabled={isUploadLoading || finalizeUploadLoading}
        />
      </label>
    </section>
  );
};

export default MediaUploaderCard;
