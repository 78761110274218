import { useQuery } from "@tanstack/react-query";

import { useAppSelector } from "@/store/hooks";

import { getAssets } from "@/api/requests";

import { AssetTags } from "@/enums";

export default function useGetAssets({
  assetTag,
  disableQuery = false,
}: {
  assetTag: AssetTags;
  disableQuery?: boolean;
}) {
  const uid = useAppSelector((state) => state.authState.currentUser.uid);

  return useQuery({
    queryKey: ["Assets", assetTag, uid],
    queryFn: () =>
      getAssets({
        assetTag,
      }),
    staleTime: 300000,
    enabled: !disableQuery,
  });
}
