import { useRef, useState, useEffect } from "react";

import { useAppSelector } from "@/store/hooks";

import PauseIcon from "@/assets/icons/pause_icon.svg";
import PlayIcon from "@/assets/icons/play_icon.svg";

export const FullVideo = () => {
  const currentSelectedProject = useAppSelector(
    (state) => state.homeState.currentSelectedProject
  );

  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [showControls, setShowControls] = useState<boolean>(false);
  const [isDraggingSlider, setIsDraggingSlider] = useState<boolean>(false);

  const videoElRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const video = videoElRef.current;
    if (video) {
      const handleTimeUpdate = () => setCurrentTime(video.currentTime);
      const handleLoadedMetadata = () => setDuration(video.duration);
      video.addEventListener("timeupdate", handleTimeUpdate);
      video.addEventListener("loadedmetadata", handleLoadedMetadata);
      return () => {
        video.removeEventListener("timeupdate", handleTimeUpdate);
        video.removeEventListener("loadedmetadata", handleLoadedMetadata);
      };
    }
  }, []);

  const handleTogglePlay = (): void => {
    const video = videoElRef.current;
    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        video.play().catch(() => {});
      }
      setIsPlaying(!isPlaying);
    }
  };

  const formatTime = (time: number): string => {
    const hours = Math.floor(time / 3600)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((time % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const seconds = Math.ceil(time % 60)
      .toString()
      .padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  const handleMouseEnter = () => {
    setShowControls(true);
  };

  const handleMouseLeave = () => {
    setShowControls(false);
  };

  return (
    <div
      className="w-full h-[500px] mt-5"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {currentSelectedProject && (
        <>
          <video
            className="mx-auto"
            ref={videoElRef}
            src={JSON.parse(currentSelectedProject?.data).remote_url}
            height={400}
            width={712}
            style={{
              borderRadius: "10px",
              boxShadow: "0px 0px 10px 1px rgba(0, 0, 0, 0.1)",
            }}
          />
          {(showControls || !isPlaying) && (
            <div className="w-[712px] text-center mx-auto mt-10 relative flex justify-center items-center">
              <div className="cursor-pointer">
                {isPlaying ? (
                  <img
                    src={PauseIcon}
                    className="h-6 w-6"
                    onClick={handleTogglePlay}
                  />
                ) : (
                  <img
                    src={PlayIcon}
                    className="h-6 w-6"
                    onClick={handleTogglePlay}
                  />
                )}
              </div>
              <div className="absolute top-0 right-0">
                {formatTime(currentTime)} / {formatTime(duration)}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
