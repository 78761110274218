import { Badge } from "flowbite-react";

import { PLAN_LABEL_TEXT } from "@/constants";

import { PlanType } from "@/enums";

import ProTickImg from "@/assets/icons/badge-check.svg";

const ProTick = ({ planType }: { planType: PlanType }) => {
  return planType === PlanType.FREE ? (
    <Badge
      color="success"
      size="lg"
    >
      FREE
    </Badge>
  ) : (
    <div
      id="pro-user-tick"
      className="group relative  mt-0.5 flex cursor-default  overflow-hidden rounded-lg bg-black px-3 py-1 text-white shadow-lg"
    >
      <div className="z-5 absolute -inset-full top-0 block h-full w-1/2 -skew-x-12 transform bg-gradient-to-r from-transparent to-white opacity-40 group-hover:animate-shine" />
      <span className="select-none relative inline-flex items-center rounded-lg text-sm font-medium text-[#F2CA46]">
        <img
          src={ProTickImg}
          className="mr-1 w-[18px]"
        />{" "}
        {PLAN_LABEL_TEXT[PlanType.PRO_PLUS]}
      </span>
    </div>
  );
};

export default ProTick;
