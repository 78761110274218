import { useMutation } from "@tanstack/react-query";

import api from "@/api";
import { ApiEndpoints } from "@/api/constants";

const postRequestForBulkRenderVideo = async (body: any) => {
  const { data } = await api.post(ApiEndpoints.BULK_RENDER_VIDEO, { ...body });
  return data;
};

export default function useBulkRenderVideo(
  pollForBulkRenderVideo: (renderTaskId: string) => void,
  bulkRenderVideoErrorCB?: () => void,
  bulkRenderVideoSuccessCB?: () => void
) {
  return useMutation(
    ({ ...body }: any) => postRequestForBulkRenderVideo(body),
    {
      onSuccess: (res: any) => {
        bulkRenderVideoSuccessCB && bulkRenderVideoSuccessCB();
        pollForBulkRenderVideo(res.render_task_id);
      },
      onError: () => {
        bulkRenderVideoErrorCB && bulkRenderVideoErrorCB();
      },
    }
  );
}
