import { ReactNode } from "react";

import { Button } from "flowbite-react";

import { LOGIN } from "@/constants";

const HelperComponent = ({
  headline,
  message,
  updateCurrentTab,
  setShowHelperComponent,
  passwordReset,
  optionalButton,
}: {
  headline: string;
  message: string;
  updateCurrentTab: (value: string) => void;
  setShowHelperComponent: (value: boolean) => void;
  passwordReset?: boolean;
  optionalButton?: ReactNode;
}) => {
  const buttonOnClick = () => {
    updateCurrentTab(LOGIN);
    setShowHelperComponent(false);
  };
  return (
    <div className="xl:mt-48 mt-24">
      <h1 className="font-bold text-2xl">{headline}</h1>
      <p
        className="mt-2 text-sm"
        style={{ color: "#9CA3AF" }}
      >
        {message}
      </p>

      <div className="py-24">
        {passwordReset && (
          <Button
            color="white"
            className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            type="button"
            onClick={buttonOnClick}
          >
            Back to Login Page
          </Button>
        )}
        {optionalButton}
      </div>
    </div>
  );
};

export default HelperComponent;
