import {
  useCallback,
  useContext,
  useState,
  createContext,
  ReactNode,
} from "react";
import { useBoolean } from "react-use";

import { VideoLayout } from "@/enums";

type SelectedClipsContextType = {
  selectedClips: Map<string, any>;
  toggleClipSelection: (clip: any) => void;
  selectedClipsCount: number;
  selectModeOn: boolean;
  toggleMultiSelectMode: () => void;
  selectedTemplatesId?: any;
  setSelectedTemplatesId: (template: any) => void;
  renderVideoPayloadForMultipleClips?: any[];
  updateRenderVideoPayloadForMultipleClips?: any;
  multipleRenderModeOn: boolean;
  toggleMultipleRenderMode: () => void;
  setSelectedClips: (clips: any) => void;
  resetSelectedTemplatesId: () => void;
  togglePreviewClipEditorLoaded: (value: boolean) => void;
  isPreviewClipEditorLoaded: boolean;
};

const SelectedClipsContext = createContext<SelectedClipsContextType>({
  selectedClips: new Map(),
  toggleClipSelection: () => {},
  selectedClipsCount: 0,
  selectModeOn: false,
  toggleMultiSelectMode: () => {},
  selectedTemplatesId: [],
  setSelectedTemplatesId: () => {},
  renderVideoPayloadForMultipleClips: [],
  updateRenderVideoPayloadForMultipleClips: () => {},
  multipleRenderModeOn: false,
  toggleMultipleRenderMode: () => {},
  setSelectedClips: () => {},
  resetSelectedTemplatesId: () => {},
  togglePreviewClipEditorLoaded: (value: boolean) => {},
  isPreviewClipEditorLoaded: false,
});

const getClipKey = (clip: any): string => `${clip.start}-${clip.end}`;

export const SelectedClipsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [selectedClips, setSelectedClips] = useState(new Map());
  const [selectModeOn, toggleSelectMode] = useBoolean(false);
  const [multipleRenderModeOn, toggleMultipleRenderMode] = useBoolean(false);
  const [isPreviewClipEditorLoaded, togglePreviewClipEditorLoaded] =
    useState(false);
  const [selectedTemplatesId, setSelectedTemplatesId] = useState<any>({
    [VideoLayout.LAYOUT_1_1]: "",
    [VideoLayout.LAYOUT_9_16_1]: "",
    [VideoLayout.LAYOUT_9_16_2]: "",
    [VideoLayout.LAYOUT_16_9]: "",
  });
  const [
    renderVideoPayloadForMultipleClips,
    setRenderVideoPayloadForMultipleClips,
  ] = useState<any>([]);

  const addSelectedClip = useCallback((clip: any) => {
    const clipData = {
      face_coord: clip.face_coord,
      start: clip.start,
      end: clip.end,
      srt_string: clip.srt_string,
      imageUrl: clip.imageUrl,
      hasTwoFace: clip.hasTwoFace,
      gist: clip.gist,
      text: clip.text,
      chapter_end: clip.chapter_end,
      chapter_start: clip.chapter_start,
      tag: clip.tag,
      clip_src: clip.clip_src,
    };
    const clipKey = getClipKey(clip);
    setSelectedClips((prev) => new Map(prev).set(clipKey, clipData));
  }, []);

  const removeSelectedClip = useCallback((clipKey: string) => {
    setSelectedClips((prev) => {
      const newMap = new Map(prev);
      newMap.delete(clipKey);
      return newMap;
    });
  }, []);

  const toggleClipSelection = (clip: any) => {
    const clipKey = getClipKey(clip);
    if (selectedClips.has(clipKey)) {
      removeSelectedClip(clipKey);
    } else {
      addSelectedClip(clip);
    }
  };

  const toggleMultiSelectMode = () => {
    toggleSelectMode();
    setSelectedClips(new Map());
  };

  const updateRenderVideoPayloadForMultipleClips = useCallback(
    (payload: any[]) => {
      setRenderVideoPayloadForMultipleClips(payload);
    },
    []
  );

  const resetSelectedTemplatesId = () => {
    setSelectedTemplatesId({
      [VideoLayout.LAYOUT_1_1]: "",
      [VideoLayout.LAYOUT_9_16_1]: "",
      [VideoLayout.LAYOUT_9_16_2]: "",
      [VideoLayout.LAYOUT_16_9]: "",
    });
  };

  const value = {
    selectedClips,
    toggleClipSelection,
    selectedClipsCount: selectedClips.size,
    toggleMultiSelectMode,
    selectModeOn,
    selectedTemplatesId,
    setSelectedTemplatesId,
    updateRenderVideoPayloadForMultipleClips,
    renderVideoPayloadForMultipleClips,
    multipleRenderModeOn,
    toggleMultipleRenderMode,
    setSelectedClips,
    resetSelectedTemplatesId,
    togglePreviewClipEditorLoaded,
    isPreviewClipEditorLoaded,
  };

  return (
    <SelectedClipsContext.Provider value={value}>
      {children}
    </SelectedClipsContext.Provider>
  );
};

export const useSelectedClips = () => useContext(SelectedClipsContext);
