import { updateOnBoardingDataById } from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { addOrReplaceObjectInArray } from "@/helpers";

import { VideoLayout } from "@/enums";

const TemplateCard = ({
  templateData,
  layoutId,
  children,
  dimensions,
}: any) => {
  const onBoardingData = useAppSelector(
    (state) => state.homeState.onBoardingData
  );
  const onBoardingId = useAppSelector(
    (state) => state.homeState.currentOnBoardingId
  );

  const baseTemplates = onBoardingData[onBoardingId].baseTemplates;

  const getCurrentLayoutsTemplate = () => {
    if (layoutId === VideoLayout.LAYOUT_9_16_1) {
      return [
        ...baseTemplates[layoutId],
        ...baseTemplates[VideoLayout.LAYOUT_9_16_2],
      ];
    }
    return baseTemplates[layoutId];
  };

  const getCurrentTemplate = () => {
    const currentLayoutTemplates = getCurrentLayoutsTemplate();
    return (
      currentLayoutTemplates.find(
        (template: any) => template.id === templateData.id
      ) || {}
    );
  };

  const isTemplateSelected = () => {
    const currentTemplate = getCurrentTemplate();

    // check if any of the base template is selected as preferred template
    return currentTemplate.isPreferred;
  };

  const dispatch = useAppDispatch();

  const updateTemplateSelection = () => {
    const currentLayoutTemplates = getCurrentLayoutsTemplate();
    const currentTemplate = getCurrentTemplate();

    if (isTemplateSelected()) {
      const newLayoutTemplates = addOrReplaceObjectInArray(
        currentLayoutTemplates,
        { ...currentTemplate, isPreferred: false }
      );
      let newBaseTemplates;
      if (layoutId === VideoLayout.LAYOUT_9_16_1) {
        newBaseTemplates = {
          ...baseTemplates,
          [VideoLayout.LAYOUT_9_16_1]: newLayoutTemplates.filter(
            (template) => template.hasTwoFace === false
          ),
          [VideoLayout.LAYOUT_9_16_2]: newLayoutTemplates.filter(
            (template) => template.hasTwoFace
          ),
        };
      } else {
        newBaseTemplates = {
          ...baseTemplates,
          [layoutId]: newLayoutTemplates,
        };
      }

      dispatch(
        updateOnBoardingDataById({
          id: onBoardingId,
          data: {
            baseTemplates: newBaseTemplates,
          },
        })
      );
    } else {
      const newLayoutTemplates = addOrReplaceObjectInArray(
        currentLayoutTemplates,
        { ...currentTemplate, isPreferred: true }
      );

      let newBaseTemplates;
      if (layoutId === VideoLayout.LAYOUT_9_16_1) {
        newBaseTemplates = {
          ...baseTemplates,
          [VideoLayout.LAYOUT_9_16_1]: newLayoutTemplates.filter(
            (template) => template.hasTwoFace === false
          ),
          [VideoLayout.LAYOUT_9_16_2]: newLayoutTemplates.filter(
            (template) => template.hasTwoFace
          ),
        };
      } else {
        newBaseTemplates = {
          ...baseTemplates,
          [layoutId]: newLayoutTemplates,
        };
      }

      dispatch(
        updateOnBoardingDataById({
          id: onBoardingId,
          data: {
            baseTemplates: newBaseTemplates,
          },
        })
      );
    }
  };

  return (
    <div
      className={`h-full rounded-2xl shadow ${
        layoutId === VideoLayout.LAYOUT_9_16_1 ? "mx-3.5" : "mx-6"
      }  w-[${
        dimensions.width
      }px] relative flex cursor-pointer select-none justify-center p-1  ${
        isTemplateSelected()
          ? "border-[3px] border-blue-600"
          : "border-[3px] border-transparent"
      }`}
    >
      <div className="absolute left-3 top-4 mb-4 items-center">
        <input
          id={templateData.id}
          type="checkbox"
          value=""
          checked={isTemplateSelected() || false}
          onChange={updateTemplateSelection}
          className=" hidden rounded-full border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
        />
      </div>
      <label
        htmlFor={templateData.id}
        className={`flex cursor-pointer justify-center w-[${dimensions.width}px]`}
      >
        {children}
      </label>
    </div>
  );
};

export default TemplateCard;
