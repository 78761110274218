const WaveFormAnimation = ({ bars = 35 }) => {
  return (
    <div className="flex justify-between items-center">
      {Array.from({ length: bars }).map((_, idx) => (
        <div
          key={idx}
          className="bg-blue-300 w-1 animate-waveAnimation rounded-full"
          style={{ animationDelay: `${0.1 * idx}s` }}
        ></div>
      ))}
    </div>
  );
};

export default WaveFormAnimation;
